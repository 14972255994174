import { IntlLabel } from '@plandok/i18n'
import { MobileBackButton } from 'components'
import React from 'react'

export const AnalyticsMobileHeader = (title: string) => (
   <>
      <MobileBackButton />
      <div>
         <IntlLabel label={title} />
      </div>
      <div />
   </>
)
