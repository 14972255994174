import { CalendarOutlined, ClockCircleOutlined, LockOutlined, RightOutlined, UserOutlined } from '@ant-design/icons'
import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { List } from 'antd'
import { AppLayout, MobileBackButton, NoInternetConnection } from 'components'
import { RoutePath, StaffTabHash } from 'constants/routes'
import React from 'react'

export default function MobileStaffPage() {
   const { navigate } = useLocation()

   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="sidebar.staff"
            mobileHeader={() => (
               <>
                  <MobileBackButton />
                  <div>
                     <IntlLabel label="sidebar.staff" />
                  </div>
                  <div />
               </>
            )}>
            <List
               itemLayout="horizontal"
               className="staff-menu__list"
               dataSource={[
                  {
                     icon: <UserOutlined />,
                     name: 'staff.menu.members',
                     description: 'staff.menu.members.description',
                     path: `${RoutePath.STAFF}#${StaffTabHash.STAFF_MEMBERS}`,
                  },
                  {
                     icon: <ClockCircleOutlined />,
                     name: 'staff.menu.working',
                     description: 'staff.menu.working.description',
                     path: `${RoutePath.STAFF}#${StaffTabHash.WORKING_HOURS}`,
                  },
                  {
                     icon: <CalendarOutlined />,
                     name: 'staff.menu.dates',
                     description: 'staff.menu.dates.description',
                     path: `${RoutePath.STAFF}#${StaffTabHash.CLOSED_DATES}`,
                  },
                  {
                     icon: <LockOutlined />,
                     name: 'staff.menu.permissions',
                     description: 'staff.menu.permissions.description',
                     path: `${RoutePath.STAFF}#${StaffTabHash.USER_PERMISSIONS}`,
                  },
               ]}
               renderItem={(item: any) => (
                  <List.Item onClick={() => navigate(item.path)}>
                     <List.Item.Meta
                        avatar={item.icon}
                        title={<IntlLabel label={item.name} />}
                        description={<IntlLabel label={item.description} />}
                     />
                     <div>
                        <RightOutlined />
                     </div>
                  </List.Item>
               )}
            />
         </AppLayout>
      </NoInternetConnection>
   )
}
