import get from 'lodash/get'
import set from 'lodash/set'

export const setEmptyFieldsToNull = (values: any, fields: string[]) => {
   let result = { ...values }

   if (result) {
      fields?.map((key: string) => {
         const value = get(values, key)
         if (!value && value !== 0) {
            set(result, key, null)
         }
      })
   }
   return result
}

export const createRequestData = (
   fValues: object,
   finalForm: any,
   formatForm: (a: object) => object,
   combinedFields: any[],
) => {
   const formValues = setEmptyFieldsToNull(fValues, finalForm.getRegisteredFields())
   const form = formatForm ? formatForm(formValues) : formValues

   if (combinedFields?.length) {
      combinedFields.forEach(k => {
         const item = form[k]
         if (!item?.split('-')?.[1]) {
            form[k] = null
         }
      })
   }

   return form
}
