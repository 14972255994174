import { useAppContext, useNavigation } from '@plandok/core'
import { AuthBlock, Button, getIsMobile, useModal } from '@plandok/core'
import { IntlLabel, LabelKey } from '@plandok/i18n'
import { Dropdown } from 'antd'
import cn from 'classnames'
import HeaderUsage from 'components/app/AppLayout/components/AppHeader/components/HeaderUsage'
import HeaderUserMenuUsage from 'components/app/AppLayout/components/AppHeader/components/HeaderUserMenuUsage'
import { PermissionType } from 'constants/auth'
import { RoutePath } from 'constants/routes'
import React, { ComponentType } from 'react'

import HeaderSearchBtn from './components/HeaderSearchBtn'
import HeaderUserMenu from './components/HeaderUserMenu'
import * as SC from './styles'

type AppHeaderProps = {
   mobileHeader: ComponentType
   headerTitle: LabelKey
   mobileBackground?: string
   headerIconTitle?: string
}

export default function AppHeader({
   mobileHeader: MobileHeader = () => null,
   mobileBackground,
   headerTitle,
   headerIconTitle,
}: AppHeaderProps) {
   const [context] = useAppContext()
   const { navigate } = useNavigation()
   const [, { showModal }] = useModal()

   const isMobile = getIsMobile()

   const isFreePlan = !context.subscription?.isPremium

   const openSelectLanguage = () => showModal({ type: 'LANGUAGE_SELECT' })
   const goToPricingPage = () => navigate(RoutePath.BILLING)
   const onLogout = () => navigate(RoutePath.LOGOUT)

   return (
      <>
         {!isMobile && (
            <SC.Header className="ant-layout-header">
               {headerIconTitle ? (
                  <div className="d-flex">
                     <SC.Title>
                        <IntlLabel label={headerTitle} />
                     </SC.Title>
                  </div>
               ) : (
                  <SC.Title>
                     <IntlLabel label={headerTitle} />
                  </SC.Title>
               )}

               <SC.UserInfo>
                  <AuthBlock section={PermissionType.ACCOUNT_SETTINGS}>
                     {isFreePlan && (
                        <Button
                           type="default"
                           label="btn.upgrade"
                           data-pricing-link
                           className="mr-4"
                           onClick={goToPricingPage}
                           style={{ background: '#349447' }}
                        />
                     )}
                  </AuthBlock>
                  <HeaderSearchBtn />

                  {!isFreePlan && (
                     <Dropdown
                        overlay={() => <HeaderUserMenuUsage goToPricingPage={goToPricingPage} />}
                        trigger={['click']}>
                        <div>
                           <HeaderUsage />
                        </div>
                     </Dropdown>
                  )}

                  <HeaderUserMenu
                     logout={onLogout}
                     openSelectLanguage={openSelectLanguage}
                     displayName={context.displayName}
                  />
               </SC.UserInfo>
            </SC.Header>
         )}
         <SC.MobileHeader
            className={cn('ant-layout-header ant-layout-header-mobile', { 'is-mobile': isMobile })}
            background={mobileBackground}
            isMobile={isMobile}>
            <div data-mobile-header-content>
               <MobileHeader />
            </div>
         </SC.MobileHeader>
      </>
   )
}
