import { MobileBackButton } from 'components'
import React from 'react'

import * as SC from '../styles'

export default function MobileHeader() {
   return (
      <>
         <MobileBackButton />
         <SC.Block />
      </>
   )
}
