import styled from 'styled-components'

type PrintButtonProps = {
   label?: string
}

export const PrintButton = styled.div<PrintButtonProps>`
   display: flex;
   align-items: center;
   padding-right: 1rem;

   button {
      display: flex;
      align-items: center;
      border: ${({ label }) => (label ? '1px solid #ADB6CC' : 'none')};
   }
`

export const PopoverTitle = styled.div`
   display: flex;
   align-items: center;

   svg {
      margin-right: 5px;
   }
`

export const PopoverDescription = styled.div`
   margin-top: 0.5rem;
   max-width: 14rem;
`

export const PrintContent = styled.div`
   .print-source {
      display: none;
   }
   @media print {
      .print-source {
         padding: 2.5rem 1rem;
         display: block;
      }
   }
`
