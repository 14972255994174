import { Card, mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const CardContainer = styled(Card)`
   max-width: 980px;
   width: 760px;
   margin: 60px auto;
   box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
   border-radius: 20px;
   padding: 0 40px;
   input[type='number']::-webkit-outer-spin-button,
   input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   ${mediaMobileDown(css`
      width: 355px;
      padding: 0 10px;
      margin: 100px auto 0 auto;
   `)}
`

export const RedirectBtn = styled.div`
   position: absolute;
   margin-top: 40px;
`

export const HeaderTitle = styled.div`
   align-self: center;
   text-align: center;
   padding: 40px 40px 0 40px;
   max-width: 290px;
   ${mediaMobileDown(css`
      padding: 20px 15px 0 15px;
   `)}
`

export const CustomTimeContainer = styled.div`
   display: flex;
   flex-direction: row;
   ${mediaMobileDown(css`
      flex-direction: column;
   `)}
`

export const CustomTimeProps = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   flex: 1;
   margin-left: 20px;
`

export const CustomTimeBtn = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   color: #13316d;
   margin-top: 20px;
   i {
      font-size: 25px;
   }
   button {
      min-width: 130px;
   }
   ${mediaMobileDown(css`
      margin-bottom: 20px;
   `)}
`
