import React, { ReactNode } from 'react'

import * as SC from './styles'

function PageControlContainer(props: {
   children: ReactNode
   mobileHidden?: boolean
   mobileColumn?: boolean
   mobileOnly?: boolean
   style?: any
}) {
   return (
      <SC.Container
         mobileOnly={props.mobileOnly}
         mobileHidden={props.mobileHidden}
         mobileColumn={props.mobileColumn}
         style={props.style}>
         {props.children}
      </SC.Container>
   )
}

PageControlContainer.Item = SC.Item
PageControlContainer.Space = SC.Space

export default PageControlContainer
