import '../styles/styles.less'

import { CustomIcon, LocalStorage, Mutation, Text, useLocation, withModal } from '@plandok/core'
import { notification } from 'antd'
import * as api from 'api'
import { RoutePath } from 'constants/routes'
import React from 'react'

import * as SC from '../styles'

type DeleteAccountModalProps = {
   onClose: () => void
}

function DeleteAccountModal({ onClose }: DeleteAccountModalProps) {
   const { navigate } = useLocation()

   const closeSuccessMessage = () =>
      notification.open({
         message: <Text label="modal.deleteAccount.successMessage" mb="none" size="small" colorType="base" />,
         closeIcon: <span />,
         className: 'delete-account-success-message',
      })

   const onSuccess = () => {
      onClose()
      navigate(RoutePath.LOGOUT)
      LocalStorage.clearAll()
      closeSuccessMessage()
   }

   return (
      <SC.DeleteAccountContainer>
         <CustomIcon type="warning" />
         <Text label="modal.deleteAccount.title" weight="medium" />
         <Text label="modal.deleteAccount.description" size="medium" />
         <Mutation action={api.deleteEmployeesAccount} onSuccess={onSuccess}>
            {({ loading, action }: any) => (
               <>
                  <SC.DeleteAccountButton
                     disabled={loading}
                     label="btn.cancel"
                     onClick={onClose}
                     upperCase={false}
                     whiteBtn
                     semiBold
                  />
                  <SC.DeleteAccountButton
                     loading={loading}
                     label="btn.delete"
                     danger
                     onClick={action}
                     upperCase={false}
                     semiBold
                  />
               </>
            )}
         </Mutation>
      </SC.DeleteAccountContainer>
   )
}

export default withModal(null, { contentClassName: 'delete-account-modal' })(DeleteAccountModal)
