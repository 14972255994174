import { IntlLabel } from '@plandok/i18n'
import { MobileBackButton } from 'components'
import React from 'react'

export default function CompanyMobileHeader() {
   return (
      <>
         <MobileBackButton />
         <div>
            <IntlLabel label="company.details.title" />
         </div>
         <div />
      </>
   )
}
