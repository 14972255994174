// THIS FILE IS GENERATED, DO NOT EDIT!
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../generatedTypes'
const defaultOptions = {} as const
export type BookingLocationsQueryVariables = Types.Exact<{ [key: string]: never }>

export type BookingLocationsQuery = {
   __typename?: 'AppQuery'
   locations?: {
      __typename?: 'App_LocationConnection'
      totalCount?: number | null
      pageInfo: {
         __typename?: 'PageInfo'
         hasNextPage: boolean
         hasPreviousPage: boolean
         startCursor?: string | null
         endCursor?: string | null
      }
      edges?: Array<{
         __typename?: 'App_LocationEdge'
         cursor: string
         node?: {
            __typename?: 'App_Location'
            id: any
            name: string
            email?: string | null
            phone?: string | null
            position: number
            onlineStatus: Types.App_LocationOnlineStatus
            description?: string | null
            slug?: string | null
            previewToken?: string | null
            zipCode?: string | null
            city?: string | null
            state?: string | null
            addressDetails?: string | null
            previewUrl?: string | null
            photos: Array<{ __typename?: 'App_LocationPhoto'; name?: string | null; url?: string | null } | null>
         } | null
      } | null> | null
   } | null
}

export const BookingLocationsDocument = gql`
   query BookingLocations {
      locations {
         totalCount
         pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
         }
         edges {
            node {
               id
               name
               email
               phone
               position
               onlineStatus
               description
               slug
               previewToken
               zipCode
               city
               state
               addressDetails
               previewUrl
               photos {
                  name
                  url
               }
            }
            cursor
         }
      }
   }
`

/**
 * __useBookingLocationsQuery__
 *
 * To run a query within a React component, call `useBookingLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingLocationsQuery(
   baseOptions?: Apollo.QueryHookOptions<BookingLocationsQuery, BookingLocationsQueryVariables>,
) {
   const options = { ...defaultOptions, ...baseOptions }
   return Apollo.useQuery<BookingLocationsQuery, BookingLocationsQueryVariables>(BookingLocationsDocument, options)
}
export function useBookingLocationsLazyQuery(
   baseOptions?: Apollo.LazyQueryHookOptions<BookingLocationsQuery, BookingLocationsQueryVariables>,
) {
   const options = { ...defaultOptions, ...baseOptions }
   return Apollo.useLazyQuery<BookingLocationsQuery, BookingLocationsQueryVariables>(BookingLocationsDocument, options)
}
export type BookingLocationsQueryHookResult = ReturnType<typeof useBookingLocationsQuery>
export type BookingLocationsLazyQueryHookResult = ReturnType<typeof useBookingLocationsLazyQuery>
export type BookingLocationsQueryResult = Apollo.QueryResult<BookingLocationsQuery, BookingLocationsQueryVariables>
