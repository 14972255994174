import { Button, Mutation, Text, withModal } from '@plandok/core'
import { message } from 'antd'
import React from 'react'

import * as api from '../../../../api'
import * as SC from '../styles'

type ChangeStripeAccountProps = {
   onClose: () => void
   modalProps: any
}

function RemoveStripeAccountModal(props: ChangeStripeAccountProps) {
   const onSuccess = () => {}

   const actionHandle = async () => {
      const response = await api.removePaymentType('stripe_payment')

      if (response?.error) {
         console.log(response.error?.message)
         message.error(response.error?.message)

         return
      }

      props.modalProps?.refresh()
      props.onClose()
   }

   return (
      <>
         <Text label="modal.remove.account.description" size="base" lh="xlarge" mb="xmedium" />
         <Mutation action={actionHandle} id={''} onSuccess={onSuccess}>
            {({ loading, action }: any) => (
               <SC.ChangeStripeBtnContainer>
                  <Button
                     disabled={loading}
                     label="btn.cancel"
                     onClick={props.onClose}
                     whiteBtn
                     upperCase={false}
                     semiBold
                  />
                  <Button
                     loading={loading}
                     label="btn.remove.account"
                     onClick={action}
                     upperCase={false}
                     danger
                     block
                  />
               </SC.ChangeStripeBtnContainer>
            )}
         </Mutation>
      </>
   )
}

export default withModal('modal.remove.account.title', { contentClassName: 'change-stripe-account-modal' })(
   RemoveStripeAccountModal,
)
