import React from 'react'
import { Spin } from 'antd'
import { getHasPermission } from '../../../helpers/permission'
import { useAppContext } from '../../../hooks/context/use-app-context'

export default function AuthBlock({ children, section }: any) {
   const [context] = useAppContext()

   if (!context) {
      return <Spin spinning>{children}</Spin>
   }

   const hasPermission = !section || getHasPermission(context, section)

   if (!hasPermission) {
      return null
   }

   return <>{children}</>
}
