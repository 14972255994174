import { mediaMobileDown } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Avatar } from 'antd'
import { format, isFuture, isToday } from 'date-fns'
import { getAvatarSign } from 'helpers/common'
import React, { memo } from 'react'
import styled, { css } from 'styled-components'

type DayColumnHeaderProps = {
   isActive: boolean
   isFuture: boolean
}

const DayColumnHeader = styled.div<DayColumnHeaderProps>`
   color: ${({ isActive, isFuture }) => (isActive ? '#13316D' : isFuture ? '#61749D' : '#ADB6CC')};
   display: flex;
   justify-content: center;
   user-select: none;
   div:first-of-type {
      font-size: 35px;
   }

   ${mediaMobileDown(css`
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div,
      div:first-of-type {
         text-align: center;
         height: 25px !important;
         min-height: 25px !important;
         max-height: 25px !important;
         font-size: 19px;
         &:last-of-type {
            font-size: 17px;
         }
      }
   `)}
`

const UserColumnHeader = styled.div`
   background: transparent;
   position: relative;
   top: 0;
   font-weight: 500;
   div {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.primaryColor};
   }

   [data-calendar-display-name] {
      margin-left: 5px;
      max-width: calc(100% - 50px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
   }
`

const Photo = styled.img`
   width: 40px;
   height: 40px;
   border-radius: 50%;
   :hover {
      cursor: pointer;
   }
`

const DAYS_CONFIG = {
   MON: 'Mon',
   TUE: 'Tue',
   WED: 'Wed',
   THU: 'Thu',
   FRI: 'Fri',
   SAT: 'Sat',
   SUN: 'Sun',
}

function CalendarHeader(props: any) {
   const dayText = props.info.toString()
   const { displayName } = props.info

   const isMonday = dayText.includes(DAYS_CONFIG.MON)
   const isTuesday = dayText.includes(DAYS_CONFIG.TUE)
   const isWednesday = dayText.includes(DAYS_CONFIG.WED)
   const isThursday = dayText.includes(DAYS_CONFIG.THU)
   const isFriday = dayText.includes(DAYS_CONFIG.FRI)
   const isSaturday = dayText.includes(DAYS_CONFIG.SAT)
   const isSunday = dayText.includes(DAYS_CONFIG.SUN)

   const translateWeekDays = () => {
      if (isMonday) return 'calendar.mon'
      if (isTuesday) return 'calendar.tue'
      if (isWednesday) return 'calendar.wed'
      if (isThursday) return 'calendar.thu'
      if (isFriday) return 'calendar.fri'
      if (isSaturday) return 'calendar.sat'
      if (isSunday) return 'calendar.sun'
   }

   if (props.modeType === 'week') {
      return (
         <DayColumnHeader isActive={isToday(props.info)} isFuture={isFuture(props.info)}>
            <div>{format(props.info, 'dd')}</div>
            <div className="capitalize">
               <IntlLabel label={translateWeekDays()} />
            </div>
         </DayColumnHeader>
      )
   }

   return (
      <UserColumnHeader>
         {props.info.photo ? (
            <div>
               <Photo src={props.info.photo} alt={displayName} />
               <span data-calendar-display-name>{displayName}</span>
            </div>
         ) : (
            <div>
               <Avatar size="large">{getAvatarSign(displayName)}</Avatar>
               <span data-calendar-display-name>{displayName}</span>
            </div>
         )}
      </UserColumnHeader>
   )
}

export default memo(CalendarHeader)
