import { RightOutlined } from '@ant-design/icons'
import { Button, useHistory, useLocation, useModal, withModal } from '@plandok/core'
import { AppointmentStatusBtn } from 'components'
import { RoutePath } from 'constants/routes'
import CardTooltip from 'pages/dashboard/CalendarPage/components/CardTooltip/index'
import calendarState from 'pages/dashboard/CalendarPage/store/calendar.state'
import React, { useCallback } from 'react'
import { useStore } from 'store/store'

import * as SC from '../styles'

function CalendarTooltipModal({ modalProps, onClose }: any) {
   const [, { showModal }]: any = useModal()
   const { navigate } = useLocation()
   const history = useHistory()

   const { appointmentStatus, appointmentId, recurrenceId } = modalProps.booking

   const setRescheduleItemId = useStore(state => state.setRescheduleItemId)

   const onClickViewDetails = useCallback(() => {
      onClose()
      navigate(`${RoutePath.APPOINTMENT_DETAILS}/${appointmentId}`)
   }, [onClose, navigate, appointmentId])

   const onClickEdit = useCallback(() => {
      onClose()
      navigate(RoutePath.APPOINTMENT_UPDATE, appointmentId)
   }, [onClose, navigate, appointmentId])

   const onClickReschedule = useCallback(() => {
      onClose()
      setRescheduleItemId(appointmentId)
      navigate(RoutePath.CALENDAR)
   }, [onClose, navigate, appointmentId, setRescheduleItemId])

   const onSuccess = () => {
      onClose()
      calendarState.fetchData()
      history.push(RoutePath.CALENDAR, { id: appointmentId, status: appointmentStatus })
   }

   const openAppointmentCancel = () => {
      onClose()
      showModal({
         type: 'CANCEL_APPOINTMENT',
         modalProps: {
            id: appointmentId,
            recurrenceId,
            onSuccess,
         },
      })
   }

   return (
      <SC.Flex direction="column" margin="-10px 0">
         <AppointmentStatusBtn status={appointmentStatus} id={appointmentId} onSuccess={onSuccess} />
         <CardTooltip
            hideStatus
            booking={modalProps.booking}
            isBlocked={modalProps.booking.isBlocked}
            timeFormat={modalProps.timeFormat}
         />
         <SC.ButtonContainer style={{ margin: '20px 0px' }}>
            <Button
               label="btn.viewDetails"
               block
               outlineBtn
               smallRadius
               type="primary"
               upperCase={false}
               onClick={onClickViewDetails}>
               <RightOutlined style={{ fontSize: '12px', marginLeft: '6px' }} />
            </Button>
            <Button
               label="btn.edit"
               block
               smallRadius
               type="primary"
               semiBold
               upperCase={false}
               onClick={onClickEdit}
            />
         </SC.ButtonContainer>
         <SC.ButtonContainer>
            <Button
               label="btn.reschedule"
               block
               ghost
               smallRadius
               type="default"
               semiBold
               upperCase={false}
               onClick={onClickReschedule}
            />
            <Button dangerTextBtn upperCase={false} label="btn.cancelAppointment" onClick={openAppointmentCancel} />
         </SC.ButtonContainer>
      </SC.Flex>
   )
}

export default withModal(null, { middle: true, contentClassName: 'card-tooltip-modal' })(CalendarTooltipModal)
