import { useNavigation } from '@plandok/core'
import * as api from 'api'
import { ModifyLayout, NoInternetConnection } from 'components'
import { RoutePath, StaffTabHash } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import StaffForm from './components/StaffForm'

const StaffFormWithApi = withFormApi({
   editAction: api.updateStaff,
   detailsAction: api.fetchStaffDetails,
   createAction: api.createStaff,
   backPath: `${RoutePath.STAFF}#${StaffTabHash.STAFF_MEMBERS}`,
   successMessage: 'Staff Created',
})(StaffForm)

export default function ModifyStaffPage() {
   const { params }: any = useNavigation()
   const isEdit = !!(params || {}).id

   return (
      <NoInternetConnection>
         <ModifyLayout title={isEdit ? 'title.editStaff' : 'title.newStaff'}>
            <StaffFormWithApi
               initialValues={{
                  appointmentColor: '#108EA8',
                  permissionLevel: 'no_access',
               }}
            />
         </ModifyLayout>
      </NoInternetConnection>
   )
}
