import { Button, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Menu } from 'antd'
import React from 'react'

interface DropdownMenuProps {
   options: { label: string; value: string }[]
   onItemSelect: (label: string) => void
}

export default function DropdownMenu({ options, onItemSelect, ...props }: DropdownMenuProps) {
   return (
      <Menu className="app-common-dropdown-with-buttons" {...props}>
         {options.map(({ label }) => (
            <Button key={label} onClick={() => onItemSelect(label)}>
               <Text size="small">
                  <IntlLabel label={label} />
               </Text>
            </Button>
         ))}
      </Menu>
   )
}
