import { addedClientdateOption, Button, Field, Form, Text, validateRequired, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Divider, Radio } from 'antd'
import * as api from 'api'
import { flow, noop } from 'lodash'
import React, { useState } from 'react'

import * as SC from '../styles'

function CreateClientByAgeModal(props: any) {
   const [clientAgeType, setClientAgeType] = useState(0)
   const { groupName, updateGender } = props.modalProps
   const clientByAgeGroupData = [
      {
         text: 'Younger than',
         dropSelect: [
            {
               value: 60,
               text: 'years',
               name: 'younger',
            },
         ],
      },
      {
         text: 'Older than',
         dropSelect: [
            {
               value: 30,
               text: 'years',
               name: 'older',
            },
         ],
      },
      {
         text: 'Age between ',
         dropSelect: [
            {
               value: 20,
               text: 'and',
               name: 'from',
            },
            {
               value: 45,
               text: 'years',
               name: 'to',
            },
         ],
      },
   ]

   const clientAge = () => {
      updateGender(groupName, clientByAgeGroupData[1].dropSelect[clientAgeType].value)
      props.onClose()
   }

   return (
      <Form
         onSubmit={(form: any) => api.closeAppointment(props.modalProps.Number, form)}
         onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}>
         {({ submitting }: any) => (
            <SC.StyledAgeContainer>
               {clientByAgeGroupData &&
                  clientByAgeGroupData.map((item, index) => (
                     <SC.Container key={index} background={clientAgeType === index}>
                        <Radio onClick={() => setClientAgeType(index)} checked={clientAgeType === index}>
                           <IntlLabel label={item.text} />
                        </Radio>
                        <SC.ToContainer>
                           {item.dropSelect.map((select, k) => (
                              <SC.DateContainer key={k}>
                                 <Field.Select
                                    label="&nbsp;"
                                    name={select.name}
                                    options={addedClientdateOption}
                                    validate={validateRequired}
                                    defaultValue={select.value}
                                 />
                                 <Text weight="normal" mb="none" size="base" ml="xxsmall">
                                    <IntlLabel label={select.text} />
                                 </Text>
                              </SC.DateContainer>
                           ))}
                        </SC.ToContainer>
                     </SC.Container>
                  ))}
               <SC.Divider as={Divider} />
               <Button type="primary" label="btn.save" onClick={clientAge} loading={submitting} upperCase={false} />
            </SC.StyledAgeContainer>
         )}
      </Form>
   )
}

export default withModal('Client by Age', { contentClassName: 'age-modal-content' })(CreateClientByAgeModal)
