export const fontFamily = "'Work Sans', sans-serif"
export const borderRadiusBase = '10px'

export const primaryColor = '#14316D'
export const infoColor = '#6ce1f7'
export const minorColor = '#ADB6CC'
export const linkColor = '#4395D4'
export const linkHoverDecoration = 'underline'

export const dangerColor = '#dc3545'
export const labelColor = primaryColor
export const normalColor = '#13316D'

export const fontSizeBase = '16px'
export const fontSizeLg = '18px'
export const fontSizeXlg = '28px'
export const fontSizeSm = '14px'

export const borderColorBase = '#ADB6CC'

export const formItemMarginBottom = '19px'

export const disabledBg = '#F8F9FC'

export const layoutBodyBackground = '#F8F9FC'

export const avatarColor = primaryColor
export const avatarBg = '#E9ECF3'

export const boxBorderDecoration = '1px solid #e9e9e9'
export const boxShadow = '0 3px 10px rgba(19, 49, 109, 0.10) !important'
