import { IntlLabel, LabelKey } from '@plandok/i18n'
import React from 'react'

export default function FormTooltipList({ tooltip }: { tooltip: { key: LabelKey; style: any }[] }): React.ReactNode {
   if (!tooltip) {
      return null
   }

   return tooltip.map(e => (
      <div key={e.key} style={e.style}>
         <IntlLabel label={e.key} />
      </div>
   ))
}
