import './styles/index.less'

import { SearchOutlined } from '@ant-design/icons'
import { AuthBlock, Button, SelectInput, TextInput } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel, useIntlOptions, withIntlLabel } from '@plandok/i18n'
import { PageControlContainer } from 'components'
import { PermissionType } from 'constants/auth'
import { clientOptions } from 'constants/data'
import { RoutePath } from 'constants/routes'
import React, { useState } from 'react'

import ClientOption from './components/ClientOption'
import MobileClientsFilter from './components/MobileClientsFilter'

const SearchInput = withIntlLabel({ propKey: 'placeholder' })(TextInput)

export default function ClientFilter({ setQuery, query }: any) {
   const { convertOptions } = useIntlOptions()
   const { navigate } = useLocation()

   const [option, setOption] = useState(<IntlLabel label="btn.options" />)

   const navigateToClients = () => navigate(RoutePath.CLIENT_CREATE)

   return (
      <>
         <PageControlContainer mobileHidden>
            <PageControlContainer.Item>
               <SearchInput
                  suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                  search
                  placeholder="input.clientSearch.placeholder"
                  value={query}
                  onChange={({ target: { value } }: any) => setQuery(value)}
                  style={{ width: '320px' }}
               />
            </PageControlContainer.Item>
            <PageControlContainer.Item mobileHidden gap={16}>
               <PageControlContainer.Space />
               <SelectInput
                  options={convertOptions(clientOptions)}
                  value={option}
                  onChange={setOption}
                  dropdownClassName="client-options-dropdown"
                  optionRender={ClientOption}
                  style={{ width: '150px' }}
                  showMenuOnOptionClick
               />
               <AuthBlock section={PermissionType.CLIENTS}>
                  <Button label="client.create.title" type="primary" onClick={navigateToClients} minorBtn />
               </AuthBlock>
            </PageControlContainer.Item>
         </PageControlContainer>
         <MobileClientsFilter value={query} onChange={({ target: { value } }: any) => setQuery(value)} />
      </>
   )
}
