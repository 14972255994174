import { AuthBlock, getIsMobile, Link, Text } from '@plandok/core'
import { LabelKey } from '@plandok/i18n'
import { PermissionType } from 'constants/auth'
import React from 'react'

import * as SC from '../styles'

type Element = {
   path: string
   mobilePath?: string
   titleKey: LabelKey
   descriptionKey: LabelKey
   permission?: PermissionType
   premiumFeatureOnly: boolean
}

type SettingCardProps = {
   title: LabelKey
   elements: Element[]
   permission?: PermissionType
   account?: boolean
}

export default function SettingCard({ title, elements, permission, account }: SettingCardProps) {
   const isMobile = getIsMobile()

   return (
      <AuthBlock section={permission}>
         <SC.SettingCard theme={{ account }}>
            <Text
               label={title}
               upperCase
               weight={isMobile ? 'semiBold' : 'bold'}
               mb={isMobile ? 'xsmall' : 'small'}
               size={isMobile ? 'medium' : 'large'}
            />
            {elements.map(({ titleKey, descriptionKey, path, mobilePath, permission }, index) => (
               <AuthBlock key={titleKey} section={permission}>
                  <SC.ElementItem isLastItem={index === elements.length - 1}>
                     <Link label={titleKey} to={isMobile ? mobilePath || path : path} />
                     <Text label={descriptionKey} size="base" color="#61749D" mb="none" style={{ marginTop: 5 }} />
                  </SC.ElementItem>
               </AuthBlock>
            ))}
         </SC.SettingCard>
      </AuthBlock>
   )
}
