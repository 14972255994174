import { LoadingOutlined } from '@ant-design/icons'
import { Row, Spin } from 'antd'
import { InfiniteScroll } from 'infinite-scroller-react'
import React from 'react'

import * as SC from './styles'

interface MobileTableProps {
   rowComponent: any
   page: number
   viewDetails?: any
   className?: string
   isLoading: boolean
   fetchData: any
   hasMore: boolean
   data: any[]
   mobileColumns?: any[]
}

export default function MobileTable({ rowComponent: RowComponent, ...props }: MobileTableProps) {
   return (
      <Spin spinning={props.isLoading}>
         <SC.Container className={props.className}>
            <InfiniteScroll
               initialLoad={false}
               loadMore={() => props.fetchData(props.page ? props.page : props.page + 1)}
               hasMore={props.hasMore}
               loader={
                  <SC.SpinnerContainer key="table-spinner">
                     <LoadingOutlined />
                  </SC.SpinnerContainer>
               }>
               <Row justify="space-between" align="middle" className="w-100">
                  <SC.StyledRowContainer>
                     {props.mobileColumns?.map(({ key, title, width }) => (
                        <div key={key} style={{ padding: '15px', maxWidth: width, minWidth: width }}>
                           {title}
                        </div>
                     ))}
                  </SC.StyledRowContainer>
               </Row>

               {props.data?.map((e: any) => (
                  <SC.Item onClick={() => props?.viewDetails?.(e.id, e)} key={`l-${e.id}`}>
                     <RowComponent value={e} />
                  </SC.Item>
               ))}
            </InfiniteScroll>
         </SC.Container>
      </Spin>
   )
}
