import { GlobalOutlined, LogoutOutlined, RightOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons'
import { Button, useAppContext, useModal, useNavigation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Avatar, Row } from 'antd'
import { NotReleased, SidebarIcons } from 'components'
import { sidebarConfig } from 'config/sidebar-config'
import { RoutePath } from 'constants/routes'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useStore } from 'store/store'

import MobileSupportBtn from './MobileSupportBtn'
import * as SC from './styles'

export default function MobileMenu() {
   const state = useStore()
   const [, { showModal }] = useModal()
   const { navigate } = useNavigation()
   const [context] = useAppContext()

   const changeLanguage = () => showModal({ type: 'LANGUAGE_SELECT' })
   const onLogout = () => navigate(RoutePath.LOGOUT)
   const goToCalendarPage = () => {
      localStorage.setItem('isHiddenNewVersionNotification1', 'false')
      navigate(RoutePath.CALENDAR)
   }

   return (
      <SC.MobileMenuContainer>
         <SC.Header>
            <Link to={RoutePath.SEARCH}>
               <SearchOutlined />
            </Link>
         </SC.Header>
         <Row>
            <SC.ManageUser as={Link} to={RoutePath.PERSONAL_SETTINGS}>
               <span data-user>
                  <div data-user-avatar>
                     <Avatar size="large" icon={<UserOutlined />} />
                  </div>
                  <div data-manage-user-info>
                     <div data-manage-user-title>{context.displayName}</div>
                     <div>
                        <IntlLabel label="text.manageProfile" />
                     </div>
                  </div>
               </span>
               <RightOutlined data-menu-right-icon />
            </SC.ManageUser>
            {sidebarConfig.map(sidebarItem => (
               <Fragment key={sidebarItem.title}>
                  {sidebarItem.notReleased ? (
                     <NotReleased>
                        <SC.Item>
                           <Link to={sidebarItem?.mobilePath ?? sidebarItem.path}>
                              <span>
                                 <i>{(SidebarIcons as any)[sidebarItem.iconType as any]}</i>
                                 <IntlLabel label={sidebarItem.title} />
                              </span>
                              <RightOutlined />
                           </Link>
                        </SC.Item>
                     </NotReleased>
                  ) : (
                     <SC.Item>
                        <Link to={sidebarItem?.mobilePath ?? sidebarItem.path}>
                           <span>
                              <i>{(SidebarIcons as any)[sidebarItem.iconType as any]}</i>
                              <IntlLabel label={sidebarItem.title} />
                           </span>
                           <RightOutlined />
                        </Link>
                     </SC.Item>
                  )}
               </Fragment>
            ))}
            <MobileSupportBtn />
            <SC.SpaceItem>
               {!context.subscription.isPremium && (
                  <Button
                     type="default"
                     label="btn.upgrade"
                     data-pricing-link
                     className="mr-4"
                     onClick={() => navigate(RoutePath.BILLING)}
                  />
               )}
            </SC.SpaceItem>
            <SC.Item onClick={changeLanguage}>
               <a href="javascript:void(0)">
                  <span>
                     <GlobalOutlined />
                     <IntlLabel label="sidebar.language" />
                  </span>
                  <RightOutlined />
               </a>
            </SC.Item>
            <SC.Item>
               <a href="javascript:void(0)" onClick={onLogout}>
                  <span>
                     <LogoutOutlined />
                     <IntlLabel label="sidebar.logout" />
                  </span>
                  <RightOutlined />
               </a>
            </SC.Item>
            {state.systems.displayUpdateAlert && (
               <SC.UpdateContainer onClick={goToCalendarPage}>
                  <Button lightBtn label="app.updateAvailable.button.title" upperCase={false} semiBold />
               </SC.UpdateContainer>
            )}
         </Row>
      </SC.MobileMenuContainer>
   )
}
