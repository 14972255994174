import { Button } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React from 'react'

import * as SC from '../styles'

interface BookingLinkCardProps {
   title: string
   description: string
   buttonText: string
   icon: any
   onClick: () => void
}

export const BookingLinkCard = ({ title, description, buttonText, icon, onClick }: BookingLinkCardProps) => (
   <SC.Card>
      <h2>
         <IntlLabel label={title} />
      </h2>
      <img src={icon} alt={title} />
      <p>
         <IntlLabel label={description} />
      </p>
      <Button type="primary" label={buttonText} onClick={onClick} />
   </SC.Card>
)
