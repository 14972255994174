import './style.less'

import { getIsMobile } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import cn from 'classnames'
import { AppLayout, HashTabs, MobileBackButton, NoInternetConnection } from 'components'
import { OnlineBookingHash } from 'constants/routes'
import React from 'react'

import BookingAnalytics from './BookingAnalytics'
import BookingLinks from './BookingLinks'
import BookingOverview from './BookingOverview'
import BookingPayments from './BookingPayments/BookingPayments'
import BookingProfile from './BookingProfile'
import BookingSettings from './BookingSettings'
import { bookingsTabsConfig } from './config'

const MobileHeader = () => (
   <>
      <MobileBackButton />
      <div>
         <IntlLabel label="ob.title" />
      </div>
      <div />
   </>
)

export default function BookingsPage() {
   const isMobile = getIsMobile()

   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="ob.title"
            mobileHeader={MobileHeader}
            className={cn('booking-page', { 'booking-page__is-mobile': isMobile })}>
            <HashTabs tabs={bookingsTabsConfig} defaultTab={OnlineBookingHash.OVERVIEW}>
               <BookingOverview />
               <BookingProfile />
               <BookingLinks />
               <BookingAnalytics />
               <BookingSettings />
               <BookingPayments />
            </HashTabs>
         </AppLayout>
      </NoInternetConnection>
   )
}
