import { Text } from '@plandok/core'
import AppLayout from 'components/app/AppLayout'
import styled, { css } from 'styled-components'

type LinkContainerProps = {
   spaceBellow?: boolean
}

export const Block = styled.div`
   height: 50px;
`

export const AppLayoutForHelpPage = styled(AppLayout)`
   .ant-layout-content {
      display: flex;
      align-items: center;
      justify-content: center;
   }
`

export const PageContainer = styled.div`
   min-width: 311px;
`

export const HelpTitle = styled(Text)`
   line-height: 33px;
   margin-bottom: 56px;
   font-size: 28px;
   text-align: center;
`

export const LiveChatContainer = styled.div`
   display: flex;
   margin-bottom: 40px;
   div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      span {
         margin-left: 16px;
         max-width: 206px;
         div:first-of-type {
            color: #26385b;
            line-height: 23px;
            margin-bottom: 4px;
         }
         div:last-of-type {
            color: #61749d;
            line-height: 19px;
         }
      }
   }
   :hover {
      cursor: pointer;
   }
`

export const LinkContainer = styled.a<LinkContainerProps>`
   display: flex;
   div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      span {
         margin-left: 16px;
         max-width: 206px;
         div:first-of-type {
            color: #26385b;
            line-height: 23px;
            margin-bottom: 4px;
         }
         div:last-of-type {
            color: #61749d;
            line-height: 19px;
         }
      }
   }
   :hover {
      text-decoration: none;
   }

   ${({ spaceBellow }) =>
      spaceBellow &&
      css`
         margin-bottom: 40px;
      `}
`
