import { CustomIcon, getIsMobile, Text, useAppContext, useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Col, Popover, Row } from 'antd'
import cn from 'classnames'
import { AppLayout, AppTable, BackButton, NoInternetConnection } from 'components'
import { AnalyticsHash, RoutePath } from 'constants/routes'
import { exportTableDataToCSV, timeRangeOptions } from 'helpers/reports'
import React, { useState } from 'react'
import * as SC from 'styles/reports/index'

import DatePicker from '../AnalyticsPage/AnalyticsDashboard/components/DatePicker/DatePicker'
import { AnalyticsErrorState } from '../AnalyticsPage/components/AnalyticsErrorsState'
import { AnalyticsMobileHeader } from '../AnalyticsPage/components/AnalyticsMobileHeader'
import { analyticsTabsConfig } from '../AnalyticsPage/config'
import { useQueryReports } from '../common/hooks/useQueryReports'
import { clientsColumns } from './config'

export default function ReportsClientsPage() {
   const [isPopoverVisible, setIsPopoverVisible] = useState(false)
   const [isStepByDay, setIsStepByDay] = useState(false)
   const isMobile = getIsMobile()
   const { goBack } = useLocation()

   const [context] = useAppContext()
   const isPremium = context.subscription?.isPremium

   const { data, loading, refetchData, loadMore, error, setTimeRangeData, from, to } = useQueryReports('clientsReport')

   const errors = error

   if (errors) {
      return AnalyticsErrorState(errors)
   }

   const handlePopoverVisibleChange = (isVisible: boolean) => setIsPopoverVisible(isVisible)

   const exportCSVByClosingPopover = (type: string, from: any, to: any) => {
      exportTableDataToCSV(type, from, to)
      setIsPopoverVisible(false)
   }

   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="analytics.title"
            headerIconTitle="analytics.premium.title"
            mobileHeader={() => AnalyticsMobileHeader('reports.appointments.title')}
            mobileBackground="#fff"
            mobileWhiteBg={true}
            className={cn('analytics-page')}>
            <SC.HashTabsWrapper
               className="analytics-tabs"
               tabs={analyticsTabsConfig}
               defaultTab={AnalyticsHash.REPORTS}
               rootPath={RoutePath.ANALYTICS}>
               <div />
               <AppLayout.Container maxWidth={'inherit'} noMargin noWhiteBG>
                  {!isMobile && (
                     <>
                        <div style={{ maxWidth: '20rem' }}>
                           <BackButton onClick={goBack} />
                        </div>

                        <Text size="mlarge" label="reports.clients.title" Type="base" bold />
                     </>
                  )}
                  <Row align="middle" className="w-auto mb-2 justify-between" gutter={16}>
                     <Col span={isMobile ? 24 : 8}>
                        <DatePicker
                           hasCustomPlacement
                           isPremium={isPremium}
                           timeRangeOptions={timeRangeOptions(isPremium)}
                           isStepByDay={isStepByDay}
                           refetchData={refetchData}
                           className="custom-react-datepicker-wrapper"
                           setTimeRangeData={setTimeRangeData}
                           setIsStepByDay={setIsStepByDay}
                        />
                     </Col>
                     <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Popover
                           content={
                              <SC.PopoverContentRow>
                                 <Text
                                    size="base"
                                    colorType="base"
                                    mb="none"
                                    onClick={() => exportCSVByClosingPopover('clients', from, to)}>
                                    <IntlLabel label="reports.clients.title" />
                                 </Text>
                              </SC.PopoverContentRow>
                           }
                           placement="bottomLeft"
                           trigger="click"
                           open={isPopoverVisible}
                           onOpenChange={handlePopoverVisibleChange}
                           overlayClassName={isMobile ? 'custom-mobile-popover' : 'custom-desktop-popover'}>
                           <SC.ExportCSVButton ghost={!isMobile} upperCase={false} minorBtn>
                              {!isMobile && (
                                 <Text size="base" colorType="base" label="reports.exportCsv.title" mb="none" />
                              )}
                              <CustomIcon type="csv" />
                           </SC.ExportCSVButton>
                        </Popover>
                     </Col>
                  </Row>
                  <AppTable
                     type="clientsReport"
                     columns={clientsColumns}
                     className="reports-table"
                     paginationSpacing="15px 0"
                     isCustomPagination
                     loading={loading}
                     loadMore={loadMore}
                     hidePageSwitcher={false}
                     apiConfig={{
                        fetchData: () => data?.reports,
                        mapping: {
                           pageKey: 'page',
                           dataKey: 'clientsReport',
                           totalCountKey: 'clientsReport.totalCount',
                           searchKey: 'query',
                           emptyStateKey: 'clientsReport.emptyState',
                        },
                     }}
                  />
               </AppLayout.Container>
            </SC.HashTabsWrapper>
         </AppLayout>
      </NoInternetConnection>
   )
}
