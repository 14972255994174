import { SelectLanguageModal } from '@plandok/core'
import GoogleTagModal from 'components/app/Modal/modals/GoogleTagModal'
import RemoveStripeAccountModal from 'components/app/Modal/modals/RemoveStripeAccountModal'

import AddAppointmentServiceModal from '../modals/AddAppointmentServiceModal'
import BlockTimeModal from '../modals/BlockTimeModal'
import CalendarTooltipModal from '../modals/CalendarTooltipModal'
import CampaignActivationConfirmModal from '../modals/CampaignActivationConfirmModal'
import CampaignUpgradeModal from '../modals/CampaignUpgradeModal'
import CancelAppointmentModal from '../modals/CancelAppointmentModal'
import CancelSubscriptionErrorModal from '../modals/CancelSubscriptionErrorModal'
import ChangeStripeAccountModal from '../modals/ChangeStripeAccountModal'
import ClientDetailsMenuModal from '../modals/ClientDetailsMenuModal'
import ConfirmClientDeleteModal from '../modals/ConfirmClientDeleteModal'
import ConfirmMergeDuplicatedClientsModal from '../modals/ConfirmMergeDuplicatedClientsModal'
import ConfirmModal from '../modals/ConfirmModal'
import ConfirmUnsubscribeModal from '../modals/ConfirmUnsubscribeModal'
import CreateCancellationModal from '../modals/CreateCancellationModal'
import CreateCategoryModal from '../modals/CreateCategoryModal'
import CreateClientByAgeModal from '../modals/CreateClientByAgeModal'
import CreateClientByGenderModal from '../modals/CreateClientByGenderModal'
import CreateDiscountModal from '../modals/CreateDiscountModal'
import CreateLocationModal from '../modals/CreateLocationModal'
import CreateNewClientModal from '../modals/CreateNewClientModal'
import CreateRecentClientModal from '../modals/CreateRecentClientModal'
import CreateRefSource from '../modals/CreateRefSource'
import CreateResourceModal from '../modals/CreateResourceModal'
import ConfirmShiftsModify from '../modals/CreateStaffHourModal/ConfirmShiftsModify'
import CreateStaffHourModal from '../modals/CreateStaffHourModal/CreateStaffHourModal'
import DeleteAccountModal from '../modals/DeleteAccountModal'
import DeleteConfirmModal from '../modals/DeleteConfirmModal'
import DeletePaymentMethodModal from '../modals/DeletePaymentMethodModal'
import DeleteReminderModal from '../modals/DeleteReminderModal'
import DeleteStaffHourModal from '../modals/DeleteStaffHourModal'
import DirectLinkForBookingsModal from '../modals/DirectLinkForBookingsModal'
import EditClientSelectionModal from '../modals/EditClientSelectionModal'
import EditServiceSelectionModal from '../modals/EditServiceSelectionModal'
import ExtraCostsForNewMemberModal from '../modals/ExtraCostsForNewMemberModal'
import FacebookLinkForBookingsModal from '../modals/FacebookLinkForBookingsModal'
import FacebookPixelModal from '../modals/FacebookPixelModal'
import GoogleAnalyticsModal from '../modals/GoogleAnalyticsModal'
import HelpModal from '../modals/HelpModal'
import ImportClientsModal from '../modals/ImportClientsModal'
import LatestPaymentFailedModal from '../modals/LatestPaymentFailedModal'
import MergeDuplicatedClientsModal from '../modals/MergeDuplicateClientsModal/MergeDuplicatedClientsModal'
import PaymentConfirmationModal from '../modals/PaymentConfirmationModal'
import PaymentErrorModal from '../modals/PaymentErrorModal'
import PremiumDeactivatedModal from '../modals/PremiumDeactivatedModal'
import PremiumFeatureModal from '../modals/PremiumFeatureModal'
import PremiumUpgradeRequiredModal from '../modals/PremiumUpgradeRequiredModal'
import PreviewMessageModal from '../modals/PreviewMessageModal'
import PricingFeatureModal from '../modals/PricingFeatureModal'
import PricingModal from '../modals/PricingModal'
import RepeatAppointmentModal from '../modals/RepeatAppointmentModal'
import ResetCampaignModal from '../modals/ResetCampaignModal'
import SendTestMessageForEmailModal from '../modals/SendTestMessageForEmailModal'
import SendTestMessageForNumberModal from '../modals/SendTestMessageForNumberModal'
import UpdateAppointmentModal from '../modals/UpdateAppointmentModal'
import UpgradeToPremiumModal from '../modals/UpgradeToPremiumModal'
import ViewClosedDateModal from '../modals/ViewClosedDateModal'
import ViewMessageModal from '../modals/ViewMessageModal'

export const ModalMap = {
   CONFIRM_DELETE: DeleteConfirmModal,
   LANGUAGE_SELECT: SelectLanguageModal,
   VIEW_MESSAGE: ViewMessageModal,
   CANCEL_APPOINTMENT: CancelAppointmentModal,
   CONFIRM_DELETE_CLIENT: ConfirmClientDeleteModal,
   CREATE_CATEGORY: CreateCategoryModal,
   CREATE_LOCATION: CreateLocationModal,
   CREATE_RESOURCE: CreateResourceModal,
   CREATE_REF_SOURCE: CreateRefSource,
   CREATE_CANCEL_REASON: CreateCancellationModal,
   CREATE_DISCOUNT: CreateDiscountModal,
   VIEW_CLOSED_DATE: ViewClosedDateModal,
   DELETE_STAFF_HOURS: DeleteStaffHourModal,
   CREATE_STAFF_HOURS: CreateStaffHourModal,
   CLIENT_DETAILS_MENU: ClientDetailsMenuModal,
   CREATE_BLOCK_TIME: BlockTimeModal,
   CONFIRM_ACTION: ConfirmModal,
   CONFIRM_UNSUBSCRIBE: ConfirmUnsubscribeModal,
   CONFIRM_WORK_TIME_MODIFY: ConfirmShiftsModify,
   VIEW_DIRECT_LINK_MODAL: DirectLinkForBookingsModal,
   VIEW_FACEBOOK_LINK_MODAL: FacebookLinkForBookingsModal,
   VIEW_GOOGLE_ANALYTICS_MODAL: GoogleAnalyticsModal,
   VIEW_FACEBOOK_PIXEL_MODAL: FacebookPixelModal,
   VIEW_GOOGLE_TAG_MODAL: GoogleTagModal,
   ADD_APPOINTMENT_SERVICE_MODAL: AddAppointmentServiceModal,
   SEND_TEST_MESSAGE_FOR_NUMBER: SendTestMessageForNumberModal,
   SEND_TEST_MESSAGE_FOR_EMAIL: SendTestMessageForEmailModal,
   CREATE_NEW_CLIENT_MODAL: CreateNewClientModal,
   CREATE_RECENT_CLIENT_MODAL: CreateRecentClientModal,
   CREATE_CLIENT_BY_AGE_MODAL: CreateClientByAgeModal,
   CREATE_CLIENT_BY_GENDER_MODAL: CreateClientByGenderModal,
   EDIT_CLIENT_SELECTION: EditClientSelectionModal,
   PAYMENT_CONFIRMATION_MODAL: PaymentConfirmationModal,
   RESET_CAMPAIGN_MODAL: ResetCampaignModal,
   PRICING_MODAL: PricingModal,
   PRICING_FEATURE_MODAL: PricingFeatureModal,
   PREVIEW_MESSAGE_MODAL: PreviewMessageModal,
   REPEAT_APPOINTMENT_MODAL: RepeatAppointmentModal,
   UPGRADE_TO_PREMIUM_MODAL: UpgradeToPremiumModal,
   CAMPAIGN_ACTIVATION_CONFIRM_MODAL: CampaignActivationConfirmModal,
   EDIT_SERVICE_SELECTION_MODAL: EditServiceSelectionModal,
   CAMPAIGN_UPGRADE_MODAL: CampaignUpgradeModal,
   MERGE_DUPLICATED_CLIENTS: MergeDuplicatedClientsModal,
   CONFIRM_MERGE_DUPLICATED_CLIENTS: ConfirmMergeDuplicatedClientsModal,
   CALENDAR_TOOLTIP_MODAL: CalendarTooltipModal,
   IMPORT_CLIENTS: ImportClientsModal,
   PREMIUM_FEATURE_MODAL: PremiumFeatureModal,
   DELETE_REMINDER: DeleteReminderModal,
   CANCEL_SUBSCRIPTION_ERROR: CancelSubscriptionErrorModal,
   LATEST_PAYMENT_FAILED: LatestPaymentFailedModal,
   EXTRA_COSTS_FOR_NEW_MEMBER: ExtraCostsForNewMemberModal,
   PREMIUM_UPGRADE_REQUIRED: PremiumUpgradeRequiredModal,
   HELP_MODAL: HelpModal,
   DELETE_ACCOUNT_MODAL: DeleteAccountModal,
   UPDATE_APPOINTMENT: UpdateAppointmentModal,
   DELETE_PAYMENT_METHOD_MODAL: DeletePaymentMethodModal,
   PAYMENT_ERROR_MODAL: PaymentErrorModal,
   PREMIUM_DEACTIVATED_MODAL: PremiumDeactivatedModal,
   CHANGE_STRIPE_ACCOUNT_MODAL: ChangeStripeAccountModal,
   REMOVE_STRIPE_ACCOUNT_MODAL: RemoveStripeAccountModal,
}
