import Icon from '@ant-design/icons'
import { Button, getIsMobile, useModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import * as api from 'api'
import { AppTable } from 'components'
import React, { useState } from 'react'

import CampaignCard from './CampaignsCard'
import MobileMarketingCampaigns from './components/MobileMarketingCampaigns'
import { campaignsCardData, messageLogColumns } from './config'
import * as SC from './styles'

export default function MarketingCampaigns() {
   const [buttonTab, setButtonTab] = useState('campaigns')
   const [switchBtn, setSwitchBtn] = useState(false)
   const isMobile = getIsMobile()
   const [, { showModal }] = useModal()

   const onChange = (checked: boolean) => {
      setSwitchBtn(checked)
   }

   const onPricing = () => {
      showModal({
         type: 'PRICING_MODAL',
         modalProps: { String },
      })
   }

   return (
      <>
         {isMobile ? (
            <MobileMarketingCampaigns buttonTab={buttonTab} setButtonTab={setButtonTab} />
         ) : (
            <>
               <SC.Container>
                  <SC.SPlaceholderTitle>
                     <IntlLabel label="Auto-sending Campaigns" />
                  </SC.SPlaceholderTitle>
                  <SC.SPlaceholderDescription>
                     <IntlLabel label="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt magna posuere vulputate convallis viverra blandit eget ut sit. Urna scelerisque metus." />
                  </SC.SPlaceholderDescription>
                  <SC.CampaignsSwitchBtns>
                     <Button
                        label="Campaigns"
                        upperCase={false}
                        ghost={buttonTab !== 'campaigns'}
                        onClick={() => setButtonTab('campaigns')}
                        type="primary"
                     />
                     <Button
                        label="Message log"
                        upperCase={false}
                        ghost={buttonTab !== 'message'}
                        onClick={() => setButtonTab('message')}
                        type="primary"
                     />
                  </SC.CampaignsSwitchBtns>
                  {buttonTab === 'campaigns' && (
                     <SC.CampaignsCardGroup>
                        {campaignsCardData?.map((item, index) => (
                           <CampaignCard
                              key={index}
                              url={item.url}
                              headerText={item.headerText}
                              icon={item.icon}
                              description={item.description}
                              switchButton={switchBtn}
                              onChange={onChange}
                           />
                        ))}
                     </SC.CampaignsCardGroup>
                  )}
               </SC.Container>
               {buttonTab === 'message' && (
                  <SC.MessageLog>
                     <AppTable
                        type="message"
                        columns={messageLogColumns}
                        mobileClassName="app_clients-list--mobile"
                        apiConfig={{
                           fetchData: api.fetchClients,
                           mapping: {
                              pageKey: 'page[offset]',
                              maxCountKey: 'page[limit]',
                              dataKey: 'massMarketingCampaign',
                              totalCountKey: 'messageLog.totalCount',
                              searchKey: 'query',
                              emptyStateKey: 'messageLog.emptyState',
                           },
                        }}
                     />
                  </SC.MessageLog>
               )}
            </>
         )}
         {buttonTab === 'campaigns' && (
            <SC.Pricing onClick={onPricing}>
               <Icon type="exclamation-circle" /> <IntlLabel label="Pricing" />
            </SC.Pricing>
         )}
      </>
   )
}
