import { CustomIcon, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React from 'react'

import { getColorType, getCustomIconByPercent } from '../../../../../../helpers/common'
import * as SC from '../TotalAppointments/styles'

type OccupancyHeaderProps = {
   percentDiff: number
   totalPercent?: number | null
}

export const OccupancyHeader = ({ totalPercent, percentDiff }: OccupancyHeaderProps) => (
   <>
      <Text size="mlarge" weight="bold" colorType="base" mb="xsmall">
         <IntlLabel label="analytics.occupancy.title" />
      </Text>
      <SC.TotalCount>
         <Text size="xxxlarge" weight="medium" colorType="base">
            {totalPercent}%
         </Text>
         <CustomIcon type={getCustomIconByPercent(percentDiff)} />
         <Text size="base" weight="semiBold" colorType={getColorType(percentDiff)} ml="xxsmall">
            {percentDiff}% &nbsp;
         </Text>
         <Text size="base" weight="semiBold" colorType={getColorType(percentDiff)}>
            <IntlLabel label="analytics.lastPeriod.title" />
         </Text>
      </SC.TotalCount>
   </>
)

export default OccupancyHeader
