import Icon from '@ant-design/icons'
import { Button, CustomIcon, Form, getIsMobile, Text, useLocation, useModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Collapse } from 'antd'
import { ModifyMassLayout } from 'components'
import flow from 'lodash/flow'
import noop from 'lodash/noop'
import React from 'react'

import * as SC from '../styles'

const messagePricingData = [
   {
      id: 'sms_id',
      kind: 'dropdown.notificationBy.sms',
      cost: '0.08€ (May vary based on country)',
   },
   {
      id: 'email_id',
      kind: 'dropdown.notificationBy.email',
      cost: '0.017€',
   },
]

export default function StartCampaignForm(props: any) {
   const isMobile = getIsMobile()
   const { goBack } = useLocation()
   const { Panel } = Collapse
   const [, { showModal }] = useModal()

   const startCampaign = () => {
      showModal({
         type: 'CAMPAIGN_ACTIVATION_CONFIRM_MODAL',
         modalProps: {
            ...props,
         },
      })
   }
   const previewMessage = () => {
      showModal({
         type: 'PREVIEW_MESSAGE_MODAL',
         modalProps: {
            ...props,
         },
      })
   }

   const onPricing = () => {
      showModal({
         type: 'PRICING_MODAL',
         modalProps: { String },
      })
   }

   const handleSubmit = (form: any) =>
      props.onSubmit({
         ...form,
         price: form.price ?? 0,
      })

   return (
      <>
         <Form
            onSubmit={handleSubmit}
            onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
            initialValues={props.initialValues}>
            {({ formError, submitting }: any) => {
               return (
                  <>
                     <SC.CardContainer>
                        <SC.HeaderText>
                           <CustomIcon type="present" />
                           <Text size={isMobile ? 'medium' : 'mlarge'} weight="semiBold" mb="small">
                              <IntlLabel label="Almost done!" />
                           </Text>
                           <Text size={isMobile ? 'small' : 'base'}>
                              <IntlLabel label="Your smart campaign is ready to go, once enabled it will automatially send messages to clients on an ongoing basis. You can easily modify or pause this campaign at any time." />
                           </Text>
                        </SC.HeaderText>
                        <SC.Text onClick={previewMessage}>
                           <Icon type="eye" />
                           <Text label="Preview " size="base" weight="medium" mb="none" ml="xsmall" />
                        </SC.Text>
                        <SC.PricingContainer>
                           <Collapse defaultActiveKey={['1']}>
                              <Panel header="Pricing" key="1" disabled>
                                 <SC.PricingIcon>
                                    <Icon type="exclamation-circle" />
                                 </SC.PricingIcon>
                                 <div>
                                    <div>
                                       <Text size="small">
                                          <IntlLabel label="Different countries have different price rates. We provide the best possible rate from our partners. It may vary." />
                                          <br />
                                          <br />
                                          <IntlLabel label="Charges will be applied once messages are sent and added to upcoming invoice." />
                                       </Text>
                                    </div>
                                    <div>
                                       <Text label="Single message price:" size="small" mb="none" />
                                       <SC.PricingTextWrapper>
                                          {messagePricingData?.map(item => (
                                             <SC.PricingText key={item.id}>
                                                <Text
                                                   label={item.kind}
                                                   size={isMobile ? 'small' : 'base'}
                                                   weight="semiBold"
                                                   mb="none"
                                                />
                                                <Text
                                                   label={item.cost}
                                                   size={isMobile ? 'small' : 'base'}
                                                   ml={isMobile ? 'xsmall' : 'base'}
                                                   mb="none"
                                                />
                                             </SC.PricingText>
                                          ))}
                                       </SC.PricingTextWrapper>
                                    </div>
                                    <SC.Pricing onClick={onPricing}>
                                       <Icon type="exclamation-circle" /> <IntlLabel label="Pricing" />
                                    </SC.Pricing>
                                 </div>
                              </Panel>
                           </Collapse>
                        </SC.PricingContainer>
                        <div>
                           <Text size="mlarge" weight="semiBold" mb="xsmall">
                              <IntlLabel label="Approximate price" />
                           </Text>
                           <SC.ApproximatePriceContainer>
                              <Text size="base" weight="normal" colorType="inherit" mb="none">
                                 <IntlLabel label="For upcoming 30 days" />
                              </Text>
                              <Text size="base" weight="normal" mb="small">
                                 <IntlLabel label="Approx. " />
                                 <strong>
                                    <IntlLabel label="12.32€" />
                                 </strong>
                              </Text>
                           </SC.ApproximatePriceContainer>
                        </div>
                     </SC.CardContainer>
                     <Form.Alert text={formError} />
                     <ModifyMassLayout.Footer>
                        <Button block type="primary" ghost upperCase={false} onClick={goBack}>
                           <Icon type="arrow-left" style={{ marginRight: '10px' }} />
                           <IntlLabel label="Edit message" />
                        </Button>
                        <Button
                           block
                           label="Start Campaign"
                           type="primary"
                           upperCase={false}
                           loading={submitting}
                           onClick={startCampaign}>
                           <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                        </Button>
                     </ModifyMassLayout.Footer>
                  </>
               )
            }}
         </Form>
      </>
   )
}
