import { CustomIcon, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Popover } from 'antd'
import AntdImgCrop from 'antd-img-crop'
import { UploadFile, UploadProps } from 'antd/lib/upload/interface'
import React, { useEffect, useState } from 'react'
import noop from 'lodash/noop'
import uniqueId from 'lodash/uniqueId'
import { FieldRenderProps } from 'react-final-form'
import withFormBlock from '../FormBlock/withFormBlock'
import './styles/index.less'
import * as SC from './styles'

type PictureCropProps = { children: JSX.Element }

type PictureEditInput = {
   submitting?: boolean
   onUpload?: (...args: any) => any
} & FieldRenderProps<any>

type UploadContainerProps = {
   uploaded?: boolean
   hideList?: boolean
}

type DefaultFileOption = Pick<UploadFile, 'uid' | 'name' | 'type' | 'status' | 'size' | 'url'>

const getDefaultFileOptions = (filePath: string): DefaultFileOption[] => [
   {
      uid: uniqueId('file-'),
      name: '',
      type: '',
      status: 'done',
      size: 0,
      url: `https:${filePath}`,
   },
]

export const PictureCrop = ({ children }: PictureCropProps) => (
   <AntdImgCrop
      modalTitle={(<IntlLabel label="input.cropImage.title" />) as any}
      modalOk={(<IntlLabel label="input.cropImage.btn.apply.title" />) as any}
      modalClassName="img-crop-modal"
      fillColor="white"
      cropShape="round"
   >
      {children}
   </AntdImgCrop>
)

const PictureEditInput = ({ input, onUpload }: PictureEditInput) => {
   const [hasFileRemoved, setHasFileRemoved] = useState(false)
   const [fileList, setFileList] = useState<UploadFile[]>([])
   const [optionsShown, setOptionsShown] = useState(false)

   const onChange: UploadProps['onChange'] = async ({ fileList }) => {
      if (fileList.length) {
         const lastItem = fileList.slice(-1)
         setFileList(lastItem)
      }

      const originFile = fileList[0]?.originFileObj
      const photoUrl = await onUpload?.(originFile).catch(noop)

      input.onChange(photoUrl)
   }

   const togglePopoverOptions = () => setOptionsShown(!optionsShown)

   const changePhoto = () => setOptionsShown(false)

   const removePhoto = () => {
      input.onChange('')

      setFileList([])
      setOptionsShown(false)
      setHasFileRemoved(true)
   }

   useEffect(() => {
      // 1. If file is saved in backend, display default file options on render
      // 2. By checking if file exist, we fix `antd` animation issues
      // 3. hasFileRemoved allows to remove file, without proper check file still exist
      if (!fileList.length && input.value) {
         setFileList(hasFileRemoved ? [] : getDefaultFileOptions(input.value))
      }
   }, [fileList.length, input.value, hasFileRemoved, fileList])

   return (
      <PictureCrop>
         <SC.UploadContainer
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            disabled={!!fileList?.length}
            uploaded={!!fileList?.length}
         >
            {!fileList?.length && <CustomIcon type="photoPlaceholder" />}
            <Popover
               content={
                  <SC.PopoverContent>
                     <SC.PopoverRow onClick={changePhoto}>
                        <PictureCrop>
                           <SC.UploadContainer listType="text" hideList onChange={onChange}>
                              <CustomIcon type="photo" />
                              <Text size="base" ml="small" mb="none" weight="medium">
                                 <IntlLabel label="staff.picture.changePhoto" />
                              </Text>
                           </SC.UploadContainer>
                        </PictureCrop>
                     </SC.PopoverRow>

                     <SC.PopoverRow onClick={removePhoto}>
                        <CustomIcon type="trashBin" />
                        <Text size="base" ml="small" mb="none" weight="medium">
                           <IntlLabel label="staff.picture.removePhoto" />
                        </Text>
                     </SC.PopoverRow>
                  </SC.PopoverContent>
               }
               trigger="click"
               placement="bottomLeft"
               open={optionsShown}
               onOpenChange={togglePopoverOptions}
               overlayClassName="photo-options-popover-content"
               className="photo-options-popover-container"
            >
               <SC.OptionButton role="button" onClick={togglePopoverOptions}>
                  {!!fileList?.length && input.value && (
                     <CustomIcon type={optionsShown ? 'editPhotoActive' : 'editPhoto'} />
                  )}
               </SC.OptionButton>
            </Popover>
         </SC.UploadContainer>
      </PictureCrop>
   )
}

export default withFormBlock<PictureEditInput>(PictureEditInput)
export type { UploadContainerProps }
