import { getIsMobile, useNavigation } from '@plandok/core'
import { IntlLabel, LabelKey } from '@plandok/i18n'
import { Tabs } from 'antd'
import cn from 'classnames'
import { StaffTabHash } from 'constants/routes'
import useGetHasPermission from 'hooks/permission/useGetHasPermission'
import React from 'react'

import { staffTabConfig } from '../config'

type StaffTabsProps = {
   children: React.ReactNode[]
}

type Tab = {
   title: LabelKey
   path: string
   permission: string
}

export default function StaffTabs({ children }: StaffTabsProps) {
   const { hash, hashNavigate } = useNavigation()
   const isMobile = getIsMobile()
   const getHasPermission = useGetHasPermission()
   const resultTabs = staffTabConfig.filter(({ permission }: { permission: string }) => getHasPermission(permission))
   const handleTabClick = (key: string) => hashNavigate(key)
   const activeKey = hash || StaffTabHash.STAFF_MEMBERS

   const getActiveTab = () => {
      const activeIndex = resultTabs.findIndex((tabConfig: any) => tabConfig.path === activeKey)
      // @ts-ignore
      return children[activeIndex] //? children[activeIndex].props.children : children[activeIndex];
   }

   return isMobile ? (
      getActiveTab()
   ) : (
      <Tabs
         className={cn('page-tabs-content-overflow', { 'staff-page__tabs--mobile': getIsMobile() }, 'staff-page__tabs')}
         defaultActiveKey={activeKey}
         activeKey={activeKey}
         onChange={handleTabClick}
         animated={false}>
         {resultTabs.map((tabConfig: Tab) => {
            const renderTabPane = children.find(({ key }: any) => key === tabConfig.permission)

            return (
               <Tabs.TabPane tab={<IntlLabel label={tabConfig.title} />} key={tabConfig.path}>
                  {renderTabPane || null}
               </Tabs.TabPane>
            )
         })}
      </Tabs>
   )
}
