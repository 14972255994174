import { withModal } from '@plandok/core'
import PricingCalculate from 'components/app/PricingCalculate'
import React from 'react'

type ModalPropsType = {
   modalProps: {
      currentStaffCount: number
   }
}

function PricingFeatureModal({ modalProps }: ModalPropsType) {
   return <PricingCalculate staffCount={modalProps.currentStaffCount} />
}

export default withModal('modal.title.pricing', { contentClassName: 'pricing-feature-modal-content' })(
   PricingFeatureModal,
)
