import { Button, CustomIcon, Text } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import * as api from 'api'
import { AppTable, NotReleased } from 'components'
import { RoutePath } from 'constants/routes'
import React from 'react'

import { messageLogColumns } from './config'
import * as SC from './styles'

export default function MarketingMassmessages() {
   const { navigate } = useLocation()
   const [buttonTab, setButtonTab] = React.useState('setup')
   const smsCreateMassage = () => navigate(RoutePath.MESSAGE_CREATE)
   const emailCreateMassage = () => navigate(RoutePath.EMAIL_MESSAGE_CREATE)

   const massageLogData = true

   return (
      <NotReleased>
         <>
            {massageLogData ? (
               <div>
                  <SC.MobileBtn>
                     <Button
                        label="Setup"
                        upperCase={false}
                        ghost={buttonTab !== 'setup'}
                        onClick={() => setButtonTab('setup')}
                        type="primary"
                     />
                     <Button
                        label="Message log"
                        upperCase={false}
                        ghost={buttonTab !== 'message'}
                        onClick={() => setButtonTab('message')}
                        type="primary"
                     />
                  </SC.MobileBtn>
                  {buttonTab === 'setup' ? (
                     <SC.Container>
                        <SC.SPlaceholderTitle>
                           <IntlLabel label="Setup new message" />
                        </SC.SPlaceholderTitle>
                        <SC.SPlaceholderDescription>
                           <IntlLabel label="Send custom marketing messages to your clients via SMS or Email with few simple clicks" />
                        </SC.SPlaceholderDescription>
                        <SC.MassWrapper>
                           <SC.MassBtn onClick={smsCreateMassage}>
                              <CustomIcon type="SMS" />
                              <Text label="dropdown.notificationBy.sms" weight="bold" size="large" mb="none" />
                           </SC.MassBtn>
                           <SC.MassBtn onClick={emailCreateMassage}>
                              <CustomIcon type="Email" />
                              <Text label="dropdown.notificationBy.email" weight="bold" size="large" mb="none" />
                           </SC.MassBtn>
                        </SC.MassWrapper>
                     </SC.Container>
                  ) : (
                     <SC.MessageLog>
                        <AppTable
                           type="message"
                           columns={messageLogColumns}
                           mobileClassName="app_clients-list--mobile"
                           apiConfig={{
                              fetchData: api.fetchClients,
                              mapping: {
                                 pageKey: 'page[offset]',
                                 maxCountKey: 'page[limit]',
                                 dataKey: 'massMarketingCampaign',
                                 totalCountKey: 'messageLog.totalCount',
                                 searchKey: 'query',
                                 emptyStateKey: 'messageLog.emptyState',
                              },
                           }}
                        />
                     </SC.MessageLog>
                  )}
               </div>
            ) : (
               <SC.Container>
                  <SC.SPlaceholderTitle>
                     <IntlLabel label="Setup new message" />
                  </SC.SPlaceholderTitle>
                  <SC.SPlaceholderDescription>
                     <IntlLabel label="Send custom marketing messages to your clients via SMS or Email with few simple clicks" />
                  </SC.SPlaceholderDescription>
                  <SC.MassWrapper>
                     <SC.MassBtn onClick={smsCreateMassage}>
                        <CustomIcon type="SMS" />
                        <Text label="dropdown.notificationBy.sms" weight="bold" size="large" mb="none" />
                     </SC.MassBtn>
                     <SC.MassBtn onClick={emailCreateMassage}>
                        <CustomIcon type="Email" />
                        <Text label="dropdown.notificationBy.email" weight="bold" size="large" mb="none" />
                     </SC.MassBtn>
                  </SC.MassWrapper>
               </SC.Container>
            )}
         </>
      </NotReleased>
   )
}
