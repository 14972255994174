import React from 'react'

import SetupForm from '../SetupForm'

export default function SetupThankYouForm() {
   return (
      <SetupForm
         notificationId="thank_you"
         checkBoxLabel="input.enableThank.label"
         checkBoxDescription="input.enableThankyou.description"
      />
   )
}
