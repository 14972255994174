import { StaffTabHash } from 'constants/routes'

import { PermissionType } from '../../../constants/auth'

export const staffTabConfig: any = [
   {
      path: StaffTabHash.STAFF_MEMBERS,
      title: 'tabs.staffMembers.title',
      permission: PermissionType.EMPLOYEES,
   },
   {
      path: StaffTabHash.WORKING_HOURS,
      title: 'tabs.staffWorking.title',
      permission: PermissionType.WORKING_HOURS,
   },
   {
      path: StaffTabHash.CLOSED_DATES,
      title: 'tabs.closedDates.title',
      permission: PermissionType.CLOSED_DATES,
   },
   {
      path: StaffTabHash.USER_PERMISSIONS,
      title: 'tabs.userPermissions.title',
      permission: PermissionType.PERMISSION_LEVELS,
   },
]
