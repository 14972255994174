import './addon.less'

import { getIsMobile, useModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Avatar, Spin } from 'antd'
import { StickyTable } from 'components'
import { addDays, startOfWeek } from 'date-fns'
import { formatDuration } from 'helpers/date'
import isArray from 'lodash/isArray'
import sumBy from 'lodash/sumBy'
import React from 'react'

import StaffWorkHeader from './components/StaffWorkHeader'
import WorkStaffCell from './components/WorkStaffCell'
import { extractWorkTableData, formatShiftInfoToForm } from './helpers'
import * as SC from './style'

export default function StaffWorkTable({ fetchData, data, date, isLoading, locationId, timeFormat }: any) {
   const resultData = isArray(data) ? data : []
   const [, { showModal }] = useModal()

   const createWorkTime = (modalProps: any) =>
      showModal({
         type: 'CREATE_STAFF_HOURS',
         modalProps: {
            ...modalProps,
            onSuccess: fetchData,
            timeFormat,
         },
      })

   const editWorkTime = (staffId: string, firstName: string) => (item: any, index: number) => {
      if (!item) {
         return
      }

      return showModal({
         type: 'CREATE_STAFF_HOURS',
         modalProps: {
            id: staffId,
            firstName,
            locationId,
            onSuccess: fetchData,
            date: addDays(startOfWeek(date, { weekStartsOn: 1 }), index),
            initialValues: formatShiftInfoToForm(item, locationId),
            staffId,
            timeFormat,
         },
      })
   }

   return (
      <Spin spinning={isLoading}>
         <StickyTable.Container mobileHeight="calc(100vh - 295px)" style={{ overflow: 'auto' }}>
            <StickyTable.Wrapper stickyColumnCount={1} stickyHeaderCount={1} disableShadow>
               <StickyTable.Table>
                  <StickyTable.Row className="staff-hours--row staff-hours--header">
                     <StickyTable.Cell data-staff-item-name>
                        <IntlLabel label="sidebar.staff" />
                     </StickyTable.Cell>
                     <StaffWorkHeader date={date} />
                  </StickyTable.Row>
                  {resultData?.map((empl: any) => {
                     const elementData = extractWorkTableData(empl, date, timeFormat)
                     const totalTime = sumBy(elementData, 'totalTime')
                     const editStaffWorkHours = editWorkTime(empl.id, empl.firstName)
                     const createStaffWorkHours = (index: number) =>
                        createWorkTime({
                           firstName: empl.firstName,
                           date: addDays(startOfWeek(date, { weekStartsOn: 1 }), index),
                           locationId,
                           staffId: empl.id,
                        })

                     const StaffAvatar = ({ photoUrl }: { photoUrl: string }) =>
                        photoUrl ? (
                           <div data-staff-user-avatar>
                              <SC.Photo src={photoUrl} alt={empl.firstName} />
                           </div>
                        ) : (
                           <div data-staff-user-avatar>
                              <Avatar size="large">{empl.firstName?.substr(0, 2)}</Avatar>
                           </div>
                        )

                     return (
                        <StickyTable.Row key={empl.id} className="staff-hours--row">
                           <StickyTable.Cell data-staff-item-name>
                              <SC.UserCell>
                                 {!getIsMobile() && <StaffAvatar photoUrl={empl.photo} />}
                                 <div data-staff-user-info>
                                    <div>
                                       {empl.firstName} {empl.lastName}
                                    </div>
                                    <div>{totalTime ? formatDuration(totalTime) : 'Not working'}</div>
                                 </div>
                              </SC.UserCell>
                           </StickyTable.Cell>
                           {[...Array(7)].map((_, i) => (
                              <WorkStaffCell
                                 key={i}
                                 createStaffWorkHours={createStaffWorkHours}
                                 editWorkTime={editStaffWorkHours}
                                 index={i}
                                 shiftsInfo={elementData?.[i]}
                              />
                           ))}
                        </StickyTable.Row>
                     )
                  })}
               </StickyTable.Table>
            </StickyTable.Wrapper>
         </StickyTable.Container>
      </Spin>
   )
}
