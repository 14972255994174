import Icon from '@ant-design/icons'
import { Field, Form, Text, withModal } from '@plandok/core'
import { getLabel, ReactIntl } from '@plandok/i18n'
import { CHARACTER_COUNTER_URL } from 'constants/external-links'
import React, { useContext, useEffect, useState } from 'react'

import * as SC from '../styles'

type Country = {
   name: string
   code: number
}

type CountryItem = {
   countryCallingCode: string
   countryCode: string
   countryName: string
   id: number
   smsPrice: number
   vat: number
   whatsappPrice: number
}

type PricingModalProps = {
   priceData: {
      data: CountryItem[]
      meta: {
         default: CountryItem
         emptyState: boolean
         pagination: {
            total: number
         }
      }
   }
}

function PricingModal({ modalProps }: { modalProps: PricingModalProps }) {
   const [countryOptions, setCountryOptions] = useState<Country[]>([])
   const [selectCountry, setSelectCountry] = useState<number | null>(null)

   const { lang } = useContext(ReactIntl)

   const { priceData } = modalProps

   useEffect(() => {
      const countries: Country[] = []

      priceData?.data.map(({ countryName, id }) => countries.push({ name: countryName, code: id }))

      setCountryOptions(countries)
   }, [priceData])

   return (
      <Form>
         {() => (
            <SC.PricingModal>
               <Icon type="exclamation-circle" style={{ marginTop: '30px' }} />
               <SC.PricingModalContainer>
                  <SC.PricingModalText>
                     <Text mb="none" lh="xlarge" weight="normal" label="modal.pricing.description" />
                     <Text mb="none" lh="xlarge" weight="normal" label="modal.pricing.subDescription" />
                     <Text mb="none" lh="xlarge" weight="normal" label="modal.pricing.counterDescription">
                        &nbsp;
                        <a href={CHARACTER_COUNTER_URL} target="_blank" rel="noopener noreferrer">
                           <span>{getLabel('modal.pricing.learnMore', lang)}</span>
                        </a>
                     </Text>
                  </SC.PricingModalText>
                  <SC.PricingModalFooter>
                     <Text size="large" weight="semiBold" label="modal.pricing.priceRates" lh="xxlarge" mb="small" />
                     <SC.WrapperInfoFooter>
                        <Field.Select
                           name="countryIsoCode"
                           label="input.country.label"
                           placeholder="input.country.placeholder"
                           options={countryOptions}
                           defaultValue={priceData?.meta.default.countryName}
                           md={10}
                           span={24}
                           onCustomChange={(id: number) => setSelectCountry(id)}
                        />
                        <SC.ContainerInfoFooter>
                           <Text label="modal.pricing.messagePrice" size="small" mb="none" lh="base" />
                           <SC.PricingText>
                              <Text size="base" weight="semiBold" mb="none" lh="small">
                                 Email
                              </Text>
                              <Text size="base" mb="none" label="plan.free" />
                           </SC.PricingText>
                           <SC.PricingText>
                              <Text size="base" weight="semiBold" mb="none" lh="small">
                                 SMS
                              </Text>
                              <Text size="base" mb="none">
                                 {selectCountry
                                    ? priceData?.data.find(({ id }) => id === selectCountry)?.smsPrice
                                    : priceData?.meta.default.smsPrice}
                                 €
                              </Text>
                           </SC.PricingText>
                           <SC.PricingText>
                              <Text size="base" weight="semiBold" mb="none" lh="small">
                                 WhatsApp
                              </Text>
                              <Text size="base" mb="none">
                                 {selectCountry
                                    ? priceData?.data.find(({ id }) => id === selectCountry)?.whatsappPrice
                                    : priceData?.meta.default.whatsappPrice}
                                 €
                              </Text>
                           </SC.PricingText>
                        </SC.ContainerInfoFooter>
                     </SC.WrapperInfoFooter>
                  </SC.PricingModalFooter>
               </SC.PricingModalContainer>
            </SC.PricingModal>
         )}
      </Form>
   )
}

export default withModal('reminder.pricing', { contentClassName: 'pricing-modal-content' })(PricingModal)
