import IntlConsumer from '../components/IntlConsumer'
import { getLabel } from '../helpers'
import { LabelKey } from '../types'
import React from 'react'

const withIntlLabel =
   ({ propKey, defaultProp }: { propKey: string; defaultProp?: LabelKey }) =>
   (Component: any) =>
   (props: any) => {
      const val = props[propKey] || defaultProp
      if (!val) {
         return <Component {...props} />
      }
      return (
         <IntlConsumer>
            {({ lang }: any) => (
               <Component
                  {...props}
                  {...{
                     [propKey]: getLabel(val, lang),
                  }}
               />
            )}
         </IntlConsumer>
      )
   }

export default withIntlLabel
