// THIS FILE IS GENERATED, DO NOT EDIT!
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../generatedTypes'
const defaultOptions = {} as const
export type EmployeesQueryVariables = Types.Exact<{ [key: string]: never }>

export type EmployeesQuery = {
   __typename?: 'AppQuery'
   employees?: {
      __typename?: 'App_EmployeeConnection'
      edges?: Array<{
         __typename?: 'App_EmployeeEdge'
         node?: { __typename?: 'App_Employee'; id: any; firstName?: string | null; lastName?: string | null } | null
      } | null> | null
   } | null
}

export const EmployeesDocument = gql`
   query Employees {
      employees {
         edges {
            node {
               id
               firstName
               lastName
            }
         }
      }
   }
`

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
   const options = { ...defaultOptions, ...baseOptions }
   return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options)
}
export function useEmployeesLazyQuery(
   baseOptions?: Apollo.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>,
) {
   const options = { ...defaultOptions, ...baseOptions }
   return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options)
}
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>
export type EmployeesQueryResult = Apollo.QueryResult<EmployeesQuery, EmployeesQueryVariables>
