import * as api from 'api'
import { ModifyMassLayout, NotReleased } from 'components'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import PaymentDetailsForm from './components/PaymentDetailsForm'

export const PaymentDetailsFormWithApi = withFormApi({
   createAction: api.createService,
   successMessage: 'Success',
})(PaymentDetailsForm)

export default function ModifyMessagePage() {
   return (
      <NotReleased>
         <ModifyMassLayout title="Payment" step="SMS setup. Step 4 of 4">
            <PaymentDetailsFormWithApi />
         </ModifyMassLayout>
      </NotReleased>
   )
}
