export const extractValue = (value: any, options?: any[], optionGroups?: any[]) => {
   if (!value) {
      return null
   }

   if (Array.isArray(optionGroups)) {
      let result = null

      optionGroups.filter(Boolean).some(e =>
         (e.options || []).some((el: any) => {
            if (el.code === value) {
               result = el.value
               return true
            }
            return false
         }),
      )

      return result
   }
   return ((options || []).find(e => e.code === value) || {}).value
}
