import { Card, mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const CardContainer = styled(Card)`
   max-width: 980px;
   min-height: 670px;
   margin: 5px auto 6px auto;
   box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
   padding: 35px;
   border-radius: 20px;
   input[type='number']::-webkit-outer-spin-button,
   input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   ant-form-explain:last-child {
      display: none;
   }
   ${mediaMobileDown(css`
      max-width: 350px;
      padding: 0 15px;
   `)}
`

export const Text = styled.div`
   padding-top: 85px;
   div {
      font-size: 22px;
   }
   ${mediaMobileDown(css`
      padding-top: 30px;
   `)}
`

export const Characters = styled.div`
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: baseline;
   justify-content: space-between;
   margin-bottom: 30px;
`

export const Count = styled.div`
   display: flex;
   flex-direction: row;
   ${mediaMobileDown(css`
      flex-direction: column;
   `)}
`

export const Pricing = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-right: 10px;
   color: #13316d;
   font-size: 16px;
   font-weight: 500;
   cursor: pointer;
   text-decoration: underline;
`

export const ActionContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   button {
      min-width: 250px;
      margin-bottom: 10px;
   }
`

export const CardImage = styled.div`
   svg {
      position: absolute;
   }
   & .ant-form-explain {
      display: none;
   }
   ${mediaMobileDown(css`
      margin: 0 auto 20px auto;
      height: 70vh;
      display: flex;
      flex-direction: column;
      align-items: center;
   `)}
`
