import { Card } from '@plandok/core'
import styled from 'styled-components'

export const CardContainer = styled(Card)`
   input[type='number']::-webkit-outer-spin-button,
   input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
`

export const Text = styled.div`
   padding-top: 35px;

   div {
      margin-bottom: 5px;
   }
`

export const Divider = styled.div`
   margin: 0;
`
