import { ICalendarFilterParams } from 'pages/dashboard/CalendarPage/useCalendarState'
import { create } from 'zustand'

export type CalendarScrollType = {
   lastScroll: number | undefined
   visitedPage: boolean
   scrollTimeout: number
   scrollTimeoutForBtn: number
}

type State = {
   allowSaveButton: boolean
   displaySearchClient: boolean
   locationId?: string
   employeeId: string
   page: number
   notificationCharge?: number
   calendarState: ICalendarFilterParams
   startDate: Date | null
   endDate: Date | null
   isTimeRangeSelected: boolean
   frequency: string
   repeatingValue: string
   specificDate: string
   rescheduleItemId: string
   systems: {
      displayUpdateAlert: boolean
      displayUpdateButton: boolean
      requestInstallApp: boolean
   }
   calendarScroll: CalendarScrollType
   rescheduleItemDate: string | Date | undefined
   rescheduleItemTime: number | undefined
   locations: any[]
   employees: any[]
   rescheduleItemStaff: string
   setStartDate: (startDate: Date | null) => void
   setEndDate: (endDate: Date | null) => void
   setIsTimeRangeSelected: (isTimeRangeSelected: boolean) => void
   setAllowSaveButton: (allowSaveButton: boolean) => void
   setEmployeeId: (employeeId: string) => void
   setLocationId: (locationId: string) => void
   setLocations: (locations: any[]) => void
   setEmployees: (employees: any[]) => void
   setPickerCleared: (pickerCleared: boolean) => void
   setPage: (page: number) => void
   setFrequency: (frequency: string) => void
   setRepeatingValue: (repeatingValue: string) => void
   setSpecificDate: (repeatingValue: string) => void
   setRescheduleItemId: (rescheduleItemId: string) => void
   setRescheduleItemDate: (rescheduleItemDate: string | Date | undefined) => void
   setRescheduleItemTime: (rescheduleItemTime: number | undefined) => void
   setRescheduleItemStaff: (rescheduleItemStaff: string) => void
   setCalendarState: (newState: any) => void
   setCalendarScroll: (newState: any) => void
   setSystems: (newState: any) => void
   setNotificationCharge: (notificationCharge: number) => void
}

export const useStore = create<State>(set => ({
   allowSaveButton: true,
   displaySearchClient: false,
   locations: [],
   employees: [],
   locationId: undefined,
   employeeId: '',
   pickerCleared: false,
   page: 1,
   startDate: null,
   endDate: null,
   isTimeRangeSelected: false,
   frequency: '',
   repeatingValue: '',
   specificDate: '',
   rescheduleItemId: '',
   rescheduleItemDate: undefined,
   rescheduleItemTime: undefined,
   rescheduleItemStaff: '',
   systems: {
      displayUpdateAlert: false,
      requestInstallApp: false,
      displayUpdateButton: false,
   },
   // calendar
   calendarState: {
      isInitialise: false,
      staff: 'all_employees',
      viewType: 'day',
      date: new Date(),
      timeOffset: 15,
   },
   // calendar scroll
   calendarScroll: {
      lastScroll: 0,
      visitedPage: false,
      scrollTimeout: 0,
      scrollTimeoutForBtn: 0,
   },
   setLocations: locations =>
      set((state: State) => ({
         ...state,
         locations,
      })),
   setEmployees: employees =>
      set((state: State) => ({
         ...state,
         employees,
      })),
   setStartDate: startDate =>
      set((state: State) => ({
         ...state,
         startDate,
      })),
   setEndDate: endDate =>
      set((state: State) => ({
         ...state,
         endDate,
      })),
   setIsTimeRangeSelected: (isTimeRangeSelected: boolean) =>
      set((state: State) => ({
         ...state,
         isTimeRangeSelected,
      })),
   setAllowSaveButton: allowSaveButton =>
      set((state: State) => ({
         ...state,
         allowSaveButton,
         displaySearchClient: !allowSaveButton,
      })),
   setEmployeeId: employeeId =>
      set((state: State) => ({
         ...state,
         employeeId,
      })),
   setLocationId: locationId =>
      set(state => ({
         ...state,
         locationId,
      })),
   setPickerCleared: pickerCleared =>
      set((state: State) => ({
         ...state,
         pickerCleared,
      })),
   setPage: page =>
      set((state: State) => ({
         ...state,
         page,
      })),
   setFrequency: frequency =>
      set((state: State) => ({
         ...state,
         frequency,
      })),
   setRepeatingValue: repeatingValue =>
      set((state: State) => ({
         ...state,
         repeatingValue,
      })),
   setSpecificDate: specificDate =>
      set((state: State) => ({
         ...state,
         specificDate,
      })),
   setRescheduleItemId: rescheduleItemId =>
      set((state: State) => ({
         ...state,
         rescheduleItemId,
      })),
   setRescheduleItemDate: rescheduleItemDate =>
      set((state: State) => ({
         ...state,
         rescheduleItemDate,
      })),
   setRescheduleItemTime: rescheduleItemTime =>
      set((state: State) => ({
         ...state,
         rescheduleItemTime,
      })),
   setRescheduleItemStaff: rescheduleItemStaff =>
      set((state: State) => ({
         ...state,
         rescheduleItemStaff,
      })),
   setCalendarState: newState =>
      set((state: State) => ({
         ...state,
         calendarState: {
            ...state.calendarState,
            ...newState,
         },
      })),
   setCalendarScroll: newState =>
      set((state: State) => ({
         ...state,
         calendarScroll: {
            ...state.calendarScroll,
            ...newState,
         },
      })),
   setSystems: newState =>
      set((state: State) => ({
         ...state,
         systems: {
            ...state.systems,
            ...newState,
         },
      })),
   setNotificationCharge: notificationCharge =>
      set((state: State) => ({
         ...state,
         notificationCharge: notificationCharge,
      })),
}))
