import { mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

const SBox = styled.div<any>`
   background: white;
   border: ${p => p.theme.boxBorderDecoration};

   ${mediaMobileDown(css`
      border: none;
   `)}
` as any

export default SBox
