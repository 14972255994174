import * as api from 'api'

import { formatDateField } from '../../../helpers/date/field'
import { TimeCardData } from './types'

const roundTimeForApi = (time: number) => {
   return time - (time % 5)
}

export const updateBookingPosition = (
   timeCard: TimeCardData,
   startTime: any,
   nextColumn: any,
   isWeekMode?: boolean,
) => {
   const date = formatDateField(isWeekMode ? nextColumn : timeCard.isBlocked ? timeCard.date : timeCard.appointmentDate)
   const employeeId = isWeekMode ? timeCard.employeeId : nextColumn

   if (timeCard.isBlocked) {
      return api.rescheduleBlockTime(timeCard.id, {
         employeeId,
         date,
         startTime: roundTimeForApi(Math.floor(startTime)),
         endTime: roundTimeForApi(startTime + timeCard.duration),
         description: timeCard.description,
      })
   }

   return api.rescheduleMeeting({
      appointmentId: timeCard.appointmentId,
      bookingId: timeCard.id,
      employeeId: employeeId || timeCard.employeeId,
      date,
      startTime: roundTimeForApi(Math.floor(startTime)),
      duration: roundTimeForApi(timeCard.duration),
   } as any)
}

export const updateBookingSize = (timeCard: TimeCardData, endTime: any): any => {
   if (timeCard.isBlocked) {
      return api.rescheduleBlockTime(timeCard.id, {
         employeeId: timeCard.employeeId,
         date: timeCard.date,
         startTime: roundTimeForApi(timeCard.startTime),
         endTime: roundTimeForApi(endTime),
         description: timeCard.description,
      })
   }

   return api.rescheduleMeeting({
      appointmentId: timeCard.appointmentId,
      bookingId: timeCard.id,
      employeeId: timeCard.employeeId,
      date: timeCard.appointmentDate,
      startTime: roundTimeForApi(timeCard.startTime),
      duration: (endTime - timeCard.startTime) as any,
   })
}
