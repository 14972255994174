import { IntlDate, IntlLabel } from '@plandok/i18n'
import React, { Fragment } from 'react'

import { getLogType } from '../helpers'
import { Log, LogType } from '../types'

type HistoryLogProps = {
   getLogLabel: (log: Log) => string
   log: Log
   dateFormat: string
}

export default function HistoryLog({ getLogLabel, log, dateFormat }: HistoryLogProps) {
   return (
      <Fragment key={log.id}>
         <IntlLabel label={getLogType(log?.type as LogType)} />
         <IntlLabel label={getLogLabel(log)} />
         <IntlDate date={new Date(log?.createdAt)} dateFormat={dateFormat} />
         <br />
      </Fragment>
   )
}
