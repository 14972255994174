const fromEntries = (iterable: any[]) =>
   [...(iterable || [])].reduce((obj, [key, val]) => {
      obj[key] = val
      return obj
   }, {})

export const getUrlParams = (dateFields: string[] = []) =>
   fromEntries(
      Object.entries(fromEntries(new URLSearchParams(window.location.search) as any)).map(([key, value]: any) => [
         key,
         (dateFields || ([] as any)).includes(key) && value ? new Date(parseInt(value, 10)) : value,
      ]),
   )
