import * as api from 'api'
import { AppLayout, NoInternetConnection } from 'components'
import withFormApi from 'hocs/form/withFormApi'
import { useGetProfile } from 'hooks/info/useGetProfile'
import React from 'react'

import PersonalSettingForm from './components/PersonalSettingForm'
import PersonalSettingsMobileHeader from './components/PersonalSettingsMobileHeader'

const SettingsForm = withFormApi({
   detailsAction: api.fetchProfileDetails,
   createAction: api.editProfileDetails,
   editAction: api.editProfileDetails,
   emptyId: true,
})(PersonalSettingForm)

export default function PersonalSettingsPage() {
   const getProfile = useGetProfile()

   return (
      <NoInternetConnection>
         <AppLayout mobileHeader={PersonalSettingsMobileHeader} headerTitle="title.mySettings">
            <div className="hidden-md" style={{ height: '80px' }} />
            <AppLayout.Container>
               <SettingsForm onSuccess={async () => await getProfile()} />
            </AppLayout.Container>
         </AppLayout>
      </NoInternetConnection>
   )
}
