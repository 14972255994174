import { CustomIcon, fontSize, fontWeight, lineHeight, mediaMdDown, Text } from '@plandok/core'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

type TextFontSize = keyof typeof fontSize
type TextFontWeight = keyof typeof fontWeight
type TextLineHeight = keyof typeof lineHeight

const Container = styled.div`
   display: flex;
   flex-direction: column;
   padding: 13px 12px 15px;
   align-items: flex-start;
   justify-content: flex-start;
   border: 1px solid #ffa100;
   border-radius: 5px;
   margin-bottom: 19px;

   ${mediaMdDown(css`
      margin-bottom: 17px;
   `)}
`

const HStack = styled.div`
   display: grid;
   grid-auto-flow: column;
   gap: 5px;
   align-items: center;
   justify-content: center;
`

interface PremiumFeatureLabelProps {
   size: TextFontSize
   weight: TextFontWeight
   lh: TextLineHeight
}

export const PremiumFeatureLabel = ({
   size = 'medium',
   weight = 'medium',
   lh = 'mlarge',
}: PremiumFeatureLabelProps) => {
   return (
      <HStack>
         <CustomIcon type="premium" />
         <Text colorType="yellow" mb="none" size={size} weight={weight} lh={lh} label="title.premiumFeature" />
      </HStack>
   )
}

interface PremiumBlockProps {
   children: ReactNode
}

export default function PremiumBlock({ children }: PremiumBlockProps) {
   return (
      <Container>
         <PremiumFeatureLabel size="medium" weight="medium" lh="mlarge" />
         {children}
      </Container>
   )
}
