import { DownloadOutlined } from '@ant-design/icons'
import { IntlLabel } from '@plandok/i18n'
import { Button, Row } from 'antd'
import React from 'react'

import * as SC from '../../../dashboard/MessagesPage/styles'
import { downloadInvoicePDF } from './helpers'
import { ColumnContainer } from './styled'

export const invoiceColumns = [
   {
      title: <IntlLabel label="invoice.table.date" />,
      dataIndex: 'name',
      key: 'name',
      render: (_: any, value: any) => value.createdAt ?? '',
   },
   {
      title: <IntlLabel label="invoice.table.invoice" />,
      dataIndex: 'formattedInvoiceNumber',
      key: 'formattedInvoiceNumber',
      render: (_: any, value: any) => value?.formattedInvoiceNumber ?? '',
   },
   {
      title: <IntlLabel label="invoice.table.priceTaxIncl" />,
      dataIndex: 'priceTaxIncl.amount',
      key: 'priceTaxIncl.amount',
      render: (_: any, value: any) => {
         const amount = value?.priceTaxIncl?.amount
         const currency = value?.priceTaxIncl?.currency
         return amount ? (
            <div>
               {amount} {currency}
            </div>
         ) : null
      },
   },
   {
      title: '',
      width: '300px',
      render: (_: any, value: any) => (
         <Button
            ghost
            shape="round"
            icon={<DownloadOutlined />}
            onClick={() => {
               downloadInvoicePDF(value?.invoiceNumber)
            }}>
            <span className="ml-1">
               <IntlLabel label="invoice.table.download" />
            </span>
         </Button>
      ),
   },
]

export function getMobileColumn(isMobile: boolean) {
   return ({ value }: any) => {
      return (
         <Row justify="space-between" align="middle" className="w-100">
            <SC.MessageContainer isMobile={isMobile}>
               {invoiceColumns.map((e, i) => (
                  <ColumnContainer key={i}>{(e as any)?.render?.({}, { ...value }, isMobile)}</ColumnContainer>
               ))}
            </SC.MessageContainer>
         </Row>
      )
   }
}
