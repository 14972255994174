import React from 'react'

import { Form } from '../../../../form'
import * as SC from './styles'

export default function ModifyFooter(props: any) {
   return (
      <SC.Footer>
         {props.info && <div data-modify-footer-info>{props.info}</div>}
         {props.error && (
            <div data-modify-footer-error>
               <Form.Alert text={props.error} />
            </div>
         )}
         <div data-modify-footer-btns>{props.children}</div>
      </SC.Footer>
   )
}
