import { GetAsyncSelectConfigFn } from './components/form/field/FormAsyncSelect/types'

export interface CoreService {
   getAsyncSelectConfig: GetAsyncSelectConfigFn
   useCountry: () => string
}

class CoreContainer {
   private service?: CoreService

   registerService = (service: CoreService) => {
      if (this.service) {
         throw new Error('Service already exists')
      }
      this.service = service
   }

   getService = () => {
      if (!this.service) {
         throw new Error('Register service to use it')
      }
      return this.service
   }
}

export const IoC = new CoreContainer()
