import { useLocation, useNavigate, useParams } from 'react-router-dom'

export default function useNavigation() {
   const reactRouter = useNavigate()
   const location = useLocation()
   const params = useParams()

   const navigate = (route: any, params: any = {}, withoutHash?: boolean) => {
      let path = Object.keys(params).reduce((acc, key) => {
         return acc.replace(`:${key}`, params[key])
      }, route)

      // if (window.location.hash !== undefined) {
      //     let h = withoutHash !== true ? window.location.hash : ''
      //     path = path + h + `?backUrl=${location.pathname}`
      // }
      // console.log(route, path)
      reactRouter(path)
   }

   const hashNavigate = (hash?: string) => {
      const query = `?${Object.entries(urlQuery())
         .filter(e => e[1] !== undefined)
         .map(e => e.join('='))
         .join('&')}`

      reactRouter(`${location.pathname}${query}#${hash}`)
   }

   const queryNavigate = (queries?: any, keep?: boolean) => {
      const query = `?${Object.entries(keep ? { ...urlQuery(), ...queries } : queries)
         .filter(e => e[1] !== undefined)
         .map(e => e.join('='))
         .join('&')}`
      let hash = window.location.hash

      reactRouter(hash ? `${location.pathname}${query}${hash}` : `${location.pathname}${query}`)
   }

   const goBack = () => {
      const { backUrl } = urlQuery()

      if (backUrl) {
         reactRouter(`${backUrl}`)
         return
      }

      reactRouter(-1)
   }

   const isActive = (route: any) => {
      return location.pathname === route
   }

   const urlQuery = (): any | undefined => {
      const searchParams = new URLSearchParams(location.search)
      const params = {} as any
      for (let [key, value] of searchParams.entries() as any) {
         params[key] = value
      }
      return params
   }

   return {
      hash: window.location.hash?.replace('#', ''),
      params: params,
      path: window.location.pathname,
      navigate,
      hashNavigate,
      queryNavigate,
      goBack,
      isActive,
      urlQuery,
   }
}
