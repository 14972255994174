import { Card, mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const HeaderText = styled.div`
   padding-top: 40px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   max-width: 430px;
   svg {
      margin-bottom: 15px;
   }
   ${mediaMobileDown(css`
      padding-top: 130px;
      max-width: 300px;
   `)}
`

export const CardContainer = styled(Card)`
   max-width: 560px;
   width: 540px;
   margin: 5px auto 6px auto;
   box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
   padding: 35px;
   border-radius: 20px;
   input[type='number']::-webkit-outer-spin-button,
   input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   ${mediaMobileDown(css`
      max-width: 355px;
      padding: 15px;
   `)}
`

export const Text = styled.div`
   div {
      margin-bottom: 10px;
      font-size: 22px;
   }
`

export const CheckedBtn = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   .ant-checkbox-wrapper {
      color: #14316d;
      margin: 0;
      padding: 5px 0;
      ${mediaMobileDown(css`
         &:first-child {
            padding-right: 50px;
         }
      `)}
   }
   .ant-checkbox-inner {
      background: #fff;
   }
   ${mediaMobileDown(css`
      flex-direction: row;
      justify-content: flex-start;
   `)}
`

export const RewardRegularText = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
   margin-bottom: 20px;
   div.ant-form-item-label {
      display: none;
   }
   .ant-form-explain {
      display: none;
   }
`

export const MobileRewardRegularText = styled.div`
   margin-bottom: 15px;
   div.ant-form-item-label {
      display: none;
   }
   .ant-form-explain {
      display: none;
   }
   .ant-col-24 {
      width: 30%;
   }
`

export const Appointments = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-bottom: 20px;
`

export const monthsGroup = styled.div`
   display: flex;
   flex-direction: center;
   align-items: center;
`
