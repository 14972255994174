import { FieldRenderProps } from 'react-final-form'
import { Input } from 'antd'
import withFormBlock from '../FormBlock/withFormBlock'
import React from 'react'

interface FormInputProps extends FieldRenderProps<any, any> {
   rest: any
}

const FormTextArea = ({ input, ...rest }: FormInputProps) => {
   return <Input.TextArea id={input.name} {...input} {...rest} />
}

export default withFormBlock<FormInputProps>(FormTextArea)
