import { IntlLabel, LabelKey } from '@plandok/i18n'
import { Dropdown, Spin } from 'antd'
import DropdownMenu from 'components/app-items/DropdownMenu'
import React from 'react'

import { tags } from '../../../constants'
import * as SC from '../../SetupForm/styles'

interface LabelWithTagSelectorProps {
   label: LabelKey
   onTagSelected: (template: string, tag: string) => void
   premium?: boolean
   reminder: boolean
   template: string
   disabled?: boolean
}

export default function LabelWithTagSelector({
   label,
   onTagSelected,
   premium,
   reminder,
   template,
   disabled = false,
}: LabelWithTagSelectorProps) {
   const onItemSelect = (key: string) => onTagSelected(template, key)

   return (
      <Spin spinning={!premium && reminder}>
         <SC.HStack>
            <IntlLabel label={label} />
            <Dropdown
               placement="topCenter"
               overlay={<DropdownMenu options={tags} onItemSelect={onItemSelect} />}
               disabled={disabled}>
               <SC.TagButton label="button.reminder.addTag" smallRadius minorBtn ghost upperCase={false} />
            </Dropdown>
         </SC.HStack>
      </Spin>
   )
}
