import { mediaMdDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const Card = styled.div<any>`
   background: white;
   border-radius: 10px;
   color: ${p => p.theme.primaryColor};
   box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
   display: flex;
   flex-direction: column;
   width: 100%;
   max-width: 418px;
   margin-right: 40px;
   padding: 30px 60px 30px 60px;
   text-align: center;
   justify-content: space-between;

   ${mediaMdDown(css`
      max-width: none;
      padding: 10px 20px;
   `)}

   h2 {
      color: ${p => p.theme.primaryColor};
      margin-bottom: 30px;
   }
   img,
   p {
      margin-bottom: 60px;
   }
   button {
      margin-top: auto;
   }
   img {
      max-width: 125px;
      max-height: 125px;
      align-self: center;
   }
` as any

export const Container = styled.div`
   display: flex;
   justify-content: center;
   margin-top: 40px;
   margin-bottom: 10px;

   ${mediaMdDown(css`
      ${Card} {
         margin-bottom: 20px;
         h2 {
            font-size: 18px;
            margin-bottom: 20px;
         }
         img {
            margin-bottom: 30px;
         }
         p {
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 30px;
         }
      }

      flex-direction: column;
      padding: 0 15px 0 20px;
   `)}
`
