import Icon from '@ant-design/icons'
import { Button, CustomIcon, Field, Form, getIsMobile, regularClientsOption, Text, useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Checkbox } from 'antd'
import { ModifyMassLayout } from 'components'
import { RoutePath } from 'constants/routes'
import { flow, noop } from 'lodash'
import React, { useState } from 'react'

import * as SC from '../styles'

export default function RewardRegularClientsForm(props: any) {
   const isMobile = getIsMobile()
   const [stateSMS, setStateSMS] = useState(true)
   const [stateEmail, setStateEmail] = useState(true)
   const { navigate } = useLocation()
   const editMessagePage = () => navigate(RoutePath.CAMPAIGN_SETUP)
   const handleSubmit = (form: any) =>
      props.onSubmit({
         ...form,
         price: form.price || 0,
      })

   return (
      <>
         <Form
            onSubmit={handleSubmit}
            onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
            initialValues={props.initialValues}>
            {({ formError, submitting }: any) => {
               return (
                  <>
                     <SC.HeaderText>
                        <CustomIcon type="clientReward" />
                        <Text size={isMobile ? 'medium' : 'mlarge'} weight="semiBold" mb="small">
                           <IntlLabel label="Reward Regular Clients" />
                        </Text>
                        <Text size={isMobile ? 'small' : 'base'}>
                           <IntlLabel label="This campaign automatically sends to clients based on recent appointment activity." />
                        </Text>
                     </SC.HeaderText>
                     <SC.CardContainer>
                        <SC.Text>
                           <Text label="Audience" size="mlarge" weight="semiBold" />
                        </SC.Text>
                        {isMobile ? (
                           <SC.MobileRewardRegularText>
                              <Text size={isMobile ? 'small' : 'base'} mb="small">
                                 <IntlLabel label="Clients with at least" />
                              </Text>
                              <SC.Appointments>
                                 <Field.Select
                                    name="times"
                                    label="&nbsp;"
                                    options={regularClientsOption}
                                    defaultValue="5"
                                    md={3}
                                 />
                                 <Text size={isMobile ? 'small' : 'base'} weight="semiBold" ml="base" mb="none">
                                    <IntlLabel label="appointments" />
                                 </Text>
                              </SC.Appointments>
                              <SC.monthsGroup>
                                 <Text size={isMobile ? 'small' : 'base'} mb="none">
                                    <IntlLabel label="in the last &nbsp;&nbsp;&nbsp;" />
                                 </Text>
                                 <Field.Select
                                    name="month"
                                    label="&nbsp;"
                                    options={regularClientsOption}
                                    defaultValue="2"
                                    md={3}
                                 />
                                 <Text size={isMobile ? 'small' : 'base'} weight="semiBold" mb="none" ml="base">
                                    <IntlLabel label="months." />
                                 </Text>
                              </SC.monthsGroup>
                           </SC.MobileRewardRegularText>
                        ) : (
                           <SC.RewardRegularText>
                              <Text size={isMobile ? 'small' : 'base'} mb="none">
                                 <IntlLabel label="Clients with at least &nbsp;" />
                              </Text>
                              <Field.Select
                                 name="birthday"
                                 label="&nbsp;"
                                 options={regularClientsOption}
                                 defaultValue="5"
                                 md={3}
                              />
                              <Text size={isMobile ? 'small' : 'base'} mb="none">
                                 <IntlLabel label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; appointments in the &nbsp;&nbsp; " />
                              </Text>
                              <Text size={isMobile ? 'small' : 'base'} mb="none">
                                 <IntlLabel label="last &nbsp;" />
                              </Text>
                              <Field.Select
                                 name="birthday"
                                 label="&nbsp;"
                                 options={regularClientsOption}
                                 defaultValue="2"
                                 md={3}
                              />
                              <Text size={isMobile ? 'small' : 'base'} mb="none">
                                 <IntlLabel label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; months." />
                              </Text>
                           </SC.RewardRegularText>
                        )}
                        <Text size="mlarge" weight="semiBold" mb="none">
                           <IntlLabel label="Channel" />
                        </Text>
                        <Text size={isMobile ? 'small' : 'base'} mb="small">
                           <IntlLabel label="Choose what channels campaign will be sent" />
                        </Text>
                        <SC.CheckedBtn>
                           <Checkbox checked={stateSMS} onClick={() => setStateSMS(!stateSMS)}>
                              <span>
                                 <IntlLabel label="SMS" />
                              </span>
                           </Checkbox>
                           <Checkbox checked={stateEmail} onClick={() => setStateEmail(!stateEmail)}>
                              <span>
                                 <IntlLabel label="Email" />
                              </span>
                           </Checkbox>
                        </SC.CheckedBtn>
                     </SC.CardContainer>
                     <Form.Alert text={formError} />
                     <ModifyMassLayout.Footer>
                        <Button
                           block
                           type="primary"
                           label="Edit message"
                           htmlType="submit"
                           loading={submitting}
                           upperCase={false}
                           onClick={editMessagePage}>
                           <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                        </Button>
                     </ModifyMassLayout.Footer>
                  </>
               )
            }}
         </Form>
      </>
   )
}
