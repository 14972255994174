import { mediaMdUp, mediaMobileDown, theme } from '@plandok/core'
import SBox from 'components/styled-components/SBox'
import styled, { css } from 'styled-components'

export const Layout = styled.div<any>`
   min-height: 100vh;
   width: 100%;
   ${mediaMdUp(css`
      background-size: auto;
   `)}
   ${mediaMobileDown(css`
      background: initial;
   `)}

  [data-base-container] {
      min-height: 100vh;
      width: 100%;
      display: flex;
      ${mediaMdUp(css`
         background-size: auto;
      `)}
      ${mediaMobileDown(css`
         background: initial;
      `)}
   }

   [data-form-container] {
      margin: 0 auto;
      max-width: 550px;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      position: relative;
      ${mediaMdUp(css`
         padding-top: ${(props: any) => (props.isRegistration ? '50px' : '200px')};
      `)}
      ${mediaMobileDown(css`
         padding-top: ${(props: any) => (props.isRegistration ? '25px' : '40px')};
      `)}
   }

   [data-title-section] {
      text-align: center;
      position: relative;
      ${mediaMdUp(css`
         margin-bottom: 24px;
      `)}
      ${mediaMobileDown(css`
         margin-bottom: 10px;
      `)}
   }

   [data-title-section] h2 {
      font-size: 35px;
      font-weight: bold;
      color: #13316d;
      margin-bottom: 5px;
      font-family: ${({ theme }) => theme.fontFamily};
   }

   [data-title-section] div {
      color: #13316d;
      font-weight: 200;
      font-size: 24px;
      opacity: 0.9;
   }

   [data-footer-info] {
      text-align: center;
      padding-top: 24px;
      padding-bottom: 24px;
   }

   [data-copyright] {
      margin-top: auto;
      text-align: center;
      margin-bottom: 20px;
   }
   [data-copyright] a {
      margin-left: 10px;
   }

   [data-auth-layout-mobile-text] {
      padding: 0 10px;
      font-size: 16px !important;
   }
` as any

export const Card = styled(SBox)`
   background: white;
   border: none;
   box-shadow: ${theme.boxShadow};

   ${mediaMdUp(css`
      padding: 35px;
      border-radius: ${({ theme }) => theme.borderRadiusBase};
   `)}
   ${mediaMobileDown(css`
      padding: 0 15px;
   `)}
  button {
      margin-top: 15px;
   }
`
