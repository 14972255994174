import { Button, Mutation, withModal } from '@plandok/core'
import { IntlLabel, IntlTemplate } from '@plandok/i18n'
import * as api from 'api'
import { tableStoreFactory } from 'components'
import { App_Client } from 'graphql/generatedTypes'
import React, { useState } from 'react'

import * as SC from '../styles'
import { ClientsForMerge } from '../types/types'

type ModalProps = {
   сlientsForMerge: ClientsForMerge[]
   setAction: (duplicatedClients: App_Client[][]) => void
   checkAction: () => void
}

type ConfirmMergeDuplicateClientsModalProps = {
   modalProps: ModalProps
   onClose: () => void
}

function ConfirmMergeDuplicateClientsModal({ modalProps, onClose }: ConfirmMergeDuplicateClientsModalProps) {
   const [confirm, setConfirm] = useState(true)

   const clientsToMergeCount = modalProps.сlientsForMerge.length

   const mergeClients = async () => {
      try {
         await api.mergeDuplicatedClients({ clients: modalProps.сlientsForMerge })
      } catch (e) {
         console.error('Client merge error')
         return
      }
   }

   const clientsRestСount =
      modalProps.сlientsForMerge.reduce((acc, { rest: restIds }) => acc + restIds.length, 0) + clientsToMergeCount

   const refetchData = () => {
      tableStoreFactory.getTableStoreInstance('clients').fetchFreshData()

      modalProps.checkAction()
   }

   return (
      <>
         <IntlTemplate label="clients.mergeConfirm.modal.description" params={[clientsRestСount]} />
         <SC.StyledCheckboxConfirm onChange={() => setConfirm(confirm => !confirm)}>
            <IntlLabel label="clients.mergeConfirm.checkbox.title" />
         </SC.StyledCheckboxConfirm>
         <SC.ModalFooterConfirmDuplicatesClients>
            <Mutation
               action={mergeClients}
               onSuccess={() => {
                  onClose()
                  refetchData()
               }}>
               {({ loading, action }: any) => (
                  <>
                     <Button
                        disabled={loading}
                        label="clients.mergeConfirm.cancel.btn.title"
                        upperCase={false}
                        onClick={() => {
                           modalProps.setAction([])
                           onClose()
                        }}
                     />
                     <Button
                        loading={loading}
                        label="clients.mergeConfirm.btn.title"
                        type="primary"
                        upperCase={false}
                        disabled={confirm}
                        onClick={action}
                     />
                  </>
               )}
            </Mutation>
         </SC.ModalFooterConfirmDuplicatesClients>
      </>
   )
}

export default withModal('clients.mergeConfirm.modal.title', { contentClassName: 'confirm-duplicates-clients-modal' })(
   ConfirmMergeDuplicateClientsModal,
)
