import { SelectInput, useAppContext } from '@plandok/core'
import * as api from 'api'
import { DateFilterItem, PageControlContainer } from 'components'
import { startOfWeek } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { useStore } from 'store/store'

import StaffWorkTable from './components/StaffWorkTable'
import * as helpers from './helpers'

export default function StaffWorkingPage() {
   const [context] = useAppContext()

   const timeFormat = context.timeFormat

   const [isLoading, setLoading] = useState(false)
   const [data, setData] = useState([])
   const [date, setDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }))

   const locations = useStore(useCallback(state => state.locations, []))
   const locationId = useStore(useCallback(state => state.locationId, []))
   const setLocationId = useStore(useCallback(state => state.setLocationId, []))

   useEffect(() => {
      if (locationId) {
         ;(async () => await fetchEmployeeHours())()
      }
      return () => {}
   }, [date, locationId])

   const fetchEmployeeHours = async () => {
      setLoading(true)
      let response = await api.fetchEmployeesHours(helpers.getFilterParams(new Date(date ?? 0), locationId))
      setLoading(false)

      response?.data && setData(response?.data)
   }

   return (
      <>
         <PageControlContainer mobileColumn>
            <PageControlContainer.Item>
               {locations?.length > 1 ? (
                  <SelectInput
                     filter
                     className="mr-sm"
                     onChange={setLocationId}
                     value={locationId}
                     options={locations.map(item => ({
                        code: item.id,
                        name: item.name,
                     }))}
                  />
               ) : null}
            </PageControlContainer.Item>
            <PageControlContainer.Item>
               <DateFilterItem date={date} changeDate={setDate} viewType="week" />
            </PageControlContainer.Item>
            <PageControlContainer.Space />
         </PageControlContainer>
         <StaffWorkTable
            data={data || []}
            date={date}
            isLoading={isLoading}
            locationId={locationId}
            timeFormat={timeFormat}
            fetchData={fetchEmployeeHours}
         />
      </>
   )
}
