import { useNavigation } from '@plandok/core'
import { getLabel, ReactIntl } from '@plandok/i18n'
import * as api from 'api'
import { ModifyLayout, NoInternetConnection } from 'components'
import { RoutePath } from 'constants/routes'
import { formatDateField } from 'helpers/date/field'
import withFormApi from 'hocs/form/withFormApi'
import React, { useContext } from 'react'
import { useStore } from 'store/store'

import AppointmentForm from './components/AppointmentForm'
import { detailsAppointmentSelect } from './helpers'

export default function ModifyAppointmentPage() {
   const { urlQuery, params } = useNavigation()
   const { lang } = useContext(ReactIntl)
   let urlParams = urlQuery()

   const isEdit = !!params?.id

   const setRescheduleItemDate = useStore(state => state.setRescheduleItemDate)
   const setRescheduleItemTime = useStore(state => state.setRescheduleItemTime)
   const setRescheduleItemStaff = useStore(state => state.setRescheduleItemStaff)

   const rescheduleItemDate = useStore(state => state.rescheduleItemDate)
   const rescheduleItemTime = useStore(state => state.rescheduleItemTime)
   const rescheduleItemStaff = useStore(state => state.rescheduleItemStaff)

   const AppointmentFormWithApi = withFormApi({
      detailsAction: api.fetchAppointmentDetails,
      createAction: api.createAppointment,
      editAction: api.updateAppointment,
      detailsSelect: detailsAppointmentSelect,
      backPath: RoutePath.CALENDAR,
      successMessage: getLabel(`appointment.${isEdit ? 'update' : 'create'}.title`, lang),
      rescheduleItemDate,
      rescheduleItemTime,
      rescheduleItemStaff,
      setRescheduleItemDate,
      setRescheduleItemTime,
      setRescheduleItemStaff,
   })(AppointmentForm)

   return (
      <NoInternetConnection>
         <ModifyLayout title={isEdit ? 'appointment.edit.title' : 'appointment.new.title'}>
            <AppointmentFormWithApi
               defaultEmployeeId={urlParams?.employeeId || ''}
               initialValues={{
                  date: formatDateField(new Date(urlParams?.date ? parseInt(urlParams?.date, 10) : new Date())),
                  locationId: urlParams?.locationId,
                  bookings: [
                     {
                        startTime: urlParams.startTime ? parseInt(urlParams.startTime, 10) : undefined,
                        employeeId: urlParams?.employeeId || '',
                     },
                  ],
               }}
            />
         </ModifyLayout>
      </NoInternetConnection>
   )
}
