import { Avatar } from 'antd'
import { getAvatarSign } from 'helpers/common'
import React from 'react'

import * as SC from '../../../styles'

export default function ClientSearchItem({ client, selectClient }: any) {
   const onSelect = () => selectClient(client.id)

   return (
      <SC.ClientItem onClick={onSelect} data-client-search-item>
         <div data-client-item-avatar>
            <Avatar>{getAvatarSign(client.firstName || client.lastName)}</Avatar>
         </div>
         <div data-client-item-info>
            <div>
               {client.firstName} {client.lastName}
            </div>
            <div>{client.email}</div>
            <div>{client.phoneNumber}</div>
         </div>
      </SC.ClientItem>
   )
}
