import { FieldRenderProps } from 'react-final-form'
import withFormBlock from './FormBlock/withFormBlock'
import React from 'react'
import { IntlLabel } from '@plandok/i18n'
import { getIsMobile } from '@plandok/core'
import styled from 'styled-components'

const Container = styled.div`
   display: flex;
   margin: 60px 40px 30px 20px;
   color: #13316d;
   font-weight: 500;
`

interface FormInputProps {
   submitting?: boolean
   text?: string
}

const PreviewInput = ({ input, submitting, text, meta, ...rest }: FormInputProps & FieldRenderProps<any, any>) => {
   const isMobile = getIsMobile()
   return (
      <Container>
         {isMobile && (
            <>
               <IntlLabel label={text} />: {input.value}
            </>
         )}
         {!isMobile && input.value}
      </Container>
   )
}

export default withFormBlock<FormInputProps>(PreviewInput)
