import { ModifyMassLayout, NotReleased } from 'components'
import { MarketingHash, RoutePath } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import MessageForm from './components/MessageForm'
export const MassageFormWithApi = withFormApi({
   backPath: `${RoutePath.MARKETING}#${MarketingHash.MASSMESSAGES}`,
   successMessage: 'Message',
})(MessageForm)

export default function ModifyMessagePage() {
   return (
      <NotReleased>
         <ModifyMassLayout title="Message" step="SMS setup. Step 1 of 4">
            <MassageFormWithApi />
         </ModifyMassLayout>
      </NotReleased>
   )
}
