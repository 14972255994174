import { LocalStorage } from '@plandok/core'

import axios from './axios'

const appToken = LocalStorage.getAuthToken()
axios.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}/api`
if (appToken) {
   axios.defaults.headers.Authorization = `Bearer ${appToken}`
}

export * from './auth'
export * from './billing'
export * from './calendar'
export * from './client'
export * from './notification'
export * from './setup'
