import Icon, { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { IntlDate, IntlLabel } from '@plandok/i18n'
import React from 'react'
import { getMonth } from 'date-fns'
import { monthOptions, weekOptions } from '../../../../../constants/data'

export default function DatepickerHeader(props: any) {
   const translatedMonth = monthOptions.find(({ code }) => code === getMonth(props.date))?.name

   return (
      <>
         <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--previous"
            onClick={props.decreaseMonth}
         >
            <LeftOutlined />
         </button>
         <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--next"
            onClick={props.increaseMonth}
         >
            <RightOutlined />
         </button>
         <div className="react-datepicker__header">
            <div className="react-datepicker__current-month">
               <IntlLabel label={translatedMonth} /> <IntlDate date={props.date} dateFormat="yyyy" />
            </div>
            <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll" />
         </div>

         <div className="react-datepicker__days">
            {weekOptions.map(({ name }) => (
               <div key={name} className="react-datepicker__day-name">
                  <IntlLabel label={name} />
               </div>
            ))}
         </div>
      </>
   )
}
