import { getCalendarHoursByViewType } from 'epic-calendar/support/helpers'
import { StateCalendar } from 'pages/dashboard/CalendarPage/constants'
import React, { memo } from 'react'

import { getTimeOptions } from '../../pages/dashboard/ModifyAppointmentPage/config/options'
import * as SC from '../styles'
import CurrentTimeIndicator, { ICurrentTimeIndicator } from './CurrentTimeIndicator'

function createTimeNameFromCode(code: number, timeFormat: string) {
   let hours = Math.floor(code / 60)
   const minutes = code % 60

   let formattedHours
   let amPm = ''

   if (timeFormat === '12h') {
      amPm = hours >= 12 ? 'PM' : 'AM'
      hours = hours % 12
      hours = hours ? hours : 12
      formattedHours = hours.toString()
   } else {
      formattedHours = hours.toString()
   }

   const formattedMinutes = minutes.toString().padStart(2, '0')

   return `${formattedHours}:${formattedMinutes} ${amPm}`.trim()
}

export default memo(function TimeColumn(props: ICurrentTimeIndicator) {
   const getTimeColumnByViewType = () => {
      const fullCalendar = [
         ...Array(
            getCalendarHoursByViewType(
               props.customViewTime.startTime,
               props.customViewTime.endTime,
               props.customViewType,
               props.workingHoursStartTime,
               props.workingHoursEndTime,
            ),
         ),
      ].map((_: any, i) => <SC.TimeCell key={i}>{i}:00</SC.TimeCell>)

      switch (props.customViewType) {
         case StateCalendar.CUSTOM_RANGE:
            const timeOptions = getTimeOptions(props.timeFormat)
            const filterCustomHours = timeOptions.filter(
               ({ name, code }) =>
                  name.includes(':00') && code >= props.customViewTime.startTime && code < props.customViewTime.endTime,
            )

            if (props.customViewTime.startTime % 60) {
               filterCustomHours.unshift({
                  name: createTimeNameFromCode(filterCustomHours[0].code - 60, props.timeFormat),
                  code: filterCustomHours[0].code - 60,
               })
            }

            return filterCustomHours.map(({ name, code }) => <SC.TimeCell key={code}>{name}</SC.TimeCell>)
         case StateCalendar.WORKING_HOUR:
            const filterWorkingHours = getTimeOptions(props.timeFormat).filter(
               ({ name, code }) =>
                  name.includes(':00') && code >= props.workingHoursStartTime && code < props.workingHoursEndTime,
            )

            return filterWorkingHours.map(({ name, code }) => <SC.TimeCell key={code}>{name}</SC.TimeCell>)
         case StateCalendar.FULL_CALENDAR:
            return fullCalendar
         default:
            return fullCalendar
      }
   }

   return (
      <SC.TimeColumn>
         <SC.TimeCellHeader />
         {getTimeColumnByViewType()}
         <CurrentTimeIndicator {...props} />
      </SC.TimeColumn>
   )
})
