import { CustomIcon } from '@plandok/core'
import React from 'react'

import * as SC from './styles'

interface Call {
   phoneNumber: string
}

export default function CallAction(props: Call) {
   return (
      <a href={`tel:${props.phoneNumber}`}>
         <SC.PhoneCallButton>
            <CustomIcon type="call" />
         </SC.PhoneCallButton>
      </a>
   )
}
