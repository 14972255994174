import { CustomIcon } from '@plandok/core'
import React from 'react'
import { Link } from 'react-router-dom'

export default function SocialMediaIcon({ href, type }: any) {
   return (
      <Link target="_blank" to={href}>
         <CustomIcon type={type} />
      </Link>
   )
}
