import { CustomIcon } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React from 'react'

export const Status = ({ status }: { status: 'new' | 'cancelled' | 'confirmed' | 'no_show' | 'completed' }) => {
   switch (status) {
      case 'new':
         return (
            <span className="bold" style={{ color: '#4A9CFC', marginLeft: '12px', fontSize: '16px' }}>
               <IntlLabel label="appointment.status.new" />
            </span>
         )
      case 'cancelled':
         return (
            <span className="bold" style={{ color: '#ADB6CC', marginLeft: '12px', fontSize: '16px' }}>
               <IntlLabel label="appointment.status.cancelled" />
            </span>
         )
      case 'confirmed':
         return (
            <>
               <span className="bold" style={{ color: '#29C0CC', marginLeft: '12px' }}>
                  <IntlLabel label="appointment.status.confirmed" />
               </span>
               <span style={{ marginLeft: '6px' }}>
                  <CustomIcon type="confirmed" />
               </span>
            </>
         )
      case 'completed':
         return (
            <>
               <span className="bold" style={{ color: '#97D561', marginLeft: '12px', fontSize: '16px' }}>
                  <IntlLabel label="appointment.status.completed" />
               </span>
               <span style={{ marginLeft: '6px' }}>
                  <CustomIcon type="completed" />
               </span>
            </>
         )
      case 'no_show':
         return (
            <>
               <span className="bold" style={{ color: '#FC6793', marginLeft: '12px', fontSize: '16px' }}>
                  <IntlLabel label="appointment.status.notShow" />
               </span>
               <span style={{ marginLeft: '6px' }}>
                  <CustomIcon type="noShow" />
               </span>
            </>
         )
      default:
         return (
            <>
               <span className="bold" style={{ color: '#FC6793', marginLeft: '12px', fontSize: '16px' }}>
                  <IntlLabel label="appointment.status.notShow" />
               </span>
               <span style={{ marginLeft: '6px' }}>
                  <CustomIcon type="noShow" />
               </span>
            </>
         )
   }
}
