import { Button } from '@plandok/core'
import React from 'react'

type RescheduleBtnProps = {
   rescheduleItem: () => void
}

export default function RescheduleBtn({ rescheduleItem }: RescheduleBtnProps) {
   return (
      <Button
         type="primary"
         label="btn.reschedule"
         whiteBtn
         smallRadius
         bold
         style={{ border: '1px solid #CED3E0' }}
         onClick={rescheduleItem}
      />
   )
}
