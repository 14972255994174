import { withModal } from '@plandok/core'
import * as api from 'api'
import React from 'react'

import withFormApi from '../../../../hocs/form/withFormApi'
import ServiceForm from '../../../../pages/dashboard/ModifyServicePage/components/ServiceForm'

export const ServiceFormWithApi = withFormApi({
   createAction: api.createService,
   successMessage: 'Service Created',
})(ServiceForm)

const AddAppointmentServiceModal = withModal('service.new.title', { wide: true, fullScreen: true })(props => (
   <ServiceFormWithApi {...props} initialValues={{ duration: 60, isBookableOnline: true }} />
))
export default AddAppointmentServiceModal
