import { AuthBlock, formatTime, getIsMobile, PrintButton, SelectInput, Text, useAppContext } from '@plandok/core'
import { IntlDate, IntlLabel, useIntlOptions } from '@plandok/i18n'
import { DateFilterItem } from 'components'
import { PermissionType } from 'constants/auth'
import { viewTypeOptions } from 'constants/data'
import { endOfWeek } from 'date-fns'
import { sortBy } from 'lodash'
import { CalendarStateProps } from 'pages/dashboard/CalendarPage/useCalendarState'
import React, { useCallback, useEffect, useState } from 'react'
import { useStore } from 'store/store'

import CalendarAddItemBtn from '../CalendarAddItemBtn'
import { staffOptions } from './constants'
import * as SC from './styles'

type CalendarFilterProps = {
   openSetBlockTime: () => void
   createAppointment: () => void
   timeFormat: string
   calendarState: CalendarStateProps
}

export default function CalendarFilter({
   openSetBlockTime,
   createAppointment,
   timeFormat,
   calendarState,
}: CalendarFilterProps) {
   const [lastScroll, setLastScroll] = useState(0)
   const [context] = useAppContext()

   const { convertOptions } = useIntlOptions()
   const isMobile = getIsMobile()

   const locationId = useStore(useCallback(state => state.locationId, []))

   const sortBookings = sortBy(calendarState?.data?.bookings, ['appointmentDate', 'startTime'])
   const showAllStaff = calendarState?.isLoading || calendarState.employeesOptions?.length > 1
   const isLocationSelectVisible = calendarState.locationOptions?.length > 1
   const isPremium = context.subscription?.isPremium

   const isStaffSelectVisible = !!calendarState.employeesOptions.length
   const isWeekMode = calendarState.filterState.viewType === 'week'
   const rangeDate = calendarState.filterState.date

   const setLastTopScroll = () => {
      const calendarBodyElement = document.getElementById('epic-calendar__body')

      if (calendarBodyElement?.scrollTop) {
         setLastScroll(calendarBodyElement.scrollTop)
      }
   }

   useEffect(() => {
      const calendarBodyElement = document.getElementById('epic-calendar__body')

      calendarBodyElement?.scroll?.({ top: lastScroll })
   }, [lastScroll])

   return (
      <SC.StyledFilterContainer isMobile={isMobile}>
         <div data-filter-group>
            {isLocationSelectVisible && (
               <div data-filter-item-select>
                  <SelectInput
                     options={calendarState.locationOptions}
                     onChange={selected => calendarState.changeFilter('location_id', selected)}
                     value={locationId}
                     onCustomChange={setLastTopScroll}
                  />
               </div>
            )}
            <div data-filter-item-select>
               {isStaffSelectVisible && (
                  <SelectInput
                     options={[
                        ...(showAllStaff ? convertOptions(staffOptions) : []),
                        ...(calendarState.employeesOptions || []),
                     ]}
                     onChange={selected => calendarState.changeFilter('staff', selected)}
                     value={calendarState.filterState.staff}
                     onCustomChange={setLastTopScroll}
                  />
               )}
            </div>
         </div>
         <div data-filter-group data-filter-date-group>
            <DateFilterItem
               date={calendarState.filterState.date}
               viewType={calendarState.filterState.viewType}
               changeDate={selected => calendarState.changeFilter('date', selected)}
               // changeDate={(selected) => calendarState.changeFilter('date', flow(selected as any, setLastTopScroll))}
            />
         </div>
         <div data-filter-group>
            <PrintButton isPremium={isPremium}>
               <Text mb="none" weight="semiBold" color="#000">
                  <IntlLabel label="calendar.print.appointmentsBetween.title" />
                  {isWeekMode ? (
                     <>
                        <IntlDate date={rangeDate} dateFormat="dd MMMM yyyy" />
                        <IntlLabel label="calendar.print.appointmentsAnd.title" />
                        <IntlDate date={endOfWeek(rangeDate)} dateFormat="dd MMMM yyyy" />
                     </>
                  ) : (
                     <IntlDate date={rangeDate} dateFormat="dd MMMM yyyy" />
                  )}
               </Text>
               {sortBookings?.map((booking: any) => (
                  <SC.PrintContainer key={booking.id}>
                     <SC.PrintHeader>
                        <Text mb="none" weight="semiBold" size="xsmall" color="#000">
                           <IntlDate date={new Date(booking.appointmentDate)} dateFormat="dd MMMM yyyy" />
                        </Text>
                        &nbsp;
                        <Text mb="none" weight="semiBold" size="xsmall" color="#000">
                           {booking.clientFirstName} {booking.clientLastName}
                        </Text>
                     </SC.PrintHeader>
                     <SC.PrintContent>
                        <Text mb="none" size="xsmall" color="#000">
                           {formatTime(booking.startTime, timeFormat)} -&nbsp;
                           {formatTime(booking.startTime + booking.duration, timeFormat)}&nbsp;
                        </Text>
                        <Text mb="none" size="xsmall" color="#000">
                           {booking.duration}
                           &nbsp;
                           <IntlLabel label="dropdown.time.minutes" />
                           &nbsp;
                        </Text>
                        <Text mb="none" size="xsmall" color="#000">
                           {booking.employeeFirstName} {booking.employeeLastName}&nbsp;
                        </Text>
                        <Text mb="none" size="xsmall" color="#000">
                           {booking.serviceName}
                        </Text>
                     </SC.PrintContent>
                  </SC.PrintContainer>
               ))}
            </PrintButton>
            <div data-filter-item-select>
               <SelectInput
                  options={convertOptions(viewTypeOptions)}
                  value={calendarState.filterState.viewType}
                  onChange={selected => calendarState.changeFilter('viewType', selected)}
               />
            </div>
            <div>
               <AuthBlock section={PermissionType.CAN_BOOK_APPOINTMENTS}>
                  <CalendarAddItemBtn openSetBlockTime={openSetBlockTime} createAppointment={createAppointment} />
               </AuthBlock>
            </div>
         </div>
      </SC.StyledFilterContainer>
   )
}
