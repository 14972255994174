import { startOfDayLocalized } from '@plandok/core'
import { addMinutes, format } from 'date-fns'
import React from 'react'

import * as SC from '../../styles'

type TimeCardContentProps = {
   dynamicStartTime: number
   dynamicEndTime: number
   timeCard: {
      startTime: number
      endTime: number
   }
   timeFormat: string
}

export default function TimeCardContent({
   dynamicStartTime,
   dynamicEndTime,
   timeCard,
   timeFormat,
}: TimeCardContentProps) {
   const formatTime = (time: number) =>
      format(addMinutes(startOfDayLocalized(new Date()), time), timeFormat === '12h' ? 'h:mm a' : 'HH:mm')

   return (
      <SC.TimeCardContent>
         <span>
            <span>{formatTime(dynamicStartTime || timeCard.startTime)}</span> -
            <span> {formatTime(dynamicEndTime || timeCard.endTime)}</span>
         </span>
      </SC.TimeCardContent>
   )
}
