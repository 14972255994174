import { memo } from 'react'
import React from 'react'

import { translatesTemplate } from '../helpers'
import { LabelKey } from '../types'
import { IntlConsumer } from './'

const IntlTemplate = ({ label, params }: { label?: LabelKey; params: any[] }) => {
   if (!label) return null

   return <IntlConsumer>{({ lang }) => translatesTemplate(label, lang, params)}</IntlConsumer>
}

export default memo(IntlTemplate)
