import { Table as AntTable } from 'antd'
import styled from 'styled-components'

export const Container = styled.div``

export const Table = styled(AntTable)`
   .ant-table {
      border-radius: 10px;
   }
`
