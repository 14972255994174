import React from 'react'

import SetupForm from '../SetupForm'

export default function SetupCancellationForm() {
   return (
      <SetupForm
         notificationId="cancellation"
         checkBoxLabel="input.enableCancellation.label"
         checkBoxDescription="input.enableCancellations.description"
      />
   )
}
