import { Button, Form } from '@plandok/core'
import { IntlLabel, LabelKey } from '@plandok/i18n'
import { Checkbox, message } from 'antd'
import * as api from 'api'
import { PermissionType } from 'constants/auth'
import useQuery from 'hooks/data/useQuery'
import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import * as helpers from '../helpers'

const Container = styled.div`
   display: flex;
   flex-direction: column;
   max-width: 1200px;
   margin: 0 auto 0 0;

   button {
      margin: 20px auto 10px auto;
      max-width: 220px;
   }
`

const Cell = styled.div<any>`
   padding: 10px;

   &:first-of-type {
      text-align: left !important;
      width: 200%;
      min-width: 120px;
      margin-right: auto;
      color: #1a263a;
   }
   &:not(:first-of-type) {
      width: 100%;
      min-width: 50px;
      border-left: 1px solid #cecece;
      display: flex;
      justify-content: center;
   }
   ${p => (p.disabled ? 'background: whitesmoke' : '')}
`

const Row = styled.div<any>`
   display: flex;
   border: ${p => (p.isHeader ? 'none' : '1px solid #cecece')};
   ${p => (p.isHeader ? 'margin-top: 5px' : '')};

   ${Cell} {
      ${p => (p.isHeader ? 'border: none' : '')};
      ${p => (p.isHeader ? 'font-weight: 600' : '')};
      ${p => (p.isHeader ? 'color: #1a263a' : '')};
      ${p => (p.isHeader ? 'padding-bottom: 2px' : '')};
      ${p => (p.isHeader ? 'padding-left: 2px' : '')};
   }
`

const TableHeader = ({ title }: { title: LabelKey }) => (
   <Row isHeader>
      <Cell>
         <IntlLabel label={title} />
      </Cell>
      <Cell>
         <IntlLabel label="title.permission.basic" />
      </Cell>
      <Cell>
         <IntlLabel label="title.permission.low" />
      </Cell>
      <Cell>
         <IntlLabel label="title.permission.med" />
      </Cell>
      <Cell>
         <IntlLabel label="title.permission.high" />
      </Cell>
      <Cell>
         <IntlLabel label="title.permission.owner" />
      </Cell>
   </Row>
)

const PermissionInput = ({ label, input, disabled }: any) => {
   const curVal: string[] = input.value || []

   const onSelect = (val: string) => () =>
      input.onChange(curVal.includes(val) ? curVal.filter(e => e !== val) : [...curVal, val])

   return (
      <Row>
         <Cell>
            <IntlLabel label={label} />
         </Cell>
         <Cell disabled={disabled}>
            <Checkbox disabled={disabled} checked={curVal.includes('basic')} onChange={onSelect('basic')} />
         </Cell>
         <Cell disabled={disabled}>
            <Checkbox disabled={disabled} checked={curVal.includes('low')} onChange={onSelect('low')} />
         </Cell>
         <Cell disabled={disabled}>
            <Checkbox disabled={disabled} checked={curVal.includes('medium')} onChange={onSelect('medium')} />
         </Cell>
         <Cell disabled={disabled}>
            <Checkbox disabled={disabled} checked={curVal.includes('high')} onChange={onSelect('high')} />
         </Cell>
         <Cell disabled>
            <Checkbox checked disabled />
         </Cell>
      </Row>
   )
}

const PermissionField = ({ name, ...props }: any) => <Field {...props} name={name} component={PermissionInput} />

export default function PermissionForm() {
   const { data } = useQuery({ fetchData: api.fetchUserPermissions })

   const initValues = helpers.extractPermissionsInitialValues(data)

   return (
      <Form
         onSubmit={api.updateUserPermissions}
         initialValues={initValues}
         formatForm={helpers.formatPermissionRequestParams}
         onSuccess={() => message.success(<IntlLabel label="notification.success.update" />)}>
         {({ submitting }: any) => (
            <Container>
               <p>
                  <IntlLabel label="text.permisionTab" />
               </p>
               <TableHeader title="title.permission.locations" />
               <PermissionField name={PermissionType.ACCESS_ALL_LOCATIONS} label="title.allLocations.permission" />
               <TableHeader title="title.permission.booking" />
               <PermissionField
                  name={PermissionType.ACCESS_OWN_CALENDAR}
                  label="title.accessCalendar.permission"
                  disabled
               />
               <PermissionField
                  name={PermissionType.ACCESS_OTHER_CALENDARS}
                  label="title.accessOtherCalendar.permission"
               />
               <PermissionField name={PermissionType.CAN_BOOK_APPOINTMENTS} label="title.canBook.permission" />
               <PermissionField
                  name={PermissionType.CAN_DRAG_APPOINTMENTS}
                  label="title.dragAndExtend.appointment.permission"
               />
               <PermissionField name={PermissionType.CAN_SEE_CLIENT_INFO} label="title.contactInfo.permission" />
               <PermissionField name={PermissionType.CLIENTS} label="title.clients.permission" />
               <PermissionField name={PermissionType.MESSAGES} label="title.messages.permission" />
               <PermissionField name={PermissionType.SERVICES} label="title.services.permission" />
               <TableHeader title="title.permission.staff" />
               <PermissionField name={PermissionType.EMPLOYEES} label="title.staffMembers.permission" />
               <PermissionField name={PermissionType.WORKING_HOURS} label="title.workingHours.permission" />
               <PermissionField name={PermissionType.CLOSED_DATES} label="tabs.closedDates.title" />
               <PermissionField name={PermissionType.PERMISSION_LEVELS} label="title.permissionLevels.permission" />
               <TableHeader title="title.permission.setup" />
               <PermissionField name={PermissionType.ACCOUNT_SETTINGS} label="title.accountSetup.permission" />
               <PermissionField name={PermissionType.CLIENT_SETTINGS} label="title.clientSettings.permission" />
               <PermissionField name={PermissionType.ONLINE_BOOKINGS} label="title.onlineBookings.permission" />
               <PermissionField name={PermissionType.ANALYTICS} label="title.analytics.permission" />
               <PermissionField name={PermissionType.MARKETING} label="title.marketing.permission" />
               <Button label="btn.save.changes" htmlType="submit" minorBtn loading={submitting} />
            </Container>
         )}
      </Form>
   )
}
