import { CustomIcon, Text } from '@plandok/core'
import { IntlTemplate } from '@plandok/i18n'
import { getPlanPriceByStaffCount } from 'helpers/common'
import React, { ReactNode, useState } from 'react'

import * as SC from '../Modal/styles'

type TableData = {
   key: number
   staff: string
   price: string
}

type TableColumns = {
   title: () => ReactNode
   dataIndex: string
   render: (text: string) => ReactNode
}

type PricingCalculateProps = {
   staffCount?: number
}

export const TABLE_DATA: TableData[] = [
   {
      key: 1,
      staff: '1',
      price: '9.99€',
   },
   {
      key: 2,
      staff: '2',
      price: '18.99€',
   },
   {
      key: 3,
      staff: '3-5',
      price: '25.99€',
   },
   {
      key: 4,
      staff: '5+',
      price: '+ 1.99€',
   },
   {
      key: 5,
      staff: '20+',
      price: '+ 1.29€',
   },
]

export const TABLE_COLUMNS: TableColumns[] = [
   {
      title: () => <Text colorType="base" size="base" weight="semiBold" mb="none" label="pricing.numberStuff" />,
      dataIndex: 'staff',
      render: (text: string) => (
         <Text colorType="base" size="base" mb="none" lh="small">
            {text}
         </Text>
      ),
   },
   {
      title: () => <Text colorType="base" size="base" weight="semiBold" mb="none" label="premium.price.perMonth" />,
      dataIndex: 'price',
      render: (text: string) => (
         <div>
            {text.includes('+') ? (
               <Text size="base" lh="small" colorType="base" mb="none">
                  <IntlTemplate params={[text]} label="pricing.extra.member" />
               </Text>
            ) : (
               <Text colorType="base" mb="none" size="base" lh="small">
                  {text}
               </Text>
            )}
         </div>
      ),
   },
]

export default function PricingCalculate({ staffCount }: PricingCalculateProps) {
   const [modalStaffCount, setModalStaffCount] = useState(staffCount || 1)

   const decrement = () => {
      if (modalStaffCount === 1) {
         return
      }

      setModalStaffCount(modalStaffCount => modalStaffCount - 1)
   }

   const increment = () => setModalStaffCount(modalStaffCount => modalStaffCount + 1)

   return (
      <div className="text-center">
         <SC.DependingDescription colorType="base" label="premium.membership" />
         <SC.PriceCalculatedDescription colorType="base" label="pricing.calculated" mb="xmedium" />
         <SC.CustomTable columns={TABLE_COLUMNS} dataSource={TABLE_DATA} pagination={false} />
         <SC.PriceCalculator size="mlarge" weight="semiBold" label="pricing.calculator" mb="medium" />
         <SC.ContainerCounter>
            <Text label="pricing.numberStuff" mb="none" size="medium" lh="mlarge" style={{ marginRight: '16px' }} />
            <div onClick={decrement}>
               <CustomIcon type="decreaseCircleIcon" />
            </div>
            <SC.InputCalc type="text" value={modalStaffCount} readOnly />
            <div onClick={increment}>
               <CustomIcon type="increaseCircleIcon" />
            </div>
         </SC.ContainerCounter>
         <SC.WrapperPrice>
            <Text colorType="base" weight="bold" mb="none">
               {getPlanPriceByStaffCount(modalStaffCount)}€
            </Text>
            <Text colorType="base" label="period.perMonth" mb="none" size="small" ml="xxsmall" lh="base" />
         </SC.WrapperPrice>
      </div>
   )
}
