import { Button, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { useOnlineBookingLinkQuery } from 'graphql/queries/onlineBookingLink.generated'
import React from 'react'
import useClipboard from 'react-use-clipboard'

import * as SC from '../styles'

function FacebookLinkForBookingsModal() {
   const { data: linkData } = useOnlineBookingLinkQuery()

   const copyUrl = linkData?.onlineBookingLink ? linkData?.onlineBookingLink : ''
   const [, setCopied] = useClipboard(copyUrl, {
      successDuration: 1000,
   })

   return (
      <SC.BookingLinksContainer>
         <h2>
            <IntlLabel label="ob.fbLinkModal.title" />
         </h2>
         <h3>
            <IntlLabel label="ob.fbLinkModal.description" />
         </h3>
         <div className="link-box">{copyUrl}</div>
         <div className="link-steps">
            <ol>
               <li>
                  <IntlLabel label="ob.fbLinkModal.step1" />
               </li>
               <li>
                  <IntlLabel label="ob.fbLinkModal.step2" />
               </li>
               <li>
                  <IntlLabel label="ob.fbLinkModal.step3" />
               </li>
            </ol>
         </div>
         <SC.ModalFooter>
            <span>
               <Button label="ob.btn.copyLink" type="primary" bold upperCase={false} onClick={setCopied} />
               {/*{isCopied && message.success(<IntlLabel label="ob.linkModalCopySuccess.title" />)}*/}
            </span>
         </SC.ModalFooter>
      </SC.BookingLinksContainer>
   )
}

export default withModal(null, { wide: true })(FacebookLinkForBookingsModal)
