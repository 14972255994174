import Icon from '@ant-design/icons'
import { Button, Mutation, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React from 'react'

import * as SC from '../styles'

const entityTranslation = {
   category: 'modal.entity.category',
   referal: 'modal.entity.referal',
   resource: 'modal.entity.resource',
   location: 'modal.entity.location',
}

function ConfirmDeleteModal({ hideAllModals, modalProps = { closeAll: true }, onClose }: any) {
   const onSuccess = () => {
      if (modalProps.closeAll) {
         hideAllModals()
      } else {
         onClose()
      }

      if (modalProps.onSuccess) {
         modalProps.onSuccess()
      }
   }

   return (
      <SC.DeleteClientContainer>
         <Icon type="delete" data-delete-icon />
         <div>
            <IntlLabel label="modal.deleteItem.confirm.0" /> <br />
            <IntlLabel label="modal.deleteItem.confirm.1" />{' '}
            <IntlLabel label={(entityTranslation as any)[modalProps.entityName]} /> <b>{modalProps.name}</b>{' '}
            <IntlLabel label="modal.deleteItem.confirm.2" />
         </div>
         <SC.ModalFooter>
            <Mutation action={modalProps.action} id={modalProps.id} onSuccess={onSuccess}>
               {({ loading, action }: any) => (
                  <>
                     <Button disabled={loading} label="btn.cancel" onClick={onClose} />
                     <Button loading={loading} label="btn.confirmDelete" danger onClick={action} />
                  </>
               )}
            </Mutation>
         </SC.ModalFooter>
      </SC.DeleteClientContainer>
   )
}

export default withModal(null)(ConfirmDeleteModal)
