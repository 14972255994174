import { useAppContext } from '@plandok/core'
import { ModalContainer } from 'components'
import React, { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { dashboardRoute } from 'routing/dashboardRoute'
import { publicRoute } from 'routing/publicRoute'
import { useStore } from 'store/store'

let __CURRENT_VERSION__ = '1.0.2'

function AppRouter() {
   const store = useStore()
   const [state] = useAppContext()
   const { isAuthenticated, isLoading } = state

   useEffect(() => {
      fetch('/version.json', {
         cache: 'no-store',
      })
         .then(res => {
            return res.json()
         })
         .then((data: { version: string }) => {
            if (data.version !== __CURRENT_VERSION__) {
               store.setSystems({ displayUpdateAlert: true, displayUpdateButton: true })
            }
         })
   }, [])

   const element = useRoutes([...publicRoute(isAuthenticated), ...dashboardRoute(isAuthenticated, isLoading)])

   return (
      <>
         {element}
         <ModalContainer />
      </>
   )
}

export default AppRouter
