import {
   ApolloClient,
   createHttpLink,
   InMemoryCache,
   MutationOptions,
   OperationVariables,
   QueryOptions,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { relayStylePagination } from '@apollo/client/utilities'
import { message as antMessage } from 'antd'
import { onError } from 'apollo-link-error'

import * as helpers from './helpers'
import * as mutation from './mutations/mutations'
import * as query from './queries/queries'

const httpLink = createHttpLink({
   uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql/app`,
})

const authLink = setContext((_, { headers }) => {
   // get the authentication token from local storage if it exists
   const token = localStorage.getItem('app-token')

   // get unauthorized error state and call refresh token again
   onError(({ graphQLErrors, networkError, operation, forward }: any) => {
      if (graphQLErrors) {
         for (let err of graphQLErrors) {
            if (!err) return null
            switch (err.extensions.code) {
               case 'UNAUTHENTICATED':
                  // error code is set to UNAUTHENTICATED
                  // when AuthenticationError thrown in resolver

                  // modify the operation context with a new token
                  const oldHeaders = operation.getContext().headers
                  operation.setContext({
                     headers: {
                        ...oldHeaders,
                        authorization: token ? `Bearer ${token}` : '',
                     },
                  })
                  // retry the request, returning the new observable
                  return forward(operation)
            }
         }
      }
      if (networkError) {
         antMessage.error(`[Network error]: ${networkError}`)
         // if you would also like to retry automatically on
         // network errors, we recommend that you use
         // apollo-link-retry
      }
   })

   // return the headers to the context so httpLink can read them
   return {
      headers: {
         ...headers,
         authorization: token ? `Bearer ${token}` : '',
      },
   }
})

const graphqlClient = new ApolloClient({
   link: authLink.concat(httpLink),
   cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
         Query: {
            fields: {
               reports: relayStylePagination(),
            },
         },
      },
   }),
   defaultOptions: {
      watchQuery: {
         fetchPolicy: 'network-only',
      },
      query: {
         fetchPolicy: 'network-only',
         notifyOnNetworkStatusChange: true,
      },
   },
})

/**
 * Function execute mutation without hooks
 * @example  mutate(mutation.UPDATE_CLOSED_DATE)(formValues)
 */
const mutate =
   (mut: MutationOptions['mutation']) =>
   (input?: OperationVariables, options?: Omit<MutationOptions<any, OperationVariables>, 'mutation'>) =>
      graphqlClient
         .mutate({ ...options, mutation: mut, variables: { input }, ...options })
         .then(helpers.mapMutationResponseResult)
         .catch(helpers.mapMutationErrorResponse)

/**
 * Function execute query without hooks
 * @example  query(queryCall.CLOSED_DATES)
 */
const queryCall = (query: QueryOptions['query'], variables?: OperationVariables, dataKey?: string) => async () => {
   const response = await graphqlClient.query({ query, variables })
   try {
      return dataKey ? response.data[dataKey] : response.data
   } catch (error) {
      antMessage.error('Failed to perform action')
      return null as any
   }
}

export { graphqlClient, mutate, mutation, query, queryCall }
export * from './types'
