import { LabelKey } from '@plandok/i18n'

import { SetupTabHash } from '../../../constants/routes'

export const getSetupPageLabel = (hash: string): LabelKey => {
   const activeHash = (hash || '').replace('#', '') || SetupTabHash.REMINDERS

   return (
      {
         [SetupTabHash.REMINDERS]: 'tabs.reminders.title',
         [SetupTabHash.CONFIRMATIONS]: 'tabs.confirmations.title',
         [SetupTabHash.RESCHEDULE]: 'tabs.reschedules.title',
         [SetupTabHash.CANCELLATIONS]: 'tabs.cancellations.title',
         [SetupTabHash.THANK_YOU]: 'tabs.thanks.title',
      } as any
   )[activeHash] as LabelKey
}
