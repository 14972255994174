// THIS FILE IS GENERATED, DO NOT EDIT!
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../generatedTypes'
const defaultOptions = {} as const
export type ReportAppointmentListQueryVariables = Types.Exact<{
   offset?: Types.InputMaybe<Types.Scalars['Int']>
   limit?: Types.InputMaybe<Types.Scalars['Int']>
   from: Types.Scalars['Date']
   to: Types.Scalars['Date']
   locationId?: Types.InputMaybe<Types.Scalars['Uuid']>
   employeeId?: Types.InputMaybe<Types.Scalars['Uuid']>
}>

export type ReportAppointmentListQuery = {
   __typename?: 'AppQuery'
   reports?: {
      __typename?: 'App_Reports'
      appointmentsReport?: {
         __typename?: 'App_AppointmentsReportConnection'
         totalCount?: number | null
         pageInfo: {
            __typename?: 'PageInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            startCursor?: string | null
            endCursor?: string | null
         }
         edges?: Array<{
            __typename?: 'App_AppointmentsReportEdge'
            cursor: string
            node?: {
               __typename?: 'App_ReportAppointment'
               id?: number | null
               clientFirstName?: string | null
               clientLastName?: string | null
               serviceName?: string | null
               time?: string | null
               date?: any | null
               duration?: number | null
               locationName?: string | null
               employeeFirstName?: string | null
               employeeLastName?: string | null
               status?: string | null
               price: { __typename?: 'Money'; amount: string; currency: string }
            } | null
         } | null> | null
      } | null
   } | null
}

export const ReportAppointmentListDocument = gql`
   query ReportAppointmentList(
      $offset: Int
      $limit: Int
      $from: Date!
      $to: Date!
      $locationId: Uuid
      $employeeId: Uuid
   ) {
      reports(from: $from, to: $to, offset: $offset, limit: $limit) {
         appointmentsReport(locationId: $locationId, employeeId: $employeeId) {
            totalCount
            pageInfo {
               hasNextPage
               hasPreviousPage
               startCursor
               endCursor
            }
            edges {
               cursor
               node {
                  id
                  clientFirstName
                  clientLastName
                  serviceName
                  price {
                     amount
                     currency
                  }
                  time
                  date
                  duration
                  locationName
                  employeeFirstName
                  employeeLastName
                  status
               }
            }
         }
      }
   }
`

/**
 * __useReportAppointmentListQuery__
 *
 * To run a query within a React component, call `useReportAppointmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportAppointmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportAppointmentListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      locationId: // value for 'locationId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useReportAppointmentListQuery(
   baseOptions: Apollo.QueryHookOptions<ReportAppointmentListQuery, ReportAppointmentListQueryVariables>,
) {
   const options = { ...defaultOptions, ...baseOptions }
   return Apollo.useQuery<ReportAppointmentListQuery, ReportAppointmentListQueryVariables>(
      ReportAppointmentListDocument,
      options,
   )
}
export function useReportAppointmentListLazyQuery(
   baseOptions?: Apollo.LazyQueryHookOptions<ReportAppointmentListQuery, ReportAppointmentListQueryVariables>,
) {
   const options = { ...defaultOptions, ...baseOptions }
   return Apollo.useLazyQuery<ReportAppointmentListQuery, ReportAppointmentListQueryVariables>(
      ReportAppointmentListDocument,
      options,
   )
}
export type ReportAppointmentListQueryHookResult = ReturnType<typeof useReportAppointmentListQuery>
export type ReportAppointmentListLazyQueryHookResult = ReturnType<typeof useReportAppointmentListLazyQuery>
export type ReportAppointmentListQueryResult = Apollo.QueryResult<
   ReportAppointmentListQuery,
   ReportAppointmentListQueryVariables
>
