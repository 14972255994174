import { useContext } from 'react'
import ReactIntl from '../components/ReactIntl'
import { getLabel, translateStaticTemplate } from '../helpers'
import { getHasPermission, useAppContext } from '@plandok/core'

export default function useIntlOptions() {
   const { lang } = useContext(ReactIntl)
   const [context] = useAppContext()

   const convertOptions = (options: any) =>
      (options || []).reduce((res: any[], e: any) => {
         const hasPermission = (e?.permission && getHasPermission(context, e?.permission)) || !e?.permission

         if (hasPermission) {
            if (e.name?.includes?.('{')) {
               res.push({
                  ...e,
                  name: translateStaticTemplate(e.name, lang),
               })
            } else {
               res.push({
                  ...e,
                  name: getLabel(e.name, lang),
               })
            }
         }
         return res
      }, [])
   return {
      convertOptions,
   }
}
