import { closestCenter, DndContext, useDroppable } from '@dnd-kit/core'
import React, { useState } from 'react'

import TableRow from './TableRow'

function TableBody(props: any) {
   const [, setActiveId] = useState<string | null>(null)

   const handleDragStart = (event: any) => {
      setActiveId(event.active.id)
   }

   const handleDragEnd = () => {
      // const { active, over } = event
      // console.log(event)
      // if (active.id !== over?.id) {
      //     setData((prev) => {
      //         const oldIndex = prev.findIndex((item) => item.id === active.id);
      //         const newIndex = prev.findIndex((item) => item.id === over?.id);
      //         return arrayMove(prev, oldIndex, newIndex);
      //     });
      // }

      setActiveId(null)
   }

   return (
      <tbody className="ant-table-tbody">
         <DndContext collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            {(props.data || []).map((value: any, i: number) => (
               <TableBodyItem
                  i={i}
                  value={value}
                  columns={props.columns}
                  onItemClick={() => (props.onItemClick || console.info)(value.id)}
               />
            ))}
         </DndContext>
      </tbody>
   )
}

function TableBodyItem({ value, columns, onItemClick, i }: { value: any; columns: any[]; onItemClick: any; i: any }) {
   const { isOver, setNodeRef } = useDroppable({
      id: value.id,
   })

   const style = {
      opacity: isOver ? 1 : 0.5,
   }

   const onRowClick = () => (onItemClick || console.info)(value.id)

   return (
      <TableRow
         ref={setNodeRef}
         key={`${value.id}-${i}`}
         style={style}
         value={value}
         columns={columns}
         index={i}
         onClick={onRowClick}
      />
   )
}

export default TableBody
