import styled from 'styled-components'
//@ts-ignore
import search from '../icon/search.svg'

export const ModalWithIconTitle = styled.div`
   display: flex;
   align-items: center;
   flex-direction: column;
   gap: 35px;
`

export const SelectLangList = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: -20px;
`

export const SelectLangLabel = styled.label`
   position: relative;

   &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      background-image: url(${search});
   }
`

export const WrapperListLanguages = styled.div`
   height: 400px;
   overflow: auto;
   max-width: 300px;
   margin: 0 auto;

   &::-webkit-scrollbar {
      width: 0.2rem;
      height: 0.2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
   }
   &::-webkit-scrollbar-track-piece {
      background-color: #e9ecf3;
   }
   &::-webkit-scrollbar-thumb {
      background: #adb6cc;
      border-radius: 10px;
   }

   @media screen and (max-width: 678px) {
      height: 100%;
   }
`

export const SelectLangItem = styled.div`
   display: flex;
   justify-content: space-between;
   padding: 11px 18px;
   cursor: pointer;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   border-radius: 5px;
   background: ${({ theme }) => theme.select && 'linear-gradient(0deg, #f4f7ff, #f4f7ff), #ffffff'};
   span > span:nth-child(2) {
      color: ${({ theme }) => (theme.select ? '#2062c9' : '#13316d')};
      margin-left: 16px;
      margin-right: 5px;
   }
   span > span:nth-child(3) {
      color: ${({ theme }) => (theme.select ? '#95b4e7' : '#adb6cc')};
   }
   svg:nth-child(1) > mask {
      mask-type: alpha;
   }
   svg:nth-child(2) {
      margin-top: 7px;
      stroke: ${({ theme }) => theme.select && '#2472e8'};
   }
`
