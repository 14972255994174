import * as api from 'api'
import { ModifyMassLayout } from 'components'
import { NotReleased } from 'components'
import { MarketingHash, RoutePath } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import EmailMessageForm from './components/EmailMessageForm'

export const EmailMassageFormWithApi = withFormApi({
   createAction: api.createService,
   backPath: `${RoutePath.MARKETING}#${MarketingHash.MASSMESSAGES}`,
   successMessage: 'Message',
})(EmailMessageForm)

export default function ModifyMessagePage() {
   return (
      <NotReleased>
         <ModifyMassLayout title="Message" step="Email setup. Step 1 of 4">
            <EmailMassageFormWithApi />
         </ModifyMassLayout>
      </NotReleased>
   )
}
