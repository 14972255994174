import Icon from '@ant-design/icons'
import { TextInput } from '@plandok/core'
import { withIntlLabel } from '@plandok/i18n'
import React from 'react'

import * as SC from '../styles'

const SearchInput = withIntlLabel({ propKey: 'placeholder' })(TextInput)

export default function MobileClientsFilter(props: any) {
   return (
      <SC.MobileContainer>
         <SearchInput
            value={props.value}
            onChange={props.onChange}
            suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.45)' }} />}
            search
            placeholder="input.clientSearch.placeholder"
         />
      </SC.MobileContainer>
   )
}
