import '../styles/index.less'

import { CustomIcon, Text, useAppContext, useLocation, useModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Popover } from 'antd'
import React, { useState } from 'react'

import { exportDataToCSV } from '../../../../../../helpers/async'
import * as SC from '../styles'

export default function ClientOption({ value = {} }: any) {
   const [visible, setVisible] = useState(false)
   const [context] = useAppContext()
   const [, { showModal }] = useModal()
   const { navigate } = useLocation()

   const isPremium = context.subscription.isPremium

   const goToPricingPage = () => navigate('/billing')
   const openModal = () => showModal({ type: 'IMPORT_CLIENTS' })
   const toggleVisibleChange = (shown: boolean) => setVisible(shown)
   const exportClients = () => {
      if (isPremium) {
         exportDataToCSV('client/export', 'clients')
      }
   }

   return (
      <>
         {value.hasPopover ? (
            <Popover
               content={
                  <div>
                     <SC.PopoverTitle>
                        <CustomIcon type="premium" />
                        <Text colorType="yellow" mb="none" size="medium" weight="medium">
                           <IntlLabel label="title.premiumFeature" />
                        </Text>
                     </SC.PopoverTitle>
                     <SC.PopoverDescription>
                        <Text colorType="base" mb="none" size="small" weight="normal">
                           <IntlLabel label="client.export.premium.description" />
                           <IntlLabel label="client.export.premium.visit" />
                           <Text
                              as="span"
                              colorType="link"
                              mb="none"
                              size="small"
                              weight="medium"
                              onClick={goToPricingPage}
                              isCursor>
                              <IntlLabel label="title.billingPlan" />
                           </Text>
                        </Text>
                     </SC.PopoverDescription>
                  </div>
               }
               trigger="click"
               placement="left"
               open={!isPremium && visible}
               onOpenChange={toggleVisibleChange}
               overlayClassName="client-options-popover">
               <div onClick={exportClients} className="d-flex">
                  <Text size="small" weight="normal" colorType="base" mb="none">
                     <IntlLabel label={value.title} />
                  </Text>
                  {value.icon && (
                     <div style={{ marginTop: '-6px' }}>
                        <CustomIcon type={value.icon} />
                     </div>
                  )}
               </div>
               <Text size="xsmall" weight="normal" colorType="lightBlue" mb="none">
                  <IntlLabel label={value.description} />
               </Text>
            </Popover>
         ) : (
            <Text size="small" weight="normal" colorType="base" mb="none" onClick={openModal}>
               <IntlLabel label={value.title} />
            </Text>
         )}
      </>
   )
}
