import { Button, Form } from '@plandok/core'
import * as api from 'api'
import useQuery from 'hooks/data/useQuery'
import React from 'react'
import styled from 'styled-components'

import BillingInfoFields from './components/BillingInfoFields'

const Container = styled.div`
   border-radius: 15px;
   box-shadow: 0px 1px 3px #00000029;
   padding: 40px;
   background: white;
   width: 100%;
   max-width: 850px;
   margin: 50px auto;
`

const BtnRow = styled.div`
   display: flex;
   button {
      width: 120px;
      margin-left: auto;
   }
`

export default function BillingInfoPage() {
   const { data } = useQuery({ fetchData: api.fetchBillingInfo })

   return (
      <Container>
         <Form
            onSubmit={api.updateBillingInfo}
            initialValues={data}
            successMessage="notification.success.update"
            excludeList={['onlineCustomerId']}>
            {({ submitting, formError }: any) => (
               <>
                  <BillingInfoFields />
                  <Form.Alert text={formError} />
                  <BtnRow>
                     <Button label="btn.save" minorBtn loading={submitting} htmlType="submit" />
                  </BtnRow>
               </>
            )}
         </Form>
      </Container>
   )
}
