export const timeCellMinWidth = 120
export const timeCellWeekMinWidth = 40
export const timeCellHeight = 100

export const disabledTimeBg = 'repeating-linear-gradient(-55deg, #eff2f8, #eff2f8 10px, #f8f9fc 10px, #f8f9fc 20px)'

export const calendarUserHeaderHeight = 50

export const prefixTimeHeight = 40

export const timeColumnWidth = 80
export const timeColumnWidthMobile = 40

export const calendarTopOffset = 161
export const calendarMobileTopOffset = 110

export const calendarTimeCellWidth = 60
export const calendarTimeCellMobileWidth = 45
