import { useLocation, withModal } from '@plandok/core'
import { getLabel, IntlTemplate, ReactIntl } from '@plandok/i18n'
import { RoutePath } from 'constants/routes'
import React, { useContext } from 'react'

import * as SC from '../styles'

type ModalProps = {
   staffPage?: boolean
}

type PremiumUpgradeRequiredModalProps = {
   modalProps: ModalProps
   onClose: () => void
}

function PremiumUpgradeRequiredModal({ modalProps, onClose }: PremiumUpgradeRequiredModalProps) {
   const { lang } = useContext(ReactIntl)
   const { navigate } = useLocation()

   const goToBilling = () => {
      onClose()
      navigate(RoutePath.BILLING)
   }

   return (
      <div className="text-center">
         <SC.UpgradeRequiredTitle>
            <IntlTemplate
               label="modal.premiumUpgradeRequired.description"
               params={[
                  `${getLabel(
                     modalProps?.staffPage
                        ? 'modal.premiumUpgradeRequired.staffMembers'
                        : 'modal.premiumUpgradeRequired.location',
                     lang,
                  )}`,
               ]}
            />
         </SC.UpgradeRequiredTitle>
         <SC.BtnUpgradeSubscription label="btn.upgradeNow" upperCase={false} onClick={goToBilling} />
      </div>
   )
}

export default withModal('modal.premiumUpgradeRequired.title', { contentClassName: 'premium-upgrade-required-modal' })(
   PremiumUpgradeRequiredModal,
)
