import { Tabs } from 'antd'
import withFormBlock from '../field/FormBlock/withFormBlock'
import React from 'react'

function FormTabInput({ input }: any) {
   return (
      <Tabs key={input.value} defaultActiveKey={input.value} onChange={input.onChange} className="mt--1">
         <Tabs.TabPane tab="% Percentage" key="percentage" />
         <Tabs.TabPane tab="EUR Amount  " key="money" />
      </Tabs>
   )
}

export default withFormBlock(FormTabInput)
