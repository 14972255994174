import styled from 'styled-components'

export const Description = styled.div`
   color: #848fab;
   margin-top: 10px;
`

export const SwitchText = styled.div`
   margin-left: 10px;
   color: ${props => props.theme.primaryColor};
   cursor: pointer;
`
