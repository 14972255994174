import { Button, mediaLgDown, mediaMdUp, useModal } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Divider } from 'antd'
import { RoutePath } from 'constants/routes'
import React from 'react'
import styled, { css } from 'styled-components'

export const SPricingItem = styled.div<any>`
   width: 358px;
   min-width: 360px;
   background: white;
   display: flex;
   position: relative;
   flex-direction: column;
   border-top: ${p => (p.active ? '3px solid #13316D' : '1px solid #c1c4c8')};
   border-left: ${p => (p.active ? '3px solid #13316D' : '1px solid #c1c4c8')};
   border-bottom: ${p => (p.active ? '3px solid #13316D' : '1px solid #c1c4c8')};
   ${p =>
      p.active
         ? 'border-right: 3px solid #13316D !important'
         : p.hasBorder
           ? 'border-right: 1px solid #c1c4c8'
           : 'border-right: none'};
   padding: 40px 20px 20px 20px;
   text-align: center;

   &:last-of-type {
      border-right: 1px solid #c1c4c8;
   }

   p[data-pricing-main-name] {
      color: #13316d;
      font-size: 28px;
   }

   button {
      width: 100%;
      max-width: 180px;
   }

   [data-pricing-current-plan] b {
      height: 42px;
      font-weight: bold;
      font-size: 18px;
      color: #13316d;
   }

   [data-pricing-current-plan-mobile] {
      display: none;
   }

   p {
      font-size: 18px;
      color: #0a1c43;
      font-weight: 300;

      &[data-pricing-title-description] {
         margin-top: 40px;
         margin-bottom: 10px;
      }
   }

   p[data-pricing-main-price] {
      p:last-of-type {
         margin-top: -10px;
         color: #13316d;
         font-size: 12px;
         font-weight: 300;
      }
      p:first-of-type,
      p[data-pricing-main-price-free] {
         color: #13316d;
         font-size: 36px;
         font-weight: bold;
         margin-bottom: 0;
         &[data-pricing-main-price-free] {
            margin-bottom: 55px;
         }
      }
   }

   div[role='separator'] {
      margin: 5px 0;
   }

   img {
      margin-bottom: 50px;
   }

   ${mediaLgDown(css`
      width: 100%;
      padding: 28px;
      flex-direction: row;
      margin-bottom: 40px;
      border-right: 1px solid #c1c4c8;
      border-radius: 5px;
      overflow: hidden;

      img {
         display: none;
      }
      div[data-pricing-info-column] {
         width: 100%;
         &:first-of-type {
            display: flex;
            flex-direction: column;
            align-items: center;
         }
      }

      [data-pricing-current-plan] {
         display: none;
      }

      p[data-pricing-title-description] {
         margin-top: 0;
      }

      [data-pricing-current-plan-mobile] {
         margin-top: 5px;
         display: inline;
      }

      p[data-pricing-main-name] {
         margin-bottom: 0;
         font-size: 20px;
      }

      [data-pricing-info-column][data-pricing-info-column-left] {
         justify-content: center;
         display: flex;
         flex-direction: column;
         align-items: center;
      }

      p[data-pricing-main-price-free] {
         font-size: 25px;
         margin-bottom: 15px !important;
      }
      p {
         font-size: 12px;
      }

      p[data-pricing-main-price] {
         height: 40px;
      }
   `)}
`

const ActivePlanIndicator = styled.div`
   ${mediaMdUp(css`
      display: none;
   `)};
   line-height: 14px;
   padding: 1px 28px 0px 28px;
   font-size: 11px;
   font-weight: bold;
   width: 116px;
   height: 30px;
   position: absolute;
   left: -28px;
   top: 15px;
   transform: rotate(-45deg);
   text-transform: uppercase;
   background: #13316d;
   color: white;
`

const PricingItem = ({
   image,
   active,
   price,
   planName,
   header,
   advantages,
   hasBorder,
   planType,
   noButtons,
   isFree,
   fetchData,
   planId,
}: any) => {
   const { navigate } = useLocation()
   const [, { showModal }] = useModal()
   const goToPlanCreate = () => navigate(RoutePath.UPDATE_SUBSCRIPTION, `${planType}/${planId}`)

   const controlBtn =
      active && isFree ? (
         <b className="uppercase">
            <IntlLabel label="btn.currentPlan" />
         </b>
      ) : (
         !isFree &&
         (active ? (
            <Button
               minorBtn
               label="btn.cancelSubscription"
               onClick={() => showModal({ type: 'CONFIRM_UNSUBSCRIBE', modalProps: { onSuccess: fetchData } })}
               style={{ maxWidth: '250px', opacity: 0.7 }}
            />
         ) : (
            <Button minorBtn label="btn.upgradeNow" onClick={goToPlanCreate} />
         ))
      )
   return (
      <SPricingItem active={active} hasBorder={hasBorder} no>
         {active && (
            <ActivePlanIndicator>
               <IntlLabel label="btn.currentPlan" />
            </ActivePlanIndicator>
         )}
         <img src={image} alt="" />
         <div data-pricing-info-column data-pricing-info-column-left>
            <div>
               <p data-pricing-main-name>
                  <IntlLabel label={planName} />
               </p>
               {!isFree ? (
                  price && (
                     <p data-pricing-main-price>
                        <p>{price}</p>
                        <p>
                           <IntlLabel label="period.perMonth" />
                        </p>
                     </p>
                  )
               ) : (
                  <p data-pricing-main-price>
                     <p data-pricing-main-price-free>
                        <IntlLabel label="plan.free" />
                     </p>
                  </p>
               )}
            </div>
         </div>
         <div data-pricing-info-column>
            {!noButtons && <p data-pricing-current-plan>{controlBtn}</p>}
            <p data-pricing-title-description>
               <IntlLabel label={header} />
            </p>
            <Divider />
            {(advantages || []).map((e: any) => (
               <p>
                  <IntlLabel key={e} label={e} />
               </p>
            ))}
            {!noButtons && <p data-pricing-current-plan-mobile>{controlBtn}</p>}
         </div>
      </SPricingItem>
   )
}

export default PricingItem
