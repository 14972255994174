import { MarketingHash } from '../../../constants/routes'

export const MarketingTabsConfig: any = [
   {
      icon: 'overview',
      path: MarketingHash.OVERVIEW,
      title: 'ob.overview.tab',
      description: 'ob.overview.tab.description',
   },
   {
      icon: 'profile',
      path: MarketingHash.MASSMESSAGES,
      title: 'Mass messages',
      description: 'mass messages',
   },
   {
      icon: 'links',
      path: MarketingHash.CAMPAIGNS,
      title: 'Campaigns',
      description: 'campaigns',
   },
]
