import './style.less'

import { getIsMobile, mediaMobileDown } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Spin } from 'antd'
import * as api from 'api'
import cn from 'classnames'
import { AppLayout, HashTabs, MobileBackButton, NoInternetConnection } from 'components'
import { BillingTabHash } from 'constants/routes'
import useQuery from 'hooks/data/useQuery'
import React from 'react'
import styled, { css } from 'styled-components'

import BillingInfoPage from './BillingInfoPage'
import { billingTabs } from './config'
import EstimatedCostsPage from './EstimatedCostsPage'
import InvoicesPage from './InvoicesPage'
import PaymentMethodPage from './PaymentMethodPage'
import PricingPage from './PricingPage'
import { PriceType } from './PricingPage/constants'

const Container = styled.div`
   overflow: auto;
   min-height: calc(100vh - 131px);

   ${mediaMobileDown(css`
      max-height: calc(100vh - 60px - 50px - 60px);
      padding: 0 8px;
   `)};
`

const BillingPage = () => {
   const { data, isLoading, fetchData }: any = useQuery({
      fetchData: api.fetchSubscriptionInfo,
   })

   const tabsWithoutPaymentMethod = billingTabs.filter(({ path }) => path !== BillingTabHash.PAYMENT_METHOD)
   const isFreePlan = data?.type === PriceType.FREE
   const isMobile = getIsMobile()

   return (
      <NoInternetConnection>
         <AppLayout
            mobileHeader={() => (
               <>
                  <MobileBackButton />
                  <div>
                     <IntlLabel label="title.billingPlan" />
                  </div>
                  <div />
               </>
            )}
            headerTitle="title.billingPlan"
            className={cn('billing-page', { 'billing-page__is-mobile': isMobile })}>
            <Spin spinning={isLoading}>
               <HashTabs
                  tabs={isFreePlan ? tabsWithoutPaymentMethod : billingTabs}
                  defaultTab={BillingTabHash.PLAN}
                  isChildrenObserver
                  className="page-tabs-content-overflow">
                  <Container>
                     <PricingPage fetchCurrentPlan={fetchData} />
                  </Container>
                  <Container>
                     <EstimatedCostsPage />
                  </Container>
                  <Container>
                     <BillingInfoPage />
                  </Container>
                  <Container>
                     <InvoicesPage />
                  </Container>
                  {!isFreePlan && (
                     <Container>
                        <PaymentMethodPage />
                     </Container>
                  )}
               </HashTabs>
            </Spin>
         </AppLayout>
      </NoInternetConnection>
   )
}

export default BillingPage
