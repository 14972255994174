import './style.less'

import { AuthBlock, getIsMobile, useAppContext, useModal, useNavigation } from '@plandok/core'
import { Spin } from 'antd'
import { AppLayout, NoInternetConnection } from 'components'
import { PermissionType } from 'constants/auth'
import { RoutePath } from 'constants/routes'
import EpicCalendar from 'epic-calendar/EpicCalendar'
import { formatDateField } from 'helpers/date/field'
import useGetHasPermission from 'hooks/permission/useGetHasPermission'
import BtnShowAllTimes from 'pages/dashboard/CalendarPage/components/BtnShowAllTimes'
import { useCalendarState } from 'pages/dashboard/CalendarPage/useCalendarState'
import React, { useCallback, useEffect, useState } from 'react'
import { isFirefox } from 'react-device-detect'
import { CalendarScrollType, useStore } from 'store/store'

import MultipleAddButton from '../ServicesPage/components/MultipleAddButton'
import BlockTimeSelectBanner from './components/BlockTimeSelectBanner'
import CalendarFilter from './components/CalendarFilter'
import CalendarHeader from './components/CalendarHeader'
import CalendarMobileHeader from './components/CalendarMobileHeader'
import CardTooltip from './components/CardTooltip'
import TimeCardComponent from './components/TimeCardComponent'
import { StateCalendar } from './constants'
import { extractBlockTimeValues, roundDown, roundUp } from './helpers'
import * as service from './service'
import * as SC from './styles'
import { TimeCardData } from './types'

let scrollTimeout: number
let scrollTimeoutForBtn: number

const scrollToCurrentTimeLine = (calendarBodyElement: HTMLElement, calendarScroll: CalendarScrollType) => {
   if (scrollTimeout) {
      clearTimeout(scrollTimeout)
   }

   scrollTimeout = window.setTimeout(() => {
      if (calendarScroll.visitedPage) {
         calendarBodyElement?.scroll?.({ top: calendarScroll.lastScroll })
      } else {
         // Firefox does not support center scroll
         document
            .getElementById('currentTimeIndicator')
            ?.scrollIntoView?.({ block: isFirefox ? 'start' : 'center', behavior: 'smooth' })
      }
   }, 100)
}

function CalendarPage() {
   const [context] = useAppContext()
   const calendarState = useCalendarState()
   const { navigate } = useNavigation()

   const store = useStore()
   const calendarScroll = useStore(state => state.calendarScroll)
   const [containerContext, setContainerContext] = useState<any>()

   const locationId = useStore(useCallback(state => state.locationId, []))

   const [isDisplayBtnShowAllTimes, setIsDisplayBtnShowAllTimes] = useState(
      context.calendarViewTime !== StateCalendar.FULL_CALENDAR,
   )
   const [isSelectAppointmentMode, setIsCreateAppointment] = useState(false)
   const [isDisplayCalendar, setIsDisplayCalendar] = useState(true)
   const [isSelectSlotMode, setBlockTimeMode] = useState(false)
   const [customViewType, setCustomViewType] = useState(context.calendarViewTime)
   const [version, setVersion] = useState(0)

   const setRescheduleItemStaff = useStore(state => state.setRescheduleItemStaff)
   const setRescheduleItemTime = useStore(state => state.setRescheduleItemTime)
   const setRescheduleItemDate = useStore(state => state.setRescheduleItemDate)
   const setRescheduleItemId = useStore(state => state.setRescheduleItemId)
   const rescheduleItemId = useStore(state => state.rescheduleItemId)

   const [, { showModal }] = useModal()

   const selectModeOn = isSelectSlotMode || isSelectAppointmentMode
   const customViewTime = context.customViewTime
   const hours: number[] = []

   const closeSlotSelect = () => {
      setBlockTimeMode(false)
      setIsCreateAppointment(false)
   }

   const openSetBlockTime = () => setBlockTimeMode(true)
   const getHasPermission = useGetHasPermission()

   const checkShowAllTimes = () => {
      const calendarBody = containerContext?.current

      const checkScroll = () => {
         if (calendarBody) {
            const hasScroll = calendarBody.scrollHeight > calendarBody.clientHeight
            const customViewTime = calendarState.data?.customViewTime
            const scrollTop = calendarBody.scrollTop
            const heightToHideFrom = 50
            const isScroll = !hasScroll || scrollTop > heightToHideFrom

            const firstCondition = context.calendarViewTime !== StateCalendar.FULL_CALENDAR
            const secondCondition = context.calendarViewTime === StateCalendar.WORKING_HOUR && isScroll
            const thirdCondition =
               context.calendarViewTime === StateCalendar.CUSTOM_RANGE &&
               (customViewTime?.startTime !== 0 || customViewTime?.endTime !== 1400) &&
               isScroll

            let displayBtnShowAllTimes =
               !calendarState.isLoading && firstCondition && (secondCondition || thirdCondition)

            if (scrollTimeoutForBtn) {
               clearTimeout(scrollTimeoutForBtn)
            }

            scrollTimeoutForBtn = window.setTimeout(() => {
               setIsDisplayBtnShowAllTimes(displayBtnShowAllTimes)
            }, 1000)
         }
      }

      if (calendarBody) {
         const hasScroll = calendarBody.scrollHeight > calendarBody.clientHeight

         if (hasScroll) {
            calendarBody.addEventListener('scroll', checkScroll)
         } else {
            setIsDisplayBtnShowAllTimes(true)
         }
      }

      return () => {
         if (calendarBody) {
            const hasScroll = calendarBody.scrollHeight > calendarBody.clientHeight

            if (hasScroll) {
               calendarBody.removeEventListener('scroll', checkScroll)
            }
         }
      }
   }

   useEffect(() => {
      if (isDisplayCalendar && !calendarState.isLoading && calendarState.calendarData.length) {
         checkShowAllTimes()
      }
   }, [isDisplayCalendar])

   useEffect(() => {
      if (locationId) {
         calendarState.initialFetchData(context.defaultViewRange).then(() => {
            checkShowAllTimes()
         })
      }

      if (rescheduleItemId) {
         setBlockTimeMode(true)
      }

      return () => {
         store.setCalendarScroll({ visitedPage: locationId !== undefined })
      }
   }, [rescheduleItemId, locationId])

   const { viewType, timeOffset } = calendarState.filterState

   const staffFromFilterParams = calendarState.filterState?.staff
   const timeFormat = context.timeFormat
   const isWeekMode = viewType === 'week'

   // useEffect(() => {
   //   if (!isUpdated) {
   //     localStorage.setItem('isUpdated1', 'false');
   //   }
   //
   //   let eventSource: EventSource | undefined;
   //
   //   if (eventUrl) {
   //     const url = new URL(`${process.env.REACT_APP_SEE_URL}`);
   //
   //     url.searchParams.append('topic', `${eventUrl}`);
   //     url.searchParams.append('authorization', `${process.env.REACT_APP_SSE_TOKEN}`);
   //
   //     eventSource = new EventSource(url.toString());
   //
   //     eventSource.onmessage = () => calendarState.fetchData(true);
   //     eventSource.onerror = (err: Event) => console.error('Error when retrieving calendar data', err);
   //
   //     return () => {
   //       if (eventSource) {
   //         eventSource.close();
   //       }
   //     };
   //   }
   // }, [eventUrl, isUpdated]);

   const createAppointment = () => setIsCreateAppointment(true)

   const onTimeSlotClick = (time: number, info: any) => {
      if (!getHasPermission(PermissionType.CAN_BOOK_APPOINTMENTS)) {
         return
      }

      const slotDate = isWeekMode ? info : calendarState.filterState?.date
      const slotDateTime = slotDate?.getTime()
      const slotStaffId = isWeekMode ? staffFromFilterParams : info.id

      const calendarBodyElement = document.getElementById('epic-calendar__body')
      store.setCalendarScroll({ lastScroll: calendarBodyElement ? calendarBodyElement!.scrollTop : 0 })

      if (selectModeOn) {
         closeSlotSelect()

         if (isSelectSlotMode && rescheduleItemId) {
            setRescheduleItemDate(slotDate)
            setRescheduleItemTime(time)
            setRescheduleItemStaff(slotStaffId)

            navigate(`${RoutePath.APPOINTMENT_UPDATE}/${rescheduleItemId}`)

            setRescheduleItemId('')
            return
         }

         if (isSelectSlotMode) {
            return showModal({
               type: 'CREATE_BLOCK_TIME',
               modalProps: {
                  onSuccess: calendarState.fetchData,
                  initialValues: {
                     employeeId: slotStaffId,
                     locationId: locationId,
                     date: formatDateField(slotDate ?? new Date()),
                     startTime: time,
                     endTime: time + 30,
                  },
               },
            })
         }

         return !context?.subscription?.canHandleAction
            ? showModal({ type: 'LATEST_PAYMENT_FAILED' })
            : navigate(
                 `${RoutePath.APPOINTMENT_CREATE}?startTime=${time}&employeeId=${slotStaffId}&locationId=${locationId}&date=${slotDateTime}`,
              )
      }

      return !context?.subscription?.canHandleAction
         ? showModal({ type: 'LATEST_PAYMENT_FAILED' })
         : navigate(
              `${RoutePath.APPOINTMENT_CREATE}?startTime=${time}&employeeId=${slotStaffId}&locationId=${locationId}&date=${slotDateTime}`,
           )
   }

   const onCardClick = (timeCard: TimeCardData) => {
      timeCard.isBlocked
         ? showModal({
              type: 'CREATE_BLOCK_TIME',
              modalProps: {
                 id: timeCard.id,
                 onSuccess: calendarState.fetchData,
                 initialValues: extractBlockTimeValues(timeCard),
              },
           })
         : navigate(`${RoutePath.APPOINTMENT_DETAILS}/${timeCard.appointmentId}`)

      const calendarBodyElement = document.getElementById('epic-calendar__body')
      store.setCalendarScroll({ lastScroll: calendarBodyElement ? calendarBodyElement!.scrollTop : 0 })
   }

   const setPosition = (timeCard: TimeCardData, startTime: any, nextColumnIndex: number, indexChanged: true) => {
      const columnInfo = calendarState?.calendarData?.[nextColumnIndex]?.info
      const nextColumn = isWeekMode ? columnInfo : columnInfo?.id

      const action = () =>
         service
            .updateBookingPosition(timeCard, startTime, nextColumn, isWeekMode)
            .then(() => calendarState.fetchData())

      if (timeCard.isCompoundAppointment && indexChanged && viewType !== 'day') {
         return new Promise(resolve =>
            showModal({
               type: 'CONFIRM_ACTION',
               modalProps: {
                  onExit: () => calendarState.fetchData().then(() => setVersion(version + 1)),
                  label: 'modal.splitAppointment.text',
                  action: () => action().then(resolve),
                  title: 'modal.splitAppointment.title',
               },
            }),
         )
      } else {
         return action()
      }
   }

   const updateSize = (timeCard: TimeCardData, endTime: any) =>
      service.updateBookingSize(timeCard, endTime).then(() => calendarState.fetchData())

   const getMinTimeStartWorkingHours = () =>
      Math.min(
         ...calendarState.calendarData
            .map(({ workingHours }) => workingHours.map(({ startTime }: any) => roundDown(startTime)))
            .flat(),
      ) || 0

   const getMaxTimeEndWorkingHours = () =>
      Math.max(
         ...calendarState.calendarData
            .map(({ workingHours }) => workingHours.map(({ endTime }: any) => roundUp(endTime)))
            .flat(),
      ) || 0

   const getMinTimeStartFirstBooking = () =>
      Math.min(
         ...calendarState.calendarData
            .map(({ bookings }) => bookings.map(({ startTime }: any) => roundDown(startTime)))
            .flat(),
      ) || 0

   const getMaxTimeEndLastBooking = () =>
      Math.max(
         ...calendarState.calendarData
            .map(({ bookings }) => bookings.map(({ endTime }: any) => roundUp(endTime)))
            .flat(),
      ) || 0

   const workingHoursStartTime = Math.min(getMinTimeStartWorkingHours(), getMinTimeStartFirstBooking())
   const workingHoursEndTime = Math.max(getMaxTimeEndWorkingHours(), getMaxTimeEndLastBooking())
   const isLoading = context.isLoading || calendarState.isLoading

   const checkBookings = calendarState.calendarData.reduce((acc, { bookings }) => acc + bookings.length, 0)

   calendarState.calendarData.forEach(data => {
      if (data.workingHours.length) {
         const workingHours = (data.workingHours[0].endTime - data.workingHours[0].startTime) / 60

         hours.push(workingHours)
      }
   })

   const checkWorkingHours = hours.reduce((acc, val) => acc + val, 0)

   const displayCalendar =
      (customViewType === StateCalendar.WORKING_HOUR && (checkBookings || checkWorkingHours)) ||
      customViewType === StateCalendar.FULL_CALENDAR ||
      customViewType === StateCalendar.CUSTOM_RANGE

   useEffect(() => {
      setIsDisplayCalendar(Boolean(displayCalendar))
   }, [displayCalendar])

   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="sidebar.calendar"
            mobileBackground="#fff"
            mobileHeader={() => (
               <CalendarMobileHeader
                  calendarState={calendarState}
                  date={calendarState.filterState.date}
                  headerComponent={CalendarHeader}
                  tooltipComponent={CardTooltip}
                  timeCardComponent={TimeCardComponent}
                  onMove={setPosition}
                  onResize={updateSize}
                  onCardClick={onCardClick}
                  onTimeSlotClick={onTimeSlotClick}
                  isSelectSlotMode={selectModeOn}
                  staffFromFilterParams={staffFromFilterParams}
                  timeFormat={timeFormat}
                  isWeekMode={isWeekMode}
                  changeDate={selected => calendarState.changeFilter('date', selected)}
                  scrollToCurrentTime={() => scrollToCurrentTimeLine(containerContext.current, calendarScroll)}
               />
            )}
            contentClassName="is-calendar-page"
            customButton={
               <AuthBlock section={PermissionType.CAN_BOOK_APPOINTMENTS}>
                  <MultipleAddButton
                     actions={[
                        {
                           title: 'appointment.create.menu',
                           action: createAppointment,
                        },
                        {
                           title: 'blocktime.create.menu',
                           action: openSetBlockTime,
                        },
                     ]}
                  />
               </AuthBlock>
            }
            disableSidebarShade
            noPaddingBottom>
            <BlockTimeSelectBanner isActive={selectModeOn} closeActive={closeSlotSelect} isBlock={isSelectSlotMode} />
            <CalendarFilter
               calendarState={calendarState}
               openSetBlockTime={openSetBlockTime}
               createAppointment={createAppointment}
               timeFormat={timeFormat}
            />
            <Spin spinning={isLoading}>
               <SC.Container isMobile={getIsMobile()}>
                  <EpicCalendar
                     key={version}
                     timeInterval={timeOffset}
                     data={calendarState.calendarData}
                     isCalendarLoading={isLoading}
                     modeType={viewType}
                     hasWorkingStaffs={calendarState.data?.employees.length !== 0}
                     setContainerContext={setContainerContext}
                     date={calendarState.filterState.date}
                     headerComponent={CalendarHeader}
                     tooltipComponent={CardTooltip}
                     timeCardComponent={TimeCardComponent}
                     onMove={setPosition}
                     onResize={updateSize}
                     onCardClick={onCardClick}
                     onTimeSlotClick={onTimeSlotClick}
                     isSelectSlotMode={selectModeOn}
                     staffFromFilterParams={staffFromFilterParams}
                     timeFormat={timeFormat}
                     customViewType={customViewType}
                     customViewTime={customViewTime}
                     workingHoursStartTime={workingHoursStartTime}
                     workingHoursEndTime={workingHoursEndTime}
                     scrollToCurrentTime={containerRef => scrollToCurrentTimeLine(containerRef, calendarScroll)}
                  />
               </SC.Container>
            </Spin>
         </AppLayout>
         {calendarState.data?.employees.length > 0 && isDisplayBtnShowAllTimes && (
            <BtnShowAllTimes
               setCustomViewType={setCustomViewType}
               setIsDisplayBtnShowAllTimes={setIsDisplayBtnShowAllTimes}
            />
         )}
      </NoInternetConnection>
   )
}

export default CalendarPage
