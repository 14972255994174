import styled from 'styled-components'

export const WrapperInvoice = styled.div`
   //margin-top: 30px;
   //overflow: auto;
`

export const ColumnContainer = styled.div`
   //min-width: 100px;
`
