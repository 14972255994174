import { startOfDay } from 'date-fns/startOfDay'
import { startOfWeek } from 'date-fns/startOfWeek'
import { addMinutes } from 'date-fns/addMinutes'
import { format } from 'date-fns/format'

/**
 * This method was created to resolve issue with winter/summer time
 * when you call `startOfDay` and start of day was on previos time zone (summer time) you will get wrong date
 */
export const startOfDayLocalized = (date: Date, time?: number) => {
   const ct = (time !== null ? addMinutes(startOfDay(date), time || 0) : date).getTimezoneOffset()
   const pt = startOfDay(date).getTimezoneOffset()

   return addMinutes(startOfDay(date), ct - pt)
}

export const formatTime = (time: number | null, timeFormat: string) => {
   if (time === null) {
      return ''
   }

   const formatTime = timeFormat === 'FORMAT_12H' || timeFormat === '12h' ? 'h:mm a' : 'HH:mm'

   return format(addMinutes(startOfDayLocalized(new Date(), time), time), formatTime)
}

export const startOfWeekIntl = (date: Date) => startOfWeek(date, { weekStartsOn: 1 })

export const removeTimeZone = (date: Date) => new Date(date.getTime() - date.getTimezoneOffset() * -60000)
