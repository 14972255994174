import Icon from '@ant-design/icons'
import { IconButton, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Dropdown, Menu, message as antMessage, message } from 'antd'
import * as api from 'api'
import React, { useState } from 'react'

import * as SC from '../style'

const ServiceControlMenu = ({ categoryId, editCategory, deleteCategory, ...props }: any) => (
   <Menu className="app-common-dropdown br-default" {...props}>
      <Menu.Item onClick={editCategory}>
         <Icon type="edit" />
         <IntlLabel label="btn.editCategory" />
      </Menu.Item>
      <Menu.Item onClick={() => deleteCategory(categoryId)} className="color-red br-bottom-default">
         <Icon type="delete" />
         <IntlLabel label="btn.deleteCategory" />
      </Menu.Item>
   </Menu>
)

export default function ServiceControl(props: any) {
   const [error, setError] = useState('')

   const editCategory = () => props.editCategory(props.id)

   const deleteCategory = async (id: string) => {
      try {
         const result = await api.deleteCategory(id)
         const errorMessage = result?.errors?.['FINAL_FORM/form-error'] || ''
         if (Boolean(errorMessage)) {
            setError(errorMessage)
         } else {
            message.success(<IntlLabel label="serviceGroup.delete.success" />)
            props.fetchServiceGroups()
         }
      } catch (error) {
         antMessage.error(error as '')
      }
      setError('')
   }

   return (
      <div>
         <Dropdown
            trigger={['click']}
            overlay={() => (
               <ServiceControlMenu
                  categoryId={props.id}
                  editCategory={editCategory}
                  deleteCategory={deleteCategory}
                  createService={props.createService}
               />
            )}
            placement="bottomRight">
            <SC.Control>
               <IconButton icon="bigDots" />
            </SC.Control>
         </Dropdown>
         {error && (message.error(<Text label={error} />) as any)}
      </div>
   )
}
