import { IntlLabel } from '@plandok/i18n'
import React from 'react'

import * as SC from './styles'

export const NoData = () => (
   <SC.NoDataContainer>
      <IntlLabel label="analytics.noData" />
   </SC.NoDataContainer>
)
