// THIS FILE IS GENERATED, DO NOT EDIT!
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../generatedTypes'
const defaultOptions = {} as const
export type ReportBookingsByCancellationReasonQueryVariables = Types.Exact<{
   offset?: Types.InputMaybe<Types.Scalars['Int']>
   limit?: Types.InputMaybe<Types.Scalars['Int']>
   from: Types.Scalars['Date']
   to: Types.Scalars['Date']
   locationId?: Types.InputMaybe<Types.Scalars['Uuid']>
   employeeId?: Types.InputMaybe<Types.Scalars['Uuid']>
}>

export type ReportBookingsByCancellationReasonQuery = {
   __typename?: 'AppQuery'
   reports?: {
      __typename?: 'App_Reports'
      appointmentsByCancellationReason?: {
         __typename?: 'App_ReportAppointmentsByCancellationReasonConnection'
         totalCount?: number | null
         pageInfo: {
            __typename?: 'PageInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            startCursor?: string | null
            endCursor?: string | null
         }
         edges?: Array<{
            __typename?: 'App_ReportAppointmentsByCancellationReasonEdge'
            cursor: string
            node?: {
               __typename?: 'App_ReportAppointmentsByCancellationReason'
               CancellationReasonName?: string | null
               totalCount: number
            } | null
         } | null> | null
      } | null
   } | null
}

export const ReportBookingsByCancellationReasonDocument = gql`
   query ReportBookingsByCancellationReason(
      $offset: Int
      $limit: Int
      $from: Date!
      $to: Date!
      $locationId: Uuid
      $employeeId: Uuid
   ) {
      reports(from: $from, to: $to, offset: $offset, limit: $limit) {
         appointmentsByCancellationReason(locationId: $locationId, employeeId: $employeeId) {
            totalCount
            pageInfo {
               hasNextPage
               hasPreviousPage
               startCursor
               endCursor
            }
            edges {
               cursor
               node {
                  CancellationReasonName
                  totalCount
               }
            }
         }
      }
   }
`

/**
 * __useReportBookingsByCancellationReasonQuery__
 *
 * To run a query within a React component, call `useReportBookingsByCancellationReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportBookingsByCancellationReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportBookingsByCancellationReasonQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      locationId: // value for 'locationId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useReportBookingsByCancellationReasonQuery(
   baseOptions: Apollo.QueryHookOptions<
      ReportBookingsByCancellationReasonQuery,
      ReportBookingsByCancellationReasonQueryVariables
   >,
) {
   const options = { ...defaultOptions, ...baseOptions }
   return Apollo.useQuery<ReportBookingsByCancellationReasonQuery, ReportBookingsByCancellationReasonQueryVariables>(
      ReportBookingsByCancellationReasonDocument,
      options,
   )
}
export function useReportBookingsByCancellationReasonLazyQuery(
   baseOptions?: Apollo.LazyQueryHookOptions<
      ReportBookingsByCancellationReasonQuery,
      ReportBookingsByCancellationReasonQueryVariables
   >,
) {
   const options = { ...defaultOptions, ...baseOptions }
   return Apollo.useLazyQuery<
      ReportBookingsByCancellationReasonQuery,
      ReportBookingsByCancellationReasonQueryVariables
   >(ReportBookingsByCancellationReasonDocument, options)
}
export type ReportBookingsByCancellationReasonQueryHookResult = ReturnType<
   typeof useReportBookingsByCancellationReasonQuery
>
export type ReportBookingsByCancellationReasonLazyQueryHookResult = ReturnType<
   typeof useReportBookingsByCancellationReasonLazyQuery
>
export type ReportBookingsByCancellationReasonQueryResult = Apollo.QueryResult<
   ReportBookingsByCancellationReasonQuery,
   ReportBookingsByCancellationReasonQueryVariables
>
