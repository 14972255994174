/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
   'login.title': 'Pieslēdzieties savam kontam',
   'label.email': 'Epasta adrese',
   'placeholder.email': 'piem. janis@gmail.com',
   'input.password.label': 'Parole',
   'input.password.placeholder': 'Ievadiet paroli',
   'login.btn': 'Pieslēgties Plandok',
   'link.sign.in': 'Pieslēgties',
   'label.password': 'Ievadiet paroli',
   'login.forgot.password': 'Aizmirsāt paroli?',
   'login.no.account': 'Vēl nav konta?',
   'login.description': 'Ja Jums rodas problēmas pieslēgties sistēmai, pamēģiniet nomainīt paroli.',
   'register.title': 'Izveidot kontu',
   'register.description': 'Izveidojiet bezmaksas kontu Plandok tikšanās plānošanas un rezervēšanas programmatūrā',
   'register.btn': 'Izveidot manu kontu',
   'register.agreement.agree': 'Es piekrītu',
   'register.agreement.terms': 'Vietnes noteikumiem',
   'register.agreement.policy': 'Privātumas politikai',
   'register.agreement.and': 'un',
   'register.agreement.partner': 'Partneru darījumu noteikumiem',
   'register.have.account': 'Jums jau ir konts?',
   'register.validation.confirm': 'Pirms turpināt, lūdzu, pārskatiet un pieņemiet iepriekš minētos nosacījumus.',
   'link.sign.up': 'Reģistrējaties',
   'text.alreadyHaveAccount': 'Jau ir konts ?',
   'input.company.label': 'Uzņēmums',
   'input.company.placeholder': 'Uzņēmums',
   'input.country.label': 'Valsts',
   'input.country.placeholder': 'Valsts nosaukums',
   'input.timeZone.label': 'Laika josla',
   'input.timeZone.placeholder': 'GMT +02.00',
   'input.currency.label': 'Valūta',
   'input.language.label': 'Valoda (Language)',
   'input.language.placeholder': 'Angļu',
   'input.currency.placeholder': 'Euro - EUR',
   'reset.title': 'Atjaunot paroli',
   'reset.btn.text': 'Nosūtīt jaunu paroli',
   'reset.pwd.btn': 'Atjaunot paroli',
   'reset.no.password': 'Nevēlaties atjaunot paroli?',
   'reset.description': 'Ievadiet reģistrēto e-pasta adresi, lai saņemtu drošu saiti jaunas paroles iestatīšanai',
   'sidebar.calendar': 'Kalendārs',
   'sidebar.client': 'Klienti',
   'sidebar.staff': 'Personāls',
   'sidebar.service': 'Pakalpojumi',
   'sidebar.notification': 'Ziņas',
   'sidebar.settings': 'Iestatījumi',
   'sidebar.menu': 'Izvēlne',
   'sidebar.analytics': 'Analytics',
   'sidebar.language': 'Valoda (Language)',
   'sidebar.logout': 'Iziet',
   'user.menu.settings': 'Mani iestatījumi',
   'user.menu.language': 'Mainīt valodu',
   'user.menu.logout': 'Iziet',
   'input.globalSearch.placeholder': 'Ko jūs meklējāt?',
   'input.globalSearch.description': 'Meklēšana pēc klienta vārda, mobilā tālruņa, e-pasta vai rezervācijas atsauces',
   'title.upcomingAppointments': 'Gaidāmās tikšanās',
   'calendar.day': 'Diena',
   'calendar.week': 'Nedēļa',
   'calendar.monday': 'Pirmdiena',
   'calendar.tuesday': 'Otrdiena',
   'calendar.wednesday': 'Trešdiena',
   'calendar.thursday': 'Ceturtdiena',
   'calendar.friday': 'Piektdiena',
   'calendar.saturday': 'Sestdiena',
   'calendar.sunday': 'Svētdiena',
   'calendar.mon': 'P',
   'calendar.tue': 'O',
   'calendar.wed': 'T',
   'calendar.thu': 'C',
   'calendar.fri': 'Pk',
   'calendar.sat': 'S',
   'calendar.sun': 'Sv',
   'calendar.today': 'Šodien',
   'calendar.january': 'Janvāris',
   'calendar.february': 'Februāris',
   'calendar.march': 'Marts',
   'calendar.april': 'Aprīls',
   'calendar.may': 'Maijs',
   'calendar.june': 'Jūnijs',
   'calendar.july': 'Jūlijs',
   'calendar.august': 'Augusts',
   'calendar.september': 'Septembris',
   'calendar.october': 'Oktobris',
   'calendar.november': 'Novembris',
   'calendar.december': 'Decembrs',
   'calendar.jan': 'Jan',
   'calendar.feb': 'Feb',
   'calendar.mar': 'Mar',
   'calendar.apr': 'Apr',
   'calendar.may.short': 'Mai',
   'calendar.jun': 'Jūn',
   'calendar.jul': 'Jūl',
   'calendar.aug': 'Aug',
   'calendar.sept': 'Sept',
   'calendar.oct': 'Oct',
   'calendar.nov': 'Nov',
   'calendar.dec': 'Dec',
   'calendar.allStaff': 'Viss',
   'calendar.workingStaff': 'Darbinieki',
   'calendar.tooltip.time': 'Laiks',
   'calendar.tooltip.category': 'Kategorija',
   'calendar.tooltip.service': 'Pakalpojums',
   'calendar.tooltip.staff': 'Personāls',
   'calendar.tooltip.price': 'Cena',
   'calendar.tooltip.repeating': 'Atkārtošana',
   'btn.viewDetails': 'Skatīt detaļas',
   'calendar.tooltip.emptyCustomer': 'Walk-in',
   'calendar.tooltip.bookedOnline': 'Online Reģistrācija',
   'calendar.print.description':
      'Lai izmantotu drukāšanas funkciju, jūsu plāns ir jāatjaunina. Lai jauninātu, apmeklējiet',
   'calendar.print.appointmentsBetween.title': 'Tikšanās starp',
   'calendar.print.appointmentsAnd.title': 'un',
   'calendar.billing.title': 'Plāns un norēķini',
   'calendar.btn.print.title': 'Drukāt',
   'datepicker.next.week': 'Nākamnedēļ',
   'datepicker.in.two.weeks': '2 nedēļu laikā',
   'datepicker.in.three.weeks': '3 nedēļu laikā',
   'datepicker.in.four.weeks': '4 nedēļu laikā',
   'datepicker.in.five.weeks': 'Pēc 5 nedēļām',
   'datepicker.in.six.weeks': 'Pēc 6 nedēļām',
   'modal.splitAppointment.title': 'Sadalīt Rezervāciju',
   'modal.splitAppointment.text':
      'Šī Rezervācija ir daļa no grupas, pārceļot to uz citu dienu, tā tiks sadalīta, lai kļūtu par atsevišķu rezervāciju. Vai Jūs esat pārliecināti?',
   'blocktime.create.modal.title': 'Pārtraukums',
   'blocktime.edit.modal.title': 'Rediģēt Pārtraukumu',
   'appointment.create.menu': 'Rezervācija',
   'blocktime.create.menu': 'Pārtraukums',
   'appointment.new.title': 'Jauna Rezervācija',
   'input.startTime.label': 'Sākuma laiks',
   'input.endTime.label': 'Beigu laiks',
   'input.startDate.label': 'Sākuma datums',
   'input.endDate.label': 'Beigu datums',
   'input.startDate.description.label': 'Izvēlieties sākuma datums',
   'input.endDate.description.label': 'Izvēlieties beigu datums',
   'input.closedDatedDescription.placeholder': 'piem brīvdienas',
   'input.service.label': 'Pakalpojums',
   'input.services.label': 'pakalpojumi',
   'input.service.placeholder': 'Izvēlieties pakalpojumu',
   'input.staff.label': 'Darbinieks',
   'input.staff.placeholder': 'Izvēlieties darbinieku',
   'input.pickDateAndTime.title': 'Izvēlēties datumu un laiku',
   'input.pickDate.title': 'Izvēlieties datumu',
   'service.add.another': 'Pievienojiet papildus rezervāciju',
   'input.appointmentNotes.label': 'Rezervācijas piezīmes',
   'input.emailSubject.placeholder': 'Piezīme par jūsu Rezervāciju',
   'input.appointmentNotes.placeholder': 'Pievienojiet komentārus par Rezervāciju (redzams tikai darbiniekiem)',
   'input.searchClients.placeholder': 'Klientu meklēšana',
   'input.searchService.placeholder': 'Meklēt',
   'appointment.empty.list':
      'Izmantojiet meklēšanu, lai pievienotu klientu, vai arī atstājiet tukšu, lai saglabātu kā walk-in.',
   'appointment.clients.empty.list': 'Klientu saraksts ir tukšs.',
   'appointment.clients.addClient': 'Izveidot klientu',
   'client.create.new': 'Izveidot jaunu klientu',
   'client.choose': 'Izvēlieties klientu',
   'client.menu.edit': 'Rediģēt klienta informāciju',
   'client.menu.remove': 'Noņemt no tikšanās',
   'appointment.btn.save': 'Saglabāt',
   'appointment.vat': 'PVN ({0}%)',
   'appointment.total': 'Kopā',
   'appointment.view.title': 'Skatīt Rezervāciju',
   'appointment.status.btn': 'Rezervācijas statuss',
   'appointment.btn.cancel': 'Dzēst',
   'btn.cancelAppointment': 'Izdzēst',
   'modal.cancelAppointment.title': 'Izdzēst',
   'title.clients': 'Klienti',
   'client.create.title': 'Jauns klients',
   'client.options.export.title': 'Eksportēt klientus',
   'client.options.export.description': 'Lejupielādēt CSV ar klienta datiem',
   'client.options.import.title': 'Importēt klientus',
   'client.export.premium.description': 'Lai izmantotu funkciju Eksportēt, jūsu plāns ir jājaunina.',
   'client.export.premium.visit': 'Lai jauninātu, apmeklējiet',
   'client.import.instructions.description':
      'Izpildiet tālāk sniegtos norādījumus, lai importētu klientus savā Plandok kontā',
   'client.import.instructions.download': 'Lejupielādējiet importēšanas failu, izmantojot tālāk esošo pogu',
   'client.import.instructions.copy': 'Kopējiet un ievietojiet klienta informāciju failā',
   'client.import.instructions.email': 'Nosūtiet to mums pa e-pastu uz context@plandok.com',
   'client.import.instructions.account': 'un mēs importēsim to jūsu kontā',
   'table.clients.name': 'Vārds',
   'table.clients.phone': 'Tālr',
   'table.clients.mobile': 'Mobīlais tālr',
   'table.clients.email': 'E-pasts',
   'table.clients.gender': 'Dzimums',
   'client.page.title': 'Klienta profils',
   'btn.more.options': 'Vairāk iespēju',
   'btn.showAll': 'Parādīt visu',
   'btn.collapse': 'Sakļaut',
   'btn.reschedule': 'Pārplānot',
   'title.edit.client': 'Rediģēt klientu',
   'menu.edit.client': 'Rediģēt klientu',
   'menu.delete.client': 'Dzēst klientu',
   'title.mobilePhone': 'Mobilais telefons',
   'title.telephoneNumber': 'Telefona numurs',
   'title.email': 'E-pasts',
   'title.address': 'Adrese',
   'title.dateOfBirth': 'Dzimšanas datums',
   'title.gender': 'Dzimums',
   'client.statistics': 'Statistika',
   'client.totalSales': 'Pārdošana',
   'client.totalBookings': 'Kopējais rezervāciju skaits',
   'client.outstanding': 'Nesamaksāts',
   'client.allBooking': 'Rezervācijas',
   'client.completed': 'Pabeigts',
   'client.cancelled': 'Atcelts',
   'client.noShow': 'Neieradās',
   'appointment.list.title': 'Rezervācijas',
   'upcoming.list.title': 'Gaidāmā',
   'past.list.title': 'Iepriekšējie',
   'modal.confirmClientDelete.text.0': 'Vai tiešām vēlaties neatgriezeniski izdzēst klientu',
   'modal.confirmClientDelete.text.1': 'no datu bāzes ?',
   'input.date.label': 'Līdz konkrētam datumam',
   'input.date.placeholder': 'Atlasiet Datumus',
   'input.firstName.label': 'Vārds',
   'input.firstName.placeholder': 'piem. Anna',
   'input.lastName.label': 'Uzvārds',
   'input.lastName.placeholder': 'piem. Kalniņa',
   'input.mobile.label': 'Mobilā telefona numurs',
   'input.mobile.placeholder': 'Ievadiet numuru',
   'input.email.label': 'E-pasta adrese',
   'input.email.placeholder': 'piem. anna.kalnina@plandok.com',
   'input.title.label': 'Nosaukums',
   'input.title.placeholder': 'piem. Zobārsts',
   'input.notificationType.label': 'Nosūtīt paziņojumu pa',
   'tab.personalInfo': 'Personīgā informācija',
   'input.gender.label': 'Dzimums',
   'input.referal.label': 'Novirzīšanas avots',
   'input.referal.placeholder': 'Izvēlieties avotu',
   'input.birthDay.label': 'Dzimšanas diena',
   'input.month.placeholder': 'Mēnesis',
   'input.day.placeholder': 'Diena',
   'input.year.placeholder': 'Gads',
   'input.clientsNotes.label': 'Klienta piezīmes',
   'input.clientsNotes.placeholder': 'Ievadiet klienta piezīmes',
   'input.bookingDisplay.label': 'Parādīt visās rezervācijās',
   'input.serviceDescription.label': 'Pakalpojumu apraksts',
   'input.serviceDescription.placeholder': 'Pievienojiet īsu aprakstu, kas būs redzama klientiem Online rezervācijas',
   'input.isBookableOnline.label': 'Pieejams tiešsaistes rezervācijas',
   'service.onlineBookings.title': 'Online rezervācijas',
   'tab.address': 'Adrese',
   'input.address.label': 'Adrese',
   'input.address.placeholder': 'Ievadiet savu adresi',
   'input.city.label': 'Pilsēta',
   'input.city.placeholder': 'Ievadiet pilsētu',
   'input.zip.label': 'Pasta indekss',
   'input.zip.placeholder': 'Ievadiet pasta indeksu',
   'btn.edit': 'Rediģēt',
   'btn.cancel': 'Atcelt',
   'btn.save': 'Saglabāt',
   'btn.save.changes': 'Saglabāt',
   'tabs.staffWorking.title': 'Darba laiks',
   'tabs.staffMembers.title': 'Personāls',
   'tabs.userPermissions.title': 'Lietotāju atļaujas',
   'tabs.closedDates.title': 'Slēgti datumi',
   'btn.new.staff': 'Jauns personāls',
   'table.staff.name': 'Vārds',
   'table.staff.mobileNumber': 'Tālr',
   'table.staff.email': 'Email',
   'table.staff.userPermission': 'Lietotāju atļauja',
   'table.staff.dataRange': 'Laika diapazons',
   'table.staff.numberOfDays': 'Dienu skaits',
   'table.staff.location': 'Vieta',
   'table.staff.description': 'Apraksts',
   'table.appointmentsList.appointment': 'Pieraksts',
   'table.appointmentsList.client': 'Klients',
   'table.appointmentsList.service': 'Pakalpojums',
   'table.appointmentsList.date': 'Datums',
   'table.appointmentsList.time': 'Laiks',
   'table.appointmentsList.duration': 'Ilgums',
   'table.appointmentsList.location': 'Filiāle',
   'table.appointmentsList.staff': 'Personāls',
   'table.appointmentsList.price': 'Cena',
   'table.appointmentsList.status': 'Statuss',
   'table.appointmentsCancellations.ref': 'Atsauce',
   'table.appointmentsCancellations.client': 'Klients',
   'table.appointmentsCancellations.service': 'Pakalpojums',
   'table.appointmentsCancellations.scheduledDate': 'Plānotais datums',
   'table.appointmentsCancellations.cancelledDate': 'Atcelts datums',
   'table.appointmentsCancellations.cancelledBy': 'Atcēla',
   'table.appointmentsCancellations.reason': 'Iemesls',
   'table.appointmentsCancellations.price': 'Cena',
   'table.appointmentsCancellations.totalCount': 'Kopējais skaits',
   'table.clients.appointments': 'Tikšanās',
   'table.clients.noShows': 'Neieradās',
   'table.clients.totalSales': 'Kopējais pārdošanas apjoms',
   'table.clients.added': 'Pievienots',
   'table.clients.daysAbsent': 'Prombūtnes dienas',
   'table.clients.lastAppointment': 'Pēdējais apmeklējums',
   'table.clients.lastLocation': 'Pēdējā Filiāle',
   'table.appointmentsByService.serviceName': 'Pakalpojuma nosaukums',
   'table.appointmentsByService.appointments': 'Apmeklējumi',
   'table.appointmentsByService.totalValue': 'Kopējā vērtība',
   'table.appointmentsByStaff.staffMember': 'Darbinieks',
   'table.appointmentsByStaff.appointments': 'Tikšanās',
   'table.appointmentsByStaff.totalValue': 'Kopējā vērtība',
   'table.pagination.showing.title': 'Rāda',
   'table.pagination.results.title': 'rezultātus',
   'clients.duplicate.title': 'Atrasti klientu dublikāti.',
   'clients.duplicate.description': 'Ir līdzīgi klienti, kurus var apvienot.',
   'clients.merge.btn.title': 'Apvienot',
   'clients.merge.modal.title': 'Apvienojiet klientus',
   'clients.merge.selectAll.title': 'Atlasiet visus dublikātus',
   'clients.merge.duplicatesNotFound.title': 'Nav atrasti dubultnieki',
   'clients.merge.duplicatesNotFound.description': 'Apvienošanai nav pieejami klienti',
   'clients.mergeConfirm.modal.title': 'Apstipriniet sapludināšanu',
   'clients.mergeConfirm.modal.description':
      'Vai tiešām vēlaties sapludināt {0} dublikātus? Šī darbība ir neatgriezeniska, un to nevar atsaukt.',
   'clients.mergeConfirm.checkbox.title': 'Es saprotu, ka klientu apvienošanu nevar atsaukt',
   'clients.mergeConfirm.btn.title': 'Apstiprināt un apvienot',
   'clients.mergeConfirm.cancel.btn.title': 'Atcelt',
   'clients.byNumber.btn.title': 'Pa tālruni {0}',
   'clients.byEmail.btn.title': 'Pa e-pastu {0}',
   'clients.byName.btn.title': 'Pēc vārda {0}',
   'title.newStaff': 'Jauns personāls',
   'title.editStaff': 'Rediģēt personālu',
   'description.extraCharge': 'Pievienojot jaunus darbiniekus, jūsu ikmēneša abonementam tiks pievienota papildu maksa',
   'tabs.details': 'Sīkāka informācija',
   'input.staffPicture.label': 'Bilde',
   'input.cropImage.title': 'Apgriezt attēlu',
   'input.cropImage.btn.apply.title': 'Pieteikties',
   'input.stafffirstName.label': 'Vārds',
   'input.stafffirstName.placeholder': 'piem. Anna',
   'input.stafflastName.label': 'Uzvārds',
   'input.stafflastName.placeholder': 'piem. Kalniņa',
   'input.stafmobile.label': 'Tālr',
   'input.stafemail.label': 'E-pasts',
   'input.stafemail.placeholder': 'piem. anna.kalniņa@pladok.com',
   'input.permission.label': 'Lietotāju atļaujas',
   'input.uploadProfile.label': 'Augšupielādēt profila attēlu',
   'input.changeProfile.label': 'Mainīt profila attēlu',
   'input.notes.label': 'Piezīmes',
   'input.staffNotes.placeholder':
      'Pievienojiet privātas piezīmes, kas skatāmas tikai personāla iestatījumos (nav obligāti)',
   'input.blockTime.placeholder': 'piemēram, sanāksme',
   'tabs.locations': 'Filiāle',
   'tabs.integrations': 'Integrācija',
   'input.staffLocations.description': 'Piešķiriet Filiāles, kur šis darbinieks var strādāt.',
   'input.multiLocation.description': 'Piešķiriet Filiāles, kur šis darbinieks var strādāt.',
   'tabs.services': 'Pakalpojumi',
   'input.staffServices.description': 'Piešķiriet pakalpojumus, ko šis darbinieks var veikt.',
   'input.multiServices.description': 'Piešķiriet pakalpojumus, ko šis darbinieks var veikt.',
   'input.shiftStart.label': 'Maiņas sakums',
   'input.shiftEnd.label': 'Maiņas beigas',
   'edit.staff.title.resendPassword': 'Paroles atkārtota sūtīšana',
   'edit.staff.info.resendPassword':
      'Darbinieks nesaņēma e-pastu ar paroli? Noklikšķiniet uz tālāk esošās pogas, lai atkārtoti nosūtītu e-pastu ar paroles iestatīšanas norādījumiem.',
   'edit.staff.button.resendPassword': 'Atkārtoti nosūtīt paroli e-pastā',
   'btn.add.shift': 'Pievienot citu maiņu',
   'break.time': '{0} pārtraukums',
   'input.repeat.label': 'Atkārtot',
   'input.repeatWeekly.label': 'Atkārtot katru nedēļu',
   'input.repeatDoNot.label': 'Neatkārtot',
   'input.endRepeat.label': 'Atkārtojuma beigas',
   'input.endRepeatOngoing.label': 'Vienmēr',
   'input.endRepeatSpecificDate.label': 'Līdz konkrētam datumam',
   'input.endRepeatDate.placeholder': 'Izvēlieties datumu',
   'btn.deleteUpcomingShift': 'Dzēst gaidāmās maiņas',
   'btn.deleteThisShift': 'Dzēst tikai šo maiņu',
   'btn.updateUpcomingShift': 'Atjauniniet gaidāmās maiņas',
   'btn.updateThisShift': 'Atjaunināt tikai šo maiņu',
   'modal.repeatShift.title': 'Atkārtojot maiņa',
   'form.error.uniqShifts': 'Maiņām jābūt unikālām',
   'btn.add.new': 'Pievienot',
   'btn.addNewService': 'Jaunu pakalpojumu',
   'btn.newCategory': 'Jauna kategorija',
   'btn.newServiceGroup': 'Jauna kategorija',
   'btn.editCategory': 'Rediģēt kategoriju',
   'btn.deleteCategory': 'Dzēst kategoriju',
   'btn.downloadImportFile': 'Lejupielādēt importa failu',
   'serviceGroup.delete.success': 'Kategorija ir veiksmīgi izdzēsta!',
   'service.new.title': 'Jauns pakalpojums',
   'service.edit.title': 'Rediģēt pakalpojumu',
   'input.serviceName.label': 'Pakalpojuma nosaukums',
   'input.serviceName.placeholder': 'piem. Masāža',
   'input.serviceCategory.label': 'Pakalpojuma kategorija',
   'input.serviceCategory.placeholder': 'Izvēlieties pakalpojumu kategoriju',
   'input.price.label': 'Cena',
   'input.price.placeholder': '0',
   'input.duration.label': 'Ilgums',
   'input.duration.placeholder': 'Ilgums',
   'input.staffSelect.description': 'Izvēlieties darbiniekus, kuri veic šo pakalpojumu',
   'input.newCategoryName.label': 'Kategorijas nosaukums',
   'input.newCategoryDescription.label': 'Kategorijas apraksts',
   'input.newCategoryDescription.placeholder': 'Aprakstiet šo kategoriju, tā tiks parādīta Online rezervāciju sadaļā',
   'modal.newCategory.title': 'Jauna kategorija',
   'modal.editCategory.title': 'Rediģēt kategoriju',
   'input.chooseCategory.label': 'Izvēlieties kategorijas krāsu.',
   'client.messages.title': 'Klientu ziņas',
   'tabs.messagesLog': 'Ziņojumu žurnāls',
   'tabs.messagesLog.description': 'Jūsu klientiem nosūtīto ziņojumu saraksts',
   'tabs.messagesSettings': 'Iestatījumi',
   'tabs.messagesSettings.description': 'Pielāgojiet ziņojumu iestatījumus, lai tie atbilstu jūsu uzņēmuma vajadzībām',
   'table.header.timeSent': 'Nosūtīts',
   'table.header.client': 'Klients',
   'table.header.appointment': 'Rezervācija',
   'table.header.destination': 'Adrese',
   'table.header.type': 'Veids',
   'table.header.message': 'Ziņas',
   'table.header.status': 'Statuss',
   'table.header.cost': 'Izmaksas',
   'modal.message.title': 'Skatīt ziņojumu',
   'setup.page.title': 'Iestatījumi',
   'title.accountSettings': 'Konta iestatījumi',
   'title.accountSetup': 'Konta iestatīšana',
   'title.accountSetup.description': 'Pārvaldiet iestatījumus, piemēram, sava uzņēmuma nosaukumu un laika joslu',
   'resources.list.title': 'Resursi',
   'title.resources': 'Resursi',
   'title.resources.description': 'Iestatiet rezervējamos resursus, piemēram, telpas, krēslus vai aprīkojumu',
   'title.locations': 'Filiāle',
   'title.locations.description': 'Pārvaldiet vairākas sava uzņēmuma atrašanās vietas',
   'calendar.settings.title': 'Kalendāra iestatījumi',
   'calendar.btn.selectFromCalendar': 'Izvēlieties no kalendāra',
   'title.calendarSettings': 'Kalendāra iestatījumi',
   'title.calendarSettings.description': 'Pielāgojiet kalendāra krāsu shēmu un izkārtojumu',
   'title.clientSettings': 'Klienta iestatījumi',
   'title.onlineBookingSettings': 'Online rezervācijas iestatījumi',
   'title.salesSettings': 'Pārdošanas iestatījumi',
   'title.receiptSequencing': 'Saņemšanas secības noteikšana',
   'title.receiptSequencing.description':
      'Konfigurējiet informāciju, kas tiek parādīta klientiem izsniegtajās pārdošanas kvītīs',
   'title.receiptTemplate': 'Kvīts veidne',
   'title.taxes.description': 'Pārvaldiet nodokļu likmes, kas tiek piemērotas precēm, kuras tiek pārdotas pie kases',
   'title.paymentTypes': 'Maksājumu veidi',
   'title.paymentTypes.description': 'Iestatiet manuālos maksājumu veidus, kas izmantojami izrakstīšanās laikā',
   'title.discounts.description': 'Atlaižu iestatīšana',
   'title.receiptPrefix': 'Kvīts Nr. Prefikss',
   'title.receiptNumber': 'Nākamais kvīts numurs',
   'title.change': 'Mainīt',
   'title.saleReceiptTemplate': 'Pārdošanas kvīts veidne',
   'description.saleReceiptTemplate':
      'Pielāgojiet saturu, kas tiek parādīts jūsu klientiem izsniegtajās pārdošanas kvītīs',
   'setting.automaticallyPrint': 'Automātiski izdrukāt kvītis pēc pārdošanas pabeigšanas',
   'setting.showMobile': 'Parādiet klienta mobilo tālruni un e-pastu pārdošanas kvītī',
   'setting.showAddress': 'Parādīt klienta adresi pārdošanas kvītī',
   'title.receiptTitle': 'Kvīts virsraksts',
   'title.referrals': 'Novirzīšanas avots',
   'title.referrals.description': 'Iestatiet pielāgotus avotus, lai izsekotu, kā klients atrada jūsu uzņēmumu',
   'title.clientNotifications': 'Klientu paziņojumu iestatījumi',
   'title.clientNotifications.description':
      'Pārvaldiet ziņojumus, kas tiek nosūtīti klientiem par viņu tikšanās reizēm',
   'cancelReason.list.title': 'Atcelšanas iemesls',
   'title.cancellationReasons': 'Atcelšanas iemesls',
   'title.cancellationReasons.description': 'Izsekojiet, kāpēc klienti neieradās uz tikšanos',
   'title.onlineBooking.description': 'Tūres tiešsaistes rezervācijas pieejamības un iestatījumu pārvaldība',
   'title.pointOfSale': 'Pārdošana',
   'discount.list.title': 'Atlaide',
   'title.discountTypes': 'Atlaižu veidi',
   'title.discountTypes.description': 'Iestatiet manuālus atlaižu veidus lietošanai norēķinu laikā',
   'title.premiumFeature': 'Premium funkcija',
   'company.details.title': 'Informācija par uzņēmumu',
   'input.businessName.label': 'Uzņēmuma nosaukums',
   'input.businessName.placeholder': 'piem. Plandok',
   'input.description.label': 'Apraksts',
   'input.description.placeholder': 'Ievadiet aprakstu',
   'input.businessAddress.label': 'Adrese',
   'input.businessAddress.placeholder': 'Ievadiet savu adresi',
   'input.website.label': 'Vietne - www',
   'input.website.placeholder': 'Ievadiet vietnes nosaukumu',
   'input.contactNumber.label': 'Kontaktpersonas numurs',
   'input.contactNumber.placeholder': 'Ievadiet numuru',
   'input.businessTimeZone.label': 'Laika zona',
   'input.businessTimeZone.placeholder': 'GMT +02.00',
   'input.businessCountry.label': 'Valsts',
   'input.businessCountry.placeholder': 'Valsts nosaukums',
   'input.timeFormat.label': 'Laika formāts',
   'input.timeFormat.placeholder': '24 stundas',
   'table.header.resourceName': 'Resurss',
   'btn.new.resource': 'Jauns resurss',
   'input.resourceName.label': 'Resursa nosaukums',
   'input.resourceName.placeholder': 'Ievadiet resursa nosaukumu',
   'input.resourceDescription.label': 'Apraksts',
   'input.resourceDescription.placeholder': 'Ievadiet aprakstu',
   'input.resourceLocation.placeholder': 'Vietas nosaukums',
   'resourcesTooltip.location': 'Location tooltip for resource',
   'modal.newResource.title': 'Jauns resurss',
   'modal.editResource.title': 'Rediģēt resursu',
   'table.header.locationName': 'Atrašanās vietas',
   'table.header.locatonAddress': 'Adrese',
   'table.header.location.Phone': 'Tālr',
   'modal.newLocation.title': 'Jauna atrašanās vieta',
   'modal.editLocation.title': 'Rediģēt atrašanās vietu',
   'btn.new.location': 'Jauna atrašanās vieta',
   'btn.addExtraLocation': 'Pievienot papildu atrašanās vietu',
   'input.locationName.label': 'Atrašanās vietas',
   'input.locationName.placeholder': 'Ievadiet Atrašanās vietu nosaukumu',
   'input.profile.locationName.placeholder': 'Piem. Barber salons',
   'input.slug.label': 'Slug',
   'input.locationTips.label': 'Padomi par atrašanās vietu',
   'input.slug.explanation': 'Būs redzama jūsu atrašanās vietu URL',
   'input.contactEmail.label': 'E-pasts saziņai',
   'input.locationAddress.label': 'Adrese',
   'input.locationAddress.placeholder': 'Ievadiet adresi',
   'tooltip.contactEmail': 'Atbildes uz klienta Rezervāciju n paziņojumiem tiks nosūtītas uz šo e-pastu.',
   'input.appointmentColors.label': 'Rezervācijas krāsas',
   'input.colorBy.service': 'Pēc pakalpojumu kategorijas',
   'input.colorBy.employee': 'Pēc darbinieka krāsas',
   'input.colorBy.appointment': 'Pēc rezervācijas statusa',
   'input.timeSlot.label': 'Laika nišas intervāls',
   'input.defaultViewType.label': 'Noklusējuma skats',
   'input.calendarTimeRange.label': 'Kalendāra laika diapazons',
   'input.calendarTimeRange.allTimes.option': 'Visi laiki (00:00 - 24:00)',
   'input.calendarTimeRange.onlyWorkingHours.option': 'Tikai darba laiks',
   'input.calendarTimeRange.customRange.option': 'Pielāgots diapazons',
   'input.weekStart.label': 'Nedēļas sākuma diena',
   'table.header.refSourcetitle': 'Novirzīšanas Avots',
   'table.header.refSourceAddDate': 'Pievienots datums',
   'btn.new.referral': 'Jauns novirzīšanas avots',
   'input.nameRefSource.label': 'Novirzīšanas Avots nosaukums',
   'input.nameRefSource.placeholder': 'piem. Facebook reklāma',
   'modal.newRefSource.title': 'Jauns novirzīšanas avots',
   'modal.editRefSource.title': 'Rediģēt novirzīšanas avotu',
   'input.sendBy.label': 'Sūtīt pa',
   'input.emailSubject.label': 'E-pasta tēma',
   'input.emailTemplate.label': 'E-pasta veidne',
   'input.emailTemplate.placeholder': 'Ievadiet e-pasta veidni',
   'input.subject.label': 'Vēstules tēma',
   'input.subject.placeholder': 'Ievadiet vēstules tēmu',
   'title.messagingSettings.label': 'Ziņojumu iestatījumi',
   'title.messagingSettings.description':
      'Šeit tiek iestatīts ziņu veids, kas tiks automātiski nosūtīts klientiem. Pielāgojiet iestatījumus, kā un kad tiek sūtītas ziņas, un rediģējiet veidnes, lai personalizētu tekstu. Izmantojiet tālāk minētos tagus, lai ziņās ievietotu informāciju par tikšanos:',
   'client.first.name': 'CLIENT_FIRST_NAME',
   'client.last.name': 'CLIENT_LAST_NAME',
   'staff.first.name': 'STAFF_FIRST_NAME',
   'staff.last.name': 'STAFF_LAST_NAME',
   'booking.date.time': 'BOOKING_DATE_TIME',
   'booking.date': 'BOOKING_DATE',
   'booking.time': 'BOOKING_TIME',
   'service.name': 'SERVICE_NAME',
   'business.name': 'BUSINESS_NAME',
   'business.phone': 'BUSINESS_PHONE',
   'business.address': 'BUSINESS_ADDRESS',
   'business.email': 'BUSINESS_EMAIL',
   'location.name': 'LOCATION_NAME',
   'location.phone': 'LOCATION_PHONE',
   'location.address': 'LOCATION_ADDRESS',
   'location.email': 'LOCATION_EMAIL',
   'tabs.reminders.title': 'Atgādinājumi',
   'input.enableReminder.label': 'Ieslēgt Reminder Messages',
   'input.enableReminder.description': 'Automātiski nosūta klientiem pirms viņu gaidāmās Rezervācijas',
   'input.reminder.title': 'Atgādinājums {0}',
   'input.message.title': 'Ziņa {0}',
   'input.reminder.description': 'Kanāli un sūtīšanas laiks',
   'input.reminder.channels': 'Atlasiet kanālus, uz kuriem šis ziņojums tiks nosūtīts',
   'input.reminderBefore.label': 'Atgādinājumi tiks nosūtīti iepriekš',
   'input.smsSender.label': 'SMS sūtītāja ID',
   'input.messageSender.label': 'Ziņojuma sūtītāja ID',
   'input.smsSender.placeholder': 'Ievadiet īsziņas ID',
   'input.smsTemplate.label': 'SMS veidne',
   'input.messageTemplate.label': 'Ziņojuma veidne',
   'input.smsTemplate.placeholder': 'Ievadiet SMS veidni',
   'input.whatsAppSender.placeholder': 'Plandok',
   'reminder.whatsApp.notificate':
      'WhatsApp ziņojuma veidne pašlaik nav rediģējama ierobežojumu dēļ. Tomēr mēs aktīvi strādājam, lai padarītu to pilnībā rediģējamu.',
   'reminder.charactersCount': 'Rakstzīmju skaits:',
   'reminder.pricing': "Cenu noteikšana'",
   'modal.pricing.description':
      'Dažādās valstīs ir atšķirīgas cenas. Mēs piedāvājam labāko iespējamo tarifu no mūsu partneriem. Tas var atšķirties.',
   'modal.pricing.subDescription': 'Maksa tiks piemērota pēc ziņojumu nosūtīšanas un pievienota nākamajam rēķinam.',
   'modal.pricing.counterDescription':
      'Standarta teksta SMS īsziņas garums ir ierobežots līdz 160 rakstzīmēm un 70 rakstzīmēm ziņojumiem ar īpašām rakstzīmēm.',
   'modal.pricing.learnMore': 'Uzzināt vairāk',
   'modal.pricing.priceRates': 'Cenu likmes',
   'modal.pricing.messagePrice': '1 ziņojuma cena:',
   'modal.sendTestMessageByNumber.title': 'Nosūtīt testa ziņojumu uz šo numuru:',
   'modal.sendTestMessageByNumber.description': 'Maksājumi tiek piemēroti kā par parasto ziņojumu.',
   'modal.sendTestMessageByEmail.title': 'Nosūtīt testa ziņu uz šo e-pastu:',
   'modal.sendTestMessageByEmail.placeholder': 'Lūdzu, ievadiet e-pasta adresi',
   'btn.send': 'Sūtīt',
   'reminder.sendTestMessage': 'Nosūtiet testa ziņojumu',
   'button.reminder.addTag': 'Pievienot tagu',
   'button.reminder.addAnotherReminder': 'PIEVIENOT CITU ATGĀDINĀJUMU',
   'button.message.addAnotherMessage': 'PIEVIENOT VĒL VIENU ZIŅU',
   'button.reminder.upgrade': 'Jauniniet, lai piekļūtu',
   'label.emailSettings': 'Epasta iestatījumi',
   'label.SMSSettings': 'SMS iestatījumi',
   'label.whatsAppSettings': 'WhatsApp iestatījumi',
   'tooltip.sender.0': 'Tas tiks parādīts kā SMS sūtītāja vārds',
   'tooltip.sender.1': 'Ne vairāk kā 11 rakstzīmes',
   'tooltip.sender.2': 'Var nedarboties dažās valstīs',
   'tooltip.sender.3': 'Tikai latīņu burti vai cipari',
   'tabs.confirmations.title': 'Apstiprinājumi',
   'btn.appBookings': 'In App rezervācijas',
   'btn.onlineBookings': 'Online rezervācijas',
   'input.enableAppConfirmation.label': 'Iespējot lietotnē rezervāciju apstiprinājumus',
   'input.enableOnlineConfirmation.label': 'Ieslēgt Online rezervāciju apstiprinājumus',
   'input.enableConfirmationApp.description':
      'Automātiski nosūta klientiem, kad viņiem ir rezervēts apmeklējumas laiks',
   'input.enableConfirmationOnline.description':
      'Automātiski nosūta klientiem, kad viņiem ir rezervēts apmeklējuma laiks',
   'messageSetup.header': 'Ziņas iestatījumi',
   'channels.subHeader': 'Kanāli',
   'tabs.reschedules.title': 'Pārplānošana',
   'input.enableReschedule.label': 'Ieslēgt ziņojumu pārplānošanu',
   'input.enableReschedule.description': 'Automātiski nosūta klientiem, kad tiek mainīts viņu Apmeklējuma sākuma laiks',
   'tabs.cancellations.title': 'Atcelšana',
   'input.enableCancellation.label': 'Ieslēgt Atcelšanas ziņojumus',
   'input.enableCancellations.description': 'Automātiski nosūta klientiem, kad viņu Apmeklējums tiek atcelts',
   'tabs.thanks.title': 'Paldies',
   'input.enableThank.label': 'Ieslēgt Paldies ziņojumus',
   'input.enableThankyou.description':
      'Ziņojums tiek nosūtīts klientam, kad Apmeklējuma statuss ir atzīmēts kā pabeigts',
   'tabs.lastVisit.title': 'Pēdējais apmeklējums',
   'input.enableLastVisit.label': 'Ieslēgt Pēdējā apmeklējuma paziņojumus',
   'input.enableLastVisit.description':
      'Automātiski nosūta klientiem ziņu, ja viņi nav apmeklējuši jūsu uzņēmumu pēdējo norādīto dienu laikā',
   'table.cancelReason.name': 'Atcelšanas Iemesls',
   'table.cancelReason.addDate': 'Pievienots',
   'btn.new.cancelReason': 'Jauns Atcelšanas Iemesls',
   'modal.newCancelReason.title': 'Jauns Atcelšanas Iemesls',
   'modal.editCancelReason.title': 'Rediģēt Atcelšanas Iemeslu',
   'input.cancelReason.title': 'Atcelšanas iemesla nosaukums',
   'input.cancelReason.placeholder': 'piem. Klients nav pieejams',
   'table.discounts.name': 'Atlaides',
   'table.discounts.value': 'Vērtība',
   'table.discounts.addDate': 'Pievienots datums',
   'input.discountName.label': 'Atlaides nosaukums',
   'input.discountName.placeholder': 'piem. Pensionāriem',
   'input.discountValue.label': 'Atlaides vērtība',
   'btn.new.discount': 'Jauna Atlaide',
   'input.discountPercentage.label': '% Procenti',
   'input.discountSum.label': 'Summa',
   'modal.newDiscount.title': 'Jauna atlaide',
   'modal.editDiscount.title': 'Rediģēt atlaidi',
   'modal.deleteAccount.title': 'Konta dzēšana',
   'modal.deleteAccount.description':
      'Konta dzēšana ir neatgriezenisks process, un visi jūsu dati tiks dzēsti uz visiem laikiem.',
   'modal.deleteAccount.successMessage': 'Jūsu konts ir veiksmīgi dzēsts.',
   'title.mySettings': 'Mani iestatījumi',
   'title.personalDetails': 'Personiskā informācija',
   'title.personalDetails.description':
      'Ievadiet savu vārdu un kontaktinformāciju, šeit ievadītā e-pasta adrese tiek izmantota jūsu piekļuvei',
   'btn.continueWithGoogle.title': 'Turpināt izmantot Google',
   'btn.continueWithFacebook.title': 'Turpināt ar Facebook',
   'notification.googleAccountUnlinked': 'Google konts atsaistīts',
   'notification.googleAccountLinked': 'Google konts piesaistīts',
   'notification.facebookAccountUnlinked': 'Facebook konts atsaistīts',
   'notification.facebookAccountLinked': 'Sasaistīts Facebook konts',
   'btn.linkAccount': 'Sasaistīt kontu',
   'btn.unlinkAccount': 'Atsaistīt kontu',
   'btn.deleteYourAccount': 'Jūsu konta dzēšana',
   'input.myName.label': 'Vārds',
   'input.myName.placeholder': 'piem. Jānis',
   'input.mylastName.label': 'Uzvārds',
   'input.mylastName.placeholder': 'piem. Bērziņš',
   'title.changePassword': 'Mainīt paroli',
   'title.changePassword.description':
      'Lai veiktu atjaunināšanu, ievadiet savu esošo paroli un pēc tam jaunu. Ja jūs nezināt savu esošo paroli, varat izrakstīties un izmantot saiti Atiestatīt paroli pierakstīšanās lapā.',
   'input.currentPassword.label': 'Pašreizējā parole',
   'label.currentPassword.placeholder': 'Ievadiet pašreizējo paroli',
   'input.newPassword.label': 'Jauna parole',
   'input.newPassword.placeholder': 'Ievadiet jauno paroli',
   'input.verifyPassword.label': 'Atkārtojiet paroli',
   'input.verifyPassword.placeholder': 'Atkārtojiet jauno paroli',
   'btn.confirmDelete': 'Jā, izdzēst tagad',
   'btn.confirm': 'Apstipriniet',
   'btn.confirmBook': 'Apstipriniet un rezervējiet',
   'btn.confirmPay': 'Apstipriniet un apmaksājiet',
   'btn.delete': 'Dzēst',
   'btn.close': 'Aizvert',
   'validation.required': 'Lauks ir obligāts',
   'validation.email.format': 'Nepareizs e-pasta formāts',
   'validation.url.format': 'Nepareizs url formāts, tam jāsākas ar https:// vai http://',
   'validation.password.length': 'Parolē jābūt vismaz 7 rakstzīmēm',
   'validation.password.letters': 'Parolē jābūt skaitlim un burtam',
   'validation.startTime': 'Sākuma laiks nevar būt vēlāks vai vienāds ar beigu laiku',
   'validation.endTime': 'Beigu laiks nevar būt agrāks vai vienāds ar sākuma laiku',
   'tooltip.password.title': 'Parolei:',
   'tooltip.password.characters': 'Jābūt vismaz 7 rakstzīmēm',
   'tooltip.password.number': 'Satur numuru',
   'tooltip.password.letter': 'Satur burtu',
   'tooltip.location': 'Location tooltip',
   'footer.support.center': 'Vajadzīga palīdzība?',
   'not.found': 'Nav atrasts',
   'input.cancelReason.label': 'Atcelšanas iemesls',
   'input.location.label': 'Atrašanās vieta',
   'input.location.placeholder': 'Atrašanā Vietas nosaukums',
   'input.name.label': 'Nosaukums',
   'title.recentlyAddedClients': 'Klienti (nesen pievienoti)',
   'title.calendarView': 'Kalendāra skats',
   'text.manageProfile': 'Pārvaldiet savu lietotāja profilu',
   'modal.language.title': 'Izvēlieties valodu',
   'modal.language.placeholder': 'Meklēšanas valoda',
   'dropdown.access.medium': 'Vidēja',
   'dropdown.access.high': 'Augsts',
   'dropdown.access.low': 'Zems',
   'dropdown.access.basic': 'Bāzes',
   'dropdown.access.no': 'Nav piekļuves',
   'dropdown.access.owner': 'Īpašnieks',
   'button.select.all': 'Izvēlēties visus',
   'button.selected': 'Atlasītās',
   'dropdown.gender.unknown': 'Nezināms',
   'dropdown.gender.male': 'Vīrietis',
   'dropdown.gender.female': 'Sieviete',
   'dropdown.notificationBy.none': 'Nesūtiet',
   'dropdown.notificationBy.sms': 'SMS',
   'dropdown.notificationBy.email': 'E-pasts',
   'dropdown.notificationBy.combined': 'SMS un e-pasts',
   'notification.success.login': 'Veiksmīgi pieteicies!',
   'notification.success.register': 'Veiksmīgi reģistrējies!',
   'dropdown.time.minutes': 'minūtes',
   'dropdown.repeat.no': 'Neatkārtot',
   'dropdown.repeat.weekly': 'Atkārtot katru nedēļu',
   'dropdown.endRepeat.ongoing': 'Vienmēr',
   'dropdown.endRepeat.weekly': 'Līdz konkrētam datumam',
   'dropdown.time.appointment.immediately': 'Nekavējoties',
   'dropdown.time.appointment.upTo1Hour': 'Līdz 1 stundai',
   'dropdown.time.appointment.upTo2Hours': 'Līdz 2 stundām',
   'dropdown.time.appointment.upTo3Hours': 'Līdz 3 stundām',
   'dropdown.time.appointment.upTo4Hours': 'Līdz 4 stundām',
   'dropdown.time.appointment.upTo5Hours': 'Līdz 5 stundām',
   'dropdown.time.appointment.upTo6Hours': 'Līdz 6 stundām',
   'dropdown.time.appointment.upTo7Hours': 'Līdz 7 stundām',
   'dropdown.time.appointment.upTo8Hours': 'Līdz 8 stundām',
   'dropdown.time.appointment.upTo9Hours': 'Līdz 9 stundām',
   'dropdown.time.appointment.upTo10Hours': 'Līdz 10 stundām',
   'dropdown.time.appointment.upTo11Hours': 'Līdz 11 stundām',
   'dropdown.time.appointment.upTo12Hours': 'Līdz 12 stundām',
   'dropdown.time.appointment.upTo13Hours': 'Līdz 13 stundām',
   'dropdown.time.appointment.upTo14Hours': 'Līdz 14 stundām',
   'dropdown.time.appointment.upTo15Hours': 'Līdz 15 stundām',
   'dropdown.time.appointment.upTo16Hours': 'Līdz 16 stundām',
   'dropdown.time.appointment.upTo17Hours': 'Līdz 17 stundām',
   'dropdown.time.appointment.upTo18Hours': 'Līdz 18 stundām',
   'dropdown.time.appointment.upTo19Hours': 'Līdz 19 stundām',
   'dropdown.time.appointment.upTo20Hours': 'Līdz 20 stundām',
   'dropdown.time.appointment.upTo21Hours': 'Līdz 21 stundām',
   'dropdown.time.appointment.upTo22Hours': 'Līdz 22 stundām',
   'dropdown.time.appointment.upTo23Hours': 'Līdz 23 stundām',
   'dropdown.time.appointment.upTo24Hours': 'Līdz 1 dienai',
   'dropdown.time.appointment.upTo48Hours': 'Līdz 2 dienām',
   'dropdown.time.appointment.upTo3days': 'Līdz 3 dienām',
   'dropdown.time.appointment.upTo4days': 'Līdz 4 dienām',
   'dropdown.time.appointment.upTo5days': 'Līdz 5 dienām',
   'dropdown.time.appointment.upTo6days': 'Līdz 6 dienām',
   'dropdown.time.appointment.upTo1week': 'Līdz 1 nedēļai',
   'dropdown.time.appointment.upTo2weeks': 'Līdz 2 nedēļām',
   'dropdown.time.appointment.upTo15Min': 'Līdz 15 minūtēm',
   'dropdown.time.appointment.upTo30Min': 'Līdz 30 minūtēm',
   'dropdown.time.appointment.upTo1Month': 'Līdz 1 mēnesim',
   'dropdown.time.appointment.upTo2Months': 'Līdz 2 mēnešiem',
   'dropdown.time.appointment.upTo3Months': 'Līdz 3 mēnešiem',
   'dropdown.time.appointment.upTo4Months': 'Līdz 4 mēnešiem',
   'dropdown.time.appointment.upTo5Months': 'Līdz 5 mēnešiem',
   'dropdown.time.appointment.upTo6Months': 'Līdz 6 mēnešiem',
   'dropdown.time.appointment.interval5Min': '5 minūtes',
   'dropdown.time.appointment.interval10Min': '10 minūtes',
   'dropdown.time.appointment.interval15Min': '15 minūtes',
   'dropdown.time.appointment.interval20Min': '20 minūtes',
   'dropdown.time.appointment.interval30Min': '30 minūtes',
   'dropdown.time.appointment.interval40Min': '40 minūtes',
   'dropdown.time.appointment.interval45Min': '45 minūtes',
   'dropdown.time.appointment.interval50Min': '50 minūtes',
   'dropdown.time.appointment.interval60Min': '60 minūtes',
   'dropdown.time.appointment.interval75Min': '75 minūtes',
   'dropdown.time.appointment.interval90Min': '90 minūtes',
   'dropdown.time.appointment.interval120Min': '120 minūtes',
   'dropdown.time.appointment.interval150Min': '150 minūtes',
   'dropdown.time.appointment.interval180Min': '180 minūtes',
   'dropdown.time.appointment.interval240Min': '240 minūtes',
   'dropdown.time.appointment.anyTime': 'Jebkurā laikā',
   'dropdown.time.appointment.disabled': 'Izslēgts',
   'dropdown.time.appointment.doesntRepeat': 'Neatkārtojas',
   'dropdown.time.appointment.daily': 'Ikdienas',
   'dropdown.time.appointment.every2Days': 'Ik pēc 2 dienām',
   'dropdown.time.appointment.every3Days': 'Ik pēc 3 dienām',
   'dropdown.time.appointment.every4Days': 'Ik pēc 4 dienām',
   'dropdown.time.appointment.every5Days': 'Ik pēc 5 dienām',
   'dropdown.time.appointment.every6Days': 'Ik pēc 6 dienām',
   'dropdown.time.appointment.every7Days': 'Ik pēc 7 dienām',
   'dropdown.time.appointment.weekly': 'Iknedēļas',
   'dropdown.time.appointment.every2weeks': 'Ik pēc 2 nedēļām',
   'dropdown.time.appointment.every3weeks': 'Ik pēc 3 nedēļām',
   'dropdown.time.appointment.every4weeks': 'Ik pēc 4 nedēļām',
   'dropdown.time.appointment.every5weeks': 'Ik pēc 5 nedēļām',
   'dropdown.time.appointment.every6weeks': 'Ik pēc 6 nedēļām',
   'dropdown.time.appointment.every7weeks': 'Ik pēc 7 nedēļām',
   'dropdown.time.appointment.every8weeks': 'Ik pēc 8 nedēļām',
   'dropdown.time.appointment.every9weeks': 'Ik pēc 9 nedēļām',
   'dropdown.time.appointment.every10weeks': 'Ik pēc 10 nedēļām',
   'dropdown.time.appointment.monthly': 'Ikmēneša',
   'dropdown.time.appointment.every2months': 'Ik pēc 2 mēnešiem',
   'dropdown.time.appointment.every3months': 'Ik pēc 3 mēnešiem',
   'dropdown.time.appointment.every4months': 'Ik pēc 4 mēnešiem',
   'dropdown.time.appointment.every5months': 'Ik pēc 5 mēnešiem',
   'dropdown.time.appointment.every6months': 'Ik pēc 6 mēnešiem',
   'dropdown.time.appointment.every7months': 'Ik pēc 7 mēnešiem',
   'dropdown.time.appointment.every8months': 'Ik pēc 8 mēnešiem',
   'dropdown.time.appointment.every9months': 'Ik pēc 9 mēnešiem',
   'dropdown.time.appointment.every10months': 'Ik pēc 10 mēnešiem',
   'dropdown.time.appointment.every11months': 'Ik pēc 11 mēnešiem',
   'dropdown.time.appointment.after2times': 'Pēc 2 reizēm',
   'dropdown.time.appointment.after3times': 'Pēc 3 reizēm',
   'dropdown.time.appointment.after4times': 'Pēc 4 reizēm',
   'dropdown.time.appointment.after5times': 'Pēc 5 reizēm',
   'dropdown.time.appointment.after6times': 'Pēc 6 reizēm',
   'dropdown.time.appointment.after7times': 'Pēc 7 reizēm',
   'dropdown.time.appointment.after8times': 'Pēc 8 reizēm',
   'dropdown.time.appointment.after9times': 'Pēc 9 reizēm',
   'dropdown.time.appointment.after10times': 'Pēc 10 reizēm',
   'dropdown.time.appointment.after11times': 'Pēc 11 reizēm',
   'dropdown.time.appointment.after12times': 'Pēc 12 reizēm',
   'dropdown.time.appointment.after13times': 'Pēc 13 reizēm',
   'dropdown.time.appointment.after14times': 'Pēc 14 reizēm',
   'dropdown.time.appointment.after15times': 'Pēc 15 reizēm',
   'dropdown.time.appointment.after20times': 'Pēc 20 reizēm',
   'dropdown.time.appointment.after25times': 'Pēc 25 reizēm',
   'dropdown.time.appointment.after30times': 'Pēc 30 reizēm',
   'dropdown.time.appointment.specificDate': 'Konkrēts datums',
   'modal.createHours.title': '{0} darba laiks',
   'modal.editHours.title': '{0} darba laiks',
   'modal.deleteItem.confirm.0': 'Vai tiešām vēlaties neatgriezeniski',
   'modal.deleteItem.confirm.1': 'izdzēst',
   'modal.deleteItem.confirm.2': 'no datu bāzes ?',
   'modal.entity.category': 'Kategorija',
   'modal.entity.referal': 'Novirzīšanas avots',
   'modal.entity.resource': 'Resurs',
   'modal.entity.location': 'Atrašanās vieta',
   'modal.deleteReminder.title': 'Dzēst atgādinājumu',
   'modal.deleteMessage.title': 'Dzēst ziņu',
   'modal.deleteReminder.description': 'Vai esat pārliecināts, ka vēlaties dzēst šo atgādinājumu ?',
   'modal.deleteMessage.description': 'Vai Jūs noteikti vēlaties izdzēst šo ziņu ?',
   'modal.deleteReminder.confirm': 'Jā, dzēst',
   'modal.deletePaymentMethod.title': 'Maksājuma metodes dzēšana',
   'modal.deletePaymentMethod.description': 'Šī maksājuma metode tiks neatgriezeniski dzēsta',
   'closedDate.create.modal.title': 'Pievienot Slēgšanas datums',
   'closedDate.edit.modal.title': 'Labot Noslēgšanas datums',
   'staff.access.owner': 'Īpašnieks',
   'staff.access.high': 'Augsts',
   'staff.access.medium': 'Vidēja',
   'staff.access.low': 'Zems',
   'staff.access.basic': 'Bāzes',
   'staff.access.no_access': 'Nav piekļuves',
   'staff.create.title': 'Pievienot Slēgšanas datums',
   'staff.emptyState.title': 'Nav ieplānotu darbinieku.',
   'staff.emptyState.description': 'Skatiet darbinieku sadaļu, lai pievienotu darba laiku.',
   'staff.viewStaffMembers.button': 'Skatīt darbiniekus',
   'staff.picture.changePhoto': 'Mainīt fotoattēlu',
   'staff.picture.removePhoto': 'Noņemt fotoattēlu',
   'input.clientSearch.placeholder': 'Meklēt pēc Vārda, e-pasta vai tālruņa numura',
   'input.blockedDate.label': 'Datums',
   'appointment.notes.title': 'Apmeklējuma piezīmes',
   'appointment.history.title': 'Apmeklējumu vēsture',
   'appointment.history.by.title': 'līdz {0}',
   'appointment.log.created.label': 'Izveidots',
   'appointment.log.updated.label': 'Atjaunots pēc',
   'appointment.log.rescheduled.label': 'Pārplānotā ar',
   'appointment.log.cancelled.label': 'Atcēla',
   'appointment.log.changed.label': 'Mainīts statuss pēc',
   'appointment.notificationLog.rescheduling': 'Paziņojums par pārplānošanu nosūtīts',
   'appointment.notificationLog.cancellation': 'Paziņojums par atcelšanu nosūtīts',
   'appointment.notificationLog.confirmation': 'Apstiprinājuma paziņojums nosūtīts',
   'appointment.notificationLog.reminder': 'Atgādinājuma paziņojums nosūtīts',
   'appointment.notificationLog.thankYou': 'Pateicības paziņojums nosūtīts',
   'appointment.notificationLog.lastVisit': 'Pēdējā apmeklējuma paziņojums nosūtīts',
   'state.cancelReason.title': 'Iestatiet atcelšanas iemeslus',
   'state.cancelReason.description': 'Atcelšanas iemesli palīdz izsekot, kāpēc klienti neieradās uz Rezervāciju.',
   'state.cancelReason.button': 'Pievienojiet atcelšanas iemeslu',
   'state.resources.title': 'Pārvaldiet savus resursus',
   'state.resources.description':
      'Bizness ir veiksmīgāks, ja zināt par pieejamajiem resursiem. Sekojiet istabām, mēbelēm vai jebkurai citai ierīcei, lai nodrošinātu vienmērīgus pakalpojumus un to plānveida apkopšanu.',
   'state.resources.button': 'Pievienojiet resursu',
   'state.referralSource.title': 'Rīkojieties ar novirzīšanas avotiem',
   'state.referralSource.description':
      'Novirzīšanas avots norāda, no kurienes klienti nāk. Sekojiet tiem un izmantojiet šo informāciju, lai, iespējams, palielinātu sava uzņēmuma redzamību un reklāmguvumu līmeni.',
   'state.referralSource.button': 'Pievienojiet novirzīšanas avotu',
   'state.locations.title': 'Iestatiet atrašanās vietas',
   'state.locations.description':
      'Šeit jūs varat pārvaldīt vairākas uzņēmuma atrašanās vietas, iestatīt atšķirīgu darba laiku darbiniekiem vienā atrašanās vietā un katram personālam izveidot atsevišķus kalendārus.',
   'state.staff.title': 'Pārvaldiet personālu',
   'state.staff.description':
      'Izveidojiet profilus saviem darbiniekiem, lai jūs varētu pievienot un rediģēt viņu grafikus, kā arī sekot viņu tikšanās reizēm un darba laikam.',
   'state.messageLog.title': 'Sekojiet ziņojumiem',
   'state.messageLog.description':
      'Ziņojumi atgādina jūsu klientiem par gaidāmajām tikšanās reizēm vai informē viņus. Šeit jūs varēsit izsekot ziņojumiem un to statusam. Lai konfigurētu automātiskos ziņojumus, dodieties uz',
   'state.messageLog.link': 'Paziņojumu iestatījumi.',
   'state.clients.title': 'Pārvaldiet klientus',
   'state.clients.description':
      'Rūpējoties par saviem klientiem, vispirms pārliecinieties, vai viņu profilos ir visa nepieciešamā informācija, lai nodrošinātu vienmērīgu pieredzi un iespēju sazināties ar viņiem.',
   'state.clients.button': 'pievienot klientu',
   'state.services.title': 'Iestatiet grupas un pakalpojumus',
   'state.services.description':
      'Grupas ir būtiskas, jo tās ļauj grupēt līdzīgus pakalpojumus, kurus jūs sniedzat. Turklāt tie padara jūsu kalendāru tīru un viegli pieejamu!',
   'state.services.button': 'Pievienojiet pakalpojumu grupu',
   'state.calendar.title': 'Nav plānota personāla',
   'state.calendar.description': 'Lai iestatītu personāla darba laiku, skatiet',
   'state.calendar.button': 'Personāla daļa.',
   'state.closedDates.title': 'Brīvdienas',
   'state.closedDates.description':
      'Uzskaitiet datumus jūsu bizness ir slēgts svētku, uzturēšanu vai jebkuru citu iemeslu dēļ. Klienti nevarēs ievietot tiešsaistes rezervācijas šajās dienās.',
   'state.closedDates.button': 'Pievienot Brīvdienas',
   'title.billingPlan': 'Plāns un rēķini',
   'btn.upgrade': 'Jaunināt',
   'header.usage': 'IZMANTOŠANA',
   'header.dropdown.subscription.plan': '{0} Plāns',
   'header.dropdown.subscription.staff': '({0} Darbinieki)',
   'header.dropdown.notifications.description': '{0} ziņojumi',
   'header.paymentDetails': 'Skatīt maksājumu informāciju',
   'title.billingPlan.description':
      'Pārvaldiet savus kontus. Plāna un norēķinu informāciju, skatiet darījumus un lejupielādējiet rēķinus.',
   'plan.free.title': 'Bez maksas',
   'plan.individual.title': 'Individuāls (1 strādnieks)',
   'plan.business.title': 'Bizness',
   'btn.upgradeNow': 'Jaunināt',
   'btn.getStarted': 'Sākt',
   'btn.currentPlan': 'Pašreizējais plāns',
   'plan.free.option.0': '1 Atrašanās vieta',
   'plan.free.option.1': 'Līdz 5 darbiniekiem',
   'plan.free.option.2': 'Neierobežotas rezervācijas',
   'plan.free.option.3': 'Neierobežoti klienti',
   'plan.free.option.4': 'Neierobežotas tiešsaistes rezervācijas',
   'plan.free.option.5': 'E -pasta paziņojumi',
   'plan.premium.option.0': 'Neierobežotas vietas',
   'plan.premium.option.1': 'Neierobežotas rezervācijas',
   'plan.premium.option.2': 'Neierobežoti klienti',
   'plan.premium.option.3': 'Neierobežotas tiešsaistes rezervācijas',
   'plan.premium.option.4': 'Atkārtotas tikšanās',
   'plan.premium.option.5': 'E -pasta paziņojumi',
   'plan.premium.option.6': 'SMS paziņojumi*',
   'plan.premium.option.7': 'WhatsApp paziņojumi*',
   'plan.premium.option.8': 'Neierobežota Analītika',
   'plan.premium.option.9': 'Neierobežoti Ziņojumi',
   'plan.premium.option.10': 'Drukas grafiki',
   'plan.premium.option.11': 'Klienta eksports',
   'plan.premium.option.12': 'Prioritātes atbalsts',
   'plan.premium.option.13': 'No e -pastiem noņemts Plandoka zīmols (bezmaksas e -pasti, kurus darbina Plandoks)',
   'plan.premium.costsInfo': '*SMS/WhatsApp izmaksas, kas nav iekļautas plānā, tiek piemērotas papildu maksa.',
   'plan.premium.activeUntilDate': 'Aktīvs līdz {0}',
   'period.perMonth': 'mēnesī (bez PVN)',
   'plan.premium.description': 'Cena palielināsies, pievienojot vairāk darbinieku.',
   'plan.premium.viewPricing': 'Cenu pārskats',
   'plan.premium.numberStuff': 'Jūsu pašreizējais darbinieku skaits: {0}',
   'premium.membership': 'Premium dalības cena var atšķirties atkarībā no jūsu darbinieku skaita.',
   'premium.price.perMonth': 'Premium cena mēnesī',
   'pricing.calculated': 'Cena tiek aprēķināta šādi:',
   'pricing.calculator': 'Cenu kalkulators',
   'pricing.numberStuff': 'Darbinieku skaits',
   'pricing.extra.member': '{0} katram papildu dalībniekam',
   'modal.title.pricing': 'Cenu noteikšana',
   'plan.foreverFree': 'Uz visiem laikiem bez maksas',
   'plan.individual.0': 'Viss no bezmaksas, kā arī:',
   'plan.individual.1': 'Pielāgoti paziņojumi',
   'plan.individual.2': 'Noņemts plandok zīmolis',
   'plan.individual.option.3': 'Neierobežoti SMS atgādinājumi',
   'plan.individual.4': 'Prioritārs atbalsts',
   'plan.business.0': 'Viss no individuāla, kā arī:',
   'plan.business.1': 'Neierobežotas vietas',
   'plan.business.2': 'Neierobežots personāls',
   'block.billingInfo.title': 'Informācija par rēķiniem',
   'block.carInfo.title': 'Informācija par kredītkarti',
   'input.cardNumber.label': 'Kartes numurs',
   'input.cardNumber.placeholder': '1236 5678 3456 2456',
   'input.expireDate.label': 'Derīguma termiņš',
   'input.expireDate.placeholder': '05 / 21',
   'input.cvv.label': 'CVV',
   'input.cvv.placeholder': '123',
   'modal.confirmCancel.subscription': 'Vai tiešām vēlaties atcelt abonementu?',
   'modal.confirmCancel.subscription.description':
      'Kad esat to izdarījis, jūs atgriezīsities pie bezmaksas versijas izmantošanas.',
   'btn.cancelSubscription.confirm': 'Jā, atcelt manu abonementu',
   'loading.processingSubscribtion': 'Jūsu maksājums tiek apstrādāts. Lūdzu uzgaidiet...',
   'state.serviceGroup.description': 'Grupā nav pakalpojumu',
   'state.serviceGroup.btn': 'Pievienojiet pakalpojumu',
   'appointment.status.new': 'Jauna rezervācija',
   'appointment.status.confirmed': 'Apstiprināts',
   'appointment.status.notShow': 'Neieradās',
   'appointment.status.completed': 'Pabeigts',
   'appointment.status.cancelled': 'Atcelts',
   'billing.tab.plan': 'Jūsu plāns',
   'billing.tab.costs': 'Paredzamās izmaksas',
   'billing.tab.billing': 'Informācija par rēķiniem',
   'billing.tab.invoice': 'Rēķini',
   'plan.free': 'Bezmaksas',
   'plan.deactivated.title': 'Premium ir deaktivizēts',
   'plan.active.title': 'Aktivizēt',
   'payment.step.title': 'Maksājumi',
   'payment.error.title': 'Atjaunināt maksājumu',
   'payment.payNow.title': 'Maksāt',
   'payment.dismiss.title': 'Atlaist',
   'payment.tab.title.sub': 'Abonēšana',
   'payment.tab.subTitle': 'Premium plāns',
   'payment.per.members': 'vienam dalībniekam',
   'payment.tab.title.notification': 'Paziņojumi',
   'payment.card.details': 'Kartes informācija',
   'payment.success.message': 'Maksājums tika veikts veiksmīgi',
   'payment.payWithCard.title': 'Maksāt ar karti',
   'payment.cardNumber.title': 'Kartes numurs',
   'payment.expirationDate.title': 'Derīguma termiņš',
   'payment.digits.title': '(3 cipari)',
   'payment.amount': 'Maksājuma summa:',
   'payment.btn.pay': 'Maksājiet',
   'invoice.table.date': 'Datums',
   'invoice.table.invoice': 'Rēķins',
   'invoice.table.download': 'Lejupielādēt',
   'invoice.table.status': 'Statusu',
   'costs.billingPeriod.title': 'Paredzamās izmaksas šim norēķinu periodam',
   'costs.billingPeriod.description':
      'Šīs ir pašreizējās lietošanas izmaksas šajā norēķinu periodā. Tālāk ir pieejams izmaksu sadalījums.',
   'costs.billingPeriod.additionalInfo':
      'Kad jūsu izdevumi par paziņojumiem sasniegs 50 €, tie tiks iekasēti automātiski. Taču neuztraucieties, ja summa ir mazāka; tā tiks ērti iekļauta jūsu ikmēneša abonementā.',
   'costs.summary.title': 'Mēneša līdz datumam kopsavilkums',
   'costs.summary.description': 'Šīs izmaksas tiek iekļautas jūsu konta bilancē',
   'costs.summary.detailedCharges': 'Detalizētas maksas',
   'costs.summary.noData': 'Nav datu',
   'costs.summary.quantity': 'Daudzums',
   'costs.summary.subtotal': 'Starpsumma',
   'costs.summary.taxes': 'Nodokļi',
   'dropdown.time.hours': 'Stundas',
   'message.planUpgrade': 'Jūs esat veiksmīgi jauninājis savu plānu!',
   'costs.subscription': 'Abonēšana',
   'btn.cancelSubscription': 'Atcelt plānu',
   'btn.no': 'Nē',
   'notification.success.update': 'Veiksmīgi atjaunināta',
   'validation.password.match': 'Paroles nesakrīt',
   'blocked.time': 'Bloķēts laiks',
   'message.successReset.password': 'Paroles atiestatīšanas e-pasts ir nosūtīts. Lūdzu, pārbaudiet iesūtni.',
   'title.newPassword': 'Ievadiet jauno paroli',
   'title.newPassword.description': 'Droši piekļūstiet savam kontam, izveidojot jaunu pieteikšanās paroli',
   'input.newPasswordRepeat.label': 'Apstipriniet jauno paroli',
   'btn.change.password': 'Mainīt manu paroli',
   'text.havingTrouble': 'Ir problēmas?',
   'link.resetPassword.again': 'Atkal atiestatiet savu paroli',
   'message.successNew.password': 'Parole ir veiksmīgi nomainīta.',
   'title.repeatShifts': 'Atkārtota Maiņa',
   'text.confirmShift.update':
      'Jūs esat rediģējis maiņu, kas atkārtojas katru nedēļu. Dzēšot gaidāmās maiņas, tiks pārrakstīts {0} pašreizējais {0} grafiks',
   'text.confirmShift.delete':
      'Jūs izdzēšat maiņu, kas atkārtojas katru nedēļu. Dzēšot gaidāmās maiņas, tiks pārrakstīts {0} pašreizējais {0} grafiks',
   'btn.deleteUpcoming.shifts': 'Dzēst gaidāmās maiņas',
   'btn.deleteThis.shift': 'Dzēst tikai šo maiņu',
   'btn.updateUpcoming.shifts': 'Atjauniniet gaidāmās maiņas',
   'btn.updateThis.shift': 'Atjaunināt tikai šo maiņu',
   'text.permisionTab':
      'Iestatiet, kuras sadaļas ir pieejamas katram lietotāja atļauju līmenim. Visi reģistrētie darbinieki var piekļūt kalendāram, un īpašnieku kontiem ir pilnīga piekļuve sistēmai.',
   'title.permission.locations': 'Atrašanās vieta',
   'title.permission.booking': 'Rezervēšana un Klienti',
   'title.permission.staff': 'Personāls',
   'title.permission.setup': 'Iestatījumi',
   'title.permission.basic': 'Bāzes',
   'title.permission.low': 'Zems',
   'title.permission.med': 'Vidēja',
   'title.permission.high': 'Augsts',
   'title.permission.owner': 'Īpašnieks',
   'title.allLocations.permission': 'Piekļūt visām atrašanās vietām',
   'title.accessCalendar.permission': 'Piekļūt savam kalendāram',
   'title.accessOtherCalendar.permission': 'Piekļūt citu darbinieku kalendāriem',
   'title.canBook.permission': 'Var izveidot Rezervācijas',
   'title.contactInfo.permission': 'Var redzēt klienta kontaktinformāciju',
   'title.clients.permission': 'Klienti',
   'title.messages.permission': 'Ziņas',
   'title.services.permission': 'Pakalpojumi',
   'title.workingHours.permission': 'Darba laiks',
   'title.staffMembers.permission': 'Personāls',
   'title.permissionLevels.permission': 'Atļauju līmeņi',
   'title.accountSetup.permission': 'Konta iestatīšana',
   'title.clientSettings.permission': 'Klienta iestatījumi',
   'title.onlineBookings.permission': 'On-Line Rezervācijas',
   'title.analytics.permission': 'Analītika',
   'title.marketing.permission': 'Mārketings',
   'notification.type.reminder': 'Atgādinājumi',
   'notification.type.confirmation': 'Apstiprinājumi',
   'notification.type.online_booking_confirmation': 'On-Line rezervācijas apstiprinājums',
   'notification.type.rescheduling': 'Pārplānošana',
   'notification.type.cancellation': 'Atcelšana',
   'notification.type.thank_you': 'Paldies',
   'notification.type.last_visit': 'Pēdējā vizīte',
   'notification.channel.sms': 'SMS',
   'notification.channel.email': 'E-pasts',
   'notification.channel.whatsapp': 'WhatsApp',
   'notification.status.sent': 'Nosūtīts',
   'notification.status.pending': 'Nepabeigts',
   'notification.status.failed': 'Neizdevās',
   'dropdown.time.days': 'dienas',
   'title.appointment': 'Pieraksts',
   'input.reminderBeforeLastVisit.label': 'Paziņojums tiks nosūtīts pēc norādīto dienu skaita',
   'txt.with': 'ar',
   'short.hours': 'stun',
   'short.minutes': 'min',
   'input.registrationNumber.label': 'Uzņēmuma numurs',
   'input.vatNumber.label': 'PVN numurs',
   'invoice.table.priceTaxIncl': 'Summa ar PVN',
   'error.wrongSenderId':
      'Sūtītāja ID jāietver cipari vai rakstzīmes, garumam jābūt lielākam par 3 un mazākam par 11 simboliem',
   'sidebar.help': 'Palīdzība',
   'modal.help.title': 'Kā mēs varam palīdzēt?',
   'modal.help.liveChat.title': 'Tērzēšana tiešraidē',
   'modal.help.liveChat.description': 'Jums ir kādi jautājumi? Jautājiet mums uzreiz',
   'modal.help.suggestFeature.title': 'Ierosināt funkciju',
   'modal.help.suggestFeature.description':
      'Kopīgojiet ieteikumus, skatiet citu ieteikumus un balsojiet par vēlamajām funkcijām',
   'modal.help.viewUpdates.title': 'Skatiet atjauninājumus',
   'modal.help.viewUpdates.description': 'Skatiet mūsu jaunākos atjauninājumus un uzlabojumus',
   'warning.title.paidFeature': 'Apmaksāta funkcija',
   'warning.smsFeatureMissing': 'Lai piekļūtu SMS, jums ir jājaunina abonements. Lūdzu, apmeklējiet lapu',
   'warning.link.billingAndPricing': 'Plāns',
   'warning.newPage.0': 'atjaunots!',
   'warning.newPage.1': 'Ja izmantojāt veco Plandok sistēmu, lūdzu, parole vairs nedarbosies',
   'warning.newPage.2': 'nomainīt paroli',
   'warning.newPage.3':
      'lai jūs varētu izbaudīt jauno sistēmu. Lai iegūtu papildu jautājumus, lūdzu, sazinieties ar mums',
   'warning.newPage.4': 'vai',
   'title.blockTime.select': 'Izvēlieties laiku',
   'calendar.allResources': 'Visi resursi',
   'appointment.edit.title': 'Rediģēt Rezervāciju',
   'appointment.create.title': 'Rezervācija ir veiksmīgi izveidota.',
   'appointment.update.title': 'Rezervācija ir veiksmīgi atjaunināta.',
   'select.notify.title': '{0} nedarbojas atlasītajā intervālā, taču jūs joprojām varat rezervēt tikšanās.',
   'booking.notify.title': 'Izvēlētais laiks ir rezervēts jebkurā gadījumā, jūs varat izveidot rezervāciju.',
   'textArea.characters.left': 'rakstzīmes palikušas',
   'textArea.characters.exceeded': 'ierobežojums ir pārsniegts par',
   'validation.characters.exceeded': 'Rakstzīmju ierobežojums ir pārsniegts',
   'staff.menu.members': 'Personāls',
   'staff.menu.dates': 'Brīvdienas',
   'staff.menu.working': 'Darba laiks',
   'staff.menu.permissions': 'Lietotāju atļaujas',
   'staff.menu.members.description':
      'Pievienojiet vai noņemiet komandas locekļus un kontrolējiet viņu piekļuves līmeni',
   'staff.menu.dates.description': 'Plānojiet gaidāmās brīvdienas vai valsts svētkus',
   'staff.menu.working.description': 'Pārvaldiet kalendāra pieejamību, pielāgojot personāla darba grafikus',
   'staff.menu.permissions.description': 'Iestatiet, kuras sadaļas ir pieejamas katram lietotāja atļauju līmenim',
   'message.successUpdateLocation': 'Paveikts! Veiksmīgi atjaunināts filiāles vietas profils.',
   'ob.title': 'On-Line Rezervācijas',
   'ob.overview.tab': 'Pārskats',
   'ob.massMessages.tab': 'Masveida ziņojumi',
   'ob.campaigns.tab': 'Kampaņas',
   'ob.profile.tab': 'Online profils',
   'ob.links.tab': 'saites',
   'ob.settings.tab': 'Iestatījumi',
   'ob.payments.tab': 'Maksājumi',
   'ob.status.online': 'Online',
   'ob.status.offline': 'Bezsaistē',
   'ob.status.title': 'Statuss',
   'ob.profile.step': '{0} no 4',
   'ob.profile.step.title': 'Profils un atrašanās vieta',
   'ob.profile.step.description':
      'Prezentēt savu filiāli un ieviest pakalpojumus, ko sniedzat saviem klientiem. "Par" daļa ir viens no pirmajiem posmiem jūsu klienti tiks meklē pēc, un, ja šī informācija nav pietiekami, pārliecinieties atstāt tālruņa numuru, kur klienti var sazināties ar jums, lai iegūtu vairāk informācijas! Ievadiet adresi birojā, lai klienti zina, kur atrast jums un redzēt šo informāciju.',
   'ob.overview.placheholder.title': 'Ļaujiet jūsu klientiem rezervēt tikšanās tiešsaistē!',
   'ob.overview.placheholder.description':
      'Izveidot tiešsaistes rezervācijas profilu un ļaujiet jūsu klientiem veikt On-line Rezervācijas. Vienkārši ievietojiet saiti jūsu tīmekļa vietnē vai sociālajos medijos, un tas arī viss.',
   'ob.overview.start.now': 'Sākt tagad',
   'ob.links.direct.title': 'Tiešā saite',
   'ob.links.facebook.title': 'Facebook Link',
   'ob.links.instagram.title': 'Instagram saite',
   'ob.links.direct.description':
      'Viss ir ļoti vienkārši, vienkārši izmantojiet šo saiti, kur jums ir ērti. Neatkarīgi no tā, vai tā ir jūsu vietne vai kāda cita vietne, klienti, izmantojot šo saiti, sasniegs jūsu tiešsaistes profilu un varēs paši reģistrēties.',
   'ob.links.facebook.description':
      'Šeit jūs atradīsiet norādījumus par to, kā ievietot saiti uz jūsu Facebook kontā, lai klienti var pieteikties tieši no Facebook',
   'ob.links.instagram.description':
      'Viss ir ļoti vienkārši, tikai izmantot šo saiti, kur jūs jūtaties ērti. Vai tas ir jūsu dzīvojamā istaba, vai jebkuru citu tīmekļa vietnē, izmantojot šo saiti klientiem, lai sasniegtu savu tiešsaistes profilu un varēs jāreģistrējas.',
   'ob.analytics.tab.description':
      'Pievienojiet Google Analytics un Facebook Pixel savai tiešsaistes rezervāciju lapai, lai analizētu datplūsmu un lietotāju uzvedību.',
   'ob.analytics.google.title': 'Google Analytics',
   'ob.analytics.facebook.title': 'Facebook Pixel',
   'ob.analytics.google.description':
      'Izmantojot Google Analytics, varat izsekot un analizēt vietnes datplūsmu un lietotāju uzvedību, lai gūtu ieskatu, kā uzlabot savu vietni.',
   'ob.analytics.active': 'Aktīvā',
   'ob.analytics.inactive': 'Neaktīvs',
   'ob.analytics.setUp.btn': 'Iestatīts',
   'ob.analytics.google.description.1':
      'Google Analytics ir jaudīgs rīks, kas var palīdzēt jums uzraudzīt vietnes datplūsmu un lietotāju uzvedību. Izmantojot Google Analytics, varat izsekot, cik daudz apmeklētāju apmeklē jūsu vietni, no kurienes viņi nāk, kādas lapas apmeklē un cik ilgi uzturas jūsu vietnē.',
   'ob.analytics.google.description.2':
      'Turklāt Google Analytics ļauj iestatīt mērķus un sekot līdzi sarunām, tādējādi gūstot labāku izpratni par to, cik labi darbojas jūsu vietne un kā to uzlabot.',
   'ob.analytics.google.trackingId': 'Izsekošanas ID',
   'ob.analytics.google.tooltip':
      'Lai atrastu savu izsekošanas ID, vispirms jāpiesakās savā Google Analytics kontā, jāizvēlas tīmekļa vietne vai lietotne, kuru vēlaties izsekot, un pēc tam jāpāriet uz cilni "Administrators". Tur noklikšķiniet uz "Izsekošanas kods". Jūsu izsekošanas ID tiks parādīts formātā "UA-XXXX-Y".',
   'ob.analytics.google.account': 'Vai jums vēl nav Google Analytics konta?',
   'ob.analytics.click': 'Noklikšķiniet uz',
   'ob.analytics.here': 'šeit',
   'ob.analytics.learn': 'lai uzzinātu, kā to izveidot.',
   'ob.analytics.facebook.description.1':
      'Facebook Pixel ir rīks, kas ļauj jums izsekot lietotāju uzvedībai jūsu vietnē, tostarp reklāmguvumiem, lapu skatījumiem un citiem notikumiem.',
   'ob.analytics.facebook.description.2':
      'Facebook Pixel var sniegt jums vērtīgu informāciju par jūsu auditoriju, piemēram, demogrāfiju, interesēm, ierīces veidu, lapu skatījumiem un veiktajām darbībām, kas var palīdzēt jums labāk izprast un optimizēt lietotāju pieredzi jūsu vietnē.',
   'ob.analytics.facebook.pixelId': 'Pikseļa ID',
   'ob.analytics.facebook.tooltip':
      'Lai atrastu savu Facebook Pixel ID, izpildiet šādas darbības: Dodieties uz savu Facebook reklāmu pārvaldnieka kontu. Noklikšķiniet uz pogas "Izvēlne" ekrāna augšējā kreisajā stūrī. Sadaļā "Notikumu pārvaldnieks" izvēlieties "Pikseļi". Šeit redzēsiet visu ar jūsu kontu saistīto pikseļu sarakstu. Noklikšķiniet uz tā pikseļa nosaukuma, kura ID jums ir nepieciešams. Jūsu pikseļa ID tiks parādīts ekrāna augšējā kreisajā stūrī blakus pikseļa nosaukumam.',
   'ob.analytics.facebook.account': 'Vai jums vēl nav Facebook uzņēmuma lapas?',
   'ob.analytics.google.account.mobile.1': 'Jums vēl nav Google Analytics',
   'ob.analytics.google.account.mobile.2': 'konts? Noklikšķiniet uz',
   'ob.analytics.google.account.mobile.3': 'lai uzzinātu, kā',
   'ob.analytics.google.account.mobile.4': 'izveidot kontu.',
   'ob.analytics.facebook.account.mobile.1': 'Jums vēl nav Facebook',
   'ob.analytics.facebook.account.mobile.2': 'biznesa lapa? Noklikšķiniet uz',
   'ob.analytics.facebook.account.mobile.3': 'lai uzzinātu, kā',
   'ob.analytics.facebook.account.mobile.4': 'to izveidotu.',
   'ob.analytics.successfullySaved.message': 'Veiksmīgi saglabāts',
   'ob.settings.booking.title': 'Rezervācija pieejamība',
   'ob.settings.booking.description':
      'Uzstādiet tuvāko un vēlāko laiku, kad klienti var veikt rezervāciju On-line. Organizēt vizītes laika intervālus un nodrošināt klientus ar attiecīgo informāciju. Darbinieka izvēles iespējas.',
   'ob.settings.cancellation.title': 'Atcelšana un rediģēšana',
   'ob.settings.cancellation.description': 'Uzstādiet laiku, kad klienti var atcelt vai labot apmeklējums',
   'ob.settings.locationsUrl.title': 'URL atrašanās vietu',
   'ob.settings.locationsUrl.description':
      'Dalīties ar savu biznesa URL saite visas vienības. Izmantojiet tikai tos uzņēmumus, kas ir vairāk nekā viena vienība.',
   'ob.settings.businessName.explanation': 'URL: book.plandok.com/business-name/Location2556',
   'ob.settings.notifications.title': 'E-pasta paziņojumi',
   'ob.settings.notifications.description':
      'Izvēlieties adresātus, kuriem tiks paziņots E-pastā, par Klienta jaunu On-line Rezervāciju',
   'ob.settings.required.title': 'Obligātie un neobligātie lauki',
   'ob.settings.required.description': 'Izvēlieties, kuri lauki klientam būs jāaizpilda, veicot On-line rezervācijas.',
   'ob.settings.requiredField.title': 'Obligāti',
   'ob.settings.optionalField.title': 'Neobligāti',
   'ob.settings.requiredField.notification.title': 'Bija problēma ar iestatījumiem',
   'ob.settings.requiredField.notification.description':
      'Atvainojiet, bet vienam laukam jābūt obligātam, izņemot "piezīmes".',
   'ob.settings.nameField.title': 'Vārds',
   'ob.settings.lastNameField.title': 'Uzvārds',
   'ob.settings.phoneField.title': 'Telefona numurs',
   'ob.settings.emailField.title': 'E-pasts',
   'ob.settings.notes.label': 'Piezīmju aizvietotājteksts',
   'ob.settings.notes.placeholder': 'Piemēram, Ievadiet savas piezīmes',
   'ob.bookingLimit.title': 'Tiešsaistes rezervācijas ierobežojums',
   'ob.bookingLimit.description': 'Lūdzu, norādiet maksimālo pakalpojumu rezervāciju skaitu, ko vēlaties iestatīt.',
   'ob.bookingLimit.label': 'Pakalpojumu rezervāciju ierobežošana',
   'ob.bookingLimit.unlimited.title': 'Neierobežots',
   'ob.bookingLimit.1service.title': 'maks. 1 Pakalpojums',
   'ob.bookingLimit.2services.title': 'maks. 2 Pakalpojumi',
   'ob.bookingLimit.3services.title': 'maks. 3 Pakalpojumi',
   'ob.menu.edit.profile': 'Rediģēt profilu',
   'ob.menu.turnOff.profile': 'Izslēgt',
   'ob.profile.status': 'Statusu',
   'ob.overview.tab.description':
      'Uzlabojiet savu biznesu, ļaujot klientiem veikt Rezervācijas internetā vai citos Soc. tīklos.',
   'ob.profile.tab.description':
      'Pārvaldiet savu tiešsaistes profilu, pielāgot foto, apraksts, adresi un citu informāciju.',
   'ob.links.tab.description': 'Jūs varat atrast saiti uz profilu, kuru jūs varat dalīties ar saviem klientiem.',
   'ob.settings.tab.description':
      'Tiešsaistes reģistrācijas iestatījumus, lai jūsu klienti var reģistrēties, ja vēlaties.',
   'ob.payments.tab.description':
      'Izvēlieties, kuras maksājumu metodes jūsu klienti varēs izmantot, maksājot par pakalpojumiem.',
   'ob.payments.page.description':
      'Šeit varat izvēlēties, kuras maksājumu metodes klienti varēs izmantot, maksājot par pakalpojumiem.',
   'ob.payOnSite.title': 'Maksāt uz vietas',
   'ob.payOnSite.description': 'Jūsu klienti varēs norēķināties uz vietas ar skaidru naudu.',
   'ob.payWithCard.title': 'Maksāt ar karti',
   'ob.payWithCard.description': 'Jūsu klienti varēs maksāt ar kredītkarti, izmantojot Stripe lietotni.',
   'ob.payWithCard.info.1': 'Jūs izmantojat bezmaksas Plandok plānu, tāpēc jums būs jāmaksā.',
   'ob.payWithCard.info.2': 'Plandok komisijas maksa un Stripe komisijas maksa, izmantojot šo maksāšanas metodi.',
   'ob.payWithCard.info.3': 'lai būtu',
   'ob.payWithCard.info.4': 'Plandok komisijas maksu.',
   'ob.payWithCard.info.5': 'Premium plāns nav atbrīvots no Stripe komisijas maksas maksāšanas.',
   'ob.payWithCard.info.6': 'Pārbaudiet Stripe cenas šeit',
   'ob.stripeConnected.info': '(Stripe savienots)',
   'ob.requiresSetup.info': '(Nepieciešams izveidot Stripe kontu)',
   'ob.connectToStripe.btn': 'Pievienoties Stripe',
   'ob.connectToStripe.successMessage.title': 'Veiksmīgi izveidots savienojums ar Stripe',
   'ob.waitingAuthorization.title': 'Gaidām jūsu autorizāciju Stripe',
   'ob.stripeDashboard.title': 'Visi maksājumu žurnāli un atmaksājumi ir pieejami',
   'ob.stripeDashboard.url': 'Stripe vadības panelī',
   'ob.yourStripeAccount.title': 'Jūsu Stripe konts',
   'ob.visitStripe.option': 'Apmeklējiet Stripe',
   'ob.changeAccount.option': 'Mainīt Stripe kontu',
   'input.earliestAppointment.label': 'Tuvākā reģistrācija',
   'input.earliestAppointment.placeholder': 'Neilgi pirms vizītes,',
   'input.latestAppointment.label': 'Vēlāka reģistrācija',
   'input.latestAppointment.placeholder': 'Ne vairāk kā 6 mēnešus uz priekšu',
   'input.timeSlots.label': 'Laika intervāli',
   'input.timeSlots.placeholder': '15 minūtes',
   'checkbox.allowCustomers': 'Ļauj klientiem izvēlēties darbinieku',
   'input.importantInfo.label': 'Svarīga informācija',
   'input.importantInfo.placeholder': 'Informāciju, kas tiks parādīta klienta rezervācijas',
   'input.changeAppointment.label': 'Klients var rediģēt vai atcelt rezervāciju',
   'input.changeAppointment.placeholder': 'Vienmēr',
   'checkbox.sendToBooked': 'Nosūtīt izvēlētajam darbiniekam',
   'checkbox.sendSpecificEmail': 'Nosūtīt uz norādītoto E-pasta adresi',
   'input.specificEmail.label': 'Norādītā e-pasta adrese',
   'input.specificEmail.placeholder': 'Ievadiet e-pasta adresi',
   'input.locationsUrl.label': 'Locations URL',
   'input.locationsUrl.placeholder': 'Piem. Junis-salons',
   'ob.btn.save': 'Saglabāt',
   'ob.btn.cancel': 'Atsaukt',
   'ob.btn.showLink': 'Rādīt saiti',
   'ob.btn.active': 'Aktīvs',
   'ob.btn.inactive': 'Neaktīvs',
   'ob.linkModal.title': 'Tieša saite rezervācijām',
   'ob.linkModal.description': 'Šeit ir saite, kur iespējams piekļūt, izmantojot internetu!',
   'ob.linkModal.afterDescription':
      'Šī saite novirza lietotājus tieši uz jūsu izveidoto tiešsaistes profiliem. Tikai pāris pogu klikšķiem klienti var izvēlēties vēlamo pakalpojumu un reģistrēt apmeklējumu. Un jums tiks paziņots pa e-pastu, bet jūs redzēsiet klienta kontu savā kalendārā.',
   'ob.fbLinkModal.title': 'Facebook lapa instrukcijas',
   'ob.fbLinkModal.description':
      'Gatavi Reģistrācijas Pogas izveidei, kuru var integrēt jūsu Facebook kontā, un klienti varēs reģistrēties tieši no jūsu Facebook kontā.',
   'ob.fbLinkModal.step1': 'Piesakieties savā Facebook lapā',
   'ob.fbLinkModal.step2': "Noklikšķiniet uz pogas ''Pievienot pogu'' tuvu vāka fotoattēlam",
   'ob.fbLinkModal.step3': "Izvēlieties 'Reģistrēties', un ievietot savu saiti",
   'ob.linkModalCopySuccess.title': 'Saite kopēta!',
   'ob.btn.copyLink': 'Kopēt saiti',
   'title.appointmentSlot.select': 'Izvēlieties laiku',
   'btn.back': 'Atpakaļ',
   'btn.continue': 'Tālāk',
   'ob.hours.open': 'Atvērts no:',
   'ob.hours.close': 'Slēgts',
   'ob.hours.step.title': 'Darba laiks',
   'ob.hours.step.description':
      'Darba laiks. Tas ir tikai informatīviem nolūkiem, klientiem, kas rezervē, izmantojot internetu, sistēma pārbauda darbinieku darba laika un sniegs tikai tur ievadīto brīvo laiku.',
   'ob.staffHours.step.title': 'Personāla darba laiks',
   'ob.staffHours.step.description':
      'Svarīgs solis, lūdzu, pievienojiet darba laiku, lai sistēma zinātu, kad klienti var rezervēt tiešsaistē.',
   'ob.location.step.title': 'Filiāles Informācija',
   'ob.photo.step.title': 'Attēli',
   'ob.photo.step.description':
      'Pievienojiet fotoattēlus, lai klienti būtu informēti par jūsu pakalpojumiem. Labas bildes pievērš uzmanību',
   'input.file.dnd.photos': 'Augšupielādēt fotoattēlus',
   'btn.publish': 'Ieslēdz',
   'text.next': 'Turpināt',
   'input.phone.number.label': 'Telefona numurs',
   'input.state.label': 'Apgabals',
   'input.state.placeholder': 'Ievadiet apgabalu (pēc izvēles)',
   'input.addressDetails.label': 'Papildus informācija',
   'input.addressDetails.placeholder': 'Ievadiet papildu informāciju, piem. durvju kods, stāvs, nokļūšanas instrukcija',
   'placeholder.no.photos': 'Fotogrāfijas nav pieejama',
   'btn.options': 'Opcijas',
   'btn.list.profile': 'Ieslēdz',
   'btn.preview': 'Pārskats',
   'btn.next': 'Tālāk',
   'market.ob.pleaseWait.title': 'Lūdzu, uzgaidiet ...',
   'market.ob.location.step.title': 'Izvēlieties vietu',
   'market.ob.services.step.title': 'Izvēlieties pakalpojumu',
   'market.ob.employee.step.title': 'Izvēlieties datumu un darbiniekus',
   'market.ob.timeSlot.step.title': 'Izvēlieties datumu un laiku',
   'market.ob.personalInfo.step.title': 'Maksājums',
   'market.ob.appointmentInfo.step.title': 'Rezervācijas informācija',
   'market.ob.informationAboutYou.title': 'Informācija par jums',
   'market.ob.paymentMethod.title': 'Maksājuma metode',
   'market.ob.securePayment.title': 'Drošs maksājums',
   'market.ob.payWithCard.title': 'Maksāt ar karti',
   'market.ob.payOnSite.title': 'Maksāt uz vietas',
   'market.ob.payOnSite.firstDescription': 'Jums būs jāmaksā salonā pēc procedūras.',
   'market.ob.payOnSite.secondDescription':
      'Neliels lūgums no Mūsu puses. Ja Jūs kavējat, nevarat iersaties, vēlaties pārcelt rezvāciju vai atcelt to - lūdzu veiciet to savlaicīgi! Cienīsim viens otra laiku!',
   'market.ob.validThru.title': 'Derīguma termiņš (mēnesis/gads)',
   'market.ob.information.title': 'Informācij',
   'market.ob.additionalInformation.title': 'Papildu informācija',
   'market.ob.reschedulingPolicy.title': 'Atcelšanas/pārplānošanas politika',
   'market.ob.reschedulingPolicy.description': 'Izmaiņas tikšanās reizēs var veikt {0} pirms tikšanās.',
   'market.ob.fieldsAreRequired.title': '* Lauki ir obligāti',
   'market.ob.step.title': 'Solis {0} no 3',
   'market.ob.confirm.step.title': 'Skatīt un apstiprināt',
   'market.ob.noServices.placeholder': 'Izvēlieties pakalpojumu',
   'market.ob.input.firstName.label': '{0} Nosaukums',
   'market.ob.input.firstName.placeholder': 'piem. Linda',
   'market.ob.input.lastName.label': '{0} Uzvārds',
   'market.ob.input.lastName.placeholder': 'piem. Mākone',
   'market.ob.input.phone.label': '{0} Mobilā tālruņa numurs',
   'market.ob.input.phone.placeholder': '670 70 822',
   'market.ob.input.email.label': '{0} E-pasta adrese',
   'market.ob.input.email.placeholder': 'linda.makone@epasts.cc',
   'market.ob.input.description.label': 'Piezīmes',
   'market.ob.staff.assignedSpecialist.label': 'Darbinieks',
   'market.ob.staff.noPreference.label': 'Atlasīts nejauši',
   'market.ob.timeSlots.fullyBooked.title': 'Atvainojiet, šajā dienā mums nav pieejami laiki.',
   'market.ob.timeSlots.choseAnotherDay.title': 'Lūdzu, izvēlieties citu dienu.',
   'market.ob.timeSlots.closedSlot.title': 'Tuvākais pieejamais laiks ir',
   'market.ob.timeSlots.selectClosest.title': 'Izvēlieties nākamo dienu',
   'market.ob.timeSlots.note.title': 'Svarīga informācija',
   'market.ob.service.duration.title': 'min',
   'market.location.workingHours.title': 'Darba laiks',
   'market.location.monday.title': 'Pirmdiena',
   'market.location.tuesday.title': 'Otrdiena',
   'market.location.wednesday.title': 'Trešdiena',
   'market.location.thursday.title': 'Ceturtdiena',
   'market.location.friday.title': 'Piektdiena',
   'market.location.saturday.title': 'Sestdiena',
   'market.location.sunday.title': 'Svētdiena',
   'market.location.meetOurTeam.title': 'Mūsu komanda',
   'market.location.title': 'Sazinieties ar mums',
   'market.location.services.title': 'Iekārtas',
   'market.location.about.title': 'Par',
   'market.location.aboutUs.title': 'Par mums',
   'market.confirmation.appointmentCanceled.title': 'Šī rezervācija tika atcelta',
   'market.confirmation.appointmentConfirmed.title': 'Rezervācija apstiprināta',
   'market.confirmation.appointmentConfirmed.description':
      'Jūsu rezervācija ir apstiprināta, šeit ir sniegta sīkāka informācija:',
   'market.confirmation.appointmentDate.title': 'Rezervācijas datums',
   'market.confirmation.scheduledAppointment.title': 'Atcelšana nav iespējama tiešsaistē.',
   'market.confirmation.scheduledAppointment.description':
      'Ja nevarat ierasties uz plānoto Rezervāciju, lūdzu, pārplānojiet vai atceliet to.',
   'market.modal.cancelAppointment.title': 'Vai esat pārliecināts, ka vēlaties atcelt šo Rezervāciju ?',
   'market.modal.cancelledAppointment.title': 'Rezervācija tika atcelta',
   'market.language.changer.title': 'Valoda (Language)',
   'market.btn.bookNow': 'Rezervēt',
   'market.servicesAvailable.title': 'pakalpojumus',
   'market.poweredByPlandok.title': 'Plandok',
   'market.poweredByPlandok.description': 'Vēlaties līdzīgu tīmekļa vietni ? Apmeklējiet',
   'market.btn.readMore': 'Lasīt vairāk',
   'market.btn.showLess': 'Rādīt mazāk',
   'market.btn.reviews': 'Atsauksmes',
   'market.btn.viewMore': 'Skatīt vairāk',
   'market.btn.cancelAppointment': 'Atcelt tikšanos',
   'market.btn.cancel': 'Jā, atcelt',
   'market.wh.closed.title': 'Slēgts',
   'market.ob.success.message': 'Reģistrācija bija veiksmīga',
   'market.ob.cancelAppointment.success.message': 'Reģistrācija anulēta',
   'market.ob.cancelAppointment.error.message': 'Neizdevās, lūdzu, mēģiniet vēlreiz',
   'market.ob.cancelAppointment.confirm.title': 'Vai esat pārliecināts, ka vēlaties atcelt reģistrāciju?',
   'market.location.getDirections.title': 'Saņemt norādes',
   'market.notFound.title': 'Lapa nav atrasta',
   'market.notFound.btn.goBack': 'Atgriezties',
   'market.ob.quantitativeLimit.title': 'Kvantitatīvais ierobežojums',
   'market.ob.quantitativeLimit.description':
      'Atvainojiet, īpašnieks ir noteicis ierobežojumu rezervācijas pakalpojumu skaitam, ļaujot izvēlēties tikai {0} pakalpojum{0}',
   'market.ob.quantitativeLimit.three': 'trīs',
   'market.ob.quantitativeLimit.two': 'divi',
   'market.ob.quantitativeLimit.one': 'viens',
   'market.ob.quantitativeLimit.serviceSingular': 's',
   'market.ob.quantitativeLimit.servicesPlural': 'i',
   'validation.characters.minLength': 'Vērtība ir pārāk īss, būtu 3 vai vairāk simboli.',
   'not.found.btn.label': 'Atgriezies',
   'not.found.subTitle.label': 'Diemžēl, šī lapa nav.',
   'analytics.title': 'Analytics',
   'analytics.dashboard.tab': 'Pārskats',
   'analytics.reports.tab': 'Ziņojumi',
   'analytics.premium.title': 'Premium',
   'analytics.totalAppointments.title': 'Kopā rezervācijas',
   'analytics.cancellationReasons.title': 'Top iemesli atcelšanai',
   'analytics.occupancy.title': 'Nodarbinātība',
   'analytics.topServices.title': 'Top pakalpojumi',
   'analytics.clients.title': 'Klienti',
   'analytics.completed.title': 'Gatavs',
   'analytics.notCompleted.title': 'Nav izdarīts',
   'analytics.canceled.title': 'Atcelts',
   'analytics.noShowAppointments.title': 'Neieradās',
   'analytics.reasonName.title': 'Cēlonis',
   'analytics.reasonCount.title': 'Numurs',
   'analytics.lastPeriod.title': 'Iepriekšējais periods',
   'analytics.workingHours.title': 'Darba Laiks',
   'analytics.bookedHours.title': 'Rezervētās stundas',
   'analytics.blockedHours.title': 'Bloķētas stundas',
   'analytics.unbookedHours.title': 'Nenorezervētas stundas',
   'analytics.service.title': 'Pakalpojums',
   'analytics.thisMonth.title': 'Šis periods',
   'analytics.lastMonth.title': 'Iepriekšējais periods',
   'analytics.totalClients.title': 'Kopējais klientu',
   'analytics.averageVisits.title': 'Vid. klientu apmeklējumi',
   'analytics.newClients.title': 'Jauni klienti',
   'analytics.returningClients.title': 'klienti, kas atgriežas',
   'analytics.returnRate.title': 'Klientu atgriešanās skaits',
   'analytics.clientsByGender.title': 'Klienti pēc dzimuma',
   'analytics.clientsBySource.title': 'Klienti pēc avotu',
   'analytics.clientsByAge.title': 'Klienti vecums',
   'analytics.timeRange.custom': 'Ieradumi',
   'analytics.timeRange.today': 'Šodien',
   'analytics.timeRange.yesterday': 'Vakar',
   'analytics.timeRange.last1day': 'Pēdējās 1 dienas',
   'analytics.timeRange.last7days': 'Pēdējās 7 dienas',
   'analytics.timeRange.last30days': 'Pēdējās 30 dienas',
   'analytics.timeRange.last90days': 'Pēdējās 90 dienas',
   'analytics.timeRange.last3months': 'Pēdējie 3 mēneši.',
   'analytics.timeRange.lastMonth': 'Pagājušajā mēnesī',
   'analytics.timeRange.last6Months': 'Pēdējie 6 mēneši.',
   'analytics.timeRange.lastYear': 'Pēdējā 1 gads',
   'analytics.timeRange.last2year': 'Pēdējo 2 gadu laikā',
   'analytics.timeRange.thisWeek': 'Šonedēļ',
   'analytics.timeRange.thisMonth': 'Šis mēnesis',
   'analytics.timeRange.thisYear': 'Šogad',
   'analytics.timeRange.tomorrow': 'Rīt',
   'analytics.timeRange.next7days': 'Nākamās 7 dienas',
   'analytics.timeRange.next30days': 'Nākamās 30 dienas',
   'analytics.menu.dashboard.description': 'Izpētiet savus biznesa datus diagrammās un statistikā',
   'analytics.menu.reports.description': 'Izpētiet savus uzņēmuma datus pārskatos',
   'analytics.timeRange.allTime': 'Visu laiku',
   'analytics.timeRange.label': 'Laika diapazons',
   'analytics.services.lastPeriod.label': 'Iepriekšējais periods',
   'analytics.totalAppointments.appointment.label': 'Rezervācijas',
   'analytics.totalAppointments.onlineBook.label': 'Tiešsaistes rezervācijas',
   'analytics.gender.male': 'Vīrietis',
   'analytics.gender.female': 'Sieviete',
   'analytics.gender.unknown': 'Nezināms',
   'analytics.noData': 'Nav datu par izvēlēto periodu',
   'analytics.premium.info.title': 'Jauniniet uz premium versiju, lai skatītu Analytics ilgāk par 7 dienām.',
   'analytics.premium.upgradeButton.title': 'AKTIVIZĒT',
   'analytics.premium.skipButton.title': 'Izlaist',
   'reports.appointments.title': 'Rezervācijas',
   'reports.appointments.description':
      'Skatiet plānotos ieņēmumus no gaidāmajāmRezervācijām, izsekojiet atcelšanas iemeslus.',
   'reports.appointmentsList.title': 'Rezervāciju saraksts',
   'reports.appointmentsList.description': 'Parāda visas Rezervācijas ar saistīto informāciju.',
   'reports.appointmentsSummary.title': 'Rezervāciju kopsavilkums',
   'reports.appointmentsSummary.description': 'Parāda Rezervācijas pēc Darbiniekiem un Pakalpojumiem',
   'reports.appointmentsCancellations.title': 'Rezervācijas atcelšana',
   'reports.appointmentCancellations.title': 'Rezervāciju atcelšana',
   'reports.appointmentsCancellations.description':
      'Parāda visus atcelto Rezervāciju iemeslus un daudzumu jūsu izvēlētajā periodā.',
   'reports.summary.title': 'Kopsavilkums',
   'reports.clients.title': 'Klienti',
   'reports.clients.description':
      'Gūstiet ieskatu par to, kā klienti mijiedarbojas ar jūsu uzņēmumu un kuri ir jūsu lielākie tērētāji',
   'reports.clientsList.title': 'Klienti',
   'reports.clientsList.description': 'Klientu saraksts ar viņu datiem',
   'reports.exportCsv.title': 'Eksportēt CSV',
   'reports.appointmentsByStaff.title': 'Rezervācijas pēc Personāla',
   'reports.appointmentsByService.title': 'Rezervācijas pēc Pakalpojuma',
   'reports.clearFilters.button.title': 'Notīrīt filtrus',
   'reports.filterStaff.placeholder': 'Viss personāls',
   'reports.filterLocations.placeholder': 'Visas Filiāles',
   'reports.filters.title': 'Filtri',
   'reports.locations.mobile.title': 'Atrašanās vietas:',
   'reports.staff.mobile.title': 'Personāls:',
   'reports.filters.apply.title': 'Pieteikties',
   'reports.search.placeholder': 'Meklēt pēc atsauces vai klienta',
   'reports.subscription.description': '{0} plāns (tikai {0} darbinieki)',
   'reports.notifications.description': '{0} ziņojumi ({0})',
   'serviceGroup.addCategory.description': 'Kategorijas ir būtiskas, jo tās ļauj grupēt līdzīgus pakalpojumus.',
   'serviceGroup.addCategory.title': 'Pievienot kategoriju',
   'app.install.title': 'Instalēt Plandok',
   'app.install.description.title':
      'Instalēšana gandrīz neizmanto krātuvi un nodrošina ātru veidu, kā atgriezties šajā lietotnē',
   'app.install.button.title': 'Uzstādīt',
   'app.skip.button.title': 'Izlaist',
   'app.actionUndone.button.title': 'Atceltā darbība',
   'app.appointmentCancelled.button.title': 'Izdzēsta rezervācija',
   'app.undo.button.title': 'Atcelt',
   'app.showAllTimes.button.title': 'Rādīt visus laikus',
   'app.install.success.title': 'Lietotne veiksmīgi instalēta!',
   'rp.upgradeToPremiumLink.title': 'Jauniniet uz Premium',
   'rp.upgradeToPremium.title': 'lai izmantotu šo funkciju',
   'rp.repeatAppointment.title': 'Atkārtojiet',
   'rp.input.frequency.title': 'Biežums',
   'rp.input.endsAfter.title': 'Beigas',
   'rp.input.selectDate.title': 'Izvēlieties datumu',
   'rp.upgrade.title': 'Jauniniet savu plānu',
   'rp.upgrade.description.title':
      'Plandok premium versija nav aktivizēta. Jauniniet savu plānu, lai pilnībā piekļūtu visām Plandok piedāvātajām funkcijām.',
   'rp.upgrade.button.title': 'Jauniniet uz premium',
   'rp.repeating.title': 'Atkārtojas',
   'rp.repeats.title': 'Atkārtojas',
   'rp.repeatsUntil.title': 'līdz',
   'rp.repeatingTimes.title': '({0} reizes)',
   'rp.cancel.upcoming.title': 'Dzēst visas gaidāmās atkārtotās tikšanās',
   'rp.update.upcoming.title': 'Atjaunināt visas gaidāmās atkārtotās tikšanās',
   'rp.edit.appointment.title': 'Atjaunināt tikšanos',
   'rp.edit.appointment.description.title':
      'Jūs rediģējat atkārtotu tikšanos. Atlasiet, kuras tikšanās ir jāatjaunina:',
   'rp.edit.thisAppointment.title': 'Atjauniniet tikai šo tikšanos',
   'rp.edit.allAppointments.title': 'Atjauniniet visas gaidāmās tikšanās',
   'rp.edit.saveAppointment.title': 'Saglabāt',
   'modal.cancelSubscriptionError.title': 'Atcelt abonementa kļūda',
   'modal.cancelSubscriptionError.text.1': 'Bezmaksas plāns ir pieejams tikai ar maksimālo skaitu 5',
   'modal.cancelSubscriptionError.text.2': 'darbiniekiem',
   'modal.cancelSubscriptionError.text.3': 'un 1',
   'modal.cancelSubscriptionError.text.4':
      'Lai izmantotu bezmaksas versiju, noņemiet atrašanās vietas / darbiniekus, lai izpildītu prasības.',
   'modal.latestPaymentFailed.title': 'Pēdējais maksājums neizdevās',
   'modal.latestPaymentFailed.text.1':
      'Lai iegūtu piekļuvi visām funkcijām, lūdzu, samaksājiet vai izmantojiet bezmaksas versiju noņemiet',
   'modal.latestPaymentFailed.text.2':
      ', lai izpildītu prasības. Bezmaksas plāns ir pieejams tikai ar ne vairāk kā 5 darbiniekiem un 1 atrašanās vietu.',
   'modal.extraCostsForNewMember.title': 'Papildu izmaksas jaunam dalībniekam',
   'modal.extraCostsForNewMember.description':
      'Palielinoties darbinieku skaitam, jūsu ikmēneša abonementam būs papildu izmaksas.',
   'modal.premiumUpgradeRequired.title': 'Nepieciešams Premium jauninājums',
   'modal.premiumUpgradeRequired.description': 'Lai pievienotu papildu {0}, jūsu plāns ir jāatjaunina uz Premium',
   'modal.premiumUpgradeRequired.location': 'atrašanās vietu',
   'modal.premiumUpgradeRequired.staffMembers': 'darbiniekus',
   'modal.changeAccount.title': 'Mainīt Stripe kontu?',
   'modal.changeAccount.description':
      'Kamēr mainīsiet kontu, jūs nevarēsiet pieņemt maksājumus. Lai veiktu maiņu, būs nepieciešama aptuveni minūte.',
   'btn.agree': 'Piekrītu',
   'btn.decline': 'Noraidīt',
   'paymentMethod.paymentMethod.title': 'Maksājuma metode',
   'paymentMethod.currentPaymentMethod.title': 'Pašreizējā maksājuma metode',
   'paymentMethod.expirationDate': 'Derīguma termiņš {0}',
   'paymentMethod.otherPaymentMethods.title': 'Citas maksājumu metodes',
   'paymentMethod.addAnotherPaymentMethod.btn': 'Pievienot citu maksājumu metodi',
   'paymentMethod.addPaymentMethod.title': 'Pievienot maksājumu metodi',
   'paymentMethod.noPaymentMethodAdded.title': 'Nav pievienota neviena maksājumu metode',
   'paymentMethod.noPaymentMethodAdded.description': 'Maksājuma metode tiks pievienota, izvēloties Premium plānu',
   'paymentMethod.goToPlanPage.btn': 'Iet uz plāna lapu',
   'paymentMethod.add.btn': 'Pievienot',
   'paymentMethod.makeDefault.btn': 'Izvēlēties noklusējuma iestatījumu',
   'paymentMethod.cardHasBeenAdded.message': 'Karte ir pievienota',
   'paymentMethod.cardHasBeenAssign.message': 'Karte ir piešķirta',
   'page.marketing.overview.messages.description':
      'Nosūtiet pielāgotus mārketinga ziņojumus saviem klientiem, izmantojot SMS vai e-pastu ar dažiem vienkāršiem klikšķiem',
   'page.marketing.overview.btn.sendMessage': 'Nosūtīt ziņu',
   'page.marketing.overview.campaigns.title': 'Automātiskās nosūtīšanas kampaņas',
   'page.marketing.overview.campaigns.description':
      'Izveidojiet personalizētas kampaņas, pamatojoties uz tādiem notikumiem kā klientu dzimšanas dienas un citiem. \nSūtiet pa vairākiem kanāliem, lai piegādātu kampaņas pa e-pastu un īsziņām',
   'page.marketing.overview.btn.newCampaign': 'Jauna kampaņa',
   'page.marketing.massMessages.title': 'Iestatiet jaunu ziņojumu',
   'page.marketing.massMessages.messageLog': 'Ziņu žurnāls',
   'page.marketing.massMessages.sendOn': 'Sūtīt uz',
   'page.marketing.massMessages.sendTo': 'Sūtīt uz',
   'page.marketing.massMessages.channel': 'Kanāls',
   'page.marketing.campaigns.description':
      'Nosūtiet automatizētus mārketinga ziņojumus saviem klientiem, izmantojot SMS vai e-pastu ar dažiem vienkāršiem klikšķiem',
   'page.marketing.campaigns.birthdaySpecial.title': 'Dzimšanas dienas īpašais',
   'page.marketing.campaigns.winBackClients.title': 'Atgūstiet klientus',
   'page.marketing.campaigns.welcomeNewClients.title': 'Sagaidiet jaunus klientus',
   'page.marketing.campaigns.rewardRegularClients.title': 'Atalgot pastāvīgos klientus',
   'page.marketing.campaigns.birthdaySpecial.description':
      'Pārsteidziet klientus viņu īpašajā dienā, šī kampaņa automātiski tiek nosūtīta klientiem tuvu viņu dzimšanas dienai',
   'page.marketing.campaigns.winBackClients.description':
      'Mērķtiecīgi aiciniet klientus, kas vairs nav atgriezušies, atgriezties ar īpašu piedāvājumu. Šī kampaņa tiek nosūtīta klientiem, kuri neatgriezās pēc noteikta laika perioda',
   'page.marketing.campaigns.welcomeNewClients.description':
      'Pārvērsiet jaunpienācējus par pastāvīgajiem klientiem, mudinot pirmreizējos klientus atkārtoti veikt rezervāciju ar īpašu atlaidi. Šī kampaņa automātiski tiek nosūtīta klientiem vienu dienu pēc viņu pirmā pārdošanas darījuma',
   'page.marketing.campaigns.rewardRegularClients.description':
      'Pārsteidziet labākos klientus ar īpašu piedāvājumu, kas ir drošs veids, kā vēl vairāk iesaistīt savus labākos klientus. Šī kampaņa automātiski tiek nosūtīta klientiem, pamatojoties uz nesen veikto apmeklējumu aktivitāti',
   'page.marketing.campaigns.btn.active': 'Aktīvs',
   'page.marketing.campaigns.btn.inactive': 'Neaktīvs',
   'page.marketing.campaigns.btn.startCampaign': 'Sākt kampaņu',
   'page.marketing.campaigns.btn.editCampaign': 'Rediģēt kampaņu',
   'page.marketing.campaigns.btn.resetCampaign': 'Atiestatīt kampaņu',
   'modal.resetCampaign.description': 'Vai esat pārliecināts, ka vēlaties atiestatīt šo Kampaņu ?',
   'modal.resetCampaign.btn.yesReset': 'Jā, atiestatīt',
   'page.marketing.smsCreate.step.1': 'SMS iestatīšana. 1. solis no 4',
   'page.marketing.smsCreate.step.2': 'SMS iestatīšana. 2. solis no 4',
   'page.marketing.smsCreate.step.3': 'SMS iestatīšana. 3. solis no 4',
   'page.marketing.smsCreate.step.4': 'SMS iestatīšana. 4. solis no 4',
   'page.marketing.emailCreate.step.1': 'E-pasta iestatīšana. 1. solis no 4',
   'page.marketing.emailCreate.step.2': 'E-pasta iestatīšana. 2. solis no 4',
   'page.marketing.emailCreate.step.3': 'E-pasta iestatīšana. 3. solis no 4',
   'page.marketing.emailCreate.step.4': 'E-pasta iestatīšana. 4. solis no 4',
   'page.marketing.campaignCreate.step.1': 'Kampaņas iestatīšana. 1. no 3 soļiem',
   'page.marketing.campaignCreate.step.2': 'Kampaņas iestatīšana. 2. no 3 soļiem',
   'page.marketing.campaignCreate.step.3': 'Kampaņas iestatīšana. 3. no 3 soļiem',
   'page.marketing.smsCreate.step.1.title': 'Ziņojuma un sūtītāja ID',
   'page.marketing.smsCreate.step.1.defaultTitleForSms': 'Ievadiet SMS sūtītāja ID',
   'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Ievadiet savu ziņojumu',
   'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Ievadiet ziņojuma virsrakstu',
   'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Ievadiet savu ziņojumu',
   'page.marketing.emailCreate.step.1.title': 'E-pasta iestatījumi',
   'page.marketing.emailCreate.step.1.messageTitle': 'Ziņojuma virsraksts',
   'page.marketing.emailCreate.step.1.replyToEmail': 'Atbildēt uz e-pastu',
   'page.marketing.emailCreate.step.1.clientReplies': 'Klienta atbildes tiks nosūtītas uz šo e-pastu',
   'page.marketing.emailCreate.step.1.photo.title': 'Foto',
   'page.marketing.emailCreate.step.1.enablePhoto': 'Ieslēgt fotoattēlu',
   'page.marketing.emailCreate.step.1.changePhoto': 'Mainīt fotoattēlu',
   'page.marketing.emailCreate.step.1.button.title': 'Poga',
   'page.marketing.emailCreate.step.1.enableButton': 'Ieslēgt pogu',
   'page.marketing.emailCreate.step.1.buttonName': 'Pogas nosaukums',
   'page.marketing.emailCreate.step.1.buttonURL': 'Pogas URL',
   'page.marketing.emailCreate.step.1.socialMedia.title': 'Sociālie mediji',
   'page.marketing.emailCreate.step.1.enableSocialMedia': 'Ieslēgt sociālo mediju saites',
   'page.marketing.emailCreate.step.1.facebookPage': 'Facebook lapa',
   'page.marketing.emailCreate.step.1.instagramPage': 'Instagram lapa',
   'page.marketing.emailCreate.step.1.yourPage': 'Jūsu lapa',
   'page.marketing.smsCreate.yourMessage': 'Jūsu ziņa',
   'page.marketing.btn.sendMeTestMessage': 'Nosūtiet man testa ziņu',
   'page.marketing.smsCreate.step.1.freeTests': 'Šodien atlikuši {0} bezmaksas testi',
   'page.marketing.smsCreate.messagePreview': 'Ziņojuma priekšskatījums',
   'page.marketing.smsCreate.btn.clientSelection': 'Klienta izvēle',
   'page.marketing.audience.title': 'Auditorija',
   'page.marketing.smsCreate.step.2.description': 'Izvēlieties, kuri klienti saņems jūsu ziņojumu',
   'page.marketing.smsCreate.step.2.allClient.title': 'Visi klienti',
   'page.marketing.smsCreate.step.2.allClient.description': 'Nosūtīt ziņojumu visiem klientiem',
   'page.marketing.smsCreate.step.2.clientGroups.title': 'Klientu grupas',
   'page.marketing.smsCreate.step.2.clientGroups.description': 'Nosūtīt ziņu atlasītām klientu grupām',
   'page.marketing.smsCreate.step.2.totalClients': 'Kopā klienti',
   'page.marketing.smsCreate.step.2.totalPrice': 'Kopējā cena',
   'page.marketing.smsCreate.step.3.description': 'Izvēlieties laiku, kad vēlaties, lai tiktu nosūtīta ziņa',
   'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Izvēlieties pielāgotu datumu un laiku',
   'page.marketing.smsCreate.step.4.title': 'Maksājums par masveida ziņu',
   'page.marketing.smsCreate.step.4.description': 'Ierakstiet savu kredītkartes informāciju un samaksājiet',
   'page.marketing.smsCreate.step.4.paymentAmount': 'Maksājuma summa:',
   'btn.scheduleSend': 'Nosūtīšanas grafiks',
   'btn.previous': 'Iepriekšējais',
   'btn.payment': 'Maksājums',
   'btn.payAndFinish': 'Maksāt un pabeigt',
   'btn.backToDashboard': 'Atgriezties uz vadības paneli',
   'btn.saveAndClose': 'Saglabāt un aizvērt',
   'modal.editClientSelection.title': 'Rediģēt klienta atlasi',
   'modal.newClient.description': 'Klienti, kas pievienoti pēdējā:',
   'modal.recentClient.title': 'Pēdējais klients',
   'modal.recentClient.description': 'Klienti, kas apmeklēti pēdējā:',
   'modal.clientByAge.title': 'Klients pēc vecuma',
   'modal.clientsByGender.title': 'Klienti pēc dzimuma',
   'modal.paymentConfirmation.title': 'Maksājums veikts veiksmīgi !',
   'modal.paymentConfirmation.messageSend': 'Jūsu ziņojums tiks nosūtīts',
   'modal.previewMessage.title': 'Ziņojuma Priekšskatījums',
   'modal.previewSms.tab': 'SMS priekšskatījums',
   'modal.previewEmail.tab': 'E-pasta priekšskatījums',
   'modal.editServiceSelection.title': 'Rediģēt pakalpojuma izvēli',
   'modal.editServiceSelection.allServices': 'Visi pakalpojumi',
   'modal.campaignActivated.title': 'Aktivizēta kampaņa !',
   'modal.campaignActivated.description': 'Jūs varat jebkurā laikā apturēt vai rediģēt šo Kampaņu',
   'btn.all': 'Visi {0}',
   'btn.included': 'Iekļauts {0}',
   'btn.excluded': 'Izslēgts {0}',
   'checkbox.allClients': 'Visi klienti {0}',
   'scheduleSend.time': 'laiks',
   'scheduleSend.timeSetTo': 'Laiks iestatīts uz:',
   'scheduleSend.setTime': 'Iestatīt laiku',
   'campaignSetup.step.1.header.title': 'Kampaņas iestatījums',
   'campaignSetup.step.2.header.title': 'Rediģēt ziņojumu',
   'campaignSetup.step.3.header.title': 'Pārskatīt',
   'campaignSetup.channel.description': 'Izvēlieties, pa kādiem kanāliem tiks nosūtīta kampaņa',
   'campaignSetup.smsSetup.tab': 'SMS iestatīšana',
   'campaignSetup.emailSetup.tab': 'E-pasta iestatīšana',
   'campaignSetup.almostDone.title': 'Gandrīz pabeigts !',
   'campaignSetup.almostDone.description':
      'Jūsu viedā kampaņa ir gatava darbam, un, tiklīdz tā būs iespējota, tā pastāvīgi automātiski sūtīs ziņojumus klientiem. Šo kampaņu jebkurā laikā varat viegli mainīt vai apturēt',
   'campaignSetup.approximatePrice.header': 'Aptuvenā cena',
   'campaignSetup.approximatePrice.description': 'Nākamajām 30 dienām',
   'campaignSetup.approximatePrice.approx': 'Aptuveni',
   'campaign.birthdaySpecial.description': 'Šī kampaņa automātiski nosūta klientiem tuvu viņu dzimšanas dienai',
   'campaign.birthdaySpecial.sendToClient': 'Sūtīt klientam:',
   'campaign.birthdaySpecial.selectOption.1': 'Dzimšanas dienā',
   'campaign.birthdaySpecial.selectOption.2': 'Trīs dienas pirms dzimšanas dienas',
   'campaign.birthdaySpecial.selectOption.3': 'Nedēļu pirms dzimšanas dienas',
   'campaign.birthdaySpecial.selectOption.4': 'Divas nedēļas pirms dzimšanas dienas',
   'campaign.winBackClients.description':
      'Šī kampaņa tiek nosūtīta klientiem, kuri nav atgriezušies pēc noteikta laika perioda',
   'campaign.winBackClients.clientsWhoDidntReturn': 'Klienti, kuri nav atgriezušies pēdējā',
   'campaign.winBackClients.appliedTo': 'Piemēro',
   'campaign.winBackClients.all': 'visiem',
   'campaign.winBackClients.services': 'pakalpojumiem',
   'campaign.winBackClients.editSelection': 'Rediģēt atlasi',
   'campaign.welcomeNewClients.description':
      'Pārvērtiet jaunpienācējus par pastāvīgajiem klientiem, mudinot pirmreizējos klientus atkārtoti veikt rezervāciju ar īpašu atlaidi',
   'campaign.welcomeNewClients.smartCampaignNewClients':
      'Šī gudrā kampaņa automātiski tiek nosūtīta jaunajiem klientiem vienu dienu pēc viņu pirmās pārdošanas reizes',
   'campaign.rewardRegularClients.description':
      'Šī kampaņa automātiski tiek nosūtīta klientiem, pamatojoties uz nesen veikto apmeklējumu aktivitāti',
   'campaign.rewardRegularClients.clientsWithAtLeast': 'Klienti ar vismaz',
   'campaign.rewardRegularClients.appointmentsInThe': 'tikšanās reizēm',
   'campaign.rewardRegularClients.last': 'pēdējās',
   'campaign.rewardRegularClients.appointments': 'tikšanās',
   'campaign.rewardRegularClients.inTheLast': 'reizēm pēdējās',
   'campaign.rewardRegularClients.months': 'mēnešos',
   'subscriptionPaymentFailed.title':
      'Lūdzu, atjauniniet savu maksājuma metodi līdz {0}, pretējā gadījumā visas premium funkcijas tiks pārtrauktas, paldies.',
   'subscriptionCancelled.title':
      'Atvainojiet, bet jūsu Premium plāns ir atspējots nemaksāšanas dēļ. Ja vēlaties turpināt izmantot Premium funkcijas, lūdzu, aktivizējiet Premium plānu.',
   'notificationPaymentFailed.title':
      'Maksājums par ziņojumiem neizdevās. Lūdzu, veiciet maksājumu līdz {0}, pretējā gadījumā paziņojumi tiks atspējoti, paldies.',
   'notificationCancelled.title':
      'Atvainojiet, bet jūsu ziņojumi ir atspējoti nemaksāšanas dēļ. Ja vēlaties turpināt sūtīt ziņojumus, lūdzu, samaksājiet, paldies.',
   'btn.hideOptions': 'Slēpt opcijas',
   'btn.showOptions': 'Rādīt opcijas',
   'notification.googleDisconnected': 'Google kalendārs atvienots',
   'notification.googleConnected': 'Google kalendārs savienots',
   'notification.appleDisconnected': 'Apple kalendārs atvienots',
   'notification.appleConnected': 'Apple kalendārs savienots',
   'notification.microsoft365Disconnected': 'Microsoft365 kalendārs atvienots',
   'notification.microsoft365Connected': 'Microsoft365 kalendārs savienots',
   'notification.linkCopied': 'Saite kopēta',
   'calendarSync.title': 'Kalendāra sinhronizācija',
   'calendarSync.description':
      'Sinhronizējiet Plandok tikšanās ar savu iecienītāko kalendāru, lai bez piepūles organizētu un nekad nepalaistu garām svarīgus notikumus.',
   'googleCalendar.title': 'Google kalendārs',
   'appleCalendar.title': 'Apple kalendārs',
   'microsoft365Calendar.title': 'Microsoft365 kalendārs',
   'linkYourCalendar.title': 'Saviet savus kalendārus',
   'connect.btn': 'Savienojiet',
   'disconnect.rtn': 'Atvienojiet',
   'connected.label': 'Savienots',
   'modal.workingHours.standartMethod.btn': 'Standarta metode',
   'modal.workingHours.customMethod.btn': 'Pielāgotā metode',
   'modal.workingHours.workingDates.input': 'Darba datumi',
   'modal.workingHours.selectDates.placeholder': 'Izvēlētie datumi',
   'noInternetConnection.notification.title': 'Nav interneta savienojuma',
   'app.update.title': 'Atjaunināt',
   'app.update.description.title':
      'Mēs pievienojām jaunas funkcijas un novērsām dažas kļūdas, lai padarītu jūsu pieredzi ar Plandok pēc iespējas ērtāku.',
   'app.update.button.title': 'Atjaunināt',
   'app.notNow.button.title': 'Ne tagad',
   'app.updateAvailable.button.title': 'Pieejama jauna versija',
   'upgradePlan.success.message': 'Abonēšana ir veiksmīgi atjaunināta',
   'upgradePlan.error.message':
      'Abonementa atjaunināšanas kļūda, lūdzu, sazinieties ar atbalsta dienestu, ja no jūsu kartes tiek iekasēta maksa un abonements netiek atjaunināts',
   'sidebar.inventory': 'Inventārs',
   'products.tab': 'Produkti',
   'suppliers.tab': 'Piegādātāji',
   'stockOrders.tab': 'Krājumu pasūtījumi',
   'stockTracking.tab': 'Krājumu izsekošana',
   'input.productsSearch.placeholder': 'Meklēt pēc produkta nosaukuma vai svītrkoda',
   'product.create.btn.title': 'Pievienot jaunu produktu',
   'table.products.product': 'Produkts',
   'table.products.barcode': 'Svītrkods',
   'table.products.brand': 'Zīmols',
   'table.products.sale': 'Pārdošana',
   'table.products.saleOpt': 'Izpārdošanas opc.',
   'saleOptions.tooltip.title': 'Izpārdošanas opcijas',
   'saleOptions.tooltip.description': 'Jūs varat izvēlēties, vai kases laikā iespējot/izslēgt pārdošanu.',
   'seeMore.title': 'Skatīt vairāk',
   'option.products.downloadCSV': 'Lejupielādēt CSV',
   'option.products.downloadExcel': 'Lejupielādēt Excel',
   'option.products.manageBrands': 'Pārvaldīt zīmolus',
   'option.products.manageCategories': 'Pārvaldīt kategorijas',
   'option.products.manageStock': 'Pārvaldīt krājumus',
   'option.products.importProducts': 'Importēt produktus',
   'modal.filter.categories.title': 'Kategorijas',
   'modal.filter.brands.title': 'Zīmoli',
   'modal.filter.stock.title': 'Krājumi',
   'modal.filter.allCategories.selectItem': 'Visas kategorijas',
   'modal.filter.allBrands.selectItem': 'Visi zīmoli',
   'modal.filter.allSuppliers.selectItem': 'Visi piegādātāji',
   'modal.filter.allProducts.selectItem': 'Visi produkti',
   'input.suppliersSearch.placeholder': 'Meklēt pēc piegādātāja nosaukuma',
   'supplier.create.btn.title': 'Pievienot jaunu piegādātāju',
   'table.suppliers.supplierName': 'Piegādātāja nosaukums',
   'table.suppliers.phoneNumber': 'Tālruņa numurs',
   'table.suppliers.physicalAddress': 'Fiziskā adrese',
   'validation.notification.reminderTime': 'Nevar nosūtīt paziņojumus ar tādu pašu atgādinājuma laiku.',
   'validation.paymentType.switches': 'Jāatļauj vismaz viens maksājuma veids',
   'plan.premium.option.14':
      '0% Plandok komisijas maksa par tikšanās reizēm, kas rezervētas, izmantojot tiešsaistes rezervācijas, izmantojot kredītkarti.',
   'title.dragAndExtend.appointment.permission': 'Var pārvietot un pagarināt Rezervāciju',
   'button.lastVisit.addAnotherLastvisit': 'PIEVIENOT CITU PĒDĒJO APMEKLĒJUMU',
   'notifications.template.badge.validate': 'Apstiprināt',
   'notifications.template.badge.refused': 'Atteicās',
   'paid.by.card': 'Maksā ar karti',
   'costs.manual.paid.link': 'Vai arī varat manuāli apmaksāt paziņojumus ar karti, noklikšķinot uz šīs saites',
   'modal.remove.account.title': 'Noņemiet Stripe kontu',
   'modal.remove.account.description': 'Jūsu konts tiks noņemts, un jūs nevarēsit pieņemt tiešsaistes maksājumus. ',
   'ob.remove.account.option': 'Noņemiet Stripe kontu',
   'btn.remove.account': 'Noņemt',
   'calendar.tooltip.selected': 'Atlasīts',
   'calendar.tooltip.no.preference': 'Nav priekšroka',
   'ob.gtag.manager.title': 'Google tagu pārvaldnieks',
   'ob.analytics.gtag.description.1':
      'Google tagu pārvaldnieks ļaus jums izmērīt jūsu reklamēšanas IA, kā arī izsekot jūsu Flash, video un sociālo tīklu vietnēm un lietojumprogrammām.',
   'ob.gtag.manager.description.1':
      'Google tagu pārvaldnieks ir bezmaksas tagu pārvaldības sistēma, kas ļauj pārvaldīt un izvietot mārketinga tagus (koda fragmentus vai izsekošanas pikseļus) savā vietnē vai mobilajā lietotnē, nepārveidojot tieši kodu.',
   'ob.gtag.manager.description.2':
      'Būtībā GTM racionalizē mārketinga tagu pārvaldības procesu, atvieglojot vietnes veiktspējas izsekošanu, mārketinga kampaņu mērīšanu un lietotāju pieredzes optimizēšanu.',
   'text.gender.male': 'Vīrietis',
   'text.gender.female': 'Sieviete',
   'text.gender.unknown': 'Nezināms',
}

export default labels
