import { Button, Card, Field, Form, Text, useAppContext, validateRequired } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { message } from 'antd'
import { appointmentColorOptions, calendarTimeOffset, calendarTimeRangeOptions, viewTypeOptions } from 'constants/data'
import { StateCalendar } from 'pages/dashboard/CalendarPage/constants'
import React, { useEffect, useState } from 'react'

import { getWholeHourOptions } from '../../ModifyAppointmentPage/config/options'
import * as SC from '../styles'

export default function CalendarSettingsForm(props: any) {
   const [, { refreshAppContext }] = useAppContext()
   const [initialValues, setInitialValues] = useState<any>({})
   const onSuccess = () => {
      message.success(<IntlLabel label="notification.success.update" />)
      refreshAppContext()
      props.onSuccess()
   }

   useEffect(() => {
      if (props.initialValues) {
         const newInitialValues = {
            ...props.initialValues,
            customViewTime: {
               ...props.initialValues.customViewTime,
               endTime:
                  props.initialValues.customViewTime.endTime > 1380 ? 1380 : props.initialValues.customViewTime.endTime,
            },
         }

         setInitialValues(newInitialValues)
      }
   }, [props.initialValues])

   return (
      <Form
         onSubmit={props.onSubmit}
         className="w-100"
         initialValues={initialValues}
         onSuccess={onSuccess}
         validate={(values: any) => {
            if (values?.customViewTime?.startTime >= values?.customViewTime?.endTime) {
               return {
                  customViewTime: {
                     startTime: 'validation.startTime',
                     endTime: 'validation.endTime',
                  },
               }
            }
         }}>
         {({ submitting, formError, values }: any) => (
            <>
               <Card withShadow>
                  <Text label="calendar.settings.title" weight="semiBold" mb="mlarge" />
                  <Field.Select
                     name="appointmentColor"
                     label="input.appointmentColors.label"
                     options={appointmentColorOptions}
                     validate={validateRequired}
                     translate
                  />
                  <Field.Select
                     name="timeSlotInterval"
                     label="input.timeSlot.label"
                     options={calendarTimeOffset}
                     validate={validateRequired}
                     translate
                  />
                  <Field.Select
                     name="defaultView"
                     label="input.defaultViewType.label"
                     options={viewTypeOptions}
                     validate={validateRequired}
                     translate
                  />

                  {values.calendarViewTime === StateCalendar.CUSTOM_RANGE ? (
                     <Field.Row>
                        <Field.Select
                           name="calendarViewTime"
                           label="input.calendarTimeRange.label"
                           options={calendarTimeRangeOptions}
                           translate
                           md={12}
                           defaultValue={StateCalendar.FULL_CALENDAR}
                        />
                        <Field.Select
                           options={getWholeHourOptions(initialValues.timeFormat)}
                           md={6}
                           name="customViewTime.startTime"
                           defaultValue={0}
                           label="input.startTime.label"
                           xs={12}
                        />
                        <Field.Select
                           options={getWholeHourOptions(initialValues.timeFormat)}
                           md={6}
                           name="customViewTime.endTime"
                           defaultValue={1380}
                           label="input.endTime.label"
                           xs={12}
                        />
                     </Field.Row>
                  ) : (
                     <Field.Select
                        name="calendarViewTime"
                        label="input.calendarTimeRange.label"
                        options={calendarTimeRangeOptions}
                        translate
                        defaultValue={StateCalendar.FULL_CALENDAR}
                     />
                  )}
               </Card>
               <Form.Alert text={formError} />
               <SC.BtnContainer>
                  <Button loading={submitting} label="btn.save.changes" type="primary" block htmlType="submit" />
               </SC.BtnContainer>
            </>
         )}
      </Form>
   )
}
