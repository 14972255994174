import styled, { css } from 'styled-components'
import { mediaMdDown, mediaMobileDown } from '../../../../../helpers/styles'
//
// type CustomTextAreaProps = {
//   contentEditable: boolean;
// };

export const Characters = styled.div`
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: ${({ theme }) => (theme.displayCount ? 'space-between' : 'flex-end')};
   line-height: 19px;
`

export const Count = styled.div`
   display: flex;
   flex-direction: row;

   ${mediaMobileDown(css`
      flex-direction: column;
   `)}
`

export const SMSPricing = styled.div<any>`
   display: inline-block;
   margin-left: 30px;
   color: ${({ color, theme }) => color || theme.primaryColor};
   font-size: 16px;
   font-weight: 500;
   cursor: pointer;
   text-decoration: ${(props: any) => (props?.theme === 'send' ? 'none' : 'underline')};
   line-height: 19px;

   i {
      margin-right: 5px;
   }

   ${mediaMdDown(css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin: ${(props: any) => (props?.theme === 'send' ? '14px 0 0' : '0')};
   `)}
`

export const WhatsAppAdditionalInfo = styled.div`
   border: 1px solid #ee9500;
   border-radius: 5px;
   background: #fef7ea;
   padding: 8px 12px 8px 9px;
   display: flex;
   align-items: center;
   margin-bottom: 6px;
`

export const CustomTextArea = styled.div`
   border: 1px solid #adb6cc;
   border-radius: 20px;
   color: #213e76;
   margin-bottom: 8px;
   padding: 4px 11px;
   outline: none;
   transition: all 0.3s;
   overflow: auto;
   :empty:before {
      content: '${({ theme }) => `${theme.placeholder}`}';
      color: #adb6cc;
   }
   :hover {
      border: 1px solid #213e76;
   }
   :focus {
      border: 1px solid rgb(110, 223, 235);
   }
`

// TODO: Check if the absence of the following snippet doesn't harm the component ( token from the last line of the CustomArea style rules)
// ${({ contentEditable }: CustomTextAreaProps) => {
//   if (!contentEditable)
//     return `
//       cursor: not-allowed;
//       background-color: #F8F9FC;
//       :hover {
//         border: 1px solid #adb6cc;
//       }
//     `;
// }}
