import { gql } from '@apollo/client'

export const CANCEL_RECURRING_APPOINTMENT = gql`
   mutation CancelRecurringAppointment($input: App_CancelRecurringAppointmentInput!) {
      cancelRecurringAppointment(input: $input) {
         success
      }
   }
`

export const CREATE_RECURRING_APPOINTMENT = gql`
   mutation CreateRecurringAppointment($input: App_CreateRecurringAppointmentInput!) {
      createRecurringAppointment(input: $input) {
         success
      }
   }
`

export const UPDATE_RECURRING_APPOINTMENT = gql`
   mutation EditRecurringAppointment($input: App_EditRecurringAppointmentInput!) {
      editRecurringAppointment(input: $input) {
         success
      }
   }
`

export const CREATE_CLOSED_DATE = gql`
   mutation CreateClosedData($input: App_CreateClosedDateInput!) {
      createClosedDate(input: $input) {
         success
      }
   }
`

export const UPDATE_CLOSED_DATE = gql`
   mutation UpdateClosedData($input: App_UpdateClosedDateInput!) {
      updateClosedDate(input: $input) {
         success
      }
   }
`

export const DELETE_CLOSED_DATE = gql`
   mutation DeleteClosedData($input: App_DeleteClosedDateInput!) {
      deleteClosedDate(input: $input) {
         success
      }
   }
`

export const UPDATE_BOOKING_BUSINESS_DETAILS = gql`
   mutation UpdateBookingBusinessDetails($input: App_UpdateLocationBusinessDetailsInput!) {
      updateLocationBusinessDetails(input: $input) {
         success
      }
   }
`

export const UPDATE_BOOKING_WORKING_HOURS = gql`
   mutation UpdateBookingWorkingHours($input: App_UpdateLocationWorkingHoursInput!) {
      updateLocationWorkingHours(input: $input) {
         success
      }
   }
`

export const UPDATE_BOOKING_ADDRESS = gql`
   mutation UpdateLocationAddress($input: App_UpdateLocationAddressInput!) {
      updateLocationAddress(input: $input) {
         success
      }
   }
`

export const UPDATE_BOOKING_SETTINGS = gql`
   mutation UpdateOnlineBookingSettings($input: App_UpdateOnlineBookingSettingsInput!) {
      updateOnlineBookingSettings(input: $input) {
         success
      }
   }
`

export const UPLOAD_LOCATION_IMAGES = gql`
   mutation UploadLocationImages($input: App_GenerateLocationUploadsInput!) {
      generateLocationUploads(input: $input) {
         uploads {
            name
            url
            form {
               action
               method
               input {
                  key
                  xAmzCredential
                  xAmzDate
                  xAmzSignature
                  xAmzAlgorithm
                  policy
                  acl
               }
            }
         }
      }
   }
`

export const GENERATE_PHOTO_UPLOADS = gql`
   mutation GeneratePhotoUploads($input: App_GeneratePhotoUploadsInput!) {
      generatePhotoUploads(input: $input) {
         uploads {
            name
            url
            form {
               action
               method
               input {
                  key
                  xAmzCredential
                  xAmzDate
                  xAmzSignature
                  xAmzAlgorithm
                  policy
                  acl
               }
            }
         }
      }
   }
`

export const UPDATE_LOCATION_IMAGES = gql`
   mutation UpdateLocationImages($input: App_UpdateLocationPhotosInput!) {
      updateLocationPhotos(input: $input) {
         success
      }
   }
`

export const PUBLISH_LOCATION_BOOKING = gql`
   mutation PublishLocationBooking($input: App_PublishLocationInput!) {
      publishLocation(input: $input) {
         success
      }
   }
`

export const REQUEST_PASSWORD_RESEND = gql`
   mutation RequestPasswordResend($input: App_RequestPasswordResendInput!) {
      requestPasswordResend(input: $input) {
         success
      }
   }
`

export const CREATE_SMS_MARKETING_CAMPAIGN = gql`
   mutation createSmsMassMarketingCampaign($input: App_CreateSmsMassMarketingCampaignInput!) {
      createSmsMassMarketingCampaign(input: $input) {
         success
      }
   }
`

export const CREATE_EMAIL_MARKETING_CAMPAIGN = gql`
   mutation createEmailMassMarketingCampaign($input: App_CreateEmailMassMarketingCampaignInput!) {
      createEmailMassMarketingCampaign(input: $input) {
         success
      }
   }
`
