import { Button, CustomIcon, Field, Form, Text, useLocation } from '@plandok/core'
import { getLabel, ReactIntl } from '@plandok/i18n'
import * as api from 'api'
import React, { useContext } from 'react'

import { AnalyticsServices } from '..'
import * as SC from '../styles'

type AnalyticsProps = {
   altKey: string
   FIELD_PLACEHOLDER: string
   imgSrc: string
   description1: string
   description2: string
   fieldName: string
   fieldLabel: string
   fieldTooltip: string
   href: string
   mobile1: string
   mobile2: string
   mobile3: string
   mobile4: string
}

export default function Analytics({
   altKey,
   imgSrc,
   description1,
   description2,
   fieldName,
   fieldLabel,
   FIELD_PLACEHOLDER,
   fieldTooltip,
   mobile1,
   mobile2,
   href,
   mobile3,
   mobile4,
}: AnalyticsProps) {
   const { location, goBack } = useLocation()
   const { lang } = useContext(ReactIntl)

   const alt = getLabel(altKey, lang)

   return (
      <SC.BookingAnalyticsInfo>
         <div className="justify-align">
            <Text label={altKey} mb="none" weight="semiBold" size="mlarge" />
            <div className="d-flex" onClick={goBack}>
               <CustomIcon type="close" />
            </div>
         </div>
         <SC.AnalyticsImg src={imgSrc} alt={alt} />
         <Text label={description1} size="base" />
         <Text label={description2} size="base" />

         <Form
            onSubmit={(form: AnalyticsServices) => api.updateAnalyticService({ ...location.state, ...form })}
            successMessage="ob.analytics.successfullySaved.message"
            initialValues={location.state}>
            {({ submitting }: { submitting: boolean }) => (
               <>
                  <Field.Input
                     name={fieldName}
                     label={fieldLabel}
                     placeholder={FIELD_PLACEHOLDER}
                     tooltip={fieldTooltip}
                  />
                  <Button
                     type="primary"
                     label="btn.save"
                     htmlType="submit"
                     loading={submitting}
                     upperCase={false}
                     semiBold
                  />
               </>
            )}
         </Form>

         <div className="text-center">
            <Text label={mobile1} size="base" mb="none" />
            <div className="justify-center">
               <Text label={mobile2} size="base" mb="none" />
               <a href={href} target="_blank" rel="noopener noreferrer">
                  <Text label="ob.analytics.here" size="base" mb="none" weight="medium" />
               </a>
               <Text label={mobile3} size="base" mb="none" ml="xxsmall" />
            </div>
            <Text label={mobile4} size="base" mb="none" ml="xxsmall" />
         </div>
      </SC.BookingAnalyticsInfo>
   )
}
