import hotkeys, { HotkeysEvent } from 'hotkeys-js'
import { useCallback, useEffect, useState } from 'react'

type CallbackFn = (event: KeyboardEvent, handler: HotkeysEvent) => void

export function useHotkeys(keys: string, callback: CallbackFn, isWorking = true) {
   const [isActive, setActive] = useState(false)
   const memoizedCallback = useCallback(callback, [callback])

   useEffect(() => {
      if (isWorking && !isActive) {
         hotkeys(keys, memoizedCallback)
         setActive(true)
      } else if (!isWorking && isActive) {
         hotkeys.unbind(keys)
         setActive(false)
      }

      return () => {
         if (isActive) {
            hotkeys.unbind(keys)
         }
      }
   }, [isWorking])
}
