import { getIsMobile } from '@plandok/core'
import React from 'react'

import * as SC from '../../styles'

export default function ColumnHeader({ headerComponent: HeaderComponent, info, modeType }: any) {
   const isMobile = getIsMobile()
   return (
      <SC.ColumnHeader isMobile={isMobile}>
         <HeaderComponent info={info} modeType={modeType} />
      </SC.ColumnHeader>
   )
}
