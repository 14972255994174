import { useAppContext } from '@plandok/core'
import { format } from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'
import { WorkingHours } from 'pages/dashboard/CalendarPage/types'
import React, { useEffect, useState } from 'react'

import * as SC from '../styles'
import {
   getCurrentLineTopOffsetByViewType,
   getLeftOffset,
   isDisplayTimeIndicatorByViewType,
} from '../support/helpers/current-time'
import { ModeType } from '../support/types'

export interface ICurrentTimeIndicator {
   dataLength: number
   hourHeight: number
   timeFormat: string
   modeType: ModeType
   date: Date
   customViewType: string
   customViewTime: WorkingHours
   workingHoursStartTime: number
   workingHoursEndTime: number
}

const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const getNow = (timeZone = defaultTimezone) => convertToTimeZone(new Date(), { timeZone })

export default function CurrentTimeIndicator(props: ICurrentTimeIndicator) {
   const [context] = useAppContext()
   const [now, setNow] = useState(getNow(context?.timeZone))

   useEffect(() => {
      const interFunc = () => setNow(getNow(context?.timeZone))
      setInterval(interFunc, 60 * 1000)

      return () => clearInterval(interFunc as any)
   }, [])

   const isWeek = props.modeType === 'week'
   const twelveHourFormat = props.timeFormat === '12h'
   const leftOffset = getLeftOffset(isWeek)
   const top = getCurrentLineTopOffsetByViewType(
      now,
      props.hourHeight,
      props.customViewType,
      props.customViewTime.startTime,
      props.workingHoursStartTime,
      props.timeFormat,
   )

   const displayTimeIndicator = isDisplayTimeIndicatorByViewType(
      props.customViewTime.endTime,
      props.customViewTime.startTime,
      props.hourHeight,
      props.customViewType,
      top,
      props.workingHoursStartTime,
      props.workingHoursEndTime,
   )

   return (
      <>
         {displayTimeIndicator && (
            <SC.NowLine
               length={isWeek ? 1 : props.dataLength}
               top={top}
               leftOffset={leftOffset}
               id="currentTimeIndicator">
               {!isWeek ? (
                  <SC.NowTime>{format(now, twelveHourFormat ? 'hh:mm' : 'HH:mm')}</SC.NowTime>
               ) : (
                  <SC.NowIndicator />
               )}
            </SC.NowLine>
         )}
      </>
   )
}
