import { Spin } from 'antd'
import { onReschedule } from 'helpers/app/reschedule'
import React from 'react'

import TableBody from './components/TableBody'
import TableContainer from './components/TableContainer'
import TableHeader from './components/TableHeader'

interface DraggableTableProps {
   onPositionUpdate?: (id: string, nextIndex: number) => Promise<void> | void
   changeData?: any
}

function DraggableTable(props: DraggableTableProps & any) {
   return (
      <Spin spinning={props.isLoading}>
         <TableContainer>
            <TableHeader columns={props.columns} />
            <TableBody
               className="ant-table-body"
               columns={props.columns}
               data={props.data}
               useDragHandle
               onSortEnd={onReschedule({
                  data: props.data,
                  changeData: props.changeData,
                  onPositionUpdate: props.onPositionUpdate,
               })}
               onItemClick={props.onItemClick}
            />
         </TableContainer>
         <div className="ant-table-footer" />
      </Spin>
   )
}

export default DraggableTable
