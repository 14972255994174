import styled from 'styled-components'

export const StyledDefaultOption = styled.div`
   position: relative;
   display: block;
   padding: 5px 0;
   overflow: hidden;
   color: rgba(0, 0, 0, 0.65);
   font-weight: normal;
   white-space: nowrap;
   text-overflow: ellipsis;
   cursor: pointer;
   transition: background 0.3s ease;
   &:hover,
   &:focus {
      color: #14316d;
      background: #f8f9fc;
   }
`
