import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { CustomIcon, DatePicker, getIsMobile, IconButton } from '@plandok/core'
import cn from 'classnames'
import { DateFilterDisplay } from 'components'
import { addDays, format } from 'date-fns'
import { ICalendarActions, ICustomComponents } from 'epic-calendar'
import { getCalendarRangeStart } from 'helpers/date/calendar'
import { CalendarStateProps } from 'pages/dashboard/CalendarPage/useCalendarState'
import React, { useState } from 'react'

import CalendarMobileFilter from '../CalendarMobileFilter'
import * as SC from './styles'

interface CalendarMobileHeaderProps extends ICalendarActions, ICustomComponents {
   isWeekMode: boolean
   date: Date
   calendarState: CalendarStateProps
   isSelectSlotMode: boolean
   staffFromFilterParams?: string
   timeFormat: string
   changeDate: (d: Date) => void
   scrollToCurrentTime: () => void
}

type DatePickerRangeDate = string & Date

function CalendarMobileHeader({
   isWeekMode,
   date = new Date(),
   changeDate,
   scrollToCurrentTime,
   calendarState,
}: CalendarMobileHeaderProps) {
   const [visibleFilter, setVisibleFilter] = useState(false)
   const toggleFilter = () => setVisibleFilter(!visibleFilter)
   const onClose = () => setVisibleFilter(false)

   const rangeDate = getCalendarRangeStart(calendarState.filterState.date, isWeekMode)
   const setToday = () => {
      calendarState.changeFilter('date', new Date())
      scrollToCurrentTime()
   }

   const daysDelta = isWeekMode ? 7 : 1

   return (
      <SC.Container>
         <SC.CalendarIcon onClick={setToday}>
            <CustomIcon type="calendar" />
            <div data-today-date>{format(new Date(), 'dd')}</div>
         </SC.CalendarIcon>

         <SC.DateFilterContainer className="date-picker-right-position filter-datepicker-container">
            <SC.LeftIconButton icon={<LeftOutlined />} ghost onClick={() => changeDate(addDays(date, -daysDelta))} />
            <DatePicker
               value={rangeDate as DatePickerRangeDate}
               customInput={
                  <SC.DatePickerButton ghost>
                     <DateFilterDisplay isWeekMode={isWeekMode} date={date} />
                  </SC.DatePickerButton>
               }
               onChange={(selectedDate: any) => calendarState.changeFilter('date', selectedDate)}
               calendarClassName={cn({ 'week-mode-react-datepicker': isWeekMode })}
               className="filter-datepicker"
               withPortal={getIsMobile()}
            />
            <SC.RightIconButton icon={<RightOutlined />} ghost onClick={() => changeDate(addDays(date, daysDelta))} />
         </SC.DateFilterContainer>

         <div tabIndex={0} role="button" onClick={toggleFilter}>
            <IconButton icon="tools" />
         </div>
         <CalendarMobileFilter visible={visibleFilter} onClose={onClose} calendarState={calendarState} />
      </SC.Container>
   )
}

export default CalendarMobileHeader
