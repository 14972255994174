import styled from 'styled-components'

export const MessageContainer = styled.div<{ isMobile?: boolean }>`
   display: flex;
   align-items: center;
   cursor: pointer;
   padding-top: 10px;
   justify-content: ${props => (props.isMobile ? 'space-between' : 'unset')};
   width: ${props => (props.isMobile ? '100%' : 'unset')};

   &:hover {
      opacity: 0.8;
   }
`
