import { CustomIcon, Text, withModal } from '@plandok/core'
import { SUGGEST_FEATURE_URL, VIEW_UPDATES_URL } from 'constants/external-links'
import { openCrisp } from 'helpers/app/crisp'
import flow from 'lodash/flow'
import React from 'react'

import * as SC from '../styles'

type HelpModalProps = {
   onClose: () => void
}

function HelpModal({ onClose }: HelpModalProps) {
   return (
      <>
         <SC.LiveChatContainer onClick={flow(onClose, openCrisp)}>
            <div>
               <CustomIcon type="liveChat" />
               <span>
                  <Text label="modal.help.liveChat.title" size="medium" weight="semiBold" />
                  <Text mb="none" label="modal.help.liveChat.description" size="small" />
               </span>
            </div>
            <CustomIcon type="caretRight" />
         </SC.LiveChatContainer>

         <SC.LinkContainer href={SUGGEST_FEATURE_URL} target="_blank" rel="noopener noreferrer" spaceBellow>
            <div>
               <div>
                  <CustomIcon type="suggestFeature" />
               </div>
               <span>
                  <Text label="modal.help.suggestFeature.title" size="medium" weight="semiBold" />
                  <Text mb="none" label="modal.help.suggestFeature.description" size="small" />
               </span>
            </div>
            <div>
               <CustomIcon type="newTab" />
            </div>
         </SC.LinkContainer>

         <SC.LinkContainer href={VIEW_UPDATES_URL} target="_blank" rel="noopener noreferrer">
            <div>
               <CustomIcon type="viewUpdates" />
               <span>
                  <Text label="modal.help.viewUpdates.title" size="medium" weight="semiBold" />
                  <Text mb="none" label="modal.help.viewUpdates.description" size="small" />
               </span>
            </div>
            <CustomIcon type="newTab" />
         </SC.LinkContainer>
      </>
   )
}

export default withModal('modal.help.title', { contentClassName: 'help-modal' })(HelpModal)
