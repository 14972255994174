import { CustomIcon, useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Col, Row } from 'antd'
import React from 'react'

import * as SC from './styles'

export default function SetupLayoutFormContainer({ children }: any) {
   const { goBack } = useLocation()

   return (
      <>
         <Row className="hidden-md">
            <Col>
               <SC.ButtonBack onClick={goBack}>
                  <CustomIcon type="arrowBack" />
                  <span>
                     <IntlLabel label="btn.back" />
                  </span>
               </SC.ButtonBack>
            </Col>
         </Row>
         <Row justify="space-between" className="w-100">
            <Col md={16} span={24}>
               {children}
            </Col>
            <Col md={7} span={24} className="hidden-md">
               <SC.Container>
                  <h1>
                     <IntlLabel label="title.messagingSettings.label" />
                  </h1>
                  <div>
                     <IntlLabel label="title.messagingSettings.description" />
                     <br />
                     <br />
                     <IntlLabel label="client.first.name" />
                     <br />
                     <IntlLabel label="client.last.name" />
                     <br />
                     <IntlLabel label="staff.first.name" />
                     <br />
                     <IntlLabel label="staff.last.name" />
                     <br />
                     <br />
                     <IntlLabel label="booking.date.time" />
                     <br />
                     <IntlLabel label="booking.date" />
                     <br />
                     <IntlLabel label="booking.time" />
                     <br />
                     <br />
                     <IntlLabel label="service.name" />
                     <br />
                     <br />
                     <IntlLabel label="business.name" />
                     <br />
                     <IntlLabel label="business.phone" />
                     <br />
                     <IntlLabel label="business.address" />
                     <br />
                     <IntlLabel label="business.email" />
                     <br />
                     <br />
                     <IntlLabel label="location.name" />
                     <br />
                     <IntlLabel label="location.phone" />
                     <br />
                     <IntlLabel label="location.address" />
                     <br />
                     <IntlLabel label="location.email" />
                     <br />
                  </div>
               </SC.Container>
            </Col>
         </Row>
      </>
   )
}
