import Icon from '@ant-design/icons'
import { Tooltip } from 'antd'
import { IntlLabel, LabelKey } from '@plandok/i18n'
import * as SC from './styles'
import FormTooltipList from './components/FormTooltipList'
import React from 'react'

interface FormTooltipProps {
   tooltip?: LabelKey | { key: LabelKey; style?: any }[]
}

export default function FormTooltip({ tooltip }: FormTooltipProps) {
   if (!tooltip) {
      return null
   }
   // @ts-ignore
   const title: any = Array.isArray(tooltip) ? <FormTooltipList tooltip={tooltip} /> : <IntlLabel label={tooltip} />
   return (
      <SC.FormTooltipContainer>
         <Tooltip placement="rightTop" title={title}>
            <Icon type="info-circle" />
         </Tooltip>
      </SC.FormTooltipContainer>
   )
}
