import { ModifyMassLayout, NotReleased } from 'components'
import { RoutePath } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import BirthdayCampaignForm from './component/BirthdayCampaignForm'

export const MassageFormWithApi = withFormApi({
   backPath: RoutePath.MARKETING,
   successMessage: 'Message',
})(BirthdayCampaignForm)

export default function BirthdayCampaignSetting() {
   return (
      <NotReleased>
         <ModifyMassLayout title="Campaign Setting" step="Campaign setup. Step 1 of 3">
            <MassageFormWithApi />
         </ModifyMassLayout>
      </NotReleased>
   )
}
