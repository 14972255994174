import { IntlLabel, LabelKey } from '@plandok/i18n'
import { MobileBackButton } from 'components'
import React from 'react'

export default function SetupLayoutMobileHeader(props: { label: LabelKey }) {
   return (
      <>
         <MobileBackButton />
         <div>
            <IntlLabel label={props.label} />
         </div>
         <div />
      </>
   )
}
