import { extractOptionsByMapping } from '@plandok/core'
import * as api from 'api'
import { formatDuration } from 'helpers/date'
import groupBy from 'lodash/groupBy'

export const extractServiceGroupOptions = (data: any, supportInfo: any) =>
   Object.entries(groupBy(data?.services || [], 'serviceGroup.name'))
      .map(e => {
         const [k, v] = e || []
         return {
            label: k,
            options:
               v?.map?.((service: any) => ({
                  value: service,
                  code: service?.id,
                  name: `${service.name} (${formatDuration(service.duration)}, ${supportInfo?.sign || ''}${service.price})`,
               })) ?? [],
         }
      })
      .filter(e => e?.label)

export const optionsMapping = {
   REF_SOURCES: { code: 'id' },
   CATEGORY: { code: 'id' },
   LOCATION: { code: 'id', name: 'name' },
   EMPLOYEE: { code: 'id', name: ['firstName', 'lastName'] },
   SERVICE: { code: 'id', name: 'name', serviceGroup: 'serviceGroup' },
   CANCEL_REASON: { code: 'id', name: 'name' },
   RESOURCES: { code: 'id', name: 'name' },
}

export const getAsyncSelectConfig = (type: any) => {
   return (
      {
         refSources: {
            fetchData: api.fetchRefSourcesOptions,
            mapping: optionsMapping.REF_SOURCES,
         },
         category: {
            fetchData: api.fetchCategoryOptions,
            mapping: optionsMapping.CATEGORY,
         },
         location: {
            fetchData: api.fetchLocationsOptions,
            mapping: optionsMapping.LOCATION,
         },
         employee: {
            fetchData: api.fetchEmployeesOptions,
            mapping: optionsMapping.EMPLOYEE,
         },
         services: {
            fetchData: api.fetchServiceOptions,
            mapping: optionsMapping.SERVICE,
         },
         cancelReason: {
            fetchData: api.fetchCancelReasons,
            mapping: optionsMapping.CANCEL_REASON,
         },
         resources: {
            fetchData: api.fetchResourcesOptions,
            mapping: optionsMapping.RESOURCES,
         },
         serviceGroups: {
            fetchData: api.fetchCategoriesOptions,
            isGroup: true,
            groupValueExtractor: extractServiceGroupOptions,
         },
      } as any
   )[type]
}

export const extractOptionsFromList = (options: any[], type: keyof typeof optionsMapping) => {
   const mapping: any = optionsMapping[type]
   if (!mapping) {
      return []
   }

   return extractOptionsByMapping(options, mapping)
}
