import { useLocation } from '@plandok/core'
import { Button, Result } from 'antd'
import { NoInternetConnection } from 'components'
import { RoutePath } from 'constants/routes'
import React from 'react'

export default function Page404() {
   const { navigate } = useLocation()

   return (
      <NoInternetConnection>
         <div style={{ width: '100%', height: '100vh', display: 'flex' }}>
            <Result
               status="404"
               title="404"
               subTitle="Sorry, the page you visited does not exist."
               extra={
                  <Button type="primary" onClick={() => navigate(RoutePath.LOGIN)}>
                     Back Home
                  </Button>
               }
               style={{
                  margin: 'auto',
               }}
            />
         </div>
      </NoInternetConnection>
   )
}
