import { ModifyMassLayout, NotReleased } from 'components'
import { RoutePath } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import WinBackClientForm from './component/WinBackClientForm'

export const MassageFormWithApi = withFormApi({
   backPath: RoutePath.MARKETING,
   successMessage: 'Message',
})(WinBackClientForm)

export default function WinBackClientsSetting() {
   return (
      <NotReleased>
         <ModifyMassLayout title="campaignSetup.step.1.header.title" step="page.marketing.campaignCreate.step.1">
            <MassageFormWithApi />
         </ModifyMassLayout>
      </NotReleased>
   )
}
