import { Button, Field, Form, FormTabInput, withModal } from '@plandok/core'
import { useCurrencySign } from '@plandok/i18n'
import { Col } from 'antd'
import React from 'react'
import { Field as FinalField } from 'react-final-form'

import * as SC from '../styles'

function CreateDiscountModal(props: any) {
   const sign = useCurrencySign()

   return (
      <Form onSubmit={console.info}>
         {({ values }: any) => (
            <>
               <Field.Input name="name" label="input.discountName.label" placeholder="input.discountName.placeholder" />
               <Field.Row>
                  {!values.type || values.type === 'percentage' ? (
                     <Field.Input md={9} prefix="%" name="value" label="input.discountValue.label" />
                  ) : (
                     <Field.Input md={9} suffix={sign} name="value" label="input.discountValue.label" />
                  )}

                  <Col span={24} md={14}>
                     <FinalField name="type" component={FormTabInput} />
                  </Col>
               </Field.Row>
               <SC.ModalFooter>
                  {props.isEdit && <Button label="btn.delete" block danger />}
                  <Button label="btn.cancel" block />
                  <Button label="btn.save" block type="primary" />
               </SC.ModalFooter>
            </>
         )}
      </Form>
   )
}

export default withModal('modal.newDiscount.title', { editLabel: 'modal.editDiscount.title', middle: true })(
   CreateDiscountModal,
)
