import { Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Divider } from 'antd'
import React from 'react'

import { App_TopCancellationReason } from '../../../../../../graphql/generatedTypes'
import { NoData } from '../NoData/NoData'
import * as SC from './styles'

type CancellationReasonsProps = {
   cancellationReasons: App_TopCancellationReason[]
}

export const CancellationReasons = ({ cancellationReasons }: CancellationReasonsProps) => (
   <SC.CancellationReasons>
      <Text size="mlarge" weight="bold" colorType="base" mb="xsmall">
         <IntlLabel label="analytics.cancellationReasons.title" />
      </Text>
      <div className="justify-between">
         <Text size="small" weight="semiBold" colorType="base" mb="small">
            <IntlLabel label="analytics.reasonName.title" />
         </Text>
         <Text size="small" weight="semiBold" colorType="base" mb="small">
            <IntlLabel label="analytics.reasonCount.title" />
         </Text>
      </div>
      <SC.Divider as={Divider} />

      {!!cancellationReasons?.length &&
         cancellationReasons?.map(cancellationReason => (
            <SC.TotalStatus key={cancellationReason.id}>
               <SC.TextColumn>
                  <Text size="base" weight="normal" colorType="base" mb="small">
                     {cancellationReason.name}
                  </Text>
               </SC.TextColumn>
               <SC.NumbersColumn>
                  <SC.Inliner>
                     <Text size="base" weight="semiBold" colorType="base" mb="small">
                        {cancellationReason.appointmentCount}
                     </Text>
                     <Text size="base" weight="normal" colorType="base" mb="small" ml="xxsmall">
                        ({cancellationReason.percent}%)
                     </Text>
                  </SC.Inliner>
               </SC.NumbersColumn>
            </SC.TotalStatus>
         ))}
      {!cancellationReasons?.length && <NoData />}
   </SC.CancellationReasons>
)

export default CancellationReasons
