import { PrivateRoute } from 'components'
import ApplicationLayout from 'components/app/AppLayout/ApplicationLayout'
import { RoutePath } from 'constants/routes'
import AnalyticsPage from 'pages/dashboard/AnalyticsPage'
import MobileAnalyticsMenuPage from 'pages/dashboard/AnalyticsPage/MobileAnalyticsMenuPage'
import AppointmentDetailsPage from 'pages/dashboard/AppointmentDetailsPage'
import FacebookPixel from 'pages/dashboard/BookingPage/BookingAnalytics/FacebookPixel'
import GoogleAnalytics from 'pages/dashboard/BookingPage/BookingAnalytics/GoogleAnalytics'
import GtagManager from 'pages/dashboard/BookingPage/BookingAnalytics/GtagManager'
import BookingsPage from 'pages/dashboard/BookingPage/BookingPage'
import MobileBookingMenuPage from 'pages/dashboard/BookingPage/MobileBookingMenuPage'
import CalendarPage from 'pages/dashboard/CalendarPage'
import CalendarSettingsPage from 'pages/dashboard/CalendarSettingsPage'
import ClientDetailsPage from 'pages/dashboard/ClientDetailsPage'
import ClientsPage from 'pages/dashboard/ClientsPage'
import CompanyDetailsPage from 'pages/dashboard/CompanyDetailsPage'
import CreateBookingFlow from 'pages/dashboard/CreateBookingFlow'
import ModifyEmailMessagePage from 'pages/dashboard/EmailSetup/ModifyEmailMessagePage'
import HelpPage from 'pages/dashboard/HelpPage'
import BirthdayCampaignSetting from 'pages/dashboard/MarketingCampaignsPages/CampaignSettings/BirthdayCampaignSetting'
import RewardRegularClientsSetting from 'pages/dashboard/MarketingCampaignsPages/CampaignSettings/RewardRegularClientsSetting'
import WelcomeNewClientSetting from 'pages/dashboard/MarketingCampaignsPages/CampaignSettings/WelcomeNewClientSetting'
import WinBackClientsSetting from 'pages/dashboard/MarketingCampaignsPages/CampaignSettings/WinBackClientsSetting'
import CampaignSetup from 'pages/dashboard/MarketingCampaignsPages/CampaignSetup'
import StartCampaignPage from 'pages/dashboard/MarketingCampaignsPages/StartCampaignPage'
import MarketingPage from 'pages/dashboard/MarketingPage/MarketingPage'
import MessagesPage from 'pages/dashboard/MessagesPage'
import MobileMessagesMenuPage from 'pages/dashboard/MessagesPage/MobileMessagesMenuPage'
import ModifyAppointmentPage from 'pages/dashboard/ModifyAppointmentPage'
import ModifyClientPage from 'pages/dashboard/ModifyClientPage'
import ModifyServicePage from 'pages/dashboard/ModifyServicePage'
import ModifyStaffPage from 'pages/dashboard/ModifyStaffPage'
import PersonalSettingsPage from 'pages/dashboard/PersonalSettingsPage'
import ReportsAppointmentCancellationsPage from 'pages/dashboard/ReportsAppointmentCancellationsPage'
import ReportsAppointmentListPage from 'pages/dashboard/ReportsAppointmentListPage'
import ReportsAppointmentsSummaryPage from 'pages/dashboard/ReportsAppointmentsSummaryPage'
import ReportsClientsPage from 'pages/dashboard/ReportsClientsPage'
import SearchPage from 'pages/dashboard/SearchPage'
import ServicesPage from 'pages/dashboard/ServicesPage'
import SettingsPage from 'pages/dashboard/SettingsPage'
import ModifyClientSelectionPage from 'pages/dashboard/SMSSetup/ModifyClientSelectionPage'
import ModifyMessagePage from 'pages/dashboard/SMSSetup/ModifyMessagePage'
import ModifyPaymentDetailsPage from 'pages/dashboard/SMSSetup/ModifyPaymentDetailsPage'
import ModifyScheduleSendCustomTimePage from 'pages/dashboard/SMSSetup/ModifyScheduleSendCustomTimePage'
import ModifyScheduleSendPage from 'pages/dashboard/SMSSetup/ModifyScheduleSendPage'
import StaffPage from 'pages/dashboard/StaffPage'
import MobileStaffPage from 'pages/dashboard/StaffPage/MobileStaffPage'
import BillingPage from 'pages/plans/BillingPage'
import MobileBillingMenuPage from 'pages/plans/BillingPage/MobileBillingMenuPage'
import NotificationChargePage from 'pages/plans/NotificationChargePage'
import UpdateSubscriptionPage from 'pages/plans/UpdateSubscriptionPage'
import SetupPage from 'pages/setup/SetupPage'
import CancellationReasonsPage from 'pages/setup-list/CancellationReasonsPage'
import DiscountsPage from 'pages/setup-list/DiscountsPage'
import LocationsPage from 'pages/setup-list/LocationsPage'
import ReferralSourcesPage from 'pages/setup-list/ReferralSourcesPage'
import ResourcesPage from 'pages/setup-list/ResourcesPage'
import MobileMenuPage from 'pages/support/MobileMenuPage'
import React from 'react'
import { Navigate } from 'react-router-dom'

export const dashboardRoute = (isAuthenticated: boolean, isLoading: boolean) => {
   return [
      {
         path: '/',
         element: <ApplicationLayout />,
         children: [
            {
               path: '/',
               element: isAuthenticated ? <Navigate to={RoutePath.CALENDAR} /> : <Navigate to={RoutePath.LOGIN} />,
            },
            {
               path: RoutePath.CALENDAR,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={CalendarPage} />
               ),
            },
            {
               path: RoutePath.CALENDAR,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={CalendarPage} />
               ),
            },
            {
               path: RoutePath.CLIENTS,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ClientsPage} />
               ),
            },
            {
               path: RoutePath.SERVICES,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ServicesPage} />
               ),
            },
            {
               path: RoutePath.SETTINGS,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={SettingsPage} />
               ),
            },
            {
               path: RoutePath.APPOINTMENT_CREATE,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ModifyAppointmentPage}
                  />
               ),
            },
            {
               path: `${RoutePath.APPOINTMENT_UPDATE}/:id`,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ModifyAppointmentPage}
                  />
               ),
            },
            {
               path: `${RoutePath.APPOINTMENT_DETAILS}/:id`,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={AppointmentDetailsPage}
                  />
               ),
            },
            {
               path: RoutePath.CLIENT_CREATE,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ModifyClientPage} />
               ),
            },
            {
               path: `${RoutePath.CLIENT_EDIT}/:id`,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ModifyClientPage} />
               ),
            },
            {
               path: `${RoutePath.CLIENT_DETAILS}/:id`,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ClientDetailsPage} />
               ),
            },
            {
               path: RoutePath.SERVICE_CREATE,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ModifyServicePage} />
               ),
            },
            {
               path: `${RoutePath.SERVICE_EDIT}/:id`,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ModifyServicePage} />
               ),
            },
            {
               path: RoutePath.COMPANY_DETAILS,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={CompanyDetailsPage}
                  />
               ),
            },
            {
               path: RoutePath.CALENDAR_SETTINGS,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={CalendarSettingsPage}
                  />
               ),
            },
            {
               path: RoutePath.LOCATIONS,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={LocationsPage} />
               ),
            },
            {
               path: RoutePath.RESOURCES,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ResourcesPage} />
               ),
            },
            {
               path: RoutePath.DISCOUNTS,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={DiscountsPage} />
               ),
            },
            {
               path: RoutePath.REFERRAL_SOURCES,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ReferralSourcesPage}
                  />
               ),
            },
            {
               path: RoutePath.CANCEL_REASONS,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={CancellationReasonsPage}
                  />
               ),
            },
            {
               path: RoutePath.SETUP_NOTIFICATIONS,
               element: <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={SetupPage} />,
            },
            {
               path: RoutePath.STAFF,
               element: <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={StaffPage} />,
            },
            {
               path: RoutePath.STAFF_SELECT,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={MobileStaffPage} />
               ),
            },
            {
               path: RoutePath.STAFF_CREATE,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ModifyStaffPage} />
               ),
            },
            {
               path: `${RoutePath.STAFF_UPDATE}/:id`,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ModifyStaffPage} />
               ),
            },
            {
               path: RoutePath.CLIENT_MESSAGES,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={MessagesPage} />
               ),
            },
            {
               path: RoutePath.PERSONAL_SETTINGS,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={PersonalSettingsPage}
                  />
               ),
            },
            {
               path: RoutePath.SEARCH,
               element: <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={SearchPage} />,
            },
            {
               path: `${RoutePath.BOOKING_CREATE}/:locationId`,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={CreateBookingFlow} />
               ),
            },
            {
               path: RoutePath.BILLING,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={BillingPage} />
               ),
            },
            {
               path: RoutePath.BILLING_SELECT,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={MobileBillingMenuPage}
                  />
               ),
            },
            {
               path: RoutePath.MORE,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={MobileMenuPage} />
               ),
            },
            {
               path: RoutePath.BOOKING,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={BookingsPage} />
               ),
            },
            {
               path: RoutePath.BOOKING_MOBILE,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={MobileBookingMenuPage}
                  />
               ),
            },
            {
               path: RoutePath.GOOGLE_ANALYTICS,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={GoogleAnalytics} />
               ),
            },
            {
               path: RoutePath.FACEBOOK_PIXEL,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={FacebookPixel} />
               ),
            },
            {
               path: RoutePath.GTAG_MANAGER,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={GtagManager} />
               ),
            },
            {
               path: RoutePath.ANALYTICS,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={AnalyticsPage} />
               ),
            },
            {
               path: RoutePath.ANALYTICS_MOBILE,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={MobileAnalyticsMenuPage}
                  />
               ),
            },
            {
               path: RoutePath.REPORTS_APPOINTMENT_LIST,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ReportsAppointmentListPage}
                  />
               ),
            },
            {
               path: RoutePath.REPORTS_APPOINTMENT_CANCELLATIONS,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ReportsAppointmentCancellationsPage}
                  />
               ),
            },
            {
               path: RoutePath.REPORTS_APPOINTMENT_SUMMARY,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ReportsAppointmentsSummaryPage}
                  />
               ),
            },
            {
               path: RoutePath.REPORTS_CLIENTS,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ReportsClientsPage}
                  />
               ),
            },
            {
               path: RoutePath.MARKETING,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={MarketingPage} />
               ),
            },
            {
               path: RoutePath.HELP_MOBILE,
               element: <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={HelpPage} />,
            },
            {
               path: RoutePath.MESSAGE_CREATE,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={ModifyMessagePage} />
               ),
            },
            {
               path: RoutePath.MESSAGES_MOBILE,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={MobileMessagesMenuPage}
                  />
               ),
            },
            {
               path: RoutePath.EMAIL_MESSAGE_CREATE,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ModifyEmailMessagePage}
                  />
               ),
            },
            {
               path: RoutePath.CLIENT_SELECTION,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ModifyClientSelectionPage}
                  />
               ),
            },
            {
               path: RoutePath.SCHEDULE_SEND,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ModifyScheduleSendPage}
                  />
               ),
            },
            {
               path: RoutePath.SCHEDULE_SEND_CUSTOM_TIME,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ModifyScheduleSendCustomTimePage}
                  />
               ),
            },
            {
               path: RoutePath.PAYMENT_DETILES,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={ModifyPaymentDetailsPage}
                  />
               ),
            },
            {
               path: RoutePath.BIRTHDAY_CAMPAIGN_SETTING,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={BirthdayCampaignSetting}
                  />
               ),
            },
            {
               path: RoutePath.WIN_BACK_CLIENTS_SETTING,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={WinBackClientsSetting}
                  />
               ),
            },
            {
               path: RoutePath.WELCOME_NEW_CLIENTS_SETTING,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={WelcomeNewClientSetting}
                  />
               ),
            },
            {
               path: RoutePath.REWARD_REGULAR_CLIENTS_SETTING,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={RewardRegularClientsSetting}
                  />
               ),
            },
            {
               path: RoutePath.CAMPAIGN_SETUP,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={CampaignSetup} />
               ),
            },
            {
               path: RoutePath.START_CAMPAIGN_PAGE,
               element: (
                  <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} component={StartCampaignPage} />
               ),
            },
            {
               path: RoutePath.UPDATE_SUBSCRIPTION,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={UpdateSubscriptionPage}
                  />
               ),
            },
            {
               path: RoutePath.NOTIFICATION_PAYMENT,
               element: (
                  <PrivateRoute
                     isAuthenticated={isAuthenticated}
                     isLoading={isLoading}
                     component={NotificationChargePage}
                  />
               ),
            },
         ],
      },
   ]
}
