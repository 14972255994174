import { RoutePath } from 'constants/routes'
import React from 'react'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ component: Component, isAuthenticated, isLoading, ...rest }: any): any => {
   return isAuthenticated ? (
      <Component {...rest} />
   ) : isLoading ? (
      () => <div>LOADING</div>
   ) : (
      <Navigate to={RoutePath.LOGIN} />
   )
}

export default PrivateRoute
