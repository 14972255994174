import { Row } from 'antd'
import styled from 'styled-components'

export const Container = styled(Row)`
   padding: 40px 15px 15px 15px;
`

export const ClientItem = styled.div`
   padding: 15px;
`
