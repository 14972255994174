import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Dropdown, Spin } from 'antd'
import * as api from 'api'
import { onReschedule } from 'helpers/app/reschedule'
import React from 'react'

import * as SC from '../styles'
import CreateServiceMenu from './CreateServiceMenu'
import ServiceGroup from './ServiceGroup'

// sort container
function ServiceGroupList({
   data,
   onItemDrop,
   createService,
   editService,
   createCategory,
   editCategory,
   isLoading,
   fetchServiceGroups,
   changeData,
}: any) {
   return (
      <SC.Container>
         <SC.Header>
            <Dropdown
               overlay={<CreateServiceMenu createService={createService} createCategory={createCategory} />}
               trigger={['click']}
               placement="bottomRight">
               <Button label="btn.add.new" type="primary" />
            </Dropdown>
         </SC.Header>
         <Spin spinning={isLoading}>
            {(data || []).map((e: any, i: number) => (
               <ServiceGroup
                  key={`group-${e.id}`}
                  fetchServiceGroups={fetchServiceGroups}
                  services={e.services}
                  name={e.name}
                  color={e.color}
                  id={e.id}
                  index={i}
                  editService={editService}
                  onServiceItemDrop={onItemDrop(e.id)}
                  editCategory={editCategory}
                  createService={createService}
                  handleServiceSortEnd={onReschedule({
                     data: e?.services,
                     changeData: (nextServices: any) => {
                        const nextData = [...data]
                        nextData[i].services = nextServices
                        changeData(nextData)
                     },
                     onPositionUpdate: async (id: string, newPosition: number) => {
                        await api.changeServicePosition('', { id, newPosition })
                     },
                  })}
               />
            ))}
         </Spin>
         <SC.AddCategoryWrapper>
            <Text size="base" weight="normal" colorType="base">
               <IntlLabel label="serviceGroup.addCategory.description" />
            </Text>
            <SC.AddCategory onClick={createCategory}>
               <PlusCircleOutlined /> <IntlLabel label="serviceGroup.addCategory.title" />
            </SC.AddCategory>
         </SC.AddCategoryWrapper>
      </SC.Container>
   )
}

export default ServiceGroupList
