import { getIsMobile } from '@plandok/core'
import { getCalendarHoursByViewType } from 'epic-calendar/support/helpers'
import { StateCalendar } from 'pages/dashboard/CalendarPage/constants'
import { WorkingHours } from 'pages/dashboard/CalendarPage/types'
import React, { memo, useEffect } from 'react'

import * as SC from '../../styles'
import {
   getColumnFormatTime,
   getColumnFormatTimeForCalendar,
   getColumnTimeForCalendar,
   getColumnTimeForFullCalendar,
} from '../../support/helpers/date'

interface IColumnTimeBlocks {
   hourCellCount: number
   timeInterval: number
   onTimeSlotClick(time: number): () => void
   customViewType: string
   customViewTime: WorkingHours
   workingHoursStartTime: number
   workingHoursEndTime: number
   timeFormat: string
}

let timer: any = null

function ColumnTimeBlocks(props: IColumnTimeBlocks): any {
   useEffect(() => {
      return () => {
         if (timer) {
            clearTimeout(timer)
            timer = null
         }
      }
   }, [])

   let count = 0

   const onClick = (e: any) => {
      const time = e?.target.getAttribute('data-item-time')
      props.onTimeSlotClick(parseInt(time, 10))
   }

   const onMobileClick = (e: any) => {
      const time = e?.target.getAttribute('data-item-time')

      props.onTimeSlotClick(parseInt(time, 10))
   }

   const getColumnTimeBlocks = () => {
      const CALENDAR_HOURS_ARR = [
         ...Array(
            getCalendarHoursByViewType(
               props.customViewTime.startTime,
               props.customViewTime.endTime,
               props.customViewType,
               props.workingHoursStartTime,
               props.workingHoursEndTime,
            ),
         ),
      ]

      const fullCalendar = CALENDAR_HOURS_ARR.map((_: any, h: any) => (
         <SC.CalendarCell key={h}>
            {[...Array(props.hourCellCount)].map((_: any, i: any) => {
               const time = getColumnTimeForFullCalendar(h, i, props.timeInterval)

               return (
                  <SC.CalendarCellTime
                     className="calendar__cell-time"
                     data-item-time={time}
                     onClick={getIsMobile() ? onMobileClick : onClick}
                     key={i}>
                     <span data-item-time={time}>
                        {getColumnFormatTime(h, i, props.timeInterval, props.timeFormat)}
                     </span>
                  </SC.CalendarCellTime>
               )
            })}
         </SC.CalendarCell>
      ))

      const calendar = (startTime: number, endTime: number) =>
         CALENDAR_HOURS_ARR.map((_, h) => (
            <SC.CalendarCell key={h}>
               {[...Array(props.hourCellCount)].map((_, i) => {
                  const time = getColumnTimeForCalendar(startTime, endTime, props.timeInterval, count)
                  const formattedTime = getColumnFormatTimeForCalendar(
                     startTime,
                     endTime,
                     props.timeInterval,
                     props.timeFormat,
                     count,
                  )
                  count++

                  return formattedTime ? (
                     <SC.CalendarCellTime
                        className="calendar__cell-time"
                        data-item-time={time}
                        onClick={getIsMobile() ? onMobileClick : onClick}
                        key={i}>
                        <span data-item-time={time}>{formattedTime}</span>
                     </SC.CalendarCellTime>
                  ) : null
               })}
            </SC.CalendarCell>
         ))

      switch (props.customViewType) {
         case StateCalendar.CUSTOM_RANGE:
            return calendar(props.customViewTime.startTime, props.customViewTime.endTime)
         case StateCalendar.WORKING_HOUR:
            return calendar(props.workingHoursStartTime, props.workingHoursEndTime)
         case StateCalendar.FULL_CALENDAR:
            return fullCalendar
         default:
            return fullCalendar
      }
   }

   return getColumnTimeBlocks()
}

export default memo(ColumnTimeBlocks)
