import { extractOptions, getFirstKey } from '@plandok/core'

import * as api from './'

// TODO move it to another place later, when we will migrate all queries
const applyMapping =
   (fn: any, { exactData, dataKey, extractDataFunc }: any = {}) =>
   async (_: any, ...args: any[]) => {
      const e = await fn(...args)
      let result = exactData ? e : dataKey ? e[dataKey] : getFirstKey(e)
      if (extractDataFunc) {
         result = extractDataFunc(e)
      }
      return result
   }

export const QueryType = {
   LOCATION_OPTIONS: 'LOCATION_OPTIONS',
   EMPLOYEE_HOURS: 'EMPLOYEE_HOURS',
   PROFILE_DETAILS: 'PROFILE_DETAILS',
   NOTIFICATIONS: 'NOTIFICATIONS',
   APPOINTMENT_DETAILS: 'APPOINTMENT_DETAILS',
   APPOINTMENT_CLIENT_DETAILS: 'APPOINTMENT_CLIENT_DETAILS',
   CLIENT_DETAILS: 'CLIENT_DETAILS',
   RESOURCE_OPTIONS: 'RESOURCE_OPTIONS',
   ESTIMATE_NOTIFICATION: 'ESTIMATE_NOTIFICATION',
   ESTIMATE_PLAN: 'ESTIMATE_PLAN',
   SUBSCRIPTION_PLAN: 'SUBSCRIPTION_PLAN',
}

export const queryLocationOptions = applyMapping(api.fetchLocationsOptions, {
   extractDataFunc: (lData: any) => extractOptions(lData, { code: 'id', name: 'name' }),
})

export const queryEmployeesHours = applyMapping(api.fetchEmployeesHours)

export const queryProfileDetails = applyMapping(api.fetchClientProfileDetails, { exactData: true })

export const queryAppointmentDetails = applyMapping(api.fetchAppointmentDetails, { exactData: true })

export const queryNotificationConfig = (notificationType: string) =>
   applyMapping(api.fetchNotificationConfig(notificationType), {
      exactData: true,
   })

export const queryAppointmentClientProfileDetails = applyMapping(api.fetchClientAppointmentDetails, {
   exactData: true,
})

export const queryResourceOptions = applyMapping(api.fetchResourcesOptions, {
   extractDataFunc: (data: any) => extractOptions(data, { code: 'id', name: 'name' }),
})

export const queryPaymentTypes = applyMapping(api.getAllPaymentTypes, { exactData: true })
