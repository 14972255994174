import { IntlLabel } from '@plandok/i18n'
import { Elements } from '@stripe/react-stripe-js'
import { Spin } from 'antd'
import * as api from 'api'
import CheckoutForm from 'pages/plans/components/CheckoutForm'
import NotificationSummaryCard from 'pages/plans/components/NotificationSummaryCard'
import React, { useEffect, useState } from 'react'

import { AppLayout, NoInternetConnection } from '../../../components'
import useStripeElement from '../../../hooks/use-stripe-element'

const NotificationChargePage = () => {
   const [isLoading, setIsLoading] = useState<boolean>(false)
   const [estimatedCostsData, setEstimatedCostsData] = useState<any>({})
   const { stripePromise } = useStripeElement()

   useEffect(() => {
      ;(async () => {
         setIsLoading(true)
         let response = await api.fetchNotificationEstimatedCosts()
         setIsLoading(false)

         setEstimatedCostsData(response.data)
      })()
      return () => {}
   }, [])

   const onPaymentSubmit = async (paymentMethod: string) => {
      return await api.payNotification({ paymentMethod: paymentMethod })
   }

   return (
      <NoInternetConnection>
         <AppLayout
            mobileHeader={() => (
               <>
                  <div />
                  <div>
                     <IntlLabel label="payment.step.title" />
                  </div>
                  <div />
               </>
            )}
            headerTitle="payment.step.title">
            <Spin spinning={isLoading}>
               <NotificationSummaryCard estimatedCostsData={estimatedCostsData} />
            </Spin>

            {stripePromise && (
               <Elements stripe={stripePromise}>
                  <CheckoutForm
                     onPaymentSubmit={onPaymentSubmit}
                     total={`${estimatedCostsData?.totalCharge?.toFixed(2) || 0}€`}
                  />
               </Elements>
            )}
         </AppLayout>
      </NoInternetConnection>
   )
}

export default NotificationChargePage
