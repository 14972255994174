import { Text } from '@plandok/core'
import { IntlTemplate } from '@plandok/i18n'
import { format } from 'date-fns'
import * as SC from 'pages/plans/UpdateSubscriptionPage/styles'
import React from 'react'

const NotificationSummaryCard = ({ estimatedCostsData }: any) => {
   const formatDate = (date: string) => format(new Date(date || Date.now()), 'yyyy/MM/dd')

   const totalCharge = `${estimatedCostsData?.totalCharge?.toFixed(2) || 0}€`
   const subtotal = `${estimatedCostsData?.subtotal?.toFixed(2) || 0}€`

   return (
      <SC.SummaryCardContainer>
         <Text label="reports.summary.title" weight="semiBold" className="summary" />
         {estimatedCostsData?.hasUnpaid && (
            <SC.ServiceItem>
               <div>
                  <Text
                     colorType="base"
                     size="medium"
                     weight="semiBold"
                     lh="mlarge"
                     label="payment.tab.title.notification"
                  />
                  <Text colorType="base" mb="none">
                     <IntlTemplate
                        label="reports.notifications.description"
                        params={[
                           estimatedCostsData?.totalCount,
                           `${formatDate(estimatedCostsData?.startDate)} - ${formatDate(estimatedCostsData?.endDate)}`,
                        ]}
                     />
                  </Text>
               </div>
               <Text colorType="base" mb="none">
                  {subtotal}
               </Text>
            </SC.ServiceItem>
         )}

         <SC.ContainerTotal>
            <div>
               <Text mb="none" size="large" lh="xxlarge">
                  <IntlTemplate label="appointment.vat" params={[estimatedCostsData?.vat || 0]} />
               </Text>
               <Text lh="xxlarge" size="large" colorType="base" mb="none" ml="large">
                  {estimatedCostsData?.taxes || 0}€
               </Text>
            </div>
            <SC.Total>
               <Text size="large" lh="xxlarge" colorType="base" mb="none" label="appointment.total" />
               <Text lh="xxlarge" size="large" weight="semiBold" colorType="base" mb="none" ml="large">
                  {totalCharge}
               </Text>
            </SC.Total>
         </SC.ContainerTotal>
      </SC.SummaryCardContainer>
   )
}

export default NotificationSummaryCard
