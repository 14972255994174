import { mediaLgUp, mediaMobileDown, Text } from '@plandok/core'
import { Button } from 'antd'
import { Field } from 'components/form'
import styled, { css } from 'styled-components'

type AddItemProps = {
   active: boolean
}

export const AddItem = styled.span<AddItemProps>`
   cursor: pointer;
   color: ${({ active }) => (active ? '#06123d' : '#e9ecf3')};
   i {
      color: ${({ active, theme }) => (active ? theme.infoColor : '#e9ecf3')};
   }
`

export const RemoveIcon = styled.div`
   position: absolute;
   right: 0;
   top: 0;
   color: #ff5e5e;
   cursor: pointer;
   z-index: 1;
`

export const FormRow = styled.div`
   ${mediaMobileDown(css`
      flex-direction: column-reverse !important;

      .time-row {
         margin-left: 0 !important;
         .ant-col-24 {
            width: 50%;
         }
      }
   `)}
`

export const FormContainer = styled.div`
   h2 {
      color: ${({ theme }) => theme.primaryColor};
      margin-left: 5px;
   }
   h3 {
      color: ${({ theme }) => theme.primaryColor};
      margin-left: 5px;
      margin-top: 50px;
   }
`

export const RightColumnWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
`

export const ScrollableContent = styled.div`
   ${mediaLgUp(css`
      overflow-y: auto;
      white-space: nowrap;
      max-height: 40vh;

      &::-webkit-scrollbar {
         width: 0.2rem;
         height: 0.2rem;
         margin-top: 1rem;
         margin-bottom: 1rem;
      }
      &::-webkit-scrollbar-track-piece {
         background-color: #e9ecf3;
      }
      &::-webkit-scrollbar-thumb {
         background: #adb6cc;
         border-radius: 10px;
      }
   `)}
`

export const Card = styled.div`
   ${mediaLgUp(css`
      box-shadow: 0px 2px 10px #263c7a33;
   `)}
`

export const RepeatAppButton = styled(Button)`
   background: transparent !important;
   border: none !important;
   box-shadow: none !important;
`

export const AppointmentFieldWrapper = styled(Field.Row)`
   position: relative;
`

export const RepeatAppButtonContent = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   color: #136ef6;

   div {
      padding-left: 1rem;
   }
`

export const Cancel = styled.span`
   margin-top: -13px;

   &:hover {
      cursor: pointer;
   }
`

export const Row = styled(Field.Row)`
   width: 100%;
   position: relative;
`

export const NotifyWrapper = styled(Text)`
   display: flex;
   gap: 5px;
   min-width: 100%;
   padding-left: 5px;
   color: #61749d;
   font-size: 13px;
   line-height: 16px;
`

export const Select = styled(Field.Select)`
   div:nth-child(1) {
      border-color: ${({ theme }) => theme.isBookingAppointment && '#f0a526'};
      :focus {
         border-color: ${({ theme }) => theme.isBookingAppointment && '#f0a526'};
      }
   }
`

export const NotifyBookingAppointmentWrapper = styled(Text)`
   min-width: 100%;
   padding-left: 5px;
   color: #f0a526;
   font-size: 13px;
   line-height: 16px;
`

export const ServiceFieldWrapper = styled.div`
   min-width: 100%;
   margin-bottom: ${({ theme }) => !theme.hasNotification && '13px'};

   .ant-form-explain {
      display: ${({ theme }) => !theme.isServiceFormError && 'none'};
   }
`

export const FieldsWrapper = styled(Field.Row)`
   min-width: 100%;
   margin-bottom: ${({ theme }) => !theme.isBookingAppointment && '13px'};

   .ant-form-explain {
      display: none;
   }
`

export const FieldsWrapperForDesktop = styled.div`
   min-width: 100%;
   display: flex;

   ${mediaMobileDown(css`
      display: block;
   `)}

   margin-bottom: ${({ theme }) =>
      !theme.isBookingAppointment && !theme.hasNotification && !theme.isServiceFormError && '13px'};

   .ant-form-explain {
      display: ${({ theme }) => !theme.isServiceFormError && 'none'};
   }
`
