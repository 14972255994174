import Icon from '@ant-design/icons'
import { Button, Field, Form, getIsMobile, Text, useLocation, useModal, validateRequired } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Col } from 'antd'
import { ModifyMassLayout } from 'components'
import { RoutePath } from 'constants/routes'
import flow from 'lodash/flow'
import noop from 'lodash/noop'
import React from 'react'

import * as SC from '../styles'

export default function MessageForm(props: any) {
   const { goBack, navigate } = useLocation()
   const [, { showModal }] = useModal()
   const isMobile = getIsMobile()

   const clientSelection = () => navigate(RoutePath.CLIENT_SELECTION)

   const sendMessage = (sendId: string, message: string) => {
      showModal({
         type: 'SEND_MESSAGE_MODAL',
         modalProps: {
            sendId,
            message,
         },
      })
   }

   const handleSubmit = (form: any) =>
      props.onSubmit({
         ...form,
         price: form.price || 0,
      })

   return (
      <>
         <Form
            onSubmit={handleSubmit}
            onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
            initialValues={props.initialValues}>
            {({ formError, values, submitting }: any) => {
               const sendId = values.smsSenderName
               const message = values.message
               return (
                  <>
                     <SC.CardContainer>
                        <Field.Row style={{ margin: 0 }}>
                           <Col span={24} md={12}>
                              <SC.Text>
                                 <Text label="Message and sender ID" size="mlarge" weight="semiBold" />
                              </SC.Text>
                              <Field.Input
                                 name="smsSenderName"
                                 label="input.smsSender.label"
                                 defaultValue="Plandok"
                                 validate={validateRequired}
                                 limit={11}
                              />
                              <Field.TextArea
                                 name="message"
                                 label="Your message"
                                 defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                                 rows="8"
                              />
                              <SC.Characters>
                                 <SC.Count>
                                    <Text label="reminder.charactersCount" size="small" mb="none" />
                                    <Text label="0/160 (1 SMS = 0.08€)" size="small" weight="semiBold" />
                                 </SC.Count>
                                 <SC.Pricing>
                                    <Icon type="exclamation-circle" /> <IntlLabel label="Pricing" />
                                 </SC.Pricing>
                              </SC.Characters>
                              <SC.ActionContainer>
                                 <Button
                                    label="Send me test message"
                                    type="primary"
                                    onClick={() => sendMessage(sendId, message)}
                                    upperCase={false}
                                 />
                                 <Text label="3 free tests left for today" size="small" />
                              </SC.ActionContainer>
                           </Col>
                           <Col span={24} md={8}>
                              <Text label="Message preview" size="base" mb="small" isCenter />
                              <SC.CardImage imgUrl="./img/illustrations/Phone-frame.svg">
                                 <Field.PreviewInput name="smsSenderName" text="input.smsSender.label" />
                                 <Field.PreviewArea name="message" />
                              </SC.CardImage>
                           </Col>
                        </Field.Row>
                     </SC.CardContainer>
                     <Form.Alert text={formError} />
                     {!isMobile ? (
                        <ModifyMassLayout.Footer>
                           <Button
                              block
                              type="primary"
                              label="Client selection"
                              htmlType="submit"
                              loading={submitting}
                              upperCase={false}
                              onClick={clientSelection}>
                              <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                           </Button>
                        </ModifyMassLayout.Footer>
                     ) : (
                        <ModifyMassLayout.Footer>
                           <Button block type="primary" ghost upperCase={false} onClick={goBack}>
                              <Icon type="arrow-left" style={{ marginRight: '10px' }} />
                              <IntlLabel label="Previous" />
                           </Button>
                           <Button
                              block
                              label="Next"
                              type="primary"
                              upperCase={false}
                              loading={submitting}
                              onClick={clientSelection}>
                              <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                           </Button>
                        </ModifyMassLayout.Footer>
                     )}
                  </>
               )
            }}
         </Form>
      </>
   )
}
