import { LabelKey } from '@plandok/i18n'
import { AppLayout, HashTabs, NoInternetConnection } from 'components'
import { SetupTabHash } from 'constants/routes'
import React, { ReactNode } from 'react'

import SetupLayoutMobileHeader from './components/SetupLayoutMobileHeader'
import { setupTabConfigs } from './config'
import SetupLayoutFormContainer from './SetupLayoutFormContainer'

type SetupPageLayoutProps = {
   children: ReactNode
   title?: LabelKey
}

function SetupPageLayout({ children, title = 'setup.page.title' }: SetupPageLayoutProps) {
   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="setup.page.title"
            mobileHeader={() => <SetupLayoutMobileHeader label={title} />}
            mobileWhiteBg={true}>
            <HashTabs tabs={setupTabConfigs} defaultTab={SetupTabHash.REMINDERS}>
               {children}
            </HashTabs>
         </AppLayout>
      </NoInternetConnection>
   )
}

SetupPageLayout.Body = SetupLayoutFormContainer

export default SetupPageLayout
