import { Spin } from 'antd'
import * as api from 'api'
import { AppLayout, NoInternetConnection } from 'components'
import withApiData from 'hocs/form/withApiData'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import CalendarSettingsForm from './components/CalendarSettingsForm'
import CalendarSettingsMobileHeader from './components/CalendarSettingsMobileHeader'

const CalendarSettingsFormWithApi = withFormApi({
   createAction: api.updateCalendarConfig,
   successMessage: 'Updated Calendar Config',
})(CalendarSettingsForm)

function CalendarSettingsPage({ data, isLoading }: any) {
   return (
      <NoInternetConnection>
         <AppLayout headerTitle="setup.page.title" mobileHeader={CalendarSettingsMobileHeader}>
            <AppLayout.Container maxWidth="680px">
               <Spin spinning={isLoading}>
                  {data ? (
                     // TODO: This is temporary fix with `excludeList`. Find proper solution
                     <CalendarSettingsFormWithApi initialValues={data} excludeList={['customViewTime']} />
                  ) : (
                     <CalendarSettingsForm />
                  )}
               </Spin>
            </AppLayout.Container>
         </AppLayout>
      </NoInternetConnection>
   )
}

export default withApiData(api.fetchCalendarConfig, { exactData: true })(CalendarSettingsPage)
