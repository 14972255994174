import { Field, useAppContext } from '@plandok/core'
import * as api from 'api'
import { dateFieldFormatterProps } from 'helpers/date/field'
import { getTimeOptions } from 'pages/dashboard/ModifyAppointmentPage/config/options'
import React from 'react'

import withModifyModal from '../withModifyModal'

function BlockTimeModal({ values = {}, form, isEdit }: any) {
   const [context] = useAppContext()
   const options = getTimeOptions(context.timeFormat)

   return (
      <>
         <Field.Date
            name="date"
            label="input.blockedDate.label"
            placeholder="input.date.placeholder"
            {...dateFieldFormatterProps}
         />
         {!isEdit && (
            <Field.AsyncSelect
               name="locationId"
               asyncType="location"
               label="input.location.label"
               placeholder="input.location.placeholder"
            />
         )}
         <Field.AsyncSelect
            name="employeeId"
            asyncType="employee"
            label="input.staff.label"
            description="input.staffSelect.description"
            filterParams={{ 'filter[location_id]': values.locationId }}
         />
         <Field.Row>
            <Field.Select
               options={options}
               md={12}
               name="startTime"
               label="input.startTime.label"
               onCustomChange={(date: any) => {
                  if (date != null) {
                     form.change('endTime', Math.min((date + 30) % (24 * 12 * 5)))
                  }
               }}
            />
            <Field.Select options={options} md={12} name="endTime" label="input.endTime.label" />
         </Field.Row>
         <Field.TextArea name="description" label="input.notes.label" placeholder="input.blockTime.placeholder" />
      </>
   )
}

export default withModifyModal({
   label: 'blocktime.create.modal.title',
   editLabel: 'blocktime.edit.modal.title',
   deleteAction: api.deleteBlockTime,
   createAction: api.createBlockTime,
   editAction: api.editBlockTime,
})(BlockTimeModal)
