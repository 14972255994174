import { CalendarOutlined, DownOutlined } from '@ant-design/icons'
import { getIsMobile, monthOptions, startWeekOptions } from '@plandok/core'
import { IntlDate, IntlLabel, ReactIntl } from '@plandok/i18n'
import { DatepickerHeader } from 'components'
import { getDay, getMonth } from 'date-fns'
import React, { useContext } from 'react'
import ReactDatePicker from 'react-datepicker'

import * as SC from './styles'

export default function AppointmentDate({ input, hideArrow, withPortal = getIsMobile(), disabled }: any) {
   const { lang } = useContext(ReactIntl)

   const weekDay = startWeekOptions.find(({ code }) => code === getDay(input.value))?.name
   const month = monthOptions.find(({ code }) => code === getMonth(input.value))?.name
   const date = input.value || new Date()

   return (
      <ReactDatePicker
         renderCustomHeader={DatepickerHeader}
         withPortal={withPortal}
         customInput={
            <SC.Container>
               <span id="calendar">
                  <CalendarOutlined />
               </span>

               <span>
                  <IntlLabel label={weekDay} />
                  <IntlDate date={date} dateFormat=", dd " />
                  <IntlLabel label={month} /> <IntlDate date={date} dateFormat=" yyyy" />
               </span>

               <span id="arrow">{!hideArrow && <DownOutlined />}</span>
            </SC.Container>
         }
         selected={input.value}
         disabled={disabled}
         locale={lang}
         {...input}
      />
   )
}
