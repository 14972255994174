import { CloseCircleOutlined, TeamOutlined } from '@ant-design/icons'
import { CustomIcon } from '@plandok/core'
import { IntlLabel, useIntlOptions } from '@plandok/i18n'
import { Avatar, Drawer } from 'antd'
import { viewTypeOptions } from 'constants/data'
import { getAvatarSign } from 'helpers/common'
import { CalendarStateProps } from 'pages/dashboard/CalendarPage/useCalendarState'
import React, { useCallback } from 'react'
import { useStore } from 'store/store'

import { staffOptions } from '../CalendarFilter/constants'
import * as SC from './styles'

const LocationComponent = (props: any) => {
   return (
      (props.options || []).map((e: any) => {
         const change = () => props.onChange(e.code)
         return (
            <SC.Item key={e.code} active={e.code === props.value} onClick={change}>
               {e.name}
            </SC.Item>
         )
      }) || null
   )
}

const SelectLocation = (props: any) => {
   return (
      props.options?.map((e: any) => (
         <SC.Item key={e.code} active={e.code === props.value} onClick={() => props.onChange(e.code)}>
            {e.isPredefined ? (
               <SC.IconCircle>
                  <TeamOutlined />
               </SC.IconCircle>
            ) : (
               <SC.AvatarContainer>
                  <Avatar size="large">{getAvatarSign(e.name)}</Avatar>
               </SC.AvatarContainer>
            )}
            <IntlLabel label={e.name as any} />
         </SC.Item>
      )) || null
   )
}

function CalendarMobileFilter(props: { calendarState: CalendarStateProps; onClose: any; visible: boolean }) {
   const { convertOptions } = useIntlOptions()
   const calendarState = props.calendarState

   const locationId = useStore(useCallback(state => state.locationId, []))

   const changeFilter = (key: string, value: any) => {
      calendarState.changeFilter(key, value)
      props.onClose()
   }

   const showAllStaff = calendarState?.isLoading || calendarState.employeesOptions?.length > 1
   return (
      <Drawer placement="right" width={270} closable={false} onClose={props.onClose} open={props.visible}>
         <SC.CloseMenu onClick={props.onClose}>
            <CloseCircleOutlined />
         </SC.CloseMenu>
         <h3>
            <IntlLabel label="title.calendarView" />
         </h3>
         <div className="d-flex">
            {viewTypeOptions.map(e => (
               <SC.IconItem
                  key={e.code}
                  active={e.code === calendarState.filterState.viewType}
                  onClick={() => changeFilter('viewType', e.code)}>
                  <SC.IconCircle>
                     <i data-calendar-custom-icon>
                        <CustomIcon type={e.icon as any} />
                     </i>
                  </SC.IconCircle>
                  <IntlLabel label={e.name as any} />
               </SC.IconItem>
            ))}
         </div>
         <br />
         <h3>Location</h3>
         <div>
            <LocationComponent
               onChange={(selected: any) => changeFilter('location_id', selected)}
               value={locationId}
               options={calendarState.locationOptions}
            />
         </div>
         <br />
         <h3>Staff</h3>
         <div>
            <SelectLocation
               options={[
                  ...(showAllStaff ? convertOptions(staffOptions) : []),
                  ...(calendarState.employeesOptions || []),
               ]}
               onChange={(selected: any) => calendarState.changeFilter('staff', selected)}
               value={calendarState.filterState.staff}
            />
         </div>
      </Drawer>
   )
}

export default CalendarMobileFilter
