import { arrayMoveImmutable } from 'array-move'
import isNil from 'lodash/isNil'

interface OnRescheduleProps {
   data?: any[]
   changeData?: any
   onPositionUpdate?: any
}

export const onReschedule = (props: OnRescheduleProps) => {
   const updatePosition = (oldIndex: number, newIndex: number) => {
      const nextData = [...(props?.data || [])]
      props.changeData?.(arrayMoveImmutable(nextData, oldIndex, newIndex))
   }

   return async ({ oldIndex, newIndex }: any) => {
      const id = props.data?.[oldIndex]?.id
      updatePosition(oldIndex, newIndex)
      try {
         await props?.onPositionUpdate?.(props.data?.[oldIndex]?.id, newIndex + 1)
      } catch (e) {
         const movedIndex = props?.data?.findIndex?.((e: any) => e.id === id)
         if (!isNil(movedIndex)) {
            updatePosition(movedIndex, oldIndex)
         }
      }
   }
}
