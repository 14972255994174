import { IntlDate, IntlLabel, IntlTemplate } from '@plandok/i18n'
import upperFirst from 'lodash/upperFirst'
import React, { Fragment } from 'react'

import { getNotificationType } from '../helpers'
import { Log, NotificationType } from '../types'

type NotificationLogProps = {
   getLogLabel: (log: Log) => string
   log: Log
   dateFormat: string
}

export default function NotificationLog({ getLogLabel, log, dateFormat }: NotificationLogProps) {
   return (
      <Fragment key={log.id}>
         <IntlLabel label={getNotificationType(log?.type as NotificationType)} />
         <IntlLabel label={getLogLabel(log)} />
         <IntlDate date={new Date(log?.createdAt)} dateFormat={dateFormat} />
         <IntlTemplate label="appointment.history.by.title" params={[upperFirst(log?.channel)]} />
         <br />
      </Fragment>
   )
}
