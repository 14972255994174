import { CustomIcon, formatTime, getIsMobile } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React, { memo } from 'react'
import styled from 'styled-components'

import { TimeCardData } from '../types'

const Container = styled.div`
   display: flex;
   max-width: 100%;
   flex-wrap: wrap;
   max-height: 40px;
   line-height: 1.2;
   font-size: 0.875rem;
   position: relative;
   width: 100%;
`

const StatusIconContainer = styled.div`
   display: flex;
   flex-direction: column;
   position: absolute;
   right: 5px;
   top: 2px;
   color: black;
   path {
      fill: #283140;
   }
`

type TimeCardContentProps = {
   timeCard: TimeCardData
   dynamicStartTime: number
   dynamicEndTime: number
   timeFormat: string
}

function TimeCardContent({ timeCard, dynamicStartTime, dynamicEndTime, timeFormat }: TimeCardContentProps) {
   if (timeCard.isBlocked) {
      return (
         <Container>
            <div className="mr-sm">
               <span>{formatTime(dynamicStartTime != null ? dynamicStartTime : timeCard.startTime, timeFormat)}</span> -
               <span> {formatTime(dynamicEndTime != null ? dynamicEndTime : timeCard.endTime, timeFormat)}</span>
            </div>
            <div className="bold mr-sm">
               <IntlLabel label="blocked.time" />
            </div>
            <div>{timeCard.description}</div>
         </Container>
      )
   }

   const getIconTypeByAppointmentStatus = (appointmentStatus: string): any => {
      switch (appointmentStatus) {
         case 'confirmed':
            return 'confirmedAppointment'
         case 'completed':
            return 'completedAppointment'
         case 'no_show':
            return 'noShowAppointment'
         default:
            return ''
      }
   }

   const iconType = getIconTypeByAppointmentStatus(timeCard.appointmentStatus)

   return (
      <Container>
         <div className="mr-sm">
            <span>{formatTime(dynamicStartTime != null ? dynamicStartTime : timeCard.startTime, timeFormat)}</span> -
            <span> {formatTime(dynamicEndTime != null ? dynamicEndTime : timeCard.endTime, timeFormat)}</span>
         </div>
         <div className="bold mr-sm">
            {timeCard.clientFirstName} {timeCard.clientLastName}
            {!timeCard.clientFirstName && !timeCard.clientFirstName && (
               <IntlLabel label="calendar.tooltip.emptyCustomer" />
            )}
         </div>
         <br />
         <div className="w-100">
            {timeCard.serviceName}
            {timeCard?.resourceName ? `, ${timeCard.resourceName}` : ''}
         </div>
         <StatusIconContainer>
            <div>{timeCard.isBookedOnline && <CustomIcon type="onlineBook" />}</div>
            <div>{timeCard.paymentType === 'stripe_payment' && <CustomIcon type="paidAppointment" />}</div>
            {!getIsMobile() && iconType && <CustomIcon type={iconType} />}
            {timeCard?.recurrenceId && <CustomIcon type="repeatStatus" />}
         </StatusIconContainer>
      </Container>
   )
}

export default memo(TimeCardContent)
