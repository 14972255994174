import styled from 'styled-components'

type StyledTextProps = {
   isCenter?: boolean
   marginLeft?: string
   marginBottom?: string
   fontWeight?: string
   upperCase?: boolean
   fontSize?: string
   lineHeight?: string
   theme?: any
   bold?: boolean
   isUnderline?: boolean
   isItalic?: boolean
   isCursor?: boolean
}

export const Text = styled.div<StyledTextProps>`
   text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
   font-size: ${({ fontSize }) => fontSize};
   line-height: ${({ lineHeight }) => lineHeight};
   color: ${({ color, theme }) => color || theme.primaryColor};
   font-weight: ${({ bold, fontWeight }) => (bold ? 500 : fontWeight ? fontWeight : 'initial')};
   margin-bottom: ${({ marginBottom }) => marginBottom || '20px'};
   margin-left: ${({ marginLeft }) => marginLeft || '0'};
   text-align: ${({ isCenter }) => (isCenter ? 'center' : 'inherit')};
   text-decoration: ${({ isUnderline }) => (isUnderline ? 'underline' : 'inherit')};
   font-style: ${({ isItalic }) => (isItalic ? 'italic' : 'inherit')};

   &:hover {
      cursor: ${({ isCursor }) => (isCursor ? 'pointer' : 'inherit')};
   }
`
