import { Button, mediaMobileDown, Text } from '@plandok/core'
import { Tabs } from 'antd'
import styled, { css } from 'styled-components'

export const ElementItem = styled.div<{ isLastElement: boolean }>`
   padding: 20px 0;

   ${mediaMobileDown(css`
      border-bottom: none;
   `)}

   border-bottom: ${props => (props.isLastElement ? 'none' : '1px solid #e9ecf3')};
`

export const ButtonWrapper = styled.a`
   padding: 0;
   color: #136ef6 !important;
   font-weight: 600;
   font-size: 18px;
   ${mediaMobileDown(css`
      height: auto;
   `)}
`

export const Title = styled(Text)`
   ${mediaMobileDown(css`
      font-size: 18px;
      margin-bottom: 5px;
   `)}
`

export const Description = styled(Text)`
   ${mediaMobileDown(css`
      font-weight: 400;
      font-size: 12px;
   `)}
`

export const ElementTitle = styled(Text)`
   ${mediaMobileDown(css`
      font-weight: 500;
      font-size: 16px;
   `)}
`

export const ElementDescription = styled(Text)`
   color: #61749d;
   ${mediaMobileDown(css`
      font-weight: 400;
      font-size: 12px;
   `)}
`

export const Divider = styled(Text)`
   display: none;
   ${mediaMobileDown(css`
      display: block;
      margin: 0;
   `)}
`

export const CloseMenu = styled.div`
   cursor: pointer;
   position: absolute;
   font-size: 26px;
   top: 15px;
   right: 15px;
   z-index: 10;
`

export const TitleDivider = styled.div`
   border-bottom: 1px solid #e9ecf3;
   position: absolute;
   left: 0;
   right: 0;
   top: 60px;
`

export const SelectTitle = styled(Text)`
   margin-top: 2rem;
`

export const ClearFilterButton = styled(Button)`
   color: #13316d !important;
   padding: 0.5rem;

   ${mediaMobileDown(css`
      height: auto;
   `)}
`

export const ApplyButton = styled(Button)`
   padding: 0 40px;
`

export const FilterActionsWrapper = styled.div`
   margin-top: 20px;
   display: flex;
   justify-content: space-between;
`

export const TabsWrapper = styled(Tabs)`
   .ant-tabs-bar {
      display: block !important;
   }
   .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
   .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
      display: none;
   }
   .ant-tabs-nav-container-scrolling {
      padding-left: 0;
      padding-right: 0;
   }
   .ant-tabs-bar {
      margin: 0 18px 16px 18px;
   }
   .ant-tabs-nav .ant-tabs-tab {
      margin: 0;
      height: 3.375rem;
      color: #61749d;
      max-width: 45%;
      white-space: pre-line;
      text-align: center;
      padding: 0px 20px;
   }
   .ant-tabs-nav .ant-tabs-tab-active {
      color: #13316d;
   }
`
