import { Button, withModal } from '@plandok/core'
import * as api from 'api'
import React from 'react'

import * as SC from '../styles'

function DeleteReminderModal({ modalProps, onClose }: any) {
   const deleteReminder = async () => {
      const timestampRegex = /^\d{10,13}$/
      if (timestampRegex.test(modalProps.templateId)) {
         const remindersAfterDelete = modalProps.forms.filter(({ id }: { id: string }) => id !== modalProps.templateId)
         modalProps.setForms(remindersAfterDelete)
      } else {
         await api.deleteNotificationConfig(modalProps.templateId)
         const remindersAfterDelete = modalProps.forms.filter(({ id }: { id: string }) => id !== modalProps.templateId)
         modalProps.setForms(remindersAfterDelete)
      }

      onClose()
   }

   return (
      <>
         <SC.DeleteRMTitle colorType="base" weight="normal" label={modalProps.label} />
         <SC.DeleteRMButtonsContainer>
            <Button label="btn.cancel" upperCase={false} onClick={() => onClose()} />
            <Button label="modal.deleteReminder.confirm" type="primary" upperCase={false} onClick={deleteReminder} />
         </SC.DeleteRMButtonsContainer>
      </>
   )
}

export default withModal('modal.deleteReminder.title', { contentClassName: 'delete-rm-modal' })(DeleteReminderModal)
