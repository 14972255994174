import { Button, CustomIcon, Text, useAppContext, useLocation, withModal } from '@plandok/core'
import { TABLE_COLUMNS, TABLE_DATA } from 'components'
import { RoutePath } from 'constants/routes'
import { getPlanPriceByStaffCount } from 'helpers/common'
import React, { useState } from 'react'

import * as SC from '../styles'

type Subscription = {
   endDate: string
   flatFee: number
   perUnit: number
   planType: string
   staffCount: number
   startDate: string
   total: number
}

type ModalProps = {
   subscription: Subscription
   isLoading: boolean
}

type ExtraCostsForNewMemberModalProps = {
   modalProps: ModalProps
   onClose: () => void
}

function ExtraCostsForNewMemberModal({ onClose }: ExtraCostsForNewMemberModalProps) {
   const [context] = useAppContext()
   const [modalStaffCount, setModalStaffCount] = useState(context.subscription.employeeCount ?? 1)
   const { navigate } = useLocation()

   const goToCreateStaff = () => {
      onClose()
      navigate(RoutePath.STAFF_CREATE)
   }

   const decrement = () => {
      if (modalStaffCount === 1) {
         return
      }

      setModalStaffCount((modalStaffCount: number) => modalStaffCount - 1)
   }

   const increment = () => setModalStaffCount((modalStaffCount: number) => modalStaffCount + 1)

   return (
      <>
         <SC.ExtraCostsForNewMemberDescription label="modal.extraCostsForNewMember.description" size="medium" />
         <SC.PriceCalculatedDescription colorType="base" label="pricing.calculated" mb="xmedium" />
         <SC.CustomTable columns={TABLE_COLUMNS} dataSource={TABLE_DATA} pagination={false} />
         <SC.PriceCalculator size="mlarge" weight="semiBold" label="pricing.calculator" mb="medium" />
         <SC.ContainerCounter>
            <Text label="pricing.numberStuff" mb="none" size="medium" lh="mlarge" style={{ marginRight: '16px' }} />
            <div onClick={decrement}>
               <CustomIcon type="decreaseCircleIcon" />
            </div>
            <SC.InputCalc type="text" value={modalStaffCount} readOnly />
            <div onClick={increment}>
               <CustomIcon type="increaseCircleIcon" />
            </div>
         </SC.ContainerCounter>
         <SC.WrapperPrice>
            <Text colorType="base" weight="bold" mb="none">
               {getPlanPriceByStaffCount(modalStaffCount)}€
            </Text>
            <Text colorType="base" label="period.perMonth" mb="none" size="small" ml="xxsmall" lh="base" />
         </SC.WrapperPrice>
         <SC.ExtraCostsForNewMemberBtnsContainer>
            <Button whiteBtn upperCase={false} onClick={() => onClose()} label="btn.decline" />
            <Button label="btn.agree" upperCase={false} onClick={goToCreateStaff} />
         </SC.ExtraCostsForNewMemberBtnsContainer>
      </>
   )
}

export default withModal('modal.extraCostsForNewMember.title', {
   contentClassName: 'extra-costs-for-new-member-modal',
})(ExtraCostsForNewMemberModal)
