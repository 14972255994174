import React, { ComponentType } from 'react'
import { useLocation as useRouterLocation, useNavigate, useParams as useRouterParams } from 'react-router-dom'

/**
 * Hook to get location and add helper methods for navigation and query parsing.
 */
export function useLocation() {
   const location = useRouterLocation()
   const navigate = useNavigate()

   /**
    * Navigate to a new route.
    * @param to - Target route.
    * @param id - Optional ID to append to the route.
    * @param options - Navigation options (replace, backPath).
    */
   function navigateTo(
      to: string,
      id?: string,
      { replace = false, backPath }: { replace?: boolean; backPath?: string } = {},
   ) {
      let nextPath = id ? `${to}/${id}` : to

      if (backPath) {
         nextPath = `${nextPath}?backPath=${backPath}`
      }
      navigate(nextPath, { replace })
   }

   /**
    * Navigate to the previous page.
    */
   function goBack() {
      navigate(-1)
   }

   /**
    * Parse query parameters from the URL.
    * @returns An object of query parameters.
    */
   function urlQuery(): Record<string, string> {
      const searchParams = new URLSearchParams(location.search)
      const params: Record<string, string> = {}
      for (const [key, value] of searchParams.entries()) {
         params[key] = value
      }
      return params
   }

   return {
      location,
      params: {} as any,
      navigate: navigateTo,
      goBack,
      urlQuery,
   }
}

/**
 * Hook to get route parameters.
 */
export function useParams<T extends Record<string, string>>() {
   return useRouterParams<T>() as any
}
/**
 * Hook to get route parameters.
 */
export function useHistory<T extends Record<string, string>>() {
   const location = useRouterLocation()
   const navigate = useNavigate()

   return {
      go: (page: number) => navigate(page),
      push: (page: string, vars?: any) => {},
      location: {
         ...location,
         state: '',
         search: '',
      } as any,
   }
}

export function useRouter<T extends Record<string, string>>() {
   let params = useRouterParams<T>()
   return {
      params: {
         ...params,
         locationId: window.location,
      },
   }
}

export function withRouter<P>(Component: ComponentType<P>) {
   return (props: P) => {
      const location = useLocation()
      const navigate = useNavigate()
      const params = useParams()

      return <Component {...props} router={{ location, navigate, params }} />
   }
}
