import { Button, Mutation, withModal } from '@plandok/core'
import { formatTextByLang, IntlTemplate, ReactIntl } from '@plandok/i18n'
import React, { useContext } from 'react'

import * as SC from '../../styles'

/**
 * When editing/deleting working hours which are Ongoing.
 * The system should drop following pop-ups, asking if the
 * user wants to update/delete this shift only, or upcoming shifts too.
 *
 * 1.) if user picks this shift only, it would update/delete that shift only, leaving others unchanged
 * 2.) if a user picks "Upcoming shifts", it would update this and any existing upcoming shifts, leaving past shifts unchanged.
 * 3.) Though, if this is a last of the shifts which had "until specific date" then there are no upcoming shifts to update and only this shift update (pop-up not required)
 */

interface ConfirmShiftsModifyProps {
   modalProps: {
      action: any
      isDelete: boolean
      firstName: string
      date: Date

      actionAll: any
      actionThis: any
   }
   onClose: any
}

function ConfirmShiftsModify({ modalProps, ...props }: ConfirmShiftsModifyProps) {
   const { lang } = useContext(ReactIntl)

   return (
      <div>
         <div>
            <IntlTemplate
               label={modalProps.isDelete ? 'text.confirmShift.delete' : 'text.confirmShift.update'}
               params={[modalProps.firstName, formatTextByLang(modalProps.date, 'EEEE', lang)]}
            />
            <br />
         </div>
         <SC.ModalFooterResponsive>
            <Button label="btn.cancel" onClick={props.onClose} data-shift-time-minor-btn />
            <Mutation action={modalProps.actionAll}>
               {({ loading, action }: any) => (
                  <Button
                     loading={loading}
                     label={modalProps.isDelete ? 'btn.deleteUpcoming.shifts' : 'btn.updateUpcoming.shifts'}
                     onClick={action}
                     data-shift-time-btn
                     minorBtn
                  />
               )}
            </Mutation>
            <Mutation action={modalProps.actionThis}>
               {({ loading, action }: any) => (
                  <Button
                     loading={loading}
                     label={modalProps.isDelete ? 'btn.deleteThis.shift' : 'btn.updateThis.shift'}
                     danger={modalProps.isDelete}
                     onClick={action}
                     data-shift-time-btn
                  />
               )}
            </Mutation>
         </SC.ModalFooterResponsive>
      </div>
   )
}

export default withModal('title.repeatShifts', { wide: true })(ConfirmShiftsModify)
