import { Button } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Dropdown } from 'antd'
import { PermissionType } from 'constants/auth'
import useHasPermission from 'hooks/permission/useHasPermission'
import React from 'react'

export default function ClientMoreOptionMenu(props: { edit(): any; delete(): any }) {
   const hasPermission = useHasPermission(PermissionType.CAN_SEE_CLIENT_INFO)

   const items: any = [
      {
         key: '1',
         onClick: props.edit,
         label: <IntlLabel label="menu.edit.client" />,
      },
      {
         key: '2',
         onClick: props.delete,
         label: <IntlLabel label="menu.delete.client" />,
      },
   ]

   return (
      <Dropdown {...props} menu={{ items } as any} disabled={!hasPermission}>
         <Button whiteBtn label="btn.more.options" bold={false} upperCase={false} />
      </Dropdown>
   )
}
