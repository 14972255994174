import { FieldRenderProps } from 'react-final-form'
import withFormBlock from './FormBlock/withFormBlock'
import React from 'react'
import { IntlDate } from '@plandok/i18n'
import styled from 'styled-components'

const Container = styled.div`
   display: flex;
   border-bottom: 1px solid #adb6cc;
   color: #13316d;
   font-weight: 500;
`

interface FormInputProps {
   submitting?: boolean
   date: Date
   hour?: boolean
}

const CustomTimeInput = ({
   input,
   submitting,
   meta,
   date,
   hour,
   ...rest
}: FormInputProps & FieldRenderProps<any, any>) => {
   return (
      <Container>
         {!hour ? <IntlDate date={date} dateFormat="MMM dd, yyyy" /> : <IntlDate date={date} dateFormat="hh:mm" />}
      </Container>
   )
}

export default withFormBlock<FormInputProps>(CustomTimeInput)
