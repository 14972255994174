import { Input } from 'antd'
import { format } from 'date-fns'
import ClearDatePicker from './ClearDatePicker'
import React from 'react'

const DatePickerInput = ({ onClear, inputValue, onClick, onInputChange, placeholder, disabled, ...props }: any) => {
   return (
      <>
         <Input
            {...props}
            type="text"
            placeholder={placeholder}
            disabled={disabled}
            value={inputValue || ''}
            onClick={onClick}
            inputMode="none"
         />
         {onClear && (
            <ClearDatePicker
               onClick={() => {
                  onInputChange('')
                  onClear()
               }}
            />
         )}
      </>
   )
}

export default DatePickerInput
