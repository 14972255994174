import { IntlLabel, LabelKey } from '@plandok/i18n'
import { Tabs as AntTab } from 'antd'
import React, { ReactNode } from 'react'

interface TabsProps {
   children: ReactNode[]
   tabs: { tab: LabelKey; key: string }[]
   defaultTab?: string
   forceRender?: boolean
}

/**
 * AntTabs not working with React.Children(...)
 * @param props
 * @constructor
 */
export default function Tabs(props: TabsProps) {
   const defaultActiveKey = props.defaultTab || props.tabs[0].key

   return (
      <AntTab defaultActiveKey={defaultActiveKey}>
         {props.children.map((rend, i) => {
            const tabConfig = props.tabs[i]

            return (
               <AntTab.TabPane
                  tab={<IntlLabel label={tabConfig.tab} />}
                  key={tabConfig.key}
                  forceRender={props.forceRender}>
                  {rend}
               </AntTab.TabPane>
            )
         })}
      </AntTab>
   )
}
