import { theme } from '@plandok/core'

export function getTheme(): any {
   return {
      token: {
         colorPrimary: theme.primaryColor,
         colorInfo: theme.infoColor,
         sizeStep: 4,
         borderRadius: 5,
         boxShadow: theme.boxBorderDecoration,
         boxShadowSecondary: theme.boxBorderDecoration,
         colorLink: theme.linkColor,
      },
      components: {
         Button: {
            borderRadius: 5,
            contentFontSize: 16,
            defaultShadow: '',
            paddingBlockLG: 15,
            marginXS: 15,
            paddingXS: 15,
            paddingBlockSM: 15,
            contentLineHeightLG: 15,
            contentLineHeightSM: 5,
            controlHeight: 40,
         },
         Divider: {
            margin: 10,
            marginLG: 12,
         },
         Drawer: {
            paddingLG: 10,
            padding: 10,
         },
         Layout: {
            headerHeight: 30,
         },
         Table: {
            headerBg: 'rgb(255,255,255)',
            footerBg: 'rgb(255,255,255)',
            headerColor: theme.primaryColor,
            boxShadow: theme.boxShadow,
            headerBorderRadius: 0,
            borderRadius: 20,
         },
         Select: {
            controlHeight: 40,
            paddingBlockLG: 15,
         },
         Input: {
            controlHeight: 40,
         },
         Modal: {
            borderRadiusLG: 15,
         },
         Menu: {
            itemBorderRadius: 1,
            itemActiveBg: theme.primaryColor,
         },
         Typography: {
            titleMarginBottom: 0,
            titleMarginTop: 0,
         },
         Collapse: {
            contentPadding: '0 0',
            headerPadding: '0 0',
         },
      },
   }
}
