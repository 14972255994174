import { useLocation } from '@plandok/core'
import { ReactIntl } from '@plandok/i18n'
import { NoInternetConnection } from 'components'
import { PriceType } from 'pages/plans/BillingPage/PricingPage/constants'
import React, { useContext, useEffect } from 'react'

import PricingItem from './components/PricingItem'
import * as SC from './styles'

export const languages = [
   '/cs',
   '/en',
   '/et',
   '/fr',
   '/de',
   '/it',
   '/lv',
   '/lt',
   '/pl',
   '/pt',
   '/ru',
   '/es',
   '/tr',
   '/ua',
]

export default function PlansPage() {
   const { changeLang } = useContext(ReactIntl)
   const { location } = useLocation()

   useEffect(() => {
      const checkLang = languages.some(lang => location.pathname.startsWith(lang))
      changeLang(checkLang ? location.pathname.split('/')[1] : 'en')
   }, [changeLang, location])

   return (
      <NoInternetConnection>
         <SC.PlansPageContainer>
            <PricingItem
               planName="plan.foreverFree"
               advantages={[
                  'plan.free.option.0',
                  'plan.free.option.1',
                  'plan.free.option.2',
                  'plan.free.option.3',
                  'plan.free.option.4',
                  'plan.free.option.5',
               ]}
               planType={PriceType.FREE}
               isFree
               plansPage
            />
            <PricingItem
               planName="analytics.premium.title"
               advantages={[
                  'plan.premium.option.0',
                  'plan.free.option.2',
                  'plan.free.option.3',
                  'plan.free.option.4',
                  'plan.premium.option.1',
                  'plan.premium.option.2',
                  'plan.premium.option.3',
                  'plan.premium.option.4',
                  'plan.premium.option.5',
                  'plan.premium.option.6',
                  'plan.premium.option.7',
                  'plan.premium.option.8',
                  'plan.premium.option.9',
                  'plan.premium.option.10',
                  'plan.premium.option.11',
                  'plan.premium.option.12',
                  'plan.premium.option.13',
               ]}
               planType={PriceType.PREMIUM}
               plansPage
            />
         </SC.PlansPageContainer>
      </NoInternetConnection>
   )
}
