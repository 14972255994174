import { Text } from '@plandok/core'
import { IntlLabel, IntlMoney } from '@plandok/i18n'
import { format } from 'date-fns'
import React from 'react'

import { capitalizeFirstLetter } from '../../../helpers/common/index'

export const clientsColumns = [
   {
      title: <IntlLabel label="table.clients.name" />,
      key: 'user',
      dataIndex: 'user',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },

   {
      title: <IntlLabel label="table.clients.appointments" />,
      key: 'appointments',
      dataIndex: 'appointments',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.clients.noShows" />,
      key: 'noShows',
      dataIndex: 'noShows',

      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.clients.totalSales" />,
      key: 'totalSales',
      dataIndex: 'totalSales',
      render: (value: any) => {
         const totalSales = JSON.parse(JSON.stringify(value))
         return (
            <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
               <IntlMoney value={totalSales.amount} currency={totalSales.currency} />
            </Text>
         )
      },
   },
   {
      title: <IntlLabel label="table.clients.gender" />,
      key: 'gender',
      dataIndex: 'gender',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {capitalizeFirstLetter(value)}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.clients.added" />,
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value: Date) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {format(new Date(value), 'yyyy-MM-dd')}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.clients.daysAbsent" />,
      key: 'daysAbsent',
      dataIndex: 'daysAbsent',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.clients.lastAppointment" />,
      key: 'lastAppointmentAt',
      dataIndex: 'lastAppointmentAt',
      render: (value: Date) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {format(new Date(value), 'yyyy-MM-dd')}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.clients.lastLocation" />,
      key: 'lastLocation',
      dataIndex: 'lastLocation',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
]
