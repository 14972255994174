import { mediaMdDown, mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

type ContainerProps = {
   paginationSpacing?: string
   isCenter?: boolean
}

export const Container = styled.div<ContainerProps>`
   margin: ${({ paginationSpacing }) => paginationSpacing || '30px 0'};
   justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'space-between')};
   display: flex;
   align-items: center;

   ${mediaMdDown(css`
      flex-direction: column-reverse;
   `)}
`

export const TotalNumbers = styled.div`
   display: flex;
   div {
      margin-left: 0.5rem;
   }
`

export const Pagination = styled.div`
   display: flex;
   background: white;
   color: #adb6cc;
   box-shadow: 0 3px 50px rgba(53, 38, 122, 0.15);
   border-radius: ${props => props.theme.borderRadiusBase};
   padding: 9px;
   align-items: center;
   button {
      color: #adb6cc;
      height: 30px;
      width: 30px;
      min-width: 30px;
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: 400;
      &:not(&:last-of-type) {
         margin-right: 10px;
      }

      &:first-of-type,
      &:last-of-type {
         color: ${props => props.theme.primaryColor};
      }
   }

   [data-active] {
      color: white;
      background: ${props => props.theme.infoColor} !important;
   }

   ${mediaMobileDown(css`
      margin: auto;
   `)}
`
