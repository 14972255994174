import { useNavigation } from '@plandok/core'
import * as api from 'api'
import { AppLayout, AppTable, NoInternetConnection } from 'components'
import { PermissionType } from 'constants/auth'
import { RoutePath } from 'constants/routes'
import useGetHasPermission from 'hooks/permission/useGetHasPermission'
import React, { useState } from 'react'

import ClientFilter from './components/ClientFilter'
import ClientsMobileHeader from './components/ClientsMobileHeader'
import MobileClientRow from './components/MobileClientRow'
import { clientColumns } from './config'

export default function ClientsPage() {
   const [query, setQuery] = useState('')

   const getHasPermission = useGetHasPermission()
   const { navigate } = useNavigation()

   const createClient = () => navigate(RoutePath.CLIENT_CREATE)

   // const checkDuplicated = async () => {
   //   try {
   //     const { status } = await api.checkDuplicatedClients();
   //     setHasDuplicates(status);
   //   } catch (e) {
   //     console.error('Error when getting duplicate clients');
   //     return;
   //   }
   // };

   // useEffect(() => {
   //   checkDuplicated();
   // }, []);

   const viewClientDetails = (id: string) => {
      if (getHasPermission(PermissionType.CAN_SEE_CLIENT_INFO)) {
         return navigate(`${RoutePath.CLIENT_DETAILS}/${id}`)
      }
   }

   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="sidebar.client"
            mobileHeader={ClientsMobileHeader}
            addButtonPermission={PermissionType.CAN_SEE_CLIENT_INFO}
            addButtonPath={RoutePath.CLIENT_CREATE}
            addBtnClassName="client-page-add-btn">
            <AppTable
               type="clients"
               columns={clientColumns}
               searchStr={query}
               viewDetails={viewClientDetails}
               mobileRowComponent={MobileClientRow}
               mobileClassName="app_clients-list--mobile"
               apiConfig={{
                  fetchData: api.fetchClients,
                  mapping: {
                     pageKey: 'page',
                     dataKey: 'clients',
                     totalCountKey: 'totalCount',
                     searchKey: 'query',
                     emptyStateKey: 'meta.emptyState',
                  },
               }}
               placeholder={{
                  imgName: 'clients',
                  titleLabel: 'state.clients.title',
                  descriptionLabel: 'state.clients.description',
                  btnLabel: 'state.clients.button',
                  onClick: createClient,
               }}>
               <ClientFilter setQuery={setQuery} query={query} />
               {/*{hasDuplicates && <MergeDuplicateClients checkAction={checkDuplicated} />}*/}
            </AppTable>
         </AppLayout>
      </NoInternetConnection>
   )
}
