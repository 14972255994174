import { Button, Card, Field, Form, getIsMobile, Text, useModal, validateRequired } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Col, message, Spin } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import * as api from 'api'
import { CalendarSync, DeleteButton, ModifyLayout, MultiCheckboxField, Tabs } from 'components'
import { PermissionLevel } from 'constants/auth'
import { permissionOptions } from 'constants/data'
import { RoutePath, StaffTabHash } from 'constants/routes'
import { App_BucketName } from 'graphql/generatedTypes'
import { uploadFileToS3 } from 'helpers/common'
import noop from 'lodash/noop'
import React, { useState } from 'react'

import { mutate, mutation } from '../../../../graphql'
import * as SC from './styles'

const staffTabs = [
   { key: 'tabs.details', tab: 'tabs.details' },
   { key: 'tabs.locations', tab: 'tabs.locations' },
   { key: 'tabs.services', tab: 'tabs.services' },
   { key: 'tabs.integrations', tab: 'tabs.integrations' },
]

export default function StaffForm(props: any) {
   const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)
   const [, { showModal }] = useModal()

   const setAllAsDefault = (onChange: any, options: any) => onChange(options.map(({ code }: any) => code))

   const handleResendPassword = async (email: string) => {
      try {
         const response = await mutate(mutation.REQUEST_PASSWORD_RESEND)({ email })

         if (response.data.requestPasswordResend.success) {
            message.success(<IntlLabel label="message.successReset.password" />)
         }
      } catch (error) {
         message.error(<IntlLabel label={(error as any).toString()} />)
      }
   }

   const getUploadedPhotoUrl = async (originFile: UploadFile['originFileObj']) => {
      try {
         setIsLoadingPhoto(true)

         const { data } = await mutate(mutation.GENERATE_PHOTO_UPLOADS)({ count: 1, bucket_name: App_BucketName.Staff })

         const upload = data?.generatePhotoUploads?.uploads[0]

         if (!upload) {
            return
         }

         await uploadFileToS3(upload, originFile)

         return upload.url
      } catch (error) {
         return { FORM_ERROR: 'Failed to load files.' }
      } finally {
         setIsLoadingPhoto(false)
      }
   }

   const formatForm = (form: any) => ({
      ...form,
      photo: form.photo ? form.photo : null,
   })

   const viewExtraCostSModal = () =>
      showModal({
         type: 'EXTRA_COSTS_FOR_NEW_MEMBER',
      })

   const isLoading = props.isLoading

   return (
      <Spin spinning={isLoading}>
         <Form
            onSubmit={props.onSubmit}
            onSuccess={props.onSuccess}
            initialValues={props.initialValues}
            combinedFields={['phoneNumber']}
            formatForm={formatForm}>
            {({ formError, values, submitting }: any) => {
               const requireEmail = values.permissionLevel !== 'no_access'
               const isOwner = values.permissionLevel === PermissionLevel.OWNER

               return (
                  <>
                     <Card withShadow minHeight="450px">
                        <Tabs tabs={staffTabs} forceRender>
                           {/* Details tab */}
                           <Field.Row gutter={getIsMobile() ? 0 : 30}>
                              <Col span={24} md={12}>
                                 <Text size="medium" mb="xsmall" weight="semiBold" bold>
                                    <IntlLabel label="title.personalDetails" />
                                 </Text>
                                 <Field.Row>
                                    <SC.StyledPictureAndNameBlock>
                                       <Field.PictureInput
                                          name="photo"
                                          label="input.staffPicture.label"
                                          onUpload={getUploadedPhotoUrl}
                                       />
                                       <Field.Input
                                          name="firstName"
                                          label="input.firstName.label"
                                          placeholder="input.firstName.placeholder"
                                       />
                                       <Field.Input
                                          name="lastName"
                                          label="input.lastName.label"
                                          placeholder="input.lastName.placeholder"
                                       />
                                    </SC.StyledPictureAndNameBlock>
                                    <Field.Input
                                       name="title"
                                       label="input.title.label"
                                       placeholder="input.title.placeholder"
                                    />
                                    <Field.CombinedInput
                                       name="phoneNumber"
                                       label="input.mobile.label"
                                       placeholder="input.mobile.placeholder"
                                    />
                                    <Field.Input
                                       name="email"
                                       label="input.email.label"
                                       placeholder="input.email.placeholder"
                                       validate={requireEmail ? validateRequired : noop}
                                       disabled={isOwner}
                                    />
                                    <Field.Select
                                       name="permissionLevel"
                                       label="input.permission.label"
                                       options={
                                          isOwner
                                             ? permissionOptions
                                             : permissionOptions.filter(e => e.code !== 'owner')
                                       }
                                       disabled={isOwner}
                                       translate
                                    />
                                 </Field.Row>
                                 {requireEmail && (
                                    <>
                                       <Text size="medium" mb="xsmall" weight="semiBold" bold>
                                          <IntlLabel label="edit.staff.title.resendPassword" />
                                       </Text>
                                       <Text size="base" mb="small" weight="normal" style={{ color: '#61749D' }}>
                                          <IntlLabel label="edit.staff.info.resendPassword" />
                                       </Text>
                                       <SC.StyledResendPasswordButton
                                          outlineBtn
                                          onClick={() => handleResendPassword(values?.email)}>
                                          <Text size="small" mb="none" weight="semiBold" upperCase bold>
                                             <IntlLabel label="edit.staff.button.resendPassword" />
                                          </Text>
                                       </SC.StyledResendPasswordButton>
                                    </>
                                 )}
                              </Col>
                              <Col span={24} md={12}>
                                 <Field.Row>
                                    <Field.TextArea
                                       name="notes"
                                       label="input.notes.label"
                                       placeholder="input.staffNotes.placeholder"
                                       rows="5"
                                    />
                                    <Field.ColorSelect name="appointmentColor" label="input.appointmentColors.label" />
                                 </Field.Row>
                              </Col>
                           </Field.Row>

                           {/* Locations tab */}
                           <Field.AsyncSelect
                              selectComponent={MultiCheckboxField}
                              name="locationIds"
                              asyncType="location"
                              description="input.multiLocation.description"
                              mode="multi"
                              onInitialLoad={!props.isEdit && setAllAsDefault}
                           />

                           {/* Services tab */}
                           <Field.AsyncSelect
                              selectComponent={MultiCheckboxField}
                              name="serviceIds"
                              asyncType="services"
                              description="input.multiServices.description"
                              mode="multi"
                              onInitialLoad={!props.isEdit && setAllAsDefault}
                           />

                           {/* Integrations tab */}
                           <CalendarSync staffPage />
                        </Tabs>
                     </Card>
                     <Form.Alert text={formError} />
                     {!props.isEdit && (
                        <SC.AdditionalPriceContainer>
                           <Text
                              mb="none"
                              colorType="lightViolet"
                              size="base"
                              lh="xlarge"
                              label="description.extraCharge"
                           />

                           <Text
                              mb="none"
                              colorType="lightViolet"
                              size="base"
                              lh="xlarge"
                              label="plan.premium.viewPricing"
                              weight="medium"
                              onClick={viewExtraCostSModal}
                           />
                        </SC.AdditionalPriceContainer>
                     )}
                     <ModifyLayout.Footer>
                        {props.isEdit && (
                           <DeleteButton
                              deleteAction={api.deleteStaff}
                              entityName="service"
                              name={values.name}
                              id={props.entityId}
                              backPath={`${RoutePath.STAFF}#${StaffTabHash.STAFF_MEMBERS}`}
                           />
                        )}
                        <Button block label="btn.cancel" onClick={props.onCancel} />
                        <Button
                           block
                           type="primary"
                           label="btn.save"
                           htmlType="submit"
                           loading={submitting || isLoadingPhoto}
                        />
                     </ModifyLayout.Footer>
                  </>
               )
            }}
         </Form>
      </Spin>
   )
}
