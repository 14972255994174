import { PlusOutlined } from '@ant-design/icons'
import { Button, useModal } from '@plandok/core'
import { useLocation } from '@plandok/core'
import React from 'react'

import * as SC from './styles'

type AddButtonProps = {
   path?: string
   addButtonModal?: string
   className?: string
   modalProps?: any
   isLoading?: boolean
}

export default function AddButton({ path, addButtonModal, className, modalProps, isLoading = false }: AddButtonProps) {
   const [, { showModal }] = useModal()
   const { navigate } = useLocation()

   const onBtnClick = () => {
      if (path) {
         navigate(path)
      } else {
         showModal({ type: addButtonModal ?? '', modalProps })
      }
   }

   return (
      <SC.ButtonContainer onClick={onBtnClick} className={className} data-create-btn-mobile>
         <Button type="primary" icon={<PlusOutlined />} minorBtn disabled={isLoading} />
      </SC.ButtonContainer>
   )
}
