import { BillingTabHash } from '../../../constants/routes'

export const billingTabs = [
   {
      path: BillingTabHash.PLAN,
      title: 'billing.tab.plan',
   },
   {
      path: BillingTabHash.COSTS,
      title: 'billing.tab.costs',
   },
   {
      path: BillingTabHash.INFO,
      title: 'billing.tab.billing',
   },
   {
      path: BillingTabHash.INVOICES,
      title: 'billing.tab.invoice',
   },
   {
      path: BillingTabHash.PAYMENT_METHOD,
      title: 'market.ob.paymentMethod.title',
   },
]

export const extendedBillingTabs: any[] = [
   {
      path: BillingTabHash.PLAN,
      title: 'billing.tab.plan',
   },
   {
      path: BillingTabHash.COSTS,
      title: 'billing.tab.costs',
   },
   {
      path: BillingTabHash.INFO,
      title: 'billing.tab.billing',
   },
   {
      path: BillingTabHash.INVOICES,
      title: 'billing.tab.invoice',
   },
   {
      path: BillingTabHash.PAYMENT_METHOD,
      title: 'market.ob.paymentMethod.title',
   },
]
