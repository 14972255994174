import { Button, Text } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { Placeholder } from 'components'
import { OnlineBookingHash } from 'constants/routes'
import React from 'react'

import * as SC from './styles'

export default function BookingOverview() {
   const { navigate } = useLocation()
   const redirectCreateBooking = () => navigate(`#${OnlineBookingHash.PROFILE}`)

   return (
      <SC.Container>
         <Placeholder
            mobileTitleContent={
               <Text
                  label="ob.overview.placheholder.title"
                  style={{ fontWeight: 'bold' }}
                  size="large"
                  data-booking-placeholder-mobile-title
               />
            }
            imgName="booking"
            titleLabel="ob.overview.placheholder.title"
            descriptionLabel="ob.overview.placheholder.description"
            position="relative"
            containerComponent={SC.PlaceholderContainer}
            templateOptions={{ description: true }}
         />
         <SC.ActionContainer>
            <Button label="ob.overview.start.now" type="primary" onClick={redirectCreateBooking} upperCase={false} />
         </SC.ActionContainer>
      </SC.Container>
   )
}
