import { Text } from '@plandok/core'
import { Col, Divider, Row } from 'antd'
import { AppointmentItem } from 'components'
import React from 'react'
import { IClientAppointment } from 'types'

type SearchAppointmentColumnProps = {
   data: IClientAppointment[]
   timeFormat: string
}

export default function SearchAppointmentColumn({ data, timeFormat }: SearchAppointmentColumnProps) {
   const count = (data || []).length

   return (
      <Col md={12} span={24}>
         <Text label="title.upcomingAppointments" bold>
            {` (${count})`}
         </Text>
         <Divider className="mb-0" />
         <Row>
            {data.map(appointment => (
               <AppointmentItem value={appointment} redirectOnClick timeFormat={timeFormat} />
            ))}
         </Row>
      </Col>
   )
}
