/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
   'login.title': 'Logi sisse',
   'label.email': 'Meiliaadress',
   'placeholder.email': 'nt. johndoe@gmail.com',
   'input.password.label': 'Salasõna',
   'input.password.placeholder': 'Salasõna',
   'login.btn': 'Logi sisse',
   'link.sign.in': 'Logi sisse',
   'label.password': 'sisesta oma salasõna',
   'login.forgot.password': 'Unustasite salasõna?',
   'login.no.account': 'Teil pole veel kontot?',
   'login.description': 'Kui teil on sisselogimisel probleeme, võite proovida parooli lähtestada',
   'register.title': 'Registreeri uus konto',
   'register.description': 'Looge uus konto Plandok kohtumiste planeerimise ja broneerimise tarkvara jaoks',
   'register.btn': 'Registreeri uus konto',
   'register.agreement.agree': 'Nõustun',
   'register.agreement.terms': 'Kodulehe tingimused',
   'register.agreement.policy': 'Privaatsuspoliitika',
   'register.agreement.and': 'ja',
   'register.agreement.partner': 'Partneri äritingimused',
   'register.have.account': 'Olete juba liitunud?',
   'register.validation.confirm': 'Meie teenuse kasutamiseks pead sa tutvuma meie tingimuste ja seda aktsepteerima',
   'link.sign.up': 'Registreeri',
   'text.alreadyHaveAccount': 'Olete juba liitunud?',
   'input.company.label': 'Ettevõte',
   'input.company.placeholder': 'Ettevõte',
   'input.country.label': 'Riik',
   'input.country.placeholder': 'Riigi nimi',
   'input.timeZone.label': 'Ajavöönd',
   'input.timeZone.placeholder': 'GMT +02.00',
   'input.currency.label': 'valuuta',
   'input.language.label': 'Keel (Language)',
   'input.language.placeholder': 'Inglise',
   'input.currency.placeholder': 'Euro - EUR',
   'reset.title': 'Taasta Salasõna',
   'reset.btn.text': 'Hangi uus salsõna',
   'reset.pwd.btn': 'Taasta Salasõna',
   'reset.no.password': 'Kas te ei soovi parooli lähtestada?',
   'reset.description': 'Palun sisesta siia oma meiliaadress ja me saadame sulle sinna juhised salasõna vahetamiseks.',
   'sidebar.calendar': 'Kalender',
   'sidebar.client': 'Kliendid',
   'sidebar.staff': 'Personal',
   'sidebar.service': 'Teenused',
   'sidebar.notification': 'Sõnumid',
   'sidebar.settings': 'Seaded',
   'sidebar.menu': 'Menüü',
   'sidebar.analytics': 'Analytics',
   'sidebar.language': 'Keel (Language)',
   'sidebar.logout': 'Logi välja',
   'user.menu.settings': 'Minu seaded',
   'user.menu.language': 'Muuda keelt (Language)',
   'user.menu.logout': 'Logi välja',
   'input.globalSearch.placeholder': 'Mida te otsite?',
   'input.globalSearch.description': 'Otsi kliendi nime, mobiiltelefoni, e-posti või broneeringu viite järgi',
   'title.upcomingAppointments': 'Järgmised Broneerigud',
   'calendar.day': 'Päev',
   'calendar.week': 'Nädal',
   'calendar.monday': 'Esmaspäev',
   'calendar.tuesday': 'Teisipäev',
   'calendar.wednesday': 'Kolmapäev',
   'calendar.thursday': 'Neljapäev',
   'calendar.friday': 'Reede',
   'calendar.saturday': 'Laupäev',
   'calendar.sunday': 'Pühapäev',
   'calendar.mon': 'Esm',
   'calendar.tue': 'Tei',
   'calendar.wed': 'Kol',
   'calendar.thu': 'Nel',
   'calendar.fri': 'Ree',
   'calendar.sat': 'Lau',
   'calendar.sun': 'Püh',
   'calendar.today': 'Täna',
   'calendar.january': 'Jaanuar',
   'calendar.february': 'Veebruar',
   'calendar.march': 'Märts',
   'calendar.april': 'Aprill',
   'calendar.may': 'Mai',
   'calendar.june': 'Juuni',
   'calendar.july': 'Juuli',
   'calendar.august': 'August',
   'calendar.september': 'Septembril',
   'calendar.october': 'Oktoober',
   'calendar.november': 'November',
   'calendar.december': 'Detsember',
   'calendar.jan': 'Jan',
   'calendar.feb': 'Veb',
   'calendar.mar': 'Mar',
   'calendar.apr': 'Apr',
   'calendar.may.short': 'Mai',
   'calendar.jun': 'Jun',
   'calendar.jul': 'Jul',
   'calendar.aug': 'Aug',
   'calendar.sept': 'Sept',
   'calendar.oct': 'Okt',
   'calendar.nov': 'Nov',
   'calendar.dec': 'Dets',
   'calendar.allStaff': 'Kõik töötajad',
   'calendar.workingStaff': 'Töötajad',
   'calendar.tooltip.time': 'Aeg',
   'calendar.tooltip.category': 'Kategooria',
   'calendar.tooltip.service': 'Teenindus',
   'calendar.tooltip.staff': 'Personal',
   'calendar.tooltip.price': 'Hind',
   'calendar.tooltip.repeating': 'Korduv',
   'btn.viewDetails': 'Vaata detaile',
   'calendar.tooltip.emptyCustomer': 'Astu sisse',
   'calendar.tooltip.bookedOnline': 'Online broneerimine',
   'calendar.print.description': 'Prindifunktsiooni kasutamiseks tuleb teie plaan uuendada. Uuendamiseks külastage',
   'calendar.print.appointmentsBetween.title': 'Kohtumised vahemikus',
   'calendar.print.appointmentsAnd.title': 'ja',
   'calendar.billing.title': 'Plaan ja arveldamine',
   'calendar.btn.print.title': 'Prindi',
   'datepicker.next.week': 'Järgmine nädal',
   'datepicker.in.two.weeks': '2 nädala pärast',
   'datepicker.in.three.weeks': '3 nädala pärast',
   'datepicker.in.four.weeks': '4 nädala pärast',
   'datepicker.in.five.weeks': '5 nädala pärast',
   'datepicker.in.six.weeks': '6 nädala pärast',
   'modal.splitAppointment.title': 'Poolita Broneering',
   'modal.splitAppointment.text':
      'See kohtumine on osa grupist, teisaldades selle teisele päevale, muudetakse see eraldi broneeringuks. Kas olete kindel, et soovite jätkata?',
   'blocktime.create.modal.title': 'Blokeeri Aeg',
   'blocktime.edit.modal.title': 'Muuda Blokeeritud Aega',
   'appointment.create.menu': 'Kohtumine',
   'blocktime.create.menu': 'Blokeeri Aeg',
   'appointment.new.title': 'Uus kohtumine',
   'input.startTime.label': 'Algusaeg',
   'input.endTime.label': 'Lõpuaeg',
   'input.startDate.label': 'Algus kuupäev',
   'input.endDate.label': 'Lõppkuupäev',
   'input.startDate.description.label': 'Valige alguskuupäev',
   'input.endDate.description.label': 'Valige kuupäev',
   'input.closedDatedDescription.placeholder': 'näiteks pühad',
   'input.service.label': 'Teenus',
   'input.services.label': 'teenused',
   'input.service.placeholder': 'Vali teenus',
   'input.staff.label': 'Personal',
   'input.staff.placeholder': 'Valige töötajad',
   'input.pickDateAndTime.title': 'Valige kuupäev ja kellaaeg',
   'input.pickDate.title': 'Valige kuupäev',
   'service.add.another': 'Lisa veel üks teenus',
   'input.appointmentNotes.label': 'Kohtumised märgib',
   'input.emailSubject.placeholder': 'Märkus oma ametisse',
   'input.appointmentNotes.placeholder': 'Lisa broneerigule märge (nähtav ainult töötajatele)',
   'input.searchClients.placeholder': 'Otsi Client',
   'input.searchService.placeholder': 'Otsing',
   'appointment.empty.list':
      'Kliendi lisamiseks kasutage otsingut või jätke tühjaks, kui soovite salvestada lihtsaks sisenemiseks.',
   'appointment.clients.empty.list': 'Klientide nimekiri on tühi.',
   'appointment.clients.addClient': 'Loo klient',
   'client.create.new': 'Loo uus klient',
   'client.choose': 'Vali kliendi',
   'client.menu.edit': 'Muuda kliendi andmed',
   'client.menu.remove': 'Eemalda ametisse',
   'appointment.btn.save': 'Salvesta',
   'appointment.vat': 'KÄIBEMAKS ({0}%)',
   'appointment.total': 'Kogusumma',
   'appointment.view.title': 'Vaata kohtumist',
   'appointment.status.btn': 'Kohtumise staatus',
   'appointment.btn.cancel': 'Kustuta',
   'btn.cancelAppointment': 'Kustuta',
   'modal.cancelAppointment.title': 'Kustuta',
   'title.clients': 'Kliendid',
   'client.create.title': 'Uus klient',
   'client.options.export.title': 'Ekspordi kliendid',
   'client.options.export.description': 'Laadige alla kliendiandmetega CSV',
   'client.options.import.title': 'Importige kliente',
   'client.export.premium.description': 'Ekspordifunktsiooni kasutamiseks tuleb teie plaan uuendada.',
   'client.export.premium.visit': 'Uuendamiseks külastage',
   'client.import.instructions.description': 'Klientide oma Plandoki kontole importimiseks järgige allolevaid juhiseid',
   'client.import.instructions.download': 'Laadige impordifail alla, kasutades allolevat nuppu',
   'client.import.instructions.copy': 'Kopeerige ja kleepige oma kliendi andmed faili',
   'client.import.instructions.email': 'Saatke see meile aadressil context@plandok.com',
   'client.import.instructions.account': 'ja impordime teie kontole',
   'table.clients.name': 'Nimi',
   'table.clients.phone': 'Lauatelefon',
   'table.clients.mobile': 'Lauatelefon',
   'table.clients.email': 'e-post',
   'table.clients.gender': 'Sugu',
   'client.page.title': 'Kliendi profiili',
   'btn.more.options': 'Veel valikuid',
   'btn.showAll': 'Näita kõike',
   'btn.collapse': 'Kitsenda',
   'btn.reschedule': 'Muuda aega',
   'title.edit.client': 'Muuda klienti',
   'menu.edit.client': 'Muuda klienti',
   'menu.delete.client': 'Kustuta klient',
   'title.mobilePhone': 'Mobiil',
   'title.telephoneNumber': 'Lauatelefon',
   'title.email': 'e-post',
   'title.address': 'Aadress',
   'title.dateOfBirth': 'Sünnipäev',
   'title.gender': 'Sugu',
   'client.statistics': 'Statistika',
   'client.totalSales': 'Müügi kogumaht',
   'client.totalBookings': 'Kokku tellimusi',
   'client.outstanding': 'Väljapaistev',
   'client.allBooking': 'Kõik broneeringud',
   'client.completed': 'lõpetatud',
   'client.cancelled': 'Tühistatud',
   'client.noShow': 'Ei tulnud kohale',
   'appointment.list.title': 'Kohtumised',
   'upcoming.list.title': 'Tulevad',
   'past.list.title': 'Toimunud',
   'modal.confirmClientDelete.text.0': 'Kas oled kindel, et soovid jäädavalt kliendi kustutada',
   'modal.confirmClientDelete.text.1': 'andmebaasist?',
   'input.date.label': 'Konkreetne Kuupäev',
   'input.date.placeholder': 'Vali kuupäev',
   'input.firstName.label': 'Eesnimi',
   'input.firstName.placeholder': 'nt. John',
   'input.lastName.label': 'Perekonnanimi',
   'input.lastName.placeholder': 'nt. Doe',
   'input.mobile.label': 'Mobiil',
   'input.mobile.placeholder': 'Sisestage mobiilinumber',
   'input.email.label': 'E-post',
   'input.email.placeholder': 'nt. johndoe@gmail.com',
   'input.title.label': 'Pealkiri',
   'input.title.placeholder': 'nt. Hambaarst',
   'input.notificationType.label': 'Saada teatis',
   'tab.personalInfo': 'Personaalne informatsioon',
   'input.gender.label': 'Sugu',
   'input.referal.label': 'Suunamisallikas',
   'input.referal.placeholder': 'Vali allikas',
   'input.birthDay.label': 'Sünnipäev',
   'input.month.placeholder': 'Kuu',
   'input.day.placeholder': 'Päev',
   'input.year.placeholder': 'Aasta',
   'input.clientsNotes.label': 'Kliendi märkused',
   'input.clientsNotes.placeholder': 'Sisestage kliendi märkused',
   'input.bookingDisplay.label': 'Kuva kõik tellimustele',
   'input.serviceDescription.label': 'Teenuse kirjeldus',
   'input.serviceDescription.placeholder': 'Lisa lühikirjeldus, mis on nähtav klientidele internetibroneeringutel',
   'input.isBookableOnline.label': 'Saadaval internetibroneeringutele',
   'service.onlineBookings.title': 'Online tellimustele',
   'tab.address': 'Aadress',
   'input.address.label': 'Aadress',
   'input.address.placeholder': 'Sisesta oma aadress',
   'input.city.label': 'linn',
   'input.city.placeholder': 'Sisestage linna',
   'input.zip.label': 'Postiindeks',
   'input.zip.placeholder': 'Sisesta Postiindeks',
   'btn.edit': 'Muutmine',
   'btn.cancel': 'Tühista',
   'btn.save': 'Salvesta',
   'btn.save.changes': 'Salvesta',
   'tabs.staffWorking.title': 'Töötunnid',
   'tabs.staffMembers.title': 'Meeskonna Liikmed',
   'tabs.userPermissions.title': 'Kasutajaõigused',
   'tabs.closedDates.title': 'Puhkepäevad',
   'btn.new.staff': 'Uued Töötajad',
   'table.staff.name': 'Nimi',
   'table.staff.mobileNumber': 'Mobiil',
   'table.staff.email': 'E-post',
   'table.staff.userPermission': 'Kasutaja luba',
   'table.staff.dataRange': 'Kuupäevavahemik',
   'table.staff.numberOfDays': 'Päevade arv',
   'table.staff.location': 'Asukoht',
   'table.staff.description': 'Kirjeldus',
   'table.appointmentsList.appointment': 'Kohtumine',
   'table.appointmentsList.client': 'Klient',
   'table.appointmentsList.service': 'Teenindus',
   'table.appointmentsList.date': 'Kuupäev',
   'table.appointmentsList.time': 'Aeg',
   'table.appointmentsList.duration': 'Kestus',
   'table.appointmentsList.location': 'Asukoht',
   'table.appointmentsList.staff': 'Personal',
   'table.appointmentsList.price': 'Hind',
   'table.appointmentsList.status': 'Olek',
   'table.appointmentsCancellations.ref': 'Ref',
   'table.appointmentsCancellations.client': 'Klient',
   'table.appointmentsCancellations.service': 'Teenindus',
   'table.appointmentsCancellations.scheduledDate': 'Planeeritud kuupäev',
   'table.appointmentsCancellations.cancelledDate': 'Tühistatud kuupäev',
   'table.appointmentsCancellations.cancelledBy': 'Tühistatud',
   'table.appointmentsCancellations.reason': 'Põhjus',
   'table.appointmentsCancellations.price': 'Hind',
   'table.appointmentsCancellations.totalCount': 'Koguarv',
   'table.clients.appointments': 'Kohtumised',
   'table.clients.noShows': 'Näitusi pole',
   'table.clients.totalSales': 'Müük kokku',
   'table.clients.added': 'Lisatud',
   'table.clients.daysAbsent': 'Puuduvad päevad',
   'table.clients.lastAppointment': 'Viimane kohtumine',
   'table.clients.lastLocation': 'Viimane asukoht',
   'table.appointmentsByService.serviceName': 'Teenuse nimi',
   'table.appointmentsByService.appointments': 'Kohtumised',
   'table.appointmentsByService.totalValue': 'Koguväärtus',
   'table.appointmentsByStaff.staffMember': 'Töötaja',
   'table.appointmentsByStaff.appointments': 'Kohtumised',
   'table.appointmentsByStaff.totalValue': 'Koguväärtus',
   'table.pagination.showing.title': 'Kuvatakse',
   'table.pagination.results.title': 'tulemused',
   'clients.duplicate.title': 'Leiti topeltkliendid.',
   'clients.duplicate.description': 'Sarnaseid kliente on võimalik ühendada.',
   'clients.merge.btn.title': 'Ühendage',
   'clients.merge.modal.title': 'Ühendage kliendid',
   'clients.merge.selectAll.title': 'Valige kõik duplikaadid',
   'clients.merge.duplicatesNotFound.title': 'Duplikaate ei leitud',
   'clients.merge.duplicatesNotFound.description': 'Ühitamiseks ei ole kliente saadaval',
   'clients.mergeConfirm.modal.title': 'Kinnitage ühendamine',
   'clients.mergeConfirm.modal.description':
      'Kas olete kindel, et soovite liita {0} duplikaati? See toiming on püsiv ja seda ei saa tagasi võtta.',
   'clients.mergeConfirm.checkbox.title': 'Saan aru, et klientide ühendamist ei saa tagasi võtta',
   'clients.mergeConfirm.btn.title': 'Kinnita ja ühenda',
   'clients.mergeConfirm.cancel.btn.title': 'Tühista',
   'clients.byNumber.btn.title': 'Telefoni teel {0}',
   'clients.byEmail.btn.title': 'Meili teel {0}',
   'clients.byName.btn.title': 'Nime järgi {0}',
   'title.newStaff': 'Uued Töötajad',
   'title.editStaff': 'Muuda töötajaid',
   'description.extraCharge': 'Lisades uusi töötajaid, lisandub lisatasu teie igakuisele tellimusele',
   'tabs.details': 'Detailid',
   'input.staffPicture.label': 'Pilt',
   'input.cropImage.title': 'Kärbi pilti',
   'input.cropImage.btn.apply.title': 'Rakenda',
   'input.stafffirstName.label': 'Eesnimi',
   'input.stafffirstName.placeholder': 'nt. John',
   'input.stafflastName.label': 'Perekonnanimi',
   'input.stafflastName.placeholder': 'nt. Doe',
   'input.stafmobile.label': 'Mobiil',
   'input.stafemail.label': 'E-post',
   'input.stafemail.placeholder': 'nt. johndoe@plandok.com',
   'input.permission.label': 'Kasutaja luba',
   'input.uploadProfile.label': 'Laadi profiilipilti',
   'input.changeProfile.label': 'Muuda profiili pilti',
   'input.notes.label': 'Märkused',
   'input.staffNotes.placeholder': 'Lisage privaatseid märkmeid, mida saab näha ainult töötajaseadetes (valikuline)',
   'input.blockTime.placeholder': 'nt. lõunakohtumine',
   'tabs.locations': 'Asukohad',
   'tabs.integrations': 'Integratsioonid',
   'input.staffLocations.description': 'Määrake kohad, kuhu see töötaja saab broneerida.',
   'input.multiLocation.description': 'Määrake kohad, kuhu see töötaja saab broneerida.',
   'tabs.services': 'Teenused',
   'input.staffServices.description': 'Määrake teenused, mida see töötaja saab osutada.',
   'input.multiServices.description': 'Määrake teenused, mida see töötaja saab osutada.',
   'input.shiftStart.label': 'Vahetuse algus',
   'input.shiftEnd.label': 'Vahetuse lõppu',
   'edit.staff.title.resendPassword': 'Parooli uuesti saatmine',
   'edit.staff.info.resendPassword':
      'Töötaja ei saanud parooliga meili? Parooli seadistusjuhistega meili uuesti saatmiseks klõpsake alloleval nupul.',
   'edit.staff.button.resendPassword': 'Saada parooliga meil uuesti',
   'btn.add.shift': 'Lisage veel üks vahetus',
   'break.time': '{0} pausiaeg',
   'input.repeat.label': 'Korda',
   'input.repeatWeekly.label': 'Iganädalane',
   'input.repeatDoNot.label': 'Ära korda',
   'input.endRepeat.label': 'Kordamine lõppeb',
   'input.endRepeatOngoing.label': 'Jätkuv',
   'input.endRepeatSpecificDate.label': 'Konkreetne Kuupäev',
   'input.endRepeatDate.placeholder': 'Vali kuupäev',
   'btn.deleteUpcomingShift': 'Kustuta eelseisvad vahetused',
   'btn.deleteThisShift': 'Kustuta ainult see vahetus',
   'btn.updateUpcomingShift': 'Uuenda eelseisvaid vahetusi',
   'btn.updateThisShift': 'Uuenda ainult seda vahetust',
   'modal.repeatShift.title': 'Vahetuse Kordamine',
   'form.error.uniqShifts': 'Vahetused peaksid olema ainulaadsed',
   'btn.add.new': 'Lisa uus',
   'btn.addNewService': 'Lisa uus teenus',
   'btn.newCategory': 'Uus kategooria',
   'btn.newServiceGroup': 'Uus kategooria',
   'btn.editCategory': 'Muuda kategooria',
   'btn.deleteCategory': 'Kustuta kategooria',
   'btn.downloadImportFile': 'Laadige alla impordifail',
   'serviceGroup.delete.success': 'Kategooria kustutamine õnnestus!',
   'service.new.title': 'Uus teenus',
   'service.edit.title': 'muuda teenus',
   'input.serviceName.label': 'Teenuse nimi',
   'input.serviceName.placeholder': 'nt. massaaž',
   'input.serviceCategory.label': 'Teenuse kategooria',
   'input.serviceCategory.placeholder': 'Vali teenuse kategooria',
   'input.price.label': 'Hind',
   'input.price.placeholder': '0',
   'input.duration.label': 'Kestus',
   'input.duration.placeholder': 'Kestus',
   'input.staffSelect.description': 'Vali teenust osutavad töötajad',
   'input.newCategoryName.label': 'Kategooria nimi',
   'input.newCategoryDescription.label': 'Kategooria kirjeldus',
   'input.newCategoryDescription.placeholder': 'Kirjeldage seda kategooriat, see kuvatakse veebipõhises broneeringus',
   'modal.newCategory.title': 'Uus kategooria',
   'modal.editCategory.title': 'Muuda kategooria',
   'input.chooseCategory.label': 'Vali kategooria värv:',
   'client.messages.title': 'Kliendisõnumid',
   'tabs.messagesLog': 'Lugemiseks logi',
   'tabs.messagesLog.description': 'Teie klientidele saadetud sõnumite loend',
   'tabs.messagesSettings': 'Seaded',
   'tabs.messagesSettings.description': 'Kohandage oma sõnumiseadeid vastavalt oma ettevõtte vajadustele',
   'table.header.timeSent': 'Saadetud',
   'table.header.client': 'Klient',
   'table.header.appointment': 'Kohtumine',
   'table.header.destination': 'Aadress',
   'table.header.type': 'Tüüp',
   'table.header.message': 'Sõnum',
   'table.header.status': 'Staatus',
   'table.header.cost': 'Maksumus',
   'modal.message.title': 'Vaata Sõnumit',
   'setup.page.title': 'Seadistamine',
   'title.accountSettings': 'Konto seaded',
   'title.accountSetup': 'Konto seadistamine',
   'title.accountSetup.description': 'Hallake selliseid sätteid nagu ettevõtte nimi ja ajavöönd.',
   'resources.list.title': 'Ressursid',
   'title.resources': 'Ressursid',
   'title.resources.description': 'Seadke broneeritavad vahendid, nagu näiteks toad, toolid või seadmed.',
   'title.locations': 'Asukohad',
   'title.locations.description': 'Hallake oma ettevõtte mitut müügikohta.',
   'calendar.settings.title': 'Kalendri Seaded',
   'calendar.btn.selectFromCalendar': 'Valige kalendrist',
   'title.calendarSettings': 'Kalendri Seaded',
   'title.calendarSettings.description': 'Reguleerige oma kalendri värviskeemi ja paigutust.',
   'title.clientSettings': 'Kliendi seaded',
   'title.onlineBookingSettings': 'Online broneeringu seaded',
   'title.salesSettings': 'Müügi seaded',
   'title.receiptSequencing': 'Laekumiste järjestamine',
   'title.receiptSequencing.description':
      'Konfigureerige klientidele väljastatud müügikviitungitel kuvatavaid üksikasju',
   'title.receiptTemplate': 'Kviitungi mall',
   'title.taxes.description': 'Müügikassas müüdavatele kaupadele kohaldatavate maksumäärade haldamine',
   'title.paymentTypes': 'Maksetüübid',
   'title.paymentTypes.description': 'Seadistage käsitsi maksetüübid kassas kasutamiseks',
   'title.discounts.description': 'Seadistage oma allahindlused',
   'title.receiptPrefix': 'Kviitung №. Esileht',
   'title.receiptNumber': 'Järgmise kviitungi number',
   'title.change': 'Muuda',
   'title.saleReceiptTemplate': 'Müügikviitungi mall',
   'description.saleReceiptTemplate': 'Kohandage oma klientidele väljastatud müügikviitungitel kuvatavat sisu',
   'setting.automaticallyPrint': 'Trükkige müügi lõpetamisel automaatselt kviitungid välja',
   'setting.showMobile': 'Näita kliendi mobiiltelefoni ja e-posti aadressi müügikviitungil',
   'setting.showAddress': 'Näita kliendi aadressi müügikviitungil',
   'title.receiptTitle': 'Kviitungi pealkiri',
   'title.referrals': 'Suunamisallikas',
   'title.referrals.description':
      'Seadistage enda poolt salvestatud seaded, et jälgida, mida klient Teie ettevõttest arvab.',
   'title.clientNotifications': 'Klient teatiste seaded',
   'title.clientNotifications.description': 'Halda selliseid seadeid nagu ettevõtte nimi ja ajavöönd',
   'cancelReason.list.title': 'Tühistamise Põhjused',
   'title.cancellationReasons': 'Tühistamise Põhjused',
   'title.cancellationReasons.description': 'Jälgige, miks kliendid ei tulnud broneeringu ajaks kohale.',
   'title.onlineBooking.description': 'Halda ekskursiooni online broneeringu kättesaadavust ja seadistusi',
   'title.pointOfSale': 'Müügipunkt',
   'discount.list.title': 'Allahindlused',
   'title.discountTypes': 'Soodustus tüübid',
   'title.discountTypes.description': 'Seadistage kassas kasutatavad allahindlustüübid.',
   'title.premiumFeature': 'Premium funktsioon',
   'company.details.title': 'Ettevõtte andmed',
   'input.businessName.label': 'Ärinimi',
   'input.businessName.placeholder': 'nt. Eaglewings',
   'input.description.label': 'Kirjeldus',
   'input.description.placeholder': 'Sisesta kirjeldus',
   'input.businessAddress.label': 'Aadress',
   'input.businessAddress.placeholder': 'Sisesta aadress',
   'input.website.label': 'Veebilehe aadress',
   'input.website.placeholder': 'Veebilehe aadress',
   'input.contactNumber.label': 'Kontaktnumber',
   'input.contactNumber.placeholder': 'Sisesta number',
   'input.businessTimeZone.label': 'Ajavöönd',
   'input.businessTimeZone.placeholder': 'GMT +02.00',
   'input.businessCountry.label': 'Riik',
   'input.businessCountry.placeholder': 'Vali riik',
   'input.timeFormat.label': 'Time format',
   'input.timeFormat.placeholder': '24 tundi',
   'table.header.resourceName': 'Allikud',
   'btn.new.resource': 'Uus Allik',
   'input.resourceName.label': 'Allika nimi',
   'input.resourceName.placeholder': 'Sisesta nimi',
   'input.resourceDescription.label': 'Kirjeldus',
   'input.resourceDescription.placeholder': 'Sisesta kirjeldus',
   'input.resourceLocation.placeholder': 'Asukoht nimi',
   'resourcesTooltip.location': 'NA?',
   'modal.newResource.title': 'Loo uus allik',
   'modal.editResource.title': 'Muuda Allikat',
   'table.header.locationName': 'Asukoht',
   'table.header.locatonAddress': 'Aadress',
   'table.header.location.Phone': 'Telefoninumber',
   'modal.newLocation.title': 'Uus Asukoht',
   'modal.editLocation.title': 'Muuda Asukohta',
   'btn.new.location': 'Uus Asukoht',
   'btn.addExtraLocation': 'Lisa täiendav asukoht',
   'input.locationName.label': 'Asukoha nimi',
   'input.locationName.placeholder': 'Sisesta Asukoha Nimi',
   'input.profile.locationName.placeholder': 'Näiteks Barber Salon',
   'input.slug.label': 'Slug',
   'input.locationTips.label': 'Asukohanipid',
   'input.slug.explanation': 'Nähtav teie asukoha URL',
   'input.contactEmail.label': 'E-post',
   'input.locationAddress.label': 'Aadress',
   'input.locationAddress.placeholder': 'Sisesta Location aadress',
   'tooltip.contactEmail': 'Kliendi kohtumiste teated lähevad sellele e-kirjale.',
   'input.appointmentColors.label': 'Broneeringu Värv',
   'input.colorBy.service': 'Teenindusgrupi Järgi',
   'input.colorBy.employee': 'Töötaja Järgi',
   'input.colorBy.appointment': 'Broneeringu Oleku Järgi',
   'input.timeSlot.label': 'Ajavahemik',
   'input.defaultViewType.label': 'Vaikevaade',
   'input.calendarTimeRange.label': 'Kalendri ajavahemik',
   'input.calendarTimeRange.allTimes.option': 'Kõik ajad (00:00 - 24:00)',
   'input.calendarTimeRange.onlyWorkingHours.option': 'Ainult tööaeg',
   'input.calendarTimeRange.customRange.option': 'Kohandatud vahemik',
   'input.weekStart.label': 'Nädala alustamise päev',
   'table.header.refSourcetitle': 'Viiteallikas',
   'table.header.refSourceAddDate': 'Lisamise Kuupäev',
   'btn.new.referral': 'Uus Viiteallikas',
   'input.nameRefSource.label': 'Viiteallika Nimi',
   'input.nameRefSource.placeholder': 'nt. reklaam Facebookis',
   'modal.newRefSource.title': 'Uus Viiteallikas',
   'modal.editRefSource.title': 'Muuda Viiteallikat',
   'input.sendBy.label': 'Saada',
   'input.emailSubject.label': 'E-posti pealkiri',
   'input.emailTemplate.label': 'E-maili vorm',
   'input.emailTemplate.placeholder': 'Sisesta e-kirja vorm',
   'input.subject.label': 'E-posti pealkiri',
   'input.subject.placeholder': 'Sisesta e-kirja pealkiri',
   'title.messagingSettings.label': 'Sõnumi Seaded',
   'title.messagingSettings.description':
      'Siin olevad sõnumid saadetakse klientidele automaatselt. Kõigile loodud sõnumitele on lihtne juurdepääs sõnumite lehel (Teie peamenüüs). Reguleerige sõnumite saatmise sätteid ja muutke teksti isikupärasemaks. Kasutage allpool olevaid silte, et lisada kohtumiste üksikasjad sõnumitesse',
   'client.first.name': 'CLIENT_FIRST_NAME',
   'client.last.name': 'CLIENT_LAST_NAME',
   'staff.first.name': 'STAFF_FIRST_NAME',
   'staff.last.name': 'STAFF_LAST_NAME',
   'booking.date.time': 'BOOKING_DATE_TIME',
   'booking.date': 'BOOKING_DATE',
   'booking.time': 'BOOKING_TIME',
   'service.name': 'SERVICE_NAME',
   'business.name': 'BUSINESS_NAME',
   'business.phone': 'BUSINESS_PHONE',
   'business.address': 'BUSINESS_ADDRESS',
   'business.email': 'BUSINESS_EMAIL',
   'location.name': 'LOCATION_NAME',
   'location.phone': 'LOCATION_PHONE',
   'location.address': 'LOCATION_ADDRESS',
   'location.email': 'LOCATION_EMAIL',
   'tabs.reminders.title': 'Meeldetuletused',
   'input.enableReminder.label': 'Luba Meeldetuletussõnumid',
   'input.enableReminder.description': 'Saadab automaatselt klientidele enne eelseisvat kohtumist',
   'input.reminder.title': 'Meeldetuletus {0}',
   'input.message.title': 'Sõnum {0}',
   'input.reminder.description': 'Kanalid ja saatmisaeg',
   'input.reminder.channels': 'Valige kanalid, kuhu see sõnum saadetakse',
   'input.reminderBefore.label': 'Meeldetuletused saadetakse enne',
   'input.smsSender.label': 'SMS-Saatja ID',
   'input.messageSender.label': 'Sõnumi saatja ID',
   'input.smsSender.placeholder': 'Sisesta SMS-Saatja ID',
   'input.smsTemplate.label': 'SMS-i vorm',
   'input.messageTemplate.label': 'Sõnumi mall',
   'input.smsTemplate.placeholder': 'Sisesta SMS-i vorm',
   'input.whatsAppSender.placeholder': 'Plandok',
   'reminder.whatsApp.notificate':
      'WhatsApp sõnumi mall on praegu piirangute tõttu mitte-redigeeritav. Kuid me töötame aktiivselt selle nimel, et muuta see täielikult redigeeritavaks.',
   'reminder.charactersCount': 'Tegelaste arv:',
   'reminder.pricing': 'Hinnakujundus',
   'modal.pricing.description':
      'Eri riikides on erinevad hinnad. Meie pakume oma partneritelt parimat võimalikku hinda. See võib erineda.',
   'modal.pricing.subDescription': 'Tasusid kohaldatakse pärast sõnumite saatmist ja lisatakse eelseisvale arvele.',
   'modal.pricing.counterDescription':
      'Standardse SMS-i pikkus on piiratud 160 tähemärgiga ja 70 tähemärki erimärkidega sõnumite puhul.',
   'modal.pricing.learnMore': 'Lisateave',
   'modal.pricing.priceRates': 'Hinnatariifid',
   'modal.pricing.messagePrice': '1 sõnumi hind:',
   'modal.sendTestMessageByNumber.title': 'Saatke sellele numbrile testsõnum:',
   'modal.sendTestMessageByNumber.description': 'Tasud kehtivad nagu tavalise sõnumi puhul.',
   'modal.sendTestMessageByEmail.title': 'Saada testsõnum sellele e-posti aadressile:',
   'modal.sendTestMessageByEmail.placeholder': 'Palun sisestage e-posti aadress',
   'btn.send': 'Saada',
   'reminder.sendTestMessage': 'Saada testsõnum',
   'button.reminder.addTag': 'Lisa silt',
   'button.reminder.addAnotherReminder': 'LISAGE VEEL MEELDETULETUS',
   'button.message.addAnotherMessage': 'LISADA VEEL ÜKS SÕNUM',
   'button.reminder.upgrade': 'Juurdepääsuks uuendage',
   'label.emailSettings': 'Email seaded',
   'label.SMSSettings': 'SMS seaded',
   'label.whatsAppSettings': 'WhatsApp seaded',
   'tooltip.sender.0': 'See kuvatakse SMS-saatja nimega',
   'tooltip.sender.1': 'Maksimaalselt 11 tähemärki',
   'tooltip.sender.2': 'Mõnes riigis ei pruugi see töötada',
   'tooltip.sender.3': 'Ainult ladina tähed või numbrid',
   'tabs.confirmations.title': 'Kinnitus',
   'btn.appBookings': 'Rakenduse broneeringud',
   'btn.onlineBookings': 'Online broneeringud',
   'input.enableAppConfirmation.label': 'Rakenduses olevate broneeringute kinnituste lubamine',
   'input.enableOnlineConfirmation.label': 'Online broneeringute kinnituste lubamine',
   'input.enableConfirmationApp.description': 'Saadab automaatselt klientidele, kui neile on broneeritud kohtumine',
   'input.enableConfirmationOnline.description': 'Saadab automaatselt klientidele, kui nad broneerivad kohtumise',
   'messageSetup.header': 'Sõnumite seadistamine',
   'channels.subHeader': 'Kanalid',
   'tabs.reschedules.title': 'Ajakava muutmine',
   'input.enableReschedule.label': 'Luba ajakava muutmine',
   'input.enableReschedule.description': 'Saadab automaatselt klientidele, kui nende kohtumise algusaega muudetakse',
   'tabs.cancellations.title': 'Tühistamine',
   'input.enableCancellation.label': 'Luba tühistamisteated',
   'input.enableCancellations.description': 'Saadab automaatselt klientidele, kui nende kohtumine tühistatakse',
   'tabs.thanks.title': 'Aitäh',
   'input.enableThank.label': 'Luba Tänusõnumid',
   'input.enableThankyou.description': 'Sõnum saadetakse kliendile, kui kohtumise olek on märgitud kui valmis',
   'tabs.lastVisit.title': 'Viimane külastus',
   'input.enableLastVisit.label': 'Luba Viimase Külastuse Teated',
   'input.enableLastVisit.description':
      'Saadab automaatselt klientidele, kui nad pole Teie ettevõtet kokkulepitud päevadel külastanud',
   'table.cancelReason.name': 'Tühistamise Põhjused',
   'table.cancelReason.addDate': 'Kuupäev lisatud',
   'btn.new.cancelReason': 'Uus Tühistamise Põhjus',
   'modal.newCancelReason.title': 'Uus Tühistamise Põhjus',
   'modal.editCancelReason.title': 'Muuda Tühistamise Põhjust',
   'input.cancelReason.title': 'Tühistamise Põhjuse nimi',
   'input.cancelReason.placeholder': 'nt. Klient ei olnud vaba',
   'table.discounts.name': 'Allahindlused',
   'table.discounts.value': 'Väärtus',
   'table.discounts.addDate': 'Loomise kuupäev',
   'input.discountName.label': 'Allahindluse nimi',
   'input.discountName.placeholder': 'nt. Vanurid',
   'input.discountValue.label': 'Allahindluse Väärtus',
   'btn.new.discount': 'Uus allahindlus',
   'input.discountPercentage.label': '% Protsent',
   'input.discountSum.label': 'Kogus',
   'modal.newDiscount.title': 'Uus Allahindlustüüp',
   'modal.editDiscount.title': 'Muuda Allahindlust',
   'modal.deleteAccount.title': 'Konto kustutamine',
   'modal.deleteAccount.description':
      'Konto kustutamine on pöördumatu protsess ja kõik teie andmed eemaldatakse igaveseks.',
   'modal.deleteAccount.successMessage': 'Teie konto on edukalt kustutatud.',
   'title.mySettings': 'Minu seaded',
   'title.personalDetails': 'Isiklikud Andmed',
   'title.personalDetails.description':
      'Sisestage oma nimi ja kontaktteaved, sisselogimiseks kasutatakse siin sisestatud e-posti aadressi',
   'btn.continueWithGoogle.title': "Jätka Google'iga",
   'btn.continueWithFacebook.title': 'Jätka Facebookiga',
   'notification.googleAccountUnlinked': "Google'i konto lahutatud",
   'notification.googleAccountLinked': 'Google konto seotud',
   'notification.facebookAccountUnlinked': 'Facebooki konto ühendamata',
   'notification.facebookAccountLinked': 'Facebooki konto seotud',
   'btn.linkAccount': 'Konto linkimine',
   'btn.unlinkAccount': 'Unlink konto',
   'btn.deleteYourAccount': 'Kustuta oma konto',
   'input.myName.label': 'Eesnimi',
   'input.myName.placeholder': 'nt. John',
   'input.mylastName.label': 'Perekonnanimi',
   'input.mylastName.placeholder': 'nt. Doe',
   'title.changePassword': 'Muuda salasõna',
   'title.changePassword.description':
      'Uuenduse tegemiseks sisestage oma olemasolev parool ja seejärel uus parool. Kui te ei tea oma olemasolevat parooli, saate välja logida ja kasutada sisselogimislehel olevat linki Lähtesta parool.',
   'input.currentPassword.label': 'Praegune salasõna',
   'label.currentPassword.placeholder': 'Sisesta Praegune salasõna',
   'input.newPassword.label': 'Uus parool',
   'input.newPassword.placeholder': 'Sisesta Uus Parool',
   'input.verifyPassword.label': 'Korda Parooli',
   'input.verifyPassword.placeholder': 'Korda Uut Parooli',
   'btn.confirmDelete': 'Jah, kustuta nüüd',
   'btn.confirm': 'Kinnita',
   'btn.confirmBook': 'Kinnitage ja broneerige',
   'btn.confirmPay': 'Kinnitage ja makske',
   'btn.delete': 'Kustuta',
   'btn.close': 'Sulge',
   'validation.required': 'nõutud',
   'validation.email.format': 'Vale e-posti formaat',
   'validation.url.format': 'Vale url-vorming, peab algama https:// või http://',
   'validation.password.length': 'Parool peab olema vähemalt 7 tähemärki',
   'validation.password.letters': 'Parool peaks sisaldama numbrit ja tähte',
   'validation.startTime': 'Algusaeg ei saa olla hilisem või sama kui lõpuaeg',
   'validation.endTime': 'Lõppaeg ei saa olla varasem või sama kui algusaeg',
   'tooltip.password.title': 'Parool peab:',
   'tooltip.password.characters': 'Olema vähemalt 7 tähemärki',
   'tooltip.password.number': 'Sisaldama ühte numbrit',
   'tooltip.password.letter': 'Sisaldama ühte tähte',
   'tooltip.location': 'Asukohavihje',
   'footer.support.center': 'Vajad abi? Võta meiega ühendust!',
   'not.found': 'Ei leitud',
   'input.cancelReason.label': 'Tühistamise Põhjus',
   'input.location.label': 'Asukoht',
   'input.location.placeholder': 'Asukoht nimi',
   'input.name.label': 'Nimi',
   'title.recentlyAddedClients': 'Kliendid (Viimati Lisatud)',
   'title.calendarView': 'Kalendrivaade',
   'text.manageProfile': 'Halda oma kasutajaprofiili',
   'modal.language.title': 'Vali keel',
   'modal.language.placeholder': 'Otsingu keel',
   'dropdown.access.medium': 'Kõrge',
   'dropdown.access.high': 'Keskmine',
   'dropdown.access.low': 'Madal',
   'dropdown.access.basic': 'Tavaline',
   'dropdown.access.no': 'Juurdepääs puudub',
   'dropdown.access.owner': 'Omanik',
   'button.select.all': 'Vali Kõik',
   'button.selected': 'Valitud',
   'dropdown.gender.unknown': 'Tundmatu',
   'dropdown.gender.male': 'Mees',
   'dropdown.gender.female': 'Naine',
   'dropdown.notificationBy.none': 'Ära saada',
   'dropdown.notificationBy.sms': 'SMS',
   'dropdown.notificationBy.email': 'e-post',
   'dropdown.notificationBy.combined': 'SMS ja e-post',
   'notification.success.login': 'Sisselogimine õnnestus!',
   'notification.success.register': 'Registreerimine õnnestus!',
   'dropdown.time.minutes': 'minutit',
   'dropdown.repeat.no': 'Ära korda',
   'dropdown.repeat.weekly': 'Iganädalane',
   'dropdown.endRepeat.ongoing': 'Jätkuv',
   'dropdown.endRepeat.weekly': 'Konkreetne Kuupäev',
   'dropdown.time.appointment.immediately': 'Kohe',
   'dropdown.time.appointment.upTo1Hour': 'Kuni 1 tund',
   'dropdown.time.appointment.upTo2Hours': 'Kuni 2 tundi',
   'dropdown.time.appointment.upTo3Hours': 'Kuni 3 tundi',
   'dropdown.time.appointment.upTo4Hours': 'Kuni 4 tundi',
   'dropdown.time.appointment.upTo5Hours': 'Kuni 5 tundi',
   'dropdown.time.appointment.upTo6Hours': 'Kuni 6 tundi',
   'dropdown.time.appointment.upTo7Hours': 'Kuni 7 tundi',
   'dropdown.time.appointment.upTo8Hours': 'Kuni 8 tundi',
   'dropdown.time.appointment.upTo9Hours': 'Kuni 9 tundi',
   'dropdown.time.appointment.upTo10Hours': 'Kuni 10 tundi',
   'dropdown.time.appointment.upTo11Hours': 'Kuni 11 tundi',
   'dropdown.time.appointment.upTo12Hours': 'Kuni 12 tundi',
   'dropdown.time.appointment.upTo13Hours': 'Kuni 13 tundi',
   'dropdown.time.appointment.upTo14Hours': 'Kuni 14 tundi',
   'dropdown.time.appointment.upTo15Hours': 'Kuni 15 tundi',
   'dropdown.time.appointment.upTo16Hours': 'Kuni 16 tundi',
   'dropdown.time.appointment.upTo17Hours': 'Kuni 17 tundi',
   'dropdown.time.appointment.upTo18Hours': 'Kuni 18 tundi',
   'dropdown.time.appointment.upTo19Hours': 'Kuni 19 tundi',
   'dropdown.time.appointment.upTo20Hours': 'Kuni 20 tundi',
   'dropdown.time.appointment.upTo21Hours': 'Kuni 21 tundi',
   'dropdown.time.appointment.upTo22Hours': 'Kuni 22 tundi',
   'dropdown.time.appointment.upTo23Hours': 'Kuni 23 tundi',
   'dropdown.time.appointment.upTo24Hours': 'Kuni 1 päev',
   'dropdown.time.appointment.upTo48Hours': 'Kuni 2 päeva',
   'dropdown.time.appointment.upTo3days': 'Kuni 3 päeva',
   'dropdown.time.appointment.upTo4days': 'Kuni 4 päeva',
   'dropdown.time.appointment.upTo5days': 'Kuni 5 päeva',
   'dropdown.time.appointment.upTo6days': 'Kuni 6 päeva',
   'dropdown.time.appointment.upTo1week': 'Kuni 1 nädal',
   'dropdown.time.appointment.upTo2weeks': 'Kuni 2 nädalat',
   'dropdown.time.appointment.upTo15Min': 'Kuni 15 minutit',
   'dropdown.time.appointment.upTo30Min': 'Kuni 30 minutit',
   'dropdown.time.appointment.upTo1Month': 'Kuni 1 kuu',
   'dropdown.time.appointment.upTo2Months': 'Kuni 2 kuud',
   'dropdown.time.appointment.upTo3Months': 'Kuni 3 kuud',
   'dropdown.time.appointment.upTo4Months': 'Kuni 4 kuud',
   'dropdown.time.appointment.upTo5Months': 'Kuni 5 kuud',
   'dropdown.time.appointment.upTo6Months': 'Kuni 6 kuud',
   'dropdown.time.appointment.interval5Min': '5 minutit',
   'dropdown.time.appointment.interval10Min': '10 minutit',
   'dropdown.time.appointment.interval15Min': '15 minutit',
   'dropdown.time.appointment.interval20Min': '20 minutit',
   'dropdown.time.appointment.interval30Min': '30 minutit',
   'dropdown.time.appointment.interval40Min': '40 minutit',
   'dropdown.time.appointment.interval45Min': '45 minutit',
   'dropdown.time.appointment.interval50Min': '50 minutit',
   'dropdown.time.appointment.interval60Min': '60 minutit',
   'dropdown.time.appointment.interval75Min': '75 minutit',
   'dropdown.time.appointment.interval90Min': '90 minutit',
   'dropdown.time.appointment.interval120Min': '120 minutit',
   'dropdown.time.appointment.interval150Min': '150 minutit',
   'dropdown.time.appointment.interval180Min': '180 minutit',
   'dropdown.time.appointment.interval240Min': '240 minutit',
   'dropdown.time.appointment.anyTime': 'Iga kord',
   'dropdown.time.appointment.disabled': 'Välja lülitatud',
   'dropdown.time.appointment.doesntRepeat': 'Ei korda',
   'dropdown.time.appointment.daily': 'Igapäevane',
   'dropdown.time.appointment.every2Days': 'Iga 2 päeva järel',
   'dropdown.time.appointment.every3Days': 'Iga 3 päeva järel',
   'dropdown.time.appointment.every4Days': 'Iga 4 päeva järel',
   'dropdown.time.appointment.every5Days': 'Iga 5 päeva järel',
   'dropdown.time.appointment.every6Days': 'Iga 6 päeva järel',
   'dropdown.time.appointment.every7Days': 'Iga 7 päeva järel',
   'dropdown.time.appointment.weekly': 'Iganädalane',
   'dropdown.time.appointment.every2weeks': 'Iga 2 nädala järel',
   'dropdown.time.appointment.every3weeks': 'Iga 3 nädala järel',
   'dropdown.time.appointment.every4weeks': 'Iga 4 nädala järel',
   'dropdown.time.appointment.every5weeks': 'Iga 5 nädala järel',
   'dropdown.time.appointment.every6weeks': 'Iga 6 nädala järel',
   'dropdown.time.appointment.every7weeks': 'Iga 7 nädala järel',
   'dropdown.time.appointment.every8weeks': 'Iga 8 nädala järel',
   'dropdown.time.appointment.every9weeks': 'Iga 9 nädala järel',
   'dropdown.time.appointment.every10weeks': 'Iga 10 nädala järel',
   'dropdown.time.appointment.monthly': 'Igakuine',
   'dropdown.time.appointment.every2months': 'Iga 2 kuu tagant',
   'dropdown.time.appointment.every3months': 'Iga 3 kuu tagant',
   'dropdown.time.appointment.every4months': 'Iga 4 kuu tagant',
   'dropdown.time.appointment.every5months': 'Iga 5 kuu tagant',
   'dropdown.time.appointment.every6months': 'Iga 6 kuu tagant',
   'dropdown.time.appointment.every7months': 'Iga 7 kuu tagant',
   'dropdown.time.appointment.every8months': 'Iga 8 kuu tagant',
   'dropdown.time.appointment.every9months': 'Iga 9 kuu tagant',
   'dropdown.time.appointment.every10months': 'Iga 10 kuu tagant',
   'dropdown.time.appointment.every11months': 'Iga 11 kuu tagant',
   'dropdown.time.appointment.after2times': 'Pärast 2 korda',
   'dropdown.time.appointment.after3times': 'Pärast 3 korda',
   'dropdown.time.appointment.after4times': 'Pärast 4 korda',
   'dropdown.time.appointment.after5times': 'Pärast 5 korda',
   'dropdown.time.appointment.after6times': 'Pärast 6 korda',
   'dropdown.time.appointment.after7times': 'Pärast 7 korda',
   'dropdown.time.appointment.after8times': 'Pärast 8 korda',
   'dropdown.time.appointment.after9times': 'Pärast 9 korda',
   'dropdown.time.appointment.after10times': 'Pärast 10 korda',
   'dropdown.time.appointment.after11times': 'Pärast 11 korda',
   'dropdown.time.appointment.after12times': 'Pärast 12 korda',
   'dropdown.time.appointment.after13times': 'Pärast 13 korda',
   'dropdown.time.appointment.after14times': 'Pärast 14 korda',
   'dropdown.time.appointment.after15times': 'Pärast 15 korda',
   'dropdown.time.appointment.after20times': 'Pärast 20 korda',
   'dropdown.time.appointment.after25times': 'Pärast 25 korda',
   'dropdown.time.appointment.after30times': 'Pärast 30 korda',
   'dropdown.time.appointment.specificDate': 'Konkreetne kuupäev',
   'modal.createHours.title': 'Loo {0} tundi',
   'modal.editHours.title': 'Muuda {0} tundi',
   'modal.deleteItem.confirm.0': 'Kas oled kindel, et soovid jäädavalt',
   'modal.deleteItem.confirm.1': 'kustutada',
   'modal.deleteItem.confirm.2': 'andmebaasist?',
   'modal.entity.category': 'Kategooria',
   'modal.entity.referal': 'Suunamisallikas',
   'modal.entity.resource': 'Ressurss',
   'modal.entity.location': 'Asukoht',
   'modal.deleteReminder.title': 'Kustuta meeldetuletus',
   'modal.deleteMessage.title': 'Sõnumi kustutamine',
   'modal.deleteReminder.description': 'Kas olete kindel, et soovite selle meeldetuletuse kustutada ?',
   'modal.deleteMessage.description': 'Kas olete kindel, et soovite selle sõnumi kustutada ?',
   'modal.deleteReminder.confirm': 'Jah, kustutage',
   'modal.deletePaymentMethod.title': 'Kustuta makseviis',
   'modal.deletePaymentMethod.description': 'See makseviis kustutatakse lõplikult',
   'closedDate.create.modal.title': 'Lisage puhkepäevad',
   'closedDate.edit.modal.title': 'Muuda puhkepäevi',
   'staff.access.owner': 'Omanik',
   'staff.access.high': 'Kõrge',
   'staff.access.medium': 'Keskmine',
   'staff.access.low': 'Madal',
   'staff.access.basic': 'Tavaline',
   'staff.access.no_access': 'Juurdepääs puudub',
   'staff.create.title': 'Lisage puhkepäevad',
   'staff.emptyState.title': 'Planeeritud töötajaid pole.',
   'staff.emptyState.description': 'Tööaegade lisamiseks vaadake jaotist Töötajad.',
   'staff.viewStaffMembers.button': 'Vaadake töötajaid',
   'staff.picture.changePhoto': 'Muuda fotot',
   'staff.picture.removePhoto': 'Eemalda foto',
   'input.clientSearch.placeholder': 'Otsi nime, e-posti või mobiili järgi',
   'input.blockedDate.label': 'Kuupäev',
   'appointment.notes.title': 'Broneeringumärkused',
   'appointment.history.title': 'Nimetamine ajalugu',
   'appointment.history.by.title': 'poolt {0}',
   'appointment.log.created.label': 'Loodud',
   'appointment.log.updated.label': 'Värskendas',
   'appointment.log.rescheduled.label': 'Edastatud poolt',
   'appointment.log.cancelled.label': 'Tühistatud',
   'appointment.log.changed.label': 'Muutunud staatuse',
   'appointment.notificationLog.rescheduling': 'Ümberajastamise teatis saadeti',
   'appointment.notificationLog.cancellation': 'Tühistamise teade saadeti',
   'appointment.notificationLog.confirmation': 'Kinnitusteade saadetud',
   'appointment.notificationLog.reminder': 'Meeldetuletuse teatis saadeti',
   'appointment.notificationLog.thankYou': 'Tänuteade on saadetud',
   'appointment.notificationLog.lastVisit': 'Viimase külastuse teatis saadeti',
   'state.cancelReason.title': 'Seadista tühistamise põhjused',
   'state.cancelReason.description': 'Tühistamise põhjused aitavad sul jälgida, miks kliendid ei tulnud kohale.',
   'state.cancelReason.button': 'Lisa tühistamise põhjus',
   'state.resources.title': 'Halda oma ressursse',
   'state.resources.description':
      'Äri on edukam, kui olete käepärastest ressurssidest teadlik. Jälgige ruume, mööblit või muid seadmeid, et tagada tõrgeteta teenused ja vähem probleeme.',
   'state.resources.button': 'Lisa ressurss',
   'state.referralSource.title': 'Halda soovitusallikaid',
   'state.referralSource.description':
      'Soovitusallikas näitab, kust kliendid on pärit. Jälgige neid ja kasutage seda teavet oma ettevõtte nähtavuse ja sissetuleku suurendamiseks.',
   'state.referralSource.button': 'Lisa soovitusallikaid',
   'state.locations.title': 'Sea oma asukohad',
   'state.locations.description':
      'Siin saad hallata oma ettevõtte mitut väljundit, seada töötajatele asukoha järgi erinevaid ajakavasid ja teha eraldi kalendri igaühe jaoks.',
   'state.staff.title': 'Halda oma töötajaid',
   'state.staff.description':
      'Looge oma töötajatele profiile, et saaksite lisada ja muuta nende ajakavasid, jälgides ka nende broneeringuid ja töötunde.',
   'state.messageLog.title': 'Jälgi sõnumeid',
   'state.messageLog.description':
      'Sõnumid tuletavad klientidele meelde eelseisvaid kohtumisi või hoiavad neid nendega kursis. Siin saad jälgida sõnumeid ja nende olekut. Automatiseeritud teadete konfigureerimiseks mine',
   'state.messageLog.link': 'Teadete seaded.',
   'state.clients.title': 'Halda oma kliente',
   'state.clients.description':
      'Oma klientide eest hoolitsemine algab veendumisest, et nende profiilid sisaldavad kogu vajalikku teavet sujuva kogemuse saamiseks ja et oleks võimalus nendega ühendust võtta.',
   'state.clients.button': 'Lisa klient',
   'state.services.title': 'Määra grupid ja teenused',
   'state.services.description':
      'Grupid on hädavajalikud, kuna need võimaldavad grupeerida firma pakutavaid sarnaseid teenuseid. Lisaks muudavad need sinu kalendri selgemaks ja hõlpsasti juurdepääsetavaks!',
   'state.services.button': 'Lisa teenusegrupp',
   'state.calendar.title': 'Puudub plaanilitud töötaja',
   'state.calendar.description': 'Töötaja ajakava määramiseks vaata',
   'state.calendar.button': 'Töötajate Osa',
   'state.closedDates.title': 'Suletud kuupäevad',
   'state.closedDates.description':
      'Nimekiri kuupäevad oma äri on suletud riigipühadel, hooldus või mõnel muul põhjusel. Kliendid ei saa panna internetibroneeringutele nende päevade jooksul.',
   'state.closedDates.button': 'Lisage puhkepäevad',
   'title.billingPlan': 'Plaan ja Arved',
   'btn.upgrade': 'Uuenda',
   'header.usage': 'KASUTAMINE',
   'header.dropdown.subscription.plan': '{0} Plaan',
   'header.dropdown.subscription.staff': '({0} Töötajad)',
   'header.dropdown.notifications.description': '{0} sõnumid',
   'header.paymentDetails': 'Vaata makse üksikasju',
   'title.billingPlan.description':
      'Halda oma kontosid. Planeeri ja vaata arvete üksikasju, vaata tehinguid ja lae arveid alla.',
   'plan.free.title': 'Alustaja',
   'plan.individual.title': 'Individuaalne (ainult 1 töötaja)',
   'plan.business.title': 'Äri',
   'btn.upgradeNow': 'Uuenda',
   'btn.getStarted': 'Alusta',
   'btn.currentPlan': 'Praegune Pakett',
   'plan.free.option.0': '1 Asukoht',
   'plan.free.option.1': 'Kuni 5 töötajat',
   'plan.free.option.2': 'Piiramatu arv kohtumisi',
   'plan.free.option.3': 'Piiramatu arv kliente',
   'plan.free.option.4': 'Piiramatu arv online-broneeringuid',
   'plan.free.option.5': 'E-posti teated',
   'plan.premium.option.0': 'Piiramatu arv asukohti',
   'plan.premium.option.1': 'Piiramatu arv kohtumisi',
   'plan.premium.option.2': 'Piiramatu arv kliente',
   'plan.premium.option.3': 'Piiramatu arv online-broneeringuid',
   'plan.premium.option.4': 'Korduvad kohtumised',
   'plan.premium.option.5': 'E-posti teated',
   'plan.premium.option.6': 'SMS-teavitused*',
   'plan.premium.option.7': 'Whatsapi märguanded*',
   'plan.premium.option.8': 'Piiramatu analüüs',
   'plan.premium.option.9': 'Piiramatu arv aruandeid',
   'plan.premium.option.10': 'Prindi ajakavad',
   'plan.premium.option.11': 'Kliendi eksport',
   'plan.premium.option.12': 'Prioriteetne toetus',
   'plan.premium.option.13': 'Plandoki kaubamärgid eemaldati meilidest (tasuta meilid, mida toetab Plandok)',
   'plan.premium.costsInfo': '*SMS/WhatsApp kulud ei sisaldu paketis, rakenduvad lisatasud.',
   'plan.premium.activeUntilDate': 'Aktiivne kuni {0}',
   'period.perMonth': 'kuus (ilma käibemaksuta)',
   'plan.premium.description': 'Hind tõuseb, kui lisada rohkem töötajaid.',
   'plan.premium.viewPricing': 'Vaata hinnakujundust',
   'plan.premium.numberStuff': 'Teie praegune töötajate arv: {0}',
   'premium.membership': 'Premium-liikmesuse hind võib olenevalt teie töötajate arvust erineda.',
   'premium.price.perMonth': 'Kuu lisatasu hind',
   'pricing.calculated': 'Hind arvutatakse järgmiselt:',
   'pricing.calculator': 'Hinnakalkulaator',
   'pricing.numberStuff': 'Töötajate arv',
   'pricing.extra.member': '{0} iga lisaliikme jaoks',
   'modal.title.pricing': 'Hinnakujundus',
   'plan.foreverFree': 'Igavesti tasuta',
   'plan.individual.0': 'Kõik on nagu Alustajal, pluss:',
   'plan.individual.1': 'Kohandatud teatised',
   'plan.individual.2': 'Eemalda Plandok märk',
   'plan.individual.option.3': 'Piiramatud SMS-meeldetuletused',
   'plan.individual.4': 'Prioriteetne toetus',
   'plan.business.0': 'Kõik nagu Individuaalsel, pluss:',
   'plan.business.1': 'Piiramatu arv asukohtasid',
   'plan.business.2': 'Piiramatu arv töötajaid',
   'block.billingInfo.title': 'Arveldusinfo',
   'block.carInfo.title': 'Krediitkaarditeave',
   'input.cardNumber.label': 'Kaardi number',
   'input.cardNumber.placeholder': '1234 5678 3456 2456',
   'input.expireDate.label': 'Exp.Date',
   'input.expireDate.placeholder': '05 / 21',
   'input.cvv.label': 'CVV',
   'input.cvv.placeholder': '123',
   'modal.confirmCancel.subscription': 'Kas oled kindel, et soovid tellimuse tühistada?',
   'modal.confirmCancel.subscription.description': 'Siis kui teed seda, saad uuesti tasuta versiooni kasutada.',
   'btn.cancelSubscription.confirm': 'Jah, tühista minu tellimus',
   'loading.processingSubscribtion': 'Teie makse on töötlemisel. Palun oodake...',
   'state.serviceGroup.description': 'Grupis pole ühtegi teenust',
   'state.serviceGroup.btn': 'Lisa teenus',
   'appointment.status.new': 'Lisa kohtumine',
   'appointment.status.confirmed': 'Kinnitatud',
   'appointment.status.notShow': 'Ei tulnud kohale',
   'appointment.status.completed': 'Lõpetatud',
   'appointment.status.cancelled': 'tühistatud',
   'billing.tab.plan': 'Sinu plaan',
   'billing.tab.costs': 'Hinnangulised kulud',
   'billing.tab.billing': 'Arveldusinfo',
   'billing.tab.invoice': 'Arved',
   'plan.free': 'Tasuta',
   'plan.deactivated.title': 'Premium on deaktiveeritud',
   'plan.active.title': 'Aktiveerige',
   'payment.step.title': 'Maksed',
   'payment.error.title': 'Makset värskendama',
   'payment.payNow.title': 'Maksma',
   'payment.dismiss.title': 'Vabaks laskma',
   'payment.tab.title.sub': 'Tellimus',
   'payment.tab.subTitle': 'Premium pakett',
   'payment.per.members': 'liikme kohta',
   'payment.tab.title.notification': 'Märguanded',
   'payment.card.details': 'Kaardi andmed',
   'payment.success.message': 'Maksmine oli edukas',
   'payment.payWithCard.title': 'Maksa kaardiga',
   'payment.cardNumber.title': 'Kaardi number',
   'payment.expirationDate.title': 'Kehtivusaeg',
   'payment.digits.title': '(3 numbrit)',
   'payment.amount': 'Makse summa:',
   'payment.btn.pay': 'Maksma',
   'invoice.table.date': 'Kuupäev',
   'invoice.table.invoice': 'Arve',
   'invoice.table.download': 'Lae alla',
   'invoice.table.status': 'Staatus',
   'costs.billingPeriod.title': 'Selle arveldusperioodi hinnangulised kulud',
   'costs.billingPeriod.description':
      'See on teie selle arveldusperioodi kasutuskulud. Teie kulude jaotus on saadaval allpool.',
   'costs.billingPeriod.additionalInfo':
      'Kui teie teavitamiskulud jõuavad 50 € tasemele, võetakse need automaatselt kasutusele. Kuid ärge muretsege, kui summa on väiksem; see sisaldub mugavalt teie igakuises tellimuses.',
   'costs.summary.title': 'Kuu kuni kuupäevani kokkuvõte',
   'costs.summary.description': 'Need tasud arvestatakse teie konto saldosse',
   'costs.summary.detailedCharges': 'Üksikasjalikud tasud',
   'costs.summary.noData': 'Andmed puuduvad',
   'costs.summary.quantity': 'Kogus',
   'costs.summary.subtotal': 'Vahesumma',
   'costs.summary.taxes': 'Maksud',
   'dropdown.time.hours': 'tundi',
   'message.planUpgrade': 'Olete oma plaani edukalt täiendanud!',
   'costs.subscription': 'Tellimus',
   'btn.cancelSubscription': 'Tühista tellimine',
   'btn.no': 'Ei',
   'notification.success.update': 'Edukalt värskendatud',
   'validation.password.match': 'Parool ja kinnitage parool ei kattu',
   'blocked.time': 'Blokeeritud aeg',
   'message.successReset.password': 'Valmis! Parooli lähtestamise e-kiri on saadetud. Kontrollige oma postkasti.',
   'title.newPassword': 'Sisesta Uus Parool',
   'title.newPassword.description': 'Logi turvaliselt sisse oma kontole, et luua uus sisselogimisparool',
   'input.newPasswordRepeat.label': 'Kinnita oma uus parool',
   'btn.change.password': 'Muuda parooli',
   'text.havingTrouble': 'Kas teil on probleeme?',
   'link.resetPassword.again': 'Lähtestage oma parool uuesti',
   'message.successNew.password': 'Parooli muutmine õnnestus.',
   'title.repeatShifts': 'Vahetuse Kordamine',
   'text.confirmShift.update':
      'Oled muutnud vahetust, mis kordub igal nädalal. Eelseisvate vahetuste kustutamine kirjutab üle {0} käimasoleva {0} ajakava',
   'text.confirmShift.delete':
      'Oled kustutamas nädala jooksul korduvat vahetust. Eelseisvate vahetuste kustutamine kirjutab üle {0} käimasoleva {0} ajakava',
   'btn.deleteUpcoming.shifts': 'Kustuta eelseisvad vahetused',
   'btn.deleteThis.shift': 'Kustuta ainult see vahetus',
   'btn.updateUpcoming.shifts': 'Uuenda eelseisvaid vahetusi',
   'btn.updateThis.shift': 'Uuenda ainult seda vahetust',
   'text.permisionTab':
      'Seadistage, millistele osadele pääseb juurde iga kasutajatase. Kõigil sisse logitud töötajatel on juurdepääs kalendrile ja omanikukontodel on täielik juurdepääs süsteemile.',
   'title.permission.locations': 'Asukohad',
   'title.permission.booking': 'Broneerimine ja kliendid',
   'title.permission.staff': 'Töötajad',
   'title.permission.setup': 'Seadistamine',
   'title.permission.basic': 'Tavaline',
   'title.permission.low': 'Madal',
   'title.permission.med': 'Keskmine',
   'title.permission.high': 'Kõrge',
   'title.permission.owner': 'Omanik',
   'title.allLocations.permission': 'Sisene Kõigidesse Asukohtadesse',
   'title.accessCalendar.permission': 'Ava Enda Kalender',
   'title.accessOtherCalendar.permission': 'Sisene Teiste Töötajate Kalendritesse',
   'title.canBook.permission': 'Saab Teha Broneeringuid',
   'title.contactInfo.permission': 'Näeb kliendi kontaktteavet',
   'title.clients.permission': 'Kliendid',
   'title.messages.permission': 'Sõnumid',
   'title.services.permission': 'Teenused',
   'title.workingHours.permission': 'Töötunnid',
   'title.staffMembers.permission': 'Meeskonna Liikmed',
   'title.permissionLevels.permission': 'Lubade Tasemed',
   'title.accountSetup.permission': 'Konto Seaded',
   'title.clientSettings.permission': 'Kliendi Seaded',
   'title.onlineBookings.permission': 'Interneti-broneeringud',
   'title.analytics.permission': 'Analüüs',
   'title.marketing.permission': 'Turundus',
   'notification.type.reminder': 'Meeldetuletus',
   'notification.type.confirmation': 'Kinnitus',
   'notification.type.online_booking_confirmation': 'Online broneeringu kinnitus',
   'notification.type.rescheduling': 'Ajakava muutmine',
   'notification.type.cancellation': 'Tühistamine',
   'notification.type.thank_you': 'Aitäh',
   'notification.type.last_visit': 'Viimane külastus',
   'notification.channel.sms': 'SMS',
   'notification.channel.email': 'E-post',
   'notification.channel.whatsapp': 'WhatsApp',
   'notification.status.sent': 'Saadetud',
   'notification.status.pending': 'Oodates',
   'notification.status.failed': 'Ebaõnnestus',
   'dropdown.time.days': 'päeva / päevi',
   'title.appointment': 'broneering',
   'input.reminderBeforeLastVisit.label': 'Teade saadetakse pärast märgitud päevade arvu',
   'txt.with': 'koos',
   'short.hours': 't',
   'short.minutes': 'min',
   'input.registrationNumber.label': 'Ettevõtte number',
   'input.vatNumber.label': 'KM number',
   'invoice.table.priceTaxIncl': 'Kogus',
   'error.wrongSenderId':
      'Saatja ID peab sisaldama numbreid või märke, pikkus peab olema üle 3 ja vähem kui 11 sümbolit',
   'sidebar.help': 'Abi',
   'modal.help.title': 'Kuidas me saame aidata?',
   'modal.help.liveChat.title': 'Otse vestlus',
   'modal.help.liveChat.description': 'Kas teil on küsimusi? Küsige meilt kohe',
   'modal.help.suggestFeature.title': 'Soovitage funktsiooni',
   'modal.help.suggestFeature.description':
      'Jagage ettepanekuid ja vaadake teiste ettepanekuid ning hääletage soovitud funktsioonide poolt',
   'modal.help.viewUpdates.title': 'Vaadake uuendusi',
   'modal.help.viewUpdates.description': 'Vaadake meie uusimaid uuendusi ja parandusi',
   'warning.title.paidFeature': 'Tasuline Funktsioon',
   'warning.smsFeatureMissing':
      'SMS-sätetele juurdepääsu saamiseks tuleb teie plaani täiendada. Täiendamiseks külastage',
   'warning.link.billingAndPricing': 'Plaan ja Arved',
   'warning.newPage.0': 'täiendati!',
   'warning.newPage.1': 'Kui kasutasite teenuse Plandok-i vana versiooni, ei tööta teie parool enam',
   'warning.newPage.2': 'parooli lähtestamine',
   'warning.newPage.3': 'et nautida uut süsteemi. Lisateabe saamiseks võtke meiega ühendust',
   'warning.newPage.4': 'või',
   'title.blockTime.select': 'Valige aeg',
   'calendar.allResources': 'Kõik ressursid',
   'appointment.edit.title': 'Muuda Broneeringut',
   'appointment.create.title': 'Kohtumine loodi edukalt.',
   'appointment.update.title': 'Kohtumise värskendamine õnnestus.',
   'select.notify.title': '{0} ei tööta valitud intervalliga, kuid saate siiski aegu broneerida.',
   'booking.notify.title': 'Valitud aeg on broneeritud, kuid saate kohtumise luua.',
   'textArea.characters.left': 'tähemärki alles',
   'textArea.characters.exceeded': 'limiit on ületatud',
   'validation.characters.exceeded': 'Tähemärkide arv on ületatud',
   'staff.menu.members': 'Meeskonna Liikmed',
   'staff.menu.dates': 'Puhkepäevi',
   'staff.menu.working': 'Töötajate töötunnid',
   'staff.menu.permissions': 'Kasutaja luba',
   'staff.menu.members.description': 'Lisa või eemalda meeskonna liikmed ja kontrolli nende kasutaja juurdepääsu taset',
   'staff.menu.dates.description': 'Graafik tulemas ettevõtete sulgemisi või riigipühadel',
   'staff.menu.working.description': 'Halda kalendrit, kohandades töötajate töögraafikuid',
   'staff.menu.permissions.description': 'Sea juurdepääsuluba tundlike funktsioonide ja teabe jaoks',
   'message.successUpdateLocation': 'Valmis! Asukohaprofiil on edukalt uuendatud.',
   'ob.title': 'Online broneerimine',
   'ob.overview.tab': 'Ülevaade',
   'ob.massMessages.tab': 'Massisõnumid',
   'ob.campaigns.tab': 'Kampaaniad',
   'ob.profile.tab': 'Online profiili',
   'ob.links.tab': 'Viited',
   'ob.settings.tab': 'Seaded',
   'ob.payments.tab': 'Maksed',
   'ob.status.online': 'Online',
   'ob.status.offline': 'offline',
   'ob.status.title': 'Staatus',
   'ob.profile.step': '{0} of 4',
   'ob.profile.step.title': 'Profiil ja asukoht',
   'ob.profile.step.description':
      'Esitage oma filiaali ja tutvustada teenuseid, mida pakkuda oma klientidele. "Teave" osa on üks esimesi lõigud teie kliendid vaadates ja kui see teave ei ole piisavalt, siis veenduge, et jätta telefoninumber, kuhu kliendid saavad teiega ühendust lisainformatsiooni! Sisesta aadress, kuhu pakkuda oma teenuseid nii, et teie kliendid saavad olema informeeritud ja vaadata aadressi.',
   'ob.overview.placheholder.title': 'Olgu teie kliendid Broneeri Kohtumised Online!',
   'ob.overview.placheholder.description':
      'Loo internetibroneeringutele profiili ja lase oma klientide raamat kohtumisi online. Lihtsalt sisesta link oma veebilehel või sotsiaalse meedia ja ongi.',
   'ob.overview.start.now': 'Start Nüüd',
   'ob.links.direct.title': 'Otselink',
   'ob.links.facebook.title': 'Facebook Link',
   'ob.links.instagram.title': 'Instagram Link',
   'ob.links.direct.description':
      'URL Link internetibroneeringutele profiili, saate kasutada seda oma veebilehel või sotsiaalse meedia kontod. Kui sa ei ole kindel, kuidas kasutada linki võtke meiega ühendust.',
   'ob.links.facebook.description':
      'Jagage seda linki Facebook meelitada rohkem kliente ja hõlbustada neil leida oma äri ja raamat kohtumine.',
   'ob.links.instagram.description':
      'Tee lihtsamaks ja siduda oma online broneerimine otse!\nVõite kasutada seda oma kodulehel ja mujal, kus te tunnete meeldib.\nKui te ei ole kindel, kuidas kasutada link, ärge muretsege, me saime teile kaetud!',
   'ob.analytics.tab.description':
      'Lisage Google Analytics ja Facebook Pixel oma veebipõhisele broneerimislehele, et analüüsida liiklust ja kasutajate käitumist.',
   'ob.analytics.google.title': 'Google Analytics',
   'ob.analytics.facebook.title': 'Facebooki piksel',
   'ob.analytics.google.description':
      'Google Analyticsi abil saate jälgida ja analüüsida veebisaidi liiklust ja kasutajate käitumist, et saada teadmisi, kuidas oma veebisaiti parandada.',
   'ob.analytics.active': 'Aktiivne',
   'ob.analytics.inactive': 'Mitteaktiivne',
   'ob.analytics.setUp.btn': 'Seadistatud',
   'ob.analytics.google.description.1':
      'Google Analytics on võimas tööriist, mis aitab teil jälgida veebisaidi liiklust ja kasutajate käitumist. Google Analyticsi abil saate jälgida oma veebisaidi külastajate arvu, seda, kust nad tulevad, milliseid lehekülgi nad külastavad ja kui kaua nad teie veebisaidil viibivad.',
   'ob.analytics.google.description.2':
      'Lisaks võimaldab Google Analytics teil seada eesmärke ja jälgida vestlusi, mis annab teile parema ülevaate sellest, kui hästi teie veebisait toimib ja kuidas te saate seda parandada.',
   'ob.analytics.google.trackingId': 'Jälgimise ID',
   'ob.analytics.google.tooltip':
      'Jälgimis-ID leidmiseks peate kõigepealt sisse logima oma Google Analyticsi kontole, valima veebisaidi või rakenduse, mida soovite jälgida, ja seejärel liikuma vahekaardile "Admin". Sealt klõpsake "Jälgimiskoodile". Teie jälgimis-ID kuvatakse kujul "UA-XXXX-Y".',
   'ob.analytics.google.account': 'Teil ei ole veel Google Analyticsi kontot?',
   'ob.analytics.click': 'Klõpsake',
   'ob.analytics.here': 'siin',
   'ob.analytics.learn': 'et teada saada, kuidas seda luua.',
   'ob.analytics.facebook.description.1':
      'Facebook Pixel on vahend, mis võimaldab teil jälgida kasutajate käitumist teie veebisaidil, sealhulgas konversioone, lehekülje vaatamisi ja muid sündmusi.',
   'ob.analytics.facebook.description.2':
      'Facebook Pixel võib anda teile väärtuslikke andmeid teie sihtrühma kohta, nagu demograafilised andmed, huvid, seadme tüüp, lehekülgede vaatamine ja tehtud tegevused, mis aitavad teil paremini mõista ja optimeerida kasutajakogemust teie veebisaidil.',
   'ob.analytics.facebook.pixelId': 'Piksli ID',
   'ob.analytics.facebook.tooltip':
      'Facebooki piksli ID leidmiseks järgige järgmisi samme: Mine oma Facebooki reklaamihalduri kontole. Vajutage ekraani vasakus ülanurgas olevale nupule "Menüü". Valige jaotise "Events Manager" all "Pixels". Siin peaksite nägema nimekirja kõigist teie kontoga seotud pikslitest. Klõpsake selle piksli nimele, mille ID-d vajate. Teie piksli ID kuvatakse ekraani vasakus ülanurgas piksli nime kõrval.',
   'ob.analytics.facebook.account': 'Kas teil ei ole veel Facebooki ärikülge?',
   'ob.analytics.google.account.mobile.1': "Teil ei ole veel Google Analytics'i",
   'ob.analytics.google.account.mobile.2': 'kontot? Klõpsake',
   'ob.analytics.google.account.mobile.3': 'et teada saada, kuidas',
   'ob.analytics.google.account.mobile.4': 'luua.',
   'ob.analytics.facebook.account.mobile.1': 'Teil ei ole veel Facebooki',
   'ob.analytics.facebook.account.mobile.2': 'ärilehte? Klõpsake',
   'ob.analytics.facebook.account.mobile.3': 'et teada saada, kuidas',
   'ob.analytics.facebook.account.mobile.4': 'seda luua.',
   'ob.analytics.successfullySaved.message': 'Edukalt salvestatud',
   'ob.settings.booking.title': 'Broneerimine kättesaadavus',
   'ob.settings.booking.description':
      'Määrake aeg, millal varem ja viimaseid kohtumisi saab planeeritud. Manage ajavahemike kohtumiste ja annavad olulist teavet klientide kõrval võimaluse valida oma töötaja oma teenust.',
   'ob.settings.cancellation.title': 'Tühistamine ja ümberajastamine',
   'ob.settings.cancellation.description':
      'Seadke aja jooksul, kui kliendid saavad tühistada või ajatada oma ametisse.',
   'ob.settings.locationsUrl.title': 'asukohad URL',
   'ob.settings.locationsUrl.description':
      'Seadistamine ja jagada üks URL kõikides Internetis broneerida kohad. See on kasulik ettevõtetele eri kohtades ainult.',
   'ob.settings.businessName.explanation': 'Teie URL: book.plandok.com/business-name/Location2556',
   'ob.settings.notifications.title': 'Teatismeilid',
   'ob.settings.notifications.description': 'Määrake saaja e-mailide kohta uusi kohtumisi või nendes tehtud muudatusi.',
   'ob.settings.required.title': 'Kohustuslikud ja valikulised väljad',
   'ob.settings.required.description': 'Valige, millised väljad peab klient veebipõhise broneeringu tegemisel täitma.',
   'ob.settings.requiredField.title': 'Nõutud',
   'ob.settings.optionalField.title': 'Valikuline',
   'ob.settings.requiredField.notification.title': 'Seadetega oli probleem',
   'ob.settings.requiredField.notification.description':
      'Vabandust, kuid üks väli peab olema kohustuslik, välja arvatud "märkused".',
   'ob.settings.nameField.title': 'Eesnimi',
   'ob.settings.lastNameField.title': 'Perekonnanimi',
   'ob.settings.phoneField.title': 'Telefoninumber',
   'ob.settings.emailField.title': 'Meil',
   'ob.settings.notes.label': 'Märkuste teksti paigutaja',
   'ob.settings.notes.placeholder': 'Nt. Sisestage oma märkused',
   'ob.bookingLimit.title': 'Online broneeringu limiit',
   'ob.bookingLimit.description': 'Palun määrake maksimaalne teenuse broneeringute arv, mille soovite määrata.',
   'ob.bookingLimit.label': 'Teenuse broneeringute piiramine',
   'ob.bookingLimit.unlimited.title': 'Piiramatu',
   'ob.bookingLimit.1service.title': 'max. 1 Teenus',
   'ob.bookingLimit.2services.title': 'max. 2 Teenust',
   'ob.bookingLimit.3services.title': 'max. 3 Teenust',
   'ob.menu.edit.profile': 'Muuda profiili',
   'ob.menu.turnOff.profile': 'Lülita Profiil',
   'ob.profile.status': 'Staatus',
   'ob.overview.tab.description': 'Kasvatada oma äri, lastes oma klientidele broneerida kohtumisi online.',
   'ob.profile.tab.description': 'Hallata online profiilid kohtades. Kujutiste, kirjeldused, aadressid ja rohkem.',
   'ob.links.tab.description': 'Siit leiad online tellimustele link, kus saab jagada oma klientidele.',
   'ob.settings.tab.description':
      'Reguleerige online seadeid siin, et teie kliendid saavad broneerida ainult siis, kui see on mugavaim.',
   'ob.payments.tab.description': 'Valige, milliseid makseviise saavad teie kliendid teenuste eest tasumisel kasutada.',
   'ob.payments.page.description':
      'Siin saate valida, milliseid makseviise teie kliendid saavad teenuste eest tasumisel kasutada.',
   'ob.payOnSite.title': 'Maksmine kohapeal',
   'ob.payOnSite.description': 'Teie kliendid saavad maksta kohapeal sularahas.',
   'ob.payWithCard.title': 'Makske kaardiga',
   'ob.payWithCard.description': "Teie kliendid saavad maksta krediitkaardiga Stripe'i rakenduse kaudu.",
   'ob.payWithCard.info.1': 'Te kasutate tasuta Plandoki paketti, seega peate maksma',
   'ob.payWithCard.info.2': "Plandoki vahendustasu ja Stripe'i vahendustasu, kasutades seda makseviisi.",
   'ob.payWithCard.info.3': 'selleks, et',
   'ob.payWithCard.info.4': 'Plandoki vahendustasu.',
   'ob.payWithCard.info.5': "Premium-plaan ei vabasta Stripe'i vahendustasu maksmisest.",
   'ob.payWithCard.info.6': "Kontrollige Stripe'i hindu siin",
   'ob.stripeConnected.info': '(Stripe ühendatud)',
   'ob.requiresSetup.info': "(nõuab Stripe'i konto seadistamist)",
   'ob.connectToStripe.btn': "Ühendage Stripe'iga",
   'ob.connectToStripe.successMessage.title': "Edukalt ühendatud Stripe'iga",
   'ob.waitingAuthorization.title': "Ootab oma autoriseerimist Stripe'is",
   'ob.stripeDashboard.title': 'Kõik makselogid ja tagasimaksed on saadaval aadressil',
   'ob.stripeDashboard.url': "Stripe'i armatuurlaual",
   'ob.yourStripeAccount.title': "Teie Stripe'i konto",
   'ob.visitStripe.option': "Külastage Stripe'i",
   'ob.changeAccount.option': "Muutke Stripe'i kontot",
   'input.earliestAppointment.label': 'Varaseim ametisse',
   'input.earliestAppointment.placeholder': 'Vahetult enne ametisse',
   'input.latestAppointment.label': 'Viimati ametisse',
   'input.latestAppointment.placeholder': 'Mitte rohkem kui 6 kuud enne',
   'input.timeSlots.label': 'Aeg pesa intervallidega',
   'input.timeSlots.placeholder': '15 minutit',
   'checkbox.allowCustomers': 'Võimaldada klientidel valida töötajad nende ametisse',
   'input.importantInfo.label': 'Oluline teave',
   'input.importantInfo.placeholder': 'Teave, mis näitab, et klient kinnitades samas oma ametisse',
   'input.changeAppointment.label': 'Muudatused kohtumisi saab teha',
   'input.changeAppointment.placeholder': 'Igal ajal',
   'checkbox.sendToBooked': 'Saada broneeritud töötaja',
   'checkbox.sendSpecificEmail': 'Saada konkreetse e-posti aadress',
   'input.specificEmail.label': 'Konkreetsed E-posti aadress',
   'input.specificEmail.placeholder': 'Sisesta e-posti aadress',
   'input.locationsUrl.label': 'asukohad URL',
   'input.locationsUrl.placeholder': 'Näiteks Johns-Barber-pood',
   'ob.btn.save': 'Salvesta',
   'ob.btn.cancel': 'Tühista',
   'ob.btn.showLink': 'Näita Link',
   'ob.btn.active': 'Aktiivne',
   'ob.btn.inactive': 'Mitteaktiivne',
   'ob.linkModal.title': 'Direct Link tellimustele',
   'ob.linkModal.description': 'Siin on link, kus oma online broneerimine teenuseid pääseb otse!',
   'ob.linkModal.afterDescription':
      'See link suunab kasutajad otse oma teenuseid. Just ühe kliki kliendid saavad jõuda oma äri profiilid ja raamat kohtumisi.\nSee link on lihtne kasutada ja sõbralik kõigi seadmete kas see siis arvuti, telefoni või tablett.',
   'ob.fbLinkModal.title': 'Facebook Page Broneeri kohe',
   'ob.fbLinkModal.description':
      'Setup integreeritud Broneeri nüüd nuppu oma Facebook äri leht pingutuseta saada uus tellimustele otse Facebook.',
   'ob.fbLinkModal.step1': "Logi sisse oma äri Facebook'i",
   'ob.fbLinkModal.step2': 'Vajuta "Lisa nuppu" asub kaanefoto',
   'ob.fbLinkModal.step3': 'Valige "Registreeru" valik ja sisestage allpool tellimus link',
   'ob.linkModalCopySuccess.title': 'Link kopeeritud!',
   'ob.btn.copyLink': 'Kopeeri link',
   'title.appointmentSlot.select': 'Vali aeg',
   'btn.back': 'Tagasi',
   'btn.continue': 'Jätkama',
   'ob.hours.open': 'Avatud',
   'ob.hours.close': 'Suletud',
   'ob.hours.step.title': 'Töötunnid',
   'ob.hours.step.description': 'Lase oma klientidele teada, kui oma äri avab ja suleb.',
   'ob.staffHours.step.title': 'Personali tööaeg',
   'ob.staffHours.step.description':
      'Oluline samm, palun lisage süsteem, et teada saada, millal kliendid saavad veebis broneerida.',
   'ob.location.step.title': 'Asukoht Informatsioon',
   'ob.photo.step.title': 'Fotod',
   'ob.photo.step.description': 'Lase oma klientidele teada, kuidas oma äri kohas välja.',
   'input.file.dnd.photos': 'Pukseerimine fotod',
   'btn.publish': 'Lülitage sisse',
   'text.next': 'Järgmine',
   'input.phone.number.label': 'Telefoninumber',
   'input.state.label': 'Riik',
   'input.state.placeholder': 'Sisestage riik (vald)',
   'input.addressDetails.label': 'Aadressiandmed',
   'input.addressDetails.placeholder':
      'Sisesta aadressiandmed näiteks ukse kood, muud sissepääsud, parkimine teavet jne',
   'placeholder.no.photos': 'Fotosid ei ole',
   'btn.options': 'Valikud',
   'btn.list.profile': 'Luba',
   'btn.preview': 'Eelvaade',
   'btn.next': 'Järgmine',
   'market.ob.pleaseWait.title': 'Palun oodake ...',
   'market.ob.location.step.title': 'Vali asukoht',
   'market.ob.services.step.title': 'Valige teenused',
   'market.ob.employee.step.title': 'Valige kuupäev ja personal',
   'market.ob.timeSlot.step.title': 'Valige kuupäev ja kellaaeg',
   'market.ob.personalInfo.step.title': 'Maksmine',
   'market.ob.appointmentInfo.step.title': 'Nimetamine teave',
   'market.ob.informationAboutYou.title': 'Teave teie kohta',
   'market.ob.paymentMethod.title': 'Makseviis',
   'market.ob.securePayment.title': 'Turvaline makse',
   'market.ob.payWithCard.title': 'Makske kaardiga',
   'market.ob.payOnSite.title': 'Maksmine kohapeal',
   'market.ob.payOnSite.firstDescription': 'Tasuda tuleb salongis pärast protseduuri.',
   'market.ob.payOnSite.secondDescription':
      'Märkus: Kui Te ei saa oma broneeritud kohtumisele tulla, siis palume Teil oma kohtumine tühistada või selle aeg ümber muuta.',
   'market.ob.validThru.title': 'Kehtiv kuni (kuu/aasta)',
   'market.ob.information.title': 'Teave',
   'market.ob.additionalInformation.title': 'Lisateave',
   'market.ob.reschedulingPolicy.title': 'Tühistamise/muutmise poliitika',
   'market.ob.reschedulingPolicy.description': 'Muudatusi kohtumistes saab teha {0} enne kohtumist.',
   'market.ob.fieldsAreRequired.title': '* Väljad on kohustuslikud',
   'market.ob.step.title': 'Samm {0} 3-st',
   'market.ob.confirm.step.title': 'Vaadake ja kinnitage',
   'market.ob.noServices.placeholder': 'Nr teenuste veel valitud',
   'market.ob.input.firstName.label': '{0} Eesnimi',
   'market.ob.input.firstName.placeholder': 'e.g. John',
   'market.ob.input.lastName.label': '{0} Perekonnanimi',
   'market.ob.input.lastName.placeholder': 'e.g. Doe',
   'market.ob.input.phone.label': '{0} Mobiilinumber',
   'market.ob.input.phone.placeholder': '670 70 822',
   'market.ob.input.email.label': '{0} E-posti aadress',
   'market.ob.input.email.placeholder': 'john.doe@gmail.com',
   'market.ob.input.description.label': 'Märkused',
   'market.ob.staff.assignedSpecialist.label': 'personali',
   'market.ob.staff.noPreference.label': 'eelistus puudub',
   'market.ob.timeSlots.fullyBooked.title': 'Kahjuks ei ole meil sel päeval ühtegi vaba aega.',
   'market.ob.timeSlots.choseAnotherDay.title': 'Palun valige mõni teine päev.',
   'market.ob.timeSlots.closedSlot.title': 'Kõige lähemal ajal on',
   'market.ob.timeSlots.selectClosest.title': 'Vali lähim kuupäev',
   'market.ob.timeSlots.note.title': 'oluline teave',
   'market.ob.service.duration.title': 'min',
   'market.location.workingHours.title': 'Avatud',
   'market.location.monday.title': 'Esmaspäev',
   'market.location.tuesday.title': 'Teisipäev',
   'market.location.wednesday.title': 'Kolmapäev',
   'market.location.thursday.title': 'Neljapäev',
   'market.location.friday.title': 'Reede',
   'market.location.saturday.title': 'Laupäev',
   'market.location.sunday.title': 'Pühapäev',
   'market.location.meetOurTeam.title': 'Meet Meie Team',
   'market.location.title': 'Võtke ühendust',
   'market.location.services.title': 'Teenused',
   'market.location.about.title': 'Meist',
   'market.location.aboutUs.title': 'Meie kohta',
   'market.confirmation.appointmentCanceled.title': 'See kohtumine tühistati',
   'market.confirmation.appointmentConfirmed.title': 'Broneeringu kinnitus.',
   'market.confirmation.appointmentConfirmed.description': 'Teie kohtumine on kinnitatud, siin on üksikasjad:',
   'market.confirmation.appointmentDate.title': 'Kohtumise kuupäev',
   'market.confirmation.scheduledAppointment.title': 'Tühistamine internetis ei ole võimalik.',
   'market.confirmation.scheduledAppointment.description':
      'Kui te ei saa oma kavandatud vastuvõtule tulla, palun muutke selle aega või tühistage see broneering.',
   'market.modal.cancelAppointment.title': 'Kas olete kindel, et soovite selle kohtumise tühistada ?',
   'market.modal.cancelledAppointment.title': 'Kohtumine on tühistatud',
   'market.language.changer.title': 'Keel (Language)',
   'market.btn.bookNow': 'Broneeri',
   'market.servicesAvailable.title': 'teenuseid',
   'market.poweredByPlandok.title': 'Plandok',
   'market.poweredByPlandok.description': 'Tahad sarnast veebilehte ? Külasta',
   'market.btn.readMore': 'Loe edasi',
   'market.btn.showLess': 'Näita vähem',
   'market.btn.reviews': 'Arvustused',
   'market.btn.viewMore': 'Vaata lähemalt',
   'market.btn.cancelAppointment': 'Tühistage kohtumine',
   'market.btn.cancel': 'Jah, tühistage',
   'market.wh.closed.title': 'Suletud',
   'market.ob.success.message': 'Valmis! Broneerimine on edukalt loodud',
   'market.ob.cancelAppointment.success.message': 'Nimetamine tühistatakse edukalt',
   'market.ob.cancelAppointment.error.message': 'Nimetamine Katkestamine ebaõnnestus',
   'market.ob.cancelAppointment.confirm.title': 'Kas olete kindel, et soovite kohtumise tühistada?',
   'market.location.getDirections.title': 'Vaata juhiseid',
   'market.notFound.title': 'Lehekülge ei leitud',
   'market.notFound.btn.goBack': 'Mine tagasi',
   'market.ob.quantitativeLimit.title': 'Kvantitatiivne piirnorm',
   'market.ob.quantitativeLimit.description':
      'Vabandust, omanik on kehtestanud broneerimisteenuste arvule piirangu, mis võimaldab valida ainult {0} teenu{0}',
   'market.ob.quantitativeLimit.three': 'kolm',
   'market.ob.quantitativeLimit.two': 'kaks',
   'market.ob.quantitativeLimit.one': 'üks',
   'market.ob.quantitativeLimit.serviceSingular': 's',
   'market.ob.quantitativeLimit.servicesPlural': 'sed',
   'validation.characters.minLength': 'See väärtus on liiga lühike. See peaks olema 3 või rohkem tähemärki.',
   'not.found.btn.label': 'Tagasi',
   'not.found.subTitle.label': 'Vabandame, lehte, mida külastasite, ei eksisteeri.',
   'analytics.title': 'Analytics',
   'analytics.dashboard.tab': 'Infotahvel',
   'analytics.reports.tab': 'Aruanded',
   'analytics.premium.title': 'Preemia',
   'analytics.totalAppointments.title': 'Kokku kohtumisi',
   'analytics.cancellationReasons.title': 'Top tühistada põhjusel',
   'analytics.occupancy.title': 'Hõivatus',
   'analytics.topServices.title': 'Top teenused',
   'analytics.clients.title': 'Kliendid',
   'analytics.completed.title': 'Lõpetatud',
   'analytics.notCompleted.title': 'Pole lõpetatud',
   'analytics.canceled.title': 'Tühistatud',
   'analytics.noShowAppointments.title': 'Ei näita kohtumisi',
   'analytics.reasonName.title': 'Põhjuse nimi',
   'analytics.reasonCount.title': 'Loendama',
   'analytics.lastPeriod.title': 'Viimase perioodiv',
   'analytics.workingHours.title': 'Töötunnid',
   'analytics.bookedHours.title': 'Broneeritud tunnid',
   'analytics.blockedHours.title': 'Blokeeritud tunnid',
   'analytics.unbookedHours.title': 'Broneerimata tunnid',
   'analytics.service.title': 'Teenus',
   'analytics.thisMonth.title': 'See periood',
   'analytics.lastMonth.title': 'Viimane periood',
   'analytics.totalClients.title': 'Kokku klientidele',
   'analytics.averageVisits.title': 'Keskmine külastuste arv kliendi',
   'analytics.newClients.title': 'Uusi kliente',
   'analytics.returningClients.title': 'Püsikliendiks',
   'analytics.returnRate.title': 'Klient tootlus',
   'analytics.clientsByGender.title': 'Kliendid soo',
   'analytics.clientsBySource.title': 'Klientidele Viide allikas',
   'analytics.clientsByAge.title': 'Kliendid vanuse',
   'analytics.timeRange.custom': 'komme',
   'analytics.timeRange.today': 'Täna',
   'analytics.timeRange.yesterday': 'Eile',
   'analytics.timeRange.last1day': 'Viimane 1 päev',
   'analytics.timeRange.last7days': 'Viimased 7 päeva',
   'analytics.timeRange.last30days': 'Viimased 30 päeva',
   'analytics.timeRange.last90days': 'Viimased 90 päeva',
   'analytics.timeRange.last3months': 'Viimased 3 kuud',
   'analytics.timeRange.lastMonth': 'Eelmine kuu',
   'analytics.timeRange.last6Months': 'Viimased 6 kuud',
   'analytics.timeRange.lastYear': 'Eelmisel aastal',
   'analytics.timeRange.last2year': 'Viimased 2 aastat',
   'analytics.timeRange.thisWeek': 'See nädal',
   'analytics.timeRange.thisMonth': 'See kuu',
   'analytics.timeRange.thisYear': 'Sellel aastal',
   'analytics.timeRange.tomorrow': 'Homme',
   'analytics.timeRange.next7days': 'Järgmised 7 päeva',
   'analytics.timeRange.next30days': 'Järgmised 30 päeva',
   'analytics.menu.dashboard.description': 'Uurige oma ettevõtte andmeid diagrammides ja statistikas',
   'analytics.menu.reports.description': 'Uurige aruannetes oma ettevõtte andmeid',
   'analytics.timeRange.allTime': 'Kogu aeg',
   'analytics.timeRange.label': 'Ajavahemik',
   'analytics.services.lastPeriod.label': 'Viimase Perioodiv',
   'analytics.totalAppointments.appointment.label': 'Kohtumised',
   'analytics.totalAppointments.onlineBook.label': 'Online tellimustele',
   'analytics.gender.male': 'Mees',
   'analytics.gender.female': 'Naine',
   'analytics.gender.unknown': 'Teadmata',
   'analytics.noData': 'Valitud perioodil puuduvad andmed',
   'analytics.premium.info.title': 'Uuenda premium versioon vaatamiseks analytics kauem kui 7 päeva.',
   'analytics.premium.upgradeButton.title': 'AKTIVEERI',
   'analytics.premium.skipButton.title': 'Jäta vahele',
   'reports.appointments.title': 'Kohtumised',
   'reports.appointments.description':
      'Vaadake eelseisvate kohtumiste prognoositavaid tulusid, jälgige tühistamise määrasid ja põhjuseid.',
   'reports.appointmentsList.title': 'Kohtumiste nimekiri',
   'reports.appointmentsList.description': 'Kuvab kõik kohtumised koos seotud teabega.',
   'reports.appointmentsSummary.title': 'Kohtumiste kokkuvõte',
   'reports.appointmentsSummary.description': 'Kuvab kokkusaamised personali ja teeninduse lõikes.',
   'reports.appointmentsCancellations.title': 'Kohtumiste tühistamised',
   'reports.appointmentCancellations.title': 'Kohtumiste tühistamised',
   'reports.appointmentsCancellations.description':
      'Kuvab kõik tühistatud kohtumiste põhjused ja koguse teie valitud perioodi kohta.',
   'reports.summary.title': 'Kokkuvõte',
   'reports.clients.title': 'Kliendid',
   'reports.clients.description':
      'Saate ülevaate sellest, kuidas kliendid teie ettevõttega suhtlevad ja kes on teie suurimad kulutused',
   'reports.clientsList.title': 'Kliendid',
   'reports.clientsList.description': 'Klientide nimekiri koos nende andmetega',
   'reports.exportCsv.title': 'Ekspordi CSV',
   'reports.appointmentsByStaff.title': 'Kohtumised personali poolt',
   'reports.appointmentsByService.title': 'Kohtumised teeninduse järgi',
   'reports.clearFilters.button.title': 'Selge filtrid',
   'reports.filterStaff.placeholder': 'Kogu personal',
   'reports.filterLocations.placeholder': 'Kõik asukohad',
   'reports.filters.title': 'Filtrid',
   'reports.locations.mobile.title': 'Asukohad:',
   'reports.staff.mobile.title': 'Töötajad:',
   'reports.filters.apply.title': 'Rakenda',
   'reports.search.placeholder': 'Otsige viite või kliendi järgi',
   'reports.subscription.description': '{0} plaan ({0} ainult personal)',
   'reports.notifications.description': '{0} sõnumid ({0})',
   'serviceGroup.addCategory.description':
      'Kategooriad on olulised, kuna need võimaldavad teil sarnaseid teenuseid rühmitada.',
   'serviceGroup.addCategory.title': 'Lisa kategooria',
   'app.install.title': 'Installige Plandok',
   'app.install.description.title':
      'Installimine ei kasuta peaaegu üldse salvestusruumi ja annab kiire võimaluse sellesse rakendusse naasta',
   'app.install.button.title': 'Installige',
   'app.skip.button.title': 'Vahele jätma',
   'app.actionUndone.button.title': 'Toiming on tagasi võetud',
   'app.appointmentCancelled.button.title': 'Nimetus välja jäetud',
   'app.undo.button.title': 'Tühista',
   'app.showAllTimes.button.title': 'Näita kõiki aegu',
   'app.install.success.title': 'Rakenduse installimine õnnestus!',
   'rp.upgradeToPremiumLink.title': 'Minge üle Premiumile',
   'rp.upgradeToPremium.title': 'selle funktsiooni kasutamiseks',
   'rp.repeatAppointment.title': 'Korda',
   'rp.input.frequency.title': 'Sagedus',
   'rp.input.endsAfter.title': 'Lõpetamine',
   'rp.input.selectDate.title': 'Valige kuupäev',
   'rp.upgrade.title': 'Täiendage oma plaani',
   'rp.upgrade.description.title':
      'Plandoki premium-versioon pole aktiveeritud. Täiendage oma plaani, et pääseda täielikult juurde kõikidele Plandoki pakutavatele funktsioonidele.',
   'rp.upgrade.button.title': 'Minge üle premium-tasemele',
   'rp.repeating.title': 'Korduv',
   'rp.repeats.title': 'Kordub',
   'rp.repeatsUntil.title': 'kuni',
   'rp.repeatingTimes.title': '({0} korda)',
   'rp.cancel.upcoming.title': 'Kustutage kõik tulevased korduvad kohtumised',
   'rp.update.upcoming.title': 'Uuendage kõik eelseisvad korduvad kohtumised',
   'rp.edit.appointment.title': 'Värskenda kohtumist',
   'rp.edit.appointment.description.title':
      'Muudate korduvat kohtumist. Valige, milliseid kohtumisi tuleks värskendada:',
   'rp.edit.thisAppointment.title': 'Värskendage ainult seda kohtumist',
   'rp.edit.allAppointments.title': 'Värskendage kõiki tulevasi kohtumisi',
   'rp.edit.saveAppointment.title': 'Salvesta',
   'modal.cancelSubscriptionError.title': 'Tühista tellimuse viga',
   'modal.cancelSubscriptionError.text.1': 'Tasuta plaan on saadaval ainult maksimaalselt 5',
   'modal.cancelSubscriptionError.text.2': 'töötajatega',
   'modal.cancelSubscriptionError.text.3': 'ja 1',
   'modal.cancelSubscriptionError.text.4':
      'Tasuta versiooni kasutamiseks eemaldage nõuete täitmiseks asukohad / töötajad.',
   'modal.latestPaymentFailed.title': 'Viimane makse ebaõnnestus',
   'modal.latestPaymentFailed.text.1':
      'Et saada juurdepääsu kõikidele funktsioonidele, palun makske või kasutada tasuta versiooni eemaldada',
   'modal.latestPaymentFailed.text.2':
      ', et vastata nõuetele. Tasuta plaan on saadaval ainult maksimaalselt 5 töötaja ja 1 asukohaga.',
   'modal.extraCostsForNewMember.title': 'Lisakulud uuele liikmele',
   'modal.extraCostsForNewMember.description':
      'Töötajate arvu suurendamisega kaasnevad lisakulud teie igakuisele tellimusele.',
   'modal.premiumUpgradeRequired.title': 'Vajalik Premium uuendamine',
   'modal.premiumUpgradeRequired.description': 'Täiendava {0} lisamiseks tuleb teie pakett uuendada Premiumi tasemele',
   'modal.premiumUpgradeRequired.location': 'asukoha',
   'modal.premiumUpgradeRequired.staffMembers': 'töötajate',
   'modal.changeAccount.title': "Muuda Stripe'i kontot?",
   'modal.changeAccount.description':
      'Te ei saa makseid vastu võtta, kui te vahetate kontot. Muutuse tegemiseks kulub umbes minut.',
   'btn.agree': 'Nõustu',
   'btn.decline': 'Tagasi lükata',
   'paymentMethod.paymentMethod.title': 'Makseviis',
   'paymentMethod.currentPaymentMethod.title': 'Praegune makseviis',
   'paymentMethod.expirationDate': 'Kehtivusaeg {0}',
   'paymentMethod.otherPaymentMethods.title': 'Muud makseviisid',
   'paymentMethod.addAnotherPaymentMethod.btn': 'Lisage teine makseviis',
   'paymentMethod.addPaymentMethod.title': 'Lisa makseviis',
   'paymentMethod.noPaymentMethodAdded.title': 'Makseviisi ei ole lisatud',
   'paymentMethod.noPaymentMethodAdded.description': 'Makseviis lisatakse Premium paketi valimisel',
   'paymentMethod.goToPlanPage.btn': 'Mine plaani lehele',
   'paymentMethod.add.btn': 'Lisa',
   'paymentMethod.makeDefault.btn': 'Tee vaikimisi',
   'paymentMethod.cardHasBeenAdded.message': 'Kaart on lisatud',
   'paymentMethod.cardHasBeenAssign.message': 'Kaart on määratud',
   'page.marketing.overview.messages.description':
      'Saatke oma klientidele kohandatud turundussõnumeid SMS-i või e-posti teel paari lihtsa klikiga',
   'page.marketing.overview.btn.sendMessage': 'Sõnumi saatmine',
   'page.marketing.overview.campaigns.title': 'Automaatselt saadetavad kampaaniad',
   'page.marketing.overview.campaigns.description':
      'Looge personaliseeritud kampaaniaid, mis põhinevad sündmustel, näiteks klientide sünnipäevadel ja muudel sündmustel. \nSaatke kampaaniate edastamiseks e-posti ja tekstisõnumite kaudu üle mitme kanali',
   'page.marketing.overview.btn.newCampaign': 'Uus kampaania',
   'page.marketing.massMessages.title': 'Uue sõnumi seadistamine',
   'page.marketing.massMessages.messageLog': 'Sõnumilogi',
   'page.marketing.massMessages.sendOn': 'Saada edasi',
   'page.marketing.massMessages.sendTo': 'Saada',
   'page.marketing.massMessages.channel': 'Kanal',
   'page.marketing.campaigns.description':
      'Saatke automatiseeritud turundussõnumeid oma klientidele SMS-i või e-posti teel mõne lihtsa klikiga',
   'page.marketing.campaigns.birthdaySpecial.title': 'Sünnipäeva Special',
   'page.marketing.campaigns.winBackClients.title': 'Võta tagasi kliendid',
   'page.marketing.campaigns.welcomeNewClients.title': 'Tervitage uusi kliente',
   'page.marketing.campaigns.rewardRegularClients.title': 'Premeerige püsikliente',
   'page.marketing.campaigns.birthdaySpecial.description':
      'Üllatage kliente nende erilisel päeval, see kampaania saadetakse automaatselt klientidele nende sünnipäeva lähedale',
   'page.marketing.campaigns.winBackClients.description':
      'Suunake mittekülastunud kliendid tagasi eripakkumisega. See kampaania saadab klientidele, kes ei ole teatud aja jooksul tagasi pöördunud',
   'page.marketing.campaigns.welcomeNewClients.description':
      'Muutke uustulnukad püsiklientideks, julgustades esmakordseid kliente erisoodustusega uuesti broneerima. See kampaania saadetakse automaatselt klientidele üks päev pärast nende esimest müüki',
   'page.marketing.campaigns.rewardRegularClients.description':
      'Üllata tippkliente eripakkumisega, kindel viis, kuidas oma parimaid kliente veelgi enam kaasata. See kampaania saadetakse automaatselt klientidele hiljutise kohtumisaktiivsuse alusel',
   'page.marketing.campaigns.btn.active': 'Aktiivne',
   'page.marketing.campaigns.btn.inactive': 'Mitteaktiivne',
   'page.marketing.campaigns.btn.startCampaign': 'Kampaania käivitamine',
   'page.marketing.campaigns.btn.editCampaign': 'Muuda kampaaniat',
   'page.marketing.campaigns.btn.resetCampaign': 'Kampaania lähtestamine',
   'modal.resetCampaign.description': 'Kas sa tahad kindlasti selle kampaania nullida ?',
   'modal.resetCampaign.btn.yesReset': 'Jah, lähtestage',
   'page.marketing.smsCreate.step.1': 'SMS-i seadistamine. 1. samm 4-st',
   'page.marketing.smsCreate.step.2': 'SMS-i seadistamine. 2. samm 4-st',
   'page.marketing.smsCreate.step.3': 'SMS-i seadistamine. 3. samm 4-st',
   'page.marketing.smsCreate.step.4': 'SMS-i seadistamine. 4. samm 4-st',
   'page.marketing.emailCreate.step.1': 'E-posti seadistamine. 1. samm 4-st',
   'page.marketing.emailCreate.step.2': 'E-posti seadistamine. 2. samm 4-st',
   'page.marketing.emailCreate.step.3': 'E-posti seadistamine. 3. samm 4-st',
   'page.marketing.emailCreate.step.4': 'E-posti seadistamine. 4. samm 4-st',
   'page.marketing.campaignCreate.step.1': 'Kampaania seadistamine. 1. samm 3-st',
   'page.marketing.campaignCreate.step.2': 'Kampaania seadistamine. 2. samm 3-st',
   'page.marketing.campaignCreate.step.3': 'Kampaania seadistamine. 3. samm 3-st',
   'page.marketing.smsCreate.step.1.title': 'Sõnum ja saatja ID',
   'page.marketing.smsCreate.step.1.defaultTitleForSms': 'Sisestage SMS saatja ID',
   'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Sisestage oma sõnum',
   'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Sisestage sõnumi pealkiri',
   'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Sisestage oma sõnum',
   'page.marketing.emailCreate.step.1.title': 'E-posti seaded',
   'page.marketing.emailCreate.step.1.messageTitle': 'Sõnumi pealkiri',
   'page.marketing.emailCreate.step.1.replyToEmail': 'Vastus e-kirjale',
   'page.marketing.emailCreate.step.1.clientReplies': 'Kliendi vastused saadetakse sellele e-kirjale',
   'page.marketing.emailCreate.step.1.photo.title': 'Foto',
   'page.marketing.emailCreate.step.1.enablePhoto': 'Foto lubamine',
   'page.marketing.emailCreate.step.1.changePhoto': 'Foto muutmine',
   'page.marketing.emailCreate.step.1.button.title': 'Nupp',
   'page.marketing.emailCreate.step.1.enableButton': 'Võta nupp sisse',
   'page.marketing.emailCreate.step.1.buttonName': 'Nupu nimi',
   'page.marketing.emailCreate.step.1.buttonURL': 'Nupu URL',
   'page.marketing.emailCreate.step.1.socialMedia.title': 'Sotsiaalmeedia',
   'page.marketing.emailCreate.step.1.enableSocialMedia': 'Sotsiaalmeedia linkide lubamine',
   'page.marketing.emailCreate.step.1.facebookPage': 'Facebooki leht',
   'page.marketing.emailCreate.step.1.instagramPage': 'Instagrami leht',
   'page.marketing.emailCreate.step.1.yourPage': 'Sinu leht',
   'page.marketing.smsCreate.yourMessage': 'Teie sõnum',
   'page.marketing.btn.sendMeTestMessage': 'Saada mulle testsõnum',
   'page.marketing.smsCreate.step.1.freeTests': 'Tänaseks on jäänud {0} tasuta testi',
   'page.marketing.smsCreate.messagePreview': 'Sõnumi eelvaade',
   'page.marketing.smsCreate.btn.clientSelection': 'Kliendi valik',
   'page.marketing.audience.title': 'Vaatajaskond',
   'page.marketing.smsCreate.step.2.description': 'Valige, millised kliendid saavad teie sõnumi',
   'page.marketing.smsCreate.step.2.allClient.title': 'Kõik kliendid',
   'page.marketing.smsCreate.step.2.allClient.description': 'Saatke sõnum kõigile oma klientidele',
   'page.marketing.smsCreate.step.2.clientGroups.title': 'Kliendirühmad',
   'page.marketing.smsCreate.step.2.clientGroups.description': 'Saatke sõnum valitud kliendirühmadele',
   'page.marketing.smsCreate.step.2.totalClients': 'Kliendid kokku',
   'page.marketing.smsCreate.step.2.totalPrice': 'Hind kokku',
   'page.marketing.smsCreate.step.3.description': 'Valige aeg, millal soovite oma sõnumit saata',
   'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Valige kohandatud kuupäev ja kellaaeg',
   'page.marketing.smsCreate.step.4.title': 'Maksmine masssõnumi eest',
   'page.marketing.smsCreate.step.4.description': 'Sisestage oma krediitkaardi andmed ja makske',
   'page.marketing.smsCreate.step.4.paymentAmount': 'Maksesumma:',
   'btn.scheduleSend': 'Saatmise ajakava',
   'btn.previous': 'Eelmine',
   'btn.payment': 'Maksmine',
   'btn.payAndFinish': 'Maksa ja lõpeta',
   'btn.backToDashboard': 'Tagasi armatuurlauale',
   'btn.saveAndClose': 'Salvesta ja sulge',
   'modal.editClientSelection.title': 'Kliendi valiku muutmine',
   'modal.newClient.description': 'Kliendid, mis on lisatud viimases:',
   'modal.recentClient.title': 'Viimased kliendid',
   'modal.recentClient.description': 'Kliendid, kes külastasid viimati:',
   'modal.clientByAge.title': 'Klient vanuse järgi',
   'modal.clientsByGender.title': 'Kliendid soo järgi',
   'modal.paymentConfirmation.title': 'Maksmine õnnestus !',
   'modal.paymentConfirmation.messageSend': 'Teie sõnum saadetakse',
   'modal.previewMessage.title': 'Eelvaade sõnumile',
   'modal.previewSms.tab': 'SMS-i eelvaade',
   'modal.previewEmail.tab': 'E-posti eelvaade',
   'modal.editServiceSelection.title': 'Teenuse valiku muutmine',
   'modal.editServiceSelection.allServices': 'Kõik teenused',
   'modal.campaignActivated.title': 'Kampaania aktiveeritud !',
   'modal.campaignActivated.description': 'Saate seda kampaaniat igal ajal peatada või redigeerida',
   'btn.all': 'Kõik {0}',
   'btn.included': 'Lisatud {0}',
   'btn.excluded': 'Välja arvatud {0}',
   'checkbox.allClients': 'Kõik kliendid {0}',
   'scheduleSend.time': 'aeg',
   'scheduleSend.timeSetTo': 'Aeg, mis on määratud:',
   'scheduleSend.setTime': 'Määratud aeg',
   'campaignSetup.step.1.header.title': 'Kampaania seadistus',
   'campaignSetup.step.2.header.title': 'Sõnumi muutmine',
   'campaignSetup.step.3.header.title': 'Vaadake üle',
   'campaignSetup.channel.description': 'Valige, milliseid kanaleid kampaania saadetakse',
   'campaignSetup.smsSetup.tab': 'SMS-i seadistamine',
   'campaignSetup.emailSetup.tab': 'E-posti seadistamine',
   'campaignSetup.almostDone.title': 'Peaaegu valmis !',
   'campaignSetup.almostDone.description':
      'Teie nutikas kampaania on valmis, kui see on sisse lülitatud, saadab see automaatselt jooksvalt klientidele sõnumeid. Te saate seda kampaaniat igal ajal hõlpsasti muuta või peatada',
   'campaignSetup.approximatePrice.header': 'Ligikaudne hind',
   'campaignSetup.approximatePrice.description': 'Eelseisva 30 päeva eest',
   'campaignSetup.approximatePrice.approx': 'Umbes',
   'campaign.birthdaySpecial.description': 'See kampaania saadab automaatselt klientidele nende sünnipäeva lähedale',
   'campaign.birthdaySpecial.sendToClient': 'Saada kliendile:',
   'campaign.birthdaySpecial.selectOption.1': 'Sünnipäeva päeval',
   'campaign.birthdaySpecial.selectOption.2': 'Kolm päeva enne sünnipäeva',
   'campaign.birthdaySpecial.selectOption.3': 'Üks nädal enne sünnipäeva',
   'campaign.birthdaySpecial.selectOption.4': 'Kaks nädalat enne sünnipäeva',
   'campaign.winBackClients.description':
      'See kampaania saadetakse klientidele, kes ei ole teatud aja jooksul tagasi pöördunud',
   'campaign.winBackClients.clientsWhoDidntReturn': 'Klientidele, kes ei pöördunud tagasi viimase',
   'campaign.winBackClients.appliedTo': 'Rakendatakse',
   'campaign.winBackClients.all': 'kõikidele',
   'campaign.winBackClients.services': 'teenustele',
   'campaign.winBackClients.editSelection': 'Valiku muutmine',
   'campaign.welcomeNewClients.description':
      'Muutke uustulnukad püsiklientideks, julgustades esmakordseid kliente erisoodustusega uuesti broneerima',
   'campaign.welcomeNewClients.smartCampaignNewClients':
      'See nutikas kampaania saadetakse uutele klientidele automaatselt üks päev pärast nende esmakordset müüki',
   'campaign.rewardRegularClients.description':
      'See kampaania saadetakse automaatselt klientidele, mis põhineb hiljutisel kohtumisaktiivsusel',
   'campaign.rewardRegularClients.clientsWithAtLeast': 'Kliendid, kellel on vähemalt',
   'campaign.rewardRegularClients.appointmentsInThe': 'kohtumisi',
   'campaign.rewardRegularClients.last': 'viimase',
   'campaign.rewardRegularClients.appointments': 'kohtumisi',
   'campaign.rewardRegularClients.inTheLast': 'viimase',
   'campaign.rewardRegularClients.months': 'kuud',
   'subscriptionPaymentFailed.title':
      'Uuendage oma maksemeetodit kuni {0}, vastasel juhul katkestatakse kõik lisatasud, tänan teid.',
   'subscriptionCancelled.title':
      'Vabandame, kuid teie Premium on maksmata jätmise tõttu deaktiveeritud. Kui soovite jätkata lisatasu funktsioonide kasutamist, aktiveerige Premium-pakett, aitäh.',
   'notificationPaymentFailed.title':
      'Teie makseteadete eest maksmine ebaõnnestus. Palun sooritage makse kuni {0}, muidu teavitused keelatakse, täname.',
   'notificationCancelled.title':
      'Vabandame, kuid teie teated on maksmata jätmise tõttu deaktiveeritud. Kui soovite jätkata teatiste saatmist, palun tasuge, aitäh.',
   'btn.hideOptions': 'Peida valikud',
   'btn.showOptions': 'Näita valikuid',
   'notification.googleDisconnected': 'Google Calendar lahti ühendatud',
   'notification.googleConnected': 'Google kalender ühendatud',
   'notification.appleDisconnected': "Apple'i kalender lahti ühendatud",
   'notification.appleConnected': "Apple'i kalender ühendatud",
   'notification.microsoft365Disconnected': 'Microsoft365 kalender lahti ühendatud',
   'notification.microsoft365Connected': 'Microsoft365 kalender ühendatud',
   'notification.linkCopied': 'Link kopeeritud',
   'calendarSync.title': 'Kalendri sünkroonimine',
   'calendarSync.description':
      'Sünkroonige Plandoki kohtumisi oma lemmikkalendriga, et organiseerida vaevata ja mitte kunagi ei jääks ühtegi olulist sündmust vahele.',
   'googleCalendar.title': 'Google kalender',
   'appleCalendar.title': "Apple'i kalender",
   'microsoft365Calendar.title': 'Microsoft365 kalender',
   'linkYourCalendar.title': 'Ühendage oma kalendrid',
   'connect.btn': 'Ühendage',
   'disconnect.rtn': 'Ühendage lahti',
   'connected.label': 'Ühendatud',
   'modal.workingHours.standartMethod.btn': 'Standart meetod',
   'modal.workingHours.customMethod.btn': 'Kohandatud meetod',
   'modal.workingHours.workingDates.input': 'Töötavad kuupäevad',
   'modal.workingHours.selectDates.placeholder': 'Valige kuupäevad',
   'noInternetConnection.notification.title': 'Internetiühendus puudub',
   'app.update.title': 'Uus versioon saadaval',
   'app.update.description.title':
      'Lisasime uusi funktsioone ja parandasime mõned vead, et muuta teie kogemus Plandokiga võimalikult sujuvaks.',
   'app.update.button.title': 'Uuendage',
   'app.notNow.button.title': 'Mitte nüüd',
   'app.updateAvailable.button.title': 'Uus versioon saadaval',
   'upgradePlan.success.message': 'Tellimus on edukalt uuendatud',
   'upgradePlan.error.message':
      'Tellimuse uuendamise viga, palun võtke ühendust klienditoega, kui teie kaardilt on võetud raha ja tellimus ei ole uuendatud',
   'sidebar.inventory': 'Inventuur',
   'products.tab': 'Tooted',
   'suppliers.tab': 'Tarnijad',
   'stockOrders.tab': 'Lao tellimused',
   'stockTracking.tab': 'Varude jälgimine',
   'input.productsSearch.placeholder': 'Otsing toote nime või vöötkoodi järgi',
   'product.create.btn.title': 'Uue toote lisamine',
   'table.products.product': 'Toode',
   'table.products.barcode': 'Vöötkood',
   'table.products.brand': 'Bränd',
   'table.products.sale': 'Müük',
   'table.products.saleOpt': 'Müügiv',
   'saleOptions.tooltip.title': 'Müügivalikud',
   'saleOptions.tooltip.description': 'Saate valida, kas lubada/välja lülitada müüki kassas.',
   'seeMore.title': 'vaata lähemalt',
   'option.products.downloadCSV': 'Lae CSV alla',
   'option.products.downloadExcel': 'Laadige alla Excel',
   'option.products.manageBrands': 'Halda kaubamärke',
   'option.products.manageCategories': 'Kategooriate haldamine',
   'option.products.manageStock': 'Varude haldamine',
   'option.products.importProducts': 'Importida tooteid',
   'modal.filter.categories.title': 'Kategooriad',
   'modal.filter.brands.title': 'Brands',
   'modal.filter.stock.title': 'Varud',
   'modal.filter.allCategories.selectItem': 'Kõik kategooriad',
   'modal.filter.allBrands.selectItem': 'Kõik kaubamärgid',
   'modal.filter.allSuppliers.selectItem': 'Kõik tarnijad',
   'modal.filter.allProducts.selectItem': 'Kõik tooted',
   'input.suppliersSearch.placeholder': 'Otsi tarnija nime järgi',
   'supplier.create.btn.title': 'Lisa uus tarnija',
   'table.suppliers.supplierName': 'Tarnija nimi',
   'table.suppliers.phoneNumber': 'Telefoninumber',
   'table.suppliers.physicalAddress': 'Füüsiline aadress',
   'validation.notification.reminderTime': 'Sama meeldetuletusajaga ei saa märguandeid saata.',
   'validation.paymentType.switches': 'Lubatud peab olema vähemalt üks makseviis',
   'plan.premium.option.14':
      '0% Plandoki vahendustasu Online Bookingsi kaudu krediitkaardiga broneeritud kohtumiste eest.',
   'title.dragAndExtend.appointment.permission': 'Saab kohtumisi lohistada ja pikendada',
   'button.lastVisit.addAnotherLastvisit': 'LISADA VEEL ÜKS VIIMANE KÜLASTUS',
   'notifications.template.badge.validate': 'Valideeri',
   'notifications.template.badge.refused': 'Keeldus',
   'paid.by.card': 'Makstakse kaardiga',
   'costs.manual.paid.link': 'Või saate märguannete eest maksta käsitsi oma kaardiga, klõpsates sellel lingil',
   'modal.remove.account.title': "Eemaldage Stripe'i konto",
   'modal.remove.account.description': 'Teie konto eemaldatakse ja te ei saa veebimakseid vastu võtta. ',
   'ob.remove.account.option': "Eemaldage Stripe'i konto",
   'btn.remove.account': 'Eemalda',
   'calendar.tooltip.selected': 'Valitud',
   'calendar.tooltip.no.preference': 'Ei mingit eelistust',
   'ob.gtag.manager.title': 'Google Tag Manager',
   'ob.analytics.gtag.description.1':
      "Google'i sildihaldur võimaldab teil mõõta teie reklaamide ROI-d ning jälgida teie Flashi, video- ja suhtlusvõrgustike saite ja rakendusi.",
   'ob.gtag.manager.description.1':
      'Google Tag Manager on tasuta sildihaldussüsteem, mis võimaldab hallata ja juurutada turundusmärgendeid (koodilõigud või jälgimispikslid) oma veebisaidil või mobiilirakenduses, ilma et peaksite koodi otse muutma.',
   'ob.gtag.manager.description.2':
      'Sisuliselt lihtsustab GTM turundusmärgendite haldamise protsessi, muutes veebisaidi toimivuse jälgimise, turunduskampaaniate mõõtmise ja kasutajakogemuste optimeerimise lihtsamaks.',
   'text.gender.male': 'Mees',
   'text.gender.female': 'Naine',
   'text.gender.unknown': 'Tundmatu',
}

export default labels
