import './style.less'

import { getIsMobile } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import cn from 'classnames'
import { AppLayout, HashTabs, MobileBackButton, NoInternetConnection, NotReleased } from 'components'
import { MarketingHash } from 'constants/routes'
import React from 'react'

import { MarketingTabsConfig } from './config'
import MarketingCampaigns from './MarketingCampaigns/MarketingCampaigns'
import MarketingMassmessages from './MarketingMassmessages/MarketingMassmessages'
import MarketingOverview from './MarketingOverview/MarketingOverview'

const MobileHeader = () => (
   <>
      <MobileBackButton />
      <div>
         <IntlLabel label="title.marketing.permission" />
      </div>
      <div />
   </>
)

export default function MarketingPage() {
   const isMobile = getIsMobile()

   return (
      <NotReleased>
         <NoInternetConnection>
            <AppLayout
               headerTitle="title.marketing.permission"
               mobileHeader={MobileHeader}
               className={cn('marketing-page', { 'Marketing-page__is-mobile': isMobile })}>
               <HashTabs tabs={MarketingTabsConfig} defaultTab={MarketingHash.OVERVIEW}>
                  <MarketingOverview />
                  <MarketingMassmessages />
                  <MarketingCampaigns />
               </HashTabs>
            </AppLayout>
         </NoInternetConnection>
      </NotReleased>
   )
}
