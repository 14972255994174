import { format } from 'date-fns/format'
import { LocalStorage } from '@plandok/core'
import Labels from '../labels/labels.en'
import { Language, LanguageFnsConfig, LanguagesBundle } from '../languages'
import { LabelKey } from '../types'

const getLabelPack = (lang: string): any => LanguagesBundle[lang] || Labels

export const getDateFnsLocale = (lang: string) => LanguageFnsConfig[lang] || LanguageFnsConfig[Language.ENG]

export const getLabelByIsoCode = (code: string) =>
   (
      ({
         li: Language.LIT,
         ...Object.entries(Language).reduce((acc, [k, v]) => {
            return { ...acc, [v]: v }
         }, {}),
      }) as any
   )[code] ||
   code ||
   Language.ENG

export const getLabel = (key: LabelKey, lang: string) =>
   getLabelPack(lang)[(key as any) || Language.ENG] || (Labels as any)[key] || key

export const getDocumentLang = () => document.documentElement?.getAttribute('lang') ?? Language.ENG

export const globalTranslate = (key: LabelKey) => getLabel(key, getDocumentLang())

export const translatesTemplate = (label: LabelKey, lang: string, params: any[]): string =>
   params.reduce((acc, e) => acc.replace('{0}', e), getLabel(label, lang))

export const translateStaticTemplate = (str: string, lang: string): string =>
   str
      .split('{')
      .map(e => (e.includes('}') ? getLabel((e as any).replace('}', ''), lang) : e))
      .join('')

export const formatTextByLang = (date: Date, dateFormat: string, lang: any) =>
   (format(date, dateFormat, { locale: LanguageFnsConfig[lang || 'en'] } as any) || '').replace(/\./g, '')

export const updateStorageLanguage = (languageIsoCode: any) => {
   const appContext = LocalStorage.getAppContext()
   if (appContext) {
      try {
         LocalStorage.setAppContext({ ...appContext, languageIsoCode })
      } catch (e) {
         console.error('Failed to parse appContext')
      }
   }
}
