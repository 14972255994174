import './style.less'

import { useAppContext } from '@plandok/core'
import { Spin } from 'antd'
import cn from 'classnames'
import { AppLayout, NoInternetConnection } from 'components'
import { AnalyticsHash } from 'constants/routes'
import { differenceInCalendarDays, format, isValid, subDays } from 'date-fns'
import { App_AnalyticsDateInterval, App_AppointmentsGraphStep } from 'graphql/generatedTypes'
import { useAnalyticsQuery } from 'graphql/queries/analytics.generated'
import React, { useState } from 'react'

import { AnalyticsDashboard } from './AnalyticsDashboard/AnalyticsDashboard'
import Reports from './AnalyticsReports/components/Reports'
import { AnalyticsErrorState } from './components/AnalyticsErrorsState'
import { AnalyticsMobileHeader } from './components/AnalyticsMobileHeader'
import { analyticsTabsConfig } from './config'
import * as SC from './styles'

type FromDate = Date | string | null
type TimeRangeData = { timeRange: Date; startDate: Date | null; endDate: Date | null }

export default function AnalyticsPage() {
   const [context] = useAppContext()
   const [isStepByDay, setIsStepByDay] = useState(false)
   const [timeRangeData, setTimeRangeData] = useState<TimeRangeData>({} as TimeRangeData)

   const isPremium = context?.subscription?.isPremium

   const toDate = isValid(timeRangeData.endDate)
      ? format(timeRangeData.endDate ?? 0, 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd')

   const defaultFormatLast7Days = format(subDays(new Date(), 7), 'yyyy-MM-dd')
   const formattedTimeRange = isValid(timeRangeData.timeRange) ? format(timeRangeData.timeRange, 'yyyy-MM-dd') : ''

   const getFromDate = (): FromDate => {
      const isTimeRangeSelected = isValid(timeRangeData.timeRange)
      const isNoDaysSelected = !isValid(timeRangeData.timeRange) && !isValid(timeRangeData.endDate)
      const isCustomRangeSelected = isValid(timeRangeData.startDate) && isValid(timeRangeData.endDate)

      if (isTimeRangeSelected) {
         return formattedTimeRange
      }

      if (isNoDaysSelected) {
         return defaultFormatLast7Days
      }

      if (isCustomRangeSelected) {
         return format(timeRangeData.startDate ?? 0, 'yyyy-MM-dd')
      }

      return ''
   }

   const differenceInDays = differenceInCalendarDays(new Date(), new Date(getFromDate() || 0))
   const fromDatePrevious = format(subDays(new Date(getFromDate() ?? 0), differenceInDays), 'yyyy-MM-dd')

   const customRangeDifferenceInDays = differenceInCalendarDays(
      timeRangeData.endDate || 0,
      timeRangeData.startDate || 0,
   )
   const isCustomRangeInDays = customRangeDifferenceInDays <= 31
   const isSelectedRangeByDay = isStepByDay || (!isValid(timeRangeData.timeRange) && isCustomRangeInDays)

   const analyticsVariables = {
      fromDate: getFromDate(),
      fromDatePrevious,
      toDate,
      toDatePrevious: getFromDate(),
      range: App_AnalyticsDateInterval.Month,
      step: isSelectedRangeByDay ? App_AppointmentsGraphStep.Day1 : App_AppointmentsGraphStep.Month1,
   }

   const {
      data: analyticsData,
      loading: analyticsLoading,
      error: analyticsErrors,
   } = useAnalyticsQuery({
      variables: analyticsVariables,
   })

   const analyticsDashboardErrors = analyticsErrors

   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="analytics.title"
            headerIconTitle="analytics.premium.title"
            mobileHeader={() => AnalyticsMobileHeader('analytics.dashboard.tab')}
            mobileBackground="#fff"
            mobileWhiteBg
            className={cn('analytics-page')}>
            <SC.HashTabsWrapper
               className="analytics-tabs"
               tabs={analyticsTabsConfig}
               defaultTab={AnalyticsHash.DASHBOARD}>
               <Spin spinning={analyticsLoading}>
                  {analyticsDashboardErrors && AnalyticsErrorState(analyticsDashboardErrors)}
                  {!analyticsDashboardErrors && analyticsData && (
                     <AnalyticsDashboard
                        analyticsData={analyticsData}
                        isPremium={isPremium}
                        isStepByDay={isStepByDay}
                        setTimeRangeData={setTimeRangeData}
                        setIsStepByDay={setIsStepByDay}
                     />
                  )}
               </Spin>
               <AppLayout.Container maxWidth={true ? 'inherit' : ''} noMargin noWhiteBG>
                  <Reports />
               </AppLayout.Container>
            </SC.HashTabsWrapper>
         </AppLayout>
      </NoInternetConnection>
   )
}
