import React, { memo } from 'react'
import omit from 'lodash/omit'
import { Form as AntForm, message } from 'antd'
import { Form as FinalForm } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { IntlLabel } from '@plandok/i18n'
import { extractFormError } from '@plandok/core'
import FormAlert from './components/FormAlert'
import focusDecorator from '../../support/final-form-focus/index'
import * as helpers from './heleprs'
import pick from 'lodash/pick'

const Form = ({
   children,
   setRef,
   render,
   formatForm,
   decorators,
   onSubmit,
   onSuccess,
   excludeList,
   includeList,
   className,
   successMessage,
   // To set Phone number to null if only prefix selected +370
   combinedFields,
   ...rest
}: any) => {
   const onFormSubmit = (fValues: any, finalForm: any) => {
      const form = helpers.createRequestData(fValues, finalForm, formatForm, combinedFields)
      return onSubmit(includeList ? pick(form, includeList) : excludeList ? omit(form, excludeList) : form)
         .then((res: any) => {
            if (res && res.errors) {
               return res.errors
            } else if (successMessage) {
               message.success((<IntlLabel label={successMessage} />) as any)
            }

            return onSuccess ? onSuccess(res, form) : res
         })
         .catch((err: any) => {
            return (err || {}).errors
         })
   }
   return (
      <FinalForm
         {...rest}
         ref={setRef}
         validateOnBlur={false}
         decorators={decorators}
         onSubmit={onFormSubmit}
         className={className}
      >
         {(props: any) => (
            <AntForm onFinish={props.handleSubmit} layout="vertical">
               {(render || children)({
                  ...props,
                  formError: extractFormError(props),
               })}
            </AntForm>
         )}
      </FinalForm>
   )
}

Form.Alert = FormAlert

Form.defaultProps = {
   decorators: [focusDecorator()],
   mutators: arrayMutators,
}

export default Form
