import { useQuery } from '@tanstack/react-query'
import { Spin } from 'antd'
import * as api from 'api'
import { QueryType } from 'api/query'
import React, { ReactElement } from 'react'

type NotificationFormApiProps = {
   children(value: { initialValues: any; onSubmit: (form: any) => void }): ReactElement
   notificationId: string
}

export default function NotificationFormApi({ children, notificationId }: NotificationFormApiProps) {
   const { data, isFetching: isLoading } = useQuery({
      queryKey: [QueryType.NOTIFICATIONS, notificationId],
      queryFn: () => api.fetchNotificationConfig(notificationId),
   })

   return (
      <Spin spinning={isLoading}>
         {children({
            initialValues: data?.data,
            onSubmit: (form: any) => {
               const timestampRegex = /^\d{10,13}$/
               if (form.hasOwnProperty('id')) {
                  const { id, ...newTemplate } = form
                  if (timestampRegex.test(form.id)) {
                     return api.createNotificationConfig(notificationId, newTemplate)
                  } else {
                     return api.updateNotificationConfig(
                        { templateId: id, notificationType: notificationId },
                        newTemplate,
                     )
                  }
               } else {
                  return api.changeNotificationStatus(notificationId, form)
               }
            },
         })}
      </Spin>
   )
}
