import { useModal } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { useQuery } from '@tanstack/react-query'
import * as api from 'api'
import { AppLayout, NoInternetConnection, Placeholder } from 'components'
import { RoutePath } from 'constants/routes'
import { onReschedule } from 'helpers/app/reschedule'
import React, { useEffect, useState } from 'react'
import { isFirefox } from 'react-device-detect'

import MultipleAddButton from './components/MultipleAddButton'
import ServiceGroupList from './components/ServiceGroupList'
import ServicesMobileHeader from './components/ServicesMobileHeader'
import * as helpers from './helpers'

let serviceGroupId: string

function ServicesPage(props: any) {
   const [, setItem] = useState(props.data)

   const { data, isLoading, refetch } = useQuery({
      queryKey: ['service_groups'],
      queryFn: api.fetchCategories,
   })

   const [, { showModal }] = useModal()
   const { navigate } = useLocation()

   const createService = (serviceGroupId?: string) =>
      navigate(RoutePath.SERVICE_CREATE + (serviceGroupId ? `?serviceGroupId=${serviceGroupId}` : ''))

   const editService = (id: string, groupId: string) => {
      navigate(RoutePath.SERVICE_EDIT, id)
      serviceGroupId = groupId
   }

   const createCategory = () =>
      showModal({
         type: 'CREATE_CATEGORY',
         modalProps: { onSuccess: refetch, initialValues: { color: '#6cd5cb' } },
      })

   const editCategory = (id: string) => showModal({ type: 'CREATE_CATEGORY', modalProps: { id, onSuccess: refetch } })

   const onItemDrop = (id: any) => (params: any) => setItem(helpers.moveElementItem(data, id, params))

   const scrollToCurrentService = (id: string) => {
      // Firefox does not support center scroll
      document.getElementById(id)?.scrollIntoView?.({ block: isFirefox ? 'start' : 'center', behavior: 'smooth' })
   }

   useEffect(() => {
      scrollToCurrentService(serviceGroupId)
   }, [scrollToCurrentService])

   return (
      <NoInternetConnection>
         <AppLayout
            mobileHeader={ServicesMobileHeader}
            headerTitle="sidebar.service"
            customButton={
               !props.showPlaceholder ? (
                  <MultipleAddButton
                     actions={[
                        {
                           icon: 'newServiceGroup',
                           title: 'btn.newServiceGroup',
                           action: createCategory,
                        },
                        {
                           icon: 'newService',
                           title: 'service.new.title',
                           action: createService,
                        },
                     ]}
                  />
               ) : null
            }>
            {props.showPlaceholder ? (
               <Placeholder
                  imgName="services"
                  btnLabel="state.services.button"
                  descriptionLabel="state.services.description"
                  titleLabel="state.services.title"
                  onClick={createCategory}
                  position="relative"
               />
            ) : (
               <ServiceGroupList
                  isLoading={isLoading}
                  fetchServiceGroups={refetch}
                  data={data?.serviceGroups ?? []}
                  onSortEnd={onReschedule({
                     data: data?.serviceGroups ?? [],
                     changeData: refetch,
                     onPositionUpdate: async (id: string, newPosition: number) =>
                        await api.changeServiceGroupPosition('', { id, newPosition }),
                  })}
                  changeData={refetch}
                  onItemDrop={onItemDrop}
                  createService={createService}
                  createCategory={createCategory}
                  editCategory={editCategory}
                  editService={editService}
               />
            )}
         </AppLayout>
      </NoInternetConnection>
   )
}

export default ServicesPage
