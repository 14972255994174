const startTimeOptions = [...Array(24 * 12)].map((_, i) => ({
   name: `${Math.floor((i * 5) / 60)}:${((i * 5) % 60).toString().padStart(2, '0')}`,
   code: i * 5,
}))
const startWholeHourTimeOptions = [...Array(24)].map((_, i) => ({
   name: `${i.toString().padStart(2, '0')}:00`,
   code: i * 60,
}))

const getHours = (format: string, startIndex = 0) =>
   [...Array(12 * 12)].map((_, i) => {
      let name = `${Math.floor((i * 5) / 60)}:${((i * 5) % 60).toString().padStart(2, '0')} ${format}M`

      if (name.startsWith('0')) {
         name = name.replace('0', '12')
      }

      return {
         name,
         code: (i + startIndex) * 5,
      }
   })
const getWholeHour = (format: string, startIndex = 0) =>
   [...Array(12)].map((_, i) => {
      let hour = i + 1
      let name = `${hour}:00 ${format}M`

      return {
         name,
         code: (i + startIndex) * 60,
      }
   })

const startTimeOptionsFor12Hours = [...getHours('A'), ...getHours('P', 144)]
const startWholeHourTimeOptionsFor12Hours = [...getWholeHour('A'), ...getWholeHour('P', 144)]

export const getTimeOptions = (timeFormat: string) =>
   timeFormat === '12h' ? startTimeOptionsFor12Hours : startTimeOptions

export const getWholeHourOptions = (timeFormat: string) =>
   timeFormat === '12h' ? startWholeHourTimeOptionsFor12Hours : startWholeHourTimeOptions
