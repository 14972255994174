import { Dropdown as AntDropdown, Menu } from 'antd'
import React, { ReactNode } from 'react'

type DropdownProps = {
   children?: ReactNode
   onChange: (id: string) => void
   placement?: 'bottomLeft' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter' | 'bottomRight' | undefined
   disabled?: boolean
   menu: {
      id: string
      element: ReactNode
   }[]
}

type DropdownMenuProps = {
   onChange: DropdownProps['onChange']
   menu: DropdownProps['menu']
   [key: string]: any
}

const DropdownMenu = ({ onChange, menu, ...props }: DropdownMenuProps) => (
   <Menu className="app-common-dropdown" {...props}>
      {menu?.map(({ id, element }) => (
         <Menu.Item onClick={() => onChange(id)} key={id}>
            {element}
         </Menu.Item>
      ))}
   </Menu>
)

export default function Dropdown(props: DropdownProps) {
   return (
      <AntDropdown
         disabled={props.disabled}
         trigger={['click']}
         dropdownRender={(prop: any) => <DropdownMenu {...prop} onChange={props.onChange} menu={props.menu} />}
         placement={props.placement || 'bottomLeft'}
      >
         {props.children}
      </AntDropdown>
   )
}
