import { IntlLabel } from '@plandok/i18n'
import { MobileBackButton } from 'components'
import React from 'react'

export default function MessagesMobileHeader() {
   return (
      <>
         <MobileBackButton />
         <IntlLabel label="Auto-sending Campaigns" />
         <div />
      </>
   )
}
