import { ModifyMassLayout, NotReleased } from 'components'
import { RoutePath } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import SheduleSendForm from './components/SheduleSendForm'

export const SheduleSendFormWithApi = withFormApi({
   backPath: RoutePath.CLIENT_SELECTION,
   successMessage: 'Massage',
})(SheduleSendForm)

export default function ModifyScheduleSendPage() {
   return (
      <NotReleased>
         <ModifyMassLayout title="Schedule send" step="SMS setup. Step 3 of 4">
            <SheduleSendFormWithApi />
         </ModifyMassLayout>
      </NotReleased>
   )
}
