import { Card, mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const CardContainer = styled(Card)`
   max-width: 980px;
   width: 780px;
   margin: auto;
   box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
   border-radius: 20px;
   padding: 0 60px;
   input[type='number']::-webkit-outer-spin-button,
   input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   ${mediaMobileDown(css`
      width: 355px;
      padding: 0 10px;
      margin: 100px auto 0 auto;
   `)}
`

export const HeaderTitle = styled.div`
   text-align: center;
   padding: 60px 40px 50px 40px;
   ${mediaMobileDown(css`
      padding: 20px 15px 20px 15px;
      div: last-child {
         width: 230px;
         margin: auto;
      }
   `)}
`

export const PaymentAmount = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   button {
      min-width: 180px;
   }
`

export const PayFinishBtn = styled.div`
   margin: 0 auto 60px auto;
   button {
      min-width: 180px;
   }
   ${mediaMobileDown(css`
      margin: 0 auto 30px auto;
   `)}
`
