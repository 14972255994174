import { theme } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Avatar, Dropdown } from 'antd'
import { RoutePath } from 'constants/routes'
import React from 'react'
import { Link } from 'react-router-dom'

type HeaderUserMenuProps = {
   openSelectLanguage: () => void
   logout: () => void
   displayName?: string
}

export default function HeaderUserMenu({ openSelectLanguage, displayName, ...props }: HeaderUserMenuProps) {
   const menuItems: any = [
      {
         key: 'settings',
         label: (
            <Link to={RoutePath.PERSONAL_SETTINGS}>
               <IntlLabel label="user.menu.settings" />
            </Link>
         ),
      },
      {
         key: 'language',
         label: <IntlLabel label="user.menu.language" />,
         onClick: openSelectLanguage,
      },
      {
         key: 'divider',
         type: 'divider',
      },
      {
         key: 'logout',
         label: (
            <Link to={RoutePath.LOGOUT} style={{ color: theme.dangerColor }}>
               <IntlLabel label="user.menu.logout" />
            </Link>
         ),
      },
   ]

   return (
      <Dropdown {...props} menu={{ items: menuItems }} trigger={['click']}>
         <Avatar>{displayName?.[0]?.toUpperCase?.() ?? ' '}</Avatar>
      </Dropdown>
   )
}
