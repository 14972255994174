import { useQuery } from '@tanstack/react-query'
import * as api from 'api'
import { QueryType } from 'api/query'

export const useGetEstimatedCosts = (staffCount?: number) => {
   const { data: estimatedCostsData, isLoading } = useQuery({
      queryKey: [QueryType.ESTIMATE_NOTIFICATION],
      queryFn: () => (staffCount ? api.fetchEstimatedCostsWithStaffCount('', staffCount) : api.fetchEstimatedCosts()),
   })

   return { estimatedCostsData: estimatedCostsData?.data, isLoading }
}
