import { Col, Row } from 'antd'
import { AppLayout, NoInternetConnection } from 'components'
import React from 'react'

import SettingCard from './components/SettingCard'
import SettingsMobileHeader from './components/SettingsMobileHeader'
import { cardSettings } from './config'

export default function SettingsPage() {
   return (
      <NoInternetConnection>
         <AppLayout mobileHeader={SettingsMobileHeader} headerTitle="sidebar.settings">
            <AppLayout.Container>
               <Row gutter={20}>
                  <Col md={12} span={24}>
                     <SettingCard {...cardSettings.ACCOUNT} account />
                  </Col>
                  <Col md={12} span={24}>
                     <SettingCard {...cardSettings.CLIENT} />
                     <SettingCard {...cardSettings.BOOKING} />
                  </Col>
               </Row>
            </AppLayout.Container>
         </AppLayout>
      </NoInternetConnection>
   )
}
