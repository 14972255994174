import { addedClientdateOption, Button, Field, Form, Text, validateRequired, withModal } from '@plandok/core'
import { Divider } from 'antd'
import * as api from 'api'
import { NotReleased } from 'components'
import { addedClientViewTypeOptions } from 'constants/data'
import { flow, noop } from 'lodash'
import React from 'react'

import * as SC from '../styles'

function CreateRecentClientModal(props: any) {
   return (
      <NotReleased>
         <Form
            onSubmit={(form: any) => api.closeAppointment(props.modalProps.Number, form)}
            onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}>
            {({ submitting }: any) => (
               <SC.StyledContainer>
                  <Text label="Clients who visited in the last:" size="base" mb="none" />
                  <Field.Row className="time-row">
                     <Field.Select
                        name="addedDate"
                        options={addedClientdateOption}
                        md={10}
                        validate={validateRequired}
                        defaultValue={30}
                     />
                     <Field.Select
                        name="defaultView"
                        options={addedClientViewTypeOptions}
                        validate={validateRequired}
                        translate
                        md={14}
                        defaultValue="days"
                     />
                  </Field.Row>
                  <SC.Divider as={Divider} />
                  <Button type="primary" label="btn.save" htmlType="submit" loading={submitting} upperCase={false} />
               </SC.StyledContainer>
            )}
         </Form>
      </NotReleased>
   )
}

export default withModal('Recent Client', { contentClassName: 'age-modal-content' })(CreateRecentClientModal)
