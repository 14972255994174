import styled from 'styled-components'

export const Container = styled.div<any>`
   max-height: ${props => (props.open ? 'initial' : '70px')};
   position: relative;
   overflow: hidden;
   font-size: 14px;
   color: ${props => props.theme.primaryColor};
   margin-bottom: 5px;
`

export const FadeContainer = styled.div`
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   position: absolute;
   background-image: linear-gradient(to bottom, transparent, white);
`

export const Toggle = styled.div`
   position: relative;
   font-size: 14px;
   cursor: pointer;
   font-weight: 500;
   color: ${props => props.theme.primaryColor};
   i {
      font-size: 12px;
   }
`
