import { withRouter } from '@plandok/core'
import { AuthLayout, NoInternetConnection } from 'components'
import { RoutePath } from 'constants/routes'
import React from 'react'

import ResetPasswordFooter from './components/ResetPasswordFooter'
import ResetPasswordForm from './components/ResetPasswordForm'

function ResetPasswordPage() {
   return (
      <NoInternetConnection>
         <AuthLayout title="reset.title" mobileDescription="reset.description" page={RoutePath.RESET_PASSWORD}>
            <AuthLayout.Card>
               <ResetPasswordForm />
            </AuthLayout.Card>
            <br />
            <ResetPasswordFooter />
         </AuthLayout>
      </NoInternetConnection>
   )
}

export default withRouter(ResetPasswordPage)
