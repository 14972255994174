import { IntlLabel } from '@plandok/i18n'
import { Checkbox } from 'antd'
import intersection from 'lodash/intersection'
import xor from 'lodash/xor'
import React from 'react'

export default function CustomCheckbox({ options, value, onChange }: any) {
   const codes = options.map(({ value: { code } }: any) => code)

   const isSelectAll = (intersection(codes, value) || []).length === options.length
   const toggleSelectAll = () => onChange(isSelectAll ? xor(codes, value) : [...value, ...codes])

   return (
      <Checkbox
         checked={isSelectAll}
         indeterminate={!isSelectAll && ((intersection(codes, value) || []).length as any)}
         onChange={toggleSelectAll}>
         <IntlLabel label="button.select.all" />
      </Checkbox>
   )
}
