import { useDispatch, useSelector } from 'react-redux'
import {
   setAppContext,
   setTimeZone,
   updateCompanyDetails,
   setDisplayName,
   resetAppContext,
   changeLanguage,
   InitialStateType,
   refreshAppContext,
} from './appStore'

export const useAppContext = () => {
   const context: InitialStateType = useSelector((state: any) => state.appContext)
   const dispatch = useDispatch()

   const actions = {
      setAppContext: (payload: any) => dispatch(setAppContext(payload)),
      setTimeZone: (timeZone: string) => dispatch(setTimeZone(timeZone)),
      updateCompanyDetails: (details: { country: string; timeZone: string }) => dispatch(updateCompanyDetails(details)),
      setDisplayName: (displayName: string) => dispatch(setDisplayName(displayName)),
      resetAppContext: () => dispatch(resetAppContext()),
      changeLanguage: (languageIsoCode: string) => dispatch(changeLanguage(languageIsoCode)),
      refreshAppContext: async () => dispatch(refreshAppContext()),
   }

   return [context, actions] as const
}
