import { CloseCircleOutlined } from '@ant-design/icons'
import { Text } from '@plandok/core'
import { useHistory } from '@plandok/core'
import { IntlLabel, LabelKey } from '@plandok/i18n'
import { useHotkeys } from 'hooks/hotkey'
import React from 'react'
import { ReactNode } from 'react'

import ModifyMassFooter from './components/ModifyMassFooter'
import * as SC from './styles'

function ModifyMassLayout(props: {
   children: ReactNode
   title?: LabelKey
   className?: string
   mdWhite?: boolean
   style?: any
   onClose?: any
   step?: LabelKey
}) {
   const history = useHistory()

   const close = () => {
      if (props.onClose) {
         props.onClose()
      }
      if (props.step === 'SMS setup. Step 4 of 4') {
         history.go(-5)
      }
      if (props.step === 'SMS setup. Step 3 of 4') {
         history.go(-4)
      }
      if (props.step === 'SMS setup. Step 3 of 4' || props.step === 'Campaign setup. Step 3 of 3') {
         history.go(-3)
      }
      if (props.step === 'SMS setup. Step 2 of 4' || props.step === 'Campaign setup. Step 2 of 3') {
         history.go(-2)
      }
      if (
         props.step === 'SMS setup. Step 1 of 4' ||
         props.step === 'Campaign setup. Step 1 of 3' ||
         props.step === 'Email setup. Step 1 of 4'
      ) {
         history.go(-1)
      }
   }

   useHotkeys('esc', close)

   return (
      <SC.Container className={props.className} mdWhite={props.mdWhite} style={props.style}>
         <SC.Header>
            <SC.HeaderTitle>
               <Text label={props.step} weight="normal" size="base" mb="none" />
               {props.title && (
                  <SC.Title>
                     <IntlLabel label={props.title} />
                  </SC.Title>
               )}
            </SC.HeaderTitle>
            <SC.CloseIcon onClick={close}>
               <CloseCircleOutlined />
            </SC.CloseIcon>
         </SC.Header>
         <SC.Content>{props.children}</SC.Content>
      </SC.Container>
   )
}

ModifyMassLayout.Footer = ModifyMassFooter

export default ModifyMassLayout
