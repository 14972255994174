export const notificationType = [
   { value: 'sms', label: 'SMS', premium: true },
   { value: 'email', label: 'Email' },
   { value: 'whatsapp', label: 'WhatsApp', premium: true },
]

export const senderTooltip = [
   { key: 'tooltip.sender.0' },
   { key: 'tooltip.sender.1' },
   { key: 'tooltip.sender.2' },
   { key: 'tooltip.sender.3' },
] as any

export const tags = [
   { label: 'CLIENT_FIRST_NAME', value: 'CLIENT_FIRST_NAME' },
   { label: 'CLIENT_LAST_NAME', value: 'CLIENT_LAST_NAME' },
   { label: 'STAFF_FIRST_NAME', value: 'STAFF_FIRST_NAME' },
   { label: 'STAFF_LAST_NAME', value: 'STAFF_LAST_NAME' },
   { label: 'BOOKING_DATE_TIME', value: 'BOOKING_DATE_TIME' },
   { label: 'BOOKING_DATE', value: 'BOOKING_DATE' },
   { label: 'BOOKING_TIME', value: 'BOOKING_TIME' },
   { label: 'SERVICE_NAME', value: 'SERVICE_NAME' },
   { label: 'BUSINESS_NAME', value: 'BUSINESS_NAME' },
   { label: 'BUSINESS_PHONE', value: 'BUSINESS_PHONE' },
   { label: 'BUSINESS_ADDRESS', value: 'BUSINESS_ADDRESS' },
   { label: 'BUSINESS_EMAIL', value: 'BUSINESS_EMAIL' },
   { label: 'LOCATION_NAME', value: 'LOCATION_NAME' },
   { label: 'LOCATION_PHONE', value: 'LOCATION_PHONE' },
   { label: 'LOCATION_ADDRESS', value: 'LOCATION_ADDRESS' },
   { label: 'LOCATION_EMAIL', value: 'LOCATION_EMAIL' },
]
