import React from 'react'
import ReactDatePicker from 'react-datepicker'

import DatepickerHeader from './components/DatepickerHeader'

const CustomTimeDatePicker = ({ ...props }: any & { value?: Date; onClear?: () => any }) => {
   return (
      <ReactDatePicker
         renderCustomHeader={DatepickerHeader}
         {...props}
         inline
         showTimeSelect
         timeFormat="HH:mm"
         timeIntervals={15}
         timeCaption="time"
         dateFormat="MMMM d, yyyy h:mm aa"
      />
   )
}

export default CustomTimeDatePicker
