import { isSameDay } from 'date-fns'

import { formatDateField } from '../../../../../helpers/date/field'

export const getHourRequestParams = (form: any, date: any, anotherShift: string[], onlyShits?: boolean) => {
   const shifts = [{ startTime: form.shiftStart, endTime: form.shiftEnd }]

   if (anotherShift.includes('secondShift')) {
      shifts.push({
         startTime: form.shiftStartSecond,
         endTime: form.shiftEndSecond,
      })
   }
   if (anotherShift.includes('thirdShift')) {
      shifts.push({
         startTime: form.shiftStartThird,
         endTime: form.shiftEndThird,
      })
   }
   if (anotherShift.includes('fourthShift')) {
      shifts.push({
         startTime: form.shiftStartFourth,
         endTime: form.shiftEndFourth,
      })
   }
   let endDate
   if (form.repeat === 'norepeat' || onlyShits) {
      endDate = formatDateField(date || new Date())
   } else if (form.endRepeat === 'ongoing') {
      endDate = null
   } else if (form.endRepeat === 'specific') {
      endDate = formatDateField(form.endRepeatDate || new Date())
   }
   return {
      locationId: form.locationId,
      startDate: formatDateField(date || new Date()),
      endDate,
      shifts,
      onlyShits,
   }
}

export const filterSpecificDate = (startDate: any) => (date: Date) => {
   return startDate?.getDay() === date?.getDay()
}

export const getIsRequireConfirm = (initialValues: any, date: Date) => {
   return initialValues?.repeat === 'weekly' && !isSameDay(initialValues?.endRepeatDate, date)
}

export const getIsRequireConfirmUpdate = (initialValues: any, form: any, date: Date) => {
   return (
      initialValues &&
      form.endRepeat === initialValues?.endRepeat &&
      form?.repeat === 'weekly' &&
      !isSameDay(form?.endRepeatDate, date)
   )
}
