import Icon from '@ant-design/icons'
import { Button, CustomIcon, Form, getIsMobile, Text, useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Checkbox } from 'antd'
import { ModifyMassLayout } from 'components'
import { RoutePath } from 'constants/routes'
import { flow, noop } from 'lodash'
import React, { useState } from 'react'

import * as SC from '../styles'

export default function SheduleSendForm(props: any) {
   const [clientType, setClientType] = useState(0)
   const { navigate, goBack } = useLocation()

   const payment = () => navigate(RoutePath.PAYMENT_DETILES)
   const selectionCustom = () => navigate(RoutePath.SCHEDULE_SEND_CUSTOM_TIME)
   const isMobile = getIsMobile()

   const schedulSendData = [
      {
         text: 'Right after payment',
      },
      {
         text: 'Tommorow morning',
         date: '13 Jan, 8:00',
      },
      {
         text: 'Tommorow afternoon',
         date: '13 Jan, 13:00',
      },
      {
         text: 'Monday morning',
         date: '18 Jan, 8:00',
      },
   ]
   const handleSubmit = (form: any) =>
      props.onSubmit({
         ...form,
         price: form.price || 0,
      })

   return (
      <>
         <Form
            onSubmit={handleSubmit}
            onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
            initialValues={props.initialValues}>
            {({ formError, submitting }: any) => (
               <>
                  <SC.CardContainer>
                     <SC.HeaderTitle>
                        <Text weight="semiBold" size="mlarge" mb="none">
                           <IntlLabel label="Schedule send" />
                        </Text>
                        <Text size="base" weight="normal">
                           <IntlLabel label="Pick time you want your message to be send" />
                        </Text>
                     </SC.HeaderTitle>
                     <SC.CheckboxListProps>
                        {schedulSendData &&
                           schedulSendData.map((data, index) => (
                              <SC.CheckboxList key={index} border={clientType === index}>
                                 <Checkbox checked={clientType === index} onClick={() => setClientType(index)}>
                                    <span>
                                       <IntlLabel label={data.text} />
                                    </span>
                                    <span>
                                       <IntlLabel label={data.date} />
                                    </span>
                                 </Checkbox>
                              </SC.CheckboxList>
                           ))}
                     </SC.CheckboxListProps>
                     <SC.SelectionCustomDateBtn onClick={selectionCustom}>
                        <Text size="base" weight="medium" mb="none">
                           <IntlLabel label="Select custom date and time" />
                        </Text>
                        <CustomIcon type="scheduleCalendar" />
                     </SC.SelectionCustomDateBtn>
                  </SC.CardContainer>
                  <Form.Alert text={formError} />
                  <ModifyMassLayout.Footer>
                     <Button block type="primary" ghost upperCase={false} onClick={goBack}>
                        <Icon type="arrow-left" style={{ marginRight: '10px' }} />
                        <IntlLabel label={isMobile ? 'Provious' : 'Client selection'} />
                     </Button>
                     <Button
                        block
                        label={isMobile ? 'Next' : 'Payment'}
                        type="primary"
                        upperCase={false}
                        loading={submitting}
                        onClick={payment}>
                        <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                     </Button>
                  </ModifyMassLayout.Footer>
               </>
            )}
         </Form>
      </>
   )
}
