import { CustomIcon } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { List } from 'antd'
import { AppLayout, MobileBackButton, NoInternetConnection } from 'components'
import { RoutePath } from 'constants/routes'
import React from 'react'
import { Item } from 'types'

import { settingTabs } from './config'

export default function MobileMessagesMenuPage() {
   const { navigate } = useLocation()

   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="client.messages.title"
            mobileHeader={() => (
               <>
                  <MobileBackButton />
                  <div>
                     <IntlLabel label="client.messages.title" />
                  </div>
                  <div />
               </>
            )}>
            <List
               itemLayout="horizontal"
               className="staff-menu__list"
               dataSource={settingTabs}
               renderItem={(item: Item) => (
                  <List.Item
                     onClick={() =>
                        navigate(item.hasBasePath ? item.path : `${RoutePath.CLIENT_MESSAGES}#${item.path}`)
                     }>
                     <List.Item.Meta
                        avatar={<CustomIcon type={item.icon} />}
                        title={<IntlLabel label={item.title} />}
                        description={<IntlLabel label={item.description} />}
                     />
                     <div>
                        <CustomIcon type="arrowForward" />
                     </div>
                  </List.Item>
               )}
            />
         </AppLayout>
      </NoInternetConnection>
   )
}
