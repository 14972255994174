import React from 'react'

import { A2HSNotificationProps } from '../A2HSNotification'
import Notification from '../Notification'

export default function UpdateNotification({ onOk, onCancel }: A2HSNotificationProps) {
   const updateNotificationProps = {
      title: 'app.update.title',
      description: 'app.update.description.title',
      onOkBtnTitle: 'app.update.button.title',
      onCancelBtnTitle: 'app.notNow.button.title',
      onOk,
      onCancel,
      zIndex: 6,
   }

   return <Notification {...updateNotificationProps} />
}
