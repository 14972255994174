import Icon from '@ant-design/icons'
import { Button, composeValidators, Field, Form, Text, validatePhone, validateRequired, withModal } from '@plandok/core'
import * as api from 'api'
import { flow, noop, pick } from 'lodash'
import React from 'react'

import * as SC from '../styles'

function SendTestMessageForNumberModal({ modalProps, onClose }: any) {
   return (
      <Form
         onSubmit={async (form: { phoneNumber: string }) => {
            await modalProps?.onSubmit(modalProps?.values)
            return await api.sendTestNotification({ ...pick(modalProps, 'channel', 'notificationType'), ...form })
         }}
         onSuccess={flow(onClose, modalProps?.onSuccess || noop)}>
         {({ submitting }: any) => (
            <SC.ContainerSendTestMessage>
               <Text label="modal.sendTestMessageByNumber.title" size="medium" mb="xmedium" lh="mlarge" />
               <Field.CombinedInput
                  name="phoneNumber"
                  placeholder="input.mobile.placeholder"
                  validate={composeValidators(validateRequired, validatePhone)}
               />
               <SC.InfoApplySendTestMessage>
                  <Icon type="exclamation-circle" />
                  <Text
                     colorType="base"
                     size="small"
                     mb="none"
                     lh="large"
                     weight="normal"
                     label="modal.sendTestMessageByNumber.description"
                  />
               </SC.InfoApplySendTestMessage>
               <Button type="primary" label="btn.send" htmlType="submit" loading={submitting} upperCase={false} />
            </SC.ContainerSendTestMessage>
         )}
      </Form>
   )
}

export default withModal(null, { contentClassName: 'send-test-message-modal' })(SendTestMessageForNumberModal as any)
