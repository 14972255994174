import React from 'react'

export default function SmallDragIcon() {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15">
         <g id="Group_55" data-name="Group 55" transform="translate(-428 -378)">
            <circle
               id="Ellipse_27"
               data-name="Ellipse 27"
               cx="1.5"
               cy="1.5"
               r="1.5"
               transform="translate(428 378)"
               fill="#adb6cc"
            />
            <circle
               id="Ellipse_32"
               data-name="Ellipse 32"
               cx="1.5"
               cy="1.5"
               r="1.5"
               transform="translate(434 378)"
               fill="#adb6cc"
            />
            <circle
               id="Ellipse_28"
               data-name="Ellipse 28"
               cx="1.5"
               cy="1.5"
               r="1.5"
               transform="translate(428 384)"
               fill="#adb6cc"
            />
            <circle
               id="Ellipse_31"
               data-name="Ellipse 31"
               cx="1.5"
               cy="1.5"
               r="1.5"
               transform="translate(434 384)"
               fill="#adb6cc"
            />
            <circle
               id="Ellipse_29"
               data-name="Ellipse 29"
               cx="1.5"
               cy="1.5"
               r="1.5"
               transform="translate(428 390)"
               fill="#adb6cc"
            />
            <circle
               id="Ellipse_30"
               data-name="Ellipse 30"
               cx="1.5"
               cy="1.5"
               r="1.5"
               transform="translate(434 390)"
               fill="#adb6cc"
            />
         </g>
      </svg>
   )
}
