import { getIsOk, LocalStorage } from '@plandok/core'
import { message } from 'antd'
import { authSuccess } from 'api/auth'
import axios from 'axios'

import { RoutePath } from '../../constants/routes'

let isRefreshing = false
let failedQueue: any[] = []

const processQueue = (error: any, token = null) => {
   failedQueue.forEach(prom => {
      if (error) {
         return prom.reject(error)
      } else {
         return prom.resolve(token)
      }
   })
   failedQueue = []
}

axios.interceptors.response.use(
   response => response,
   function (error) {
      const originalRequest = error.config

      if (originalRequest?.url?.includes('/refresh-token') && !getIsOk(error.response)) {
         failedQueue = []
         message.error('Session expired', 1)
         window.location.href = RoutePath.LOGOUT
         return
      } else if (originalRequest?.url !== RoutePath.LOGIN && 401 === error.response.status && !originalRequest._retry) {
         if (isRefreshing) {
            return new Promise(function (resolve, reject) {
               failedQueue.push({ resolve, reject })
            })
               .then(token => {
                  originalRequest.headers.Authorization = `Bearer ${token}`
                  return axios(originalRequest)
               })
               .catch(err => {
                  return Promise.reject(err)
               })
         }

         originalRequest._retry = true
         isRefreshing = true

         const refreshToken = LocalStorage.getRefreshToken()
         return new Promise(function (resolve, reject) {
            axios
               .post('/refresh-token', { refreshToken })
               .then(({ data }) => {
                  authSuccess(data)
                  processQueue(null, data.token)
                  originalRequest.headers.Authorization = `Bearer ${data.token}`
                  isRefreshing = false
                  resolve(axios(originalRequest))
               })
               .catch(err => {
                  processQueue(err, null)
                  isRefreshing = false
                  reject(err)
               })
         })
      }

      return Promise.reject(error)
   },
)

export default axios
