import * as api from 'api'

import { AnalyticsServices } from '.'

const getAnalyticsServices = async (
   setIsLoading: (value: boolean) => void,
   setAnalyticsServices: (value: AnalyticsServices) => void,
) => {
   setIsLoading(true)

   try {
      const { data } = await api.fetchAnalyticsServices()

      setAnalyticsServices(data)
   } catch (e) {
      console.error('Error when getting analytics services')
   } finally {
      setIsLoading(false)
   }
}

export default getAnalyticsServices
