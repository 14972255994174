import { message } from 'antd'
import * as api from 'api'
import { extractOptionsFromList } from 'helpers/app/lookups'
import { useCallback, useMemo, useState } from 'react'
import { useStore } from 'store/store'

import { staffOptions } from './components/CalendarFilter/constants'
import * as helpers from './store/calendar-state.helpers'

export interface ICalendarFilterParams {
   staff?: string
   isInitialise: boolean
   viewType: 'day' | 'week'
   date: Date
   timeOffset: number
}

const initFilter: ICalendarFilterParams = {
   isInitialise: false,
   staff: 'all_employees',
   viewType: 'day',
   date: new Date(),
   timeOffset: 15,
}

export interface CalendarStateProps {
   filterState: ICalendarFilterParams
   data: any
   employees: any[]
   locations: any[]
   initialized: boolean
   isLoading: boolean
   employeesOptions: Array<{ code: string; name: string; serviceGroup: string }>
   locationOptions: Array<{ code: string; name: string; serviceGroup: string }>
   calendarData: any[]
   fetchData: (isEventSource?: boolean) => Promise<void>
   resetFilter: () => void
   changeFilter: (field: string, value?: any) => Promise<void>
   initialFetchData: (viewType: 'day' | 'week') => Promise<void>
}

export const useCalendarState = (): CalendarStateProps => {
   const appState = useStore()
   const locations = useStore(state => state.locations)
   const employees = useStore(state => state.employees)
   const locationId = useStore(state => state.locationId)
   const filterState = useStore(state => state.calendarState)

   const [data, setData] = useState<any>(null)
   const [isLoading, setIsLoading] = useState(false)

   const employeesOptions = useMemo(() => extractOptionsFromList(employees, 'EMPLOYEE'), [employees])

   const locationOptions = useMemo(() => extractOptionsFromList(locations, 'LOCATION'), [locations])

   const calendarData = useMemo(
      () => helpers.extractCalendarData(filterState.viewType, filterState.date, filterState.staff, data) || [],
      [filterState, data],
   )

   const fetchData = useCallback(
      async (skipLoading = false, currentFilter: any | undefined = undefined) => {
         if (filterState.isInitialise) {
            if (!skipLoading) setIsLoading(true)

            try {
               const calendarData = await api.fetchCalendar(
                  helpers.getCalendarFilterParams(
                     currentFilter ?? { ...filterState, location_id: locationId },
                     employees,
                  ),
               )
               setData(calendarData)
            } catch (error) {
               console.error(error)
               message.error('Failed to fetch calendar data. Please try again later.')
            } finally {
               setIsLoading(false)
            }
         }
      },
      [filterState, employees, locationId],
   )

   const resetFilter = () => {
      setData(null)
      appState.setCalendarState(initFilter)
      appState.setCalendarState({ isInitialise: false })
   }

   const changeFilter = useCallback(
      async (field: string, value?: any) => {
         let updated: any = { ...filterState, [field]: value, location_id: locationId }

         if (field === 'viewType' && value === 'week') {
            const isGroupStaff = staffOptions.some(e => e.code === filterState.staff)
            if (isGroupStaff) {
               updated.viewType = 'week'
               updated.staff = employees?.[0]?.id || 'all_employees'
            }
         }

         if (field === 'staff' && staffOptions.some(e => e.code === value)) {
            updated.viewType = 'day'
         }

         if (field === 'location_id') {
            try {
               appState.setLocationId(value)
               const employeeData = (await api.fetchEmployeesCalendarOptions({ location_id: value }))?.employees || []
               appState.setEmployees(employeeData)

               if (
                  !employeeData.some((e: any) => e?.id === filterState.staff) &&
                  updated.staff !== 'working_employees'
               ) {
                  updated = { ...updated, staff: 'all_employees', viewType: 'day', location_id: value }
               }
            } catch (error) {
               console.error('Error fetching employees:', error)
            }
         }

         appState.setCalendarState({
            date: updated.date,
            staff: updated.staff,
            viewType: updated.viewType,
         })
         await fetchData(false, updated)
         return
      },
      [employees, fetchData],
   )

   const initialFetchData = useCallback(
      async (viewType: 'day' | 'week') => {
         if (!filterState.isInitialise) {
            setIsLoading(true)
            try {
               const employeeData =
                  (await api.fetchEmployeesCalendarOptions({ location_id: locationId }))?.employees || []

               const calendarData = await api.fetchCalendar({
                  ...helpers.getCalendarFilterParams(filterState as any, employeeData),
                  location_id: locationId,
               })

               if (!calendarData) {
                  message.error('Failed to initialize calendar data. Please contact support.')
                  return
               }

               appState.setEmployees(employeeData)
               appState.setCalendarState({
                  isInitialise: true,
                  staff: viewType === 'week' ? employeeData?.[0]?.id : 'all_employees',
               })
               setData(calendarData)
            } catch (error) {
               console.error('Error during initial fetch:', error)
            } finally {
               setIsLoading(false)
            }
         } else {
            await fetchData(true)
         }
      },
      [filterState.isInitialise, filterState.viewType, locations, locationId],
   )

   return {
      filterState,
      data,
      employees,
      locations,
      initialized: filterState.isInitialise,
      isLoading,
      employeesOptions,
      locationOptions,
      calendarData,
      fetchData,
      resetFilter,
      changeFilter,
      initialFetchData,
   }
}
