import { validateRequired } from '@plandok/core'
import { getLocationInfo } from 'api'
import { Field } from 'components/form'
import React, { useEffect, useState } from 'react'

export default function ProfileLocationStep() {
   const [locationCallingCode, setLocationCallingCode] = useState<string | undefined>('')

   useEffect(() => {
      ;(async () => {
         const { callingCode } = await getLocationInfo()
         setLocationCallingCode(callingCode)
      })()
   }, [])

   return (
      <>
         <Field.Input
            name="name"
            label="input.locationName.label"
            placeholder="input.profile.locationName.placeholder"
            validate={validateRequired}
         />
         <Field.Row className="slug-explanation">
            <Field.CombinedInput
               name="phone"
               label="input.stafmobile.label"
               placeholder="input.contactNumber.placeholder"
               validate={validateRequired}
               md={11}
               preloadedPrefix={locationCallingCode}
            />
            <Field.Input
               name="slug"
               label="input.slug.label"
               explanation="input.slug.explanation"
               validate={validateRequired}
               md={11}
            />
         </Field.Row>

         <Field.Row>
            <Field.Input md={11} name="city" label="input.city.label" placeholder="input.city.placeholder" />
            <Field.Input md={11} name="state" label="input.state.label" placeholder="input.state.placeholder" />
         </Field.Row>
         <Field.Row>
            <Field.Input md={11} name="address" label="input.address.label" placeholder="input.address.placeholder" />
            <Field.Input md={11} name="zipCode" label="input.zip.label" placeholder="input.zip.placeholder" />
         </Field.Row>
         <Field.TextArea
            name="addressDetails"
            label="input.addressDetails.label"
            placeholder="input.addressDetails.placeholder"
            rows={4}
         />
      </>
   )
}
