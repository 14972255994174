import { IntlLabel } from '@plandok/i18n'
import { MobileBackButton } from 'components'
import React from 'react'

export default function SettingsMobileHeader() {
   return (
      <>
         <MobileBackButton />
         <div>
            <IntlLabel label="sidebar.settings" />
         </div>
         <div />
      </>
   )
}
