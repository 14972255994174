import { memo } from 'react'
import React from 'react'

import { getLabel } from '../helpers'
import { LabelKey } from '../types'
import { IntlConsumer } from './'

const IntlLabel = ({ label, lang: nextLang, isTemplate }: { label?: LabelKey; lang?: any; isTemplate?: boolean }) => {
   if (!label) return null
   return (
      <IntlConsumer>
         {({ lang }) => {
            const resultLabel = getLabel(label, nextLang || lang)

            if (isTemplate) {
               return <div dangerouslySetInnerHTML={{ __html: resultLabel }} />
            }
            return resultLabel
         }}
      </IntlConsumer>
   )
}

export default memo(IntlLabel)
