import { CustomIcon, Text } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Switch } from 'antd'
import React, { useState } from 'react'

import MoreButtonExpended from './components/MoreButtonExpended'
import * as SC from './styles'

const CampaignCard = ({ headerText, icon, description, url }: any) => {
   const [activeSwitch, setActiveSwitch] = useState(false)
   const { navigate } = useLocation()

   const startCampaigns = () => navigate(url)
   const editCampaigns = () => navigate(url)

   return (
      <SC.CampaignsCardItem>
         <CustomIcon type={icon as any} />
         <SC.CampaignsCardText>
            <SC.CampaignsCardTextHeader>
               <Text size="mlarge" weight="semiBold" mb="small">
                  <IntlLabel label={headerText} />
               </Text>
               <MoreButtonExpended />
               // TODO:
               {/*<MoreButtonExpended perView={perView} resetCampaign={resetCampaign} />*/}
            </SC.CampaignsCardTextHeader>
            <Text size="small" weight="normal" mb="xsmall">
               <IntlLabel label={description} />
            </Text>
            <SC.CampaignsCardFooter>
               <SC.SwitchBtn>
                  <Switch defaultChecked checked={activeSwitch} onChange={setActiveSwitch} />
                  <Text
                     style={{ color: activeSwitch ? '#009329' : 'inherit' }}
                     label={activeSwitch ? 'ob.analytics.active' : 'ob.analytics.inactive'}
                     size="medium"
                     weight="normal"
                     mb="none"
                     ml="base"
                  />
               </SC.SwitchBtn>
               {activeSwitch ? (
                  <SC.CampaignsCardBtn onClick={editCampaigns}>
                     <CustomIcon type="edit" />
                     <IntlLabel label="Edit Campaign" />
                  </SC.CampaignsCardBtn>
               ) : (
                  <SC.CampaignsCardBtn onClick={startCampaigns}>
                     <IntlLabel label="Start Campaign" />
                     <CustomIcon type="arrow" />
                  </SC.CampaignsCardBtn>
               )}
            </SC.CampaignsCardFooter>
         </SC.CampaignsCardText>
      </SC.CampaignsCardItem>
   )
}

export default CampaignCard
