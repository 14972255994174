import { PhonePrefixOptions, TimeZoneOptions, TimeFormatOptions, CurrencyOptions, CountryOptions } from '../types'

export const countryOptions: CountryOptions[] = [
   { name: 'Afghanistan', code: 'AF' },
   { name: 'Albania', code: 'AL' },
   { name: 'Algeria', code: 'DZ' },
   { name: 'American Samoa', code: 'AS' },
   { name: 'Andorra', code: 'AD' },
   { name: 'Angola', code: 'AO' },
   { name: 'Anguilla', code: 'AI' },
   { name: 'Antarctica', code: 'AQ' },
   { name: 'Antigua and Barbuda', code: 'AG' },
   { name: 'Argentina', code: 'AR' },
   { name: 'Armenia', code: 'AM' },
   { name: 'Aruba', code: 'AW' },
   { name: 'Australia', code: 'AU' },
   { name: 'Austria', code: 'AT' },
   { name: 'Azerbaijan', code: 'AZ' },
   { name: 'Bahamas', code: 'BS' },
   { name: 'Bahrain', code: 'BH' },
   { name: 'Bangladesh', code: 'BD' },
   { name: 'Barbados', code: 'BB' },
   { name: 'Belarus', code: 'BY' },
   { name: 'Belgium', code: 'BE' },
   { name: 'Belize', code: 'BZ' },
   { name: 'Benin', code: 'BJ' },
   { name: 'Bermuda', code: 'BM' },
   { name: 'Bhutan', code: 'BT' },
   { name: 'Bolivia, Plurinational State of', code: 'BO' },
   { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
   { name: 'Bosnia and Herzegovina', code: 'BA' },
   { name: 'Botswana', code: 'BW' },
   { name: 'Brazil', code: 'BR' },
   { name: 'British Indian Ocean Territory', code: 'IO' },
   { name: 'Brunei Darussalam', code: 'BN' },
   { name: 'Bulgaria', code: 'BG' },
   { name: 'Burkina Faso', code: 'BF' },
   { name: 'Burundi', code: 'BI' },
   { name: 'Cabo Verde', code: 'CV' },
   { name: 'Cambodia', code: 'KH' },
   { name: 'Cameroon', code: 'CM' },
   { name: 'Canada', code: 'CA' },
   { name: 'Cayman Islands', code: 'KY' },
   { name: 'Central African Republic', code: 'CF' },
   { name: 'Chad', code: 'TD' },
   { name: 'Chile', code: 'CL' },
   { name: 'China', code: 'CN' },
   { name: 'Christmas Island', code: 'CX' },
   { name: 'Cocos (Keeling) Islands', code: 'CC' },
   { name: 'Colombia', code: 'CO' },
   { name: 'Comoros', code: 'KM' },
   { name: 'Congo', code: 'CG' },
   { name: 'Congo, the Democratic Republic of the', code: 'CD' },
   { name: 'Cook Islands', code: 'CK' },
   { name: 'Costa Rica', code: 'CR' },
   { name: 'Croatia', code: 'HR' },
   { name: 'Cuba', code: 'CU' },
   { name: 'Curaçao', code: 'CW' },
   { name: 'Cyprus', code: 'CY' },
   { name: 'Czech Republic', code: 'CZ' },
   { name: "Côte d'Ivoire", code: 'CI' },
   { name: 'Denmark', code: 'DK' },
   { name: 'Djibouti', code: 'DJ' },
   { name: 'Dominica', code: 'DM' },
   { name: 'Dominican Republic', code: 'DO' },
   { name: 'Ecuador', code: 'EC' },
   { name: 'Egypt', code: 'EG' },
   { name: 'El Salvador', code: 'SV' },
   { name: 'Equatorial Guinea', code: 'GQ' },
   { name: 'Eritrea', code: 'ER' },
   { name: 'Estonia', code: 'EE' },
   { name: 'Ethiopia', code: 'ET' },
   { name: 'Falkland Islands (Malvinas)', code: 'FK' },
   { name: 'Faroe Islands', code: 'FO' },
   { name: 'Fiji', code: 'FJ' },
   { name: 'Finland', code: 'FI' },
   { name: 'France', code: 'FR' },
   { name: 'French Guiana', code: 'GF' },
   { name: 'French Polynesia', code: 'PF' },
   { name: 'French Southern Territories', code: 'TF' },
   { name: 'Gabon', code: 'GA' },
   { name: 'Gambia', code: 'GM' },
   { name: 'Georgia', code: 'GE' },
   { name: 'Germany', code: 'DE' },
   { name: 'Ghana', code: 'GH' },
   { name: 'Gibraltar', code: 'GI' },
   { name: 'Greece', code: 'GR' },
   { name: 'Greenland', code: 'GL' },
   { name: 'Grenada', code: 'GD' },
   { name: 'Guadeloupe', code: 'GP' },
   { name: 'Guam', code: 'GU' },
   { name: 'Guatemala', code: 'GT' },
   { name: 'Guernsey', code: 'GG' },
   { name: 'Guinea', code: 'GN' },
   { name: 'Guinea-Bissau', code: 'GW' },
   { name: 'Guyana', code: 'GY' },
   { name: 'Haiti', code: 'HT' },
   { name: 'Holy See (Vatican City State)', code: 'VA' },
   { name: 'Honduras', code: 'HN' },
   { name: 'Hong Kong', code: 'HK' },
   { name: 'Hungary', code: 'HU' },
   { name: 'Iceland', code: 'IS' },
   { name: 'India', code: 'IN' },
   { name: 'Indonesia', code: 'ID' },
   { name: 'Iran, Islamic Republic of', code: 'IR' },
   { name: 'Iraq', code: 'IQ' },
   { name: 'Ireland', code: 'IE' },
   { name: 'Isle of Man', code: 'IM' },
   { name: 'Israel', code: 'IL' },
   { name: 'Italy', code: 'IT' },
   { name: 'Jamaica', code: 'JM' },
   { name: 'Japan', code: 'JP' },
   { name: 'Jersey', code: 'JE' },
   { name: 'Jordan', code: 'JO' },
   { name: 'Kazakhstan', code: 'KZ' },
   { name: 'Kenya', code: 'KE' },
   { name: 'Kiribati', code: 'KI' },
   { name: "Korea, Democratic People's Republic of", code: 'KP' },
   { name: 'Korea, Republic of', code: 'KR' },
   { name: 'Kuwait', code: 'KW' },
   { name: 'Kyrgyzstan', code: 'KG' },
   { name: "Lao People's Democratic Republic", code: 'LA' },
   { name: 'Latvia', code: 'LV' },
   { name: 'Lebanon', code: 'LB' },
   { name: 'Lesotho', code: 'LS' },
   { name: 'Liberia', code: 'LR' },
   { name: 'Libya', code: 'LY' },
   { name: 'Liechtenstein', code: 'LI' },
   { name: 'Lithuania', code: 'LT' },
   { name: 'Luxembourg', code: 'LU' },
   { name: 'Macao', code: 'MO' },
   { name: 'Macedonia, the former Yugoslav Republic of', code: 'MK' },
   { name: 'Madagascar', code: 'MG' },
   { name: 'Malawi', code: 'MW' },
   { name: 'Malaysia', code: 'MY' },
   { name: 'Maldives', code: 'MV' },
   { name: 'Mali', code: 'ML' },
   { name: 'Malta', code: 'MT' },
   { name: 'Marshall Islands', code: 'MH' },
   { name: 'Martinique', code: 'MQ' },
   { name: 'Mauritania', code: 'MR' },
   { name: 'Mauritius', code: 'MU' },
   { name: 'Mayotte', code: 'YT' },
   { name: 'Mexico', code: 'MX' },
   { name: 'Micronesia, Federated States of', code: 'FM' },
   { name: 'Moldova, Republic of', code: 'MD' },
   { name: 'Monaco', code: 'MC' },
   { name: 'Mongolia', code: 'MN' },
   { name: 'Montenegro', code: 'ME' },
   { name: 'Montserrat', code: 'MS' },
   { name: 'Morocco', code: 'MA' },
   { name: 'Mozambique', code: 'MZ' },
   { name: 'Myanmar', code: 'MM' },
   { name: 'Namibia', code: 'NA' },
   { name: 'Nauru', code: 'NR' },
   { name: 'Nepal', code: 'NP' },
   { name: 'Netherlands', code: 'NL' },
   { name: 'New Caledonia', code: 'NC' },
   { name: 'New Zealand', code: 'NZ' },
   { name: 'Nicaragua', code: 'NI' },
   { name: 'Niger', code: 'NE' },
   { name: 'Nigeria', code: 'NG' },
   { name: 'Niue', code: 'NU' },
   { name: 'Norfolk Island', code: 'NF' },
   { name: 'Northern Mariana Islands', code: 'MP' },
   { name: 'Norway', code: 'NO' },
   { name: 'Oman', code: 'OM' },
   { name: 'Pakistan', code: 'PK' },
   { name: 'Palau', code: 'PW' },
   { name: 'Palestine, State of', code: 'PS' },
   { name: 'Panama', code: 'PA' },
   { name: 'Papua New Guinea', code: 'PG' },
   { name: 'Paraguay', code: 'PY' },
   { name: 'Peru', code: 'PE' },
   { name: 'Philippines', code: 'PH' },
   { name: 'Pitcairn', code: 'PN' },
   { name: 'Poland', code: 'PL' },
   { name: 'Portugal', code: 'PT' },
   { name: 'Puerto Rico', code: 'PR' },
   { name: 'Qatar', code: 'QA' },
   { name: 'Romania', code: 'RO' },
   { name: 'Russian Federation', code: 'RU' },
   { name: 'Rwanda', code: 'RW' },
   { name: 'Réunion', code: 'RE' },
   { name: 'Saint Barthélemy', code: 'BL' },
   { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH' },
   { name: 'Saint Kitts and Nevis', code: 'KN' },
   { name: 'Saint Lucia', code: 'LC' },
   { name: 'Saint Martin (French part)', code: 'MF' },
   { name: 'Saint Pierre and Miquelon', code: 'PM' },
   { name: 'Saint Vincent and the Grenadines', code: 'VC' },
   { name: 'Samoa', code: 'WS' },
   { name: 'San Marino', code: 'SM' },
   { name: 'Sao Tome and Principe', code: 'ST' },
   { name: 'Saudi Arabia', code: 'SA' },
   { name: 'Senegal', code: 'SN' },
   { name: 'Serbia', code: 'RS' },
   { name: 'Seychelles', code: 'SC' },
   { name: 'Sierra Leone', code: 'SL' },
   { name: 'Singapore', code: 'SG' },
   { name: 'Sint Maarten (Dutch part)', code: 'SX' },
   { name: 'Slovakia', code: 'SK' },
   { name: 'Slovenia', code: 'SI' },
   { name: 'Solomon Islands', code: 'SB' },
   { name: 'Somalia', code: 'SO' },
   { name: 'South Africa', code: 'ZA' },
   { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
   { name: 'South Sudan', code: 'SS' },
   { name: 'Spain', code: 'ES' },
   { name: 'Sri Lanka', code: 'LK' },
   { name: 'Sudan', code: 'SD' },
   { name: 'Suriname', code: 'SR' },
   { name: 'Svalbard and Jan Mayen', code: 'SJ' },
   { name: 'Swaziland', code: 'SZ' },
   { name: 'Sweden', code: 'SE' },
   { name: 'Switzerland', code: 'CH' },
   { name: 'Syrian Arab Republic', code: 'SY' },
   { name: 'Taiwan, Province of China', code: 'TW' },
   { name: 'Tajikistan', code: 'TJ' },
   { name: 'Tanzania, United Republic of', code: 'TZ' },
   { name: 'Thailand', code: 'TH' },
   { name: 'Timor-Leste', code: 'TL' },
   { name: 'Togo', code: 'TG' },
   { name: 'Tokelau', code: 'TK' },
   { name: 'Tonga', code: 'TO' },
   { name: 'Trinidad and Tobago', code: 'TT' },
   { name: 'Tunisia', code: 'TN' },
   { name: 'Turkey', code: 'TR' },
   { name: 'Turkmenistan', code: 'TM' },
   { name: 'Turks and Caicos Islands', code: 'TC' },
   { name: 'Tuvalu', code: 'TV' },
   { name: 'Uganda', code: 'UG' },
   { name: 'Ukraine', code: 'UA' },
   { name: 'United Arab Emirates', code: 'AE' },
   { name: 'United Kingdom', code: 'GB' },
   { name: 'United States', code: 'US' },
   { name: 'United States Minor Outlying Islands', code: 'UM' },
   { name: 'Uruguay', code: 'UY' },
   { name: 'Uzbekistan', code: 'UZ' },
   { name: 'Vanuatu', code: 'VU' },
   { name: 'Venezuela, Bolivarian Republic of', code: 'VE' },
   { name: 'Vietnam', code: 'VN' },
   { name: 'Virgin Islands, British', code: 'VG' },
   { name: 'Virgin Islands, U.S.', code: 'VI' },
   { name: 'Wallis and Futuna', code: 'WF' },
   { name: 'Western Sahara', code: 'EH' },
   { name: 'Yemen', code: 'YE' },
   { name: 'Zambia', code: 'ZM' },
   { name: 'Zimbabwe', code: 'ZW' },
]

export const timeZoneOptions: TimeZoneOptions[] = [
   { name: '(GMT -11:00) Pago Pago', code: 'Pacific/Pago_Pago' },
   { name: '(GMT -11:00) Niue', code: 'Pacific/Niue' },
   { name: '(GMT -11:00) Midway', code: 'Pacific/Midway' },
   { name: '(GMT -10:00) Rarotonga', code: 'Pacific/Rarotonga' },
   { name: '(GMT -10:00) Tahiti', code: 'Pacific/Tahiti' },
   { name: '(GMT -10:00) Adak', code: 'America/Adak' },
   { name: '(GMT -10:00) Honolulu', code: 'Pacific/Honolulu' },
   { name: '(GMT -09:30) Marquesas', code: 'Pacific/Marquesas' },
   { name: '(GMT -09:00) Gambier', code: 'Pacific/Gambier' },
   { name: '(GMT -09:00) Anchorage', code: 'America/Anchorage' },
   { name: '(GMT -09:00) Juneau', code: 'America/Juneau' },
   { name: '(GMT -09:00) Sitka', code: 'America/Sitka' },
   { name: '(GMT -09:00) Metlakatla', code: 'America/Metlakatla' },
   { name: '(GMT -09:00) Yakutat', code: 'America/Yakutat' },
   { name: '(GMT -09:00) Nome', code: 'America/Nome' },
   { name: '(GMT -08:00) Vancouver', code: 'America/Vancouver' },
   { name: '(GMT -08:00) Whitehorse', code: 'America/Whitehorse' },
   { name: '(GMT -08:00) Dawson', code: 'America/Dawson' },
   { name: '(GMT -08:00) Tijuana', code: 'America/Tijuana' },
   { name: '(GMT -08:00) Pitcairn', code: 'Pacific/Pitcairn' },
   { name: '(GMT -08:00) Los Angeles', code: 'America/Los_Angeles' },
   { name: '(GMT -07:00) Edmonton', code: 'America/Edmonton' },
   { name: '(GMT -07:00) Cambridge Bay', code: 'America/Cambridge_Bay' },
   { name: '(GMT -07:00) Yellowknife', code: 'America/Yellowknife' },
   { name: '(GMT -07:00) Inuvik', code: 'America/Inuvik' },
   { name: '(GMT -07:00) Creston', code: 'America/Creston' },
   { name: '(GMT -07:00) Dawson Creek', code: 'America/Dawson_Creek' },
   { name: '(GMT -07:00) Fort Nelson', code: 'America/Fort_Nelson' },
   { name: '(GMT -07:00) Mazatlan', code: 'America/Mazatlan' },
   { name: '(GMT -07:00) Chihuahua', code: 'America/Chihuahua' },
   { name: '(GMT -07:00) Ojinaga', code: 'America/Ojinaga' },
   { name: '(GMT -07:00) Hermosillo', code: 'America/Hermosillo' },
   { name: '(GMT -07:00) Bahia Banderas', code: 'America/Bahia_Banderas' },
   { name: '(GMT -07:00) Denver', code: 'America/Denver' },
   { name: '(GMT -07:00) Boise', code: 'America/Boise' },
   { name: '(GMT -07:00) Phoenix', code: 'America/Phoenix' },
   { name: '(GMT -06:00) Belize', code: 'America/Belize' },
   { name: '(GMT -06:00) Winnipeg', code: 'America/Winnipeg' },
   { name: '(GMT -06:00) Rainy River', code: 'America/Rainy_River' },
   { name: '(GMT -06:00) Resolute', code: 'America/Resolute' },
   { name: '(GMT -06:00) Rankin Inlet', code: 'America/Rankin_Inlet' },
   { name: '(GMT -06:00) Regina', code: 'America/Regina' },
   { name: '(GMT -06:00) Swift Current', code: 'America/Swift_Current' },
   { name: '(GMT -06:00) Easter', code: 'Pacific/Easter' },
   { name: '(GMT -06:00) Costa Rica', code: 'America/Costa_Rica' },
   { name: '(GMT -06:00) Galapagos', code: 'Pacific/Galapagos' },
   { name: '(GMT -06:00) Guatemala', code: 'America/Guatemala' },
   { name: '(GMT -06:00) Tegucigalpa', code: 'America/Tegucigalpa' },
   { name: '(GMT -06:00) Mexico City', code: 'America/Mexico_City' },
   { name: '(GMT -06:00) Merida', code: 'America/Merida' },
   { name: '(GMT -06:00) Monterrey', code: 'America/Monterrey' },
   { name: '(GMT -06:00) Matamoros', code: 'America/Matamoros' },
   { name: '(GMT -06:00) Managua', code: 'America/Managua' },
   { name: '(GMT -06:00) El Salvador', code: 'America/El_Salvador' },
   { name: '(GMT -06:00) Chicago', code: 'America/Chicago' },
   { name: '(GMT -06:00) Tell City, Indiana', code: 'America/Indiana/Tell_City' },
   { name: '(GMT -06:00) Knox, Indiana', code: 'America/Indiana/Knox' },
   { name: '(GMT -06:00) Menominee', code: 'America/Menominee' },
   { name: '(GMT -06:00) Center, North Dakota', code: 'America/North_Dakota/Center' },
   { name: '(GMT -06:00) New Salem, North Dakota', code: 'America/North_Dakota/New_Salem' },
   { name: '(GMT -06:00) Beulah, North Dakota', code: 'America/North_Dakota/Beulah' },
   { name: '(GMT -05:00) Eirunepe', code: 'America/Eirunepe' },
   { name: '(GMT -05:00) Rio Branco', code: 'America/Rio_Branco' },
   { name: '(GMT -05:00) Nassau', code: 'America/Nassau' },
   { name: '(GMT -05:00) Toronto', code: 'America/Toronto' },
   { name: '(GMT -05:00) Nipigon', code: 'America/Nipigon' },
   { name: '(GMT -05:00) Thunder Bay', code: 'America/Thunder_Bay' },
   { name: '(GMT -05:00) Iqaluit', code: 'America/Iqaluit' },
   { name: '(GMT -05:00) Pangnirtung', code: 'America/Pangnirtung' },
   { name: '(GMT -05:00) Atikokan', code: 'America/Atikokan' },
   { name: '(GMT -05:00) Bogota', code: 'America/Bogota' },
   { name: '(GMT -05:00) Havana', code: 'America/Havana' },
   { name: '(GMT -05:00) Guayaquil', code: 'America/Guayaquil' },
   { name: '(GMT -05:00) Port-au-Prince', code: 'America/Port-au-Prince' },
   { name: '(GMT -05:00) Jamaica', code: 'America/Jamaica' },
   { name: '(GMT -05:00) Cancun', code: 'America/Cancun' },
   { name: '(GMT -05:00) Panama', code: 'America/Panama' },
   { name: '(GMT -05:00) Lima', code: 'America/Lima' },
   { name: '(GMT -05:00) Grand Turk', code: 'America/Grand_Turk' },
   { name: '(GMT -05:00) New York', code: 'America/New_York' },
   { name: '(GMT -05:00) Detroit', code: 'America/Detroit' },
   { name: '(GMT -05:00) Louisville, Kentucky', code: 'America/Kentucky/Louisville' },
   { name: '(GMT -05:00) Monticello, Kentucky', code: 'America/Kentucky/Monticello' },
   { name: '(GMT -05:00) Indianapolis, Indiana', code: 'America/Indiana/Indianapolis' },
   { name: '(GMT -05:00) Vincennes, Indiana', code: 'America/Indiana/Vincennes' },
   { name: '(GMT -05:00) Winamac, Indiana', code: 'America/Indiana/Winamac' },
   { name: '(GMT -05:00) Marengo, Indiana', code: 'America/Indiana/Marengo' },
   { name: '(GMT -05:00) Petersburg, Indiana', code: 'America/Indiana/Petersburg' },
   { name: '(GMT -05:00) Vevay, Indiana', code: 'America/Indiana/Vevay' },
   { name: '(GMT -04:00) Antigua', code: 'America/Antigua' },
   { name: '(GMT -04:00) Anguilla', code: 'America/Anguilla' },
   { name: '(GMT -04:00) Aruba', code: 'America/Aruba' },
   { name: '(GMT -04:00) Barbados', code: 'America/Barbados' },
   { name: '(GMT -04:00) St Barthelemy', code: 'America/St_Barthelemy' },
   { name: '(GMT -04:00) Bermuda', code: 'Atlantic/Bermuda' },
   { name: '(GMT -04:00) La Paz', code: 'America/La_Paz' },
   { name: '(GMT -04:00) Kralendijk', code: 'America/Kralendijk' },
   { name: '(GMT -04:00) Campo Grande', code: 'America/Campo_Grande' },
   { name: '(GMT -04:00) Cuiaba', code: 'America/Cuiaba' },
   { name: '(GMT -04:00) Porto Velho', code: 'America/Porto_Velho' },
   { name: '(GMT -04:00) Boa Vista', code: 'America/Boa_Vista' },
   { name: '(GMT -04:00) Manaus', code: 'America/Manaus' },
   { name: '(GMT -04:00) Halifax', code: 'America/Halifax' },
   { name: '(GMT -04:00) Glace Bay', code: 'America/Glace_Bay' },
   { name: '(GMT -04:00) Moncton', code: 'America/Moncton' },
   { name: '(GMT -04:00) Goose Bay', code: 'America/Goose_Bay' },
   { name: '(GMT -04:00) Blanc-Sablon', code: 'America/Blanc-Sablon' },
   { name: '(GMT -04:00) Santiago', code: 'America/Santiago' },
   { name: '(GMT -04:00) Curacao', code: 'America/Curacao' },
   { name: '(GMT -04:00) Dominica', code: 'America/Dominica' },
   { name: '(GMT -04:00) Santo Domingo', code: 'America/Santo_Domingo' },
   { name: '(GMT -04:00) Grenada', code: 'America/Grenada' },
   { name: '(GMT -04:00) Thule', code: 'America/Thule' },
   { name: '(GMT -04:00) Guadeloupe', code: 'America/Guadeloupe' },
   { name: '(GMT -04:00) Guyana', code: 'America/Guyana' },
   { name: '(GMT -04:00) St Kitts', code: 'America/St_Kitts' },
   { name: '(GMT -04:00) St Lucia', code: 'America/St_Lucia' },
   { name: '(GMT -04:00) Marigot', code: 'America/Marigot' },
   { name: '(GMT -04:00) Martinique', code: 'America/Martinique' },
   { name: '(GMT -04:00) Montserrat', code: 'America/Montserrat' },
   { name: '(GMT -04:00) Puerto Rico', code: 'America/Puerto_Rico' },
   { name: '(GMT -04:00) Asuncion', code: 'America/Asuncion' },
   { name: '(GMT -04:00) Lower Princes', code: 'America/Lower_Princes' },
   { name: '(GMT -04:00) Port of Spain', code: 'America/Port_of_Spain' },
   { name: '(GMT -04:00) St Vincent', code: 'America/St_Vincent' },
   { name: '(GMT -04:00) Caracas', code: 'America/Caracas' },
   { name: '(GMT -04:00) Tortola', code: 'America/Tortola' },
   { name: '(GMT -04:00) St Thomas', code: 'America/St_Thomas' },
   { name: '(GMT -03:30) St Johns', code: 'America/St_Johns' },
   { name: '(GMT -03:00) Palmer', code: 'Antarctica/Palmer' },
   { name: '(GMT -03:00) Rothera', code: 'Antarctica/Rothera' },
   { name: '(GMT -03:00) Buenos Aires, Argentina', code: 'America/Argentina/Buenos_Aires' },
   { name: '(GMT -03:00) Cordoba, Argentina', code: 'America/Argentina/Cordoba' },
   { name: '(GMT -03:00) Salta, Argentina', code: 'America/Argentina/Salta' },
   { name: '(GMT -03:00) Jujuy, Argentina', code: 'America/Argentina/Jujuy' },
   { name: '(GMT -03:00) Tucuman, Argentina', code: 'America/Argentina/Tucuman' },
   { name: '(GMT -03:00) Catamarca, Argentina', code: 'America/Argentina/Catamarca' },
   { name: '(GMT -03:00) La Rioja, Argentina', code: 'America/Argentina/La_Rioja' },
   { name: '(GMT -03:00) San Juan, Argentina', code: 'America/Argentina/San_Juan' },
   { name: '(GMT -03:00) Mendoza, Argentina', code: 'America/Argentina/Mendoza' },
   { name: '(GMT -03:00) San Luis, Argentina', code: 'America/Argentina/San_Luis' },
   { name: '(GMT -03:00) Rio Gallegos, Argentina', code: 'America/Argentina/Rio_Gallegos' },
   { name: '(GMT -03:00) Ushuaia, Argentina', code: 'America/Argentina/Ushuaia' },
   { name: '(GMT -03:00) Belem', code: 'America/Belem' },
   { name: '(GMT -03:00) Fortaleza', code: 'America/Fortaleza' },
   { name: '(GMT -03:00) Recife', code: 'America/Recife' },
   { name: '(GMT -03:00) Araguaina', code: 'America/Araguaina' },
   { name: '(GMT -03:00) Maceio', code: 'America/Maceio' },
   { name: '(GMT -03:00) Bahia', code: 'America/Bahia' },
   { name: '(GMT -03:00) Sao Paulo', code: 'America/Sao_Paulo' },
   { name: '(GMT -03:00) Santarem', code: 'America/Santarem' },
   { name: '(GMT -03:00) Punta Arenas', code: 'America/Punta_Arenas' },
   { name: '(GMT -03:00) Stanley', code: 'Atlantic/Stanley' },
   { name: '(GMT -03:00) Cayenne', code: 'America/Cayenne' },
   { name: '(GMT -03:00) Godthab', code: 'America/Godthab' },
   { name: '(GMT -03:00) Miquelon', code: 'America/Miquelon' },
   { name: '(GMT -03:00) Paramaribo', code: 'America/Paramaribo' },
   { name: '(GMT -03:00) Montevideo', code: 'America/Montevideo' },
   { name: '(GMT -02:00) Noronha', code: 'America/Noronha' },
   { name: '(GMT -02:00) South Georgia', code: 'Atlantic/South_Georgia' },
   { name: '(GMT -01:00) Cape Verde', code: 'Atlantic/Cape_Verde' },
   { name: '(GMT -01:00) Scoresbysund', code: 'America/Scoresbysund' },
   { name: '(GMT -01:00) Azores', code: 'Atlantic/Azores' },
   { name: '(GMT +00:00) Troll', code: 'Antarctica/Troll' },
   { name: '(GMT +00:00) Ouagadougou', code: 'Africa/Ouagadougou' },
   { name: '(GMT +00:00) Abidjan', code: 'Africa/Abidjan' },
   { name: '(GMT +00:00) El Aaiun', code: 'Africa/El_Aaiun' },
   { name: '(GMT +00:00) Canary', code: 'Atlantic/Canary' },
   { name: '(GMT +00:00) Faroe', code: 'Atlantic/Faroe' },
   { name: '(GMT +00:00) London', code: 'Europe/London' },
   { name: '(GMT +00:00) Guernsey', code: 'Europe/Guernsey' },
   { name: '(GMT +00:00) Accra', code: 'Africa/Accra' },
   { name: '(GMT +00:00) Danmarkshavn', code: 'America/Danmarkshavn' },
   { name: '(GMT +00:00) Banjul', code: 'Africa/Banjul' },
   { name: '(GMT +00:00) Conakry', code: 'Africa/Conakry' },
   { name: '(GMT +00:00) Bissau', code: 'Africa/Bissau' },
   { name: '(GMT +00:00) Dublin', code: 'Europe/Dublin' },
   { name: '(GMT +00:00) Isle of Man', code: 'Europe/Isle_of_Man' },
   { name: '(GMT +00:00) Reykjavik', code: 'Atlantic/Reykjavik' },
   { name: '(GMT +00:00) Jersey', code: 'Europe/Jersey' },
   { name: '(GMT +00:00) Monrovia', code: 'Africa/Monrovia' },
   { name: '(GMT +00:00) Casablanca', code: 'Africa/Casablanca' },
   { name: '(GMT +00:00) Bamako', code: 'Africa/Bamako' },
   { name: '(GMT +00:00) Nouakchott', code: 'Africa/Nouakchott' },
   { name: '(GMT +00:00) Lisbon', code: 'Europe/Lisbon' },
   { name: '(GMT +00:00) Madeira', code: 'Atlantic/Madeira' },
   { name: '(GMT +00:00) St Helena', code: 'Atlantic/St_Helena' },
   { name: '(GMT +00:00) Freetown', code: 'Africa/Freetown' },
   { name: '(GMT +00:00) Dakar', code: 'Africa/Dakar' },
   { name: '(GMT +00:00) Sao Tome', code: 'Africa/Sao_Tome' },
   { name: '(GMT +00:00) Lome', code: 'Africa/Lome' },
   { name: '(GMT +01:00) Andorra', code: 'Europe/Andorra' },
   { name: '(GMT +01:00) Tirane', code: 'Europe/Tirane' },
   { name: '(GMT +01:00) Luanda', code: 'Africa/Luanda' },
   { name: '(GMT +01:00) Vienna', code: 'Europe/Vienna' },
   { name: '(GMT +01:00) Sarajevo', code: 'Europe/Sarajevo' },
   { name: '(GMT +01:00) Brussels', code: 'Europe/Brussels' },
   { name: '(GMT +01:00) Porto-Novo', code: 'Africa/Porto-Novo' },
   { name: '(GMT +01:00) Kinshasa', code: 'Africa/Kinshasa' },
   { name: '(GMT +01:00) Bangui', code: 'Africa/Bangui' },
   { name: '(GMT +01:00) Brazzaville', code: 'Africa/Brazzaville' },
   { name: '(GMT +01:00) Zurich', code: 'Europe/Zurich' },
   { name: '(GMT +01:00) Douala', code: 'Africa/Douala' },
   { name: '(GMT +01:00) Prague', code: 'Europe/Prague' },
   { name: '(GMT +01:00) Berlin', code: 'Europe/Berlin' },
   { name: '(GMT +01:00) Busingen', code: 'Europe/Busingen' },
   { name: '(GMT +01:00) Copenhagen', code: 'Europe/Copenhagen' },
   { name: '(GMT +01:00) Algiers', code: 'Africa/Algiers' },
   { name: '(GMT +01:00) Madrid', code: 'Europe/Madrid' },
   { name: '(GMT +01:00) Ceuta', code: 'Africa/Ceuta' },
   { name: '(GMT +01:00) Paris', code: 'Europe/Paris' },
   { name: '(GMT +01:00) Libreville', code: 'Africa/Libreville' },
   { name: '(GMT +01:00) Gibraltar', code: 'Europe/Gibraltar' },
   { name: '(GMT +01:00) Malabo', code: 'Africa/Malabo' },
   { name: '(GMT +01:00) Zagreb', code: 'Europe/Zagreb' },
   { name: '(GMT +01:00) Budapest', code: 'Europe/Budapest' },
   { name: '(GMT +01:00) Rome', code: 'Europe/Rome' },
   { name: '(GMT +01:00) Vaduz', code: 'Europe/Vaduz' },
   { name: '(GMT +01:00) Luxembourg', code: 'Europe/Luxembourg' },
   { name: '(GMT +01:00) Monaco', code: 'Europe/Monaco' },
   { name: '(GMT +01:00) Podgorica', code: 'Europe/Podgorica' },
   { name: '(GMT +01:00) Skopje', code: 'Europe/Skopje' },
   { name: '(GMT +01:00) Malta', code: 'Europe/Malta' },
   { name: '(GMT +01:00) Niamey', code: 'Africa/Niamey' },
   { name: '(GMT +01:00) Lagos', code: 'Africa/Lagos' },
   { name: '(GMT +01:00) Amsterdam', code: 'Europe/Amsterdam' },
   { name: '(GMT +01:00) Oslo', code: 'Europe/Oslo' },
   { name: '(GMT +01:00) Warsaw', code: 'Europe/Warsaw' },
   { name: '(GMT +01:00) Belgrade', code: 'Europe/Belgrade' },
   { name: '(GMT +01:00) Stockholm', code: 'Europe/Stockholm' },
   { name: '(GMT +01:00) Ljubljana', code: 'Europe/Ljubljana' },
   { name: '(GMT +01:00) Longyearbyen', code: 'Arctic/Longyearbyen' },
   { name: '(GMT +01:00) Bratislava', code: 'Europe/Bratislava' },
   { name: '(GMT +01:00) San Marino', code: 'Europe/San_Marino' },
   { name: '(GMT +01:00) Ndjamena', code: 'Africa/Ndjamena' },
   { name: '(GMT +01:00) Tunis', code: 'Africa/Tunis' },
   { name: '(GMT +01:00) Vatican', code: 'Europe/Vatican' },
   { name: '(GMT +02:00) Mariehamn', code: 'Europe/Mariehamn' },
   { name: '(GMT +02:00) Sofia', code: 'Europe/Sofia' },
   { name: '(GMT +02:00) Bujumbura', code: 'Africa/Bujumbura' },
   { name: '(GMT +02:00) Gaborone', code: 'Africa/Gaborone' },
   { name: '(GMT +02:00) Lubumbashi', code: 'Africa/Lubumbashi' },
   { name: '(GMT +02:00) Nicosia', code: 'Asia/Nicosia' },
   { name: '(GMT +02:00) Famagusta', code: 'Asia/Famagusta' },
   { name: '(GMT +02:00) Tallinn', code: 'Europe/Tallinn' },
   { name: '(GMT +02:00) Cairo', code: 'Africa/Cairo' },
   { name: '(GMT +02:00) Helsinki', code: 'Europe/Helsinki' },
   { name: '(GMT +02:00) Athens', code: 'Europe/Athens' },
   { name: '(GMT +02:00) Jerusalem', code: 'Asia/Jerusalem' },
   { name: '(GMT +02:00) Amman', code: 'Asia/Amman' },
   { name: '(GMT +02:00) Beirut', code: 'Asia/Beirut' },
   { name: '(GMT +02:00) Maseru', code: 'Africa/Maseru' },
   { name: '(GMT +02:00) Vilnius', code: 'Europe/Vilnius' },
   { name: '(GMT +02:00) Riga', code: 'Europe/Riga' },
   { name: '(GMT +02:00) Tripoli', code: 'Africa/Tripoli' },
   { name: '(GMT +02:00) Chisinau', code: 'Europe/Chisinau' },
   { name: '(GMT +02:00) Blantyre', code: 'Africa/Blantyre' },
   { name: '(GMT +02:00) Maputo', code: 'Africa/Maputo' },
   { name: '(GMT +02:00) Windhoek', code: 'Africa/Windhoek' },
   { name: '(GMT +02:00) Gaza', code: 'Asia/Gaza' },
   { name: '(GMT +02:00) Hebron', code: 'Asia/Hebron' },
   { name: '(GMT +02:00) Bucharest', code: 'Europe/Bucharest' },
   { name: '(GMT +02:00) Kaliningrad', code: 'Europe/Kaliningrad' },
   { name: '(GMT +02:00) Kigali', code: 'Africa/Kigali' },
   { name: '(GMT +02:00) Khartoum', code: 'Africa/Khartoum' },
   { name: '(GMT +02:00) Damascus', code: 'Asia/Damascus' },
   { name: '(GMT +02:00) Mbabane', code: 'Africa/Mbabane' },
   { name: '(GMT +02:00) Kiev', code: 'Europe/Kiev' },
   { name: '(GMT +02:00) Finland', code: 'Europe/Helsinki' },
   { name: '(GMT +02:00) Uzhgorod', code: 'Europe/Uzhgorod' },
   { name: '(GMT +02:00) Zaporozhye', code: 'Europe/Zaporozhye' },
   { name: '(GMT +02:00) Johannesburg', code: 'Africa/Johannesburg' },
   { name: '(GMT +02:00) Lusaka', code: 'Africa/Lusaka' },
   { name: '(GMT +02:00) Harare', code: 'Africa/Harare' },
   { name: '(GMT +03:00) Syowa', code: 'Antarctica/Syowa' },
   { name: '(GMT +03:00) Bahrain', code: 'Asia/Bahrain' },
   { name: '(GMT +03:00) Minsk', code: 'Europe/Minsk' },
   { name: '(GMT +03:00) Djibouti', code: 'Africa/Djibouti' },
   { name: '(GMT +03:00) Asmara', code: 'Africa/Asmara' },
   { name: '(GMT +03:00) Addis Ababa', code: 'Africa/Addis_Ababa' },
   { name: '(GMT +03:00) Baghdad', code: 'Asia/Baghdad' },
   { name: '(GMT +03:00) Nairobi', code: 'Africa/Nairobi' },
   { name: '(GMT +03:00) Comoro', code: 'Indian/Comoro' },
   { name: '(GMT +03:00) Kuwait', code: 'Asia/Kuwait' },
   { name: '(GMT +03:00) Antananarivo', code: 'Indian/Antananarivo' },
   { name: '(GMT +03:00) Qatar', code: 'Asia/Qatar' },
   { name: '(GMT +03:00) Moscow', code: 'Europe/Moscow' },
   { name: '(GMT +03:00) Simferopol', code: 'Europe/Simferopol' },
   { name: '(GMT +03:00) Volgograd', code: 'Europe/Volgograd' },
   { name: '(GMT +03:00) Kirov', code: 'Europe/Kirov' },
   { name: '(GMT +03:00) Riyadh', code: 'Asia/Riyadh' },
   { name: '(GMT +03:00) Mogadishu', code: 'Africa/Mogadishu' },
   { name: '(GMT +03:00) Juba', code: 'Africa/Juba' },
   { name: '(GMT +03:00) Istanbul', code: 'Europe/Istanbul' },
   { name: '(GMT +03:00) Dar es Salaam', code: 'Africa/Dar_es_Salaam' },
   { name: '(GMT +03:00) Kampala', code: 'Africa/Kampala' },
   { name: '(GMT +03:00) Aden', code: 'Asia/Aden' },
   { name: '(GMT +03:00) Mayotte', code: 'Indian/Mayotte' },
   { name: '(GMT +03:30) Tehran', code: 'Asia/Tehran' },
   { name: '(GMT +04:00) Dubai', code: 'Asia/Dubai' },
   { name: '(GMT +04:00) Yerevan', code: 'Asia/Yerevan' },
   { name: '(GMT +04:00) Baku', code: 'Asia/Baku' },
   { name: '(GMT +04:00) Tbilisi', code: 'Asia/Tbilisi' },
   { name: '(GMT +04:00) Mauritius', code: 'Indian/Mauritius' },
   { name: '(GMT +04:00) Muscat', code: 'Asia/Muscat' },
   { name: '(GMT +04:00) Reunion', code: 'Indian/Reunion' },
   { name: '(GMT +04:00) Astrakhan', code: 'Europe/Astrakhan' },
   { name: '(GMT +04:00) Saratov', code: 'Europe/Saratov' },
   { name: '(GMT +04:00) Ulyanovsk', code: 'Europe/Ulyanovsk' },
   { name: '(GMT +04:00) Samara', code: 'Europe/Samara' },
   { name: '(GMT +04:00) Mahe', code: 'Indian/Mahe' },
   { name: '(GMT +04:30) Kabul', code: 'Asia/Kabul' },
   { name: '(GMT +05:00) Mawson', code: 'Antarctica/Mawson' },
   { name: '(GMT +05:00) Aqtobe', code: 'Asia/Aqtobe' },
   { name: '(GMT +05:00) Aqtau', code: 'Asia/Aqtau' },
   { name: '(GMT +05:00) Atyrau', code: 'Asia/Atyrau' },
   { name: '(GMT +05:00) Oral', code: 'Asia/Oral' },
   { name: '(GMT +05:00) Maldives', code: 'Indian/Maldives' },
   { name: '(GMT +05:00) Karachi', code: 'Asia/Karachi' },
   { name: '(GMT +05:00) Yekaterinburg', code: 'Asia/Yekaterinburg' },
   { name: '(GMT +05:00) Kerguelen', code: 'Indian/Kerguelen' },
   { name: '(GMT +05:00) Dushanbe', code: 'Asia/Dushanbe' },
   { name: '(GMT +05:00) Ashgabat', code: 'Asia/Ashgabat' },
   { name: '(GMT +05:00) Samarkand', code: 'Asia/Samarkand' },
   { name: '(GMT +05:00) Tashkent', code: 'Asia/Tashkent' },
   { name: '(GMT +05:30) Kolkata', code: 'Asia/Kolkata' },
   { name: '(GMT +05:30) Colombo', code: 'Asia/Colombo' },
   { name: '(GMT +05:45) Kathmandu', code: 'Asia/Kathmandu' },
   { name: '(GMT +06:00) Vostok', code: 'Antarctica/Vostok' },
   { name: '(GMT +06:00) Dhaka', code: 'Asia/Dhaka' },
   { name: '(GMT +06:00) Thimphu', code: 'Asia/Thimphu' },
   { name: '(GMT +06:00) Urumqi', code: 'Asia/Urumqi' },
   { name: '(GMT +06:00) Chagos', code: 'Indian/Chagos' },
   { name: '(GMT +06:00) Bishkek', code: 'Asia/Bishkek' },
   { name: '(GMT +06:00) Almaty', code: 'Asia/Almaty' },
   { name: '(GMT +06:00) Qyzylorda', code: 'Asia/Qyzylorda' },
   { name: '(GMT +06:00) Omsk', code: 'Asia/Omsk' },
   { name: '(GMT +06:30) Cocos', code: 'Indian/Cocos' },
   { name: '(GMT +06:30) Yangon', code: 'Asia/Yangon' },
   { name: '(GMT +07:00) Davis', code: 'Antarctica/Davis' },
   { name: '(GMT +07:00) Christmas', code: 'Indian/Christmas' },
   { name: '(GMT +07:00) Jakarta', code: 'Asia/Jakarta' },
   { name: '(GMT +07:00) Pontianak', code: 'Asia/Pontianak' },
   { name: '(GMT +07:00) Phnom Penh', code: 'Asia/Phnom_Penh' },
   { name: '(GMT +07:00) Vientiane', code: 'Asia/Vientiane' },
   { name: '(GMT +07:00) Hovd', code: 'Asia/Hovd' },
   { name: '(GMT +07:00) Novosibirsk', code: 'Asia/Novosibirsk' },
   { name: '(GMT +07:00) Barnaul', code: 'Asia/Barnaul' },
   { name: '(GMT +07:00) Tomsk', code: 'Asia/Tomsk' },
   { name: '(GMT +07:00) Novokuznetsk', code: 'Asia/Novokuznetsk' },
   { name: '(GMT +07:00) Krasnoyarsk', code: 'Asia/Krasnoyarsk' },
   { name: '(GMT +07:00) Bangkok', code: 'Asia/Bangkok' },
   { name: '(GMT +07:00) Ho Chi Minh', code: 'Asia/Ho_Chi_Minh' },
   { name: '(GMT +08:00) Perth', code: 'Australia/Perth' },
   { name: '(GMT +08:00) Brunei', code: 'Asia/Brunei' },
   { name: '(GMT +08:00) Shanghai', code: 'Asia/Shanghai' },
   { name: '(GMT +08:00) Hong Kong', code: 'Asia/Hong_Kong' },
   { name: '(GMT +08:00) Makassar', code: 'Asia/Makassar' },
   { name: '(GMT +08:00) Ulaanbaatar', code: 'Asia/Ulaanbaatar' },
   { name: '(GMT +08:00) Choibalsan', code: 'Asia/Choibalsan' },
   { name: '(GMT +08:00) Macau', code: 'Asia/Macau' },
   { name: '(GMT +08:00) Kuala Lumpur', code: 'Asia/Kuala_Lumpur' },
   { name: '(GMT +08:00) Kuching', code: 'Asia/Kuching' },
   { name: '(GMT +08:00) Manila', code: 'Asia/Manila' },
   { name: '(GMT +08:00) Irkutsk', code: 'Asia/Irkutsk' },
   { name: '(GMT +08:00) Singapore', code: 'Asia/Singapore' },
   { name: '(GMT +08:00) Taipei', code: 'Asia/Taipei' },
   { name: '(GMT +08:30) Pyongyang', code: 'Asia/Pyongyang' },
   { name: '(GMT +08:45) Eucla', code: 'Australia/Eucla' },
   { name: '(GMT +09:00) Jayapura', code: 'Asia/Jayapura' },
   { name: '(GMT +09:00) Tokyo', code: 'Asia/Tokyo' },
   { name: '(GMT +09:00) Seoul', code: 'Asia/Seoul' },
   { name: '(GMT +09:00) Palau', code: 'Pacific/Palau' },
   { name: '(GMT +09:00) Chita', code: 'Asia/Chita' },
   { name: '(GMT +09:00) Yakutsk', code: 'Asia/Yakutsk' },
   { name: '(GMT +09:00) Khandyga', code: 'Asia/Khandyga' },
   { name: '(GMT +09:00) Dili', code: 'Asia/Dili' },
   { name: '(GMT +09:30) Broken Hill', code: 'Australia/Broken_Hill' },
   { name: '(GMT +09:30) Adelaide', code: 'Australia/Adelaide' },
   { name: '(GMT +09:30) Darwin', code: 'Australia/Darwin' },
   { name: "(GMT +10:00) Dumont D'Urville", code: 'Antarctica/DumontDUrville' },
   { name: '(GMT +10:00) Hobart', code: 'Australia/Hobart' },
   { name: '(GMT +10:00) Currie', code: 'Australia/Currie' },
   { name: '(GMT +10:00) Melbourne', code: 'Australia/Melbourne' },
   { name: '(GMT +10:00) Sydney', code: 'Australia/Sydney' },
   { name: '(GMT +10:00) Brisbane', code: 'Australia/Brisbane' },
   { name: '(GMT +10:00) Lindeman', code: 'Australia/Lindeman' },
   { name: '(GMT +10:00) Chuuk', code: 'Pacific/Chuuk' },
   { name: '(GMT +10:00) Guam', code: 'Pacific/Guam' },
   { name: '(GMT +10:00) Saipan', code: 'Pacific/Saipan' },
   { name: '(GMT +10:00) Port Moresby', code: 'Pacific/Port_Moresby' },
   { name: '(GMT +10:00) Vladivostok', code: 'Asia/Vladivostok' },
   { name: '(GMT +10:00) Ust-Nera', code: 'Asia/Ust-Nera' },
   { name: '(GMT +10:30) Lord Howe', code: 'Australia/Lord_Howe' },
   { name: '(GMT +11:00) Casey', code: 'Antarctica/Casey' },
   { name: '(GMT +11:00) Macquarie', code: 'Antarctica/Macquarie' },
   { name: '(GMT +11:00) Pohnpei', code: 'Pacific/Pohnpei' },
   { name: '(GMT +11:00) Kosrae', code: 'Pacific/Kosrae' },
   { name: '(GMT +11:00) Noumea', code: 'Pacific/Noumea' },
   { name: '(GMT +11:00) Norfolk', code: 'Pacific/Norfolk' },
   { name: '(GMT +11:00) Bougainville', code: 'Pacific/Bougainville' },
   { name: '(GMT +11:00) Magadan', code: 'Asia/Magadan' },
   { name: '(GMT +11:00) Sakhalin', code: 'Asia/Sakhalin' },
   { name: '(GMT +11:00) Srednekolymsk', code: 'Asia/Srednekolymsk' },
   { name: '(GMT +11:00) Guadalcanal', code: 'Pacific/Guadalcanal' },
   { name: '(GMT +11:00) Efate', code: 'Pacific/Efate' },
   { name: '(GMT +12:00) McMurdo', code: 'Antarctica/McMurdo' },
   { name: '(GMT +12:00) Fiji', code: 'Pacific/Fiji' },
   { name: '(GMT +12:00) Tarawa', code: 'Pacific/Tarawa' },
   { name: '(GMT +12:00) Majuro', code: 'Pacific/Majuro' },
   { name: '(GMT +12:00) Kwajalein', code: 'Pacific/Kwajalein' },
   { name: '(GMT +12:00) Nauru', code: 'Pacific/Nauru' },
   { name: '(GMT +12:00) Auckland', code: 'Pacific/Auckland' },
   { name: '(GMT +12:00) Kamchatka', code: 'Asia/Kamchatka' },
   { name: '(GMT +12:00) Anadyr', code: 'Asia/Anadyr' },
   { name: '(GMT +12:00) Funafuti', code: 'Pacific/Funafuti' },
   { name: '(GMT +12:00) Wake', code: 'Pacific/Wake' },
   { name: '(GMT +12:00) Wallis', code: 'Pacific/Wallis' },
   { name: '(GMT +12:45) Chatham', code: 'Pacific/Chatham' },
   { name: '(GMT +13:00) Enderbury', code: 'Pacific/Enderbury' },
   { name: '(GMT +13:00) Fakaofo', code: 'Pacific/Fakaofo' },
   { name: '(GMT +13:00) Tongatapu', code: 'Pacific/Tongatapu' },
   { name: '(GMT +13:00) Apia', code: 'Pacific/Apia' },
   { name: '(GMT +14:00) Kiritimati', code: 'Pacific/Kiritimati' },
]

export const currencyOptions: CurrencyOptions[] = [
   { name: 'Euro - EUR', code: 'EUR' },
   { name: 'USA Dollar - USD', code: 'USD' },
   { name: 'Afghan Afghani - AFN', code: 'AFN' },
   { name: 'Albanian Lek - ALL', code: 'ALL' },
   { name: 'Algerian Dinar - DZD', code: 'DZD' },
   { name: 'Angolan Kwanza - AOA', code: 'AOA' },
   { name: 'Argentine Peso - ARS', code: 'ARS' },
   { name: 'Armenian Dram - AMD', code: 'AMD' },
   { name: 'Aruban Florin - AWG', code: 'AWG' },
   { name: 'Australian Dollar - AUD', code: 'AUD' },
   { name: 'Azerbaijani Manat - AZN', code: 'AZN' },
   { name: 'Bahamian Dollar - BSD', code: 'BSD' },
   { name: 'Bahraini Dinar - BHD', code: 'BHD' },
   { name: 'Bangladeshi Taka - BDT', code: 'BDT' },
   { name: 'Barbadian Dollar - BBD', code: 'BBD' },
   { name: 'Belarusian Ruble - BYR', code: 'BYR' },
   { name: 'Belarusian Ruble - BYN', code: 'BYN' },
   { name: 'Belize Dollar - BZD', code: 'BZD' },
   { name: 'Bermudian Dollar - BMD', code: 'BMD' },
   { name: 'Bhutanese Ngultrum - BTN', code: 'BTN' },
   { name: 'Bolivian Boliviano - BOB', code: 'BOB' },
   { name: 'Bosnia and Herzegovina Convertible Mark - BAM', code: 'BAM' },
   { name: 'Botswana Pula - BWP', code: 'BWP' },
   { name: 'Brazilian Real - BRL', code: 'BRL' },
   { name: 'British Pound - GBP', code: 'GBP' },
   { name: 'Brunei Dollar - BND', code: 'BND' },
   { name: 'Bulgarian Lev - BGN', code: 'BGN' },
   { name: 'Burundian Franc - BIF', code: 'BIF' },
   { name: 'Cambodian Riel - KHR', code: 'KHR' },
   { name: 'Canadian Dollar - CAD', code: 'CAD' },
   { name: 'Cape Verdean Escudo - CVE', code: 'CVE' },
   { name: 'Cayman Islands Dollar - KYD', code: 'KYD' },
   { name: 'Central African Cfa Franc - XAF', code: 'XAF' },
   { name: 'Cfp Franc - XPF', code: 'XPF' },
   { name: 'Chilean Peso - CLP', code: 'CLP' },
   { name: 'Chinese Renminbi Yuan - CNY', code: 'CNY' },
   { name: 'Colombian Peso - COP', code: 'COP' },
   { name: 'Comorian Franc - KMF', code: 'KMF' },
   { name: 'Congolese Franc - CDF', code: 'CDF' },
   { name: 'Costa Rican Colón - CRC', code: 'CRC' },
   { name: 'Croatian Kuna - HRK', code: 'HRK' },
   { name: 'Cuban Convertible Peso - CUC', code: 'CUC' },
   { name: 'Cuban Peso - CUP', code: 'CUP' },
   { name: 'Czech Koruna - CZK', code: 'CZK' },
   { name: 'Danish Krone - DKK', code: 'DKK' },
   { name: 'Djiboutian Franc - DJF', code: 'DJF' },
   { name: 'Dominican Peso - DOP', code: 'DOP' },
   { name: 'East Caribbean Dollar - XCD', code: 'XCD' },
   { name: 'Egyptian Pound - EGP', code: 'EGP' },
   { name: 'Eritrean Nakfa - ERN', code: 'ERN' },
   { name: 'Estonian Kroon - EEK', code: 'EEK' },
   { name: 'Ethiopian Birr - ETB', code: 'ETB' },
   { name: 'Falkland Pound - FKP', code: 'FKP' },
   { name: 'Fijian Dollar - FJD', code: 'FJD' },
   { name: 'Gambian Dalasi - GMD', code: 'GMD' },
   { name: 'Georgian Lari - GEL', code: 'GEL' },
   { name: 'Ghanaian Cedi - GHS', code: 'GHS' },
   { name: 'Gibraltar Pound - GIP', code: 'GIP' },
   { name: 'Guatemalan Quetzal - GTQ', code: 'GTQ' },
   { name: 'Guinean Franc - GNF', code: 'GNF' },
   { name: 'Guyanese Dollar - GYD', code: 'GYD' },
   { name: 'Haitian Gourde - HTG', code: 'HTG' },
   { name: 'Honduran Lempira - HNL', code: 'HNL' },
   { name: 'Hong Kong Dollar - HKD', code: 'HKD' },
   { name: 'Hungarian Forint - HUF', code: 'HUF' },
   { name: 'Icelandic Króna - ISK', code: 'ISK' },
   { name: 'Indian Rupee - INR', code: 'INR' },
   { name: 'Indonesian Rupiah - IDR', code: 'IDR' },
   { name: 'Iranian Rial - IRR', code: 'IRR' },
   { name: 'Iraqi Dinar - IQD', code: 'IQD' },
   { name: 'Israeli New Sheqel - ILS', code: 'ILS' },
   { name: 'Jamaican Dollar - JMD', code: 'JMD' },
   { name: 'Japanese Yen - JPY', code: 'JPY' },
   { name: 'Jordanian Dinar - JOD', code: 'JOD' },
   { name: 'Kazakhstani Tenge - KZT', code: 'KZT' },
   { name: 'Kenyan Shilling - KES', code: 'KES' },
   { name: 'Kuwaiti Dinar - KWD', code: 'KWD' },
   { name: 'Kyrgyzstani Som - KGS', code: 'KGS' },
   { name: 'Lao Kip - LAK', code: 'LAK' },
   { name: 'Latvian Lats - LVL', code: 'LVL' },
   { name: 'Lebanese Pound - LBP', code: 'LBP' },
   { name: 'Lesotho Loti - LSL', code: 'LSL' },
   { name: 'Liberian Dollar - LRD', code: 'LRD' },
   { name: 'Libyan Dinar - LYD', code: 'LYD' },
   { name: 'Macanese Pataca - MOP', code: 'MOP' },
   { name: 'Macedonian Denar - MKD', code: 'MKD' },
   { name: 'Malagasy Ariary - MGA', code: 'MGA' },
   { name: 'Malawian Kwacha - MWK', code: 'MWK' },
   { name: 'Malaysian Ringgit - MYR', code: 'MYR' },
   { name: 'Maldivian Rufiyaa - MVR', code: 'MVR' },
   { name: 'Mauritanian Ouguiya - MRO', code: 'MRO' },
   { name: 'Mauritian Rupee - MUR', code: 'MUR' },
   { name: 'Mexican Peso - MXN', code: 'MXN' },
   { name: 'Moldovan Leu - MDL', code: 'MDL' },
   { name: 'Mongolian Tögrög - MNT', code: 'MNT' },
   { name: 'Moroccan Dirham - MAD', code: 'MAD' },
   { name: 'Mozambican Metical - MZN', code: 'MZN' },
   { name: 'Myanmar Kyat - MMK', code: 'MMK' },
   { name: 'Namibian Dollar - NAD', code: 'NAD' },
   { name: 'Nepalese Rupee - NPR', code: 'NPR' },
   { name: 'Netherlands Antillean Gulden - ANG', code: 'ANG' },
   { name: 'New Taiwan Dollar - TWD', code: 'TWD' },
   { name: 'New Zealand Dollar - NZD', code: 'NZD' },
   { name: 'Nicaraguan Córdoba - NIO', code: 'NIO' },
   { name: 'Nigerian Naira - NGN', code: 'NGN' },
   { name: 'North Korean Won - KPW', code: 'KPW' },
   { name: 'Norwegian Krone - NOK', code: 'NOK' },
   { name: 'Omani Rial - OMR', code: 'OMR' },
   { name: 'Pakistani Rupee - PKR', code: 'PKR' },
   { name: 'Panamanian Balboa - PAB', code: 'PAB' },
   { name: 'Papua New Guinean Kina - PGK', code: 'PGK' },
   { name: 'Paraguayan Guaraní - PYG', code: 'PYG' },
   { name: 'Peruvian Nuevo Sol - PEN', code: 'PEN' },
   { name: 'Philippine Peso - PHP', code: 'PHP' },
   { name: 'Polish Złoty - PLN', code: 'PLN' },
   { name: 'Qatari Riyal - QAR', code: 'QAR' },
   { name: 'Romanian Leu - RON', code: 'RON' },
   { name: 'Russian Ruble - RUB', code: 'RUB' },
   { name: 'Rwandan Franc - RWF', code: 'RWF' },
   { name: 'Saint Helenian Pound - SHP', code: 'SHP' },
   { name: 'Salvadoran Colón - SVC', code: 'SVC' },
   { name: 'Samoan Tala - WST', code: 'WST' },
   { name: 'São Tomé and Príncipe Dobra - STD', code: 'STD' },
   { name: 'Saudi Riyal - SAR', code: 'SAR' },
   { name: 'Serbian Dinar - RSD', code: 'RSD' },
   { name: 'Seychellois Rupee - SCR', code: 'SCR' },
   { name: 'Sierra Leonean Leone - SLL', code: 'SLL' },
   { name: 'Singapore Dollar - SGD', code: 'SGD' },
   { name: 'Slovak Koruna - SKK', code: 'SKK' },
   { name: 'Solomon Islands Dollar - SBD', code: 'SBD' },
   { name: 'Somali Shilling - SOS', code: 'SOS' },
   { name: 'South African Rand - ZAR', code: 'ZAR' },
   { name: 'South Korean Won - KRW', code: 'KRW' },
   { name: 'Sri Lankan Rupee - LKR', code: 'LKR' },
   { name: 'Sudanese Pound - SDG', code: 'SDG' },
   { name: 'Surinamese Dollar - SRD', code: 'SRD' },
   { name: 'Swazi Lilangeni - SZL', code: 'SZL' },
   { name: 'Swedish Krona - SEK', code: 'SEK' },
   { name: 'Swiss Franc - CHF', code: 'CHF' },
   { name: 'Syrian Pound - SYP', code: 'SYP' },
   { name: 'Tajikistani Somoni - TJS', code: 'TJS' },
   { name: 'Tanzanian Shilling - TZS', code: 'TZS' },
   { name: 'Thai Baht - THB', code: 'THB' },
   { name: 'Tongan Paʻanga - TOP', code: 'TOP' },
   { name: 'Trinidad and Tobago Dollar - TTD', code: 'TTD' },
   { name: 'Tunisian Dinar - TND', code: 'TND' },
   { name: 'Turkish Lira - TRY', code: 'TRY' },
   { name: 'Turkmenistani Manat - TMM', code: 'TMM' },
   { name: 'Ugandan Shilling - UGX', code: 'UGX' },
   { name: 'Ukrainian Hryvnia - UAH', code: 'UAH' },
   { name: 'United Arab Emirates Dirham - AED', code: 'AED' },
   { name: 'Uruguayan Peso - UYU', code: 'UYU' },
   { name: 'Uzbekistani Som - UZS', code: 'UZS' },
   { name: 'Vanuatu Vatu - VUV', code: 'VUV' },
   { name: 'Venezuelan Bolívar - VEF', code: 'VEF' },
   { name: 'Vietnamese Đồng - VND', code: 'VND' },
   { name: 'West African Cfa Franc - XOF', code: 'XOF' },
   { name: 'Yemeni Rial - YER', code: 'YER' },
   { name: 'Zambian Kwacha - ZMK', code: 'ZMK' },
   { name: 'Zimbabwean Dollar - ZWD', code: 'ZWD' },
]

export const timeFormatOptions: TimeFormatOptions[] = [
   { name: '12-hours', code: '12h' },
   { name: '24-hours', code: '24h' },
]

export const phonePrefixOptions: PhonePrefixOptions[] = [
   { value: '+93', country: 'Afghanistan ', text: '🇦🇫 +93' },
   { value: '+355', country: 'Albania ', text: '🇦🇱 +355' },
   { value: '+213', country: 'Algeria ', text: '🇩🇿 +213' },
   { value: '+1 684', country: 'American Samoa ', text: '🇦🇸 +1 684' },
   { value: '+376', country: 'Andorra ', text: '🇦🇩 +376' },
   { value: '+244', country: 'Angola ', text: '🇦🇴 +244' },
   { value: '+1 264', country: 'Anguilla ', text: '🇦🇮 +1 264' },
   { value: '+672', country: 'Antarctica ', text: '🇦🇶 +672' },
   { value: '+1 268', country: 'Antigua And Barbuda ', text: '🇦🇬 +1 268' },
   { value: '+54', country: 'Argentina ', text: '🇦🇷 +54' },
   { value: '+374', country: 'Armenia ', text: '🇦🇲 +374' },
   { value: '+297', country: 'Aruba ', text: '🇦🇼 +297' },
   { value: '+247', country: 'Ascension Island  ', text: '+247' },
   { value: '+61', country: 'Australia ', text: '🇦🇺 +61' },
   { value: '+43', country: 'Austria ', text: '🇦🇹 +43' },
   { value: '+994', country: 'Azerbaijan ', text: '🇦🇿 +994' },
   { value: '+1 242', country: 'Bahamas ', text: '🇧🇸 +1 242' },
   { value: '+973', country: 'Bahrain ', text: '🇧🇭 +973' },
   { value: '+880', country: 'Bangladesh ', text: '🇧🇩 +880' },
   { value: '+1 246', country: 'Barbados ', text: '🇧🇧 +1 246' },
   { value: '+375', country: 'Belarus ', text: '🇧🇾 +375' },
   { value: '+32', country: 'Belgium ', text: '🇧🇪 +32' },
   { value: '+501', country: 'Belize ', text: '🇧🇿 +501' },
   { value: '+229', country: 'Benin ', text: '🇧🇯 +229' },
   { value: '+1 441', country: 'Bermuda ', text: '🇧🇲 +1 441' },
   { value: '+975', country: 'Bhutan ', text: '🇧🇹 +975' },
   { value: '+387', country: 'Bosnia & Herzegovina', text: '🇧🇦 +387' },
   { value: '+267', country: 'Botswana ', text: '🇧🇼 +267' },
   { value: '+591', country: 'Bolivia ', text: '🇧🇴 +591' },
   { value: '+55', country: 'Brazil ', text: '🇧🇷 +55' },
   { value: '+246', country: 'British Indian Ocean Territory ', text: '🇮🇴 +246' },
   { value: '+673', country: 'Brunei Darussalam ', text: '🇧🇳 +673' },
   { value: '+359', country: 'Bulgaria ', text: '🇧🇬 +359' },
   { value: '+226', country: 'Burkina Faso ', text: '🇧🇫 +226' },
   { value: '+257', country: 'Burundi ', text: '🇧🇮 +257' },
   { value: '+238', country: 'Cabo Verde ', text: '🇨🇻 +238' },
   { value: '+855', country: 'Cambodia ', text: '🇰🇭 +855' },
   { value: '+237', country: 'Cameroon ', text: '🇨🇲 +237' },
   { value: '+1', country: 'Canada ', text: '🇨🇦 +1' },
   { value: '+236', country: 'Central African Republic ', text: '🇨🇫 +236' },
   { value: '+235', country: 'Chad ', text: '🇹🇩 +235' },
   { value: '+56', country: 'Chile ', text: '🇨🇱 +56' },
   { value: '+86', country: 'China ', text: '🇨🇳 +86' },
   { value: '+57', country: 'Colombia ', text: '🇨🇴 +57' },
   { value: '+269', country: 'Comoros ', text: '🇰🇲 +269' },
   { value: '+682', country: 'Cook Islands ', text: '🇨🇰 +682' },
   { value: '+506', country: 'Costa Rica ', text: '🇨🇷 +506' },
   { value: '+385', country: 'Croatia ', text: '🇭🇷 +385' },
   { value: '+53', country: 'Cuba ', text: '🇨🇺 +53' },
   { value: '+357', country: 'Cyprus ', text: '🇨🇾 +357' },
   { value: '+420', country: 'Czech Republic ', text: '🇨🇿 +420' },
   { value: '+225', country: "Côte d'Ivoire", text: '🇨🇮 +225' },
   { value: '+243', country: 'Democratic Republic Of Congo ', text: '🇨🇩 +243' },
   { value: '+45', country: 'Denmark ', text: '🇩🇰 +45' },
   { value: '+253', country: 'Djibouti ', text: '🇩🇯 +253' },
   { value: '+1 767', country: 'Dominica ', text: '🇩🇲 +1 767' },
   { value: '+1 809', country: 'Dominican Republic ', text: '🇩🇴 +1 809' },
   { value: '+1 1829', country: 'Dominican Republic ', text: '🇩🇴 +1 829' },
   { value: '+1 1849', country: 'Dominican Republic ', text: '🇩🇴 +1 849' },
   { value: '+593', country: 'Ecuador ', text: '🇪🇨 +593' },
   { value: '+20', country: 'Egypt ', text: '🇪🇬 +20' },
   { value: '+503', country: 'El Salvador ', text: '🇸🇻 +503' },
   { value: '+240', country: 'Equatorial Guinea ', text: '🇬🇶 +240' },
   { value: '+291', country: 'Eritrea ', text: '🇪🇷 +291' },
   { value: '+372', country: 'Estonia ', text: '🇪🇪 +372' },
   { value: '+251', country: 'Ethiopia ', text: '🇪🇹 +251' },
   { value: '+388', country: 'European Union ', text: '🇪🇺 +388' },
   { value: '+500', country: 'Falkland Islands ', text: '🇫🇰 +500' },
   { value: '+298', country: 'Faroe Islands ', text: '🇫🇴 +298' },
   { value: '+679', country: 'Fiji ', text: '🇫🇯 +679' },
   { value: '+358', country: 'Finland ', text: '🇫🇮 +358' },
   { value: '+33', country: 'France ', text: '🇫🇷 +33' },
   { value: '+594', country: 'French Guiana ', text: '🇬🇫 +594' },
   { value: '+689', country: 'French Polynesia ', text: '🇵🇫 +689' },
   { value: '+220', country: 'Gambia ', text: '🇬🇲 +220' },
   { value: '+995', country: 'Georgia ', text: '🇬🇪 +995' },
   { value: '+49', country: 'Germany ', text: '🇩🇪 +49' },
   { value: '+233', country: 'Ghana ', text: '🇬🇭 +233' },
   { value: '+350', country: 'Gibraltar ', text: '🇬🇮 +350' },
   { value: '+30', country: 'Greece ', text: '🇬🇷 +30' },
   { value: '+299', country: 'Greenland ', text: '🇬🇱 +299' },
   { value: '+473', country: 'Grenada ', text: '🇬🇩 +473' },
   { value: '+1 671', country: 'Guam ', text: '🇬🇺 +1 671' },
   { value: '+502', country: 'Guatemala ', text: '🇬🇹 +502' },
   { value: '+245', country: 'Guinea', text: '-bissau 🇬🇼 +245' },
   { value: '+224', country: 'Guinea ', text: '🇬🇳 +224' },
   { value: '+592', country: 'Guyana ', text: '🇬🇾 +592' },
   { value: '+509', country: 'Haiti ', text: '🇭🇹 +509' },
   { value: '+504', country: 'Honduras ', text: '🇭🇳 +504' },
   { value: '+852', country: 'Hong Kong ', text: '🇭🇰 +852' },
   { value: '+36', country: 'Hungary ', text: '🇭🇺 +36' },
   { value: '+354', country: 'Iceland ', text: '🇮🇸 +354' },
   { value: '+91', country: 'India ', text: '🇮🇳 +91' },
   { value: '+62', country: 'Indonesia ', text: '🇮🇩 +62' },
   { value: '+98', country: 'Iran', text: ', Islamic Republic Of 🇮🇷 +98' },
   { value: '+964', country: 'Iraq ', text: '🇮🇶 +964' },
   { value: '+353', country: 'Ireland ', text: '🇮🇪 +353' },
   { value: '+972', country: 'Israel ', text: '🇮🇱 +972' },
   { value: '+39', country: 'Italy ', text: '🇮🇹 +39' },
   { value: '+1 876', country: 'Jamaica ', text: '🇯🇲 +1 876' },
   { value: '+81', country: 'Japan ', text: '🇯🇵 +81' },
   { value: '+962', country: 'Jordan ', text: '🇯🇴 +962' },
   { value: '+7', country: 'Kazakhstan', text: '🇰🇿 +7' },
   { value: '+254', country: 'Kenya ', text: '🇰🇪 +254' },
   { value: '+686', country: 'Kiribati ', text: '🇰🇮 +686' },
   { value: '+850', text: '🇰🇵 +850', country: "Korea, Democratic People's Republic Of " },
   { value: '+383', country: 'Kosovo', text: '+383' },
   { value: '+965', country: 'Kuwait ', text: '🇰🇼 +965' },
   { value: '+996', country: 'Kyrgyzstan ', text: '🇰🇬 +996' },
   { value: '+856', country: "Lao People's Democratic Republic", text: ' 🇱🇦 +856' },
   { value: '+371', country: 'Latvia ', text: '🇱🇻 +371' },
   { value: '+961', country: 'Lebanon ', text: '🇱🇧 +961' },
   { value: '+266', country: 'Lesotho ', text: '🇱🇸 +266' },
   { value: '+231', country: 'Liberia ', text: '🇱🇷 +231' },
   { value: '+218', country: 'Libya ', text: '🇱🇾 +218' },
   { value: '+423', country: 'Liechtenstein ', text: '🇱🇮 +423' },
   { value: '+370', country: 'Lithuania ', text: '🇱🇹 +370' },
   { value: '+352', country: 'Luxembourg ', text: '🇱🇺 +352' },
   { value: '+853', country: 'Macao ', text: '🇲🇴 +853' },
   { value: '+389', country: 'Macedonia, The Former Yugoslav Republic Of', text: '🇲🇰 +389' },
   { value: '+261', country: 'Madagascar ', text: '🇲🇬 +261' },
   { value: '+265', country: 'Malawi ', text: '🇲🇼 +265' },
   { value: '+60', country: 'Malaysia ', text: '🇲🇾 +60' },
   { value: '+960', country: 'Maldives ', text: '🇲🇻 +960' },
   { value: '+223', country: 'Mali ', text: '🇲🇱 +223' },
   { value: '+356', country: 'Malta ', text: '🇲🇹 +356' },
   { value: '+692', country: 'Marshall Islands ', text: '🇲🇭 +692' },
   { value: '+596', country: 'Martinique ', text: '🇲🇶 +596' },
   { value: '+222', country: 'Mauritania ', text: '🇲🇷 +222' },
   { value: '+230', country: 'Mauritius ', text: '🇲🇺 +230' },
   { value: '+52', country: 'Mexico ', text: '🇲🇽 +52' },
   { value: '+373', country: 'Moldova ', text: '🇲🇩 +373' },
   { value: '+377', country: 'Monaco ', text: '🇲🇨 +377' },
   { value: '+976', country: 'Mongolia ', text: '🇲🇳 +976' },
   { value: '+382', country: 'Montenegro ', text: '🇲🇪 +382' },
   { value: '+1 664', country: 'Montserrat ', text: '🇲🇸 +1 664' },
   { value: '+258', country: 'Mozambique ', text: '🇲🇿 +258' },
   { value: '+95', country: 'Myanmar ', text: '🇲🇲 +95' },
   { value: '+264', country: 'Namibia ', text: '🇳🇦 +264' },
   { value: '+674', country: 'Nauru ', text: '🇳🇷 +674' },
   { value: '+977', country: 'Nepal ', text: '🇳🇵 +977' },
   { value: '+31', country: 'Netherlands ', text: '🇳🇱 +31' },
   { value: '+687', country: 'New Caledonia ', text: '🇳🇨 +687' },
   { value: '+64', country: 'New Zealand ', text: '🇳🇿 +64' },
   { value: '+505', country: 'Nicaragua ', text: '🇳🇮 +505' },
   { value: '+227', country: 'Niger ', text: '🇳🇪 +227' },
   { value: '+234', country: 'Nigeria ', text: '🇳🇬 +234' },
   { value: '+683', country: 'Niue ', text: '🇳🇺 +683' },
   { value: '+1 670', country: 'Northern Mariana Islands ', text: '🇲🇵 +1 670' },
   { value: '+47', country: 'Norway ', text: '🇳🇴 +47' },
   { value: '+968', country: 'Oman ', text: '🇴🇲 +968' },
   { value: '+92', country: 'Pakistan ', text: '🇵🇰 +92' },
   { value: '+680', country: 'Palau ', text: '🇵🇼 +680' },
   { value: '+970', country: 'Palestinian Territory', text: ', Occupied 🇵🇸 +970' },
   { value: '+507', country: 'Panama ', text: '🇵🇦 +507' },
   { value: '+675', country: 'Papua New Guinea ', text: '🇵🇬 +675' },
   { value: '+595', country: 'Paraguay ', text: '🇵🇾 +595' },
   { value: '+51', country: 'Peru ', text: '🇵🇪 +51' },
   { value: '+63', country: 'Philippines ', text: '🇵🇭 +63' },
   { value: '+872', country: 'Pitcairn ', text: '🇵🇳 +872' },
   { value: '+48', country: 'Poland ', text: '🇵🇱 +48' },
   { value: '+351', country: 'Portugal ', text: '🇵🇹 +351' },
   { value: '+1 787', country: 'Puerto Rico ', text: '🇵🇷 +1 787' },
   { value: '+1 939', country: 'Puerto Rico ', text: '🇵🇷 +1 939' },
   { value: '+974', country: 'Qatar ', text: '🇶🇦 +974' },
   { value: '+242', country: 'Republic Of Congo ', text: '🇨🇬 +242' },
   { value: '+262', country: 'Reunion ', text: '🇷🇪 +262' },
   { value: '+40', country: 'Romania ', text: '🇷🇴 +40' },
   { value: '+7', country: 'Russian Federation ', text: '🇷🇺 +7' },
   { value: '+250', country: 'Rwanda ', text: '🇷🇼 +250' },
   { value: '+590', country: 'Saint Barth élemy', text: '🇧🇱 +590' },
   { value: '+290', country: 'Saint Helena, Ascension And Tristan Da Cunha', text: '🇸🇭 +290' },
   { value: '+1 869', country: 'Saint Kitts And Nevis ', text: '🇰🇳 +1 869' },
   { value: '+1 758', country: 'Saint Lucia ', text: '🇱🇨 +1 758' },
   { value: '+508', country: 'Saint Pierre And Miquelon ', text: '🇵🇲 +508' },
   { value: '+1 784', country: 'Saint Vincent And The Grenadines ', text: '🇻🇨 +1 784' },
   { value: '+685', country: 'Samoa ', text: '🇼🇸 +685' },
   { value: '+378', country: 'San Marino ', text: '🇸🇲 +378' },
   { value: '+239', country: 'Sao Tome and Principe ', text: '🇸🇹 +239' },
   { value: '+966', country: 'Saudi Arabia ', text: '🇸🇦 +966' },
   { value: '+221', country: 'Senegal ', text: '🇸🇳 +221' },
   { value: '+381', country: 'Serbia ', text: '🇷🇸 +381' },
   { value: '+248', country: 'Seychelles ', text: '🇸🇨 +248' },
   { value: '+232', country: 'Sierra Leone ', text: '🇸🇱 +232' },
   { value: '+65', country: 'Singapore ', text: '🇸🇬 +65' },
   { value: '+1 721', country: 'Sint Maarten ', text: '🇸🇽 +1 721' },
   { value: '+421', country: 'Slovakia ', text: '🇸🇰 +421' },
   { value: '+386', country: 'Slovenia ', text: '🇸🇮 +386' },
   { value: '+677', country: 'Solomon Islands ', text: '🇸🇧 +677' },
   { value: '+252', country: 'Somalia ', text: '🇸🇴 +252' },
   { value: '+27', country: 'South Africa ', text: '🇿🇦 +27' },
   { value: '+211', country: 'South Sudan ', text: '🇸🇸 +211' },
   { value: '+34', country: 'Spain ', text: '🇪🇸 +34' },
   { value: '+94', country: 'Sri Lanka ', text: '🇱🇰 +94' },
   { value: '+249', country: 'Sudan ', text: '🇸🇩 +249' },
   { value: '+597', country: 'Suriname ', text: '🇸🇷 +597' },
   { value: '+268', country: 'Swaziland ', text: '🇸🇿 +268' },
   { value: '+46', country: 'Sweden ', text: '🇸🇪 +46' },
   { value: '+41', country: 'Switzerland ', text: '🇨🇭 +41' },
   { value: '+963', country: 'Syrian Arab Republic ', text: '🇸🇾 +963' },
   { value: '+886', country: 'Taiwan ', text: '🇹🇼 +886' },
   { value: '+992', country: 'Tajikistan ', text: '🇹🇯 +992' },
   { value: '+255', country: 'Tanzania, United Republic Of', text: '🇹🇿 +255' },
   { value: '+66', country: 'Thailand ', text: '🇹🇭 +66' },
   { value: '+670', country: 'Timor', text: '-Leste, Democratic Republic of 🇹🇱 +670' },
   { value: '+228', country: 'Togo ', text: '🇹🇬 +228' },
   { value: '+690', country: 'Tokelau ', text: '🇹🇰 +690' },
   { value: '+676', country: 'Tonga ', text: '🇹🇴 +676' },
   { value: '+1 868', country: 'Trinidad And Tobago ', text: '🇹🇹 +1 868' },
   { value: '+216', country: 'Tunisia ', text: '🇹🇳 +216' },
   { value: '+90', country: 'Turkey ', text: '🇹🇷 +90' },
   { value: '+993', country: 'Turkmenistan ', text: '🇹🇲 +993' },
   { value: '+1 649', country: 'Turks And Caicos Islands ', text: '🇹🇨 +1 649' },
   { value: '+688', country: 'Tuvalu ', text: '🇹🇻 +688' },
   { value: '+256', country: 'Uganda ', text: '🇺🇬 +256' },
   { value: '+380', country: 'Ukraine ', text: '🇺🇦 +380' },
   { value: '+971', country: 'United Arab Emirates ', text: '🇦🇪 +971' },
   { value: '+44', country: 'United Kingdom ', text: '🇬🇧 +44' },
   { value: '+1', country: 'United States ', text: '🇺🇸 +1' },
   { value: '+598', country: 'Uruguay ', text: '🇺🇾 +598' },
   { value: '+998', country: 'Uzbekistan ', text: '🇺🇿 +998' },
   { value: '+678', country: 'Vanuatu ', text: '🇻🇺 +678' },
   { value: '+379', country: 'Vatican City State ', text: '🇻🇦 +379' },
   { value: '+58', country: 'Venezuela, Bolivarian Republic Of', text: '🇻🇪 +58' },
   { value: '+84', country: 'Viet Nam ', text: '🇻🇳 +84' },
   { value: '+1 284', country: 'Virgin Islands ', text: '(British) 🇻🇬 +1 284' },
   { value: '+1 340', country: 'Virgin Islands ', text: '(US) 🇻🇮 +1 340' },
   { value: '+681', country: 'Wallis And Futuna ', text: '🇼🇫 +681' },
   { value: '+212', country: 'Morocco ', text: '🇲🇦 +212' },
   { value: '+967', country: 'Yemen ', text: '🇾🇪 +967' },
   { value: '+260', country: 'Zambia ', text: '🇿🇲 +260' },
   { value: '+263', country: 'Zimbabwe ', text: '🇿🇼 +263' },
]

export const monthOptions = [
   { code: 0, name: 'calendar.january' },
   { code: 1, name: 'calendar.february' },
   { code: 2, name: 'calendar.march' },
   { code: 3, name: 'calendar.april' },
   { code: 4, name: 'calendar.may' },
   { code: 5, name: 'calendar.june' },
   { code: 6, name: 'calendar.july' },
   { code: 7, name: 'calendar.august' },
   { code: 8, name: 'calendar.september' },
   { code: 9, name: 'calendar.october' },
   { code: 10, name: 'calendar.november' },
   { code: 11, name: 'calendar.december' },
]

export const monthShortOptions = [
   { code: 0, name: 'calendar.jan' },
   { code: 1, name: 'calendar.feb' },
   { code: 2, name: 'calendar.mar' },
   { code: 3, name: 'calendar.apr' },
   { code: 4, name: 'calendar.may.short' },
   { code: 5, name: 'calendar.jun' },
   { code: 6, name: 'calendar.jul' },
   { code: 7, name: 'calendar.aug' },
   { code: 8, name: 'calendar.sept' },
   { code: 9, name: 'calendar.oct' },
   { code: 10, name: 'calendar.nov' },
   { code: 11, name: 'calendar.dec' },
]

export const yearOptions = [...Array(126)].map((_, i) => ({
   code: 2025 - i,
   name: `${2025 - i}`,
}))

export const weekOptions = [
   { code: 1, name: 'calendar.mon' },
   { code: 2, name: 'calendar.tue' },
   { code: 3, name: 'calendar.wed' },
   { code: 4, name: 'calendar.thu' },
   { code: 5, name: 'calendar.fri' },
   { code: 6, name: 'calendar.sat' },
   { code: 0, name: 'calendar.sun' },
]

export const genderOptions = [
   { code: 'unknown', name: 'dropdown.gender.unknown' },
   { code: 'male', name: 'dropdown.gender.male' },
   { code: 'female', name: 'dropdown.gender.female' },
]

export const durationOptions = [
   { code: 5, name: '5min' },
   { code: 10, name: '10min' },
   { code: 15, name: '15min' },
   { code: 20, name: '20min' },
   { code: 25, name: '25min' },
   { code: 30, name: '30min' },
   { code: 35, name: '35min' },
   { code: 40, name: '40min' },
   { code: 45, name: '45min' },
   { code: 50, name: '50min' },
   { code: 55, name: '55min' },
   { code: 60, name: '1h' },
   { code: 65, name: '1h 5min' },
   { code: 70, name: '1h 10min' },
   { code: 75, name: '1h 15min' },
   { code: 80, name: '1h 20min' },
   { code: 85, name: '1h 25min' },
   { code: 90, name: '1h 30min' },
   { code: 95, name: '1h 35min' },
   { code: 100, name: '1h 40min' },
   { code: 105, name: '1h 45min' },
   { code: 110, name: '1h 50min' },
   { code: 115, name: '1h 55min' },
   { code: 120, name: '2h' },
   { code: 135, name: '2h 15min' },
   { code: 150, name: '2h 30min' },
   { code: 165, name: '2h 45min' },
   { code: 180, name: '3h' },
   { code: 195, name: '3h 15min' },
   { code: 210, name: '3h 30min' },
   { code: 225, name: '3h 45min' },
   { code: 240, name: '4h' },
   { code: 270, name: '4h 30min' },
   { code: 300, name: '5h' },
   { code: 330, name: '5h 30min' },
   { code: 360, name: '6h' },
   { code: 390, name: '6h 30min' },
   { code: 420, name: '7h' },
   { code: 450, name: '7h 30min' },
   { code: 480, name: '8h' },
   { code: 540, name: '9h' },
   { code: 600, name: '10h' },
   { code: 660, name: '11h' },
   { code: 720, name: '12h' },
]

export const startWeekOptions = [
   { code: 0, name: 'calendar.sunday' },
   { code: 1, name: 'calendar.monday' },
   { code: 2, name: 'calendar.tuesday' },
   { code: 3, name: 'calendar.wednesday' },
   { code: 4, name: 'calendar.thursday' },
   { code: 5, name: 'calendar.friday' },
   { code: 6, name: 'calendar.saturday' },
]

export const addedClientdateOption = [
   { code: 20, name: 20 },
   { code: 30, name: 30 },
   { code: 45, name: 45 },
   { code: 60, name: 60 },
   { code: 90, name: 90 },
]

export const sendToClientOption = [
   {
      code: 'birthday',
      name: 'On the day of Birthday',
   },
   {
      code: 'birthday',
      name: 'On the day of Birthday',
   },
]

export const winBackClientOption = [
   { code: 365, name: 365 },
   { code: 375, name: 375 },
   { code: 380, name: 380 },
   { code: 385, name: 385 },
]

export const regularClientsOption = [
   { code: 1, name: 1 },
   { code: 2, name: 2 },
   { code: 3, name: 3 },
   { code: 4, name: 4 },
   { code: 5, name: 5 },
   { code: 10, name: 10 },
]
