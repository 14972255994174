import { Button, CustomIcon, Text, withModal } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { RoutePath } from 'constants/routes'
import React from 'react'
import styled from 'styled-components'

import * as SC from '../styles'

const StyledButton = styled(Button)`
   box-shadow: none;
`

function PremiumFeatureModal(props: any) {
   const { navigate } = useLocation()

   const goToPricingPage = () => {
      props.modalProps.onExit()
      navigate(RoutePath.BILLING)
   }

   return (
      <SC.PremiumModalContainer>
         <CustomIcon type="star" />

         <Text colorType="yellow" weight="semiBold" style={{ fontSize: '28px' }} mb="medium">
            <IntlLabel label="title.premiumFeature" />
         </Text>
         <Text colorType="base" weight="normal" size="base" mb="large" isCenter>
            <IntlLabel label="client.export.premium.description" />
         </Text>

         <SC.PremiumModalFooter>
            <Button
               type="primary"
               style={{ background: '#2472E8', borderRadius: '38px', padding: '0 67px' }}
               onClick={goToPricingPage}>
               <Text colorType="white" weight="medium" size="base" mb="none">
                  <IntlLabel label="btn.upgrade" />
               </Text>
            </Button>
            <StyledButton upperCase={false} onClick={props.modalProps.onExit}>
               <Text colorType="base" weight="semiBold" size="base" mb="none">
                  <IntlLabel label="btn.close" />
               </Text>
            </StyledButton>
         </SC.PremiumModalFooter>
      </SC.PremiumModalContainer>
   )
}

export default withModal(null, { contentClassName: 'premium-feature-modal' })(PremiumFeatureModal)
