import styled from 'styled-components'

export const MobileMenuContainer = styled.aside`
   z-index: ${({ theme }) => theme.zIndexMobileMenu};
   background: white;
   width: 100%;
   padding-top: 45px;
   padding-bottom: 100px;
   [data-user-name-title] {
      font-size: 1.8rem;
      color: ${({ theme }) => theme.primaryColor};
   }
   [data-user-avatar] {
      margin-right: 10px;
      zoom: 1.3;
   }
`

export const Item = styled.div`
   width: 100%;
   a {
      display: flex;
      padding: 0 22px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.primaryColor};
      &:hover,
      &:active,
      &:focus {
         background: #f7f7f8;
         text-decoration: none;
      }
      i svg {
         padding-top: 3px;
         max-width: 20px;
         min-width: 20px;
      }
      svg:first-of-type {
         font-size: 20px;
         margin-right: 10px;
         path {
            fill: #b1bacf;
         }
      }
      i svg:last-of-type {
         margin-left: auto;
      }
   }
`

export const UpdateContainer = styled.div`
   width: 100vw;
   margin: 12px 12px 0;

   button {
      width: calc(100vw - 24px);
      border-radius: 5px;
      background: #136ef6;
   }
`

export const SpaceItem = styled.div`
   background: ${({ theme }) => theme.layoutBodyBackground};
   width: 100%;
   height: 60px;
   padding: 10px 21px;

   button {
      background: #349447;
   }
`

export const ManageUser = styled.a`
   display: flex;
   width: 100%;
   justify-content: space-between;
   margin: 0 10px 10px 10px;
   padding: 10px 10px 20px 10px;
   border-bottom: 1px solid #eef1f6;
   text-decoration: none !important;
   position: relative;

   &:hover,
   &:active,
   &:focus {
      background: #f7f7f8;
      text-decoration: none;
   }

   [data-user] {
      display: flex;

      [data-manage-user-info] {
         display: flex;
         flex-direction: column;
         justify-content: left;
         line-height: 1.3;
      }
      [data-manage-user-title] {
         padding-top: 10px;
         font-weight: 500;
         color: ${({ theme }) => theme.primaryColor};
         & + div {
            font-size: 14px;
            color: #798aac;
         }
      }
   }

   [data-menu-right-icon] {
      color: ${({ theme }) => theme.primaryColor};
      justify-content: center;
      align-items: center;
      display: flex;
      margin-left: auto;
   }
`

export const Header = styled.nav`
   position: fixed;
   z-index: ${({ theme }) => theme.zIndexMobileMenuHeader};
   top: 0;
   width: 100%;
   background: white;
   display: flex;
   flex-direction: row-reverse;
   align-items: center;
   padding: 10px 12px 5px 0;
   font-size: 20px;
   height: 45px;
   margin-bottom: 10px;
   a {
      cursor: pointer;
      text-align: right;
      color: #adb6cc;
      width: 40px;
   }
`
