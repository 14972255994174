import { arrayMoveImmutable } from 'array-move'

export const moveElementItem = (data: any[], id: any, params: any) =>
   (data || []).map((e: any) =>
      e.id === id
         ? {
              ...e,
              items: arrayMoveImmutable(e.items, params.oldIndex, params.newIndex),
           }
         : e,
   )
