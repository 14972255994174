const theme = {
   calendarHeaderHeight: 50,
   timeColumnWidth: 48,
   cardMinWidth: 30,
   columnMinWidth: 100,
   columnWeekMinWidth: 40,
   timeCellHeight: 50,
   baseSlotHeight: 25,

   scrollbarWidth: 10,

   zIndexTimeColumn: 104,
   zIndexColumnHeader: 105,
   zIndexTimeSelect: 103,
   zIndexTimeCellHeader: 105,
   zIndexNowLine: 104,
   zIndexCardDrag: 104,
   zIndexCard: 3,
}

export default theme
