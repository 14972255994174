import { IntlLabel } from '@plandok/i18n'
import { LinkWithLanguage } from 'components'
import { RoutePath } from 'constants/routes'
import React from 'react'

export default function LoginFooter() {
   return (
      <div className="text-center">
         <LinkWithLanguage to={RoutePath.RESET_PASSWORD} label="login.forgot.password" />
         <div data-footer-info>
            <IntlLabel label="login.no.account" /> <LinkWithLanguage to={RoutePath.REGISTRATION} label="link.sign.up" />
         </div>
      </div>
   )
}
