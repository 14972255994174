import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ModalProps {
   type?: string
   modalProps?: any
   visible: boolean
}

interface ModalState {
   modals: ModalProps[]
}

const initialState: ModalState = {
   modals: [],
}

const modalSlice = createSlice({
   name: 'modals',
   initialState,
   reducers: {
      showModal: (state, action: PayloadAction<{ type?: string; modalProps?: any }>) => {
         state.modals.push({
            ...action.payload,
            visible: true,
         })
      },
      hideModal: (state, action: PayloadAction<string>) => {
         state.modals = state.modals.filter(modal =>
            modal.type !== action.payload
         )
      },
      hideAllModals: state => {
         state.modals = []
      },
   },
})

export const { showModal, hideModal, hideAllModals } = modalSlice.actions
export default modalSlice
