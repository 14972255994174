import './styles/style.less'

import { getIsMobile, Text } from '@plandok/core'
import * as api from 'api'
import React, { useState } from 'react'

import calendarState from '../../store/calendar.state'
import { replaceHistoryState } from '../../store/calendar-state.helpers'
import * as SC from './styles'

type DeletedAppointment = {
   id: string
   status: string
}

type BtnUndoProps = {
   isVisibleUndo: boolean
   setIsVisibleUndo: (value: boolean) => void
   deletedAppointment: DeletedAppointment
   refetch?: () => void
}

export default function BtnUndo({ isVisibleUndo, setIsVisibleUndo, deletedAppointment, refetch }: BtnUndoProps) {
   const [isClicked, setIsClicked] = useState(false)
   const isMobile = getIsMobile()

   const TIME_BEFORE_DISAPPEAR = 1000
   const LONGER_DURATION = TIME_BEFORE_DISAPPEAR * 10

   const hideUndoBtn = () => {
      setIsVisibleUndo(false)
      setIsClicked(false)
   }

   const onCancelUndo = () => {
      replaceHistoryState()
      setTimeout(hideUndoBtn, TIME_BEFORE_DISAPPEAR)
   }

   const undoDeleteAppointment = async () => {
      await api.changeAppointmentStatus(deletedAppointment.id, { status: deletedAppointment.status })
      replaceHistoryState()

      if (refetch) {
         refetch()
      } else {
         calendarState.fetchData()
      }

      setIsClicked(true)
      setTimeout(hideUndoBtn, LONGER_DURATION)
   }

   return (
      <div className={`overlay ${isVisibleUndo && 'show'}`}>
         <SC.UndoContainer isMobile={isMobile}>
            <Text
               mb="none"
               label={isClicked ? 'app.actionUndone.button.title' : 'app.appointmentCancelled.button.title'}
               colorType="white"
               size={isMobile ? 'small' : 'base'}
            />
            {!isClicked && (
               <SC.BtnUndo
                  onClick={undoDeleteAppointment}
                  upperCase={false}
                  label="app.undo.button.title"
                  isMobile={isMobile}
               />
            )}
            <SC.BtnClose onClick={onCancelUndo} label="X" isMobile={isMobile} semiBold />
         </SC.UndoContainer>
      </div>
   )
}
