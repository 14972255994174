import { AppSetupList, NoInternetConnection } from 'components'
import React from 'react'

import { columnsConfig, fakeData } from './config'

export default function DiscountsPage() {
   return (
      <NoInternetConnection>
         <AppSetupList
            btnLabel="btn.new.discount"
            data={fakeData}
            columns={columnsConfig}
            title="discount.list.title"
            modalType="CREATE_DISCOUNT"
         />
      </NoInternetConnection>
   )
}
