type IConfirmResponse = {
   response: boolean
   errorMessage?: string
}

function useStripeHook() {
   const loadComponent = (elements?: any, style?: any) => {
      const numberElement = document.getElementById('card-number-element')
      const expiryElement = document.getElementById('card-expiry-element')
      const cvcElement = document.getElementById('card-cvc-element')

      const cardNumberElement =
         elements.getElement('cardNumber') ||
         elements.create('cardNumber', {
            style,
            placeholder: '•••• •••• •••• ••••',
         })

      const cardExpiryElement =
         elements.getElement('cardExpiry') ||
         elements.create('cardExpiry', {
            style,
            placeholder: 'MM/YY',
         })

      const cardCvcElement =
         elements.getElement('cardCvc') ||
         elements.create('cardCvc', {
            style,
            placeholder: '•••',
         })

      if (numberElement && expiryElement && cvcElement) {
         cardNumberElement.mount('#card-number-element')
         cardExpiryElement.mount('#card-expiry-element')
         cardCvcElement.mount('#card-cvc-element')
      }
   }

   const confirmPayment = async (
      stripe: any,
      paymentStatus: string,
      clientSecret: string,
      payload?: any,
   ): Promise<IConfirmResponse> => {
      if (paymentStatus === 'succeeded' || paymentStatus === 'active') {
         return {
            response: true,
         }
      } else if (paymentStatus === 'incomplete' || paymentStatus === 'requires_action') {
         if (!stripe) {
            return {
               response: false,
            }
         }

         // if subscription is not active then we try 3d verification again
         const { error: confirmPayment } = await stripe.confirmCardPayment(clientSecret, payload)

         return {
            response: !confirmPayment,
            errorMessage: confirmPayment ? confirmPayment.message : undefined,
         }
      }

      return {
         response: false,
      }
   }

   return {
      loadComponent,
      confirmPayment,
   }
}

export default useStripeHook
