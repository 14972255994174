import { FieldRenderProps } from 'react-final-form'
import withFormBlock from './FormBlock/withFormBlock'
import DatePicker from '../input/DatePicker'
import React from 'react'

interface FormDatepickerProps extends FieldRenderProps<any, any> {
   submitting?: boolean
   disabled?: boolean
}

const FormDatepicker = ({ input, submitting, ...rest }: FormDatepickerProps) => {
   return <DatePicker id={input.name} {...input} {...rest} disabled={submitting || rest.disabled} />
}

export default withFormBlock<FormDatepickerProps>(FormDatepicker, { className: 'date-picker-form' })
