import Icon from '@ant-design/icons'
import { Text, useModal } from '@plandok/core'
import { Spin } from 'antd'
import { App_Location } from 'graphql/generatedTypes'
import { useBookingLocationsQuery } from 'graphql/queries/bookingLocations.generated'
import React from 'react'

import BookingProfileCard from './components/BookingProfileCard/BookingProfileCard'
import * as SC from './styles'

export default function BookingProfile() {
   const { data, loading, refetch } = useBookingLocationsQuery()
   const [, { showModal }] = useModal()

   const locations = data?.locations?.edges?.map((e: any) => e?.node)?.filter(Boolean) as App_Location[]

   const openCreateLocationModal = () =>
      showModal({
         type: 'CREATE_LOCATION',
         modalProps: { onSuccess: refetch },
      })

   return (
      <SC.Container>
         {loading ? (
            <Spin spinning />
         ) : (
            <>
               <SC.AddButton type="primary" upperCase={false} onClick={openCreateLocationModal} minorBtn>
                  <Icon type="plus" />{' '}
                  <Text label="btn.addExtraLocation" size="base" colorType="white" weight="semiBold" mb="none" />
               </SC.AddButton>
               {locations?.map((e, i) => <BookingProfileCard location={e} key={e?.id || i} refetch={refetch} />)}
            </>
         )}
      </SC.Container>
   )
}
