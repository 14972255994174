import * as api from 'api'
import { useEffect, useState } from 'react'

export interface IPaymentType {
   id: string
   paymentType: string
   isActive: boolean
   accountDescription?: string
   isOnboard: boolean
   paymentEnable: boolean
}

export enum PaymentTypeEnum {
   ON_SITE = 'on_site',
   STRIPE_PAYMENT = 'stripe_payment',
}

function PaymentTypesController() {
   const [error, setError] = useState()
   const [loading, setLoading] = useState(false)
   const [refresh, setRefresh] = useState(0)
   const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([])

   useEffect(() => {
      ;(async () => {
         setLoading(true)
         let response = await api.getAllPaymentTypes()
         setLoading(false)

         if (response?.data) {
            setPaymentTypes(response.data)
         }
      })()
      return () => {}
   }, [refresh])

   const getPaymentType = (paymentType: PaymentTypeEnum): IPaymentType => {
      return paymentTypes.filter(item => item.paymentType === paymentType)[0]
   }

   const changeStatus = async (index: number, paymentType: PaymentTypeEnum, status: boolean) => {
      setError(undefined)
      setLoading(true)
      let response = await api.updatePaymentStatus(paymentType, { status })
      setLoading(false)

      if (response?.error) {
         setError(response.error?.message)

         return
      }

      setPaymentTypes(prev => {
         let newValue = [...prev]
         newValue[index].isActive = status

         return newValue
      })
   }

   return {
      error,
      paymentTypes,
      changeStatus,
      loading,
      setPaymentTypes,
      getPaymentType,
      refresh: () => setRefresh(p => p + 1),
   }
}

export default PaymentTypesController
