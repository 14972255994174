import { Button, CustomIcon, Form, getIsMobile, Text, useAppContext, useNavigation, useStripeHook } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { message, Spin } from 'antd'
import { BillingInformationType } from 'pages/plans/components/CheckoutForm'
import React from 'react'

import * as api from '../../../api'
import { PaymentTabHash, RoutePath } from '../../../constants/routes'
import BillingInfoFields from '../../../pages/plans/BillingPage/BillingInfoPage/components/BillingInfoFields'
import { paymentTabs } from '../../../pages/plans/BillingPage/PricingPage/constants'
import HashTabs from '../HashTabs'
import * as SC from './styles'

interface CardBillingInformationProps {
   billingInformation: any
   onPaymentSubmit: any
   total: string
}

const CardBillingInformation = ({ billingInformation, total, onPaymentSubmit }: CardBillingInformationProps) => {
   const [, { refreshAppContext }] = useAppContext()
   const { goBack, location } = useLocation()
   const stripeService = useStripeHook()

   const isMobile = getIsMobile()
   const stripe = useStripe()
   const elements = useElements()
   const { navigate } = useNavigation()

   const hashBillingInfo = location.hash === `#${PaymentTabHash.INFORMATION}`

   const handleSubmit = async (billingInformation: BillingInformationType) => {
      if (!stripe || !elements) {
         return
      }

      const cardNumber = elements.getElement('cardNumber')
      const cardExpiry = elements.getElement('cardExpiry')
      const cardCvc = elements.getElement('cardCvc')

      const onSuccess = (info: string) => {
         navigate(RoutePath.BILLING)
         message.success(<IntlLabel label={info} />)
      }

      const onError = (info?: string) => {
         message.error(<IntlLabel label={info} />)

         cardNumber?.clear()
         cardExpiry?.clear()
         cardCvc?.clear()
      }

      async function handlePaymentResponse(chargeResponse: any, stripe: any, paymentMethod: string) {
         let response = await stripeService.confirmPayment(
            stripe,
            chargeResponse?.status,
            chargeResponse?.clientSecret,
            { payment_method: paymentMethod },
         )

         if (!response.response) {
            onError(response.errorMessage)
         } else {
            // Payment successful without 3D Secure authentication
            onSuccess('upgradePlan.success.message')
            await refreshAppContext()
         }
      }

      const updateBillingInfo = await api.updateBillingInfo(billingInformation)

      if (updateBillingInfo.errors && !hashBillingInfo) {
         navigate(`${location.pathname}#${PaymentTabHash.INFORMATION}`)
         return await api.updateBillingInfo(billingInformation)
      }

      if (cardNumber === null) {
         return
      }

      const isCompleteCardNumber = (cardNumber as any)?._complete
      const isCompleteCardExpiry = (cardExpiry as any)?._complete
      const isCompleteCardCvc = (cardCvc as any)?._complete

      if (isCompleteCardNumber && isCompleteCardExpiry && isCompleteCardCvc) {
         const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumber,
         })

         if (error) {
            // Handle error
            onError(error.message)
         } else if (paymentMethod) {
            const chargeResponse = await onPaymentSubmit(paymentMethod?.id, billingInformation)

            if (chargeResponse.errors) {
               onError(Object.values(chargeResponse.errors)[0] as string)
            } else if (chargeResponse.status === 'retry_action') {
               // Payment method created successfully, proceed with payment
               const { data } = await api.fetchClientSecret()

               // This query doesn't run when I use our clientSecret
               const { error: confirmError } = await stripe.confirmCardSetup(data.clientSecret, {
                  payment_method: paymentMethod.id,
               })

               if (confirmError) {
                  onError('paymentMethod.error.message')
                  return
               }

               const retryCharge = await onPaymentSubmit(paymentMethod?.id, billingInformation)

               await handlePaymentResponse(retryCharge, stripe, paymentMethod?.id)
            } else {
               await handlePaymentResponse(chargeResponse, stripe, paymentMethod?.id)
            }
         }
      }

      return
   }

   return (
      <Form
         onSubmit={({ billingInformation }: { billingInformation: BillingInformationType }) =>
            handleSubmit(billingInformation)
         }
         initialValues={{ billingInformation }}
         excludeList={['billingInformation.onlineCustomerId']}
         successMessage={hashBillingInfo && 'notification.success.update'}>
         {({ submitting, values }: any) => (
            <Spin spinning={submitting}>
               <SC.ContainerTabs>
                  <HashTabs tabs={paymentTabs} defaultTab={PaymentTabHash.DETAILS}>
                     <SC.CardDetails>
                        <Text
                           label="payment.card.details"
                           weight="semiBold"
                           size={isMobile ? 'llarge' : 'mlarge'}
                           mb="medium"
                        />

                        <SC.PayWithCardBlock>
                           <div className="align-center">
                              <CustomIcon type="card" />
                              <Text
                                 mb="none"
                                 label="payment.payWithCard.title"
                                 size="base"
                                 ml={isMobile ? 'xsmall' : 'base'}
                              />
                           </div>
                           <CustomIcon type={isMobile ? 'mobileCards' : 'cards'} />
                        </SC.PayWithCardBlock>

                        <SC.InputsCardBlock>
                           <Text mb="xsmall" size="small" label="payment.cardNumber.title" />
                           <div id="card-number-element" />

                           <SC.CardElementsWrapper>
                              <div>
                                 <Text mb="xsmall" size="small" label="payment.expirationDate.title" />
                                 <div id="card-expiry-element" />
                              </div>

                              <div>
                                 <div className="d-flex">
                                    <Text mb="xsmall" size="small" label="CVC" />
                                    <Text
                                       mb="xsmall"
                                       size="small"
                                       label="payment.digits.title"
                                       ml="xxsmall"
                                       color="#b3bcd0"
                                    />
                                 </div>
                                 <div id="card-cvc-element" />
                              </div>
                           </SC.CardElementsWrapper>
                        </SC.InputsCardBlock>
                     </SC.CardDetails>

                     <SC.BillingInformation>
                        <Text
                           colorType="base"
                           label="billing.tab.billing"
                           weight="semiBold"
                           mb="xmedium"
                           className="billing-information"
                        />
                        <BillingInfoFields hasPrefix values={values} />
                     </SC.BillingInformation>
                  </HashTabs>

                  <SC.ContainerPaymentAmount>
                     <Text label="payment.amount" size="medium" colorType="base" lh="mlarge" mb="none" />
                     <Text size="llarge" weight="semiBold" ml="small" colorType="base" lh="xxxlarge" mb="none">
                        {total}
                     </Text>
                  </SC.ContainerPaymentAmount>

                  <SC.BottomBtnContainer>
                     <Button label="btn.cancel" whiteBtn bold upperCase={false} onClick={goBack} />
                     <Button
                        id="test"
                        label={hashBillingInfo ? 'btn.save' : 'payment.btn.pay'}
                        bold
                        upperCase={false}
                        minorBtn
                        htmlType="submit"
                        loading={hashBillingInfo && submitting}
                        disabled={!stripe}
                     />
                  </SC.BottomBtnContainer>
               </SC.ContainerTabs>
            </Spin>
         )}
      </Form>
   )
}

export default CardBillingInformation
