import { useModal } from '@plandok/core'
import { PermissionType } from 'constants/auth'
import useGetHasPermission from 'hooks/permission/useGetHasPermission'
import { extractBlockTimeValues } from 'pages/dashboard/CalendarPage/helpers'
import calendarState from 'pages/dashboard/CalendarPage/store/calendar.state'
import React, { memo } from 'react'

import { getElementSizePositionsByViewType } from '../../support/helpers/card'
import TimeCard from './TimeCard'

export default memo(function TimeCardList(props: any) {
   const cardsPositions = getElementSizePositionsByViewType(
      props.bookings,
      props.columnWidth,
      props.hourHeight,
      props.customViewType,
      props.customViewTime.startTime,
      props.customViewTime.endTime,
      props.workingHoursStartTime,
   )

   const getHasPermission = useGetHasPermission()
   const canEdit = getHasPermission(PermissionType.CAN_BOOK_APPOINTMENTS)
   const [, { showModal }] = useModal()

   const onCardClickMobile = (booking: any) =>
      booking.isBlocked
         ? showModal({
              type: 'CREATE_BLOCK_TIME',
              modalProps: {
                 id: booking.id,
                 onSuccess: calendarState.fetchData,
                 initialValues: extractBlockTimeValues(booking),
              },
           })
         : showModal({
              type: 'CALENDAR_TOOLTIP_MODAL',
              modalProps: {
                 id: `tooltip-modal-${booking.appointmentId}`,
                 booking: booking,
                 timeFormat: props.timeFormat,
              },
           })

   return (
      <>
         {props.bookings?.map((card: any, i: number) => (
            <TimeCard
               style={cardsPositions[i]}
               getContainerRef={props.getContainerRef}
               columnIndex={props.columnIndex}
               columnsCount={props.columnsCount}
               columnWidth={props.columnWidth}
               hourHeight={props.hourHeight}
               onCardClick={props.onCardClick}
               onCardClickMobile={onCardClickMobile}
               onMove={props.onMove}
               onResize={props.onResize}
               parentFrameId={props.parentFrameId}
               tooltipComponent={props.tooltipComponent}
               timeCardComponent={props.timeCardComponent}
               timeCard={card}
               displayOnly={props.isSelectSlotMode}
               key={card?.id}
               canEdit={canEdit}
               timeFormat={props.timeFormat}
            />
         ))}
      </>
   )
})
