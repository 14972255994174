import * as SC from './styles'
import React from 'react'

const CardImage = ({ imgUrl, ...props }: any) => {
   return <SC.CardImage imgUrl={imgUrl} {...props} />
}

function Card(props: {
   children: React.ReactNode
   noPadding?: boolean
   padding?: string
   className?: string
   minHeight?: string
   withShadow?: boolean
   borderRadius?: string
}) {
   return (
      <SC.Container
         noPadding={props.noPadding}
         padding={props.padding}
         className={props.className}
         withShadow={props.withShadow}
         borderRadius={props.borderRadius}
      >
         {props.children}
      </SC.Container>
   )
}

Card.Title = SC.Title as any
Card.Image = CardImage

export default Card
