import { CustomIcon, getIsMobile } from '@plandok/core'
import { Layout } from 'antd'
import cn from 'classnames'
import { sidebarConfig } from 'config/sidebar-config'
import useElementSize from 'hooks/use-element-size'
import React, { useRef } from 'react'
import { useStore } from 'store/store'

import * as SC from '../../styles'
import MobileSidebar from '../MobileSidebar'
import SidebarItem from './components/SidebarItem'
import SupportButton from './components/SupportButton'
import UpdateButton from './components/UpdateButton'

type SidebarProps = {
   disableShade?: boolean
}

export default function Sidebar({ disableShade }: SidebarProps) {
   const isMobile = getIsMobile()
   const menuRef: any = useRef(null)
   const { height } = useElementSize(menuRef)
   const state = useStore()

   return (
      <>
         {!isMobile && (
            <Layout.Sider
               theme="dark"
               collapsed={true}
               className={cn('app-sidebar', { 'app-sidebar--no-shadow': disableShade })}>
               <div ref={menuRef}>
                  <div className="app-sidebar-trigger">
                     <CustomIcon type="logo" />
                  </div>
                  <SC.StyledMenu mode="inline" theme="dark">
                     {sidebarConfig.map(sidebarItem => (
                        <SidebarItem item={sidebarItem} key={sidebarItem.title} siderCollapsed={true} />
                     ))}
                     {state.systems.displayUpdateButton && <UpdateButton isFixed={height > 734} collapsed={true} />}
                     <SupportButton isFixed={height > 734} collapsed={true} />
                  </SC.StyledMenu>
               </div>
            </Layout.Sider>
         )}
         <MobileSidebar />
      </>
   )
}
