import { mediaMdUp, mediaMobileDown, mediaXlUp } from '@plandok/core'
import styled, { css } from 'styled-components'

export const Footer = styled.div`
   display: flex;
   flex-direction: column;
   text-align: center;
   margin-top: 50px;
   margin-bottom: 20px;

   [data-modify-footer-info] {
      font-size: 25px;
      color: #adb6cc;
      padding-bottom: 5px;
      border-bottom: 1px solid #adb6cc;
      margin-bottom: 15px;
   }

   [data-modify-footer-error] {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
   }

   [data-modify-footer-btns] {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 600px;
      text-align: center;
      margin: 0 auto;
      button {
         ${mediaMdUp(css`
            width: initial;
            max-width: 100%;
            &:not([data-footer-minor-btn]) {
               min-width: 200px;
               width: inherit;
            }
         `)}
         ${mediaXlUp(css`
            &:nth-of-type(n + 2) {
               margin-left: 20px;
            }
         `)}
      &[data-footer-minor-btn] {
            width: 100%;
            max-width: 140px;
         }
      }
   }

   ${mediaMobileDown(css`
      background: white;
      position: fixed;
      box-shadow: 0 3px 25px rgba(19, 49, 109, 0.16);
      bottom: -25px;
      left: 0;
      right: 0;
      padding: 10px 15px 15px 15px;
      margin-top: 0;
      z-index: 2;

      [data-modify-footer-info] {
         font-size: 18px;
         color: #adb6cc;
         margin-bottom: 0;
         border-bottom: 0;
      }
   `)}
`
