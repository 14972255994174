import { CloseCircleOutlined } from '@ant-design/icons'
import { useLocation } from '@plandok/core'
import { IntlLabel, LabelKey } from '@plandok/i18n'
import { useHotkeys } from 'hooks/hotkey'
import React from 'react'
import { ReactNode } from 'react'
import { useStore } from 'store/store'

import ModifyFooter from './components/ModifyFooter'
import * as SC from './styles'

function ModifyLayout(props: {
   children: ReactNode
   title?: LabelKey
   className?: string
   mdWhite?: boolean
   style?: any
   onClose?: any
}) {
   const setRescheduleItemDate = useStore(state => state.setRescheduleItemDate)
   const setRescheduleItemTime = useStore(state => state.setRescheduleItemTime)
   const setRescheduleItemStaff = useStore(state => state.setRescheduleItemStaff)

   const { goBack } = useLocation()

   const helperOnClose = () => {
      goBack()
      setRescheduleItemDate(undefined)
      setRescheduleItemTime(undefined)
      setRescheduleItemStaff('')
   }

   const close = () => (props.onClose ? props.onClose() : helperOnClose())
   useHotkeys('esc', close)

   return (
      <SC.Container className={props.className} mdWhite={props.mdWhite} style={props.style}>
         <SC.CloseIcon onClick={close}>
            <CloseCircleOutlined />
         </SC.CloseIcon>
         <SC.Content>
            {props.title && (
               <SC.Title>
                  <IntlLabel label={props.title} />
               </SC.Title>
            )}
            {props.children}
         </SC.Content>
      </SC.Container>
   )
}

ModifyLayout.Footer = ModifyFooter

export default ModifyLayout
