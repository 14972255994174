import { Button, CustomIcon, Form, getIsMobile, Text, useStripeHook } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { message } from 'antd'
import * as api from 'api'
import React, { useEffect } from 'react'

import { style } from '../../PricingPage/constants'
import * as SC from '../styles'

type PaymentCardFormProps = {
   fetchData: () => void
   setIsOpenAddMethod: (isOpenAddMethod: boolean) => void
}

export default function PaymentCardForm({ fetchData, setIsOpenAddMethod }: PaymentCardFormProps) {
   const isMobile = getIsMobile()
   const elements = useElements()
   const stripe = useStripe()
   const stripeHook = useStripeHook()

   useEffect(() => {
      if (elements) {
         stripeHook.loadComponent(elements, style)
      }
   }, [elements, stripeHook])

   const handleSubmit = async () => {
      if (!stripe || !elements) {
         return
      }

      const card = elements.getElement('cardNumber')

      if (card === null) {
         return
      }

      const onError = (errMessage?: string) => {
         message.error(<IntlLabel label={errMessage} />)
         setIsOpenAddMethod(false)
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
         type: 'card',
         card,
      })

      if (error) {
         onError(error.message)
      } else if (paymentMethod) {
         const { data } = await api.fetchClientSecret()

         if (data.clientSecret) {
            const { error, setupIntent } = await stripe.confirmCardSetup(data.clientSecret, {
               payment_method: {
                  card,
               },
            })

            if (error) {
               onError(error.message)
            } else if (setupIntent) {
               const { errors } = await api.assignPaymentMethods({
                  paymentMethod: paymentMethod.id,
               })

               if (errors) {
                  onError(errors['FINAL_FORM/form-error'])
               } else {
                  message.success(<IntlLabel label="paymentMethod.cardHasBeenAdded.message" />)
                  setIsOpenAddMethod(false)
                  return fetchData()
               }
            }
         }

         return
      }
   }

   return (
      <Form onSubmit={handleSubmit}>
         {({ submitting, submitErrors }: any) => (
            <>
               <SC.InputsCardBlock>
                  <SC.CardNumberTitle>
                     <Text mb="none" size="small" label="payment.cardNumber.title" />
                     <CustomIcon type={isMobile ? 'paymentMobileCards' : 'paymentCards'} />
                  </SC.CardNumberTitle>
                  <div id="card-number-element" />

                  <SC.CardElementsWrapper>
                     <div>
                        <Text mb="xsmall" size="small" label="payment.expirationDate.title" />
                        <div id="card-expiry-element" />
                     </div>

                     <div>
                        <div className="d-flex">
                           <Text mb="xsmall" size="small" label="input.cvv.label" />
                           <Text mb="xsmall" size="small" label="payment.digits.title" ml="xxsmall" color="#b3bcd0" />
                        </div>
                        <div id="card-cvc-element" />
                     </div>
                  </SC.CardElementsWrapper>
               </SC.InputsCardBlock>

               {submitErrors && <Form.Alert text={Object.values(submitErrors)} />}

               <Button
                  label="paymentMethod.add.btn"
                  bold
                  upperCase={false}
                  minorBtn
                  htmlType="submit"
                  loading={submitting}
                  disabled={!stripe}
               />
            </>
         )}
      </Form>
   )
}
