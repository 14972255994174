// JSON translation labels, downloaded from google table
import Labels from './labels/labels.en'
import LabelsLat from './labels/labels.lv'
import LabelsLit from './labels/labels.lt'
import LabelsRus from './labels/labels.ru'
import LabelsFra from './labels/labels.fr'
import LabelsDeu from './labels/labels.de'
import LabelsCze from './labels/labels.cz'
import LabelsIta from './labels/labels.it'
import LabelsPrt from './labels/labels.pt'
import LabelsEst from './labels/labels.ee'
import LabelsEsp from './labels/labels.es'
import LabelsTur from './labels/labels.tr'
import LabelsUkr from './labels/labels.ua'
import LabelsPol from './labels/labels.pl'

import { enUS } from 'date-fns/locale/en-US'
import { lt } from 'date-fns/locale/lt'
import { lv } from 'date-fns/locale/lv'
import { ru } from 'date-fns/locale/ru'
import { fr } from 'date-fns/locale/fr'
import { de } from 'date-fns/locale/de'
import { it } from 'date-fns/locale/it'
import { pt } from 'date-fns/locale/pt'
import { tr } from 'date-fns/locale/tr'
import { uk } from 'date-fns/locale/uk'
import { es } from 'date-fns/locale/es'
import { pl } from 'date-fns/locale/pl'
import { et } from 'date-fns/locale/et'
import { cs } from 'date-fns/locale/cs'

export const Language = {
   CZE: 'cs',
   ENG: 'en',
   EST: 'et',
   FRA: 'fr',
   DEU: 'de',
   ITA: 'it',
   LAT: 'lv',
   LIT: 'lt',
   POL: 'pl',
   PRT: 'pt',
   RUS: 'ru',
   ESP: 'es',
   TUR: 'tr',
   UKR: 'ua',
}

export const LanguageFnsConfig = {
   [Language.ENG]: enUS,
   [Language.RUS]: ru,
   [Language.LAT]: lv,
   [Language.LIT]: lt,
   [Language.FRA]: fr,
   [Language.DEU]: de,
   [Language.CZE]: cs,
   [Language.ITA]: it,
   [Language.PRT]: pt,
   [Language.ESP]: es,
   [Language.EST]: et,
   [Language.TUR]: tr,
   [Language.UKR]: uk,
   [Language.POL]: pl,
}

export const LanguageNameMapping = {
   [Language.ENG]: 'English',
   [Language.RUS]: 'Русский',
   [Language.LAT]: 'Latviešu',
   [Language.LIT]: 'Lietuvių',
   [Language.FRA]: 'Français',
   [Language.DEU]: 'Deutsch',
   [Language.CZE]: 'Čeština',
   [Language.ITA]: 'Italiano',
   [Language.PRT]: 'Português',
   [Language.EST]: 'Eesti',
   [Language.ESP]: 'Española',
   [Language.TUR]: 'Türkçe',
   [Language.UKR]: 'Український',
   [Language.POL]: 'Polskie',
}

export const LanguageNameEnglishMapping = {
   [Language.ENG]: 'English',
   [Language.RUS]: 'Russian',
   [Language.LAT]: 'Latvian',
   [Language.LIT]: 'Lithuanian',
   [Language.FRA]: 'French',
   [Language.DEU]: 'German',
   [Language.CZE]: 'Czech',
   [Language.ITA]: 'Italian',
   [Language.PRT]: 'Portuguese',
   [Language.EST]: 'Estonian',
   [Language.ESP]: 'Spanish',
   [Language.TUR]: 'Turkish',
   [Language.UKR]: 'Ukrainian',
   [Language.POL]: 'Polish',
}

export const LanguagesBundle = {
   [Language.LAT]: LabelsLat,
   [Language.LIT]: LabelsLit,
   [Language.ENG]: Labels,
   [Language.RUS]: LabelsRus,
   [Language.FRA]: LabelsFra,
   [Language.DEU]: LabelsDeu,
   [Language.CZE]: LabelsCze,
   [Language.ITA]: LabelsIta,
   [Language.PRT]: LabelsPrt,
   [Language.EST]: LabelsEst,
   [Language.ESP]: LabelsEsp,
   [Language.UKR]: LabelsUkr,
   [Language.TUR]: LabelsTur,
   [Language.POL]: LabelsPol,
}
