import React from 'react'

// import * as RSH from 'react-sortable-hoc';
import * as SC from '../style'
import DragIcon from './DragIcon'

function DragHandle() {
   return (
      <SC.Drag>
         <DragIcon />
      </SC.Drag>
   )
}

export default DragHandle
