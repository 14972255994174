import { ModifyMassLayout, NotReleased } from 'components'
import { RoutePath } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import CampaignSetupForm from './components/CampaignSetupForm'

export const CampaignSetFormWithApi = withFormApi({
   backPath: RoutePath.MARKETING,
   successMessage: 'Message',
})(CampaignSetupForm)

export default function CampaignSetup() {
   return (
      <NotReleased>
         <ModifyMassLayout title="Edit Message" step="Campaign setup. Step 2 of 3">
            <CampaignSetFormWithApi />
         </ModifyMassLayout>
      </NotReleased>
   )
}
