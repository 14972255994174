import { getIsMobile } from '@plandok/core'
import React from 'react'

import { StateCalendar } from '../../constants'
import * as SC from './styles'

type BtnShowAllTimesProps = {
   setCustomViewType: (viewType: string) => void
   setIsDisplayBtnShowAllTimes: (value: boolean) => void
}

export default function BtnShowAllTimes({ setCustomViewType, setIsDisplayBtnShowAllTimes }: BtnShowAllTimesProps) {
   const isMobile = getIsMobile()

   const onClick = () => {
      setCustomViewType(StateCalendar.FULL_CALENDAR)
      setIsDisplayBtnShowAllTimes(false)
   }

   return (
      <SC.BtnShowAllTimes
         onClick={onClick}
         upperCase={false}
         label="app.showAllTimes.button.title"
         isMobile={isMobile}
         whiteBtn
         bold
      />
   )
}
