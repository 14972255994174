import { ButtonProps as AntBtnProps } from 'antd/lib/button'
import { Button as AntBtn } from 'antd'
import cn from 'classnames'
import React from 'react'
import { IntlLabel, LabelKey } from '@plandok/i18n'

interface ButtonProps extends AntBtnProps {
   label?: LabelKey
   bold?: boolean
   semiBold?: boolean
   children?: React.ReactNode
   minorBtn?: boolean
   whiteBtn?: boolean
   outlineBtn?: boolean
   lightBtn?: boolean
   dangerTextBtn?: boolean
   upperCase?: boolean
   supportBtn?: boolean
   smallRadius?: boolean
}

function Button({
   label,
   bold = false,
   semiBold = false,
   upperCase = true,
   children,
   minorBtn,
   whiteBtn,
   lightBtn,
   outlineBtn,
   dangerTextBtn,
   className,
   supportBtn,
   smallRadius,
   ...props
}: ButtonProps) {
   const isBase = !props.type || props.type === 'default'
   const resultClassName = cn(
      {
         'ant-btn-base': isBase,
         'ant-btn-minor': minorBtn,
         'ant-btn-white': whiteBtn,
         'ant-btn-outline': outlineBtn,
         'ant-btn-light': lightBtn,
         'ant-btn-danger-text': dangerTextBtn,
         bold,
         'semi-bold': semiBold,
         'upper-case': upperCase,
         'ant-btn-support': supportBtn,
         'ant-btn-radius': smallRadius,
      },
      'br-small',
      className,
   )
   return (
      <AntBtn {...props} className={resultClassName}>
         <IntlLabel label={label} />
         {children}
      </AntBtn>
   )
}

export default Button
