import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Avatar } from 'antd'
import { RoutePath } from 'constants/routes'
import { getAvatarSign } from 'helpers/common'
import React, { useCallback } from 'react'

import * as SC from './styles'

export default function ClientItem({ client, redirectOnClick }: any) {
   const { navigate } = useLocation()

   const onClick = useCallback(() => {
      if (redirectOnClick && client?.id) {
         navigate(RoutePath.CLIENT_DETAILS, client?.id)
      }
   }, [navigate, client, redirectOnClick])

   return (
      <SC.ClientItem onClick={onClick}>
         <div data-client-item-avatar>
            <Avatar size="large">{getAvatarSign(client?.firstName || 'Walk in')}</Avatar>
         </div>
         <div data-client-item-info>
            <div>
               {client?.firstName} {client?.lastName}
               {!client && <IntlLabel label="calendar.tooltip.emptyCustomer" />}
            </div>
            <div>{client?.email}</div>
            <div>{client?.phoneNumber ?? client?.phone}</div>
         </div>
      </SC.ClientItem>
   )
}
