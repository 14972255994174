import styled from 'styled-components'

export const Container = styled.div`
   color: #61749d;

   h1 {
      font-size: 40px;
      line-height: 47px;
      margin-bottom: 26px;
      color: inherit;
   }

   div {
      font-size: 18px;
   }
`

export const ButtonBack = styled.a`
   margin-bottom: 20px;
   background: transparent !important;
   height: 19px;
   line-height: 19px;
   display: flex;
   align-items: center;

   span {
      font-size: 17px;
      font-weight: 500;
      color: #136ef6 !important;
   }

   svg {
      margin-right: 6px;
      color: #136ef6 !important;
   }
`
