import { IntlLabel } from '@plandok/i18n'
import { MobileBackButton } from 'components'
import React from 'react'

export default function PersonalSettingsMobileHeader() {
   return (
      <>
         <MobileBackButton />
         <div>
            <IntlLabel label="title.mySettings" />
         </div>
         <div />
      </>
   )
}
