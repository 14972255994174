import { Button } from '@plandok/core'
import React from 'react'

type CancelBtnProps = {
   openAppointmentCancel: () => void
}

export default function CancelBtn({ openAppointmentCancel }: CancelBtnProps) {
   return (
      <Button
         type="primary"
         label="btn.cancelAppointment"
         whiteBtn
         smallRadius
         bold
         style={{ color: '#FF5E5E', border: '1px solid #CED3E0', width: '212px' }}
         onClick={openAppointmentCancel}
      />
   )
}
