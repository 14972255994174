export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
   ID: string
   String: string
   Boolean: boolean
   Int: number
   Float: number
   Date: any
   DateTime: any
   Json: any
   Uuid: any
}

export type AppMutation = {
   __typename?: 'AppMutation'
   cancelRecurringAppointment?: Maybe<App_CancelRecurringAppointmentResponse>
   createAutoSendingMarketingCampaign?: Maybe<App_AutoSendingMarketingCampaignResponse>
   createClosedDate?: Maybe<App_CreateClosedDateResponse>
   createEmailMassMarketingCampaign?: Maybe<App_CreateMassMarketingCampaignResponse>
   createRecurringAppointment?: Maybe<App_CreateRecurringAppointmentResponse>
   createSmsMassMarketingCampaign?: Maybe<App_CreateMassMarketingCampaignResponse>
   deleteClosedDate?: Maybe<App_DeleteClosedDateResponse>
   editRecurringAppointment?: Maybe<App_EditRecurringAppointmentResponse>
   generateLocationUploads: App_GenerateLocationUploadsResponse
   generatePhotoUploads: App_GeneratePhotoUploadsResponse
   publishLocation?: Maybe<App_PublishLocationResponse>
   requestPasswordResend?: Maybe<App_RequestPasswordResendResponse>
   resetAutoSendingMarketingCampaign?: Maybe<App_AutoSendingMarketingCampaignResponse>
   updateAutoSendingMarketingCampaign?: Maybe<App_AutoSendingMarketingCampaignResponse>
   updateClosedDate?: Maybe<App_UpdateClosedDateResponse>
   updateEmployeeDetails?: Maybe<App_UpdateEmployeeDetailsResponse>
   updateLocationAddress?: Maybe<App_UpdateLocationAddressResponse>
   updateLocationBusinessDetails?: Maybe<App_UpdateLocationBusinessDetailsResponse>
   updateLocationPhotos: App_UpdateLocationPhotosResponse
   updateLocationWorkingHours?: Maybe<App_UpdateLocationWorkingHoursResponse>
   updateOnlineBookingSettings?: Maybe<App_UpdateOnlineBookingSettingsResponse>
}

export type AppMutationCancelRecurringAppointmentArgs = {
   input: App_CancelRecurringAppointmentInput
}

export type AppMutationCreateAutoSendingMarketingCampaignArgs = {
   input: App_CreateAutoSendingMarketingCampaignInput
}

export type AppMutationCreateClosedDateArgs = {
   input: App_CreateClosedDateInput
}

export type AppMutationCreateEmailMassMarketingCampaignArgs = {
   input: App_CreateEmailMassMarketingCampaignInput
}

export type AppMutationCreateRecurringAppointmentArgs = {
   input: App_CreateRecurringAppointmentInput
}

export type AppMutationCreateSmsMassMarketingCampaignArgs = {
   input: App_CreateSmsMassMarketingCampaignInput
}

export type AppMutationDeleteClosedDateArgs = {
   input: App_DeleteClosedDateInput
}

export type AppMutationEditRecurringAppointmentArgs = {
   input: App_EditRecurringAppointmentInput
}

export type AppMutationGenerateLocationUploadsArgs = {
   input: App_GenerateLocationUploadsInput
}

export type AppMutationGeneratePhotoUploadsArgs = {
   input: App_GeneratePhotoUploadsInput
}

export type AppMutationPublishLocationArgs = {
   input: App_PublishLocationInput
}

export type AppMutationRequestPasswordResendArgs = {
   input: App_RequestPasswordResendInput
}

export type AppMutationResetAutoSendingMarketingCampaignArgs = {
   input: App_ResetAutoSendingMarketingCampaignInput
}

export type AppMutationUpdateAutoSendingMarketingCampaignArgs = {
   input: App_UpdateAutoSendingMarketingCampaignInput
}

export type AppMutationUpdateClosedDateArgs = {
   input: App_UpdateClosedDateInput
}

export type AppMutationUpdateEmployeeDetailsArgs = {
   input: App_UpdateEmployeeDetailsInput
}

export type AppMutationUpdateLocationAddressArgs = {
   input: App_UpdateLocationAddressInput
}

export type AppMutationUpdateLocationBusinessDetailsArgs = {
   input: App_UpdateLocationBusinessDetailsInput
}

export type AppMutationUpdateLocationPhotosArgs = {
   input: App_UpdateLocationPhotosInput
}

export type AppMutationUpdateLocationWorkingHoursArgs = {
   input: App_UpdateLocationWorkingHoursInput
}

export type AppMutationUpdateOnlineBookingSettingsArgs = {
   input: App_UpdateOnlineBookingSettingsInput
}

export type AppQuery = {
   __typename?: 'AppQuery'
   analytics?: Maybe<App_Analytics>
   autoSendingCampaign?: Maybe<App_AutoSendingCampaign>
   autoSendingCampaigns?: Maybe<App_AutoSendingCampaignConnection>
   client?: Maybe<App_Client>
   clients?: Maybe<App_ClientConnection>
   closedDate?: Maybe<App_ClosedDate>
   closedDates?: Maybe<App_ClosedDateConnection>
   employee?: Maybe<App_Employee>
   employeeDetails?: Maybe<App_EmployeeDetails>
   employees?: Maybe<App_EmployeeConnection>
   location?: Maybe<App_Location>
   locations?: Maybe<App_LocationConnection>
   massMarketingCampaign?: Maybe<App_MassMarketingCampaign>
   massMarketingCampaigns?: Maybe<App_MassMarketingCampaignConnection>
   onlineBookingLink?: Maybe<Scalars['String']>
   onlineBookingSettings?: Maybe<App_OnlineBookingSettings>
   pricing?: Maybe<App_PricingConnection>
   reports?: Maybe<App_Reports>
   service?: Maybe<App_Service>
   serviceGroup?: Maybe<App_ServiceGroup>
   serviceGroups?: Maybe<App_ServiceGroupConnection>
   services?: Maybe<App_ServiceConnection>
   user?: Maybe<App_User>
}

export type AppQueryAnalyticsArgs = {
   fromDate: Scalars['Date']
   toDate: Scalars['Date']
}

export type AppQueryAutoSendingCampaignArgs = {
   id: Scalars['Uuid']
}

export type AppQueryAutoSendingCampaignsArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
}

export type AppQueryClientArgs = {
   id: Scalars['Uuid']
}

export type AppQueryClientsArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
   search?: InputMaybe<Scalars['String']>
}

export type AppQueryClosedDateArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   id: Scalars['Uuid']
   last?: InputMaybe<Scalars['Int']>
}

export type AppQueryClosedDatesArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
}

export type AppQueryEmployeeArgs = {
   id: Scalars['Uuid']
}

export type AppQueryEmployeeDetailsArgs = {
   id: Scalars['Uuid']
}

export type AppQueryEmployeesArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
}

export type AppQueryLocationArgs = {
   id: Scalars['Uuid']
}

export type AppQueryLocationsArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
}

export type AppQueryMassMarketingCampaignArgs = {
   id: Scalars['Uuid']
}

export type AppQueryMassMarketingCampaignsArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
}

export type AppQueryPricingArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   countryIsoCode?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
   notificationChannel?: InputMaybe<NotificationChannel>
}

export type AppQueryReportsArgs = {
   from: Scalars['Date']
   limit?: InputMaybe<Scalars['Int']>
   offset?: InputMaybe<Scalars['Int']>
   to: Scalars['Date']
}

export type AppQueryServiceArgs = {
   id: Scalars['Uuid']
}

export type AppQueryServiceGroupArgs = {
   id: Scalars['Uuid']
}

export type AppQueryServiceGroupsArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
}

export type AppQueryServicesArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
}

export type App_Access = {
   __typename?: 'App_Access'
   permissionLevel?: Maybe<App_PermissionLevel>
   permissions?: Maybe<Array<App_Permission>>
   roles?: Maybe<Array<Scalars['String']>>
}

export type App_Analytics = {
   __typename?: 'App_Analytics'
   appointments?: Maybe<App_AppointmentsAnalytics>
   clients?: Maybe<App_ClientsAnalytics>
   graph?: Maybe<App_AppointmentsAnalyticsGraph>
   occupancy?: Maybe<App_OccupancyAnalytics>
   topCancellationReasons?: Maybe<Array<App_TopCancellationReason>>
   topServices?: Maybe<Array<App_TopService>>
}

export type App_AnalyticsAppointmentsArgs = {
   range?: InputMaybe<App_AnalyticsDateInterval>
}

export type App_AnalyticsGraphArgs = {
   step: App_AppointmentsGraphStep
}

export enum App_AnalyticsDateInterval {
   Day = 'DAY',
   Month = 'MONTH',
   Year = 'YEAR',
}

export type App_AppointmentBookingsInput = {
   duration?: InputMaybe<Scalars['Int']>
   employeeId?: InputMaybe<Scalars['Uuid']>
   resourceId?: InputMaybe<Scalars['Uuid']>
   serviceId?: InputMaybe<Scalars['Uuid']>
   startTime?: InputMaybe<Scalars['Int']>
}

export enum App_AppointmentChangeTimeSetting {
   AnyTime = 'ANY_TIME',
   Disabled = 'DISABLED',
   UpTo_1Hour = 'UP_TO_1_HOUR',
   UpTo_2Hours = 'UP_TO_2_HOURS',
   UpTo_3Hours = 'UP_TO_3_HOURS',
   UpTo_4Hours = 'UP_TO_4_HOURS',
   UpTo_5Hours = 'UP_TO_5_HOURS',
   UpTo_6Hours = 'UP_TO_6_HOURS',
   UpTo_12Hours = 'UP_TO_12_HOURS',
   UpTo_24Hours = 'UP_TO_24_HOURS',
   UpTo_30Minutes = 'UP_TO_30_MINUTES',
   UpTo_48Hours = 'UP_TO_48_HOURS',
}

export type App_AppointmentPoint = {
   __typename?: 'App_AppointmentPoint'
   point?: Maybe<Scalars['String']>
   totalCount?: Maybe<Scalars['Int']>
   totalOnlineCount?: Maybe<Scalars['Int']>
}

export type App_AppointmentsAnalytics = {
   __typename?: 'App_AppointmentsAnalytics'
   cancelledCount?: Maybe<Scalars['Int']>
   cancelledPercent?: Maybe<Scalars['Int']>
   completedCount?: Maybe<Scalars['Int']>
   completedPercent?: Maybe<Scalars['Int']>
   noShowCount?: Maybe<Scalars['Int']>
   noShowPercent?: Maybe<Scalars['Int']>
   notCompletedCount?: Maybe<Scalars['Int']>
   notCompletedPercent?: Maybe<Scalars['Int']>
   points?: Maybe<Array<App_AppointmentPoint>>
   totalAppointments?: Maybe<Scalars['Int']>
}

export type App_AppointmentsAnalyticsGraph = {
   __typename?: 'App_AppointmentsAnalyticsGraph'
   points?: Maybe<Array<App_AppointmentsAnalyticsGraphPoint>>
}

export type App_AppointmentsAnalyticsGraphPoint = {
   __typename?: 'App_AppointmentsAnalyticsGraphPoint'
   xValue: Scalars['String']
   yAppointmentsCount: Scalars['Int']
   yOnlineBookingsCount?: Maybe<Scalars['Int']>
}

export enum App_AppointmentsGraphStep {
   Day1 = 'DAY1',
   Month1 = 'MONTH1',
}

/** A connection to a list of items. */
export type App_AppointmentsReportConnection = {
   __typename?: 'App_AppointmentsReportConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_AppointmentsReportEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_AppointmentsReportEdge = {
   __typename?: 'App_AppointmentsReportEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_ReportAppointment>
}

export type App_AutoSendingCampaign = {
   __typename?: 'App_AutoSendingCampaign'
   active: Scalars['Boolean']
   channels?: Maybe<Array<NotificationChannel>>
   emailMessage?: Maybe<App_AutoSendingCampaignEmail>
   id?: Maybe<Scalars['Uuid']>
   options?: Maybe<Scalars['Json']>
   smsMessage?: Maybe<App_AutoSendingCampaignSms>
   type: Scalars['String']
}

/** A connection to a list of items. */
export type App_AutoSendingCampaignConnection = {
   __typename?: 'App_AutoSendingCampaignConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_AutoSendingCampaignEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_AutoSendingCampaignEdge = {
   __typename?: 'App_AutoSendingCampaignEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_AutoSendingCampaign>
}

export type App_AutoSendingCampaignEmail = {
   __typename?: 'App_AutoSendingCampaignEmail'
   ctaName?: Maybe<Scalars['String']>
   ctaUrl?: Maybe<Scalars['String']>
   customUrl?: Maybe<Scalars['String']>
   facebookUrl?: Maybe<Scalars['String']>
   id?: Maybe<Scalars['Uuid']>
   instagramUrl?: Maybe<Scalars['String']>
   message?: Maybe<Scalars['String']>
   photo?: Maybe<Scalars['String']>
   replyTo?: Maybe<Scalars['String']>
   subject?: Maybe<Scalars['String']>
   title?: Maybe<Scalars['String']>
}

export type App_AutoSendingCampaignSms = {
   __typename?: 'App_AutoSendingCampaignSms'
   id?: Maybe<Scalars['Uuid']>
   sender?: Maybe<Scalars['String']>
   text?: Maybe<Scalars['String']>
}

export type App_AutoSendingMarketingCampaignEmailInput = {
   ctaName?: InputMaybe<Scalars['String']>
   ctaUrl?: InputMaybe<Scalars['String']>
   customUrl?: InputMaybe<Scalars['String']>
   facebookUrl?: InputMaybe<Scalars['String']>
   instagramUrl?: InputMaybe<Scalars['String']>
   message: Scalars['String']
   photo?: InputMaybe<Scalars['String']>
   replyTo: Scalars['String']
   subject: Scalars['String']
   title: Scalars['String']
}

export type App_AutoSendingMarketingCampaignResponse = {
   __typename?: 'App_AutoSendingMarketingCampaignResponse'
   success: Scalars['Boolean']
}

export type App_AutoSendingMarketingCampaignSmsInput = {
   sender: Scalars['String']
   text: Scalars['String']
}

export enum App_AutoSendingMarketingType {
   RewardRegular = 'REWARD_REGULAR',
   SpecialBirthday = 'SPECIAL_BIRTHDAY',
   WelcomeNewClient = 'WELCOME_NEW_CLIENT',
   WinBackClients = 'WIN_BACK_CLIENTS',
}

export type App_BookingsByEmployeeReport = {
   __typename?: 'App_BookingsByEmployeeReport'
   firstName: Scalars['String']
   lastName?: Maybe<Scalars['String']>
   totalCount: Scalars['Int']
   totalPrice: Money
}

/** A connection to a list of items. */
export type App_BookingsByEmployeeReportConnection = {
   __typename?: 'App_BookingsByEmployeeReportConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_BookingsByEmployeeReportEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_BookingsByEmployeeReportEdge = {
   __typename?: 'App_BookingsByEmployeeReportEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_BookingsByEmployeeReport>
}

export type App_BookingsByServiceReport = {
   __typename?: 'App_BookingsByServiceReport'
   name?: Maybe<Scalars['String']>
   totalCount: Scalars['Int']
   totalPrice: Money
}

/** A connection to a list of items. */
export type App_BookingsByServiceReportConnection = {
   __typename?: 'App_BookingsByServiceReportConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_BookingsByServiceReportEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_BookingsByServiceReportEdge = {
   __typename?: 'App_BookingsByServiceReportEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_BookingsByServiceReport>
}

export enum App_BucketName {
   Location = 'LOCATION',
   Staff = 'STAFF',
}

export type App_CancelRecurringAppointmentInput = {
   appointmentId: Scalars['Uuid']
   cancellationReasonId?: InputMaybe<Scalars['Uuid']>
   recurrenceId: Scalars['Uuid']
}

export type App_CancelRecurringAppointmentResponse = {
   __typename?: 'App_CancelRecurringAppointmentResponse'
   success: Scalars['Boolean']
}

export type App_Client = {
   __typename?: 'App_Client'
   address?: Maybe<Scalars['String']>
   birthday?: Maybe<Birthday>
   city?: Maybe<Scalars['String']>
   email?: Maybe<Scalars['String']>
   firstName?: Maybe<Scalars['String']>
   gender?: Maybe<Gender>
   id?: Maybe<Scalars['Uuid']>
   lastName?: Maybe<Scalars['String']>
   note?: Maybe<Scalars['String']>
   noteVisibleOnAppointments?: Maybe<Scalars['Boolean']>
   notificationChannels?: Maybe<Array<NotificationChannel>>
   phoneNumber?: Maybe<Phone>
   postalCode?: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export type App_ClientConnection = {
   __typename?: 'App_ClientConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_ClientEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_ClientEdge = {
   __typename?: 'App_ClientEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_Client>
}

export enum App_ClientGroupFilter {
   All = 'ALL',
   ByAge = 'BY_AGE',
   ByGender = 'BY_GENDER',
   New = 'NEW',
   Recent = 'RECENT',
   Selected = 'SELECTED',
}

export type App_ClientGroupFilterValueInput = {
   ageFrom?: InputMaybe<Scalars['Int']>
   ageTo?: InputMaybe<Scalars['Int']>
   clientsIds?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>
   daysFrom?: InputMaybe<Scalars['Int']>
   gender?: InputMaybe<Gender>
}

export type App_ClientReport = {
   __typename?: 'App_ClientReport'
   appointments: Scalars['Int']
   createdAt?: Maybe<Scalars['DateTime']>
   daysAbsent?: Maybe<Scalars['Int']>
   firstName?: Maybe<Scalars['String']>
   gender: Gender
   id: Scalars['Uuid']
   lastAppointmentAt?: Maybe<Scalars['DateTime']>
   lastLocation?: Maybe<Scalars['String']>
   lastName?: Maybe<Scalars['String']>
   noShows: Scalars['Int']
   totalSales: Money
}

/** A connection to a list of items. */
export type App_ClientReportConnection = {
   __typename?: 'App_ClientReportConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_ClientReportEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_ClientReportEdge = {
   __typename?: 'App_ClientReportEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_ClientReport>
}

export type App_ClientsAnalytics = {
   __typename?: 'App_ClientsAnalytics'
   averageVisitsCount?: Maybe<Scalars['Float']>
   bookedCount?: Maybe<Scalars['Int']>
   byAge?: Maybe<Array<App_ClientsByAgeAnalytics>>
   byGender?: Maybe<Array<App_ClientsByGenderAnalytics>>
   byReferralSource?: Maybe<Array<App_ClientsByReferralSourceAnalytics>>
   newCount?: Maybe<Scalars['Int']>
   returningCount?: Maybe<Scalars['Int']>
   returningPercent?: Maybe<Scalars['Int']>
}

export type App_ClientsByAgeAnalytics = {
   __typename?: 'App_ClientsByAgeAnalytics'
   count?: Maybe<Scalars['Int']>
   name?: Maybe<Scalars['String']>
   percent?: Maybe<Scalars['Int']>
}

export type App_ClientsByGenderAnalytics = {
   __typename?: 'App_ClientsByGenderAnalytics'
   count?: Maybe<Scalars['Int']>
   gender?: Maybe<Gender>
   percent?: Maybe<Scalars['Int']>
}

export type App_ClientsByReferralSourceAnalytics = {
   __typename?: 'App_ClientsByReferralSourceAnalytics'
   count?: Maybe<Scalars['Int']>
   name?: Maybe<Scalars['String']>
   percent?: Maybe<Scalars['Int']>
}

export type App_ClosedDate = {
   __typename?: 'App_ClosedDate'
   description?: Maybe<Scalars['String']>
   endDate: Scalars['Date']
   id: Scalars['Uuid']
   locationIds?: Maybe<Array<Scalars['Uuid']>>
   locations?: Maybe<Array<Maybe<App_Location>>>
   startDate: Scalars['Date']
}

/** A connection to a list of items. */
export type App_ClosedDateConnection = {
   __typename?: 'App_ClosedDateConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_ClosedDateEdge>>>
   emptyState?: Maybe<Scalars['Boolean']>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_ClosedDateEdge = {
   __typename?: 'App_ClosedDateEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_ClosedDate>
}

export type App_CreateAutoSendingMarketingCampaignInput = {
   active?: InputMaybe<Scalars['Boolean']>
   channel?: InputMaybe<Array<NotificationChannel>>
   emailMessage?: InputMaybe<App_AutoSendingMarketingCampaignEmailInput>
   options: Scalars['Json']
   smsMessage?: InputMaybe<App_AutoSendingMarketingCampaignSmsInput>
   type: App_AutoSendingMarketingType
}

export type App_CreateClosedDateInput = {
   description?: InputMaybe<Scalars['String']>
   endDate: Scalars['Date']
   locationIds: Array<Scalars['Uuid']>
   startDate: Scalars['Date']
}

export type App_CreateClosedDateResponse = {
   __typename?: 'App_CreateClosedDateResponse'
   success: Scalars['Boolean']
}

export type App_CreateEmailMassMarketingCampaignInput = {
   clientGroup?: InputMaybe<App_ClientGroupFilter>
   clientGroupFilterValue?: InputMaybe<App_ClientGroupFilterValueInput>
   ctaName?: InputMaybe<Scalars['String']>
   ctaUrl?: InputMaybe<Scalars['String']>
   customUrl?: InputMaybe<Scalars['String']>
   facebookUrl?: InputMaybe<Scalars['String']>
   instagramUrl?: InputMaybe<Scalars['String']>
   message?: InputMaybe<Scalars['String']>
   photo?: InputMaybe<Scalars['String']>
   replyTo?: InputMaybe<Scalars['String']>
   scheduledAt?: InputMaybe<Scalars['Date']>
   subject?: InputMaybe<Scalars['String']>
   title?: InputMaybe<Scalars['String']>
}

export type App_CreateMassMarketingCampaignResponse = {
   __typename?: 'App_CreateMassMarketingCampaignResponse'
   success: Scalars['Boolean']
}

export type App_CreateRecurringAppointmentInput = {
   bookings: Array<InputMaybe<App_AppointmentBookingsInput>>
   clientId?: InputMaybe<Scalars['Uuid']>
   date: Scalars['Date']
   employeeId?: InputMaybe<Scalars['Uuid']>
   frequency: App_Frequency
   locationId: Scalars['Uuid']
   notes?: InputMaybe<Scalars['String']>
   repeatingType: App_RepeatingType
   repeatingValue?: InputMaybe<Scalars['String']>
}

export type App_CreateRecurringAppointmentResponse = {
   __typename?: 'App_CreateRecurringAppointmentResponse'
   success: Scalars['Boolean']
}

export type App_CreateSmsMassMarketingCampaignInput = {
   clientGroup?: InputMaybe<App_ClientGroupFilter>
   clientGroupFilterValue?: InputMaybe<App_ClientGroupFilterValueInput>
   scheduledAt?: InputMaybe<Scalars['Date']>
   sender?: InputMaybe<Scalars['String']>
   text?: InputMaybe<Scalars['String']>
}

export type App_DeleteClosedDateInput = {
   id: Scalars['Uuid']
}

export type App_DeleteClosedDateResponse = {
   __typename?: 'App_DeleteClosedDateResponse'
   success: Scalars['Boolean']
}

export enum App_EarliestAppointmentSetting {
   Immediately = 'IMMEDIATELY',
   UpTo_1Hour = 'UP_TO_1_HOUR',
   UpTo_1Week = 'UP_TO_1_WEEK',
   UpTo_2Days = 'UP_TO_2_DAYS',
   UpTo_2Hours = 'UP_TO_2_HOURS',
   UpTo_2Week = 'UP_TO_2_WEEK',
   UpTo_3Days = 'UP_TO_3_DAYS',
   UpTo_3Hours = 'UP_TO_3_HOURS',
   UpTo_4Days = 'UP_TO_4_DAYS',
   UpTo_4Hours = 'UP_TO_4_HOURS',
   UpTo_5Days = 'UP_TO_5_DAYS',
   UpTo_5Hours = 'UP_TO_5_HOURS',
   UpTo_6Days = 'UP_TO_6_DAYS',
   UpTo_6Hours = 'UP_TO_6_HOURS',
   UpTo_7Hours = 'UP_TO_7_HOURS',
   UpTo_8Hours = 'UP_TO_8_HOURS',
   UpTo_9Hours = 'UP_TO_9_HOURS',
   UpTo_10Hours = 'UP_TO_10_HOURS',
   UpTo_11Hours = 'UP_TO_11_HOURS',
   UpTo_12Hours = 'UP_TO_12_HOURS',
   UpTo_13Hours = 'UP_TO_13_HOURS',
   UpTo_14Hours = 'UP_TO_14_HOURS',
   UpTo_15Hours = 'UP_TO_15_HOURS',
   UpTo_15Minutes = 'UP_TO_15_MINUTES',
   UpTo_16Hours = 'UP_TO_16_HOURS',
   UpTo_17Hours = 'UP_TO_17_HOURS',
   UpTo_18Hours = 'UP_TO_18_HOURS',
   UpTo_19Hours = 'UP_TO_19_HOURS',
   UpTo_20Hours = 'UP_TO_20_HOURS',
   UpTo_21Hours = 'UP_TO_21_HOURS',
   UpTo_22Hours = 'UP_TO_22_HOURS',
   UpTo_23Hours = 'UP_TO_23_HOURS',
   UpTo_24Hours = 'UP_TO_24_HOURS',
   UpTo_30Minutes = 'UP_TO_30_MINUTES',
}

export type App_EditRecurringAppointmentInput = {
   appointmentId: Scalars['Uuid']
   bookings: Array<InputMaybe<App_AppointmentBookingsInput>>
   clientId?: InputMaybe<Scalars['Uuid']>
   date: Scalars['Date']
   employeeId?: InputMaybe<Scalars['Uuid']>
   notes?: InputMaybe<Scalars['String']>
   recurrenceId: Scalars['Uuid']
}

export type App_EditRecurringAppointmentResponse = {
   __typename?: 'App_EditRecurringAppointmentResponse'
   success: Scalars['Boolean']
}

export type App_Employee = {
   __typename?: 'App_Employee'
   appointmentColor?: Maybe<Scalars['String']>
   bookableOnline?: Maybe<Scalars['Boolean']>
   firstName?: Maybe<Scalars['String']>
   id: Scalars['Uuid']
   lastName?: Maybe<Scalars['String']>
   notes?: Maybe<Scalars['String']>
   phoneNumber?: Maybe<Phone>
}

/** A connection to a list of items. */
export type App_EmployeeConnection = {
   __typename?: 'App_EmployeeConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_EmployeeEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

export type App_EmployeeDetails = {
   __typename?: 'App_EmployeeDetails'
   appointmentColor?: Maybe<Scalars['String']>
   bookableOnline?: Maybe<Scalars['Boolean']>
   email?: Maybe<Scalars['String']>
   firstName?: Maybe<Scalars['String']>
   id: Scalars['Uuid']
   lastName?: Maybe<Scalars['String']>
   locationIds?: Maybe<Array<Maybe<Scalars['Uuid']>>>
   notes?: Maybe<Scalars['String']>
   permissionLevel?: Maybe<Scalars['String']>
   phoneNumber?: Maybe<Scalars['String']>
   serviceGroup?: Maybe<Array<Maybe<ServiceGroup>>>
}

/** An edge in a connection. */
export type App_EmployeeEdge = {
   __typename?: 'App_EmployeeEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_Employee>
}

export enum App_Frequency {
   Daily = 'DAILY',
   EightMonths = 'EIGHT_MONTHS',
   EightWeeks = 'EIGHT_WEEKS',
   ElevenMonths = 'ELEVEN_MONTHS',
   FiveDays = 'FIVE_DAYS',
   FiveMonths = 'FIVE_MONTHS',
   FiveWeeks = 'FIVE_WEEKS',
   FourDays = 'FOUR_DAYS',
   FourMonths = 'FOUR_MONTHS',
   FourWeeks = 'FOUR_WEEKS',
   Month = 'MONTH',
   NineMonths = 'NINE_MONTHS',
   NineWeeks = 'NINE_WEEKS',
   SevenMonths = 'SEVEN_MONTHS',
   SevenWeeks = 'SEVEN_WEEKS',
   SixDays = 'SIX_DAYS',
   SixMonths = 'SIX_MONTHS',
   SixWeeks = 'SIX_WEEKS',
   TenMonths = 'TEN_MONTHS',
   TenWeeks = 'TEN_WEEKS',
   ThreeDays = 'THREE_DAYS',
   ThreeMonths = 'THREE_MONTHS',
   ThreeWeeks = 'THREE_WEEKS',
   TwoDays = 'TWO_DAYS',
   TwoMonths = 'TWO_MONTHS',
   TwoWeeks = 'TWO_WEEKS',
   Week = 'WEEK',
}

export type App_GenerateLocationUploadsInput = {
   bucket_name?: InputMaybe<Scalars['String']>
   count?: InputMaybe<Scalars['Int']>
}

export type App_GenerateLocationUploadsResponse = {
   __typename?: 'App_GenerateLocationUploadsResponse'
   uploads?: Maybe<Array<Maybe<App_LocationUpload>>>
}

export type App_GeneratePhotoUploadsInput = {
   bucket_name: App_BucketName
   count?: InputMaybe<Scalars['Int']>
}

export type App_GeneratePhotoUploadsResponse = {
   __typename?: 'App_GeneratePhotoUploadsResponse'
   uploads?: Maybe<Array<Maybe<App_LocationUpload>>>
}

export enum App_LatestAppointmentSetting {
   UpTo_1Month = 'UP_TO_1_MONTH',
   UpTo_2Months = 'UP_TO_2_MONTHS',
   UpTo_3Months = 'UP_TO_3_MONTHS',
   UpTo_4Months = 'UP_TO_4_MONTHS',
   UpTo_5Months = 'UP_TO_5_MONTHS',
   UpTo_6Months = 'UP_TO_6_MONTHS',
}

export type App_Location = {
   __typename?: 'App_Location'
   address?: Maybe<Scalars['String']>
   addressDetails?: Maybe<Scalars['String']>
   city?: Maybe<Scalars['String']>
   description?: Maybe<Scalars['String']>
   email?: Maybe<Scalars['String']>
   id: Scalars['Uuid']
   name: Scalars['String']
   onlineStatus: App_LocationOnlineStatus
   /** Country calling code + number, e.g "+370-61231231" */
   phone?: Maybe<Scalars['String']>
   photos: Array<Maybe<App_LocationPhoto>>
   /** Position in list */
   position: Scalars['Int']
   previewToken?: Maybe<Scalars['String']>
   previewUrl?: Maybe<Scalars['String']>
   slug?: Maybe<Scalars['String']>
   state?: Maybe<Scalars['String']>
   workingHours?: Maybe<App_LocationWorkingHours>
   zipCode?: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export type App_LocationConnection = {
   __typename?: 'App_LocationConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_LocationEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_LocationEdge = {
   __typename?: 'App_LocationEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_Location>
}

export enum App_LocationOnlineStatus {
   Published = 'PUBLISHED',
   Unpublished = 'UNPUBLISHED',
}

export type App_LocationPhoto = {
   __typename?: 'App_LocationPhoto'
   name?: Maybe<Scalars['String']>
   url?: Maybe<Scalars['String']>
}

export type App_LocationUpload = {
   __typename?: 'App_LocationUpload'
   form?: Maybe<App_LocationUploadForm>
   name?: Maybe<Scalars['String']>
   url?: Maybe<Scalars['String']>
}

export type App_LocationUploadForm = {
   __typename?: 'App_LocationUploadForm'
   action?: Maybe<Scalars['String']>
   input?: Maybe<App_LocationUploadFormInput>
   method?: Maybe<Scalars['String']>
}

export type App_LocationUploadFormInput = {
   __typename?: 'App_LocationUploadFormInput'
   acl?: Maybe<Scalars['String']>
   key?: Maybe<Scalars['String']>
   policy?: Maybe<Scalars['String']>
   xAmzAlgorithm?: Maybe<Scalars['String']>
   xAmzCredential?: Maybe<Scalars['String']>
   xAmzDate?: Maybe<Scalars['String']>
   xAmzSignature?: Maybe<Scalars['String']>
}

export type App_LocationWorkingHours = {
   __typename?: 'App_LocationWorkingHours'
   friday?: Maybe<App_LocationWorkingTime>
   monday?: Maybe<App_LocationWorkingTime>
   saturday?: Maybe<App_LocationWorkingTime>
   sunday?: Maybe<App_LocationWorkingTime>
   thursday?: Maybe<App_LocationWorkingTime>
   tuesday?: Maybe<App_LocationWorkingTime>
   wednesday?: Maybe<App_LocationWorkingTime>
}

export type App_LocationWorkingHoursInput = {
   friday: App_LocationWorkingTimeInput
   monday: App_LocationWorkingTimeInput
   saturday: App_LocationWorkingTimeInput
   sunday: App_LocationWorkingTimeInput
   thursday: App_LocationWorkingTimeInput
   tuesday: App_LocationWorkingTimeInput
   wednesday: App_LocationWorkingTimeInput
}

export type App_LocationWorkingTime = {
   __typename?: 'App_LocationWorkingTime'
   closesAt?: Maybe<Scalars['Int']>
   open: Scalars['Boolean']
   opensAt?: Maybe<Scalars['Int']>
}

export type App_LocationWorkingTimeInput = {
   /** Time in minutes */
   closesAt?: InputMaybe<Scalars['Int']>
   open: Scalars['Boolean']
   /** Time in minutes */
   opensAt?: InputMaybe<Scalars['Int']>
}

export type App_MassMarketingCampaign = {
   __typename?: 'App_MassMarketingCampaign'
   clients?: Maybe<Array<Maybe<App_Client>>>
   emailMessage?: Maybe<App_MassMarketingCampaignEmailMessage>
   id: Scalars['Uuid']
   scheduledAt?: Maybe<Scalars['Date']>
   smsMessage?: Maybe<App_MassMarketingCampaignSmsMessage>
}

/** A connection to a list of items. */
export type App_MassMarketingCampaignConnection = {
   __typename?: 'App_MassMarketingCampaignConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_MassMarketingCampaignEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_MassMarketingCampaignEdge = {
   __typename?: 'App_MassMarketingCampaignEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_MassMarketingCampaign>
}

export type App_MassMarketingCampaignEmailMessage = {
   __typename?: 'App_MassMarketingCampaignEmailMessage'
   ctaName?: Maybe<Scalars['String']>
   ctaUrl?: Maybe<Scalars['String']>
   customUrl?: Maybe<Scalars['String']>
   facebookUrl?: Maybe<Scalars['String']>
   instagramUrl?: Maybe<Scalars['String']>
   message?: Maybe<Scalars['String']>
   photo?: Maybe<Scalars['String']>
   replyTo?: Maybe<Scalars['String']>
   subject?: Maybe<Scalars['String']>
   title?: Maybe<Scalars['String']>
}

export type App_MassMarketingCampaignSmsMessage = {
   __typename?: 'App_MassMarketingCampaignSmsMessage'
   sender?: Maybe<Scalars['String']>
   text?: Maybe<Scalars['String']>
}

export type App_OccupancyAnalytics = {
   __typename?: 'App_OccupancyAnalytics'
   blockedHoursCount?: Maybe<Scalars['Float']>
   blockedHoursPercent?: Maybe<Scalars['Int']>
   bookedHoursCount?: Maybe<Scalars['Float']>
   bookedHoursPercent?: Maybe<Scalars['Int']>
   percent?: Maybe<Scalars['Int']>
   unbookedHoursCount?: Maybe<Scalars['Float']>
   unbookedHoursPercent?: Maybe<Scalars['Int']>
   workingHoursCount?: Maybe<Scalars['Float']>
}

export type App_OnlineBookingSettings = {
   __typename?: 'App_OnlineBookingSettings'
   allowEmployeeSelection: Scalars['Boolean']
   appointmentChangeTime: App_AppointmentChangeTimeSetting
   earliestAppointment: App_EarliestAppointmentSetting
   importantInformation?: Maybe<Scalars['String']>
   latestAppointment: App_LatestAppointmentSetting
   notificationSpecificEmail?: Maybe<Scalars['String']>
   notifyBookedEmployee: Scalars['Boolean']
   notifySpecificEmail: Scalars['Boolean']
   onlineRequiredField?: Maybe<Array<App_OnlineRequiredField>>
   partnerSlug?: Maybe<Scalars['String']>
   timeSlotInterval: App_TimeSlotIntervalSetting
   onlineOptionalField?: Maybe<Array<App_OnlineRequiredField>>
   onlineHiddenField?: Maybe<Array<App_OnlineRequiredField>>
}

export enum App_OnlineRequiredField {
   FirstName = 'FIRST_NAME',
   LastName = 'LAST_NAME',
   PhoneNumber = 'PHONE_NUMBER',
   Email = 'EMAIL',
   Notes = 'NOTES',
}

export type App_Partner = {
   __typename?: 'App_Partner'
   address?: Maybe<Scalars['String']>
   country?: Maybe<Scalars['String']>
   currency?: Maybe<Scalars['String']>
   description?: Maybe<Scalars['String']>
   email?: Maybe<Scalars['String']>
   id?: Maybe<Scalars['Uuid']>
   locale?: Maybe<Scalars['String']>
   name?: Maybe<Scalars['String']>
   notificationCharge?: Maybe<Scalars['Float']>
   phoneNumber?: Maybe<Phone>
   slug?: Maybe<Scalars['String']>
   subscription: App_Subscription
   timeFormat?: Maybe<TimeFormat>
   timeZone?: Maybe<Scalars['String']>
   website?: Maybe<Scalars['String']>
}

export enum App_Permission {
   AccessAllLocations = 'ACCESS_ALL_LOCATIONS',
   AccessOtherEmployeeCalendars = 'ACCESS_OTHER_EMPLOYEE_CALENDARS',
   AccessOwnCalendar = 'ACCESS_OWN_CALENDAR',
   AccountSettings = 'ACCOUNT_SETTINGS',
   Analytics = 'ANALYTICS',
   CanBookAppointments = 'CAN_BOOK_APPOINTMENTS',
   CanSeeClientsContactInfo = 'CAN_SEE_CLIENTS_CONTACT_INFO',
   Clients = 'CLIENTS',
   ClientSettings = 'CLIENT_SETTINGS',
   ClosesDates = 'CLOSES_DATES',
   Employees = 'EMPLOYEES',
   Marketing = 'MARKETING',
   Messages = 'MESSAGES',
   OnlineBookings = 'ONLINE_BOOKINGS',
   PermissionLevels = 'PERMISSION_LEVELS',
   Services = 'SERVICES',
   SubscriptionBilling = 'SUBSCRIPTION_BILLING',
   WorkingHours = 'WORKING_HOURS',
}

export enum App_PermissionLevel {
   Basic = 'BASIC',
   High = 'HIGH',
   Low = 'LOW',
   Medium = 'MEDIUM',
   NoAccess = 'NO_ACCESS',
   Owner = 'OWNER',
}

export type App_Pricing = {
   __typename?: 'App_Pricing'
   countryIsoCode?: Maybe<Scalars['String']>
   markUp?: Maybe<Scalars['Float']>
   notificationChannel?: Maybe<NotificationChannel>
   price?: Maybe<Scalars['Float']>
   vat?: Maybe<Scalars['Float']>
}

/** A connection to a list of items. */
export type App_PricingConnection = {
   __typename?: 'App_PricingConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_PricingEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_PricingEdge = {
   __typename?: 'App_PricingEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_Pricing>
}

export type App_PublishLocationInput = {
   id: Scalars['Uuid']
   publish: Scalars['Boolean']
}

export type App_PublishLocationResponse = {
   __typename?: 'App_PublishLocationResponse'
   success: Scalars['Boolean']
}

export enum App_RepeatingType {
   NumberOfTimes = 'NUMBER_OF_TIMES',
   UntilDate = 'UNTIL_DATE',
}

export type App_ReportAppointment = {
   __typename?: 'App_ReportAppointment'
   clientFirstName?: Maybe<Scalars['String']>
   clientLastName?: Maybe<Scalars['String']>
   date?: Maybe<Scalars['Date']>
   duration?: Maybe<Scalars['Int']>
   employeeFirstName?: Maybe<Scalars['String']>
   employeeLastName?: Maybe<Scalars['String']>
   id?: Maybe<Scalars['Int']>
   locationName?: Maybe<Scalars['String']>
   price: Money
   serviceName?: Maybe<Scalars['String']>
   status?: Maybe<Scalars['String']>
   time?: Maybe<Scalars['String']>
}

export type App_ReportAppointmentCancellations = {
   __typename?: 'App_ReportAppointmentCancellations'
   cancelledByFirstName?: Maybe<Scalars['String']>
   cancelledByLastName?: Maybe<Scalars['String']>
   cancelledDate?: Maybe<Scalars['Date']>
   clientFirstName?: Maybe<Scalars['String']>
   clientLastName?: Maybe<Scalars['String']>
   id?: Maybe<Scalars['String']>
   price: Money
   reasonName?: Maybe<Scalars['String']>
   scheduledDate?: Maybe<Scalars['Date']>
   serviceName?: Maybe<Scalars['String']>
}

export type App_ReportAppointmentsByCancellationReason = {
   __typename?: 'App_ReportAppointmentsByCancellationReason'
   CancellationReasonName?: Maybe<Scalars['String']>
   totalCount: Scalars['Int']
}

/** A connection to a list of items. */
export type App_ReportAppointmentsByCancellationReasonConnection = {
   __typename?: 'App_ReportAppointmentsByCancellationReasonConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_ReportAppointmentsByCancellationReasonEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_ReportAppointmentsByCancellationReasonEdge = {
   __typename?: 'App_ReportAppointmentsByCancellationReasonEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_ReportAppointmentsByCancellationReason>
}

/** A connection to a list of items. */
export type App_ReportCancellationsConnection = {
   __typename?: 'App_ReportCancellationsConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_ReportCancellationsEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_ReportCancellationsEdge = {
   __typename?: 'App_ReportCancellationsEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_ReportAppointmentCancellations>
}

export type App_Reports = {
   __typename?: 'App_Reports'
   appointmentCancellations?: Maybe<App_ReportCancellationsConnection>
   appointmentsByCancellationReason?: Maybe<App_ReportAppointmentsByCancellationReasonConnection>
   appointmentsReport?: Maybe<App_AppointmentsReportConnection>
   bookingsByEmployee?: Maybe<App_BookingsByEmployeeReportConnection>
   bookingsByService?: Maybe<App_BookingsByServiceReportConnection>
   clientsReport?: Maybe<App_ClientReportConnection>
}

export type App_ReportsAppointmentCancellationsArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   employeeId?: InputMaybe<Scalars['Uuid']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
   locationId?: InputMaybe<Scalars['Uuid']>
}

export type App_ReportsAppointmentsByCancellationReasonArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   employeeId?: InputMaybe<Scalars['Uuid']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
   locationId?: InputMaybe<Scalars['Uuid']>
}

export type App_ReportsAppointmentsReportArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   employeeId?: InputMaybe<Scalars['Uuid']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
   locationId?: InputMaybe<Scalars['Uuid']>
}

export type App_ReportsBookingsByEmployeeArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   employeeId?: InputMaybe<Scalars['Uuid']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
   locationId?: InputMaybe<Scalars['Uuid']>
}

export type App_ReportsBookingsByServiceArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   employeeId?: InputMaybe<Scalars['Uuid']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
   locationId?: InputMaybe<Scalars['Uuid']>
}

export type App_ReportsClientsReportArgs = {
   after?: InputMaybe<Scalars['String']>
   before?: InputMaybe<Scalars['String']>
   first?: InputMaybe<Scalars['Int']>
   last?: InputMaybe<Scalars['Int']>
}

export type App_RequestPasswordResendInput = {
   email: Scalars['String']
}

export type App_RequestPasswordResendResponse = {
   __typename?: 'App_RequestPasswordResendResponse'
   success: Scalars['Boolean']
}

export type App_ResetAutoSendingMarketingCampaignInput = {
   type: App_AutoSendingMarketingType
}

export type App_Service = {
   __typename?: 'App_Service'
   bookableOnline?: Maybe<Scalars['Boolean']>
   description?: Maybe<Scalars['String']>
   /** In minutes */
   duration: Scalars['Int']
   id: Scalars['Uuid']
   name?: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export type App_ServiceConnection = {
   __typename?: 'App_ServiceConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_ServiceEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_ServiceEdge = {
   __typename?: 'App_ServiceEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_Service>
}

export type App_ServiceGroup = {
   __typename?: 'App_ServiceGroup'
   color?: Maybe<Scalars['String']>
   id: Scalars['Uuid']
   name?: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export type App_ServiceGroupConnection = {
   __typename?: 'App_ServiceGroupConnection'
   /** Information to aid in pagination. */
   edges?: Maybe<Array<Maybe<App_ServiceGroupEdge>>>
   /** Information to aid in pagination. */
   pageInfo: PageInfo
   totalCount?: Maybe<Scalars['Int']>
}

/** An edge in a connection. */
export type App_ServiceGroupEdge = {
   __typename?: 'App_ServiceGroupEdge'
   /** A cursor for use in pagination. */
   cursor: Scalars['String']
   /** The item at the end of the edge. */
   node?: Maybe<App_ServiceGroup>
}

export type App_Subscription = {
   __typename?: 'App_Subscription'
   plan: App_SubscriptionPlan
   premium?: Maybe<Scalars['Boolean']>
}

export type App_SubscriptionPlan = {
   __typename?: 'App_SubscriptionPlan'
   features: Array<App_SubscriptionPlanFeature>
   type: App_SubscriptionPlanType
}

export enum App_SubscriptionPlanFeature {
   SmsNotifications = 'SMS_NOTIFICATIONS',
   UnlimitedEmployees = 'UNLIMITED_EMPLOYEES',
}

export enum App_SubscriptionPlanType {
   Business = 'BUSINESS',
   Free = 'FREE',
   Individual = 'INDIVIDUAL',
}

export enum App_TimeSlotIntervalSetting {
   Interval_5Minutes = 'INTERVAL_5_MINUTES',
   Interval_10Minutes = 'INTERVAL_10_MINUTES',
   Interval_15Minutes = 'INTERVAL_15_MINUTES',
   Interval_20Minutes = 'INTERVAL_20_MINUTES',
   Interval_30Minutes = 'INTERVAL_30_MINUTES',
   Interval_40Minutes = 'INTERVAL_40_MINUTES',
   Interval_45Minutes = 'INTERVAL_45_MINUTES',
   Interval_50Minutes = 'INTERVAL_50_MINUTES',
   Interval_60Minutes = 'INTERVAL_60_MINUTES',
   Interval_75Minutes = 'INTERVAL_75_MINUTES',
   Interval_90Minutes = 'INTERVAL_90_MINUTES',
   Interval_120Minutes = 'INTERVAL_120_MINUTES',
   Interval_150Minutes = 'INTERVAL_150_MINUTES',
   Interval_180Minutes = 'INTERVAL_180_MINUTES',
   Interval_240Minutes = 'INTERVAL_240_MINUTES',
}

export type App_TopCancellationReason = {
   __typename?: 'App_TopCancellationReason'
   appointmentCount?: Maybe<Scalars['Int']>
   id?: Maybe<Scalars['Uuid']>
   name?: Maybe<Scalars['String']>
   percent?: Maybe<Scalars['Int']>
}

export type App_TopService = {
   __typename?: 'App_TopService'
   bookingCount?: Maybe<Scalars['Int']>
   id?: Maybe<Scalars['Uuid']>
   name?: Maybe<Scalars['String']>
}

export type App_UpdateAutoSendingMarketingCampaignInput = {
   active?: InputMaybe<Scalars['Boolean']>
   channel?: InputMaybe<Array<NotificationChannel>>
   emailMessage?: InputMaybe<App_AutoSendingMarketingCampaignEmailInput>
   options: Scalars['Json']
   smsMessage?: InputMaybe<App_AutoSendingMarketingCampaignSmsInput>
   type: App_AutoSendingMarketingType
}

export type App_UpdateClosedDateInput = {
   description?: InputMaybe<Scalars['String']>
   endDate: Scalars['Date']
   id: Scalars['Uuid']
   locationIds: Array<Scalars['Uuid']>
   startDate: Scalars['Date']
}

export type App_UpdateClosedDateResponse = {
   __typename?: 'App_UpdateClosedDateResponse'
   success: Scalars['Boolean']
}

export type App_UpdateEmployeeDetailsInput = {
   appointmentColor?: InputMaybe<Scalars['String']>
   bookableOnline: Scalars['Boolean']
   email: Scalars['String']
   firstName: Scalars['String']
   id: Scalars['Uuid']
   lastName?: InputMaybe<Scalars['String']>
   locationIds?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>
   notes?: InputMaybe<Scalars['String']>
   permissionLevel: Scalars['String']
   phoneNumber?: InputMaybe<Scalars['String']>
   serviceGroup?: InputMaybe<Array<ServiceGroupInput>>
}

export type App_UpdateEmployeeDetailsResponse = {
   __typename?: 'App_UpdateEmployeeDetailsResponse'
   success: Scalars['Boolean']
}

export type App_UpdateLocationAddressInput = {
   address?: InputMaybe<Scalars['String']>
   addressDetails?: InputMaybe<Scalars['String']>
   city?: InputMaybe<Scalars['String']>
   id: Scalars['Uuid']
   state?: InputMaybe<Scalars['String']>
   zipCode?: InputMaybe<Scalars['String']>
}

export type App_UpdateLocationAddressResponse = {
   __typename?: 'App_UpdateLocationAddressResponse'
   success: Scalars['Boolean']
}

export type App_UpdateLocationBusinessDetailsInput = {
   description?: InputMaybe<Scalars['String']>
   id: Scalars['Uuid']
   name?: InputMaybe<Scalars['String']>
   phone?: InputMaybe<Scalars['String']>
   slug?: InputMaybe<Scalars['String']>
}

export type App_UpdateLocationBusinessDetailsResponse = {
   __typename?: 'App_UpdateLocationBusinessDetailsResponse'
   success: Scalars['Boolean']
}

export type App_UpdateLocationPhotosInput = {
   id: Scalars['Uuid']
   photos: Array<Scalars['String']>
}

export type App_UpdateLocationPhotosResponse = {
   __typename?: 'App_UpdateLocationPhotosResponse'
   success: Scalars['Boolean']
}

export type App_UpdateLocationWorkingHoursInput = {
   id: Scalars['Uuid']
   workingHours: App_LocationWorkingHoursInput
}

export type App_UpdateLocationWorkingHoursResponse = {
   __typename?: 'App_UpdateLocationWorkingHoursResponse'
   success: Scalars['Boolean']
}

export type App_UpdateOnlineBookingSettingsInput = {
   allowEmployeeSelection?: InputMaybe<Scalars['Boolean']>
   appointmentChangeTime?: InputMaybe<App_AppointmentChangeTimeSetting>
   earliestAppointment?: InputMaybe<App_EarliestAppointmentSetting>
   importantInformation?: InputMaybe<Scalars['String']>
   latestAppointment?: InputMaybe<App_LatestAppointmentSetting>
   notificationSpecificEmail?: InputMaybe<Scalars['String']>
   notifyBookedEmployee?: InputMaybe<Scalars['Boolean']>
   notifySpecificEmail?: InputMaybe<Scalars['Boolean']>
   onlineRequiredField?: InputMaybe<Array<App_OnlineRequiredField>>
   partnerSlug?: InputMaybe<Scalars['String']>
   timeSlotInterval?: InputMaybe<App_TimeSlotIntervalSetting>
   onlineOptionalField?: InputMaybe<Array<App_OnlineRequiredField>>
   onlineHiddenField?: InputMaybe<Array<App_OnlineRequiredField>>
   placeholderNotes?: InputMaybe<Scalars['String']>
}

export type App_UpdateOnlineBookingSettingsResponse = {
   __typename?: 'App_UpdateOnlineBookingSettingsResponse'
   success: Scalars['Boolean']
}

export type App_User = {
   __typename?: 'App_User'
   access?: Maybe<App_Access>
   employee?: Maybe<App_Employee>
   language?: Maybe<Scalars['String']>
   partner?: Maybe<App_Partner>
   username?: Maybe<Scalars['String']>
}

export type Birthday = {
   __typename?: 'Birthday'
   day?: Maybe<Scalars['Int']>
   month?: Maybe<Scalars['Int']>
   year?: Maybe<Scalars['Int']>
}

export enum Gender {
   Female = 'FEMALE',
   Male = 'MALE',
   Unknown = 'UNKNOWN',
}

export type Money = {
   __typename?: 'Money'
   amount: Scalars['String']
   currency: Scalars['String']
}

export enum NotificationChannel {
   Email = 'EMAIL',
   Sms = 'SMS',
   Whatsapp = 'WHATSAPP',
}

/** Information about pagination in a connection. */
export type PageInfo = {
   __typename?: 'PageInfo'
   /** When paginating forwards, the cursor to continue. */
   endCursor?: Maybe<Scalars['String']>
   /** When paginating forwards, are there more items? */
   hasNextPage: Scalars['Boolean']
   /** When paginating backwards, are there more items? */
   hasPreviousPage: Scalars['Boolean']
   /** When paginating backwards, the cursor to continue. */
   startCursor?: Maybe<Scalars['String']>
}

export type Phone = {
   __typename?: 'Phone'
   number?: Maybe<Scalars['String']>
   prefix?: Maybe<Scalars['String']>
}

export type ServiceGroup = {
   __typename?: 'ServiceGroup'
   name?: Maybe<Scalars['String']>
   serviceIds?: Maybe<Array<Scalars['Uuid']>>
}

export type ServiceGroupInput = {
   name?: InputMaybe<Scalars['String']>
   serviceIds?: InputMaybe<Array<Scalars['Uuid']>>
}

export enum TimeFormat {
   Format_12H = 'FORMAT_12H',
   Format_24H = 'FORMAT_24H',
}
