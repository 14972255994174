import { format, parse } from 'date-fns'

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
export const DEFAULT_DATE_FORMAT_WITH_TIME = 'yyyy-MM-dd HH:mm:ss'

export const formatDateField = (value: any) => {
   return value instanceof Date ? format(value, DEFAULT_DATE_FORMAT) : value
}

export const parseDateField = (value: any) => {
   return value ? (value instanceof Date ? value : parse(value, DEFAULT_DATE_FORMAT, new Date())) : null
}

export const parseDateFieldWithTime = (value: any) => {
   return value ? (value instanceof Date ? value : parse(value, DEFAULT_DATE_FORMAT_WITH_TIME, new Date())) : null
}

export const dateFieldFormatterProps = {
   parse: formatDateField,
   format: parseDateField,
}
