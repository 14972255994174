/* eslint-disable no-nested-ternary,consistent-return */
import { getFirstKey } from '@plandok/core'
import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'

import usePrevious from './usePrevios'

interface QueryProps {
   fetchData(...a: any[]): any
   dataKey?: any
   exactData?: boolean
   extractDataFunc?: (...a: any[]) => any
   onInitialLoad?: (...a: any[]) => any
   skipMountFetch?: boolean
   filterParams?: any
   blocked?: boolean

   // currently it fixes 2 calls on initial load, but since this
   // hook used everywhere introduced it in one place
   tempFix?: boolean
}

export default function useQuery({ filterParams, ...props }: QueryProps) {
   const [init, setInitial] = useState(false)
   const [isLoading, setIsLoading] = useState(false)
   const [data, setData] = useState(null)
   const prevFilterParams = usePrevious(filterParams)
   const prevBlocked = usePrevious(props.blocked)
   const { fetchData, dataKey, exactData, extractDataFunc, onInitialLoad, blocked, tempFix } = props

   const fetchDataAction = (isInitialLoad?: boolean) => {
      if (!fetchData || blocked) {
         return
      }
      setIsLoading(true)
      return fetchData(filterParams).then((e: any) => {
         if (isInitialLoad && onInitialLoad) {
            const loadCode = onInitialLoad(e)
            setInitial(init)
            if (loadCode === 'retry') {
               setInitial(false)
               setIsLoading(false)
               return
            }
         }
         let result = exactData ? e : dataKey ? e[dataKey] : getFirstKey(e)
         if (extractDataFunc) {
            result = extractDataFunc(e)
         }
         setIsLoading(false)
         setData(result)
      })
   }

   useEffect(() => {
      if (!props.skipMountFetch && !isLoading && !blocked && !init) {
         fetchDataAction(true)
      }
      // eslint-disable-next-line
   }, [blocked, init])

   useEffect(() => {
      if (!isLoading && (tempFix ? init : !init)) {
         if (!isEqual(prevFilterParams, filterParams) || prevBlocked !== blocked) {
            fetchDataAction(false)
         }
      }
      // eslint-disable-next-line
   }, [filterParams, blocked, prevFilterParams, prevBlocked, isLoading, init])

   return {
      data,
      isLoading,
      fetchData: fetchDataAction,
   }
}
