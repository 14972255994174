import React from 'react'

import SetupForm from '../SetupForm'

export default function SetupLastVisitForm() {
   return (
      <SetupForm
         checkBoxLabel="input.enableLastVisit.label"
         checkBoxDescription="input.enableLastVisit.description"
         notificationId="last_visit"
         reminder
         lastVisit
      />
   )
}
