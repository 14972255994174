import { formatTime } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlDate, IntlMoney } from '@plandok/i18n'
import { RoutePath } from 'constants/routes'
import { format, parse } from 'date-fns'
import { formatDuration } from 'helpers/date'
import { DEFAULT_DATE_FORMAT } from 'helpers/date/field'
import React, { useCallback } from 'react'
import { IClientAppointment } from 'types/api'

import { Status } from './Status'
import * as SC from './styles'

type AppointmentItemProps = {
   value?: IClientAppointment
   noBorder?: boolean
   noPadding?: boolean
   redirectOnClick?: boolean
   backPath?: string
   timeFormat: string
}

export default function AppointmentItem({
   value,
   noBorder,
   noPadding,
   redirectOnClick,
   backPath,
   timeFormat,
}: AppointmentItemProps) {
   const { navigate } = useLocation()

   const onClick = useCallback(() => {
      if (redirectOnClick) {
         navigate(RoutePath.APPOINTMENT_DETAILS, value?.appointmentId, backPath ? { backPath } : {})
      }
   }, [navigate, value, redirectOnClick, backPath])

   const date = value?.appointmentDate ? parse(value?.appointmentDate, DEFAULT_DATE_FORMAT, new Date()) : new Date()

   const hasEmployeeDisplayName = value?.employeeFirstName || value?.employeeLastName
   const employeeDisplayName = `${value?.employeeFirstName ?? ''} ${value?.employeeLastName ?? ''}`

   return (
      <SC.AppointmentItem noBorder={noBorder} noPadding={noPadding} onClick={onClick}>
         <div data-appointment-item-date>
            <div>{format(date, 'dd')}</div>
            <div>
               <IntlDate date={date} dateFormat="MMM" />
            </div>
            <div>
               <IntlDate date={date} dateFormat="Y" />
            </div>
         </div>
         <div data-appointment-item-info>
            <div>
               <IntlDate date={date} dateFormat="EEE" />
               &nbsp;
               {formatTime(value?.startTime as number, timeFormat)}
               <Status status={value?.status ?? 'completed'} />
            </div>
            <div>
               {value?.serviceName}
               {hasEmployeeDisplayName ? ', ' : ''}
               {employeeDisplayName}
            </div>
            <div>{value?.duration != null && formatDuration(value?.duration)}</div>
         </div>
         <div data-appointment-item-amount>
            <IntlMoney value={value?.price as number} />
         </div>
      </SC.AppointmentItem>
   )
}
