import { IntlLabel } from '@plandok/i18n'
import React from 'react'

export default function ServicesMobileHeader() {
   return (
      <>
         <div />
         <div>
            <IntlLabel label="sidebar.service" />
         </div>
         <div />
      </>
   )
}
