import { Card, mediaMdDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const ElementItem = styled.div<{ isLastItem: boolean }>`
   padding: 20px 0 12px;

   a {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
   }

   div {
      line-height: 19px;
   }

   border-bottom: ${({ isLastItem }) => (isLastItem ? 'none' : '1px solid #e8e7e7')};

   ${mediaMdDown(css`
      padding: 8px 0 12px;
      a {
         font-size: 16px;
         line-height: 19px;
      }
      div {
         font-size: 14px;
         line-height: 17px;
      }
   `)}
`

export const SettingCard = styled(Card)`
   padding: 30px 40px ${({ theme }) => (theme.account ? '53px' : '18px')};
   box-shadow: 0 2px 10px rgba(38, 60, 122, 0.2);
   border-radius: 5px;
   border: none;

   margin-bottom: 20px;

   ${mediaMdDown(css`
      padding: 8px 16px;
      box-shadow: none;

      :nth-last-of-type(2) {
         margin-bottom: 0;
      }
   `)}
`
