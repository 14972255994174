import { Field } from '@plandok/core'
import * as api from 'api'
import React from 'react'

import withModifyModal from '../withModifyModal'

function CreateCancellationModal() {
   return <Field.Input name="name" label="input.name.label" placeholder="input.cancelReason.placeholder" />
}

export default withModifyModal({
   label: 'modal.newCancelReason.title',
   editLabel: 'modal.newCancelReason.title',
   entityName: 'cancel reason',
   entityNameField: 'name',
   deleteAction: api.deleteCancelReason,
   detailsAction: api.fetchCancelReasonDetails,
   createAction: api.createCancelReason,
   editAction: api.editCancelReason,
   excludeList: ['createdAt'],
})(CreateCancellationModal)
