import { PermissionLevel, PermissionType } from 'constants/auth'

export const extractPermissionsInitialValues = (data: any) => {
   if (!data) {
      return {}
   }

   const permissionObj = data.reduce(
      (acc: any, e: any) => ({
         ...acc,
         [e.permission_level]: e.sections,
      }),
      {},
   )
   return Object.values(PermissionType).reduce((acc, k) => {
      return {
         ...acc,
         [k]: Object.values(PermissionLevel).filter(pL => permissionObj[pL]?.includes(k)) || [],
      }
   }, {})
}

export const formatPermissionRequestParams = (form: any) => {
   return Object.values(PermissionLevel)
      .filter(e => e !== PermissionLevel.OWNER)
      .reduce(
         (acc, pL) => ({
            ...acc,
            [pL]: Object.values(PermissionType).filter(pT => form[pT]?.includes(pL)),
         }),
         {},
      )
}
