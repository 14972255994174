import Icon from '@ant-design/icons'
import {
   Button,
   Field,
   Form,
   getIsMobile,
   Text,
   useLocation,
   useModal,
   validateEmail,
   validateRequired,
} from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Col, Divider } from 'antd'
import { ModifyMassLayout } from 'components'
import { RoutePath } from 'constants/routes'
import flow from 'lodash/flow'
import noop from 'lodash/noop'
import React from 'react'

import * as SC from '../styles'
import ChangePhoto from './ChangePhoto'
import { defaultMessage, socialData } from './config'
import PreviewPhoto from './PreviewPhoto'
import SocialMediaIcon from './SocialMediaIcon'

export default function EmailMessageForm(props: any) {
   const { goBack } = useLocation()
   const isMobile = getIsMobile()
   const [, { showModal }] = useModal()
   const { navigate } = useLocation()

   const clientSelection = () => navigate(RoutePath.CLIENT_SELECTION)

   const sendMessage = () =>
      showModal({
         type: 'SEND_MESSAGE_MODAL',
         modalProps: { Number },
      })

   const handleSubmit = (form: any) =>
      props.onSubmit({
         ...form,
         price: form.price || 0,
      })

   return (
      <Form
         onSubmit={handleSubmit}
         onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
         initialValues={props.initialValues}>
         {({ formError, values, submitting }: any) => {
            const { enabled, enableButton, enableSocial } = values

            return (
               <>
                  <SC.CardContainer>
                     <Field.Row style={{ margin: 0 }}>
                        <Col span={24} md={10}>
                           <SC.ScrollContent>
                              <SC.Text>
                                 <Text label="Email settings" bold />
                              </SC.Text>
                              <Field.Input
                                 name="emailSubject"
                                 label="input.emailSubject.label"
                                 placeholder="input.emailSubject.label"
                                 validate={validateRequired}
                              />
                              <Field.Input
                                 name="messageTitle"
                                 label="Message title"
                                 placeholder="Type email subject"
                                 defaultValue="Text title"
                                 validate={validateRequired}
                              />
                              <Field.TextArea
                                 name="message"
                                 label="Your message"
                                 placeholder="Type your message"
                                 defaultValue={defaultMessage}
                                 rows="4"
                              />
                              <Field.Input
                                 name="email"
                                 label="Reply to email"
                                 placeholder="input.email.placeholder"
                                 validate={validateEmail}
                              />
                              <SC.EmailContainer>
                                 <Text label="Client replies will be send to this email" size="small" mb="none" />
                              </SC.EmailContainer>
                              <SC.CheckboxList>
                                 <Text label="Photo" size="mlarge" weight="semiBold" mb="none" />
                                 <Field.Checkbox
                                    name="enabled"
                                    textKey="Enable photo"
                                    isSwitch
                                    infoCheckbox
                                    noLabelHolder
                                 />
                                 {!enabled && <ChangePhoto />}
                              </SC.CheckboxList>
                              <SC.CheckboxList>
                                 <Text label="Button" size="mlarge" weight="semiBold" mb="none" />
                                 <Field.Checkbox
                                    name="enableButton"
                                    textKey="Enable button"
                                    isSwitch
                                    infoCheckbox
                                    noLabelHolder
                                 />
                                 {!enableButton && (
                                    <>
                                       <Field.Input
                                          name="buttonName"
                                          label="Button name"
                                          placeholder="input.emailSubject.label"
                                          defaultValue="Take special deal"
                                       />
                                       <Field.Input
                                          name="buttonURL"
                                          label="Button URL"
                                          placeholder="www.somelink.com"
                                          defaultValue="www.somelink.com"
                                       />
                                    </>
                                 )}
                              </SC.CheckboxList>
                              <SC.CheckboxList>
                                 <Text label="Social media" size="mlarge" weight="semiBold" mb="none" />
                                 <Field.Checkbox
                                    name="enableSocial"
                                    textKey="Enable social media links"
                                    isSwitch
                                    infoCheckbox
                                    noLabelHolder
                                 />
                                 {!enableSocial && (
                                    <SC.enabledSocialList>
                                       {socialData &&
                                          socialData.map((item, index) => (
                                             <Field.SocialInput
                                                key={index}
                                                name={item.name}
                                                label={item.label}
                                                iconType={item.icon}
                                                placeholder={item.value}
                                             />
                                          ))}
                                    </SC.enabledSocialList>
                                 )}
                              </SC.CheckboxList>
                           </SC.ScrollContent>
                        </Col>
                        <Col span={24} md={12}>
                           <div className="mt-2">
                              <Text label="Message preview" size="base" mb="small" isCenter />
                           </div>
                           <SC.MessagePreviewContainer>
                              {!enabled && <PreviewPhoto />}
                              <Field.EmailPreInput name="messageTitle" />
                              <Field.EmailPreArea name="message" />
                              {!enableButton && <Field.PreviewBtn name="buttonName" />}
                              {!enableSocial && (
                                 <>
                                    <Divider />
                                    <SC.socialMediaIcon>
                                       {!!values['facebook'] && (
                                          <SocialMediaIcon href={`//${values['facebook']}`} type="facebookCustomIcon" />
                                       )}
                                       {!!values['instagram'] && (
                                          <SocialMediaIcon
                                             href={`//${values['instagram']}`}
                                             type="instagramCustomIcon"
                                          />
                                       )}
                                       {!!values['yourPage'] && (
                                          <SocialMediaIcon href={`//${values['yourPage']}`} type="globeCustomIcon" />
                                       )}
                                    </SC.socialMediaIcon>
                                 </>
                              )}
                           </SC.MessagePreviewContainer>
                           <SC.ActionContainer>
                              <Button
                                 label="page.marketing.btn.sendMeTestMessage"
                                 type="primary"
                                 onClick={sendMessage}
                                 upperCase={false}
                              />
                           </SC.ActionContainer>
                           {isMobile && (
                              <SC.Pricing>
                                 <Icon type="exclamation-circle" /> <IntlLabel label="Pricing" />
                              </SC.Pricing>
                           )}
                        </Col>
                     </Field.Row>
                  </SC.CardContainer>
                  <Form.Alert text={formError} />
                  {!isMobile ? (
                     <ModifyMassLayout.Footer>
                        <Button
                           block
                           type="primary"
                           label="Client selection"
                           htmlType="submit"
                           loading={submitting}
                           upperCase={false}
                           onClick={clientSelection}>
                           <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                        </Button>
                     </ModifyMassLayout.Footer>
                  ) : (
                     <ModifyMassLayout.Footer>
                        <Button block type="primary" ghost upperCase={false} onClick={goBack}>
                           <Icon type="arrow-left" style={{ marginRight: '10px' }} />
                           <IntlLabel label="Previous" />
                        </Button>
                        <Button
                           block
                           label="Next"
                           type="primary"
                           upperCase={false}
                           loading={submitting}
                           onClick={clientSelection}>
                           <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                        </Button>
                     </ModifyMassLayout.Footer>
                  )}
               </>
            )
         }}
      </Form>
   )
}
