import { message } from 'antd'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { useCallback, useMemo, useState } from 'react'

import { queryCall } from '../../../../graphql'

export interface TableStoreInitializeProps {
   gqlQuery?: any
   apiType?: 'rest' | 'gql'
   magicMinus?: boolean
   fetchData: (a: any) => any
   actualOffset?: boolean
   mapping?: {
      pageKey: string
      limitKey?: string
      dataKey: string
      totalCountKey: string
      searchKey?: string
      maxCountKey?: string
      emptyStateKey?: string
   }
}

export interface UseTableStoreType {
   initialized: boolean
   error: string
   data: { [key: string]: any }[]
   count: number
   page: number
   showPlaceholder: boolean
   totalCount: number
   maxCount: number
   hasMore: boolean
   isLoading: boolean
   pageChange: (page: number) => Promise<void>
   fetchFreshData: (data?: any) => Promise<void>
   fetchDataDebounced: (query?: string) => any
   fetchData: (page?: number, freshData?: any) => Promise<void>
   clearCache: () => void
   allData: () => any
}

const useTableStore = (initialProps: TableStoreInitializeProps): UseTableStoreType => {
   const [magicMinus] = useState(initialProps.magicMinus ?? false)
   const [page, setPage] = useState(1)
   const [maxCount] = useState(10)
   const [totalCount, setTotalCount] = useState(0)
   const [isLoading, setIsLoading] = useState(false)
   const [initialized, setInitialized] = useState(false)
   const [error] = useState('')
   const [searchStr, setSearchStr] = useState('')
   const [debouncedQuery] = useState('')
   const [actualOffset] = useState(initialProps.actualOffset ?? false)
   const [isNodeData, setIsNodeData] = useState(initialProps?.apiType === 'gql')
   const [mapping] = useState<{
      pageKey: string
      limitKey?: string
      dataKey: string
      totalCountKey: string
      searchKey?: string
      maxCountKey?: string
      emptyStateKey?: string
   }>(initialProps.mapping || ({ pageKey: 'page', dataKey: 'data' } as any))
   const [data, setData] = useState<{ [key: string]: any[] }>({})
   const [showPlaceholder, setShowPlaceholder] = useState(false)
   const [gqlParams, setGqlParams] = useState<any>({})

   const fetchDataAction =
      initialProps?.apiType === 'gql' ? queryCall(initialProps.gqlQuery, { ...gqlParams }) : initialProps.fetchData

   const getSearchStrCachePart = () => (searchStr ? `:query${searchStr}` : '')

   const getCacheKey = (page: number) => `${page}${getSearchStrCachePart()}`

   const allData = useMemo(() => {
      if (searchStr) {
         return Object.keys(data)
            .filter(e => e.includes(getSearchStrCachePart()))
            .sort(
               (a, b) =>
                  parseInt(a.replace(getSearchStrCachePart(), ''), 10) -
                  parseInt(b.replace(getSearchStrCachePart(), ''), 10),
            )
            .reduce((acc, k) => [...acc, ...(data[k] || [])], [] as any)
      }
      return Object.values(data).reduce((acc, a) => [...(acc || []), ...(a || [])], []) || []
   }, [data, searchStr])

   const clearCache = () => setData({})

   const count = useMemo(() => data[getCacheKey(page)]?.length ?? 0, [data, page])

   const hasMore = useMemo(() => totalCount > allData.length, [totalCount, allData])

   const currentData = useMemo(() => {
      if (data[page]) {
         let index = 0
         return (data[page] || []).map(v => ({ ...v, key: index++ }))
      }
      return []
   }, [data, page])

   const extractGqlParams = (resp: any) => {
      if (resp.pageInfo) {
         gqlParams.after = resp.pageInfo.endCursor
         gqlParams.before = resp.pageInfo.startCursor
      }
   }

   const extractData = (resp: any) => {
      const getResData = get(resp, mapping.dataKey)
      if (Boolean(getResData?.edges)) {
         extractGqlParams(getResData)
         return get(resp, mapping.dataKey)?.edges?.map(({ node }: any) => {
            setIsNodeData(true)
            if (Boolean(node.firstName)) {
               return { ...node, user: `${node.firstName} ${node.lastName || ''}` }
            }
            if (
               Boolean(node.clientFirstName) ||
               Boolean(node.cancelledByFirstName) ||
               Boolean(node.employeeFirstName)
            ) {
               return {
                  ...node,
                  client: `${node.clientFirstName || ''} ${node.clientLastName || ''}`,
                  employee: `${node.employeeFirstName || ''} ${node.employeeLastName ?? ''}`,
                  cancelledBy: `${node.cancelledByFirstName || ''} ${node.cancelledByLastName ?? ''}`,
               }
            }

            return node
         })
      }
      return getResData
   }

   const extractTotalCount = (resp: any) => get(resp, mapping.totalCountKey) ?? 0

   const getFilterParams = useCallback(
      (page: number, searchStr?: string) => {
         const result: any = { [mapping.pageKey]: page }
         if (magicMinus) {
            result[mapping.pageKey] -= 1
         }
         if (actualOffset) {
            result[mapping.pageKey] = result[mapping.pageKey] * maxCount
         }
         if (mapping.searchKey && searchStr) {
            result[mapping.searchKey] = searchStr
         }
         if (mapping.limitKey && maxCount) {
            result[mapping.limitKey] = maxCount
         }
         if (mapping.maxCountKey) {
            result[mapping.maxCountKey] = maxCount
         }

         return result
      },
      [magicMinus, actualOffset, mapping, searchStr, maxCount],
   )

   const filterGqlDataBySearchParams = (result: any, query?: string) => {
      if (query) {
         const filteredResult =
            result?.[mapping.dataKey]?.edges?.filter((item: any) =>
               Boolean(
                  item?.node?.clientFirstName?.toLowerCase().includes(query.toLowerCase()) ||
                     item?.node?.clientLastName?.toLowerCase().includes(query.toLowerCase()),
               ),
            ) ?? []

         return {
            ...result,
            [mapping.dataKey]: { edges: filteredResult, totalCount: filteredResult.length },
         }
      } else {
         return result
      }
   }

   const fetchData = useCallback(
      async (page: number = 1, freshData?: any, searchStr?: string) => {
         if (isLoading) return

         if (1 !== page && data[getCacheKey(page)]) {
            setPage(page)
            return
         }

         setIsLoading(true)

         try {
            let resp =
               freshData && typeof freshData !== 'number'
                  ? filterGqlDataBySearchParams(freshData, debouncedQuery)
                  : await fetchDataAction(getFilterParams(page, searchStr))

            const data = extractData(resp)

            setTotalCount(extractTotalCount(resp))

            if (mapping?.emptyStateKey) {
               setShowPlaceholder(!!get(resp, mapping?.emptyStateKey))
            } else {
               if (!totalCount && !data?.length) {
                  setShowPlaceholder(true)
               }
            }

            setData(prevData => ({
               ...prevData,
               [page]: data,
            }))
            setPage(page)
            setIsLoading(false)

            if (!initialized) {
               setInitialized(true)
            }
         } catch (e) {
            console.log(e)
            message.error('Failed to load data, please contact support')
            setIsLoading(false)
         }
      },
      [
         data,
         initialized,
         mapping,
         totalCount,
         isLoading,
         gqlParams,
         getCacheKey,
         getFilterParams,
         fetchDataAction,
         debouncedQuery,
      ],
   )

   const fetchDataDebounced = useCallback(
      debounce(async (query?: string) => {
         setPage(1)
         setSearchStr(query || '')

         if (!isNodeData) {
            clearCache()
         }

         await fetchData(1, undefined, query)
      }, 50),
      [fetchData, isNodeData],
   )

   const fetchFreshData = useCallback(
      async (data?: any) => {
         clearCache()
         setGqlParams({})
         await fetchData(page, data)
      },
      [fetchData, page],
   )

   const pageChange = useCallback(async (page: any) => await fetchData(page), [fetchData])

   return {
      initialized,
      showPlaceholder,
      error,
      data: currentData,
      count,
      page,
      totalCount,
      maxCount,
      hasMore,
      isLoading,
      pageChange,
      fetchFreshData,
      fetchDataDebounced,
      fetchData,
      allData,
      clearCache: () => setData({}),
   }
}

export default useTableStore
