import styled from 'styled-components'

import { AnalyticsCard } from '../../styles'

export const CancellationReasons = styled(AnalyticsCard)<any>`
   grid-area: cancellation-reasons;
   background: #fff;
   padding: 30px;
` as any

export const TotalStatus = styled.div`
   display: grid;
   grid-template-columns: 1fr auto;
`

export const Inliner = styled.div`
   display: flex;
   justify-content: flex-end;
`

export const TextColumn = styled.div`
   div {
      width: 12rem;
      white-space: pre-line;
      overflow: hidden;
   }
`

export const NumbersColumn = styled.div`
   justify-content: center;
`

export const Divider = styled.div`
   margin: 5px 0 !important;
`
