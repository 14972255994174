import { CustomIcon, Text, useModal } from '@plandok/core'
import { useGoogleLogin } from '@react-oauth/google'
import { message, notification, Spin } from 'antd'
import * as api from 'api'
import React, { useEffect, useState } from 'react'

import CalendarItemComponent from './components/CalendarItemComponent'
import * as SC from './styles'

type CalendarSyncProps = {
   staffPage?: boolean
}

export enum SyncCalendarType {
   GOOGLE_CALENDAR = 'google_calendar',
   MICROSOFT_CALENDAR = 'microsoft_calendar',
   APPLE_CALENDAR = 'apple_calendar',
   CUSTOM_CALENDAR = 'custom_calendar',
}

export default function CalendarSync({ staffPage = false }: CalendarSyncProps) {
   const [, { showModal }] = useModal()
   const [loading, setLoading] = useState(true)
   const [showOptions, setShowOptions] = useState(true)
   const [syncCalendar, setSyncCalendar] = useState({
      isPremium: false,
      isGoogleCalendarSync: false,
      isMicrosoftCalendarSync: false,
      isAppleCalendarSync: false,
      isCustomCalendarSync: false,
   })

   useEffect(() => {
      ;(async () => onFetchCalendarSyncs())()
      return () => {}
   }, [])

   const onFetchCalendarSyncs = async () => {
      setLoading(true)
      let response = await api.fetchCalendarSyncs()
      setLoading(false)

      if (response?.data) {
         setSyncCalendar(response.data)
      }
   }

   const viewUpgradePlanModal = () => showModal({ type: 'UPGRADE_TO_PREMIUM_MODAL' })

   const OptionsBtn = () => (
      <SC.ShowOptionsContainer onClick={() => setShowOptions(showOptions => !showOptions)}>
         <Text label={showOptions ? 'btn.hideOptions' : 'btn.showOptions'} mb="none" size="base" weight="medium" />
         <CustomIcon type={showOptions ? 'arrowUp' : 'arrowDown'} />
      </SC.ShowOptionsContainer>
   )

   const onNotification = (label: string) =>
      notification.open({
         message: (
            <div>
               <CustomIcon type="successLinked" />
               <Text label={label} mb="none" size="base" color="#039855" ml="small" weight="medium" />
            </div>
         ),
         className: 'account-linked-message',
      })

   // const onConnected = (calendarName?: string) => {
   //   switch (calendarName) {
   //     case 'apple':
   //       onNotification(syncCalendar?.microsoftCalendarToken !== null ? 'notification.appleDisconnected' : 'notification.appleConnected');
   //       return;
   //     case 'microsoft':
   //       onNotification(
   //           syncCalendar?.appleCalendarToken !== null ? 'notification.microsoft365Disconnected' : 'notification.microsoft365Connected'
   //       );
   //       return;
   //     default:
   //       onNotification('notification.linkCopied');
   //       return;
   //   }
   // };

   const onConnectGoogle = useGoogleLogin({
      onSuccess: async (codeResponse: any) => {
         setLoading(true)
         let response = await api.connectCalendarSyncs(SyncCalendarType.GOOGLE_CALENDAR, {
            accessToken: codeResponse.code,
         })
         setLoading(false)

         if (response?.errors || response?.error) {
            message.error(response?.errors?.message)
            return
         }

         await onFetchCalendarSyncs()
         onNotification('notification.googleConnected')
      },
      onError: () => message.error('Login failed'),
      flow: 'auth-code',
      scope: 'openid https://www.googleapis.com/auth/calendar',
   })

   const onDisconnectGoogleSync = async () => {
      setLoading(true)
      await api.disconnectCalendarSyncs(SyncCalendarType.GOOGLE_CALENDAR)
      setLoading(false)

      await onFetchCalendarSyncs()
      onNotification('notification.googleDisconnected')
   }

   return (
      <Spin spinning={loading}>
         <SC.CalendarSyncContainer theme={{ background: staffPage ? false : syncCalendar.isPremium }}>
            <div className="justify-align">
               <div>
                  <SC.CalendarSyncTitleContainer>
                     <Text label="calendarSync.title" mb="none" weight="semiBold" />
                     {!syncCalendar.isPremium && (
                        <SC.PremiumFeatureTitleContainer>
                           <div>
                              <CustomIcon type="star" />
                           </div>
                           <Text
                              colorType="yellow"
                              label="title.premiumFeature"
                              mb="none"
                              weight="medium"
                              ml="xxsmall"
                           />
                        </SC.PremiumFeatureTitleContainer>
                     )}
                  </SC.CalendarSyncTitleContainer>
                  <SC.CalendarSyncDescription label="calendarSync.description" />
               </div>

               {!syncCalendar.isPremium && (
                  <SC.UpgradeButton
                     type="primary"
                     label="rp.upgradeToPremiumLink.title"
                     upperCase={false}
                     semiBold
                     className="hidden-md"
                     onClick={viewUpgradePlanModal}
                  />
               )}
            </div>

            {showOptions ? (
               <>
                  <CalendarItemComponent
                     icon="googleIcon"
                     title="googleCalendar.title"
                     disabled={!syncCalendar.isPremium}
                     isConnected={syncCalendar?.isGoogleCalendarSync}
                     btn={syncCalendar?.isGoogleCalendarSync ? 'disconnect.rtn' : 'connect.btn'}
                     action={syncCalendar?.isGoogleCalendarSync ? onDisconnectGoogleSync : onConnectGoogle}
                  />
                  {/*<CalendarItemComponent*/}
                  {/*  icon="apple"*/}
                  {/*  title="appleCalendar.title"*/}
                  {/*  btn={isAppleConnected ? 'disconnect.rtn' : 'connect.btn'}*/}
                  {/*  disabled={isFreePlan}*/}
                  {/*  action={() => onConnected('apple')}*/}
                  {/*  isConnected={isAppleConnected}*/}
                  {/*/>*/}
                  {/*<CalendarItemComponent*/}
                  {/*  icon="microsoft365"*/}
                  {/*  title="microsoft365Calendar.title"*/}
                  {/*  btn={isMicrosoftConnected ? 'disconnect.rtn' : 'connect.btn'}*/}
                  {/*  disabled={isFreePlan}*/}
                  {/*  action={() => onConnected('microsoft')}*/}
                  {/*  isConnected={isMicrosoftConnected}*/}
                  {/*/>*/}
                  {/*<CalendarItemComponent*/}
                  {/*  icon="myLink"*/}
                  {/*  title="linkYourCalendar.title"*/}
                  {/*  btn="Copy link"*/}
                  {/*  disabled={isFreePlan}*/}
                  {/*  action={onConnected}*/}
                  {/*/>*/}

                  {!staffPage && <OptionsBtn />}
               </>
            ) : (
               <OptionsBtn />
            )}

            {syncCalendar.isPremium && (
               <SC.UpgradeButton
                  type="primary"
                  label="rp.upgradeToPremiumLink.title"
                  upperCase={false}
                  semiBold
                  className="visible-md"
                  onClick={viewUpgradePlanModal}
               />
            )}
         </SC.CalendarSyncContainer>
      </Spin>
   )
}
