import React from 'react'

import SetupForm from '../SetupForm'

export default function SetupRemindersForm() {
   return (
      <SetupForm
         checkBoxLabel="input.enableReminder.label"
         checkBoxDescription="input.enableReminder.description"
         notificationId="reminder"
         reminder
      />
   )
}
