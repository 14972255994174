import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import React from 'react'

const Portal = (props: any) => {
   const [isLoaded, setIsLoaded] = useState(false)
   const el = document.createElement('div')
   useEffect(() => {
      document.body.appendChild(el)
      setIsLoaded(true)
      return () => {
         document.body.removeChild(el)
      }
   }, [el])
   if (!isLoaded) {
      return null
   }

   return <div>{ReactDOM.createPortal(props.children, el) || null}</div>
}

export default Portal
