import { Button, CustomIcon, Dropdown, Mutation } from '@plandok/core'
import * as api from 'api'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Status } from './helpers'

const BtnContainer = styled.div<{ margin?: string }>`
   display: flex;
   z-index: 100;
   margin-bottom: 20px;
   button[data-appointment-btn] {
      color: #61749d !important;
      margin: ${({ margin }) => margin || '20px auto'};
      font-weight: 500;
   }

   span {
      margin-right: 12px;
   }
`

export type AppointmentStatus = 'new' | 'cancelled' | 'confirmed' | 'no_show' | 'completed'

export interface AppointmentStatusBtnProps {
   id: string
   status: AppointmentStatus
   onSuccess: () => void
}

export default function AppointmentStatusBtn(props: any) {
   const [isExpanded, setIsExpanded] = useState<boolean>(false)

   const onClick = () => {
      setIsExpanded(!isExpanded)
   }

   return (
      <BtnContainer margin="0px auto">
         <Mutation action={api.changeAppointmentStatus} id={props.id} onSuccess={props.onSuccess}>
            {({ loading, action }: any) => (
               <>
                  <Dropdown
                     onChange={(status: any) => action({ status })}
                     menu={[
                        { id: 'new', element: <Status status="new" /> },
                        { id: 'confirmed', element: <Status status="confirmed" /> },
                        { id: 'completed', element: <Status status="completed" /> },
                        { id: 'no_show', element: <Status status="no_show" /> },
                     ]}>
                     <Button data-appointment-btn ghost loading={loading} upperCase={false} onClick={onClick}>
                        <Status status={props.status} />
                        <span>
                           <CustomIcon type={isExpanded ? 'arrowUp' : 'arrowDown'} />
                        </span>
                     </Button>
                  </Dropdown>
               </>
            )}
         </Mutation>
      </BtnContainer>
   )
}
