import { CustomIcon, getIsMobile, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { message, notification, Spin, Switch } from 'antd'
import * as api from 'api'
import { IPaymentType } from 'pages/dashboard/BookingPage/BookingPayments/controllers/paymentTypeController'
import React, { useState } from 'react'

import * as SC from '../styles'
import StripeDropdown from './StripeDropdown'

type PayCardContentProps = {
   stripePayment: IPaymentType
   onSitePayment: IPaymentType
   controller: any
   changeStatus: (stripe_payment: boolean) => void
}

export default function PayCardContent(props: PayCardContentProps) {
   // const { navigate } = useLocation()
   const [loading, setLoading] = useState(false)
   const isMobile = getIsMobile()

   const onOnboardHandle = async () => {
      setLoading(true)
      let response = await api.onboardPaymentType(props.stripePayment?.paymentType)
      setLoading(false)

      if (response?.error) {
         console.log(response.error?.message)
         message.error(response.error?.message)

         return
      }

      notification.open({
         message: (
            <div className="align-center">
               <CustomIcon type="checkmark" />
               <Text
                  label="ob.connectToStripe.successMessage.title"
                  mb="none"
                  size="small"
                  colorType="base"
                  ml="xsmall"
               />
            </div>
         ),
         closeIcon: <span />,
         className: 'stripe-connect-success-message',
      })

      window.location.href = response.link
   }

   return (
      <Spin spinning={loading}>
         {/*{'free' && (*/}
         {/*  <SC.Commission>*/}
         {/*    <Text label="ob.payWithCard.context.1" size="small" lh="large" marginBottom="16px">*/}
         {/*      <strong> 1% </strong>*/}
         {/*      <IntlLabel label="ob.payWithCard.context.2" />*/}
         {/*    </Text>*/}

         {/*    <Text size="small" lh="large" marginBottom="16px">*/}
         {/*      <SC.Link onClick={() => navigate(RoutePath.BILLING)}>*/}
         {/*        <IntlLabel label="rp.upgradeToPremiumLink.title" />*/}
         {/*      </SC.Link>*/}
         {/*      <IntlLabel label="ob.payWithCard.context.3" />*/}
         {/*      <strong> 0% </strong>*/}
         {/*      <IntlLabel label="ob.payWithCard.context.4" />*/}
         {/*    </Text>*/}

         {/*    <Text size="small" lh="large" mb="none" label="ob.payWithCard.context.5">*/}
         {/*      <a href="https://stripe.com/pricing" target="_blank" rel="noopener noreferrer">*/}
         {/*        <IntlLabel label="ob.payWithCard.context.6" />*/}
         {/*      </a>*/}
         {/*    </Text>*/}
         {/*  </SC.Commission>*/}
         {/*)}*/}

         <SC.SwitchContainer theme={{ payCard: true }}>
            <div className="d-flex">
               <Switch
                  defaultChecked
                  checked={props.stripePayment.isActive}
                  onChange={props.changeStatus}
                  disabled={!props.stripePayment.isOnboard || !props.onSitePayment.isActive}
               />
               <Text
                  color={props.stripePayment.isActive ? '#009329' : '#7183a7'}
                  label={props.stripePayment.isActive ? 'ob.btn.active' : 'ob.btn.inactive'}
                  size="medium"
                  lh="mlarge"
                  mb={isMobile ? 'xsmall' : 'none'}
                  marginLeft="12px"
               />
            </div>
            <Text
               label={props.stripePayment.isActive ? 'ob.stripeConnected.context' : 'ob.requiresSetup.context'}
               size="small"
               lh="base"
               mb="none"
               ml="xxsmall"
            />
         </SC.SwitchContainer>

         {!props.controller?.loading ? (
            props.stripePayment.isOnboard ? (
               <SC.OnBoardContainer>
                  <StripeDropdown controller={props.controller} stripePayment={props.stripePayment} />
                  <SC.StripeDashboardContainer>
                     <Text size="small" lh="large" mb="none" label="ob.stripeDashboard.title" color="#5A6F99">
                        <a href="https://dashboard.stripe.com" target="_blank" rel="noopener noreferrer">
                           <IntlLabel label="ob.stripeDashboard.url" />
                        </a>
                        .
                     </Text>
                  </SC.StripeDashboardContainer>
               </SC.OnBoardContainer>
            ) : (
               <SC.ConnectButton
                  semiBold
                  type="primary"
                  label="ob.connectToStripe.btn"
                  upperCase={false}
                  onClick={onOnboardHandle}
               />
            )
         ) : (
            <SC.WaitingAuthorization>
               <Spin spinning />
               <Text label="ob.waitingAuthorization.title" mb="none" size="medium" lh="mlarge" />
            </SC.WaitingAuthorization>
         )}
      </Spin>
   )
}
