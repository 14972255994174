import { Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Divider } from 'antd'
import { Fragment } from 'react'
import React from 'react'

import { App_TopService } from '../../../../../../graphql/generatedTypes'
import * as StyledCommon from '../../styles'
import { NoData } from '../NoData/NoData'
import * as SC from './styles'

type TopServicesProps = {
   services: App_TopService[]
}

export const TopServices = ({ services }: TopServicesProps) => (
   <SC.TopServices>
      <Text size="mlarge" weight="bold" colorType="base" mb="xsmall">
         <IntlLabel label="analytics.topServices.title" />
      </Text>
      <StyledCommon.ScrollableContent maxHeight={25}>
         <SC.GridContent>
            <SC.ServiceName>
               <Text size="small" weight="semiBold" colorType="base" mb="small">
                  <IntlLabel label="analytics.service.title" />
               </Text>
               <SC.Divider as={Divider} />
            </SC.ServiceName>
            <SC.ThisMonthTitle>
               <Text size="small" weight="semiBold" colorType="base" mb="small">
                  <IntlLabel label="analytics.thisMonth.title" />
               </Text>
               <SC.Divider as={Divider} />
            </SC.ThisMonthTitle>
            {!!services?.length &&
               services?.map(service => (
                  <Fragment key={service.id}>
                     <SC.TextColumn>
                        <Text size="base" weight="normal" colorType="base" mb="small">
                           {service.name}
                        </Text>
                     </SC.TextColumn>
                     <SC.ThisMonthColumn>
                        <Text size="base" weight="semiBold" colorType="base" mb="small">
                           {service.bookingCount}
                        </Text>
                     </SC.ThisMonthColumn>
                  </Fragment>
               ))}
         </SC.GridContent>
      </StyledCommon.ScrollableContent>
      {!services?.length && <NoData />}
   </SC.TopServices>
)

export default TopServices
