import { useAppContext } from '@plandok/core'
import * as api from 'api'
import { AppSetupList, InfoPlaceholder, NoInternetConnection } from 'components'
import React from 'react'

import { locationColumns } from './config'

export default function LocationsPage() {
   const [context] = useAppContext()

   return (
      <NoInternetConnection>
         <AppSetupList
            btnLabel="btn.new.location"
            columns={locationColumns}
            title="title.locations"
            modalType={context.subscription?.canAddLocation ? 'CREATE_LOCATION' : 'PREMIUM_UPGRADE_REQUIRED'}
            initialValues={{ locationPage: true }}
            fetchData={api.fetchLocations}
            onPositionUpdate={async (id: string, newPosition: number) =>
               await api.changeLocationPosition('', { id, newPosition })
            }
            infoPlaceholder={
               <InfoPlaceholder
                  imgName="locations"
                  titleLabel="state.locations.title"
                  descriptionLabel="state.locations.description"
               />
            }
         />
      </NoInternetConnection>
   )
}
