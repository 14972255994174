import { Button, getIsMobile, Text, useLocation, withModal } from '@plandok/core'
import { RoutePath } from 'constants/routes'
import React from 'react'

import * as SC from '../styles'

type PremiumDeactivatedModalProps = {
   modalProps: {
      type: string
   }
   hideAllModals: () => void
   onClose: () => void
}

function PremiumDeactivatedModal({ modalProps, hideAllModals, onClose }: PremiumDeactivatedModalProps) {
   const { navigate } = useLocation()
   const isMobile = getIsMobile()

   const dismiss = () => {
      sessionStorage.setItem('isPremiumDeactivatedModalDismiss', 'true')
      onClose()
   }

   const goToBillingPage = () => {
      hideAllModals()
      navigate(RoutePath.BILLING)
   }

   return (
      <>
         <Text
            size="base"
            mb={isMobile ? 'xmedium' : 'large'}
            lh="xlarge"
            label={
               modalProps.type === 'subscription_cancelled'
                  ? 'subscriptionCancelled.title'
                  : 'notificationCancelled.title'
            }
         />

         <SC.BtnsWrapper>
            <SC.BtnDismiss label="payment.dismiss.title" onClick={dismiss} upperCase={false} whiteBtn semiBold />
            <Button
               label="plan.active.title"
               onClick={goToBillingPage}
               upperCase={false}
               semiBold
               style={{ background: '#349447' }}
            />
         </SC.BtnsWrapper>
      </>
   )
}

export default withModal('plan.deactivated.title', { contentClassName: 'payment-error-modal' })(PremiumDeactivatedModal)
