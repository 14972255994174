import { StateCalendar } from 'pages/dashboard/CalendarPage/constants'

import { ICalendarProps } from '../../EpicCalendar'
import theme from '../../styles/theme'
import { ModeType } from '../types'

export const getCalendarHoursByViewType = (
   startTime: number,
   endTime: number,
   customViewType: string,
   workingHoursStartTime: number,
   workingHoursEndTime: number,
) => {
   switch (customViewType) {
      case StateCalendar.CUSTOM_RANGE:
         return Math.ceil((endTime - startTime) / 60)
      case StateCalendar.WORKING_HOUR:
         return isFinite((workingHoursEndTime - workingHoursStartTime) / 60)
            ? (workingHoursEndTime - workingHoursStartTime) / 60
            : 24
      case StateCalendar.FULL_CALENDAR:
         return 24
      default:
         return 24
   }
}

const calculateColumnHeight = (calendarHours: number, timeInterval: number) => {
   const timeSlotHeight = timeInterval >= 15 ? theme.baseSlotHeight * 2 : theme.baseSlotHeight
   const hourHeight = timeInterval >= 15 ? timeSlotHeight * 2 : theme.baseSlotHeight * (60 / timeInterval)

   return {
      columnHeight: calendarHours * hourHeight + theme.calendarHeaderHeight,
      timeSlotHeight,
      hourHeight,
   }
}

const calculateColumnWidth = (dataLength: number, modeType: ModeType, containerWidth: number) => {
   const visibleContainerWidth = containerWidth - theme.timeColumnWidth - theme.scrollbarWidth
   const minWidth = modeType === 'week' ? theme.columnWeekMinWidth : theme.columnMinWidth

   if (visibleContainerWidth / dataLength >= minWidth) {
      return visibleContainerWidth / dataLength
   }

   const nearVisibleCount = visibleContainerWidth / minWidth

   return Math.max(minWidth, visibleContainerWidth / Math.floor(nearVisibleCount))
}

export const getThemeConfig = (props: ICalendarProps, containerWidth: number) => {
   const calendarHours = getCalendarHoursByViewType(
      props.customViewTime?.startTime,
      props.customViewTime?.endTime,
      props.customViewType,
      props.workingHoursStartTime,
      props.workingHoursEndTime,
   )

   return {
      ...theme,
      ...calculateColumnHeight(calendarHours, props.timeInterval),
      columnWidth: calculateColumnWidth(props.data?.length, props.modeType, containerWidth),
   }
}

export const getHourCellCount = (timeInterval: number) => (timeInterval === 30 ? 2 : 60 / timeInterval)

export const getTopByViewType = (
   calendarStartTime: number,
   customViewType: string,
   staffStartTime: number,
   hourHeight: number,
   workingHoursStartTime: number,
) => {
   const fullCalendar = theme.calendarHeaderHeight + (staffStartTime / 60) * hourHeight

   switch (customViewType) {
      case StateCalendar.CUSTOM_RANGE:
         const skipCustomCalendarHours = calendarStartTime / 60

         return fullCalendar - skipCustomCalendarHours * hourHeight
      case StateCalendar.WORKING_HOUR:
         const skipWorkingCalendarHours = workingHoursStartTime / 60

         return fullCalendar - skipWorkingCalendarHours * hourHeight
      case StateCalendar.FULL_CALENDAR:
         return fullCalendar
      default:
         return fullCalendar
   }
}

export const getHeight = (
   customViewType: string,
   staffEndTime: number,
   staffStartTime: number,
   calendarEndTime: number,
   hourHeight: number,
) => {
   if (customViewType === StateCalendar.CUSTOM_RANGE && staffEndTime > calendarEndTime) {
      return hourHeight * ((calendarEndTime - staffStartTime) / 60)
   }
   return hourHeight * ((staffEndTime - staffStartTime) / 60)
}
