import { startOfDayLocalized } from '@plandok/core'
import { addMinutes, format } from 'date-fns'
import range from 'lodash/range'

export const getDayTime = (date: Date): number => (date.getTime() - startOfDayLocalized(date).getTime()) / (60 * 1000)

export const formatTime = (time: number, timeFormat: string = 'HH:mm'): any =>
   format(addMinutes(startOfDayLocalized(new Date()), time), timeFormat)

export const getColumnTime = (hourOffsetIndex: number, minutesOffsetIndex: number, timeInterval: number) =>
   hourOffsetIndex * 60 + minutesOffsetIndex * timeInterval

export const getColumnTimeForFullCalendar = (
   hourOffsetIndex: number,
   minutesOffsetIndex: number,
   timeInterval: number,
) => hourOffsetIndex * 60 + minutesOffsetIndex * timeInterval

export const getColumnFormatTime = (
   hourOffsetIndex: number,
   minutesOffsetIndex: number,
   timeInterval: number,
   timeFormat: string,
) => {
   return format(
      addMinutes(startOfDayLocalized(new Date()), getColumnTime(hourOffsetIndex, minutesOffsetIndex, timeInterval)),
      timeFormat === '12h' ? 'h:mm a' : 'HH:mm',
   )
}

export const getColumnFormatTimeForCalendar = (
   hourOffsetIndex: number,
   minutesOffsetIndex: number,
   timeInterval: number,
   timeFormat: string,
   count: number,
) => {
   const columnTimeForCalendar = getColumnTimeForCalendar(hourOffsetIndex, minutesOffsetIndex, timeInterval, count)
   if (columnTimeForCalendar === undefined) {
      return null
   }
   const textMinutes = addMinutes(startOfDayLocalized(new Date()), columnTimeForCalendar || 0)
   return format(textMinutes, timeFormat === '12h' ? 'h:mm a' : 'HH:mm')
}

export const getColumnTimeForCalendar = (startTime: number, endTime: number, timeInterval: number, count: number) => {
   const rangeTime = range(startTime, endTime + timeInterval, timeInterval)

   return rangeTime[count]
}
