/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
   'login.title': 'In mein Konto anmelden',
   'label.email': 'E-Mail-Adresse',
   'placeholder.email': 'z.B. johndoe@gmail.com',
   'input.password.label': 'Passwort',
   'input.password.placeholder': 'Passwort eingeben',
   'login.btn': 'Anmelden in Plandok',
   'link.sign.in': 'Anmelden',
   'label.password': 'Passwort eingeben',
   'login.forgot.password': 'Passwort vergessen',
   'login.no.account': 'Haben Sie schon ein Konto?',
   'login.description': 'Wenn Sie Probleme mit der Anmeldung haben, versuchen Sie bitte Ihr Passwort zurückzusetzen',
   'register.title': 'Ein Konto anlegen',
   'register.description': 'Erstellen Sie ein kostenloses Konto auf Plandok Terminverwaltungs- und Buchungssoftware',
   'register.btn': 'Mein Konto anlegen',
   'register.agreement.agree': 'Ich stimme zu',
   'register.agreement.terms': 'Website Bedingungen',
   'register.agreement.policy': 'Datenschutzrichtlinie',
   'register.agreement.and': 'und',
   'register.agreement.partner': 'Partner Geschäftsbedingungen',
   'register.have.account': 'Haben Sie bereits ein Konto? ',
   'register.validation.confirm':
      'Bevor Sie fortfahren, sollten Sie bitte die folgenden Nutzungsbedingungen lesen und akzeptieren.',
   'link.sign.up': 'Registrierung',
   'text.alreadyHaveAccount': 'Haben Sie bereits ein Konto? ',
   'input.company.label': 'Firma',
   'input.company.placeholder': 'Firma',
   'input.country.label': 'Land',
   'input.country.placeholder': 'Ländername',
   'input.timeZone.label': 'Zeitzone',
   'input.timeZone.placeholder': 'GMT +02.00',
   'input.currency.label': 'Währung',
   'input.language.label': 'Sprache (Language)',
   'input.language.placeholder': 'Englisch',
   'input.currency.placeholder': 'Euro - EUR',
   'reset.title': 'Passwort zurücksetzen',
   'reset.btn.text': 'Passwortzurücksetzung beantragen',
   'reset.pwd.btn': 'Passwort zurücksetzen',
   'reset.no.password': 'Möchten Sie Ihr Passwort nicht zurücksetzen? ',
   'reset.description':
      'Geben Sie Ihr registrierte E-Mail-Adresse ein, um eine gesicherte Link zum Passwortänderung zu erhalten',
   'sidebar.calendar': 'Kalender',
   'sidebar.client': 'Kunden',
   'sidebar.staff': 'Mitarbeiter',
   'sidebar.service': 'Dienstleistungen',
   'sidebar.notification': 'Nachricht',
   'sidebar.settings': 'Einstellungen',
   'sidebar.menu': 'Menüpunkt',
   'sidebar.analytics': 'Analytics',
   'sidebar.language': 'Sprache (Language)',
   'sidebar.logout': 'Abmeldung',
   'user.menu.settings': 'Meine Einstellungen',
   'user.menu.language': 'Sprache ändern',
   'user.menu.logout': 'Abmelden',
   'input.globalSearch.placeholder': 'Wonach suchen Sie?',
   'input.globalSearch.description': 'Nach Kundenname, Handynummer, E-Mail oder Buchungsreferenz suchen',
   'title.upcomingAppointments': 'nächstfolgende Termine',
   'calendar.day': 'Tag',
   'calendar.week': 'Woche',
   'calendar.monday': 'Montag',
   'calendar.tuesday': 'Dienstag',
   'calendar.wednesday': 'Mittwoch',
   'calendar.thursday': 'Donnerstag',
   'calendar.friday': 'Freitag',
   'calendar.saturday': 'Samstag',
   'calendar.sunday': 'Sonntag',
   'calendar.mon': 'Mo',
   'calendar.tue': 'Di',
   'calendar.wed': 'Mi',
   'calendar.thu': 'Do',
   'calendar.fri': 'Fr',
   'calendar.sat': 'Sa',
   'calendar.sun': 'So',
   'calendar.today': 'Heute',
   'calendar.january': 'Januar',
   'calendar.february': 'Februar',
   'calendar.march': 'März',
   'calendar.april': 'April',
   'calendar.may': 'Mai',
   'calendar.june': 'Juni',
   'calendar.july': 'Juli',
   'calendar.august': 'August',
   'calendar.september': 'September',
   'calendar.october': 'Oktober',
   'calendar.november': 'November',
   'calendar.december': 'Dezember',
   'calendar.jan': 'Jan.',
   'calendar.feb': 'Feb.',
   'calendar.mar': 'März',
   'calendar.apr': 'Apr.',
   'calendar.may.short': 'May',
   'calendar.jun': 'Jun.',
   'calendar.jul': 'Jul.',
   'calendar.aug': 'Aug.',
   'calendar.sept': 'Sept.',
   'calendar.oct': 'Okt.',
   'calendar.nov': 'Nov.',
   'calendar.dec': 'Dez.',
   'calendar.allStaff': 'Alle Mitarbeiter',
   'calendar.workingStaff': 'Arbeitskräften',
   'calendar.tooltip.time': 'Zeit',
   'calendar.tooltip.category': 'Kategorie',
   'calendar.tooltip.service': 'Service',
   'calendar.tooltip.staff': 'Personal',
   'calendar.tooltip.price': 'Preis',
   'calendar.tooltip.repeating': 'Wiederholung',
   'btn.viewDetails': 'Details anzeigen',
   'calendar.tooltip.emptyCustomer': 'Walk-in',
   'calendar.tooltip.bookedOnline': 'Online-Buchung',
   'calendar.print.description':
      'Um die Druckfunktion nutzen zu können, muss Ihr Plan aktualisiert werden. Um ein Upgrade durchzuführen, besuchen Sie',
   'calendar.print.appointmentsBetween.title': 'Termine zwischen',
   'calendar.print.appointmentsAnd.title': 'Und',
   'calendar.billing.title': 'Plan und Abrechnung',
   'calendar.btn.print.title': 'Drucken',
   'datepicker.next.week': 'Nächste Woche',
   'datepicker.in.two.weeks': 'In 2 Wochen',
   'datepicker.in.three.weeks': 'In 3 Wochen',
   'datepicker.in.four.weeks': 'In 4 Wochen',
   'datepicker.in.five.weeks': 'In 5 Wochen',
   'datepicker.in.six.weeks': 'In 6 Wochen',
   'modal.splitAppointment.title': 'Online-Buchung',
   'modal.splitAppointment.text':
      'Dieser Termin ist Teil einer Serie, es wird eine separate Buchung erstellt, wenn das Datum geändert wird. Wollen Sie fortfahren?',
   'blocktime.create.modal.title': 'Blockzeit',
   'blocktime.edit.modal.title': 'Blockzeit bearbeiten',
   'appointment.create.menu': 'Termin',
   'blocktime.create.menu': 'Blockzeit',
   'appointment.new.title': 'Neuer Termin',
   'input.startTime.label': 'Startzeit',
   'input.endTime.label': 'Endzeit',
   'input.startDate.label': 'Startdatum',
   'input.endDate.label': 'Enddatum',
   'input.startDate.description.label': 'Wählen Sie das Startdatum',
   'input.endDate.description.label': 'Wählen Sie das Enddatum aus',
   'input.closedDatedDescription.placeholder': 'z.B. Feiertage',
   'input.service.label': 'Dienstleistung',
   'input.services.label': 'dienstleistungen',
   'input.service.placeholder': 'Dienstleistung auswählen',
   'input.staff.label': 'Mitarbeiter',
   'input.staff.placeholder': 'Mitarbeiter auswählen',
   'input.pickDateAndTime.title': 'Datum und Uhrzeit auswählen',
   'input.pickDate.title': 'Wählen Sie ein Datum',
   'service.add.another': 'Weitere Dienstleistung hinzufügen',
   'input.appointmentNotes.label': 'Notizen zu Terminen',
   'input.emailSubject.placeholder': 'Notizen zu Termin',
   'input.appointmentNotes.placeholder': 'Notizen zu Termin hinzufügen (nur für das Personal sichtbar)',
   'input.searchClients.placeholder': 'Kunden suchen',
   'input.searchService.placeholder': 'Suchen',
   'appointment.empty.list':
      'Suchfunktion nutzen um Neukunde hinzufügen, oder leer lassen, um als walk-in zu speichern',
   'appointment.clients.empty.list': 'Die Kundenliste ist leer.',
   'appointment.clients.addClient': 'Mandant anlegen',
   'client.create.new': 'Neukunde anlegen',
   'client.choose': 'Kunde auswählen',
   'client.menu.edit': 'Kundendaten bearbeiten',
   'client.menu.remove': 'Stornieren aus dem Termin',
   'appointment.btn.save': 'Speichern',
   'appointment.vat': 'MWST. ({0}%)',
   'appointment.total': 'Gesamt',
   'appointment.view.title': 'Termin anzeigen',
   'appointment.status.btn': 'Status des Termins',
   'appointment.btn.cancel': 'Löschen',
   'btn.cancelAppointment': 'Löschen',
   'modal.cancelAppointment.title': 'Löschen',
   'title.clients': 'Kunden',
   'client.create.title': 'Neukunde',
   'client.options.export.title': 'Kunden exportieren',
   'client.options.export.description': 'Laden Sie CSV mit Kundendaten herunter',
   'client.options.import.title': 'Kunden importieren',
   'client.export.premium.description': 'Um die Exportfunktion nutzen zu können, muss Ihr Plan aktualisiert werden.',
   'client.export.premium.visit': 'Um ein Upgrade durchzuführen, besuchen Sie ',
   'client.import.instructions.description':
      'Befolgen Sie die nachstehenden Anweisungen, um Kunden in Ihr Plandok-Konto zu importieren',
   'client.import.instructions.download': 'Laden Sie Ihre Importdatei über die Schaltfläche unten herunter',
   'client.import.instructions.copy': 'Kopieren Sie Ihre Kundendaten und fügen Sie sie in die Datei ein',
   'client.import.instructions.email': 'Schicken Sie es uns per E-Mail an context@plandok.com',
   'client.import.instructions.account': 'und wir importieren in Ihr Konto',
   'table.clients.name': 'Name',
   'table.clients.phone': 'Telefon',
   'table.clients.mobile': 'Telefon',
   'table.clients.email': 'E-Mail',
   'table.clients.gender': 'Geschlecht',
   'client.page.title': 'Kundenprofil',
   'btn.more.options': 'Weitere Möglichkeiten',
   'btn.showAll': 'Alles zeigen',
   'btn.collapse': 'Zusammenbruch',
   'btn.reschedule': 'Neu planen',
   'title.edit.client': 'Kundenstamm bearbeiten',
   'menu.edit.client': 'Kundenstamm bearbeiten',
   'menu.delete.client': 'Kunde stornieren',
   'title.mobilePhone': 'Handy',
   'title.telephoneNumber': 'Telefonnummer',
   'title.email': 'E-Mail',
   'title.address': 'Adresse',
   'title.dateOfBirth': 'Geburtsdatum',
   'title.gender': 'Geschlecht',
   'client.statistics': 'Statistiken',
   'client.totalSales': 'Gesamtumsatz',
   'client.totalBookings': 'Anzahl der Buchungen',
   'client.outstanding': 'Hervorragend',
   'client.allBooking': 'Alle Buchungen',
   'client.completed': 'Abgeschlossen',
   'client.cancelled': 'Storniert',
   'client.noShow': 'No Show',
   'appointment.list.title': 'Terminen',
   'upcoming.list.title': 'Bevorstehende',
   'past.list.title': 'Vergangene',
   'modal.confirmClientDelete.text.0': 'Wollen Sie den Kundenstamm dauerhaft löschen?',
   'modal.confirmClientDelete.text.1': 'aus dem Datenbank?',
   'input.date.label': 'Bestimmtes Datum',
   'input.date.placeholder': 'Datum auswählen',
   'input.firstName.label': 'Vorname',
   'input.firstName.placeholder': 'z.B. John',
   'input.lastName.label': 'Nachname',
   'input.lastName.placeholder': 'z.B. Doe',
   'input.mobile.label': 'Handynummer',
   'input.mobile.placeholder': 'Nummer eingeben',
   'input.email.label': 'E-Mail-Adress',
   'input.email.placeholder': 'z.B. johndoe@gmail.com',
   'input.title.label': 'Titel',
   'input.title.placeholder': 'z.B. Zahnarzt',
   'input.notificationType.label': 'Benachrichtigung senden via',
   'tab.personalInfo': 'Persönliche Informationen',
   'input.gender.label': 'Geschlecht',
   'input.referal.label': 'Verweisquelle',
   'input.referal.placeholder': 'Quelle auswählen',
   'input.birthDay.label': 'Geburtstag',
   'input.month.placeholder': 'Monat',
   'input.day.placeholder': 'Tag',
   'input.year.placeholder': 'Jahr',
   'input.clientsNotes.label': 'Kundennotizen',
   'input.clientsNotes.placeholder': 'Kundennotizen eingeben',
   'input.bookingDisplay.label': 'Auf alle Buchungen anzeigen',
   'input.serviceDescription.label': 'Leistungsbeschreibung',
   'input.serviceDescription.placeholder':
      'Fügen Sie eine kurze Beschreibung hinzu, die für Kunden bei Online-Buchungen sichtbar ist',
   'input.isBookableOnline.label': 'Verfügbar für Online-Buchungen',
   'service.onlineBookings.title': 'Online-Buchungen',
   'tab.address': 'Adresse',
   'input.address.label': 'Adresse',
   'input.address.placeholder': 'Geben Sie Ihre Adresse ein',
   'input.city.label': 'Stadt',
   'input.city.placeholder': 'Stadt eingeben',
   'input.zip.label': 'PLZ',
   'input.zip.placeholder': 'PLZ eingeben',
   'btn.edit': 'Bearbeiten',
   'btn.cancel': 'Löschen',
   'btn.save': 'Speichern',
   'btn.save.changes': 'Speichern',
   'tabs.staffWorking.title': 'Arbeitsstunden',
   'tabs.staffMembers.title': 'Mitarbeiter',
   'tabs.userPermissions.title': 'Benutzerberichtigung',
   'tabs.closedDates.title': 'Geschlossene Termine',
   'btn.new.staff': 'Neue Mitarbeiter',
   'table.staff.name': 'Name',
   'table.staff.mobileNumber': 'Telefon',
   'table.staff.email': 'E-Mail',
   'table.staff.userPermission': 'Benutzerberichtigung',
   'table.staff.dataRange': 'Plage de dates',
   'table.staff.numberOfDays': 'Nombre de jours',
   'table.staff.location': 'Emplacement',
   'table.staff.description': 'Description',
   'table.appointmentsList.appointment': 'Termin',
   'table.appointmentsList.client': 'Kunde',
   'table.appointmentsList.service': 'Service',
   'table.appointmentsList.date': 'Datum',
   'table.appointmentsList.time': 'Zeit',
   'table.appointmentsList.duration': 'Dauer',
   'table.appointmentsList.location': 'Standort',
   'table.appointmentsList.staff': 'Personal',
   'table.appointmentsList.price': 'Preis',
   'table.appointmentsList.status': 'Status',
   'table.appointmentsCancellations.ref': 'Ref',
   'table.appointmentsCancellations.client': 'Kunde',
   'table.appointmentsCancellations.service': 'Service',
   'table.appointmentsCancellations.scheduledDate': 'Geplanter Termin',
   'table.appointmentsCancellations.cancelledDate': 'Storniertes Datum',
   'table.appointmentsCancellations.cancelledBy': 'Storniert von',
   'table.appointmentsCancellations.reason': 'Grund',
   'table.appointmentsCancellations.price': 'Preis',
   'table.appointmentsCancellations.totalCount': 'Gesamtzahl',
   'table.clients.appointments': 'Termine',
   'table.clients.noShows': 'Keine Shows',
   'table.clients.totalSales': 'Gesamtumsatz',
   'table.clients.added': 'Hinzugefügt',
   'table.clients.daysAbsent': 'Fehlende Tage',
   'table.clients.lastAppointment': 'Letzter Termin',
   'table.clients.lastLocation': 'Letzter Standort',
   'table.appointmentsByService.serviceName': 'Dienstname',
   'table.appointmentsByService.appointments': 'Termine',
   'table.appointmentsByService.totalValue': 'Gesamtwert',
   'table.appointmentsByStaff.staffMember': 'Mitarbeiter',
   'table.appointmentsByStaff.appointments': 'Termine',
   'table.appointmentsByStaff.totalValue': 'Gesamtwert',
   'table.pagination.showing.title': 'Zeigt',
   'table.pagination.results.title': 'Ergebnisse',
   'clients.duplicate.title': 'Doppelte Clients gefunden.',
   'clients.duplicate.description': 'Es gibt ähnliche Clients, die zusammengeführt werden können.',
   'clients.merge.btn.title': 'Verschmelzen',
   'clients.merge.modal.title': 'Kunden zusammenführen',
   'clients.merge.selectAll.title': 'Wählen Sie alle Duplikate aus',
   'clients.merge.duplicatesNotFound.title': 'Keine Duplikate gefunden',
   'clients.merge.duplicatesNotFound.description': 'Es sind keine Clients zum Zusammenführen vorhanden',
   'clients.mergeConfirm.modal.title': 'Bestätigen Sie die Zusammenführung',
   'clients.mergeConfirm.modal.description':
      'Sind Sie sicher, dass Sie {0} Duplikate zusammenführen möchten? Diese Aktion ist dauerhaft und kann nicht rückgängig gemacht werden.',
   'clients.mergeConfirm.checkbox.title':
      'Ich verstehe, dass die Zusammenführung von Clients nicht rückgängig gemacht werden kann',
   'clients.mergeConfirm.btn.title': 'Bestätigen und zusammenführen',
   'clients.mergeConfirm.cancel.btn.title': 'Stornieren',
   'clients.byNumber.btn.title': 'Per Telefon {0}',
   'clients.byEmail.btn.title': 'Per E-Mail {0}',
   'clients.byName.btn.title': 'Nach Namen {0}',
   'title.newStaff': 'Neue Mitarbeiter',
   'title.editStaff': 'Mitarbeiterdaten bearbeiten',
   'description.extraCharge':
      'Wenn Sie neue Mitarbeiter hinzufügen, werden zusätzliche Kosten zu Ihrem monatlichen Abonnement hinzugefügt',
   'tabs.details': 'Details',
   'input.staffPicture.label': 'Bild',
   'input.cropImage.title': 'Bild zuschneiden',
   'input.cropImage.btn.apply.title': 'Anwenden',
   'input.stafffirstName.label': 'Vorname',
   'input.stafffirstName.placeholder': 'z.B. John',
   'input.stafflastName.label': 'Nachname',
   'input.stafflastName.placeholder': 'z.B. Doe',
   'input.stafmobile.label': 'Telefon',
   'input.stafemail.label': 'E-Mail',
   'input.stafemail.placeholder': 'z.B. johndoe@gmail.com',
   'input.permission.label': 'Benutzerberichtigung',
   'input.uploadProfile.label': 'Profilbild hochladen',
   'input.changeProfile.label': 'Profilbild ändern',
   'input.notes.label': 'Notizen',
   'input.staffNotes.placeholder':
      'Private Notizen hinzufügen, die nur in Mitarbeitereinstellungen sichtbar ist (optional)',
   'input.blockTime.placeholder': 'z.B. Besprechung zum Mittagszeit',
   'tabs.locations': 'Standorte',
   'tabs.integrations': 'Integrationen',
   'input.staffLocations.description': 'Standorte hinzufügen, wo der Mitarbeiter gebucht werden kann.',
   'input.multiLocation.description': 'Standorte hinzufügen, wo der Mitarbeiter gebucht werden kann.',
   'tabs.services': 'Dienstleistungen',
   'input.staffServices.description': 'Diensleistungen an dem Mitarbeiter zuordnen, die er durchführen kann.',
   'input.multiServices.description': 'Dienstleistungen an dem Mitarbeiter zuordnen, die er durchführen kann.',
   'input.shiftStart.label': 'Schichtbeginn',
   'input.shiftEnd.label': 'Schichtende',
   'edit.staff.title.resendPassword': 'Passwort erneut senden',
   'edit.staff.info.resendPassword':
      'Der Mitarbeiter hat keine E-Mail mit Passwort erhalten? Klicken Sie unten auf eine Schaltfläche, um die E-Mail mit Anweisungen zur Passworteinrichtung erneut zu senden.',
   'edit.staff.button.resendPassword': 'Passwort-E-Mail erneut senden',
   'btn.add.shift': 'Weitere Schichte hinzufügen',
   'break.time': '{0} Pause',
   'input.repeat.label': 'Wiederholen',
   'input.repeatWeekly.label': 'Wöchentlich',
   'input.repeatDoNot.label': 'Nicht wiederholen',
   'input.endRepeat.label': 'Wiederholung beenden',
   'input.endRepeatOngoing.label': 'Laufend',
   'input.endRepeatSpecificDate.label': 'Bestimmtes Datum',
   'input.endRepeatDate.placeholder': 'Datum auswählen',
   'btn.deleteUpcomingShift': 'Kommende Schichte löschen',
   'btn.deleteThisShift': 'Diese Schicht löschen',
   'btn.updateUpcomingShift': 'Kommende Schichte aktualisieren',
   'btn.updateThisShift': 'Diese Schickt aktualisieren',
   'modal.repeatShift.title': 'Schicht wiederholen',
   'form.error.uniqShifts': 'Schichten sollten eindeutig sein',
   'btn.add.new': 'Neue hinzufügen',
   'btn.addNewService': 'Neue Dienstleistung hinzufügen',
   'btn.newCategory': 'Neue Kategorie',
   'btn.newServiceGroup': 'Neue Kategorie',
   'btn.editCategory': 'Kategorie bearbeiten',
   'btn.deleteCategory': 'Kategorie löschen',
   'btn.downloadImportFile': 'Importdatei herunterladen',
   'serviceGroup.delete.success': 'Kategorie erfolgreich gelöscht!',
   'service.new.title': 'Neue Dienstleistung',
   'service.edit.title': 'Dienstleistung bearbeiten',
   'input.serviceName.label': 'Name der Dienstleistung',
   'input.serviceName.placeholder': 'z.B. Nachricht',
   'input.serviceCategory.label': 'Dienstleistungskategorie',
   'input.serviceCategory.placeholder': 'Dienstleistungskategorie auswählen',
   'input.price.label': 'Preis',
   'input.price.placeholder': '0',
   'input.duration.label': 'Dauer',
   'input.duration.placeholder': 'Dauer',
   'input.staffSelect.description': 'Mitarbeiter auswählen um diese Dienstleistung auszuführen',
   'input.newCategoryName.label': 'Kategoriename',
   'input.newCategoryDescription.label': 'Kategoriebeschreibung',
   'input.newCategoryDescription.placeholder':
      'Beschreiben Sie diese Kategorie, sie wird in Online-Buchungen angezeigt',
   'modal.newCategory.title': 'Neue Kategorie',
   'modal.editCategory.title': 'Kategorie bearbeiten',
   'input.chooseCategory.label': 'Kategoriefarbe auswählen',
   'client.messages.title': 'Kundennachrichten',
   'tabs.messagesLog': 'Nachrichtenverlauf',
   'tabs.messagesLog.description': 'Liste der an Ihre Kunden gesendeten Nachrichten',
   'tabs.messagesSettings': 'Einstellungen',
   'tabs.messagesSettings.description': 'Passen Sie Ihre Nachrichteneinstellungen an Ihre Geschäftsanforderungen an',
   'table.header.timeSent': 'Senden',
   'table.header.client': 'Kunde',
   'table.header.appointment': 'Termin',
   'table.header.destination': 'Adresse',
   'table.header.type': 'Art',
   'table.header.message': 'Nachricht',
   'table.header.status': 'Status',
   'table.header.cost': 'Kosten',
   'modal.message.title': 'Nachricht anschauen',
   'setup.page.title': 'Konfiguration',
   'title.accountSettings': 'Kontoeinstellungen',
   'title.accountSetup': 'Konto einrichten',
   'title.accountSetup.description': 'Einstellungen, wie Firmenname und Zeitzone bearbeiten',
   'resources.list.title': 'Hilfsmittel',
   'title.resources': 'Hilfsmittel',
   'title.resources.description': 'Erreichbare Hilfsmittel, wie Räume, Stühle oder Ausrüstung auflisten',
   'title.locations': 'Standorte',
   'title.locations.description': 'Mehrere Standorte Ihres Geschäfts verwalten',
   'calendar.settings.title': 'Kalendereinstellungen',
   'calendar.btn.selectFromCalendar': 'Vom Kalender auswählen',
   'title.calendarSettings': 'Kalendereinstellungen',
   'title.calendarSettings.description': 'Farbschema und Struktur Ihres Kalenders anpassen',
   'title.clientSettings': 'Kundeneinstellungen',
   'title.onlineBookingSettings': 'Online Buchungseinstellungen',
   'title.salesSettings': 'Einstellungen für den Verkauf',
   'title.receiptSequencing': 'Sequenzierung der Quittungen',
   'title.receiptSequencing.description':
      'Konfigurieren Sie die Details, die auf den an Ihre Kunden ausgegebenen Verkaufsbelegen angezeigt werden',
   'title.receiptTemplate': 'Quittungsvorlage',
   'title.taxes.description': 'Verwalten Sie Steuersätze, die für an der Kasse verkaufte Artikel gelten',
   'title.paymentTypes': 'Zahlungsarten',
   'title.paymentTypes.description': 'Richten Sie manuelle Zahlungsarten für die Verwendung an der Kasse ein',
   'title.discounts.description': 'Einrichten Ihrer Rabatte',
   'title.receiptPrefix': 'Quittung №. Vorwahl',
   'title.receiptNumber': 'Nächste Bon-Nummer',
   'title.change': 'ändern',
   'title.saleReceiptTemplate': 'Verkaufsbeleg-Vorlage',
   'description.saleReceiptTemplate': 'Passen Sie den Inhalt der an Ihre Kunden ausgegebenen Verkaufsquittungen an',
   'setting.automaticallyPrint': 'Automatischer Druck von Quittungen nach Verkaufsabschluss',
   'setting.showMobile': 'Kundenhandy und E-Mail auf dem Kassenbon anzeigen',
   'setting.showAddress': 'Kundenadresse auf der Quittung anzeigen',
   'title.receiptTitle': 'Titel der Quittung',
   'title.referrals': 'Verweisquelle',
   'title.referrals.description':
      'Kundenquellen erstellen, um es nachzuvollziehen, wie Kunden Ihr Geschäft gefunden haben',
   'title.clientNotifications': 'Kunde-Benachrichtigungseinstellungen',
   'title.clientNotifications.description': 'Nachrichten an Kunden über Ihre Termine zu verwalten',
   'cancelReason.list.title': 'Stornierungsgrund',
   'title.cancellationReasons': 'Stornierungsgrund',
   'title.cancellationReasons.description': 'Gründe nachvollziehen, wieso Kunden die Termine verpasst haben',
   'title.onlineBooking.description':
      'Verwalten der Verfügbarkeit und der Einstellungen für die Online-Buchung von Touren',
   'title.pointOfSale': 'Point of Sale',
   'discount.list.title': 'Rabatte',
   'title.discountTypes': 'Rabattarten',
   'title.discountTypes.description': 'Manuelle Rabattarten erstellen, um bei der Bezahlung zu nutzen',
   'title.premiumFeature': 'Premium-Funktion',
   'company.details.title': 'Firmendetails',
   'input.businessName.label': 'Firmenname',
   'input.businessName.placeholder': 'z.B. Eaglewings',
   'input.description.label': 'Beschreibung',
   'input.description.placeholder': 'Beschreibung eingeben',
   'input.businessAddress.label': 'Adresse',
   'input.businessAddress.placeholder': 'Adresse eingeben',
   'input.website.label': 'Website',
   'input.website.placeholder': 'Website eingeben',
   'input.contactNumber.label': 'Telefonnummer',
   'input.contactNumber.placeholder': 'Telefonnummer eingeben',
   'input.businessTimeZone.label': 'Zeitzone',
   'input.businessTimeZone.placeholder': 'GMT +2.00',
   'input.businessCountry.label': 'Land',
   'input.businessCountry.placeholder': 'Land auswählen',
   'input.timeFormat.label': 'Zeitformat',
   'input.timeFormat.placeholder': '24-Stunden',
   'table.header.resourceName': 'Ressource',
   'btn.new.resource': 'Neue Ressource',
   'input.resourceName.label': 'Ressourcename',
   'input.resourceName.placeholder': 'Name eingeben',
   'input.resourceDescription.label': 'Beschreibung',
   'input.resourceDescription.placeholder': 'Beschreibung eingeben',
   'input.resourceLocation.placeholder': 'Name des Standortes',
   'resourcesTooltip.location': 'Standort Tooltip für Ressource',
   'modal.newResource.title': 'Neue Ressource erstellen',
   'modal.editResource.title': 'Ressource bearbeiten',
   'table.header.locationName': 'Standort',
   'table.header.locatonAddress': 'Adresse',
   'table.header.location.Phone': 'Telefon',
   'modal.newLocation.title': 'Neuer Standort',
   'modal.editLocation.title': 'Standort bearbeiten',
   'btn.new.location': 'Neuer Standort',
   'btn.addExtraLocation': 'Zusätzlichen Standort hinzufügen',
   'input.locationName.label': 'Name des Standortes',
   'input.locationName.placeholder': 'Name des Standortes eingeben',
   'input.profile.locationName.placeholder': 'Z.B. Friseursalon',
   'input.slug.label': 'Slug',
   'input.locationTips.label': 'Tipps zum Standort',
   'input.slug.explanation': 'Wird in Ihrer Standort-URL sichtbar sein',
   'input.contactEmail.label': 'E-Mail-Adresse',
   'input.locationAddress.label': 'Adresse',
   'input.locationAddress.placeholder': 'Adresse des Standortes eingeben',
   'tooltip.contactEmail': 'Nachrichten über Termine und Benachrichtigungen werden an diese E-Mail-Adresse gesendet.',
   'input.appointmentColors.label': 'Farbe des Termins',
   'input.colorBy.service': 'Nach Dienstleistungsgruppe',
   'input.colorBy.employee': 'Nach Mitarbeiter',
   'input.colorBy.appointment': 'Nach Status des Termins',
   'input.timeSlot.label': 'Zeitfenster-Intervall',
   'input.defaultViewType.label': 'Standardansicht',
   'input.calendarTimeRange.label': 'Kalenderzeitbereich',
   'input.calendarTimeRange.allTimes.option': 'Alle Zeiten (00:00 - 24:00)',
   'input.calendarTimeRange.onlyWorkingHours.option': 'Nur Arbeitszeiten',
   'input.calendarTimeRange.customRange.option': 'Benutzerdefinierter Bereich',
   'input.weekStart.label': 'Anfangstag der Woche',
   'table.header.refSourcetitle': 'Verweisquelle',
   'table.header.refSourceAddDate': 'Datum hinzugefügt',
   'btn.new.referral': 'Neue Verweisquelle',
   'input.nameRefSource.label': 'Name der Verweisquelle',
   'input.nameRefSource.placeholder': 'z.B. Facebook Werbungen',
   'modal.newRefSource.title': 'Neue Verweisquelle',
   'modal.editRefSource.title': 'Verweisquelle bearbeiten',
   'input.sendBy.label': 'Absender',
   'input.emailSubject.label': 'E-Mail Betreff',
   'input.emailTemplate.label': 'E-Mail Vorlage',
   'input.emailTemplate.placeholder': 'E-Mail Vorlage erstellen',
   'input.subject.label': 'E-Mail Betreff',
   'input.subject.placeholder': 'E-Mail Betreff eingeben',
   'title.messagingSettings.label': 'Nachrichteinstellungen',
   'title.messagingSettings.description':
      'Die hier erstellten Nachrichtarten werden automatisch an den Kunden versendet. Alle generierten Nachrichten werden einfach zugänglich (im Hauptmenu). Stellen Sie die Einstellungen ein, wie und wann die Nachrichten geschickt werden sollen, und bearbeiten Sie die Vorlagen um die Nachrichten zu personalisieren. Sie können die unten stehende Tags um Termindetails in den Nachrichten einzugeben benutzen. ',
   'client.first.name': 'CLIENT_FIRST_NAME',
   'client.last.name': 'CLIENT_LAST_NAME',
   'staff.first.name': 'STAFF_FIRST_NAME',
   'staff.last.name': 'STAFF_LAST_NAME',
   'booking.date.time': 'BOOKING_DATE_TIME',
   'booking.date': 'BOOKING_DATE',
   'booking.time': 'BOOKING_TIME',
   'service.name': 'SERVICE_NAME',
   'business.name': 'BUSINESS_NAME',
   'business.phone': 'BUSINESS_PHONE',
   'business.address': 'BUSINESS_ADDRESS',
   'business.email': 'BUSINESS_EMAIL',
   'location.name': 'LOCATION_NAME',
   'location.phone': 'LOCATION_PHONE',
   'location.address': 'LOCATION_ADDRESS',
   'location.email': 'LOCATION_EMAIL',
   'tabs.reminders.title': 'Erinnerungen',
   'input.enableReminder.label': 'Erinnerungsnachrichten freigeben',
   'input.enableReminder.description': 'Nachricht an Kunde über kommenden Termin wird automatisch versendet',
   'input.reminder.title': 'Erinnerung {0}',
   'input.message.title': 'Nachricht {0}',
   'input.reminder.description': 'Kanäle und Sendezeit',
   'input.reminder.channels': 'Wählen Sie die Kanäle aus, auf denen diese Nachricht gesendet werden soll',
   'input.reminderBefore.label': 'Erinnerungen werden vorher verschickt',
   'input.smsSender.label': 'SMS Absender ID',
   'input.messageSender.label': 'Absender-ID der Nachricht',
   'input.smsSender.placeholder': 'SMS Absender ID eingeben',
   'input.smsTemplate.label': 'SMS Vorlage',
   'input.messageTemplate.label': 'Nachrichtenvorlage',
   'input.smsTemplate.placeholder': 'SMS Vorlage erstellen',
   'input.whatsAppSender.placeholder': 'Plandok',
   'reminder.whatsApp.notificate':
      'Die Vorlage für WhatsApp-Nachrichten kann derzeit aufgrund von Einschränkungen nicht bearbeitet werden. Wir arbeiten jedoch aktiv daran, sie vollständig bearbeitbar zu machen.',
   'reminder.charactersCount': 'Zeichen zählen:',
   'reminder.pricing': 'Preisgestaltung',
   'modal.pricing.description':
      'Verschiedene Länder haben unterschiedliche Preise. Wir bieten den bestmöglichen Tarif unserer Partner an. Er kann variieren.',
   'modal.pricing.subDescription':
      'Die Gebühren werden erhoben, sobald die Nachrichten versendet wurden, und werden der nächsten Rechnung hinzugefügt.',
   'modal.pricing.counterDescription':
      'Eine Standard-SMS ist auf 160 Zeichen und 70 Zeichen für Nachrichten mit Sonderzeichen begrenzt.',
   'modal.pricing.learnMore': 'Mehr erfahren',
   'modal.pricing.priceRates': 'Preise',
   'modal.pricing.messagePrice': 'Preis für 1 Nachricht:',
   'modal.sendTestMessageByNumber.title': 'Senden Sie eine Testnachricht an diese Nummer:',
   'modal.sendTestMessageByNumber.description': 'Es fallen Gebühren wie bei einer normalen Nachricht an.',
   'modal.sendTestMessageByEmail.title': 'Testnachricht an diese E-Mail senden:',
   'modal.sendTestMessageByEmail.placeholder': 'Bitte geben Sie die E-Mail-Adresse ein',
   'btn.send': 'Senden Sie',
   'reminder.sendTestMessage': 'Testnachricht senden',
   'button.reminder.addTag': 'Tag hinzufügen',
   'button.reminder.addAnotherReminder': 'EINE WEITERE ERINNERUNG HINZUFÜGEN',
   'button.message.addAnotherMessage': 'WEITERE NACHRICHT HINZUFÜGEN',
   'button.reminder.upgrade': 'Upgrade für den Zugriff',
   'label.emailSettings': 'Email Einstellungen',
   'label.SMSSettings': 'SMS Einstellungen',
   'label.whatsAppSettings': 'WhatsApp Einstellungen',
   'tooltip.sender.0': 'SMS Absendername wird gezeigt',
   'tooltip.sender.1': 'Maximal 11 Zeichen',
   'tooltip.sender.2': 'Konnte in einigen Länder nicht funktionieren',
   'tooltip.sender.3': 'Ausschließlich lateinische Buchstaben und Nummern',
   'tabs.confirmations.title': 'Bestätigung',
   'btn.appBookings': 'In App Buchungen',
   'btn.onlineBookings': 'Online Buchungen',
   'input.enableAppConfirmation.label': 'In App Buchungsbestätigungen aktivieren',
   'input.enableOnlineConfirmation.label': 'Online Buchungsbestätigungen aktivieren',
   'input.enableConfirmationApp.description':
      'Wird automatisch an Kunden gesendet, wenn ein Termin für sie gebucht wurde',
   'input.enableConfirmationOnline.description': 'Wird automatisch an Kunden gesendet, wenn diese einen Termin buchen',
   'messageSetup.header': 'Nachricht einrichten',
   'channels.subHeader': 'Kanäle',
   'tabs.reschedules.title': 'Terminverschiebung',
   'input.enableReschedule.label': 'Nachrichten über Terminverschiebungen freigeben',
   'input.enableReschedule.description': 'Nachricht an Kunde über Terminverschiebung wird automatisch versendet',
   'tabs.cancellations.title': 'Stornierung',
   'input.enableCancellation.label': 'Stonierungsnachrichten freigeben',
   'input.enableCancellations.description': 'Nachricht an Kunde über Terminstornierung wird automatisch versendet',
   'tabs.thanks.title': 'Vielen Dank',
   'input.enableThank.label': 'Danknachricht freigeben',
   'input.enableThankyou.description': 'Nachricht an Kunde über geschlossenen Terminsstatus wird automatisch versendet',
   'tabs.lastVisit.title': 'Letzter Besuch',
   'input.enableLastVisit.label': 'Nachrichten über letzten Besuch freigeben',
   'input.enableLastVisit.description':
      'Nachricht an Kunde wird automatisch nach bestimmte Tagen nach dem letzten Termin versendet',
   'table.cancelReason.name': 'Stonierungsgrund',
   'table.cancelReason.addDate': 'Datum hinzufügen',
   'btn.new.cancelReason': 'Neuer Stornierungsgrund',
   'modal.newCancelReason.title': 'Neuer Stornierungsgrund',
   'modal.editCancelReason.title': 'Stornierungsgrund bearbeiten',
   'input.cancelReason.title': 'Name des Stornierungsgrunds',
   'input.cancelReason.placeholder': 'z.B. Kunde nicht verfügbar',
   'table.discounts.name': 'Rabatte',
   'table.discounts.value': 'Wert',
   'table.discounts.addDate': 'Erstellungsdatum',
   'input.discountName.label': 'Name der Rabatte',
   'input.discountName.placeholder': 'z.B. Seniorenrabatte',
   'input.discountValue.label': 'Wert der Rabatte',
   'btn.new.discount': 'Neue Rabatte',
   'input.discountPercentage.label': '% Anteil',
   'input.discountSum.label': 'Wert',
   'modal.newDiscount.title': 'Neue Rabatttyp',
   'modal.editDiscount.title': 'Rabatte bearbeiten',
   'modal.deleteAccount.title': 'Konto löschen',
   'modal.deleteAccount.description':
      'Das Löschen Ihres Kontos ist ein unumkehrbarer Vorgang und alle Ihre Daten werden für immer entfernt.',
   'modal.deleteAccount.successMessage': 'Ihr Konto wurde erfolgreich gelöscht.',
   'title.mySettings': 'Meine Einstellungen',
   'title.personalDetails': 'Persönliche Daten',
   'title.personalDetails.description':
      'Name und Kontaktdaten bearbeiten, die E-Mail-Adresse wird bei den Anmeldungen benutzt',
   'btn.continueWithGoogle.title': 'Weiter mit Google',
   'btn.continueWithFacebook.title': 'Weiter mit Facebook',
   'notification.googleAccountUnlinked': 'Google-Konto nicht verknüpft',
   'notification.googleAccountLinked': 'Google-Konto verknüpft',
   'notification.facebookAccountUnlinked': 'Facebook-Konto nicht verknüpft',
   'notification.facebookAccountLinked': 'Facebook-Konto verknüpft',
   'btn.linkAccount': 'Konto verknüpfen',
   'btn.unlinkAccount': 'Unverknüpftes Konto',
   'btn.deleteYourAccount': 'Löschen Sie Ihr Konto',
   'input.myName.label': 'Vorname',
   'input.myName.placeholder': 'z.B. John',
   'input.mylastName.label': 'Nachname',
   'input.mylastName.placeholder': 'z.B. Doe',
   'title.changePassword': 'Passwort ändern',
   'title.changePassword.description':
      'Um das Passwort zu ändern, geben Sie erst Ihr aktuelles Passwort und danach das neue Passwort ein. Wenn Sie Ihr aktuelles Passwort vergessen haben, melden Sie sich ab und nutzen Sie die Passwortrücksetzung Funktion.',
   'input.currentPassword.label': 'Aktuelles Passwort',
   'label.currentPassword.placeholder': 'Aktuelles Passwort eingeben',
   'input.newPassword.label': 'Neues Passwort',
   'input.newPassword.placeholder': 'Neues Passwort eingeben',
   'input.verifyPassword.label': 'Passwort erneut eingeben',
   'input.verifyPassword.placeholder': 'Neues Passwort erneut eingeben',
   'btn.confirmDelete': 'Ja, jetzt löschen',
   'btn.confirm': 'Bestätigen',
   'btn.confirmBook': 'Bestätigen und buchen',
   'btn.confirmPay': 'Bestätigen und bezahlen',
   'btn.delete': 'Löschen',
   'btn.close': 'Schließen',
   'validation.required': 'Pflichtfeld',
   'validation.email.format': 'Inkorrektes E-Mail-Format',
   'validation.url.format': 'Falsches Url-Format, muss mit https:// oder http:// beginnen',
   'validation.password.length': 'Die Länge des Passworts sollte mindestens 8 Zeichen betragen',
   'validation.password.letters': 'Passwort muss aus Nummern und Buschstaben bestehen',
   'validation.startTime': 'Die Startzeit kann nicht später oder gleich der Endzeit sein',
   'validation.endTime': 'Die Endzeit kann nicht vor der Startzeit liegen oder mit ihr identisch sein',
   'tooltip.password.title': 'Das Passwort muss:',
   'tooltip.password.characters': 'mindestens 7 Zeichnen betragen',
   'tooltip.password.number': '1 Nummer enthalten',
   'tooltip.password.letter': '1 Buchstaben enthalten',
   'tooltip.location': 'Standort Tooltip',
   'footer.support.center': 'Brauchen Sie Hilfe? Kontaktieren Sie uns',
   'not.found': 'Nicht gefunden',
   'input.cancelReason.label': 'Stonierungsgrund',
   'input.location.label': 'Standort',
   'input.location.placeholder': 'Name des Standortes',
   'input.name.label': 'Name',
   'title.recentlyAddedClients': 'Kunden (vor kurzem hinzugefügt)',
   'title.calendarView': 'Kalender-Ansicht',
   'text.manageProfile': 'Benutzerprofil bearbeiten',
   'modal.language.title': 'Sprache auswählen',
   'modal.language.placeholder': 'Suchen sprache',
   'dropdown.access.medium': 'Mittel',
   'dropdown.access.high': 'Hoch',
   'dropdown.access.low': 'Niedrig',
   'dropdown.access.basic': 'Basic',
   'dropdown.access.no': 'Kein Zugang',
   'dropdown.access.owner': 'Inhaber',
   'button.select.all': 'Alles markieren',
   'button.selected': 'Markierte',
   'dropdown.gender.unknown': 'Unbekannt',
   'dropdown.gender.male': 'Männlich',
   'dropdown.gender.female': 'Weiblich',
   'dropdown.notificationBy.none': 'Nicht senden',
   'dropdown.notificationBy.sms': 'SMS',
   'dropdown.notificationBy.email': 'E-Mail',
   'dropdown.notificationBy.combined': 'SMS und E-Mail',
   'notification.success.login': 'Sie haben sich erfolgreich angemeldet!',
   'notification.success.register': 'Sie haben sich erfolgreich registriert!',
   'dropdown.time.minutes': 'Minuten',
   'dropdown.repeat.no': 'Nicht wiederholen',
   'dropdown.repeat.weekly': 'Wöchentlich',
   'dropdown.endRepeat.ongoing': 'Laufend',
   'dropdown.endRepeat.weekly': 'Bestimmtes Datum',
   'dropdown.time.appointment.immediately': 'Sofort',
   'dropdown.time.appointment.upTo1Hour': 'Bis zu 1 Stunde',
   'dropdown.time.appointment.upTo2Hours': 'Bis zu 2 Stunden',
   'dropdown.time.appointment.upTo3Hours': 'Bis zu 3 Stunden',
   'dropdown.time.appointment.upTo4Hours': 'Bis zu 4 Stunden',
   'dropdown.time.appointment.upTo5Hours': 'Bis zu 5 Stunden',
   'dropdown.time.appointment.upTo6Hours': 'Bis zu 6 Stunden',
   'dropdown.time.appointment.upTo7Hours': 'Bis zu 7 Stunden',
   'dropdown.time.appointment.upTo8Hours': 'Bis zu 8 Stunden',
   'dropdown.time.appointment.upTo9Hours': 'Bis zu 9 Stunden',
   'dropdown.time.appointment.upTo10Hours': 'Bis zu 10 Stunden',
   'dropdown.time.appointment.upTo11Hours': 'Bis zu 11 Stunden',
   'dropdown.time.appointment.upTo12Hours': 'Bis zu 12 Stunden',
   'dropdown.time.appointment.upTo13Hours': 'Bis zu 13 Stunden',
   'dropdown.time.appointment.upTo14Hours': 'Bis zu 14 Stunden',
   'dropdown.time.appointment.upTo15Hours': 'Bis zu 15 Stunden',
   'dropdown.time.appointment.upTo16Hours': 'Bis zu 16 Stunden',
   'dropdown.time.appointment.upTo17Hours': 'Bis zu 17 Stunden',
   'dropdown.time.appointment.upTo18Hours': 'Bis zu 18 Stunden',
   'dropdown.time.appointment.upTo19Hours': 'Bis zu 19 Stunden',
   'dropdown.time.appointment.upTo20Hours': 'Bis zu 20 Stunden',
   'dropdown.time.appointment.upTo21Hours': 'Bis zu 21 Stunden',
   'dropdown.time.appointment.upTo22Hours': 'Bis zu 22 Stunden',
   'dropdown.time.appointment.upTo23Hours': 'Bis zu 23 Stunden',
   'dropdown.time.appointment.upTo24Hours': 'Bis zu 1 Tag',
   'dropdown.time.appointment.upTo48Hours': 'Bis zu 2 Tage',
   'dropdown.time.appointment.upTo3days': 'Bis zu 3 Tage',
   'dropdown.time.appointment.upTo4days': 'Bis zu 4 Tage',
   'dropdown.time.appointment.upTo5days': 'Bis zu 5 Tage',
   'dropdown.time.appointment.upTo6days': 'Bis zu 6 Tage',
   'dropdown.time.appointment.upTo1week': 'Bis zu 1 Woche',
   'dropdown.time.appointment.upTo2weeks': 'Bis zu 2 Wochen',
   'dropdown.time.appointment.upTo15Min': 'Bis zu 15 Minuten',
   'dropdown.time.appointment.upTo30Min': 'Bis zu 30 Minuten',
   'dropdown.time.appointment.upTo1Month': 'Bis zu 1 Monat',
   'dropdown.time.appointment.upTo2Months': 'Bis zu 2 Monate',
   'dropdown.time.appointment.upTo3Months': 'Bis zu 3 Monate',
   'dropdown.time.appointment.upTo4Months': 'Bis zu 4 Monate',
   'dropdown.time.appointment.upTo5Months': 'Bis zu 5 Monate',
   'dropdown.time.appointment.upTo6Months': 'Bis zu 6 Monate',
   'dropdown.time.appointment.interval5Min': '5 Minuten',
   'dropdown.time.appointment.interval10Min': '10 Minuten',
   'dropdown.time.appointment.interval15Min': '15 Minuten',
   'dropdown.time.appointment.interval20Min': '20 Minuten',
   'dropdown.time.appointment.interval30Min': '30 Minuten',
   'dropdown.time.appointment.interval40Min': '40 Minuten',
   'dropdown.time.appointment.interval45Min': '45 Minuten',
   'dropdown.time.appointment.interval50Min': '50 Minuten',
   'dropdown.time.appointment.interval60Min': '60 Minuten',
   'dropdown.time.appointment.interval75Min': '75 Minuten',
   'dropdown.time.appointment.interval90Min': '90 Minuten',
   'dropdown.time.appointment.interval120Min': '120 Minuten',
   'dropdown.time.appointment.interval150Min': '150 Minuten',
   'dropdown.time.appointment.interval180Min': '180 Minuten',
   'dropdown.time.appointment.interval240Min': '240 Minuten',
   'dropdown.time.appointment.anyTime': 'Jederzeit',
   'dropdown.time.appointment.disabled': 'Ausgeschaltet',
   'dropdown.time.appointment.doesntRepeat': 'Wiederholt sich nicht',
   'dropdown.time.appointment.daily': 'Täglich',
   'dropdown.time.appointment.every2Days': 'Alle 2 Tage',
   'dropdown.time.appointment.every3Days': 'Alle 3 Tage',
   'dropdown.time.appointment.every4Days': 'Alle 4 Tage',
   'dropdown.time.appointment.every5Days': 'Alle 5 Tage',
   'dropdown.time.appointment.every6Days': 'Alle 6 Tage',
   'dropdown.time.appointment.every7Days': 'Alle 7 Tage',
   'dropdown.time.appointment.weekly': 'Wöchentlich',
   'dropdown.time.appointment.every2weeks': 'Alle 2 Wochen',
   'dropdown.time.appointment.every3weeks': 'Alle 3 Wochen',
   'dropdown.time.appointment.every4weeks': 'Alle 4 Wochen',
   'dropdown.time.appointment.every5weeks': 'Alle 5 Wochen',
   'dropdown.time.appointment.every6weeks': 'Alle 6 Wochen',
   'dropdown.time.appointment.every7weeks': 'Alle 7 Wochen',
   'dropdown.time.appointment.every8weeks': 'Alle 8 Wochen',
   'dropdown.time.appointment.every9weeks': 'Alle 9 Wochen',
   'dropdown.time.appointment.every10weeks': 'Alle 10 Wochen',
   'dropdown.time.appointment.monthly': 'Monatlich',
   'dropdown.time.appointment.every2months': 'Alle 2 Monate',
   'dropdown.time.appointment.every3months': 'Alle 3 Monate',
   'dropdown.time.appointment.every4months': 'Alle 4 Monate',
   'dropdown.time.appointment.every5months': 'Alle 5 Monate',
   'dropdown.time.appointment.every6months': 'Alle 6 Monate',
   'dropdown.time.appointment.every7months': 'Alle 7 Monate',
   'dropdown.time.appointment.every8months': 'Alle 8 Monate',
   'dropdown.time.appointment.every9months': 'Alle 9 Monate',
   'dropdown.time.appointment.every10months': 'Alle 10 Monate',
   'dropdown.time.appointment.every11months': 'Alle 11 Monate',
   'dropdown.time.appointment.after2times': 'Nach 2 mal',
   'dropdown.time.appointment.after3times': 'Nach 3 mal',
   'dropdown.time.appointment.after4times': 'Nach 4 mal',
   'dropdown.time.appointment.after5times': 'Nach 5 Mal',
   'dropdown.time.appointment.after6times': 'Nach 6 Mal',
   'dropdown.time.appointment.after7times': 'Nach 7 Mal',
   'dropdown.time.appointment.after8times': 'Nach 8 Mal',
   'dropdown.time.appointment.after9times': 'Nach 9 Mal',
   'dropdown.time.appointment.after10times': 'Nach 10 Mal',
   'dropdown.time.appointment.after11times': 'Nach 11 mal',
   'dropdown.time.appointment.after12times': 'Nach 12 Mal',
   'dropdown.time.appointment.after13times': 'Nach 13 Mal',
   'dropdown.time.appointment.after14times': 'Nach 14 Mal',
   'dropdown.time.appointment.after15times': 'Nach 15 Mal',
   'dropdown.time.appointment.after20times': 'Nach 20 Mal',
   'dropdown.time.appointment.after25times': 'Nach 25 Mal',
   'dropdown.time.appointment.after30times': 'Nach 30 Mal',
   'dropdown.time.appointment.specificDate': 'Spezifisches Datum',
   'modal.createHours.title': '{0} Stunden erstellen',
   'modal.editHours.title': '{0} Stunden bearbeiten',
   'modal.deleteItem.confirm.0': 'Wollen sie es dauerhaft',
   'modal.deleteItem.confirm.1': 'löschen',
   'modal.deleteItem.confirm.2': 'aus der Datenbank?',
   'modal.entity.category': 'Kategorie',
   'modal.entity.referal': 'Verweisquelle',
   'modal.entity.resource': 'Quelle',
   'modal.entity.location': 'Standort',
   'modal.deleteReminder.title': 'Erinnerung löschen',
   'modal.deleteMessage.title': 'Nachricht löschen',
   'modal.deleteReminder.description': 'Sind Sie sicher, dass Sie diese Erinnerung löschen möchten ?',
   'modal.deleteMessage.description': 'Sind Sie sicher, dass Sie diese Nachricht löschen möchten ?',
   'modal.deleteReminder.confirm': 'Ja, löschen',
   'modal.deletePaymentMethod.title': 'Zahlungsmethode löschen',
   'modal.deletePaymentMethod.description': 'Diese Zahlungsmethode wird endgültig gelöscht',
   'closedDate.create.modal.title': 'Geschlossenes Datum hinzufügen',
   'closedDate.edit.modal.title': 'Geschlossenes Datum bearbeiten',
   'staff.access.owner': 'Inhaber',
   'staff.access.high': 'Hoch',
   'staff.access.medium': 'Mittel',
   'staff.access.low': 'Niedrig',
   'staff.access.basic': 'Basic',
   'staff.access.no_access': 'Kein Zugang',
   'staff.create.title': 'Geschlossenes Datum hinzufügen',
   'staff.emptyState.title': 'Keine eingeplanten Mitarbeiter.',
   'staff.emptyState.description':
      'Informationen zum Hinzufügen von Arbeitszeiten finden Sie im Abschnitt „Mitarbeiter“.\n',
   'staff.viewStaffMembers.button': 'Mitarbeiter anzeigen',
   'staff.picture.changePhoto': 'Foto ändern',
   'staff.picture.removePhoto': 'Foto entfernen',
   'input.clientSearch.placeholder': 'Nach Namen, E-Mail oder Handynummer suchen',
   'input.blockedDate.label': 'Datum',
   'appointment.notes.title': 'Terminnotizen',
   'appointment.history.title': 'Terminhistorie',
   'appointment.history.by.title': ' von {0}',
   'appointment.log.created.label': 'Erstellt von',
   'appointment.log.updated.label': 'Aktualisiert von',
   'appointment.log.rescheduled.label': 'Verschoben von',
   'appointment.log.cancelled.label': 'Storniert von',
   'appointment.log.changed.label': 'Status geändert von',
   'appointment.notificationLog.rescheduling': 'Verschiebungsbenachrichtigung gesendet am',
   'appointment.notificationLog.cancellation': 'Stornierungsbenachrichtigung gesendet am',
   'appointment.notificationLog.confirmation': 'Bestätigungsbenachrichtigung gesendet',
   'appointment.notificationLog.reminder': 'Erinnerungsbenachrichtigung gesendet am',
   'appointment.notificationLog.thankYou': 'Dankeschön-Benachrichtigung gesendet',
   'appointment.notificationLog.lastVisit': 'Letzte Besuchsbenachrichtigung gesendet am',
   'state.cancelReason.title': 'Stornierungsgründe bearbeiten',
   'state.cancelReason.description':
      'Stornierungsgründe helfen Ihnen es nachzuvollziehen, wieso Ihre Kunden die Termine verpasst haben.',
   'state.cancelReason.button': 'Stornierungsgründe erstellen',
   'state.resources.title': 'Ihre Ressourcen verwalten',
   'state.resources.description':
      'Ihr Geschäft wird erfolgreicher, wenn Sie Ihre Ressourcen kennen. Nachvollziehen Sie Ihre Räume, Möbel oder andere Mittel, um bessere Services anbieten und müheslosen Geschäftsablauf verschaffen zu können.',
   'state.resources.button': 'Ressourcen hinzufügen',
   'state.referralSource.title': 'Verweisquelle bearbeiten',
   'state.referralSource.description':
      'Verweisquellen zeigen Ihnen, wie Ihre Kunden Sie gefunden haben. Nachvollziehen Sie die und benutzen Sie die informationen, um die Sichtbarkeit Ihres Geschäfts und Ihre Konversionsrate zu erhöhen.',
   'state.referralSource.button': 'Verweisquelle hinzufügen',
   'state.locations.title': 'Standorte bearbeiten',
   'state.locations.description':
      'Hier können Sie die mehrere Standorte Ihres Geschäfts verwalten, verschiedene Arbeitstunden pro Mitarbeiter pro Standort einstellen und individuelle Kalenders für jede erstellen.',
   'state.staff.title': 'Mitarbeiter verwalten',
   'state.staff.description':
      'Erstellen Sie individuelle Profile für Ihre Mitarbeiter, damit Sie ihre Schichte bearbeiten und verwalten, und ihre Termine und Arbeitsstunden nachvollziehen können.',
   'state.messageLog.title': 'Nachrichten verwalten',
   'state.messageLog.description':
      'Nachrichten erinnern Ihre Kunden an den kommenden Terminen und halten sie auf dem Laufenden. Hier können Sie die Nachrichten verwalten und ihr Status nachvollziehen.',
   'state.messageLog.link': 'Benachrichtigungseinstellungen',
   'state.clients.title': 'Ihre Kunden verwalten',
   'state.clients.description':
      'Ihren Kundenstamm zu pflegen heißt alle nötige Infos um einen müheslosen Geschäftablauf und Kontaktaufnahme zu haben. ',
   'state.clients.button': 'Kunde hinzufügen',
   'state.services.title': 'Gruppen und Dienstleistungen bearbeiten',
   'state.services.description':
      'Gruppen sind vorteilhaft um ähnliche Services in einer Gruppe verwalten zu können. Außerdem wird damit Ihr Kalender sauberer und übersichlicher ausschauen.',
   'state.services.button': 'Service-Gruppe hinzufügen',
   'state.calendar.title': 'Keine zugewiesene Mitarbeiter',
   'state.calendar.description': 'Um Arbeitsstunden einzustellen, benutzen Sie',
   'state.calendar.button': 'die Mitarbeiter-Sektion.',
   'state.closedDates.title': 'Geschlossene Termine',
   'state.closedDates.description':
      'Geben Sie die Daten an, an denen Ihr Unternehmen aufgrund von Feiertagen, Wartungsarbeiten oder aus anderen Gründen geschlossen ist. Kunden können an diesen Tagen keine Online-Buchungen vornehmen.',
   'state.closedDates.button': 'Geschlossenes Datum hinzufügen',
   'title.billingPlan': 'Plan und Rechnungen',
   'btn.upgrade': 'Aktualisieren',
   'header.usage': 'ANWENDUNG',
   'header.dropdown.subscription.plan': '{0} Plan',
   'header.dropdown.subscription.staff': '({0} Mitarbeiter)',
   'header.dropdown.notifications.description': '{0} Nachrichten',
   'header.paymentDetails': 'Zahlungsdetails anzeigen',
   'title.billingPlan.description':
      'Ihr Konto verwalten. Plan- und Rechnungsdaten, Transaktionen anschauen und Rechnungen runterladen. ',
   'plan.free.title': 'Startpaket',
   'plan.individual.title': 'Individuell Paket (nur für 1 Mitarbeiter)',
   'plan.business.title': 'Geschäft',
   'btn.upgradeNow': 'Aktualisieren',
   'btn.getStarted': "Los geht's",
   'btn.currentPlan': 'Aktueller Plan',
   'plan.free.option.0': '1 Standort',
   'plan.free.option.1': 'Bis zu 5 Mitarbeiter',
   'plan.free.option.2': 'Unbegrenzte Termine',
   'plan.free.option.3': 'Unbegrenzte Anzahl an Kunden',
   'plan.free.option.4': 'Unbegrenzte Online-Buchungen',
   'plan.free.option.5': 'E-Mail-Benachrichtigungen',
   'plan.premium.option.0': 'Unbegrenzte Standorte',
   'plan.premium.option.1': 'Unbegrenzte Termine',
   'plan.premium.option.2': 'Unbegrenzte Anzahl an Kunden',
   'plan.premium.option.3': 'Unbegrenzte Online-Buchungen',
   'plan.premium.option.4': 'Wiederkehrende Termine',
   'plan.premium.option.5': 'E-Mail-Benachrichtigungen',
   'plan.premium.option.6': 'SMS-Benachrichtigungen* ',
   'plan.premium.option.7': 'WhatsApp-Benachrichtigungen*',
   'plan.premium.option.8': 'Unbegrenzte Analysen',
   'plan.premium.option.9': 'Unbegrenzte Berichte',
   'plan.premium.option.10': 'Zeitpläne drucken',
   'plan.premium.option.11': 'Kundenexport',
   'plan.premium.option.12': 'Vorrangiger Support',
   'plan.premium.option.13': 'Plandok-Branding aus E-Mails entfernt (kostenlose E-Mails bereitgestellt von Plandok)',
   'plan.premium.costsInfo': '*SMS-/WhatsApp-Kosten sind nicht im Plan enthalten, es fallen zusätzliche Gebühren an.',
   'plan.premium.activeUntilDate': 'Aktiv bis {0}',
   'period.perMonth': 'Pro Monat (exkl. MwSt.)',
   'plan.premium.description': 'Der Preis erhöht sich, wenn Sie weitere Mitarbeiter hinzufügen.',
   'plan.premium.viewPricing': 'Preise ansehen',
   'plan.premium.numberStuff': 'Ihre aktuelle Anzahl von Mitarbeitern: {0}',
   'premium.membership': 'Der Preis der Premium-Mitgliedschaft kann je nach Anzahl Ihrer Mitarbeiter variieren.',
   'premium.price.perMonth': 'Premiumpreis pro Monat',
   'pricing.calculated': 'Der Preis errechnet sich wie folgt:',
   'pricing.calculator': 'Preisrechner',
   'pricing.numberStuff': 'Anzahl der Mitarbeiter',
   'pricing.extra.member': '{0} für jedes zusätzliche Mitglied',
   'modal.title.pricing': 'Preisgestaltung',
   'plan.foreverFree': 'Für immer kostenlos',
   'plan.individual.0': 'Alles was im Starterpaket inbegriffen ist, und noch:',
   'plan.individual.1': 'Individuelle Benachrichtigungen',
   'plan.individual.2': 'Plandok Markenzeichen entfernen',
   'plan.individual.option.3': 'Unbegrenzte SMS-Erinnerungsnachrichten',
   'plan.individual.4': 'Priority-Support',
   'plan.business.0': 'Alles was im Individuell Paket inbegriffen ist, und noch:',
   'plan.business.1': 'Unlimitierte Standorte',
   'plan.business.2': 'Unlimitierte Mitarbeiter',
   'block.billingInfo.title': 'Rechnungsdaten',
   'block.carInfo.title': 'Kreditkartendetails',
   'input.cardNumber.label': 'Kartennummer',
   'input.cardNumber.placeholder': '1234 5678 3456 2456',
   'input.expireDate.label': 'Ablaufdatum',
   'input.expireDate.placeholder': '21.05',
   'input.cvv.label': 'CVV',
   'input.cvv.placeholder': '123',
   'modal.confirmCancel.subscription': 'Wollen Sie die Subskription kündigen?',
   'modal.confirmCancel.subscription.description':
      'Wenn Sie es durchführen, werden Sie wieder die kostelnlose Version nutzen.',
   'btn.cancelSubscription.confirm': 'Ja, meine Subskription löschen',
   'loading.processingSubscribtion': 'Die Zahlung wird bearbeitet, bitte warten...',
   'state.serviceGroup.description': 'Keine Dienstleistungen in der Gruppe',
   'state.serviceGroup.btn': 'Dienstleistung hinzufügen',
   'appointment.status.new': 'Neuer Termin',
   'appointment.status.confirmed': 'Bestätigt',
   'appointment.status.notShow': 'No show',
   'appointment.status.completed': 'Abgeschlossen',
   'appointment.status.cancelled': 'Abgesagt',
   'billing.tab.plan': 'Ihr Plan',
   'billing.tab.costs': 'Voraussichtliche Kosten',
   'billing.tab.billing': 'Rechnungsdaten',
   'billing.tab.invoice': 'Rechnungen',
   'plan.free': 'Kostenlos',
   'plan.deactivated.title': 'Die Prämie wurde deaktiviert',
   'plan.active.title': 'Aktivieren',
   'payment.step.title': 'Zahlungen',
   'payment.error.title': 'Zahlung aktualisieren',
   'payment.payNow.title': 'Zahlen',
   'payment.dismiss.title': 'Zurückweisen',
   'payment.tab.title.sub': 'Abonnement',
   'payment.tab.subTitle': 'Premium Plan',
   'payment.per.members': 'pro Mitglied',
   'payment.tab.title.notification': 'Benachrichtigungen',
   'payment.card.details': 'Kartendetails',
   'payment.success.message': 'Die Zahlung war erfolgreich',
   'payment.payWithCard.title': 'Mit Karte bezahlen',
   'payment.cardNumber.title': 'Kartennummer',
   'payment.expirationDate.title': 'Gültigkeitsdatum',
   'payment.digits.title': '(3 Ziffern)',
   'payment.amount': 'Zahlungsbetrag:',
   'payment.btn.pay': 'Zahlen',
   'invoice.table.date': 'Datum',
   'invoice.table.invoice': 'Rechnung',
   'invoice.table.download': 'Herunterladen',
   'invoice.table.status': 'Status',
   'costs.billingPeriod.title': 'Geschätzte Kosten für diesen Abrechnungszeitraum',
   'costs.billingPeriod.description':
      'Dies sind die laufenden Kosten für Ihre Nutzung in diesem Abrechnungszeitraum. Eine Aufschlüsselung Ihrer Kosten finden Sie unten.',
   'costs.billingPeriod.additionalInfo':
      'Sobald Ihre Benachrichtigungskosten 50 € erreichen, werden sie automatisch berechnet. Aber machen Sie sich keine Sorgen, wenn der Betrag kleiner ist; er wird bequem in Ihrem monatlichen Abonnement enthalten sein.',
   'costs.summary.title': 'Monatliche Zusammenfassung',
   'costs.summary.description': 'Diese Gebühren werden in Ihrem Kontostand berücksichtigt',
   'costs.summary.detailedCharges': 'Detaillierte Gebühren',
   'costs.summary.noData': 'Keine Daten',
   'costs.summary.quantity': 'Menge',
   'costs.summary.subtotal': 'Zwischensumme',
   'costs.summary.taxes': 'Steuern',
   'dropdown.time.hours': 'Stunden',
   'message.planUpgrade': 'Sie haben Ihren Plan erfolgreich aktualisiert!',
   'costs.subscription': 'Abonnement',
   'btn.cancelSubscription': 'Subskription kündigen',
   'btn.no': 'Nein',
   'notification.success.update': 'Erfolgreich aktualisiert',
   'validation.password.match': 'Passwort und Bestätigung stimmen nicht überein',
   'blocked.time': 'Blockierte Zeit',
   'message.successReset.password':
      'Durchgeführt! Die E-Mail, womit Sie Ihr Passwort zurücksetzen können, wurde versendet, überprüfen Sie Ihren Posteingang.',
   'title.newPassword': 'Neues Passwort eingeben',
   'title.newPassword.description':
      'Sie können einen sicheren Zugang zu Ihr Konto haben, wenn Sie ein neues Passwort erstellen.',
   'input.newPasswordRepeat.label': 'Neues Passwort bestätigen',
   'btn.change.password': 'Mein Passwort ändern',
   'text.havingTrouble': 'Haben Sie Probleme? ',
   'link.resetPassword.again': 'Passwort erneut zurücksetzen',
   'message.successNew.password': 'Passwort erfolgreich geändert',
   'title.repeatShifts': 'Wiederholende Schicht',
   'text.confirmShift.update':
      'Sie haben eine wöchentlich wiederholende Schicht geändert. Stornierung der kommenden Schichte von {0} wird {0} andere Einträge ändern. ',
   'text.confirmShift.delete':
      'Sie haben eine wöchentlich wiederholende Schicht geändert. Stornierung der kommenden Schichte von {0} wird {0} andere Einträge ändern. ',
   'btn.deleteUpcoming.shifts': 'Kommende Schichte löschen',
   'btn.deleteThis.shift': 'Diese Schicht löschen',
   'btn.updateUpcoming.shifts': 'Kommende Schichte aktualisieren',
   'btn.updateThis.shift': 'Diese Schicht aktualisieren',
   'text.permisionTab':
      'Berechtigungsstufen zu Sektionen zuordnen. Alle angemeldeten Mitarbeiter haben Zugang zu Kalender, und Inhaber-Kontos haben Zugang zu dem kompletten System.',
   'title.permission.locations': 'Standorte',
   'title.permission.booking': 'Buchung und Kunden',
   'title.permission.staff': 'Mitarbeiter',
   'title.permission.setup': 'Konfiguration',
   'title.permission.basic': 'Basic',
   'title.permission.low': 'Niedrig',
   'title.permission.med': 'Mittel',
   'title.permission.high': 'Hoch',
   'title.permission.owner': 'Inhaber',
   'title.allLocations.permission': 'Zugang zu alle Standorte',
   'title.accessCalendar.permission': 'Zugang zu eigenen Kalender',
   'title.accessOtherCalendar.permission': 'Zugang zu den Kalender anderer Mitarbeiter',
   'title.canBook.permission': 'Kann Termine buchen',
   'title.contactInfo.permission': 'Kann Kundendaten anschauen',
   'title.clients.permission': 'Kunden',
   'title.messages.permission': 'Nachrichten',
   'title.services.permission': 'Dienstleistungen',
   'title.workingHours.permission': 'Arbeitsstunden',
   'title.staffMembers.permission': 'Mitarbeiter',
   'title.permissionLevels.permission': 'Berechtigungsstufen',
   'title.accountSetup.permission': 'Kontoeinstellungen',
   'title.clientSettings.permission': 'Kundeneinstellungen',
   'title.onlineBookings.permission': 'Online-Buchungen',
   'title.analytics.permission': 'Analyse',
   'title.marketing.permission': 'Marketing',
   'notification.type.reminder': 'Erinnerung',
   'notification.type.confirmation': 'Bestätigung',
   'notification.type.online_booking_confirmation': 'Online-Buchungsbestätigung',
   'notification.type.rescheduling': 'Terminverschiebung',
   'notification.type.cancellation': 'Stornierung',
   'notification.type.thank_you': 'Vielen Dank',
   'notification.type.last_visit': 'Letzter Besuch',
   'notification.channel.sms': 'SMS',
   'notification.channel.email': 'E-Mail',
   'notification.channel.whatsapp': 'WhatsApp',
   'notification.status.sent': 'Versenden',
   'notification.status.pending': 'Anhängig',
   'notification.status.failed': 'Fehlgeschlagen',
   'dropdown.time.days': 'Tagen',
   'title.appointment': 'Termin',
   'input.reminderBeforeLastVisit.label': 'Erinnerung wird nach bestimmte Tagen versendet.',
   'txt.with': 'mit',
   'short.hours': 'Std.',
   'short.minutes': 'Min.',
   'input.registrationNumber.label': 'Firmennummer',
   'input.vatNumber.label': 'UST-ID. Nummer',
   'invoice.table.priceTaxIncl': 'Wert',
   'error.wrongSenderId':
      'Absender-ID muss Ziffern oder Zeichnen enthalten, und nicht kürzer als 3, aber nicht länger als 11 Zeichnen lang sein.',
   'sidebar.help': 'Hilfe',
   'modal.help.title': 'Wie können wir helfen?',
   'modal.help.liveChat.title': 'Live-Chat',
   'modal.help.liveChat.description': 'Haben Sie noch Fragen? Fragen Sie uns einfach sofort',
   'modal.help.suggestFeature.title': 'Eine Funktion vorschlagen',
   'modal.help.suggestFeature.description':
      'Teilen Sie Ihre Vorschläge mit, sehen Sie die Vorschläge anderer und stimmen Sie für die gewünschten Funktionen ab.',
   'modal.help.viewUpdates.title': 'Updates anzeigen',
   'modal.help.viewUpdates.description': 'Sehen Sie sich unsere neuesten Aktualisierungen und Verbesserungen an',
   'warning.title.paidFeature': 'Zahlungspflichtige Funktionen',
   'warning.smsFeatureMissing':
      'Um Zugriff zu SMS-Einstellungen zu bekommen, muss Ihren plan aktualisiert werden. Um es durchzuführen klicken Sie',
   'warning.link.billingAndPricing': 'Plan und Rechnungen',
   'warning.newPage.0': 'wurde aktualisiert!',
   'warning.newPage.1':
      'Wenn Sie eine altere Version von Plandok benutzt haben, wird Ihr Passwort nicht mehr funktionieren, bitte',
   'warning.newPage.2': 'setzen Sie Ihr Passwort zurück',
   'warning.newPage.3': 'um das neue System zu genießen. Für weitere Informationen kontaktieren Sie bitte uns',
   'warning.newPage.4': 'oder',
   'title.blockTime.select': 'Wählen Sie eine Zeit aus',
   'calendar.allResources': 'Alle Ressourcen',
   'appointment.edit.title': 'Termin bearbeiten',
   'appointment.create.title': 'Termin erfolgreich erstellt.',
   'appointment.update.title': 'Termin erfolgreich aktualisiert.',
   'select.notify.title': '{0} funktioniert nicht im ausgewählten Intervall, aber Sie können trotzdem Termine buchen.',
   'booking.notify.title': 'Die ausgewählte Zeit ist gebucht, aber Sie können Termin vereinbaren.',
   'textArea.characters.left': 'Zeichen übrig geblieben',
   'textArea.characters.exceeded': 'Limit wurde überschritten mit',
   'validation.characters.exceeded': 'Zeichenlimit wurde überschritten',
   'staff.menu.members': 'Mitarbeiter',
   'staff.menu.dates': 'Nicht-Arbeitstag',
   'staff.menu.working': 'Arbeitsstunden der Mitarbeiter',
   'staff.menu.permissions': 'Benutzerberichtigungen',
   'staff.menu.members.description': 'Teammitgliede hinzufügen oder entfernen und Benutzerberichtigungen verwalten',
   'staff.menu.dates.description': 'Planen Sie bevorstehende Geschäftsschließungen oder Feiertage',
   'staff.menu.working.description':
      'Arbeitsstunden der Mitarbeiter einstellen, um die Kalender-Verfügbarkeit zu verwalten',
   'staff.menu.permissions.description': 'Zugangserlaubnisse für sensitive Funktionen und Informationen einstellen',
   'message.successUpdateLocation': 'Erledigt! Das Standortprofil wurde erfolgreich aktualisiert.',
   'ob.title': 'Online-Buchung',
   'ob.overview.tab': 'Überblick',
   'ob.massMessages.tab': 'Massenmeldungen',
   'ob.campaigns.tab': 'Kampagnen',
   'ob.profile.tab': 'Online-Profil',
   'ob.links.tab': 'Links',
   'ob.settings.tab': 'Einstellungen',
   'ob.payments.tab': 'Zahlungen',
   'ob.status.online': 'Online',
   'ob.status.offline': 'Offline',
   'ob.status.title': 'Status',
   'ob.profile.step': '{0} von 4',
   'ob.profile.step.title': 'Profil & Standort',
   'ob.profile.step.description':
      'Stellen Sie Ihre Filiale vor und stellen Sie die Dienstleistungen vor, die Sie Ihren Kunden anbieten. Das "About" -Teil ist einer der ersten Abschnitte, die Ihre Kunden betrachten. Geben Sie die Adresse ein, an der Sie Ihre Dienste anbieten, damit Ihre Kunden informiert werden können, und sehen Sie sich die Adresse an.',
   'ob.overview.placheholder.title': 'Lassen Sie Ihre Kunden Termine online buchen!',
   'ob.overview.placheholder.description':
      'Erstellen Sie ein Online-Buchungsprofil und ermöglichen Sie Ihren Kunden, Termine online zu buchen. Fügen Sie einfach einen Link zu Ihrer Website oder Ihren sozialen Medien ein und fertig.',
   'ob.overview.start.now': 'Beginnen Sie jetzt',
   'ob.links.direct.title': 'Direkter Link',
   'ob.links.facebook.title': 'Facebook-Link',
   'ob.links.instagram.title': 'Instagram-Link',
   'ob.links.direct.description':
      'URL-Link zu Ihrem Online-Buchungsprofil, Sie können ihn auf Ihrer Website oder in Social-Media-Konten verwenden.  Wenn Sie nicht sicher sind, wie Sie den Link verwenden sollen, kontaktieren Sie uns.',
   'ob.links.facebook.description':
      'Teilen Sie diesen Link auf Facebook, um mehr Kunden anzulocken und es ihnen leichter zu machen, Ihr Unternehmen zu finden und einen Termin zu buchen.',
   'ob.links.instagram.description':
      'Machen Sie es sich einfacher und verknüpfen Sie Ihre Online-Buchung direkt!\nSie können es auf Ihrer Website und überall dort verwenden, wo Sie möchten.\nWenn Sie nicht sicher sind, wie Sie den Link verwenden sollen, machen Sie sich keine Sorgen, wir sind für Sie da!',
   'ob.analytics.tab.description':
      'Fügen Sie Google Analytics und Facebook Pixel zu Ihrer Online-Buchungsseite hinzu, um den Datenverkehr und das Nutzerverhalten zu analysieren.',
   'ob.analytics.google.title': 'Google-Analyse',
   'ob.analytics.facebook.title': 'Facebook-Pixel',
   'ob.analytics.google.description':
      'Mit Google Analytics können Sie den Website-Verkehr und das Nutzerverhalten verfolgen und analysieren, um Erkenntnisse zu gewinnen, wie Sie Ihre Website verbessern können.',
   'ob.analytics.active': 'Aktiv',
   'ob.analytics.inactive': 'Inaktiv',
   'ob.analytics.setUp.btn': 'Einrichten',
   'ob.analytics.google.description.1':
      'Google Analytics ist ein leistungsstarkes Tool, mit dem Sie den Website-Traffic und das Nutzerverhalten überwachen können. Mit Google Analytics können Sie die Anzahl der Besucher auf Ihrer Website verfolgen, woher sie kommen, welche Seiten sie besuchen und wie lange sie auf Ihrer Website bleiben.',
   'ob.analytics.google.description.2':
      'Darüber hinaus können Sie mit Google Analytics Ziele festlegen und Konversationen verfolgen, so dass Sie besser verstehen, wie gut Ihre Website funktioniert und wie Sie sie verbessern können.',
   'ob.analytics.google.trackingId': 'Tracking-ID',
   'ob.analytics.google.tooltip':
      'Um Ihre Tracking-ID zu finden, müssen Sie sich zunächst in Ihrem Google Analytics-Konto anmelden, die Website oder App auswählen, die Sie verfolgen möchten, und dann zur Registerkarte "Verwaltung" navigieren. Klicken Sie dort auf den "Tracking-Code". Ihre Tracking-ID wird im Format "UA-XXXX-Y" angezeigt.',
   'ob.analytics.google.account': 'Sie verfügen noch nicht über ein Google Analytics-Konto?',
   'ob.analytics.click': 'Klicken Sie auf',
   'ob.analytics.here': 'hier',
   'ob.analytics.learn': 'um zu erfahren, wie Sie eines erstellen können.',
   'ob.analytics.facebook.description.1':
      'Facebook Pixel ist ein Tool, mit dem Sie das Nutzerverhalten auf Ihrer Website verfolgen können, einschließlich Konversionen, Seitenaufrufe und andere Ereignisse.',
   'ob.analytics.facebook.description.2':
      'Facebook Pixel kann Ihnen wertvolle Erkenntnisse über Ihr Publikum liefern, wie z. B. demografische Daten, Interessen, Gerätetyp, Seitenaufrufe und durchgeführte Aktionen, die Ihnen helfen können, die Nutzererfahrung auf Ihrer Website besser zu verstehen und zu optimieren.',
   'ob.analytics.facebook.pixelId': 'Pixel-ID',
   'ob.analytics.facebook.tooltip':
      'Führen Sie folgende Schritte aus, um Ihre Facebook-Pixel-ID zu finden: Gehen Sie zu Ihrem Facebook Ads Manager-Konto. Klicken Sie auf die Schaltfläche "Menü" in der oberen linken Ecke des Bildschirms. Wählen Sie unter dem Abschnitt "Ereignis-Manager" die Option "Pixel". Hier sollten Sie eine Liste aller Pixel sehen, die mit Ihrem Konto verbunden sind. Klicken Sie auf den Namen des Pixels, für das Sie die ID benötigen. Ihre Pixel-ID wird in der oberen linken Ecke des Bildschirms neben dem Pixelnamen angezeigt.',
   'ob.analytics.facebook.account': 'Sie haben noch keine Facebook-Geschäftsseite?',
   'ob.analytics.google.account.mobile.1': 'Sie haben noch kein Google Analytics',
   'ob.analytics.google.account.mobile.2': 'konto? Klicken Sie auf',
   'ob.analytics.google.account.mobile.3': 'um zu erfahren, wie Sie',
   'ob.analytics.google.account.mobile.4': 'ein Konto erstellen.',
   'ob.analytics.facebook.account.mobile.1': 'Sie haben noch keine Facebook',
   'ob.analytics.facebook.account.mobile.2': 'unternehmensseite? Klicken Sie auf',
   'ob.analytics.facebook.account.mobile.3': 'um zu erfahren, wie',
   'ob.analytics.facebook.account.mobile.4': 'Sie eine erstellen können.',
   'ob.analytics.successfullySaved.message': 'Erfolgreich gespeichert',
   'ob.settings.booking.title': 'Online-Buchungsverfügbarkeit',
   'ob.settings.booking.description':
      'Legen Sie den Zeitpunkt fest, zu dem die frühesten und spätesten Termine geplant werden können. Verwalten Sie die Zeitintervalle von Terminen und stellen Sie den Kunden wichtige Informationen zur Verfügung, zusammen mit der Möglichkeit, einen Mitarbeiter für ihren Service auszuwählen.',
   'ob.settings.cancellation.title': 'Stornierung und Umplanung',
   'ob.settings.cancellation.description':
      'Legen Sie den Zeitrahmen fest, in dem Kunden ihren Termin absagen oder verschieben können.',
   'ob.settings.locationsUrl.title': 'Standort-URL',
   'ob.settings.locationsUrl.description':
      'Konfigurieren und teilen Sie eine einzige URL für alle online buchbaren Standorte. Dies ist nur für Unternehmen mit mehreren Standorten nützlich.',
   'ob.settings.businessName.explanation': 'Ihre URL: book.plandok.com/business-name/Location2556',
   'ob.settings.notifications.title': 'Benachrichtigungs-E-Mails',
   'ob.settings.notifications.description':
      'Geben Sie den Empfänger von Benachrichtigungs -E -Mails über neue Termine oder Änderungen an, die an sie vorgenommen wurden.',
   'ob.settings.required.title': 'Erforderliche und optionale Felder',
   'ob.settings.required.description': 'Wählen Sie aus, welche Felder der Kunde bei Online-Buchungen angeben muss.',
   'ob.settings.requiredField.title': 'Erforderlich',
   'ob.settings.optionalField.title': 'Optional',
   'ob.settings.requiredField.notification.title': 'Es gab ein Problem mit den Einstellungen',
   'ob.settings.requiredField.notification.description':
      'Entschuldigung, aber ein Feld muss obligatorisch sein, außer "Notizen".',
   'ob.settings.nameField.title': 'Vorname',
   'ob.settings.lastNameField.title': 'Nachname',
   'ob.settings.phoneField.title': 'Telefonnummer',
   'ob.settings.emailField.title': 'E-Mail',
   'ob.settings.notes.label': 'Platzhaltertext für Notizen',
   'ob.settings.notes.placeholder': 'Z.B. Geben Sie Ihre Notizen ein',
   'ob.bookingLimit.title': 'Online Buchungslimit',
   'ob.bookingLimit.description':
      'Bitte geben Sie die maximale Anzahl der Servicebuchungen an, die Sie einstellen möchten.',
   'ob.bookingLimit.label': 'Begrenzung der Servicebuchungen',
   'ob.bookingLimit.unlimited.title': 'Unbegrenzt',
   'ob.bookingLimit.1service.title': 'max. 1 Dienstleistung',
   'ob.bookingLimit.2services.title': 'max. 2 Dienstleistungen',
   'ob.bookingLimit.3services.title': 'max. 3 Dienstleistungen',
   'ob.menu.edit.profile': 'Profil bearbeiten',
   'ob.menu.turnOff.profile': 'ausschalten',
   'ob.profile.status': 'Status',
   'ob.overview.tab.description':
      'Verbessern Sie Ihr Geschäft, indem Sie Ihren Kunden die Online-Registrierung ermöglichen.',
   'ob.profile.tab.description':
      'Verwalten Sie Online -Profile für Ihre Standorte. Passen Sie Bilder, Beschreibungen, Adressen und mehr an.',
   'ob.links.tab.description': 'Hier finden Sie Ihren Profillink, um sie mit Ihren Kunden zu teilen.',
   'ob.settings.tab.description':
      'Passen Sie hier die Online -Buchungseinstellungen an, damit Ihre Kunden nur dann buchen können, wenn dies für Sie bequem ist.',
   'ob.payments.tab.description':
      'Wählen Sie aus, welche Zahlungsmethoden Ihre Kunden bei der Bezahlung von Dienstleistungen verwenden können.',
   'ob.payments.page.description':
      'Hier können Sie auswählen, welche Zahlungsmethoden Ihre Kunden für die Bezahlung von Dienstleistungen verwenden können.',
   'ob.payOnSite.title': 'Vor Ort bezahlen',
   'ob.payOnSite.description': 'Ihre Kunden können vor Ort mit Bargeld bezahlen.',
   'ob.payWithCard.title': 'Mit Karte bezahlen',
   'ob.payWithCard.description': 'Ihre Kunden können mit Kreditkarte über die Stripe-App bezahlen.',
   'ob.payWithCard.info.1': 'Sie nutzen den kostenlosen Plandok-Plan und müssen daher folgende Kosten tragen',
   'ob.payWithCard.info.2': 'Plandok-Provisionsgebühr und Stripe-Provisionsgebühr mit dieser Zahlungsmethode.',
   'ob.payWithCard.info.3': ' zu haben',
   'ob.payWithCard.info.4': 'Plandok-Provisionsgebühren.',
   'ob.payWithCard.info.5': 'Der Premium-Plan befreit nicht von der Zahlung der Stripe-Provisionsgebühren. ',
   'ob.payWithCard.info.6': 'Prüfen Sie die Stripe-Preise hier',
   'ob.stripeConnected.info': '(Stripe angeschlossen)',
   'ob.requiresSetup.info': '(Erfordert die Einrichtung Ihres Stripe-Kontos)',
   'ob.connectToStripe.btn': 'Mit Stripe verbinden',
   'ob.connectToStripe.successMessage.title': 'Erfolgreich mit Stripe verbunden',
   'ob.waitingAuthorization.title': 'Warten Sie auf Ihre Autorisierung in Stripe',
   'ob.stripeDashboard.title': 'Alle Zahlungsprotokolle und Erstattungen sind verfügbar in ',
   'ob.stripeDashboard.url': 'Stripe Dashboard',
   'ob.yourStripeAccount.title': 'Ihr Stripe-Konto',
   'ob.visitStripe.option': 'Stripe besuchen',
   'ob.changeAccount.option': 'Stripe-Konto ändern',
   'input.earliestAppointment.label': 'Frühester Termin',
   'input.earliestAppointment.placeholder': 'Kurz vor dem Termin',
   'input.latestAppointment.label': 'Letzter Termin',
   'input.latestAppointment.placeholder': 'Nicht länger als 6 Monate im Voraus',
   'input.timeSlots.label': 'Zeitfensterintervalle',
   'input.timeSlots.placeholder': '15 Minuten',
   'checkbox.allowCustomers': 'Ermöglichen Sie Kunden, Mitarbeiter für ihren Termin auszuwählen',
   'input.importantInfo.label': 'Wichtige Informationen',
   'input.importantInfo.placeholder':
      'Informationen, die dem Kunden bei der Bestätigung seines Termins angezeigt werden',
   'input.changeAppointment.label': 'Terminänderungen sind möglich',
   'input.changeAppointment.placeholder': 'Jederzeit',
   'checkbox.sendToBooked': 'An den gebuchten Mitarbeiter senden',
   'checkbox.sendSpecificEmail': 'An eine bestimmte E-Mail-Adresse senden',
   'input.specificEmail.label': 'Spezifische E-Mail-Adresse',
   'input.specificEmail.placeholder': 'Geben Sie die E-Mail-Adresse ein',
   'input.locationsUrl.label': 'Standort-URL',
   'input.locationsUrl.placeholder': 'Z.B. Johns-Barber-Shop',
   'ob.btn.save': 'Speichern',
   'ob.btn.cancel': 'Stornieren',
   'ob.btn.showLink': 'Link anzeigen',
   'ob.btn.active': 'Aktiv',
   'ob.btn.inactive': 'Inaktiv',
   'ob.linkModal.title': 'Direkter Link für Buchungen',
   'ob.linkModal.description': 'Hier ist der Link, über den Sie direkt zu Ihren Online-Buchungsdiensten gelangen!',
   'ob.linkModal.afterDescription':
      'Dieser Link führt Benutzer direkt zu Ihren Diensten. Mit nur einem Klick können die Kunden Ihre Unternehmensprofile erreichen und Termine buchen.\nDieser Link ist einfach zu verwenden und für alle Geräte geeignet, sei es ein Computer, ein Telefon oder ein Tablet.',
   'ob.fbLinkModal.title': 'Facebook-Seite Jetzt buchen',
   'ob.fbLinkModal.description':
      'Richten Sie eine integrierte Schaltfläche „Jetzt buchen“ für Ihre Facebook-Unternehmensseite ein, um mühelos neue Buchungen direkt von Facebook zu erhalten.\n',
   'ob.fbLinkModal.step1': 'Melden Sie sich auf Ihrer Unternehmensseite auf Facebook an',
   'ob.fbLinkModal.step2': 'Klicken Sie auf „Schaltfläche hinzufügen“ neben dem Titelbild',
   'ob.fbLinkModal.step3': 'Wählen Sie die Option „Anmelden“ und geben Sie den untenstehenden Buchungslink ein',
   'ob.linkModalCopySuccess.title': 'Link kopiert!',
   'ob.btn.copyLink': 'Link kopieren',
   'title.appointmentSlot.select': 'Wählen Sie die Uhrzeit aus',
   'btn.back': 'Zurück',
   'btn.continue': 'Weitermachen',
   'ob.hours.open': 'Offen',
   'ob.hours.close': 'Geschlossen',
   'ob.hours.step.title': 'Arbeitszeit',
   'ob.hours.step.description': 'Informieren Sie Ihre Kunden, wann Ihr Geschäft öffnet und schließt.',
   'ob.staffHours.step.title': 'Arbeitszeiten der Mitarbeiter',
   'ob.staffHours.step.description':
      'Ein wichtiger Schritt: Bitte fügen Sie die Arbeitszeit hinzu, damit das System wissen kann, wann Kunden online buchen können.',
   'ob.location.step.title': 'Standortinformationen',
   'ob.photo.step.title': 'Fotos',
   'ob.photo.step.description': 'Lassen Sie Ihre Kunden wissen, wie Ihr Geschäftsstandort aussieht.',
   'input.file.dnd.photos': 'Fotos per Drag-and-Drop verschieben',
   'btn.publish': 'Einschalten',
   'text.next': 'Nächste',
   'input.phone.number.label': 'Telefonnummer',
   'input.state.label': 'Zustand',
   'input.state.placeholder': 'Geben Sie ein Bundesland (Gemeinde) ein',
   'input.addressDetails.label': 'Adressdaten',
   'input.addressDetails.placeholder':
      'Geben Sie Adressdaten wie Türcode, andere Eingänge, Parkinformationen usw. ein.',
   'placeholder.no.photos': 'Keine Fotos',
   'btn.options': 'Optionen',
   'btn.list.profile': 'Anmachen',
   'btn.preview': 'Vorschau',
   'btn.next': 'Nächste',
   'market.ob.pleaseWait.title': 'Bitte warten ...',
   'market.ob.location.step.title': 'Standort auswählen',
   'market.ob.services.step.title': 'Wählen Sie Dienste aus',
   'market.ob.employee.step.title': 'Datum und Personal auswählen',
   'market.ob.timeSlot.step.title': 'Datum und Uhrzeit auswählen',
   'market.ob.personalInfo.step.title': 'Zahlung',
   'market.ob.appointmentInfo.step.title': 'Termininformationen',
   'market.ob.informationAboutYou.title': 'Informationen über Sie',
   'market.ob.paymentMethod.title': 'Zahlungsmethode',
   'market.ob.securePayment.title': 'Sichere Bezahlung',
   'market.ob.payWithCard.title': 'Bezahlen mit Karte',
   'market.ob.payOnSite.title': 'Vor Ort bezahlen',
   'market.ob.payOnSite.firstDescription': 'Sie müssen nach dem Eingriff im Salon bezahlen.',
   'market.ob.payOnSite.secondDescription':
      'Eine kleine Anfrage von uns: Unternehmen sind oft kleine unabhängige Unternehmen. Wenn Sie es also nicht zu Ihrem geplanten Termin schaffen, planen oder stornieren Sie es.',
   'market.ob.validThru.title': 'Gültig bis (Monat/Jahr)',
   'market.ob.information.title': 'Informationen',
   'market.ob.additionalInformation.title': 'Zusätzliche Informationen',
   'market.ob.reschedulingPolicy.title': 'Stornierung/Terminverschiebung',
   'market.ob.reschedulingPolicy.description': 'Terminänderungen können {0} vor dem Termin vorgenommen werden.',
   'market.ob.fieldsAreRequired.title': '* Felder sind erforderlich',
   'market.ob.step.title': 'Schritt {0} von 3',
   'market.ob.confirm.step.title': 'Ansehen und bestätigen',
   'market.ob.noServices.placeholder': 'Noch keine Dienste ausgewählt',
   'market.ob.input.firstName.label': '{0} Vorname',
   'market.ob.input.firstName.placeholder': 'e.g. John',
   'market.ob.input.lastName.label': '{0} Nachname',
   'market.ob.input.lastName.placeholder': 'e.g. Damhirschkuh',
   'market.ob.input.phone.label': '{0} Handynummer',
   'market.ob.input.phone.placeholder': '670 70 822',
   'market.ob.input.email.label': '{0} E-Mail Adresse',
   'market.ob.input.email.placeholder': 'john.damhirschkuh@gmail.com',
   'market.ob.input.description.label': 'Anmerkungen',
   'market.ob.staff.assignedSpecialist.label': 'mit Mitarbeitern',
   'market.ob.staff.noPreference.label': 'Keine Präferenz',
   'market.ob.timeSlots.fullyBooked.title': 'Leider haben wir für diesen Tag keine freien Zeiten.',
   'market.ob.timeSlots.choseAnotherDay.title': 'Bitte wählen Sie einen anderen Tag.',
   'market.ob.timeSlots.closedSlot.title': 'Die nächstgelegene verfügbare Zeit ist ',
   'market.ob.timeSlots.selectClosest.title': 'Wählen Sie das nächstgelegene Datum aus',
   'market.ob.timeSlots.note.title': 'Wichtige Informationen',
   'market.ob.service.duration.title': 'min',
   'market.location.workingHours.title': 'Arbeitszeit',
   'market.location.monday.title': 'Montag',
   'market.location.tuesday.title': 'Dienstag',
   'market.location.wednesday.title': 'Mittwoch',
   'market.location.thursday.title': 'Donnerstag',
   'market.location.friday.title': 'Freitag',
   'market.location.saturday.title': 'Samstag',
   'market.location.sunday.title': 'Sonntag',
   'market.location.meetOurTeam.title': 'Treffen Sie unser Team',
   'market.location.title': 'Kontakt aufnehmen',
   'market.location.services.title': 'Dienstleistungen',
   'market.location.about.title': 'Über',
   'market.location.aboutUs.title': 'Über uns',
   'market.confirmation.appointmentCanceled.title': 'Dieser Termin wurde abgesagt',
   'market.confirmation.appointmentConfirmed.title': 'Ernennung bestätigt',
   'market.confirmation.appointmentConfirmed.description': 'Ihr Termin ist bestätigt, hier sind die Details:',
   'market.confirmation.appointmentDate.title': 'Datum der Verabredung',
   'market.confirmation.scheduledAppointment.title': 'Eine Stornierung ist online nicht möglich.',
   'market.confirmation.scheduledAppointment.description':
      'Wenn Sie Ihren Termin nicht wahrnehmen können, verschieben Sie ihn bitte oder sagen Sie ihn ab.',
   'market.modal.cancelAppointment.title': 'Sind Sie sicher, dass Sie diesen Termin absagen möchten ?',
   'market.modal.cancelledAppointment.title': 'Termin wurde abgesagt',
   'market.language.changer.title': 'Sprache (Language)',
   'market.btn.bookNow': 'Reservieren',
   'market.servicesAvailable.title': 'Dienstleistungen',
   'market.poweredByPlandok.title': 'Plandok',
   'market.poweredByPlandok.description': 'Möchten Sie eine ähnliche Website ? Besuchen Sie',
   'market.btn.readMore': 'Mehr lesen',
   'market.btn.showLess': 'Weniger anzeigen',
   'market.btn.reviews': 'Bewertungen',
   'market.btn.viewMore': 'Mehr sehen',
   'market.btn.cancelAppointment': 'Termin absagen',
   'market.btn.cancel': 'Ja, absagen',
   'market.wh.closed.title': 'Geschlossen',
   'market.ob.success.message': 'Erledigt! Die Buchung wurde erfolgreich erstellt',
   'market.ob.cancelAppointment.success.message': 'Termin erfolgreich abgesagt',
   'market.ob.cancelAppointment.error.message': 'Die Terminabsage ist fehlgeschlagen',
   'market.ob.cancelAppointment.confirm.title': 'Sind Sie sicher, dass Sie einen Termin stornieren möchten?',
   'market.location.getDirections.title': 'Wegbeschreibung abrufen',
   'market.notFound.title': 'Seite nicht gefunden',
   'market.notFound.btn.goBack': 'Zurückgehen',
   'market.ob.quantitativeLimit.title': 'Quantitative Begrenzung',
   'market.ob.quantitativeLimit.description':
      'Leider hat der Eigentümer die Anzahl der Buchungsdienste begrenzt, so dass nur {0} Dienstleistun{0} zur Auswahl stehen',
   'market.ob.quantitativeLimit.three': 'drei',
   'market.ob.quantitativeLimit.two': 'zwei',
   'market.ob.quantitativeLimit.one': 'einer',
   'market.ob.quantitativeLimit.serviceSingular': 'g',
   'market.ob.quantitativeLimit.servicesPlural': 'gen',
   'validation.characters.minLength': 'Dieser Wert ist zu kurz. Es sollte mindestens 3 Zeichen lang sein.',
   'not.found.btn.label': 'Zurück',
   'not.found.subTitle.label': 'Leider existiert die von Ihnen besuchte Seite nicht.',
   'analytics.title': 'Analytics',
   'analytics.dashboard.tab': 'Armaturenbrett',
   'analytics.reports.tab': 'Berichte',
   'analytics.premium.title': 'Prämie',
   'analytics.totalAppointments.title': 'Gesamtzahl der Termine',
   'analytics.cancellationReasons.title': 'Top-Stornierungsgründe',
   'analytics.occupancy.title': 'Belegung',
   'analytics.topServices.title': 'Top-Leistungen',
   'analytics.clients.title': 'Kunden',
   'analytics.completed.title': 'Vollendet',
   'analytics.notCompleted.title': 'Nicht abgeschlossen',
   'analytics.canceled.title': 'Abgesagt',
   'analytics.noShowAppointments.title': 'No-Show-Termine',
   'analytics.reasonName.title': 'Grundname',
   'analytics.reasonCount.title': 'Zählen',
   'analytics.lastPeriod.title': 'letzte Periode',
   'analytics.workingHours.title': 'Arbeitszeit',
   'analytics.bookedHours.title': 'Gebuchte Stunden',
   'analytics.blockedHours.title': 'Gesperrte Stunden',
   'analytics.unbookedHours.title': 'Nicht gebuchte Stunden',
   'analytics.service.title': 'Service',
   'analytics.thisMonth.title': 'Dieser Zeitraum',
   'analytics.lastMonth.title': 'Letzte Periode',
   'analytics.totalClients.title': 'Gesamtzahl der Kunden',
   'analytics.averageVisits.title': 'Durchschnittliche Besuche pro Kunde ',
   'analytics.newClients.title': 'Neue Kunden',
   'analytics.returningClients.title': 'Wiederkehrende Kunden',
   'analytics.returnRate.title': 'Kundenrücklaufquote',
   'analytics.clientsByGender.title': 'Kunden nach Geschlecht',
   'analytics.clientsBySource.title': 'Kunden nach Referenzquelle',
   'analytics.clientsByAge.title': 'Kunden nach Alter',
   'analytics.timeRange.custom': 'Brauch',
   'analytics.timeRange.today': 'Heute',
   'analytics.timeRange.yesterday': 'Gestern',
   'analytics.timeRange.last1day': 'Letzter 1 Tag',
   'analytics.timeRange.last7days': 'Letzte 7 Tage',
   'analytics.timeRange.last30days': 'Letzte 30 Tage',
   'analytics.timeRange.last90days': 'Letzte 90 Tage',
   'analytics.timeRange.last3months': 'Letzte 3 Monate',
   'analytics.timeRange.lastMonth': 'Letzten Monat',
   'analytics.timeRange.last6Months': 'Letzte 6 Monate',
   'analytics.timeRange.lastYear': 'Letztes Jahr',
   'analytics.timeRange.last2year': 'Letzte 2 Jahre',
   'analytics.timeRange.thisWeek': 'Diese Woche',
   'analytics.timeRange.thisMonth': 'Diesen Monat',
   'analytics.timeRange.thisYear': 'Dieses Jahr',
   'analytics.timeRange.tomorrow': 'Morgen',
   'analytics.timeRange.next7days': 'Nächste 7 Tage',
   'analytics.timeRange.next30days': 'Nächste 30 Tage',
   'analytics.menu.dashboard.description': 'Erkunden Sie Ihre Geschäftsdaten in Diagrammen und Statistiken',
   'analytics.menu.reports.description': 'Erkunden Sie Ihre Geschäftsdaten in Berichten',
   'analytics.timeRange.allTime': 'Alle Zeiten',
   'analytics.timeRange.label': 'Zeitbereich',
   'analytics.services.lastPeriod.label': 'Letzte Periode',
   'analytics.totalAppointments.appointment.label': 'Terminen',
   'analytics.totalAppointments.onlineBook.label': 'Online-Buchungen',
   'analytics.gender.male': 'Ein Mann',
   'analytics.gender.female': 'Eine Frau',
   'analytics.gender.unknown': 'Unbekannt',
   'analytics.noData': 'Für den ausgewählten Zeitraum liegen keine Daten vor',
   'analytics.premium.info.title': 'Upgrade auf Premium-Version Analytik für länger als 7 Tage zu sehen.',
   'analytics.premium.upgradeButton.title': 'AKTIVIEREN SIE',
   'analytics.premium.skipButton.title': 'Überspringen',
   'reports.appointments.title': 'Termine',
   'reports.appointments.description':
      'Sehen Sie sich die prognostizierten Einnahmen anstehender Termine an, verfolgen Sie die Stornierungsraten und -gründe.',
   'reports.appointmentsList.title': 'Terminliste',
   'reports.appointmentsList.description': 'Zeigt alle Termine mit zugehörigen Informationen an.',
   'reports.appointmentsSummary.title': 'Zusammenfassung der Termine',
   'reports.appointmentsSummary.description': 'Zeigt Termine nach Personal und Service an.',
   'reports.appointmentsCancellations.title': 'Terminabsagen',
   'reports.appointmentCancellations.title': 'Terminstornierungen',
   'reports.appointmentsCancellations.description':
      'Zeigt alle Gründe und Mengen für abgesagte Termine für den von Ihnen ausgewählten Zeitraum an.',
   'reports.summary.title': 'Zusammenfassung',
   'reports.clients.title': 'Kunden',
   'reports.clients.description':
      'Erhalten Sie Erkenntnisse darüber, wie Kunden mit Ihrem Unternehmen interagieren und wer Ihre größten Geldgeber sind',
   'reports.clientsList.title': 'Kunden',
   'reports.clientsList.description': 'Liste der Kunden mit ihren Daten',
   'reports.exportCsv.title': 'CSV exportieren',
   'reports.appointmentsByStaff.title': 'Termine durch Mitarbeiter',
   'reports.appointmentsByService.title': 'Terminvergabe nach Service',
   'reports.clearFilters.button.title': 'Filter löschen',
   'reports.filterStaff.placeholder': 'Alle Mitarbeiter',
   'reports.filterLocations.placeholder': 'Alle Standorte',
   'reports.filters.title': 'Filter',
   'reports.locations.mobile.title': 'Standorte:',
   'reports.staff.mobile.title': 'Personal:',
   'reports.filters.apply.title': 'Anwenden',
   'reports.search.placeholder': 'Suche nach Referenz oder Kunde',
   'reports.subscription.description': '{0} Plan (nur {0} Mitarbeiter)',
   'reports.notifications.description': '{0} Nachrichten ({0})',
   'serviceGroup.addCategory.description': 'Kategorien sind wichtig, da Sie damit ähnliche Dienste gruppieren können.',
   'serviceGroup.addCategory.title': 'Kategorie hinzufügen',
   'app.install.title': 'Installieren Sie Plandok',
   'app.install.description.title':
      'Durch die Installation wird fast kein Speicherplatz benötigt und Sie können schnell zu dieser App zurückkehren',
   'app.install.button.title': 'Installieren',
   'app.skip.button.title': 'Überspringen',
   'app.actionUndone.button.title': 'Aktion rückgängig gemacht',
   'app.appointmentCancelled.button.title': 'Ernennung gelöscht',
   'app.undo.button.title': 'Rückgängig machen',
   'app.showAllTimes.button.title': 'Alle Zeiten anzeigen',
   'app.install.success.title': 'App erfolgreich installiert!',
   'rp.upgradeToPremiumLink.title': 'Upgrade auf Premium',
   'rp.upgradeToPremium.title': 'um diese Funktion zu nutzen',
   'rp.repeatAppointment.title': 'Wiederholen',
   'rp.input.frequency.title': 'Frequenz',
   'rp.input.endsAfter.title': 'Ende',
   'rp.input.selectDate.title': 'Datum auswählen',
   'rp.upgrade.title': 'Aktualisieren Sie Ihren Plan',
   'rp.upgrade.description.title':
      'Die Premium-Version von Plandok ist nicht aktiviert. Aktualisieren Sie Ihren Plan, um vollen Zugriff auf alle von Plandok bereitgestellten Funktionen zu erhalten.',
   'rp.upgrade.button.title': 'Upgrade auf Premium',
   'rp.repeating.title': 'Wiederholen',
   'rp.repeats.title': 'Wiederholt',
   'rp.repeatsUntil.title': 'bis',
   'rp.repeatingTimes.title': '({0} Mal)',
   'rp.cancel.upcoming.title': 'Löschen Sie alle anstehenden Wiederholungstermine',
   'rp.update.upcoming.title': 'Aktualisieren Sie alle bevorstehenden Wiederholungstermine',
   'rp.edit.appointment.title': 'Termin aktualisieren',
   'rp.edit.appointment.description.title':
      'Sie bearbeiten einen wiederkehrenden Termin. Wählen Sie aus, welche Termine aktualisiert werden sollen:',
   'rp.edit.thisAppointment.title': 'Nur diesen Termin aktualisieren',
   'rp.edit.allAppointments.title': 'Aktualisieren Sie alle bevorstehenden Termine',
   'rp.edit.saveAppointment.title': 'Speichern',
   'modal.cancelSubscriptionError.title': 'Fehler beim Abbrechen des Abonnements',
   'modal.cancelSubscriptionError.text.1': 'Der kostenlose Plan ist nur für maximal 5',
   'modal.cancelSubscriptionError.text.2': 'mitarbeitern',
   'modal.cancelSubscriptionError.text.3': 'und 1',
   'modal.cancelSubscriptionError.text.4':
      'Um die kostenlose Version zu nutzen, entfernen Sie Standorte / Mitarbeiter, um die Anforderungen zu erfüllen.',
   'modal.latestPaymentFailed.title': 'Letzte Zahlung fehlgeschlagen',
   'modal.latestPaymentFailed.text.1':
      'Um Zugang zu allen Funktionen zu erhalten, zahlen Sie bitte oder verwenden Sie die kostenlose Version',
   'modal.latestPaymentFailed.text.2':
      ', um die Anforderungen zu erfüllen. Der kostenlose Plan ist nur mit maximal 5 Mitarbeitern und 1 Standort verfügbar.',
   'modal.extraCostsForNewMember.title': 'Zusätzliche Kosten für neue Mitglieder',
   'modal.extraCostsForNewMember.description':
      'Wenn Sie die Anzahl der Mitarbeiter erhöhen, entstehen zusätzliche Kosten für Ihr monatliches Abonnement.',
   'modal.premiumUpgradeRequired.title': 'Premium-Upgrade erforderlich',
   'modal.premiumUpgradeRequired.description':
      'Um weitere {0} hinzuzufügen, muss Ihr Plan auf Premium aufgerüstet werden',
   'modal.premiumUpgradeRequired.location': 'standorte',
   'modal.premiumUpgradeRequired.staffMembers': 'mitarbeiter',
   'modal.changeAccount.title': 'Stripe-Konto wechseln?',
   'modal.changeAccount.description':
      'Während Sie Ihr Konto wechseln, können Sie keine Zahlungen annehmen. Es dauert etwa eine Minute, um die Änderung vorzunehmen.',
   'btn.agree': 'Zustimmen',
   'btn.decline': 'Ablehnen',
   'paymentMethod.paymentMethod.title': 'Zahlungsmethode',
   'paymentMethod.currentPaymentMethod.title': 'Aktuelle Zahlungsmethode',
   'paymentMethod.expirationDate': 'Verfallsdatum {0}',
   'paymentMethod.otherPaymentMethods.title': 'Andere Zahlungsarten',
   'paymentMethod.addAnotherPaymentMethod.btn': 'Weitere Zahlungsmethode hinzufügen',
   'paymentMethod.addPaymentMethod.title': 'Zahlungsmethode hinzufügen',
   'paymentMethod.noPaymentMethodAdded.title': 'Keine Zahlungsmethode hinzugefügt',
   'paymentMethod.noPaymentMethodAdded.description': 'Zahlungsmethode wird bei Auswahl des Premium-Tarifs hinzugefügt',
   'paymentMethod.goToPlanPage.btn': 'Zur Plan-Seite gehen',
   'paymentMethod.add.btn': 'hinzufügen',
   'paymentMethod.makeDefault.btn': 'Standard machen',
   'paymentMethod.cardHasBeenAdded.message': 'Karte wurde hinzugefügt',
   'paymentMethod.cardHasBeenAssign.message': 'Karte wurde zugewiesen',
   'page.marketing.overview.messages.description':
      'Senden Sie Ihren Kunden mit wenigen Mausklicks individuelle Marketingnachrichten per SMS oder E-Mail',
   'page.marketing.overview.btn.sendMessage': 'Nachricht senden',
   'page.marketing.overview.campaigns.title': 'Automatisch versendete Kampagnen',
   'page.marketing.overview.campaigns.description':
      'Erstellen Sie personalisierte Kampagnen auf der Grundlage von Ereignissen wie Geburtstagen von Kunden und mehr. \nVersenden Sie Kampagnen über mehrere Kanäle per E-Mail und SMS',
   'page.marketing.overview.btn.newCampaign': 'Neue Kampagne',
   'page.marketing.massMessages.title': 'Neue Nachricht einrichten',
   'page.marketing.massMessages.messageLog': 'Nachrichtenprotokoll',
   'page.marketing.massMessages.sendOn': 'Senden an',
   'page.marketing.massMessages.sendTo': 'Senden an',
   'page.marketing.massMessages.channel': 'Kanal',
   'page.marketing.campaigns.description':
      'Senden Sie mit wenigen Klicks automatisierte Marketingnachrichten per SMS oder E-Mail an Ihre Kunden',
   'page.marketing.campaigns.birthdaySpecial.title': 'Geburtstags-Spezial',
   'page.marketing.campaigns.winBackClients.title': 'Kunden zurückgewinnen',
   'page.marketing.campaigns.welcomeNewClients.title': 'Begrüßen Sie neue Kunden',
   'page.marketing.campaigns.rewardRegularClients.title': 'Stammkunden belohnen',
   'page.marketing.campaigns.birthdaySpecial.description':
      'Überraschen Sie Ihre Kunden an ihrem Ehrentag. Diese Kampagne wird automatisch an Kunden in der Nähe ihres Geburtstages gesendet',
   'page.marketing.campaigns.winBackClients.description':
      'Ermuntern Sie unzufriedene Kunden mit einem speziellen Angebot zur Rückkehr. Diese Kampagne wird an Kunden verschickt, die nach einer bestimmten Zeit nicht wiedergekommen sind',
   'page.marketing.campaigns.welcomeNewClients.description':
      'Verwandeln Sie Neukunden in Stammkunden, indem Sie Erstkunden mit einem Sonderrabatt zur erneuten Buchung ermutigen. Diese Kampagne wird automatisch einen Tag nach dem ersten Verkauf an den Kunden gesendet',
   'page.marketing.campaigns.rewardRegularClients.description':
      'Überraschen Sie Top-Kunden mit einem Sonderangebot, um Ihre besten Kunden noch stärker zu binden. Diese Kampagne wird automatisch an Kunden gesendet, die kürzlich einen Termin wahrgenommen haben',
   'page.marketing.campaigns.btn.active': 'Aktiv',
   'page.marketing.campaigns.btn.inactive': 'Inaktiv',
   'page.marketing.campaigns.btn.startCampaign': 'Kampagne starten',
   'page.marketing.campaigns.btn.editCampaign': 'Kampagne bearbeiten',
   'page.marketing.campaigns.btn.resetCampaign': 'Kampagne bearbeiten',
   'modal.resetCampaign.description': 'Sind Sie sicher, dass Sie diese Kampagne zurücksetzen wollen ?',
   'modal.resetCampaign.btn.yesReset': 'Ja, zurücksetzen',
   'page.marketing.smsCreate.step.1': 'SMS-Einrichtung. Schritt 1 von 4',
   'page.marketing.smsCreate.step.2': 'SMS-Einrichtung. Schritt 2 von 4',
   'page.marketing.smsCreate.step.3': 'SMS-Einrichtung. Schritt 3 von 4',
   'page.marketing.smsCreate.step.4': 'SMS-Einrichtung. Schritt 4 von 4',
   'page.marketing.emailCreate.step.1': 'E-Mail-Einrichtung. Schritt 1 von 4',
   'page.marketing.emailCreate.step.2': 'E-Mail-Einrichtung. Schritt 2 von 4',
   'page.marketing.emailCreate.step.3': 'E-Mail-Einrichtung. Schritt 3 von 4',
   'page.marketing.emailCreate.step.4': 'E-Mail-Einrichtung. Schritt 4 von 4',
   'page.marketing.campaignCreate.step.1': 'Kampagnen-Einrichtung. Schritt 1 von 3',
   'page.marketing.campaignCreate.step.2': 'Kampagnen-Einrichtung. Schritt 2 von 3',
   'page.marketing.campaignCreate.step.3': 'Kampagnen-Einrichtung. Schritt 3 von 3',
   'page.marketing.smsCreate.step.1.title': 'Nachricht und Absender-ID',
   'page.marketing.smsCreate.step.1.defaultTitleForSms': 'SMS-Absender-ID eingeben',
   'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Geben Sie Ihre Nachricht ein',
   'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Titel der Nachricht eingeben',
   'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Geben Sie Ihre Nachricht ein',
   'page.marketing.emailCreate.step.1.title': 'E-Mail-Einstellungen',
   'page.marketing.emailCreate.step.1.messageTitle': 'Titel der Nachricht',
   'page.marketing.emailCreate.step.1.replyToEmail': 'Antwort an E-Mail',
   'page.marketing.emailCreate.step.1.clientReplies': 'Die Antworten des Kunden werden an diese E-Mail gesendet',
   'page.marketing.emailCreate.step.1.photo.title': 'Foto',
   'page.marketing.emailCreate.step.1.enablePhoto': 'Foto aktivieren',
   'page.marketing.emailCreate.step.1.changePhoto': 'Foto ändern',
   'page.marketing.emailCreate.step.1.button.title': 'Schaltfläche',
   'page.marketing.emailCreate.step.1.enableButton': 'Aktivieren Sie die Schaltfläche',
   'page.marketing.emailCreate.step.1.buttonName': 'Name der Schaltfläche',
   'page.marketing.emailCreate.step.1.buttonURL': 'URL der Schaltfläche',
   'page.marketing.emailCreate.step.1.socialMedia.title': 'Soziale Medien',
   'page.marketing.emailCreate.step.1.enableSocialMedia': 'Aktivieren Sie Links zu sozialen Medien',
   'page.marketing.emailCreate.step.1.facebookPage': 'Facebook-Seite',
   'page.marketing.emailCreate.step.1.instagramPage': 'Instagram-Seite',
   'page.marketing.emailCreate.step.1.yourPage': 'Ihre Seite',
   'page.marketing.smsCreate.yourMessage': 'Deine Nachricht',
   'page.marketing.btn.sendMeTestMessage': 'Senden Sie mir eine Testnachricht',
   'page.marketing.smsCreate.step.1.freeTests': '{0} kostenlose Tests für heute übrig',
   'page.marketing.smsCreate.messagePreview': 'Vorschau der Nachricht',
   'page.marketing.smsCreate.btn.clientSelection': 'Auswahl der Kunden',
   'page.marketing.audience.title': 'Zielgruppe',
   'page.marketing.smsCreate.step.2.description': 'Wählen Sie, welche Kunden Ihre Nachricht erhalten sollen',
   'page.marketing.smsCreate.step.2.allClient.title': 'Alle Kunden',
   'page.marketing.smsCreate.step.2.allClient.description': 'Nachricht an alle Ihre Kunden senden',
   'page.marketing.smsCreate.step.2.clientGroups.title': 'Kundengruppen',
   'page.marketing.smsCreate.step.2.clientGroups.description': 'Nachricht an ausgewählte Kundengruppen senden',
   'page.marketing.smsCreate.step.2.totalClients': 'Kunden gesamt',
   'page.marketing.smsCreate.step.2.totalPrice': 'Gesamtpreis',
   'page.marketing.smsCreate.step.3.description': 'Wählen Sie die Zeit, zu der Sie die Nachricht senden möchten',
   'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Benutzerdefiniertes Datum und Uhrzeit auswählen',
   'page.marketing.smsCreate.step.4.title': 'Zahlung für Massennachricht',
   'page.marketing.smsCreate.step.4.description': 'Geben Sie Ihre Kreditkartendaten ein und bezahlen Sie',
   'page.marketing.smsCreate.step.4.paymentAmount': 'Zahlungsbetrag:',
   'btn.scheduleSend': 'Zeitplan für den Versand',
   'btn.previous': 'Vorherige',
   'btn.payment': 'Zahlung',
   'btn.payAndFinish': 'Bezahlen und beenden',
   'btn.backToDashboard': 'Zurück zum Dashboard',
   'btn.saveAndClose': 'Speichern und schließen',
   'modal.editClientSelection.title': 'Kundenauswahl bearbeiten',
   'modal.newClient.description': 'In der letzten Zeit hinzugefügte Kunden:',
   'modal.recentClient.title': 'Letzter Kunde',
   'modal.recentClient.description': 'Kunden, die in der letzten Zeit besucht wurden:',
   'modal.clientByAge.title': 'Kunden nach Alter',
   'modal.clientsByGender.title': 'Kunden nach Geschlecht',
   'modal.paymentConfirmation.title': 'Zahlung erfolgreich !',
   'modal.paymentConfirmation.messageSend': 'Ihre Nachricht wird gesendet',
   'modal.previewMessage.title': 'Vorschau Nachricht',
   'modal.previewSms.tab': 'Vorschau SMS',
   'modal.previewEmail.tab': 'Vorschau E-Mail',
   'modal.editServiceSelection.title': 'Dienstauswahl bearbeiten',
   'modal.editServiceSelection.allServices': 'Alle Dienste',
   'modal.campaignActivated.title': 'Kampagne aktiviert !',
   'modal.campaignActivated.description': 'Sie können diese Kampagne jederzeit unterbrechen oder bearbeiten',
   'btn.all': 'Alle {0}',
   'btn.included': 'Eingeschlossen {0}',
   'btn.excluded': 'Ausgeschlossen {0}',
   'checkbox.allClients': 'Alle Kunden {0}',
   'scheduleSend.time': 'zeit',
   'scheduleSend.timeSetTo': 'Zeit eingestellt auf:',
   'scheduleSend.setTime': 'Eingestellte Zeit',
   'campaignSetup.step.1.header.title': 'Kampagnen-Einstellung',
   'campaignSetup.step.2.header.title': 'Nachricht bearbeiten',
   'campaignSetup.step.3.header.title': 'Überprüfen',
   'campaignSetup.channel.description': 'Wählen Sie die Kanäle, über die die Kampagne gesendet werden soll',
   'campaignSetup.smsSetup.tab': 'SMS-Einstellungen',
   'campaignSetup.emailSetup.tab': 'E-Mail-Einstellungen',
   'campaignSetup.almostDone.title': 'Fast fertig !',
   'campaignSetup.almostDone.description':
      'Ihre intelligente Kampagne ist einsatzbereit. Sobald sie aktiviert ist, wird sie fortlaufend automatisch Nachrichten an Kunden senden. Sie können diese Kampagne jederzeit leicht ändern oder unterbrechen',
   'campaignSetup.approximatePrice.header': 'Ungefährer Preis',
   'campaignSetup.approximatePrice.description': 'Für die nächsten 30 Tage',
   'campaignSetup.approximatePrice.approx': 'Ungefähr',
   'campaign.birthdaySpecial.description':
      'Diese Kampagne sendet automatisch an Kunden, die kurz vor ihrem Geburtstag stehen',
   'campaign.birthdaySpecial.sendToClient': 'An Kunden senden:',
   'campaign.birthdaySpecial.selectOption.1': 'Am Tag des Geburtstags',
   'campaign.birthdaySpecial.selectOption.2': 'Drei Tage vor dem Geburtstag',
   'campaign.birthdaySpecial.selectOption.3': 'Eine Woche vor dem Geburtstag',
   'campaign.birthdaySpecial.selectOption.4': 'Zwei Wochen vor dem Geburtstag',
   'campaign.winBackClients.description':
      'Diese Kampagne sendet an Kunden, die nach einem bestimmten Zeitraum nicht wiedergekommen sind',
   'campaign.winBackClients.clientsWhoDidntReturn': 'Kunden, die im letzten Jahr nicht wiedergekommen sind',
   'campaign.winBackClients.appliedTo': 'Angewandt auf',
   'campaign.winBackClients.all': ' alle ',
   'campaign.winBackClients.services': 'Dienstleistungen',
   'campaign.winBackClients.editSelection': 'Auswahl bearbeiten',
   'campaign.welcomeNewClients.description':
      'Machen Sie Neukunden zu Stammkunden, indem Sie Erstkunden mit einem Sonderrabatt zu einer erneuten Buchung animieren',
   'campaign.welcomeNewClients.smartCampaignNewClients':
      'Diese intelligente Kampagne sendet automatisch an neue Kunden einen Tag nach deren erstem Verkauf',
   'campaign.rewardRegularClients.description':
      'Diese Kampagne wird automatisch an Kunden gesendet, die kürzlich einen Termin wahrgenommen haben',
   'campaign.rewardRegularClients.clientsWithAtLeast': 'Kunden mit mindestens',
   'campaign.rewardRegularClients.appointmentsInThe': 'Terminen in den',
   'campaign.rewardRegularClients.last': 'letzten',
   'campaign.rewardRegularClients.appointments': 'Terminen',
   'campaign.rewardRegularClients.inTheLast': 'in den letzten',
   'campaign.rewardRegularClients.months': 'monaten',
   'subscriptionPaymentFailed.title':
      'Bitte aktualisieren Sie Ihre Zahlungsmethode bis {0}, andernfalls werden alle Premium -Funktionen eingestellt, danke.',
   'subscriptionCancelled.title':
      'Leider wurde Ihr Premium-Plan wegen Nichtzahlung deaktiviert. Wenn Sie die Premium-Funktionen weiterhin nutzen möchten, aktivieren Sie bitte den Premium-Plan.',
   'notificationPaymentFailed.title':
      'Die Zahlung für Nachrichten ist fehlgeschlagen. Bitte leisten Sie die Zahlung bis zum {0}, sonst werden die Benachrichtigungen deaktiviert, danke.',
   'notificationCancelled.title':
      'Leider wurden Ihre Nachrichten wegen Nichtzahlung deaktiviert. Wenn Sie weiterhin Nachrichten senden möchten, zahlen Sie bitte, vielen Dank.',
   'btn.hideOptions': 'Optionen ausblenden',
   'btn.showOptions': 'Optionen anzeigen',
   'notification.googleDisconnected': 'Google Kalender nicht verbunden',
   'notification.googleConnected': 'Google Calendar verbunden',
   'notification.appleDisconnected': 'Apple Calendar nicht verbunden',
   'notification.appleConnected': 'Apple Calendar verbunden',
   'notification.microsoft365Disconnected': 'Microsoft365 Kalender nicht verbunden',
   'notification.microsoft365Connected': 'Microsoft365 Kalender verbunden',
   'notification.linkCopied': 'Link kopiert',
   'calendarSync.title': 'Kalender synchronisieren',
   'calendarSync.description':
      'Synchronisieren Sie Plandok-Termine mit Ihrem Lieblingskalender, um mühelos zu organisieren und kein wichtiges Ereignis zu verpassen.',
   'googleCalendar.title': 'Google-Kalender',
   'appleCalendar.title': 'Apple-Kalender',
   'microsoft365Calendar.title': 'Microsoft365-Kalender',
   'linkYourCalendar.title': 'Verknüpfen Sie Ihre Kalender',
   'connect.btn': 'Verbinden Sie',
   'disconnect.rtn': 'Trennen Sie die Verbindung',
   'connected.label': 'Verbunden',
   'modal.workingHours.standartMethod.btn': 'Standard-Methode',
   'modal.workingHours.customMethod.btn': 'Benutzerdefinierte Methode',
   'modal.workingHours.workingDates.input': 'Arbeitsdaten',
   'modal.workingHours.selectDates.placeholder': 'Daten auswählen',
   'noInternetConnection.notification.title': 'Keine Internetverbindung',
   'app.update.title': 'Neue Version verfügbar',
   'app.update.description.title':
      'Wir haben neue Funktionen hinzugefügt und einige Fehler behoben, um Ihre Erfahrung mit Plandok so reibungslos wie möglich zu gestalten.',
   'app.update.button.title': 'Aktualisieren',
   'app.notNow.button.title': 'Nicht jetzt',
   'app.updateAvailable.button.title': 'Neue Version verfügbar',
   'upgradePlan.success.message': 'Das Abonnement wurde erfolgreich aktualisiert',
   'upgradePlan.error.message':
      'Fehler bei der Aktualisierung des Abonnements, bitte kontaktieren Sie den Support, wenn Ihre Karte belastet wurde und das Abonnement nicht aktualisiert wurde',
   'sidebar.inventory': 'Bestandsaufnahme',
   'products.tab': 'Produkte',
   'suppliers.tab': 'Lieferanten',
   'stockOrders.tab': 'Lagerbestellungen',
   'stockTracking.tab': 'Bestandsverfolgung',
   'input.productsSearch.placeholder': 'Suche nach Produktname oder Barcode',
   'product.create.btn.title': 'Neues Produkt hinzufügen',
   'table.products.product': 'Produkt',
   'table.products.barcode': 'Strichcode',
   'table.products.brand': 'Marke',
   'table.products.sale': 'Verkauf',
   'table.products.saleOpt': 'Verkauf Opt.',
   'saleOptions.tooltip.title': 'Verkauf Optionen',
   'saleOptions.tooltip.description': 'Sie können den Verkauf während der Kaufabwicklung aktivieren/deaktivieren.',
   'seeMore.title': 'mehr sehen',
   'option.products.downloadCSV': 'CSV herunterladen',
   'option.products.downloadExcel': 'Excel herunterladen',
   'option.products.manageBrands': 'Marken verwalten',
   'option.products.manageCategories': 'Kategorien verwalten',
   'option.products.manageStock': 'Lagerbestand verwalten',
   'option.products.importProducts': 'Produkte importieren',
   'modal.filter.categories.title': 'Kategorien',
   'modal.filter.brands.title': 'Marken',
   'modal.filter.stock.title': 'Lagerbestand',
   'modal.filter.allCategories.selectItem': 'Alle Kategorien',
   'modal.filter.allBrands.selectItem': 'Alle Marken',
   'modal.filter.allSuppliers.selectItem': 'Alle Anbieter',
   'modal.filter.allProducts.selectItem': 'Alle Produkte',
   'input.suppliersSearch.placeholder': 'Suche nach Anbieternamen',
   'supplier.create.btn.title': 'Neuen Lieferanten hinzufügen',
   'table.suppliers.supplierName': 'Name des Anbieters',
   'table.suppliers.phoneNumber': 'Telefonnummer',
   'table.suppliers.physicalAddress': 'Physische Adresse',
   'validation.notification.reminderTime':
      'Es können keine Benachrichtigungen mit derselben Erinnerungszeit gesendet werden.',
   'validation.paymentType.switches': 'Es muss mindestens eine Zahlungsart erlaubt sein',
   'plan.premium.option.14':
      '0 % Plandok-Provisionsgebühr für Termine, die über Online-Buchungen mit Kreditkarte gebucht werden.',
   'title.dragAndExtend.appointment.permission': 'Kann Termine verschieben und verlängern',
   'button.lastVisit.addAnotherLastvisit': 'Fügen Sie einen weiteren letzten Besuch hinzu',
   'notifications.template.badge.validate': 'Bestätigen',
   'notifications.template.badge.refused': 'Abgelehnt',
   'paid.by.card': 'Mit Karte bezahlt',
   'costs.manual.paid.link':
      'Sie können Ihre Benachrichtigungen auch manuell mit Ihrer Karte bezahlen, indem Sie auf diesen Link klicken',
   'modal.remove.account.title': 'Stripe-Konto entfernen',
   'modal.remove.account.description':
      'Ihr Konto wird entfernt und Sie können keine Online-Zahlungen mehr akzeptieren. ',
   'ob.remove.account.option': 'Stripe-Konto entfernen',
   'btn.remove.account': 'Entfernen',
   'calendar.tooltip.selected': 'Ausgewählt',
   'calendar.tooltip.no.preference': 'Keine Präferenz',
   'ob.gtag.manager.title': 'Google Tag Manager',
   'ob.analytics.gtag.description.1':
      'Mit dem Google Tag Manager können Sie Ihren Werbe-ROI messen und Ihre Flash-, Video- und Social-Networking-Sites und -Anwendungen verfolgen.',
   'ob.gtag.manager.description.1':
      'Google Tag Manager ist ein kostenloses Tag-Management-System, mit dem Sie Marketing-Tags (Codeausschnitte oder Tracking-Pixel) auf Ihrer Website oder mobilen App verwalten und bereitstellen können, ohne den Code direkt ändern zu müssen.',
   'ob.gtag.manager.description.2':
      'Im Wesentlichen rationalisiert GTM den Prozess der Verwaltung von Marketing-Tags und erleichtert so die Verfolgung der Website-Leistung, die Messung von Marketingkampagnen und die Optimierung der Benutzererfahrungen.',
   'text.gender.male': 'Männlich',
   'text.gender.female': 'Weiblich',
   'text.gender.unknown': 'Unbekannt',
}

export default labels
