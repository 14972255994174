import { Card, getIsMobile, useModal, useNavigation } from '@plandok/core'
import { Avatar, Divider } from 'antd'
import cn from 'classnames'
import { TextLong } from 'components'
import { RoutePath } from 'constants/routes'
import React from 'react'
import { IClientDetails } from 'types/api'

import { Gender } from '../../types/types'
import ClientInfoItem from './components/ClientInfoItem'
import ClientMoreOptionMenu from './components/ClientMoreOptionMenu'
import { getFormattedBirthday } from './helpers'
import * as SC from './style'

export default function ClientInfoBlock({ data, id }: { id: string; data: IClientDetails }) {
   const { navigate } = useNavigation()
   const [, { showModal }] = useModal()

   const isMobile = getIsMobile()

   if (!data?.profile) {
      return null
   }
   const { profile } = data
   const editClient = () => navigate(`${RoutePath.CLIENT_EDIT}/${id}`)

   const openDeleteClientModal = () => showModal({ type: 'CONFIRM_DELETE_CLIENT', modalProps: { id, profile } })

   const genderLabel = () => {
      if (profile.gender === Gender.MALE) return 'dropdown.gender.male'
      if (profile.gender === Gender.FEMALE) return 'dropdown.gender.female'
      return 'dropdown.gender.unknown'
   }

   return (
      <Card withShadow>
         <SC.MainInfo>
            <Avatar size="large" data-client-info-avatar>
               {profile.firstName?.[0]?.toUpperCase?.() ?? ' '}
               {profile.lastName?.[0]?.toUpperCase?.() ?? ''}
            </Avatar>
            <div data-client-info-name>
               {profile.firstName} {profile.lastName}
            </div>
            {!isMobile && (
               <div data-client-info-options>
                  <ClientMoreOptionMenu delete={openDeleteClientModal} edit={editClient} />
               </div>
            )}
         </SC.MainInfo>
         <TextLong text={profile.notes}>
            <Divider />
         </TextLong>
         <SC.ItemList>
            <ClientInfoItem icon="phone" label="title.telephoneNumber" text={profile.phoneNumber} isPhone />
            <ClientInfoItem icon="email" label="title.email" text={profile.email} />
            <ClientInfoItem
               icon="address"
               label="title.address"
               text={cn(profile.address, profile.city, profile.postalCode)}
            />
            <ClientInfoItem icon="birth" label="title.dateOfBirth" text={getFormattedBirthday(profile)} />
            <ClientInfoItem icon="gender" label="title.gender" text={genderLabel()} />
            {/* TODO: Update later when referal be ready from BE */}
            {/* <ClientInfoItem icon="referal" label="input.referal.label" text={profile.gender} /> */}
         </SC.ItemList>
      </Card>
   )
}
