import '../../style.less'

import { CustomIcon, SelectInput, Text, useModal } from '@plandok/core'
import React, { useState } from 'react'

import { IPaymentType } from '../controllers/paymentTypeController'
import * as SC from '../styles'

type Value = {
   value: {
      title: string
      icon?: 'newTab' | 'redDeleteButton'
      href?: string
      action?: () => void
   }
}

type IStripeDropdown = {
   stripePayment: IPaymentType
   controller: any
}

export default function StripeDropdown({ stripePayment, controller }: IStripeDropdown) {
   const [, { showModal }] = useModal()
   const [option, setOption] = useState(
      <Text mb="none" size="base">
         {stripePayment.accountDescription}
      </Text>,
   )

   const dropdownOptions = [
      {
         code: 'email',
         value: {
            title: stripePayment.accountDescription,
         },
      },
      {
         code: 'visit_stripe',
         value: {
            title: 'ob.visitStripe.option',
            icon: 'newTab',
            href: 'https://dashboard.stripe.com',
         },
      },
      {
         code: 'change_stripe',
         value: {
            title: 'ob.changeAccount.option',
            icon: 'newTab',
            action: () => showModal({ type: 'CHANGE_STRIPE_ACCOUNT_MODAL' }),
         },
      },
      {
         code: 'remove_account',
         value: {
            title: 'ob.remove.account.option',
            icon: 'redDeleteButton',
            action: () =>
               showModal({
                  type: 'REMOVE_STRIPE_ACCOUNT_MODAL',
                  modalProps: {
                     refresh: controller?.refresh,
                  },
               }),
         },
      },
   ]

   const DropdownList = ({ value }: Value) => {
      if (value.href) {
         return (
            <a href={value.href} target="_blank" rel="noopener noreferrer" className="justify-align">
               <Text label={value.title} size="small" mb="none" />
               {value.icon && <CustomIcon type={value.icon} />}
            </a>
         )
      }

      if (value?.icon === 'redDeleteButton') {
         return (
            <div className="justify-align" onClick={value.action && value.action}>
               <Text label={value.title} colorType="red" size="small" mb="none" />
               {value.icon && <CustomIcon type={value.icon} />}
            </div>
         )
      }

      return (
         <div className="justify-align" onClick={value.action && value.action}>
            <Text label={value.title} size="small" mb="none" />
            {value.icon && <CustomIcon type={value.icon} />}
         </div>
      )
   }

   return (
      <SC.StripeDropdownContainer>
         <Text label="ob.yourStripeAccount.title" size="small" weight="semiBold" lh="base" marginBottom="4px" />

         <SelectInput
            options={dropdownOptions}
            value={option}
            onChange={setOption}
            optionRender={DropdownList}
            showMenuOnOptionClick
            dropdownClassName="stripe-dropdown"
         />
      </SC.StripeDropdownContainer>
   )
}
