import { Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Divider } from 'antd'
import React from 'react'

import { App_ClientsAnalytics } from '../../../../../../graphql/generatedTypes'
import { AgeColumnChart } from './AgeColumnChart'
import { GenderPieChart } from './GenderPieChart'
import { ReferencePieChart } from './ReferralSourcePieChart'
import * as SC from './styles'

type ClientsProps = {
   clients: App_ClientsAnalytics
}

export const Clients = ({ clients }: ClientsProps) => (
   <SC.Clients>
      <Text size="mlarge" weight="bold" colorType="base" mb="xsmall">
         <IntlLabel label="analytics.clients.title" />
      </Text>
      <SC.TotalNumbersStatus>
         <SC.TotalNumbersColumn>
            <Text size="small" colorType="base" mb="none">
               <IntlLabel label="analytics.totalClients.title" />
            </Text>
            <SC.CountTitle as={Text} size="xxxlarge" weight="medium" colorType="base" isCenter>
               {clients.bookedCount}
            </SC.CountTitle>
         </SC.TotalNumbersColumn>
         <SC.TotalNumbersColumn>
            <Text size="small" colorType="base" mb="none">
               <IntlLabel label="analytics.averageVisits.title" />
            </Text>
            <SC.CountTitle as={Text} size="xxxlarge" weight="medium" colorType="base" isCenter>
               {clients.averageVisitsCount}
            </SC.CountTitle>
         </SC.TotalNumbersColumn>
         <SC.TotalNumbersColumn>
            <Text size="small" colorType="base" mb="none">
               <IntlLabel label="analytics.newClients.title" />
            </Text>
            <SC.CountTitle as={Text} size="xxxlarge" weight="medium" colorType="base" isCenter>
               {clients.newCount}
            </SC.CountTitle>
         </SC.TotalNumbersColumn>
         <SC.TotalNumbersColumn>
            <Text size="small" colorType="base" mb="none">
               <IntlLabel label="analytics.returningClients.title" />
            </Text>
            <SC.CountTitle as={Text} size="xxxlarge" weight="medium" colorType="base" isCenter>
               {clients.returningCount}
            </SC.CountTitle>
         </SC.TotalNumbersColumn>
         <SC.TotalNumbersColumn>
            <Text size="small" colorType="base" mb="none">
               <IntlLabel label="analytics.returnRate.title" />
            </Text>
            <SC.CountTitle as={Text} size="xxxlarge" weight="medium" colorType="base" isCenter>
               {clients.returningPercent}%
            </SC.CountTitle>
         </SC.TotalNumbersColumn>
      </SC.TotalNumbersStatus>
      <SC.Divider as={Divider} />
      <SC.ChartsContainer>
         <GenderPieChart data={clients} />
         <SC.Divider as={Divider} />
         <ReferencePieChart data={clients} />
         <SC.Divider as={Divider} />
         <AgeColumnChart data={clients} />
      </SC.ChartsContainer>
   </SC.Clients>
)

export default Clients
