import { Button, Mutation, Text, withModal } from '@plandok/core'
import React from 'react'

import * as SC from '../styles'

function ResetCampaignModal({ modalProps, onClose, hideAllModals }: any) {
   const onSuccess = () => {
      modalProps?.onSuccess()
      hideAllModals()
   }

   return (
      <SC.CampaignModals>
         <Text size="large">Are you sure want to reset this Campaign?</Text>
         <SC.ModalFooter>
            <Mutation onSuccess={onSuccess}>
               {({ loading }: any) => (
                  <>
                     <Button
                        disabled={loading}
                        type="primary"
                        label="btn.cancel"
                        ghost
                        upperCase={false}
                        onClick={onClose}
                     />
                     <Button
                        loading={loading}
                        label="Yes, reset"
                        type="primary"
                        upperCase={false}
                        onClick={onClose}
                        data-confirm-unsubscribe
                     />
                  </>
               )}
            </Mutation>
         </SC.ModalFooter>
      </SC.CampaignModals>
   )
}

export default withModal('Reset Campaign', { contentClassName: 'reset-campagin-modal' })(ResetCampaignModal)
