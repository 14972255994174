import { Button, useAppContext, useHistory, useModal } from '@plandok/core'
import { IntlLabel, LabelKey } from '@plandok/i18n'
import { ColumnProps } from 'antd/lib/table'
import { AddButton, AppLayout, DraggableTable, ModalType, PageControlContainer, Placeholder } from 'components'
import withApiData from 'hocs/form/withApiData'
import React, { ReactNode } from 'react'

import MobileBackButton from './MobileBackButton'

const AppSetupMobile = (props: { title: LabelKey }) => (
   <>
      <MobileBackButton />
      <div>
         <IntlLabel label={props.title} />
      </div>
      <div />
   </>
)

interface AppSetupListProps<T = any> {
   title: LabelKey
   columns: ColumnProps<any>[]
   data: T[]
   controlItem?: ReactNode
   btnLabel: LabelKey
   modalType: ModalType
   isLoading?: boolean
   fetchData?: any
   initialValues?: any
   children?: any
   infoPlaceholder?: ReactNode
   emptyState?: boolean
   alwaysAllowDelete?: boolean
   onPositionUpdate?: (id: string, nextIndex: number) => Promise<void> | void
   changeData?: Function
   placeholder: {
      imgName: string
      titleLabel: LabelKey
      descriptionLabel: LabelKey
      btnLabel: LabelKey
   }
}

function AppSetupList(props: AppSetupListProps) {
   const [, { showModal }] = useModal()
   const [, { refreshAppContext }] = useAppContext()
   const history = useHistory()

   const editItem = (id: string) => {
      const isDefault = props?.data?.find(item => item.id === id)?.isDefault
      const disableDelete = (!props.alwaysAllowDelete && (props?.data?.length || 0) <= 1) || isDefault

      history.go(1)

      if (props?.initialValues?.locationPage) {
         return showModal({
            type: 'CREATE_LOCATION',
            modalProps: {
               id,
               onSuccess: () => {
                  props.fetchData()
                  refreshAppContext()
               },
               disableDelete,
               initialValues: props?.initialValues,
            },
         })
      }

      return showModal({
         type: props.modalType,
         modalProps: {
            id,
            disableDelete,
            initialValues: props?.initialValues,
            onSuccess: () => {
               props.fetchData()
               refreshAppContext()
            },
         },
      })
   }

   const createItem = () =>
      showModal({
         type: props.modalType,
         modalProps: {
            onSuccess: () => {
               props.fetchData()
               refreshAppContext()
            },
            initialValues: props?.initialValues,
         },
      })

   const showPlaceholder = !props.isLoading && !props.data?.length && (props.emptyState ?? true)
   const isLoading = props.isLoading || props?.initialValues?.isAlertLoading || false

   return (
      <AppLayout headerTitle="setup.page.title" mobileHeader={() => <AppSetupMobile title={props.title} />}>
         <PageControlContainer mobileHidden>
            <PageControlContainer.Item>{!showPlaceholder && props.controlItem}</PageControlContainer.Item>
            <PageControlContainer.Item>{props.infoPlaceholder}</PageControlContainer.Item>
            <PageControlContainer.Item mobileHidden>
               {!showPlaceholder && (
                  <Button
                     label={props.btnLabel}
                     type="primary"
                     bold={false}
                     upperCase={false}
                     onClick={createItem}
                     data-create-btn
                     loading={isLoading}
                  />
               )}
            </PageControlContainer.Item>
         </PageControlContainer>
         <PageControlContainer mobileOnly>
            <PageControlContainer.Space />
            <PageControlContainer.Space />
            <PageControlContainer.Item>
               {!showPlaceholder && (
                  <AddButton
                     addButtonModal={props.modalType}
                     className="position-static"
                     modalProps={{ initialValues: props?.initialValues }}
                     isLoading={isLoading}
                  />
               )}
            </PageControlContainer.Item>
         </PageControlContainer>
         {showPlaceholder ? (
            <Placeholder {...props.placeholder} onClick={createItem} />
         ) : (
            <DraggableTable
               columns={props.columns}
               data={props.data}
               onItemClick={editItem}
               isLoading={isLoading}
               onPositionUpdate={props.onPositionUpdate}
               changeData={props.changeData}
            />
         )}
      </AppLayout>
   )
}

export default withApiData(null, { isSortable: true })(AppSetupList)
