/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
   'login.title': 'Se connecter à votre compte',
   'label.email': 'Adresse électronique',
   'placeholder.email': 'exp: johndoe@gmail.com',
   'input.password.label': 'Mot de passe',
   'input.password.placeholder': 'Entrer le mot de passe',
   'login.btn': 'Se connecter à Plandok',
   'link.sign.in': 'Se connecter',
   'label.password': 'Entrer le mot de passe',
   'login.forgot.password': 'Mot de passe oublié?',
   'login.no.account': "Vous n'avez pas de compte?",
   'login.description':
      "Si vous rencontrez des problèmes d'authentification, vous pouvez essayer de réinitialiser votre mot de passe",
   'register.title': 'Créer un compte',
   'register.description':
      'Créer un compte gratuit sur le logicel de planification et de réservation de rendez-vous Plandok',
   'register.btn': 'Créer mon compte',
   'register.agreement.agree': "J'accepte les",
   'register.agreement.terms': 'Conditions générales du site',
   'register.agreement.policy': 'Politique de confidentialité',
   'register.agreement.and': 'et',
   'register.agreement.partner': 'Les conditions commerciales du partenaire',
   'register.have.account': 'Vous avez déjà un compte ?',
   'register.validation.confirm': 'Avant de poursuivre, veuillez réviser et accepter les conditions ci-dessus.',
   'link.sign.up': "S'inscrire",
   'text.alreadyHaveAccount': 'Vous avez déjà un compte ?',
   'input.company.label': 'Entreprise',
   'input.company.placeholder': 'Entreprise',
   'input.country.label': 'Pays',
   'input.country.placeholder': 'Nom du pays',
   'input.timeZone.label': 'Fuseau horaire',
   'input.timeZone.placeholder': 'GMT +02.00',
   'input.currency.label': 'Devise',
   'input.language.label': 'Langue (Language)',
   'input.language.placeholder': 'Anglais',
   'input.currency.placeholder': 'Euro - EUR',
   'reset.title': 'Réinitialiser le mot de passe',
   'reset.btn.text': 'Envoyer la réinitialisation du mot de passe',
   'reset.pwd.btn': 'Réinitialiser le mot de passe',
   'reset.no.password': 'Vous ne voulez pas réinitialiser le mot de passe?',
   'reset.description':
      'Entrez votre adresse e-mail de connexion enregistrée pour recevoir un lien sécurisé pour définir un nouveau mot de passe',
   'sidebar.calendar': 'Calendrier',
   'sidebar.client': 'Client',
   'sidebar.staff': 'Personnel',
   'sidebar.service': 'Services',
   'sidebar.notification': 'Messages',
   'sidebar.settings': 'Paramètres',
   'sidebar.menu': 'Menu',
   'sidebar.analytics': 'Analytique',
   'sidebar.language': 'Langue (Language)',
   'sidebar.logout': 'Se déconnecter',
   'user.menu.settings': 'Mes paramètres',
   'user.menu.language': 'Changer la langue',
   'user.menu.logout': 'Se déconnecter',
   'input.globalSearch.placeholder': 'Que cherchez-vous?',
   'input.globalSearch.description': 'Recherche par nom de client, téléphone mobile, e-mail ou numéro de réservation',
   'title.upcomingAppointments': 'Rendez-vous prochains',
   'calendar.day': 'Jour',
   'calendar.week': 'Semaine',
   'calendar.monday': 'Lundi',
   'calendar.tuesday': 'Mardi',
   'calendar.wednesday': 'Mercredi',
   'calendar.thursday': 'Jeudi',
   'calendar.friday': 'Vendredi',
   'calendar.saturday': 'Samedi',
   'calendar.sunday': 'Dimanche',
   'calendar.mon': 'Lun',
   'calendar.tue': 'Mar',
   'calendar.wed': 'Mer',
   'calendar.thu': 'Jeu',
   'calendar.fri': 'Ven',
   'calendar.sat': 'Sam',
   'calendar.sun': 'Dim',
   'calendar.today': "Aujourd'hui",
   'calendar.january': 'Janvier',
   'calendar.february': 'Février',
   'calendar.march': 'Mars',
   'calendar.april': 'Avril',
   'calendar.may': 'Mai',
   'calendar.june': 'Juin',
   'calendar.july': 'Juillet',
   'calendar.august': 'Août',
   'calendar.september': 'Septembre',
   'calendar.october': 'Octobre',
   'calendar.november': 'Novembre',
   'calendar.december': 'Décembre',
   'calendar.jan': 'Jan',
   'calendar.feb': 'Fév',
   'calendar.mar': 'Mar',
   'calendar.apr': 'Avr',
   'calendar.may.short': 'Mai',
   'calendar.jun': 'Jun',
   'calendar.jul': 'Jul',
   'calendar.aug': 'Aoû',
   'calendar.sept': 'Sep',
   'calendar.oct': 'Oct',
   'calendar.nov': 'Nov',
   'calendar.dec': 'Déc',
   'calendar.allStaff': 'Tous',
   'calendar.workingStaff': 'Actif',
   'calendar.tooltip.time': 'Temps',
   'calendar.tooltip.category': 'Catégorie',
   'calendar.tooltip.service': 'Service',
   'calendar.tooltip.staff': 'Personnel',
   'calendar.tooltip.price': 'Prix',
   'calendar.tooltip.repeating': 'Répétitif',
   'btn.viewDetails': 'Afficher les détails',
   'calendar.tooltip.emptyCustomer': 'Sans réservation',
   'calendar.tooltip.bookedOnline': 'Réservation en ligne',
   'calendar.print.description':
      "Pour utiliser la fonction d'impression, votre plan doit être mis à niveau. Pour mettre à niveau, visitez",
   'calendar.print.appointmentsBetween.title': 'Rendez-vous entre',
   'calendar.print.appointmentsAnd.title': 'et',
   'calendar.billing.title': 'Forfait et facturation',
   'calendar.btn.print.title': 'Imprimer',
   'datepicker.next.week': 'La semaine prochaine',
   'datepicker.in.two.weeks': 'Dans 2 semaines',
   'datepicker.in.three.weeks': 'Dans 3 semaines',
   'datepicker.in.four.weeks': 'Dans 4 semaines',
   'datepicker.in.five.weeks': 'Dans 5 semaines',
   'datepicker.in.six.weeks': 'Dans 6 semaines',
   'modal.splitAppointment.title': 'Séparer rendez-vous',
   'modal.splitAppointment.text':
      "Ce rendez-vous fait partie d'un groupe, le déplacer à un autre jour le séparera pour le rendre une réservation à part. Êtes-vous sûr?",
   'blocktime.create.modal.title': 'Bloquer horaire',
   'blocktime.edit.modal.title': 'Modifier horaire bloquée',
   'appointment.create.menu': 'Rendez-vous',
   'blocktime.create.menu': 'Bloquer horaire',
   'appointment.new.title': 'Nouveau rendez-vous',
   'input.startTime.label': 'Heure de début',
   'input.endTime.label': 'Heure de fin',
   'input.startDate.label': 'Date de début',
   'input.endDate.label': 'Date de fin',
   'input.startDate.description.label': 'Sélectionnez la date de début',
   'input.endDate.description.label': 'Sélectionnez la date de fin',
   'input.closedDatedDescription.placeholder': 'par ex. vacances',
   'input.service.label': 'Services',
   'input.services.label': 'services',
   'input.service.placeholder': 'Choisir un service',
   'input.staff.label': 'Collaborateur',
   'input.staff.placeholder': 'Sélectionner le collaborateur',
   'input.pickDateAndTime.title': "Choisir la date et l'heure",
   'input.pickDate.title': 'Choisissez une date',
   'service.add.another': 'Ajouter un autre service',
   'input.appointmentNotes.label': 'Remarques du rendez-vous',
   'input.emailSubject.placeholder': 'Remarque à propos de votre rendez-vous',
   'input.appointmentNotes.placeholder': 'Ajouter une remarque du rendez-vous (visible au personnel uniquement)',
   'input.searchClients.placeholder': 'Rechercher client',
   'input.searchService.placeholder': 'Recherche',
   'appointment.empty.list':
      'Utilisez la recherche pour ajouter un client, ou garder vide pour enregistrer en tant que sans rendez-vous.',
   'appointment.clients.empty.list': 'La liste des clients est vide.',
   'appointment.clients.addClient': 'Créer un client',
   'client.create.new': 'Créer un nouveau client',
   'client.choose': 'Choisir client',
   'client.menu.edit': 'Modifier les détails du client',
   'client.menu.remove': 'Retirer du rendez-vous',
   'appointment.btn.save': 'Sauvegarder',
   'appointment.vat': 'TVA ({0}%)',
   'appointment.total': 'Total',
   'appointment.view.title': 'Consulter rendez-vous',
   'appointment.status.btn': 'Statut du rendez-vous',
   'appointment.btn.cancel': 'Supprimer',
   'btn.cancelAppointment': 'Supprimer',
   'modal.cancelAppointment.title': 'Supprimer',
   'title.clients': 'Clients',
   'client.create.title': 'Nouveau client',
   'client.options.export.title': 'Clients exportateurs',
   'client.options.export.description': 'Télécharger CSV avec les données client',
   'client.options.import.title': 'Importer des clients',
   'client.export.premium.description': "Pour utiliser la fonction d'exportation, votre plan doit être mis à niveau.",
   'client.export.premium.visit': 'Pour mettre à niveau, visitez ',
   'client.import.instructions.description':
      'Suivez les instructions ci-dessous pour importer des clients sur votre compte Plandok',
   'client.import.instructions.download': "Téléchargez votre fichier d'import en utilisant le bouton ci-dessous",
   'client.import.instructions.copy': 'Copiez et collez les détails de votre client dans le fichier',
   'client.import.instructions.email': 'Envoyez-le-nous par email à context@plandok.com',
   'client.import.instructions.account': 'et nous importerons sur votre compte',
   'table.clients.name': 'Nom',
   'table.clients.phone': 'Téléphone',
   'table.clients.mobile': 'Téléphone',
   'table.clients.email': 'Email',
   'table.clients.gender': 'Genre',
   'client.page.title': 'Profile du client',
   'btn.more.options': "Plus d'options",
   'btn.showAll': 'Afficher tout',
   'btn.collapse': 'Réduire',
   'btn.reschedule': 'Reprogrammer',
   'title.edit.client': 'Modifier client',
   'menu.edit.client': 'Modifier client',
   'menu.delete.client': 'Supprimer client',
   'title.mobilePhone': 'Téléphone portable',
   'title.telephoneNumber': 'Numéro téléphone',
   'title.email': 'Email',
   'title.address': 'Adresse',
   'title.dateOfBirth': 'Date de naissance',
   'title.gender': 'Sexe',
   'client.statistics': 'Statistiques',
   'client.totalSales': 'Ventes totales',
   'client.totalBookings': 'Réservation totales',
   'client.outstanding': 'Remarquable',
   'client.allBooking': 'Toutes les réservations',
   'client.completed': 'Terminé',
   'client.cancelled': 'Annulé',
   'client.noShow': 'Annulation',
   'appointment.list.title': 'Rendez-vous',
   'upcoming.list.title': 'Prochain',
   'past.list.title': 'Passé',
   'modal.confirmClientDelete.text.0': 'Êtes-vous sûr de vouloir supprimer définitivement ce client?',
   'modal.confirmClientDelete.text.1': 'De la base de données?',
   'input.date.label': 'Date spécifique',
   'input.date.placeholder': 'Sélectionner une date',
   'input.firstName.label': 'Prénom',
   'input.firstName.placeholder': 'exp. John',
   'input.lastName.label': 'Nom de famille',
   'input.lastName.placeholder': 'exp. Doe',
   'input.mobile.label': 'Téléphone mobile',
   'input.mobile.placeholder': 'Entrer numéro',
   'input.email.label': 'Adresse électronique',
   'input.email.placeholder': 'exp. johndoe@gmail.com',
   'input.title.label': 'Titre',
   'input.title.placeholder': 'par ex. Dentiste',
   'input.notificationType.label': 'Envoyer une notification par',
   'tab.personalInfo': 'Informations personnelles',
   'input.gender.label': 'Sexe',
   'input.referal.label': 'Souce de référence',
   'input.referal.placeholder': 'Sélectionner source',
   'input.birthDay.label': 'Anniversaire',
   'input.month.placeholder': 'Mois',
   'input.day.placeholder': 'Jour',
   'input.year.placeholder': 'Année',
   'input.clientsNotes.label': 'Remarques du client',
   'input.clientsNotes.placeholder': 'Entrer les remarques du client',
   'input.bookingDisplay.label': 'Afficher sur toutes les réservations',
   'input.serviceDescription.label': 'Descriptif des prestations',
   'input.serviceDescription.placeholder':
      'Ajoutez une brève description qui sera visible pour les clients lors des réservations en ligne',
   'input.isBookableOnline.label': 'Disponible pour les réservations en ligne',
   'service.onlineBookings.title': 'Réservations en ligne',
   'tab.address': 'Adresse',
   'input.address.label': 'Adresse',
   'input.address.placeholder': 'Entrer votre adresse',
   'input.city.label': 'Ville',
   'input.city.placeholder': 'Entrer ville',
   'input.zip.label': 'Code postal',
   'input.zip.placeholder': 'Entrer code postal',
   'btn.edit': 'Modifier',
   'btn.cancel': 'Annuler',
   'btn.save': 'Sauvegarder',
   'btn.save.changes': 'Sauvergarder',
   'tabs.staffWorking.title': 'Horaires de travail',
   'tabs.staffMembers.title': 'Membres du personnel',
   'tabs.userPermissions.title': 'Permissions utilisateur',
   'tabs.closedDates.title': 'Dates de fermeture',
   'btn.new.staff': 'Nouveau collaborateur',
   'table.staff.name': 'Nom',
   'table.staff.mobileNumber': 'Téléphone',
   'table.staff.email': 'Adresse électronique',
   'table.staff.userPermission': 'Permissions utilisateur',
   'table.staff.dataRange': 'Plage de dates',
   'table.staff.numberOfDays': 'Nombre de jours',
   'table.staff.location': 'Emplacement',
   'table.staff.description': 'Description',
   'table.appointmentsList.appointment': 'Rendez-vous',
   'table.appointmentsList.client': 'Client',
   'table.appointmentsList.service': 'Service',
   'table.appointmentsList.date': 'Date',
   'table.appointmentsList.time': 'Temps',
   'table.appointmentsList.duration': 'Durée',
   'table.appointmentsList.location': 'Emplacement',
   'table.appointmentsList.staff': 'Personnel',
   'table.appointmentsList.price': 'Prix',
   'table.appointmentsList.status': 'Statut',
   'table.appointmentsCancellations.ref': 'Réf',
   'table.appointmentsCancellations.client': 'Client',
   'table.appointmentsCancellations.service': 'Service',
   'table.appointmentsCancellations.scheduledDate': 'Date prévue',
   'table.appointmentsCancellations.cancelledDate': "Date d'annulation",
   'table.appointmentsCancellations.cancelledBy': 'Annulé par',
   'table.appointmentsCancellations.reason': 'Raison',
   'table.appointmentsCancellations.price': 'Prix',
   'table.appointmentsCancellations.totalCount': 'Nombre total',
   'table.clients.appointments': 'Rendez-vous',
   'table.clients.noShows': 'Pas de présentation',
   'table.clients.totalSales': 'Ventes totales',
   'table.clients.added': 'Ajouté',
   'table.clients.daysAbsent': "Jours d'absence",
   'table.clients.lastAppointment': 'Dernier rendez-vous',
   'table.clients.lastLocation': 'Dernier emplacement',
   'table.appointmentsByService.serviceName': 'Nom du service',
   'table.appointmentsByService.appointments': 'Rendez-vous',
   'table.appointmentsByService.totalValue': 'Valeur totale',
   'table.appointmentsByStaff.staffMember': 'Membre du personnel',
   'table.appointmentsByStaff.appointments': 'Rendez-vous',
   'table.appointmentsByStaff.totalValue': 'Valeur totale',
   'table.pagination.showing.title': 'Affichage',
   'table.pagination.results.title': 'résultats',
   'clients.duplicate.title': 'Clients en double trouvés.',
   'clients.duplicate.description': 'Il existe des clients similaires qui peuvent être fusionnés.',
   'clients.merge.btn.title': 'Fusionner',
   'clients.merge.modal.title': 'Fusionner les clients',
   'clients.merge.selectAll.title': 'Sélectionnez tous les doublons',
   'clients.merge.duplicatesNotFound.title': 'Duplicata non trouvé',
   'clients.merge.duplicatesNotFound.description': "Il n'y a pas de clients disponibles pour la fusion",
   'clients.mergeConfirm.modal.title': 'Confirmer la fusion',
   'clients.mergeConfirm.modal.description':
      'Êtes-vous sûr de vouloir fusionner {0} doublons ? Cette action est permanente et ne peut être annulée.',
   'clients.mergeConfirm.checkbox.title': 'Je comprends que la fusion de clients ne peut pas être annulée',
   'clients.mergeConfirm.btn.title': 'Confirmer et fusionner',
   'clients.mergeConfirm.cancel.btn.title': 'Annuler',
   'clients.byNumber.btn.title': 'Par téléphone {0}',
   'clients.byEmail.btn.title': 'Par e-mail {0}',
   'clients.byName.btn.title': 'Par nom {0}',
   'title.newStaff': 'Nouveau collaborateur',
   'title.editStaff': 'Modifier collaborateur',
   'description.extraCharge':
      'Si vous ajoutez du personnel, des frais supplémentaires seront ajoutés à votre abonnement mensuel',
   'tabs.details': 'Détails',
   'input.staffPicture.label': 'Image',
   'input.cropImage.title': "Recadrer l'image",
   'input.cropImage.btn.apply.title': 'Appliquer',
   'input.stafffirstName.label': 'Prénom',
   'input.stafffirstName.placeholder': 'exp. John',
   'input.stafflastName.label': 'Nom de famille',
   'input.stafflastName.placeholder': 'exp. Doe',
   'input.stafmobile.label': 'Téléphone',
   'input.stafemail.label': 'Adresse électronique',
   'input.stafemail.placeholder': 'exp. johndoe@plandok.com',
   'input.permission.label': 'Permissions utilisateur',
   'input.uploadProfile.label': 'Télécharger une photo de profil',
   'input.changeProfile.label': 'Changer la photo de profil',
   'input.notes.label': 'Remarques',
   'input.staffNotes.placeholder':
      'Ajouter des remarques privées uniquement visibles dans les paramètres du personnel (optionnel)',
   'input.blockTime.placeholder': 'exp. déjeuner de travail',
   'tabs.locations': 'Emplacements',
   'tabs.integrations': 'Intégrations',
   'input.staffLocations.description': 'Choisir les emplacements où ce membre du personnel peut être réservé.',
   'input.multiLocation.description': 'Choisir les emplacements où ce membre du personnel peut être réservé.',
   'tabs.services': 'Services',
   'input.staffServices.description': 'Choisir les services que ce membre du personnel peut effectuer.',
   'input.multiServices.description': 'Choisir les services que ce membre du personnel peut effectuer.',
   'input.shiftStart.label': 'Début du travail',
   'input.shiftEnd.label': 'Fin du travail',
   'edit.staff.title.resendPassword': 'Renvoyer le mot de passe',
   'edit.staff.info.resendPassword':
      "Le membre du personnel n'a pas reçu d'e-mail avec son mot de passe ? Cliquez sur un bouton ci-dessous pour renvoyer un e-mail contenant les instructions de configuration du mot de passe.",
   'edit.staff.button.resendPassword': 'Renvoyer le courrier électronique de mot de passe',
   'btn.add.shift': 'Ajouter une autre séance',
   'break.time': '{0} pause',
   'input.repeat.label': 'Répéter',
   'input.repeatWeekly.label': 'Hebdomadaire',
   'input.repeatDoNot.label': 'Ne pas répéter',
   'input.endRepeat.label': 'Finir répétition',
   'input.endRepeatOngoing.label': 'En cours',
   'input.endRepeatSpecificDate.label': 'Date spécifique',
   'input.endRepeatDate.placeholder': 'Choisir date',
   'btn.deleteUpcomingShift': 'Supprimer les séances prochaines',
   'btn.deleteThisShift': 'Supprimer cette séance uniquement',
   'btn.updateUpcomingShift': 'Mettre à jour les prochaines séances',
   'btn.updateThisShift': 'Mettre à jour cette séance uniquement',
   'modal.repeatShift.title': 'Répéter séance',
   'form.error.uniqShifts': 'Les changements doivent être uniques',
   'btn.add.new': 'Ajouter',
   'btn.addNewService': 'Ajouter un nouveau service',
   'btn.newCategory': 'Nouvelle catégorie',
   'btn.newServiceGroup': 'Nouvelle catégorie',
   'btn.editCategory': 'Modifier catégorie',
   'btn.deleteCategory': 'Supprimer catégorie',
   'btn.downloadImportFile': "Télécharger le fichier d'importation",
   'serviceGroup.delete.success': 'Catégorie supprimée avec succès !',
   'service.new.title': 'Nouveau service',
   'service.edit.title': 'Modifier service',
   'input.serviceName.label': 'Nom du service',
   'input.serviceName.placeholder': 'exp. Massage',
   'input.serviceCategory.label': 'Catégorie du service',
   'input.serviceCategory.placeholder': 'Sélectionner la catégorie du service',
   'input.price.label': 'Prix',
   'input.price.placeholder': '0',
   'input.duration.label': 'Durée',
   'input.duration.placeholder': 'Durée',
   'input.staffSelect.description': 'Sélectionner le collaborateur qui exécute ce service',
   'input.newCategoryName.label': 'Nom de catégorie',
   'input.newCategoryDescription.label': 'Description de la catégorie',
   'input.newCategoryDescription.placeholder':
      'Décrivez cette catégorie, elle sera affichée dans Réservations en ligne',
   'modal.newCategory.title': 'Nouvelle catégorie',
   'modal.editCategory.title': 'Modifier catégorie',
   'input.chooseCategory.label': 'Choisir une couleur de catégorie:',
   'client.messages.title': 'Messages client',
   'tabs.messagesLog': 'Journal des messages',
   'tabs.messagesLog.description': 'Liste des messages envoyés à vos clients',
   'tabs.messagesSettings': 'Paramètres',
   'tabs.messagesSettings.description':
      'Ajustez les paramètres de vos messages en fonction des besoins de votre entreprise',
   'table.header.timeSent': 'Envoyé',
   'table.header.client': 'Client',
   'table.header.appointment': 'Rendez-vous',
   'table.header.destination': 'Adresse',
   'table.header.type': 'Type',
   'table.header.message': 'Message',
   'table.header.status': 'Statut',
   'table.header.cost': 'Coût',
   'modal.message.title': 'Voir message',
   'setup.page.title': 'Configuration',
   'title.accountSettings': 'Paramètres du compte',
   'title.accountSetup': 'Configuration du compte',
   'title.accountSetup.description': 'Gérer les paramètres tels que votre nom commercial et le fuseau horaire.',
   'resources.list.title': 'Ressources',
   'title.resources': 'Ressources',
   'title.resources.description':
      'Définir des ressources qui peuvent être réservées tels que les chambres, chaises ou équipements.',
   'title.locations': 'Emplacements',
   'title.locations.description': 'Gérer plusieurs points de ventre de votre entreprise.',
   'calendar.settings.title': 'Paramètres du calendrier',
   'calendar.btn.selectFromCalendar': 'Sélectionnez dans le calendrier',
   'title.calendarSettings': 'Paramètres du calendrier',
   'title.calendarSettings.description': 'Ajuster le modèle des couleurs et la mise en page de votre calendrier.',
   'title.clientSettings': 'Paramètres du client',
   'title.onlineBookingSettings': 'Paramètres de réservation en ligne',
   'title.salesSettings': 'Paramètres de vente',
   'title.receiptSequencing': 'Séquencement des reçus',
   'title.receiptSequencing.description':
      'Configurez les détails affichés sur les reçus de vente délivrés à vos clients',
   'title.receiptTemplate': 'Modèle de reçu',
   'title.taxes.description': "Gérer les taux de taxe qui s'appliquent aux articles vendus à la caisse",
   'title.paymentTypes': 'Types de paiement',
   'title.paymentTypes.description': 'Configurer les types de paiement manuels à utiliser lors du passage en caisse',
   'title.discounts.description': 'Configurez vos remises',
   'title.receiptPrefix': 'Reçu №. Préfixe',
   'title.receiptNumber': 'Prochain numéro de reçu',
   'title.change': 'Modifier',
   'title.saleReceiptTemplate': 'Modèle de ticket de caisse',
   'description.saleReceiptTemplate': 'Personnalisez le contenu affiché sur les reçus de vente délivrés à vos clients',
   'setting.automaticallyPrint': 'Imprimer automatiquement les reçus à la fin de la vente',
   'setting.showMobile': "Afficher le téléphone portable et l'adresse électronique du client sur le ticket de caisse",
   'setting.showAddress': "Afficher l'adresse du client sur le ticket de caisse",
   'title.receiptTitle': 'Titre du reçu',
   'title.referrals': 'Sources de référence',
   'title.referrals.description':
      'Mettre en place des sources personnalisées pour suivre la façon avec laquelle le client a trouvé votre entreprise.',
   'title.clientNotifications': 'Paramètres de notifications client',
   'title.clientNotifications.description': 'Gérer les messages envoyés aux clients pour leurs rendez-vous.',
   'cancelReason.list.title': "Motifs d'annulation",
   'title.cancellationReasons': "Motifs d'annulation",
   'title.cancellationReasons.description': 'Suivre pourquoi les clients ne sont pas arrivés à leurs rendez-vous.',
   'title.onlineBooking.description':
      "Gérer la disponibilité et les paramètres de la réservation en ligne d'une tournée",
   'title.pointOfSale': 'Point de vente',
   'discount.list.title': 'Réductions',
   'title.discountTypes': 'Types de réduction',
   'title.discountTypes.description': 'Configurer les types de réduction manuelle pour utilisation lors du paiement',
   'title.premiumFeature': 'Fonctionnalité Premium',
   'company.details.title': "Données de l'entreprise",
   'input.businessName.label': "Nom de l'entreprise",
   'input.businessName.placeholder': 'exp: Eaglewings',
   'input.description.label': 'Description',
   'input.description.placeholder': 'Entrer la description',
   'input.businessAddress.label': 'Adresse',
   'input.businessAddress.placeholder': 'Entrer adresse',
   'input.website.label': 'Site Web',
   'input.website.placeholder': 'Entrez le nom du site web',
   'input.contactNumber.label': 'Numéro de contact',
   'input.contactNumber.placeholder': 'Entrer le numéro',
   'input.businessTimeZone.label': 'Fuseau horaire',
   'input.businessTimeZone.placeholder': 'GMT 02,00',
   'input.businessCountry.label': 'Pays',
   'input.businessCountry.placeholder': 'Choisissez un pays',
   'input.timeFormat.label': "Format de l'heure",
   'input.timeFormat.placeholder': '24 heures',
   'table.header.resourceName': 'Ressource',
   'btn.new.resource': 'Nouvelle ressource',
   'input.resourceName.label': 'Nom de la ressource',
   'input.resourceName.placeholder': 'Entrez le nom',
   'input.resourceDescription.label': 'Description',
   'input.resourceDescription.placeholder': 'Entrer description',
   'input.resourceLocation.placeholder': "Nom de l'emplacement",
   'resourcesTooltip.location': "Infobulle de l'emplacement pour la ressource",
   'modal.newResource.title': 'Créer une nouvelle ressource',
   'modal.editResource.title': 'Modifier ressource',
   'table.header.locationName': 'Emplacement',
   'table.header.locatonAddress': 'Adresse',
   'table.header.location.Phone': 'Téléphone',
   'modal.newLocation.title': 'Nouvel emplacement',
   'modal.editLocation.title': 'Modifier emplacement',
   'btn.new.location': 'Nouvel emplacement',
   'btn.addExtraLocation': 'Ajouter un lieu supplémentaire',
   'input.locationName.label': "Nom de l'emplacement",
   'input.locationName.placeholder': "Entrer le nom de l'emplacement",
   'input.profile.locationName.placeholder': 'Par ex. Salon de coiffure',
   'input.slug.label': 'Slug',
   'input.locationTips.label': 'Conseils de localisation',
   'input.slug.explanation': "Sera visible dans l'URL de votre localisation",
   'input.contactEmail.label': 'Email du contact',
   'input.locationAddress.label': 'Adresse',
   'input.locationAddress.placeholder': "Entrer l'adresse de l'emplacement",
   'tooltip.contactEmail': 'Les réponses aux notifications de rendez-vous du client seront incluses dans cet email.',
   'input.appointmentColors.label': 'Couleur de rendez-vous',
   'input.colorBy.service': 'Par groupe de services',
   'input.colorBy.employee': 'Par employé',
   'input.colorBy.appointment': 'Par statut de rendez-vous',
   'input.timeSlot.label': 'Interval de créneau horaire',
   'input.defaultViewType.label': 'Vue par défaut',
   'input.calendarTimeRange.label': 'Plage horaire du calendrier',
   'input.calendarTimeRange.allTimes.option': 'Toutes les heures (00:00 - 24:00)',
   'input.calendarTimeRange.onlyWorkingHours.option': 'Seulement les heures de travail',
   'input.calendarTimeRange.customRange.option': 'Plage personnalisée',
   'input.weekStart.label': 'Jour de début de la semaine',
   'table.header.refSourcetitle': 'Source de référence',
   'table.header.refSourceAddDate': "Date d'ajout",
   'btn.new.referral': 'Nouvelle source de référence',
   'input.nameRefSource.label': 'Nom de la source de référence',
   'input.nameRefSource.placeholder': 'exp: annonces Facebook',
   'modal.newRefSource.title': 'Nouvelle source de référence',
   'modal.editRefSource.title': 'Modifier source de référence',
   'input.sendBy.label': 'Envoyer par',
   'input.emailSubject.label': "Sujet de l'email",
   'input.emailTemplate.label': "Modèle de l'email",
   'input.emailTemplate.placeholder': "Entrer le modèle de l'email",
   'input.subject.label': "Sujet de l'email",
   'input.subject.placeholder': "Entrer le sujet de l'email",
   'title.messagingSettings.label': 'Paramètres de messagerie',
   'title.messagingSettings.description':
      "La configuration des types de message ici sera automatiquement envoyée aux clients. Tous les messages générés sont facilement accessibles sur la page des messages (dans le menu principal). Réglez les paramètres de comment et quand les messages sont envoyés et modifiez les modèles pour personnaliser le texte. Utilisez les balises ci-dessous pour inclure les détails de rendez-vous à l'intérieur des messages:",
   'client.first.name': 'CLIENT_FIRST_NAME',
   'client.last.name': 'CLIENT_LAST_NAME',
   'staff.first.name': 'STAFF_FIRST_NAME',
   'staff.last.name': 'STAFF_LAST_NAME',
   'booking.date.time': 'BOOKING_DATE_TIME',
   'booking.date': 'BOOKING_DATE',
   'booking.time': 'BOOKING_TIME',
   'service.name': 'SERVICE_NAME',
   'business.name': 'BUSINESS_NAME',
   'business.phone': 'BUSINESS_PHONE',
   'business.address': 'BUSINESS_ADDRESS',
   'business.email': 'BUSINESS_EMAIL',
   'location.name': 'LOCATION_NAME',
   'location.phone': 'LOCATION_PHONE',
   'location.address': 'LOCATION_ADDRESS',
   'location.email': 'LOCATION_EMAIL',
   'tabs.reminders.title': 'Rappels',
   'input.enableReminder.label': 'Activer les messages de rappel',
   'input.enableReminder.description': 'Envoie automatiquement aux clients avant leur prochain rendez-vous',
   'input.reminder.title': 'Rappel {0}',
   'input.message.title': 'Message {0}',
   'input.reminder.description': "Canaux et heure d'envoi",
   'input.reminder.channels': 'Sélectionnez les canaux sur lesquels ce message sera envoyé',
   'input.reminderBefore.label': 'Des rappels seront envoyés avant',
   'input.smsSender.label': "ID de l'expéditeur du SMS",
   'input.messageSender.label': "ID de l'expéditeur du message",
   'input.smsSender.placeholder': "Entrer l'ID de l'expéditeur du SMS",
   'input.smsTemplate.label': 'Modèle du SMS',
   'input.messageTemplate.label': 'Modèle de message',
   'input.smsTemplate.placeholder': 'Entrer le modèle du SMS',
   'input.whatsAppSender.placeholder': 'Plandok',
   'reminder.whatsApp.notificate':
      "Le modèle de message WhatsApp n'est actuellement pas modifiable en raison de limitations. Cependant, nous travaillons activement à le rendre entièrement modifiable.",
   'reminder.charactersCount': 'Les caractères comptent:',
   'reminder.pricing': 'Tarification',
   'modal.pricing.description':
      'Les prix varient selon les pays. Nous fournissons le meilleur taux possible auprès de nos partenaires. Il peut varier.',
   'modal.pricing.subDescription':
      'Les frais seront appliqués une fois les messages envoyés et ajoutés à la prochaine facture.',
   'modal.pricing.counterDescription':
      'Un SMS standard est limité à 160 caractères et à 70 caractères pour les messages contenant des caractères spéciaux.',
   'modal.pricing.learnMore': 'En savoir plus',
   'modal.pricing.priceRates': 'Tarifs',
   'modal.pricing.messagePrice': "Prix d'un message:",
   'modal.sendTestMessageByNumber.title': 'Envoyez un message test à ce numéro:',
   'modal.sendTestMessageByNumber.description': "Les frais s'appliquent comme pour un message normal.",
   'modal.sendTestMessageByEmail.title': 'Envoyer un message test à cette adresse e-mail:',
   'modal.sendTestMessageByEmail.placeholder': "Veuillez saisir l'adresse e-mail",
   'btn.send': 'Envoyer',
   'reminder.sendTestMessage': 'Envoyer un message test',
   'button.reminder.addTag': 'Ajouter une étiquette',
   'button.reminder.addAnotherReminder': 'AJOUTER UN AUTRE RAPPEL',
   'button.message.addAnotherMessage': 'AJOUTER UN AUTRE MESSAGE',
   'button.reminder.upgrade': 'Mise à niveau pour accéder',
   'label.emailSettings': 'Paramètres Email',
   'label.SMSSettings': 'Paramètres SMS',
   'label.whatsAppSettings': 'Paramètres WhatsApp',
   'tooltip.sender.0': "Il sera affiché comme nom de l'expéditeur de SMS",
   'tooltip.sender.1': '11 caractères maximum',
   'tooltip.sender.2': 'Peut ne pas fonctionner dans certains pays',
   'tooltip.sender.3': 'Lettres latines ou numéro seulement',
   'tabs.confirmations.title': 'Confirmation',
   'btn.appBookings': 'Réservations en App',
   'btn.onlineBookings': 'Réservations en ligne',
   'input.enableAppConfirmation.label': 'Activer les confirmations de réservations en App',
   'input.enableOnlineConfirmation.label': 'Activer les confirmations de réservations en ligne',
   'input.enableConfirmationApp.description': "Envoi automatique aux clients lorsqu'un rendez-vous est pris pour eux",
   'input.enableConfirmationOnline.description': "Envoi automatique aux clients lorsqu'ils prennent un rendez-vous",
   'messageSetup.header': 'Configuration des messages',
   'channels.subHeader': 'Canaux',
   'tabs.reschedules.title': 'Replanifier',
   'input.enableReschedule.label': 'Activer les messages de replanification',
   'input.enableReschedule.description':
      'Envoie automatiquement aux clients lorsque le temps de début de leur rendez-vous est modifié',
   'tabs.cancellations.title': 'Annulation',
   'input.enableCancellation.label': "Activer les messages d'annulation",
   'input.enableCancellations.description': 'Envoie automatiquement aux clients lorsque leur rendez-vous est annulé',
   'tabs.thanks.title': 'Remerciement',
   'input.enableThank.label': 'Activer les messages de remerciement',
   'input.enableThankyou.description':
      'Le message est envoyé au client lorsque le statut du rendez-vous est marqué comme terminé',
   'tabs.lastVisit.title': 'Dernière visite',
   'input.enableLastVisit.label': 'Activier les messages de dernière visite',
   'input.enableLastVisit.description':
      "Envoie automatiquement aux clients quand ils n'ont pas visité votre business pendant le nombre de jours indiqué",
   'table.cancelReason.name': "Motifs d'annulation",
   'table.cancelReason.addDate': 'Date ajoutée',
   'btn.new.cancelReason': "Nouveau motif d'annulation",
   'modal.newCancelReason.title': "Nouveau motif d'annulation",
   'modal.editCancelReason.title': "Modifier motif d'annulation",
   'input.cancelReason.title': "Nom du motif d'annulation",
   'input.cancelReason.placeholder': 'exp: client indisponible',
   'table.discounts.name': 'Réductions',
   'table.discounts.value': 'Valeur',
   'table.discounts.addDate': 'Date de création',
   'input.discountName.label': 'Nom de la réduction',
   'input.discountName.placeholder': 'exp: Personnes âgées',
   'input.discountValue.label': 'Valeur de réduction',
   'btn.new.discount': 'Nouvelle réduction',
   'input.discountPercentage.label': '% Pourcentage',
   'input.discountSum.label': 'Montant',
   'modal.newDiscount.title': 'Nouveaun type de réduction',
   'modal.editDiscount.title': 'Modifier réduction',
   'modal.deleteAccount.title': 'Supprimer un compte',
   'modal.deleteAccount.description':
      'La suppression de votre compte est un processus irréversible et toutes vos données seront supprimées à jamais.',
   'modal.deleteAccount.successMessage': 'Votre compte a été supprimé avec succès.',
   'title.mySettings': 'Mes paramètres',
   'title.personalDetails': 'Détails personnels',
   'title.personalDetails.description':
      "Définissez votre nom et vos coordonnées, l'adresse e-mail indiquée ici est utilisé pour votre connexion",
   'btn.continueWithGoogle.title': 'Continuer avec Google',
   'btn.continueWithFacebook.title': 'Continuer avec Facebook',
   'notification.googleAccountUnlinked': 'Compte Google non lié',
   'notification.googleAccountLinked': 'Compte Google lié',
   'notification.facebookAccountUnlinked': 'Compte Facebook non lié',
   'notification.facebookAccountLinked': 'Compte Facebook lié',
   'btn.linkAccount': 'Lier le compte',
   'btn.unlinkAccount': 'Compte dissocié',
   'btn.deleteYourAccount': 'Supprimer votre compte',
   'input.myName.label': 'Prénom',
   'input.myName.placeholder': 'exp: John',
   'input.mylastName.label': 'Nom de famille',
   'input.mylastName.placeholder': 'exp: Doe',
   'title.changePassword': 'Changer le mot de passe',
   'title.changePassword.description':
      'Pour effectuer une mise à jour, saisissez votre mot de passe actuel suivi d\'un nouveau. Si vous ne \\n connaissez pas votre mot de passe actuel, vous pouvez vous déconnecter et utiliser le lien "Réinitialiser le mot de passe" sur la page de connexion.',
   'input.currentPassword.label': 'Mot de passe actuel',
   'label.currentPassword.placeholder': 'Entrer le mot de passe actuel',
   'input.newPassword.label': 'Nouveau mot de passe',
   'input.newPassword.placeholder': 'Entrer un nouveau mot de passe',
   'input.verifyPassword.label': 'Répéter le mot de passe',
   'input.verifyPassword.placeholder': 'Répété le nouveau mot de passe',
   'btn.confirmDelete': 'Oui, supprimer',
   'btn.confirm': 'Confirmer',
   'btn.confirmBook': 'Confirmer et réserver',
   'btn.confirmPay': 'Confirmer et payer',
   'btn.delete': 'Supprimer',
   'btn.close': 'Fermer',
   'validation.required': 'Champ obligatoire',
   'validation.email.format': "Format de l'email incorrect",
   'validation.url.format': "Format d'url incorrect, doit commencer par https:// ou http://",
   'validation.password.length': 'Le mot de passe doit contenir au moins 7 caractères',
   'validation.password.letters': 'Le mot de passe doit contenir des chiffres et des lettres',
   'validation.startTime': "L'heure de début ne peut pas être postérieure ou identique à l'heure de fin",
   'validation.endTime': "L'heure de fin ne peut pas être antérieure ou identique à l'heure de début",
   'tooltip.password.title': 'Le mot de passe doit:',
   'tooltip.password.characters': 'Contenir au moins 7 caractères',
   'tooltip.password.number': 'Inclure un chiffre',
   'tooltip.password.letter': 'Inclure une lettre',
   'tooltip.location': "Infobulle de l'emplacement",
   'footer.support.center': "Besoin d'aide? Contactez-nous",
   'not.found': 'Introuvable',
   'input.cancelReason.label': "Motif d'annulation",
   'input.location.label': 'Emplacement',
   'input.location.placeholder': "Nom de l'emplacement",
   'input.name.label': 'Nom',
   'title.recentlyAddedClients': 'Clients (ajoutés récemment)',
   'title.calendarView': 'Voir le calendrier',
   'text.manageProfile': 'Gérer votre profil utilisateur',
   'modal.language.title': 'Choisir la langue',
   'modal.language.placeholder': 'Recherche langue',
   'dropdown.access.medium': 'Moyen',
   'dropdown.access.high': 'Haut',
   'dropdown.access.low': 'Bas',
   'dropdown.access.basic': 'Basique',
   'dropdown.access.no': "Pas d'accès",
   'dropdown.access.owner': 'Propriétaire',
   'button.select.all': 'Tout sélectionner',
   'button.selected': 'Sélectionné',
   'dropdown.gender.unknown': 'Inconnu',
   'dropdown.gender.male': 'Masculin',
   'dropdown.gender.female': 'Féminin',
   'dropdown.notificationBy.none': 'Ne pas envoyer',
   'dropdown.notificationBy.sms': 'SMS',
   'dropdown.notificationBy.email': 'Email',
   'dropdown.notificationBy.combined': 'SMS et e-mail',
   'notification.success.login': 'Connecté avec succès!',
   'notification.success.register': 'Enregistré avec succès!',
   'dropdown.time.minutes': 'minutes',
   'dropdown.repeat.no': 'Ne pas répéter',
   'dropdown.repeat.weekly': 'Hebdomadaire',
   'dropdown.endRepeat.ongoing': 'En cours',
   'dropdown.endRepeat.weekly': 'Date spécifique',
   'dropdown.time.appointment.immediately': 'Immédiatement',
   'dropdown.time.appointment.upTo1Hour': "Jusqu'à 1 heure",
   'dropdown.time.appointment.upTo2Hours': "Jusqu'à 2 heures",
   'dropdown.time.appointment.upTo3Hours': "Jusqu'à 3 heures",
   'dropdown.time.appointment.upTo4Hours': "Jusqu'à 4 heures",
   'dropdown.time.appointment.upTo5Hours': "Jusqu'à 5 heures",
   'dropdown.time.appointment.upTo6Hours': "Jusqu'à 6 heures",
   'dropdown.time.appointment.upTo7Hours': "Jusqu'à 7 heures",
   'dropdown.time.appointment.upTo8Hours': "Jusqu'à 8 heures",
   'dropdown.time.appointment.upTo9Hours': "Jusqu'à 9 heures",
   'dropdown.time.appointment.upTo10Hours': "Jusqu'à 10 heures",
   'dropdown.time.appointment.upTo11Hours': "Jusqu'à 11 heures",
   'dropdown.time.appointment.upTo12Hours': "Jusqu'à 12 heures",
   'dropdown.time.appointment.upTo13Hours': "Jusqu'à 13 heures",
   'dropdown.time.appointment.upTo14Hours': "Jusqu'à 14 heures",
   'dropdown.time.appointment.upTo15Hours': "Jusqu'à 15 heures",
   'dropdown.time.appointment.upTo16Hours': "Jusqu'à 16 heures",
   'dropdown.time.appointment.upTo17Hours': "Jusqu'à 17 heures",
   'dropdown.time.appointment.upTo18Hours': "Jusqu'à 18 heures",
   'dropdown.time.appointment.upTo19Hours': "Jusqu'à 19 heures",
   'dropdown.time.appointment.upTo20Hours': "Jusqu'à 20 heures",
   'dropdown.time.appointment.upTo21Hours': "Jusqu'à 21 heures",
   'dropdown.time.appointment.upTo22Hours': "Jusqu'à 22 heures",
   'dropdown.time.appointment.upTo23Hours': "Jusqu'à 23 heures",
   'dropdown.time.appointment.upTo24Hours': "Jusqu'à 1 jour",
   'dropdown.time.appointment.upTo48Hours': "Jusqu'à 2 jours",
   'dropdown.time.appointment.upTo3days': "Jusqu'à 3 jours",
   'dropdown.time.appointment.upTo4days': "Jusqu'à 4 jours",
   'dropdown.time.appointment.upTo5days': "Jusqu'à 5 jours",
   'dropdown.time.appointment.upTo6days': "Jusqu'à 6 jours",
   'dropdown.time.appointment.upTo1week': "Jusqu'à 1 semaine",
   'dropdown.time.appointment.upTo2weeks': "Jusqu'à 2 semaines",
   'dropdown.time.appointment.upTo15Min': "Jusqu'à 15 minutes",
   'dropdown.time.appointment.upTo30Min': "Jusqu'à 30 minutes",
   'dropdown.time.appointment.upTo1Month': "Jusqu'à 1 mois",
   'dropdown.time.appointment.upTo2Months': "Jusqu'à 2 mois",
   'dropdown.time.appointment.upTo3Months': "Jusqu'à 3 mois",
   'dropdown.time.appointment.upTo4Months': "Jusqu'à 4 mois",
   'dropdown.time.appointment.upTo5Months': "Jusqu'à 5 mois",
   'dropdown.time.appointment.upTo6Months': "Jusqu'à 6 mois",
   'dropdown.time.appointment.interval5Min': '5 minutes',
   'dropdown.time.appointment.interval10Min': '10 minutes',
   'dropdown.time.appointment.interval15Min': '15 minutes',
   'dropdown.time.appointment.interval20Min': '20 minutes',
   'dropdown.time.appointment.interval30Min': '30 minutes',
   'dropdown.time.appointment.interval40Min': '40 minutes',
   'dropdown.time.appointment.interval45Min': '45 minutes',
   'dropdown.time.appointment.interval50Min': '50 minutes',
   'dropdown.time.appointment.interval60Min': '60 minutes',
   'dropdown.time.appointment.interval75Min': '75 minutes',
   'dropdown.time.appointment.interval90Min': '90 minutes',
   'dropdown.time.appointment.interval120Min': '120 minutes',
   'dropdown.time.appointment.interval150Min': '150 minutes',
   'dropdown.time.appointment.interval180Min': '180 minutes',
   'dropdown.time.appointment.interval240Min': '240 minutes',
   'dropdown.time.appointment.anyTime': 'À tout moment',
   'dropdown.time.appointment.disabled': 'Éteindre',
   'dropdown.time.appointment.doesntRepeat': 'Ne se répète pas',
   'dropdown.time.appointment.daily': 'Tous les jours',
   'dropdown.time.appointment.every2Days': 'Tous les 2 jours',
   'dropdown.time.appointment.every3Days': 'Tous les 3 jours',
   'dropdown.time.appointment.every4Days': 'Tous les 4 jours',
   'dropdown.time.appointment.every5Days': 'Tous les 5 jours',
   'dropdown.time.appointment.every6Days': 'Tous les 6 jours',
   'dropdown.time.appointment.every7Days': 'Tous les 7 jours',
   'dropdown.time.appointment.weekly': 'Hebdomadaire',
   'dropdown.time.appointment.every2weeks': 'Toutes les 2 semaines',
   'dropdown.time.appointment.every3weeks': 'Toutes les 3 semaines',
   'dropdown.time.appointment.every4weeks': 'Toutes les 4 semaines',
   'dropdown.time.appointment.every5weeks': 'Toutes les 5 semaines',
   'dropdown.time.appointment.every6weeks': 'Toutes les 6 semaines',
   'dropdown.time.appointment.every7weeks': 'Toutes les 7 semaines',
   'dropdown.time.appointment.every8weeks': 'Toutes les 8 semaines',
   'dropdown.time.appointment.every9weeks': 'Toutes les 9 semaines',
   'dropdown.time.appointment.every10weeks': 'Toutes les 10 semaines',
   'dropdown.time.appointment.monthly': 'Mensuel',
   'dropdown.time.appointment.every2months': 'Tous les 2 mois',
   'dropdown.time.appointment.every3months': 'Tous les 3 mois',
   'dropdown.time.appointment.every4months': 'Tous les 4 mois',
   'dropdown.time.appointment.every5months': 'Tous les 5 mois',
   'dropdown.time.appointment.every6months': 'Tous les 6 mois',
   'dropdown.time.appointment.every7months': 'Tous les 7 mois',
   'dropdown.time.appointment.every8months': 'Tous les 8 mois',
   'dropdown.time.appointment.every9months': 'Tous les 9 mois',
   'dropdown.time.appointment.every10months': 'Tous les 10 mois',
   'dropdown.time.appointment.every11months': 'Tous les 11 mois',
   'dropdown.time.appointment.after2times': 'Après 2 fois',
   'dropdown.time.appointment.after3times': 'Après 3 fois',
   'dropdown.time.appointment.after4times': 'Après 4 fois',
   'dropdown.time.appointment.after5times': 'Après 5 fois',
   'dropdown.time.appointment.after6times': 'Après 6 fois',
   'dropdown.time.appointment.after7times': 'Après 7 fois',
   'dropdown.time.appointment.after8times': 'Après 8 fois',
   'dropdown.time.appointment.after9times': 'Après 9 fois',
   'dropdown.time.appointment.after10times': 'Après 10 fois',
   'dropdown.time.appointment.after11times': 'Après 11 fois',
   'dropdown.time.appointment.after12times': 'Après 12 fois',
   'dropdown.time.appointment.after13times': 'Après 13 fois',
   'dropdown.time.appointment.after14times': 'Après 14 fois',
   'dropdown.time.appointment.after15times': 'Après 15 fois',
   'dropdown.time.appointment.after20times': 'Après 20 fois',
   'dropdown.time.appointment.after25times': 'Après 25 fois',
   'dropdown.time.appointment.after30times': 'Après 30 fois',
   'dropdown.time.appointment.specificDate': 'Date précise',
   'modal.createHours.title': 'Créer {0} heures',
   'modal.editHours.title': 'Modifier {0} heures',
   'modal.deleteItem.confirm.0': 'Êtes-vous sûr de vouloir ',
   'modal.deleteItem.confirm.1': 'supprimer de façon permanente',
   'modal.deleteItem.confirm.2': 'de la base de donnée?',
   'modal.entity.category': 'Catégorie',
   'modal.entity.referal': 'Source de référence',
   'modal.entity.resource': 'Ressource',
   'modal.entity.location': 'Emplacement',
   'modal.deleteReminder.title': 'Supprimer le rappel',
   'modal.deleteMessage.title': 'Supprimer le message',
   'modal.deleteReminder.description': 'Êtes-vous sûr de vouloir supprimer ce rappel ?',
   'modal.deleteMessage.description': 'Êtes-vous sûr de vouloir supprimer ce message ?',
   'modal.deleteReminder.confirm': 'Oui, supprimer',
   'modal.deletePaymentMethod.title': 'Supprimer le mode de paiement',
   'modal.deletePaymentMethod.description': 'Ce mode de paiement sera définitivement supprimé',
   'closedDate.create.modal.title': 'Ajouter une date de fermeture',
   'closedDate.edit.modal.title': 'Modifier la date de fermeture',
   'staff.access.owner': 'Propriétaire',
   'staff.access.high': 'Haut',
   'staff.access.medium': 'Moyen',
   'staff.access.low': 'Bas',
   'staff.access.basic': 'Basique',
   'staff.access.no_access': "Pas d'accès",
   'staff.create.title': 'Ajouter une date de fermeture',
   'staff.emptyState.title': 'Aucun membre du personnel prévu.',
   'staff.emptyState.description': 'Voir la section membres du personnel pour ajouter des heures de travail.\n',
   'staff.viewStaffMembers.button': 'Voir les membres du personnel',
   'staff.picture.changePhoto': 'Changer de photo',
   'staff.picture.removePhoto': 'Supprimer la photo',
   'input.clientSearch.placeholder': 'Recherche par nom, e-mail ou téléphone mobile',
   'input.blockedDate.label': 'Date',
   'appointment.notes.title': 'Remarques de rendez-vous',
   'appointment.history.title': 'Historique des rendez-vous',
   'appointment.history.by.title': ' par {0}',
   'appointment.log.created.label': 'Créé par',
   'appointment.log.updated.label': 'Mis à jour par',
   'appointment.log.rescheduled.label': 'Reprogrammé par',
   'appointment.log.cancelled.label': 'Annulé par',
   'appointment.log.changed.label': 'Statut modifié par',
   'appointment.notificationLog.rescheduling': 'Notification de reprogrammation envoyée le',
   'appointment.notificationLog.cancellation': "Notification d'annulation envoyée le",
   'appointment.notificationLog.confirmation': 'Notification de confirmation envoyée le',
   'appointment.notificationLog.reminder': 'Notification de rappel envoyée le',
   'appointment.notificationLog.thankYou': 'Merci notification envoyée le',
   'appointment.notificationLog.lastVisit': 'Notification de dernière visite envoyée le',
   'state.cancelReason.title': "Définir les motifs d'annulation",
   'state.cancelReason.description':
      "Les motifs d'annulations vous permettent de suivre les raisons pour lesquelles vos clients ne sont pas venus pour leurs rendez-vous.",
   'state.cancelReason.button': "Ajouter motif d'annulation",
   'state.resources.title': 'Gérer vos ressources',
   'state.resources.description':
      'Votre entreprise est plus efficace quand vous êtes au courant des ressources disponibles. Assurez le suivi des chambres, meubles ou tout autre dispositif pour assurer un service aisé et sans incidents.',
   'state.resources.button': 'Ajouter une ressource',
   'state.referralSource.title': 'Gérer les sources de référence',
   'state.referralSource.description':
      "Une source de référence indique d'où viennent les clients. Tracez-les et utilisez cette information pour augmenter la visibilité et le taux de conversion de votre entreprise.",
   'state.referralSource.button': 'Ajouter une source de référence',
   'state.locations.title': 'Définir vos emplacements',
   'state.locations.description':
      'Ici, vous pouvez gérer plusieurs points de vente de votre entreprise, définir différents horaires par emplacement pour votre personnel et avoir des agendas individuels pour chacun.',
   'state.staff.title': 'Gérer votre personnel',
   'state.staff.description':
      'Créez des profils pour vos employés pour pouvoir ajouter et modifier leurs planifications et suivre leurs rendez-vous et horaires de travail.',
   'state.messageLog.title': 'Garder une trace des messages',
   'state.messageLog.description':
      'Les messages rappellent vos clients des rendez-vous à venir ou les tiennent au courant. Ici, vous pouvez suivre les messages et leurs statuts. Pour configurer les messages automatiques, aller à',
   'state.messageLog.link': 'Paramètres de notification.',
   'state.clients.title': 'Administrer vos client',
   'state.clients.description':
      "Prendre soin de vos clients commence par s'assurer que leurs profils contiennent toutes les informations requises",
   'state.clients.button': 'Ajouter client',
   'state.services.title': 'Définir groupes et services',
   'state.services.description':
      "Les groupes sont essentiels puisqu'ils vous permettent de grouper les services similaires que vous fournissez. De plus, ils rendent votre calendrier plus propre et facilement accessible!",
   'state.services.button': 'Ajouter un groupe de service',
   'state.calendar.title': 'Aucun membre du personnel planifié',
   'state.calendar.description': 'Pour définir les horaires de travail du personnel, consulter la',
   'state.calendar.button': 'Section du personnel',
   'state.closedDates.title': 'Dates de fermeture',
   'state.closedDates.description':
      'Indiquez les dates de fermeture de votre entreprise pour jours fériés, maintenance ou toute autre raison. Les clients ne pourront pas effectuer de réservations en ligne pendant ces jours-là.',
   'state.closedDates.button': 'Ajouter une date de fermeture',
   'title.billingPlan': 'Plan et facturation',
   'btn.upgrade': 'Améliorer',
   'header.usage': 'USAGE',
   'header.dropdown.subscription.plan': '{0} Plan',
   'header.dropdown.subscription.staff': '({0} Membres du personnel)',
   'header.dropdown.notifications.description': '{0} messages',
   'header.paymentDetails': 'Voir les détails du paiement',
   'title.billingPlan.description':
      'Gérer vos comptes, le plan et les informations de facturations, afficher les transactions et télécharger les factures.',
   'plan.free.title': 'De démarrage',
   'plan.individual.title': 'Individuel (1 seul collaborateur)',
   'plan.business.title': 'Entreprise',
   'btn.upgradeNow': 'Améliorer',
   'btn.getStarted': 'Commencer',
   'btn.currentPlan': 'Plan actuel',
   'plan.free.option.0': '1 Emplacement',
   'plan.free.option.1': "Jusqu'à 5 employés",
   'plan.free.option.2': 'Rendez-vous illimités',
   'plan.free.option.3': 'Clients illimités',
   'plan.free.option.4': 'Réservations en ligne illimitées',
   'plan.free.option.5': 'Notifications par courrier électronique',
   'plan.premium.option.0': 'Emplacements illimités',
   'plan.premium.option.1': 'Rendez-vous illimités',
   'plan.premium.option.2': 'Clients illimités',
   'plan.premium.option.3': 'Réservations en ligne illimitées',
   'plan.premium.option.4': 'Rendez-vous récurrents',
   'plan.premium.option.5': 'Notifications par courrier électronique',
   'plan.premium.option.6': 'Notifications SMS* ',
   'plan.premium.option.7': 'Notifications WhatsApp*',
   'plan.premium.option.8': 'Analyses illimitées',
   'plan.premium.option.9': 'Rapports illimités',
   'plan.premium.option.10': 'Imprimer les horaires',
   'plan.premium.option.11': 'Exportation clients',
   'plan.premium.option.12': 'Assistance prioritaire',
   'plan.premium.option.13': 'Suppression de la marque Plandok des e-mails (e-mails gratuits propulsés par Plandok)',
   'plan.premium.costsInfo': "*Frais SMS/WhatsApp non inclus dans le forfait, des frais supplémentaires s'appliquent.",
   'plan.premium.activeUntilDate': "Actif jusqu'à {0}",
   'period.perMonth': 'par mois (hors TVA)',
   'plan.premium.description': 'Le prix augmentera en ajoutant des membres du personnel.',
   'plan.premium.viewPricing': 'Voir les tarifs',
   'plan.premium.numberStuff': 'Votre nombre actuel de collaborateurs : {0}',
   'premium.membership': "Le prix de l'abonnement Premium peut varier en fonction du nombre d'employés que vous avez.",
   'premium.price.perMonth': 'Prix premium par mois',
   'pricing.calculated': 'Le prix est calculé comme suit :',
   'pricing.calculator': 'Calculateur de prix',
   'pricing.numberStuff': "Nombre d'employés",
   'pricing.extra.member': '{0} pour chaque membre supplémentaire',
   'modal.title.pricing': 'Tarification',
   'plan.foreverFree': 'Gratuit pour toujours',
   'plan.individual.0': 'Tout pour démarrage, plus:',
   'plan.individual.1': 'Notifications personnalisées',
   'plan.individual.2': "Pas d'image Plandok",
   'plan.individual.option.3': 'Rappels SMS illimités',
   'plan.individual.4': 'Assitance prioritaire',
   'plan.business.0': 'Tout pour individuel, plus:',
   'plan.business.1': 'Emplacements illimités',
   'plan.business.2': 'Personnel illimité',
   'block.billingInfo.title': 'Informations de facturation',
   'block.carInfo.title': 'Informations de la carte de crédit',
   'input.cardNumber.label': 'Numéro de carte',
   'input.cardNumber.placeholder': '1234 5678 3456 2456',
   'input.expireDate.label': "Date d'expiration",
   'input.expireDate.placeholder': '21/05',
   'input.cvv.label': 'CVV',
   'input.cvv.placeholder': '123',
   'modal.confirmCancel.subscription': "Êtes-vous sûr de vouloir annuler l'abonnement?",
   'modal.confirmCancel.subscription.description':
      'Une fois effectué, vous serez amené à utiliser la version gratuite.',
   'btn.cancelSubscription.confirm': 'Oui, annuler mon abonnement',
   'loading.processingSubscribtion': 'Votre paiement est en cours de traitement. Veuillez patienter...',
   'state.serviceGroup.description': 'Aucun service dans le groupe',
   'state.serviceGroup.btn': 'Ajouter un service',
   'appointment.status.new': 'Nouveau rendez-vous',
   'appointment.status.confirmed': 'Confirmé',
   'appointment.status.notShow': 'Annulation',
   'appointment.status.completed': 'Terminé',
   'appointment.status.cancelled': 'Annulé',
   'billing.tab.plan': 'Votre plan',
   'billing.tab.costs': 'Prix estimés',
   'billing.tab.billing': 'Informations de facturation',
   'billing.tab.invoice': 'Factures',
   'plan.free': 'Gratuit',
   'plan.deactivated.title': 'La prime a été désactivée',
   'plan.active.title': 'Activer',
   'payment.step.title': 'Paiements',
   'payment.error.title': 'Mettre à jour le paiement',
   'payment.payNow.title': 'Payer',
   'payment.dismiss.title': 'Rejeter',
   'payment.tab.title.sub': 'Abonnement',
   'payment.tab.subTitle': 'Forfait Premium',
   'payment.per.members': 'par membre',
   'payment.tab.title.notification': 'Avis',
   'payment.card.details': 'Détails de la carte',
   'payment.success.message': 'Le paiement a été effectué avec succès',
   'payment.payWithCard.title': 'Payer par carte',
   'payment.cardNumber.title': 'Numéro de la carte',
   'payment.expirationDate.title': "Date d'expiration",
   'payment.digits.title': '(3 chiffres)',
   'payment.amount': 'Montant du paiement:',
   'payment.btn.pay': 'Payer',
   'invoice.table.date': 'Date',
   'invoice.table.invoice': 'Facture',
   'invoice.table.download': 'Télécharger',
   'invoice.table.status': 'Statut',
   'costs.billingPeriod.title': 'Coûts estimés pour cette période de facturation',
   'costs.billingPeriod.description':
      "Il s'agit des coûts actuels pour votre utilisation de cette période de facturation. Une ventilation de vos coûts est disponible ci-dessous.",
   'costs.billingPeriod.additionalInfo':
      'Lorsque vos frais de notification atteindront 50 €, ils seront automatiquement facturés. Mais ne vous inquiétez pas si le montant est inférieur, il sera commodément inclus dans votre abonnement mensuel.',
   'costs.summary.title': 'Résumé du mois à ce jour',
   'costs.summary.description': 'Ces frais sont pris en compte dans le solde de votre compte',
   'costs.summary.detailedCharges': 'Frais détaillés',
   'costs.summary.noData': 'Pas de données',
   'costs.summary.quantity': 'Quantité',
   'costs.summary.subtotal': 'Total',
   'costs.summary.taxes': 'Impôts',
   'dropdown.time.hours': 'heures',
   'message.planUpgrade': 'Vous avez amélioré votre plan avec succès!',
   'costs.subscription': 'Abonnement',
   'btn.cancelSubscription': "Annuler l'abonnement",
   'btn.no': 'Non',
   'notification.success.update': 'Mis à jour avec succès',
   'validation.password.match': 'Le mot de passe et le mot de passe de confirmation ne correspondent pas',
   'blocked.time': 'Horaire bloqué',
   'message.successReset.password':
      "Terminé! L'email de réinitialisation de mot de passe a été envoyé, veuillez vérifer votre boite de réception",
   'title.newPassword': 'Entrer le nouveau mot de passe',
   'title.newPassword.description':
      'Accéder à votre compte en toute sécurité en créant un nouveau mot de passe de connexion',
   'input.newPasswordRepeat.label': 'Confirmer votre nouveau mot de passe',
   'btn.change.password': 'Changer mon mot de passe',
   'text.havingTrouble': 'Avez-vous des problèmes?',
   'link.resetPassword.again': 'Réinitialiser de nouveau votre mot de passe',
   'message.successNew.password': 'Mot de passe changé avec succès',
   'title.repeatShifts': 'Séance récurrente',
   'text.confirmShift.update':
      'Vous avez modifié une séance qui se répète chaque semaine. La suppression des prochaines séances écrasera {0} programmes en cours',
   'text.confirmShift.delete':
      'Vous avez supprimé une séance qui se répète chaque semaine. La suppression des prochaines séances écrasera {0} programmes en cours',
   'btn.deleteUpcoming.shifts': 'Supprimer les prochaines séances',
   'btn.deleteThis.shift': 'Supprimer cette séance uniquement',
   'btn.updateUpcoming.shifts': 'Mettre à jour les prochaines séances',
   'btn.updateThis.shift': 'Mettre à jour cette séance uniquement',
   'text.permisionTab':
      "Définir quelles sections sont accessibles pour chaque niveau de permission d'utilisateur. Tous les membres du personnel connectés peuvent accéder à l'agenda et les comptes propriétaires ont un accès système complet",
   'title.permission.locations': 'Emplacements',
   'title.permission.booking': 'Réservation & clients',
   'title.permission.staff': 'Personnel',
   'title.permission.setup': 'Installation',
   'title.permission.basic': 'Basique',
   'title.permission.low': 'Faible',
   'title.permission.med': 'Moyenne',
   'title.permission.high': 'Haute',
   'title.permission.owner': 'Propriétaire',
   'title.allLocations.permission': 'Accède à tous les emplacements',
   'title.accessCalendar.permission': 'Accède à son propre agenda',
   'title.accessOtherCalendar.permission': 'Accède aux agendas des autres membres du personnel',
   'title.canBook.permission': 'Peut prendre des rendez-vous',
   'title.contactInfo.permission': 'Peut voir les informations de contact du client',
   'title.clients.permission': 'Clients',
   'title.messages.permission': 'Messages',
   'title.services.permission': 'Services',
   'title.workingHours.permission': 'Horaires de travail',
   'title.staffMembers.permission': 'Membres du personnel',
   'title.permissionLevels.permission': 'Niveaux de permission',
   'title.accountSetup.permission': 'Paramètres du compte',
   'title.clientSettings.permission': 'Paramètres du client',
   'title.onlineBookings.permission': 'Réservations en ligne',
   'title.analytics.permission': 'Analytique',
   'title.marketing.permission': 'Commercialisation',
   'notification.type.reminder': 'Rappel',
   'notification.type.confirmation': 'Confirmation',
   'notification.type.online_booking_confirmation': 'Confirmation de réservation en ligne',
   'notification.type.rescheduling': 'Replanificatoin',
   'notification.type.cancellation': 'Annulation',
   'notification.type.thank_you': 'Remerciement',
   'notification.type.last_visit': 'Dernière visite',
   'notification.channel.sms': 'SMS',
   'notification.channel.email': 'Envoyer',
   'notification.channel.whatsapp': 'WhatsApp',
   'notification.status.sent': 'Envoyé',
   'notification.status.pending': 'En attente',
   'notification.status.failed': 'Échoué',
   'dropdown.time.days': 'jours',
   'title.appointment': 'rendez-vous',
   'input.reminderBeforeLastVisit.label': 'La notification sera envoyée après le nombre de jours indiqué',
   'txt.with': 'avec',
   'short.hours': 'h',
   'short.minutes': 'min',
   'input.registrationNumber.label': "Numéro d'entreprise",
   'input.vatNumber.label': 'Numéro de TVA',
   'invoice.table.priceTaxIncl': 'Montant',
   'error.wrongSenderId':
      "L'ID de l'envoyeur doit comporter des chiffres ou des caractères, la longueur doit être supérieure à 3 et inférieure à 11 symboles",
   'sidebar.help': 'Aide',
   'modal.help.title': 'Comment pouvons-nous vous aider ?',
   'modal.help.liveChat.title': 'Chat en direct',
   'modal.help.liveChat.description': 'Vous avez des questions ? Posez-nous la question tout de suite',
   'modal.help.suggestFeature.title': 'Suggérer une fonctionnalité',
   'modal.help.suggestFeature.description':
      'Partagez vos suggestions, voyez celles des autres et votez pour les fonctionnalités que vous souhaitez',
   'modal.help.viewUpdates.title': 'Voir les mises à jour',
   'modal.help.viewUpdates.description': 'Consultez nos dernières mises à jour et améliorations',
   'warning.title.paidFeature': 'Fonctionnalité payante',
   'warning.smsFeatureMissing':
      'Pour accéder aux paramètres SMS, votre planning doit être mis à jour. Pour mettre à jour, visiter:',
   'warning.link.billingAndPricing': 'Plan et facturation',
   'warning.newPage.0': 'a été amélioré!',
   'warning.newPage.1':
      'Si vous avez utilisé une ancienne version de Plandok, votre mot de passe ne fonctionnera plus. Merci de',
   'warning.newPage.2': 'réinitialiser le mot de passe',
   'warning.newPage.3': "pour bénéficier du nouveau système. Pour plus d'informations, contactez-nous",
   'warning.newPage.4': 'ou',
   'title.blockTime.select': 'Sélectionnez une heure',
   'calendar.allResources': 'Toutes les ressources',
   'appointment.edit.title': 'Modifier rendez-vous',
   'appointment.create.title': 'Rendez-vous créé avec succès.',
   'appointment.update.title': 'Rendez-vous mis à jour avec succès.',
   'select.notify.title':
      "{0} ne fonctionne pas à l'intervalle sélectionné, mais vous pouvez toujours prendre des rendez-vous.",
   'booking.notify.title': 'Le temps sélectionné est réservé, mais vous pouvez créer un rendez-vous.',
   'textArea.characters.left': 'Caractères restants',
   'textArea.characters.exceeded': 'Limite dépassée par',
   'validation.characters.exceeded': 'Limite de caractères dépassée',
   'staff.menu.members': 'Membres du personnel',
   'staff.menu.dates': 'Chômés',
   'staff.menu.working': 'Horaires de travail du personnel',
   'staff.menu.permissions': "Permissions d'utilisateur",
   'staff.menu.members.description': "Ajouter ou supprimer des membres d'équipe et contrôler leur niveau d'accès",
   'staff.menu.dates.description': "Planifiez les prochaines fermetures d'entreprises ou les jours fériés",
   'staff.menu.working.description':
      "Gérer la disponibilité de l'agenda en ajustant les horaires de travail du personnel",
   'staff.menu.permissions.description':
      "Définir des autorisations d'accès aux fonctionnalités et informations sensibles",
   'message.successUpdateLocation':
      "C'est fait ! La mise à jour du profil de localisation a été effectuée avec succès.",
   'ob.title': 'Réservation en ligne',
   'ob.overview.tab': 'Aperçu',
   'ob.massMessages.tab': 'Messages de masse',
   'ob.campaigns.tab': 'Campagnes',
   'ob.profile.tab': 'Profil en ligne',
   'ob.links.tab': 'Links',
   'ob.settings.tab': 'Paramètres',
   'ob.payments.tab': 'Paiements',
   'ob.status.online': 'En ligne',
   'ob.status.offline': 'Hors ligne',
   'ob.status.title': 'Statut',
   'ob.profile.step': '{0} de 4',
   'ob.profile.step.title': 'Profil et localisation',
   'ob.profile.step.description':
      "Présentez votre succursale et présentez les services que vous fournissez à vos clients. La partie \"À propos\" est l\\'une des premières sections que vos clients examineront et si ces informations ne sont pas suffisantes, assurez-vous de laisser un numéro de téléphone où les clients peuvent vous contacter pour plus d\\'informations! Entrez l\\'adresse où vous fournissez vos services, afin que vos clients puissent être informés et afficher l\\'adresse.",
   'ob.overview.placheholder.title': 'Laissez vos clients prendre rendez-vous en ligne !',
   'ob.overview.placheholder.description':
      "Créez un profil de réservation en ligne et laissez vos clients prendre rendez-vous en ligne. Insérez simplement un lien vers votre site Web ou vos réseaux sociaux et c'est tout.",
   'ob.overview.start.now': 'Commencez maintenant',
   'ob.links.direct.title': 'Lien direct',
   'ob.links.facebook.title': 'Lien Facebook',
   'ob.links.instagram.title': 'Lien Instagram',
   'ob.links.direct.description':
      "Lien URL vers votre profil de réservations en ligne, vous pouvez l'utiliser sur votre site Web ou sur des comptes de médias sociaux. Si vous ne savez pas comment utiliser le lien, contactez-nous.",
   'ob.links.facebook.description':
      'Partagez ce lien sur Facebook pour attirer plus de clients et facilitez leur recherche votre entreprise et réservez pour un rendez-vous.',
   'ob.links.instagram.description':
      "Facilitez-vous la tâche et liez directement votre réservation en ligne !\nVous pouvez l'utiliser sur votre site Web et partout ailleurs où vous en avez envie.\nSi vous ne savez pas comment utiliser le lien, ne vous inquiétez pas, nous sommes là pour vous !",
   'ob.analytics.tab.description':
      'Ajoutez Google Analytics et Facebook Pixel à votre page de réservation en ligne pour analyser le trafic et le comportement des utilisateurs.',
   'ob.analytics.google.title': 'Google Analytics',
   'ob.analytics.facebook.title': 'Pixel Facebook',
   'ob.analytics.google.description':
      "Avec Google Analytics, vous pouvez suivre et analyser le trafic de votre site web et le comportement des utilisateurs afin d'obtenir des informations sur la manière d'améliorer votre site web.",
   'ob.analytics.active': 'Actif',
   'ob.analytics.inactive': 'Inactif',
   'ob.analytics.setUp.btn': 'Configuré',
   'ob.analytics.google.description.1':
      "Google Analytics est un outil puissant qui peut vous aider à surveiller le trafic sur votre site web et le comportement des utilisateurs. Avec Google Analytics, vous pouvez suivre le nombre de visiteurs sur votre site web, leur provenance, les pages qu'ils visitent et le temps qu'ils passent sur votre site.",
   'ob.analytics.google.description.2':
      'En outre, Google Analytics vous permet de définir des objectifs et de suivre les conversations, ce qui vous permet de mieux comprendre les performances de votre site web et les moyens de les améliorer.',
   'ob.analytics.google.trackingId': 'ID de suivi',
   'ob.analytics.google.tooltip':
      "Pour trouver votre identifiant de suivi, vous devez d'abord vous connecter à votre compte Google Analytics, sélectionner le site Web ou l'application que vous souhaitez suivre, puis accéder à l'onglet 'Admin'. De là, cliquez sur le 'Code de suivi'. Votre code de suivi s'affichera au format 'UA-XXXX-Y'.",
   'ob.analytics.google.account': "Vous n'avez pas encore de compte Google Analytics ?",
   'ob.analytics.click': 'Cliquez',
   'ob.analytics.here': 'ici',
   'ob.analytics.learn': 'pour apprendre à en créer un.',
   'ob.analytics.facebook.description.1':
      "Facebook Pixel est un outil qui vous permet de suivre le comportement des utilisateurs sur votre site web, y compris les conversions, les pages consultées et d'autres événements.",
   'ob.analytics.facebook.description.2':
      "Facebook Pixel peut vous fournir des informations précieuses sur votre public, telles que les données démographiques, les centres d'intérêt, le type d'appareil, les pages consultées et les actions entreprises, ce qui peut vous aider à mieux comprendre et à optimiser l'expérience des utilisateurs sur votre site.",
   'ob.analytics.facebook.pixelId': 'ID du pixel',
   'ob.analytics.facebook.tooltip':
      "Pour trouver votre identifiant de pixel Facebook, procédez comme suit : Accédez à votre compte Facebook Ads Manager. Cliquez sur le bouton 'Menu' dans le coin supérieur gauche de l'écran. Dans la section 'Gestion des événements', sélectionnez 'Pixels'. Vous devriez y trouver une liste de tous les pixels associés à votre compte. Cliquez sur le nom du pixel pour lequel vous avez besoin de l'ID. Votre ID de pixel s'affichera dans le coin supérieur gauche de l'écran, à côté du nom du pixel.",
   'ob.analytics.facebook.account': "Vous n'avez pas encore de page professionnelle Facebook ?",
   'ob.analytics.google.account.mobile.1': "Vous n'avez pas encore de compte Google Analytics",
   'ob.analytics.google.account.mobile.2': 'Google Analytics ? Cliquez sur',
   'ob.analytics.google.account.mobile.3': 'pour apprendre à en créer un.',
   'ob.analytics.google.account.mobile.4': 'en créer un.',
   'ob.analytics.facebook.account.mobile.1': "Vous n'avez pas encore de page",
   'ob.analytics.facebook.account.mobile.2': '? Cliquez sur',
   'ob.analytics.facebook.account.mobile.3': 'pour savoir comment',
   'ob.analytics.facebook.account.mobile.4': 'en créer une.',
   'ob.analytics.successfullySaved.message': 'Sauvegardé avec succès',
   'ob.settings.booking.title': 'Disponibilité de réservation en ligne',
   'ob.settings.booking.description':
      'Définissez l’heure à laquelle les rendez-vous les plus anciens et les plus récents peuvent être programmés. Gérez les intervalles de temps des rendez-vous et fournissez des informations importantes aux clients, ainsi que la possibilité de choisir un membre du personnel pour leur service.',
   'ob.settings.cancellation.title': 'Annulation et report',
   'ob.settings.cancellation.description':
      'Définissez le délai pendant lequel les clients peuvent annuler ou reprogrammer leur rendez-vous.',
   'ob.settings.locationsUrl.title': 'URL des emplacements',
   'ob.settings.locationsUrl.description':
      'Configurez et partagez une URL unique pour tous les emplacements réservables en ligne. Ceci n’est utile que pour les entreprises possédant plusieurs sites.',
   'ob.settings.businessName.explanation': 'Votre URL : book.plandok.com/business-name/Location2556',
   'ob.settings.notifications.title': 'E-mails de notification',
   'ob.settings.notifications.description':
      'Spécifiez le destinataire des e-mails de notification concernant les nouveaux rendez-vous ou les modifications qui leur sont apportées.',
   'ob.settings.required.title': 'Champs obligatoires et facultatifs',
   'ob.settings.required.description':
      'Sélectionnez les champs que le client devra fournir lors de la réservation en ligne.',
   'ob.settings.requiredField.title': 'Requis',
   'ob.settings.optionalField.title': 'Facultatif',
   'ob.settings.requiredField.notification.title': 'Il y a eu un problème avec les paramètres',
   'ob.settings.requiredField.notification.description':
      'Désolé, mais un champ doit être obligatoire, sauf "remarques".',
   'ob.settings.nameField.title': 'Prénom',
   'ob.settings.lastNameField.title': 'Nom de famille',
   'ob.settings.phoneField.title': 'Numéro de téléphone',
   'ob.settings.emailField.title': 'E-mail',
   'ob.settings.notes.label': 'Texte de remplacement pour les notes',
   'ob.settings.notes.placeholder': 'Ex : Saisissez vos notes',
   'ob.bookingLimit.title': 'Limite de réservation en ligne',
   'ob.bookingLimit.description':
      'Veuillez indiquer le nombre maximum de réservations de services que vous souhaitez fixer.',
   'ob.bookingLimit.label': 'Limitation des réservations de services',
   'ob.bookingLimit.unlimited.title': 'Illimité',
   'ob.bookingLimit.1service.title': 'max. 1 Service',
   'ob.bookingLimit.2services.title': 'max. 2 Services',
   'ob.bookingLimit.3services.title': 'max. 3 Services',
   'ob.menu.edit.profile': 'Modifier le profil',
   'ob.menu.turnOff.profile': 'Désactiver le profil',
   'ob.profile.status': 'Statut',
   'ob.overview.tab.description':
      'Développez votre entreprise en permettant à vos clients de prendre rendez-vous en ligne.',
   'ob.profile.tab.description':
      'Gérez les profils en ligne pour vos emplacements. Ajustez les images, les descriptions, les adresses, etc.',
   'ob.links.tab.description':
      'Vous trouverez ici un lien de réservation en ligne, que vous pourrez partager avec vos clients.',
   'ob.settings.tab.description':
      'Ajustez ici les paramètres de réservation en ligne, afin que vos clients puissent réserver uniquement lorsque cela vous convient.',
   'ob.payments.tab.description':
      'Sélectionnez les modes de paiement que vos clients pourront utiliser pour payer leurs services.',
   'ob.payments.page.description':
      'Vous pouvez sélectionner ici les modes de paiement que vos clients pourront utiliser pour payer leurs services.',
   'ob.payOnSite.title': 'Payer sur place',
   'ob.payOnSite.description': 'Vos clients pourront payer sur place en espèces.',
   'ob.payWithCard.title': 'Payer par carte',
   'ob.payWithCard.description': "Vos clients pourront payer par carte de crédit via l'application Stripe.",
   'ob.payWithCard.info.1': 'Vous utilisez le plan gratuit Plandok, vous devrez donc payer',
   'ob.payWithCard.info.2':
      'les frais de commission Plandok et les frais de commission Stripe en utilisant cette méthode de paiement.',
   'ob.payWithCard.info.3': ' pour avoir',
   'ob.payWithCard.info.4': 'frais de commission Plandok.',
   'ob.payWithCard.info.5': 'Le plan Premium ne dispense pas de payer les frais de commission Stripe. ',
   'ob.payWithCard.info.6': 'Vérifiez les prix de Stripe ici',
   'ob.stripeConnected.info': '(Stripe connecté)',
   'ob.requiresSetup.info': "(Nécessite la création d'un compte Stripe)",
   'ob.connectToStripe.btn': 'Se connecter à Stripe',
   'ob.connectToStripe.successMessage.title': 'Connexion réussie à Stripe',
   'ob.waitingAuthorization.title': 'En attente de votre autorisation dans Stripe',
   'ob.stripeDashboard.title': 'Tous les journaux de paiement et les remboursements sont disponibles dans le ',
   'ob.stripeDashboard.url': 'Tableau de bord Stripe',
   'ob.yourStripeAccount.title': 'Votre compte Stripe',
   'ob.visitStripe.option': 'Visiter Stripe',
   'ob.changeAccount.option': 'Changer de compte Stripe',
   'input.earliestAppointment.label': 'Rendez-vous au plus tôt',
   'input.earliestAppointment.placeholder': 'Juste avant le rendez-vous',
   'input.latestAppointment.label': 'Dernier rendez-vous',
   'input.latestAppointment.placeholder': "Pas plus de 6 mois à l'avance",
   'input.timeSlots.label': 'Intervalles de plages horaires',
   'input.timeSlots.placeholder': '15 minutes',
   'checkbox.allowCustomers': 'Permettre aux clients de choisir les membres du personnel pour leur rendez-vous',
   'input.importantInfo.label': 'Informations importantes',
   'input.importantInfo.placeholder':
      'Informations qui seront présentées au client lors de la confirmation de son rendez-vous',
   'input.changeAppointment.label': 'Des modifications de rendez-vous peuvent être apportées',
   'input.changeAppointment.placeholder': 'À tout moment',
   'checkbox.sendToBooked': 'Envoyer au membre du personnel réservé',
   'checkbox.sendSpecificEmail': 'Envoyer à une adresse e-mail spécifique',
   'input.specificEmail.label': 'Adresse email spécifique',
   'input.specificEmail.placeholder': "Entrez l'adresse e-mail",
   'input.locationsUrl.label': 'URL des emplacements',
   'input.locationsUrl.placeholder': 'Par ex. Johns-Barber-Shop',
   'ob.btn.save': 'Sauvegarder',
   'ob.btn.cancel': 'Annuler',
   'ob.btn.showLink': 'Montrer le lien',
   'ob.btn.active': 'Actif',
   'ob.btn.inactive': 'Inactif',
   'ob.linkModal.title': 'Lien direct pour les réservations',
   'ob.linkModal.description': 'Voici le lien où vos services de réservation en ligne sont directement accessibles !',
   'ob.linkModal.afterDescription':
      "Ce lien dirige les utilisateurs directement vers vos services. En un seul clic, les clients peuvent accéder à vos profils professionnels et prendre rendez-vous.\nCe lien est facile à utiliser et convivial pour tous les appareils, qu'il s'agisse d'un ordinateur, d'un téléphone ou d'une tablette.",
   'ob.fbLinkModal.title': 'Page Facebook Réservez maintenant',
   'ob.fbLinkModal.description':
      'Configurez un bouton Réserver maintenant intégré pour votre page professionnelle Facebook afin de recevoir sans effort de nouvelles réservations directement depuis Facebook.\n',
   'ob.fbLinkModal.step1': 'Connectez-vous à la page de votre entreprise sur Facebook',
   'ob.fbLinkModal.step2': 'Cliquez sur "Ajouter un bouton" situé à côté de la photo de couverture',
   'ob.fbLinkModal.step3': "Sélectionnez l'option « S'inscrire » et entrez le lien de réservation ci-dessous",
   'ob.linkModalCopySuccess.title': 'Lien copié !',
   'ob.btn.copyLink': 'Copier le lien',
   'title.appointmentSlot.select': "Sélectionnez l'heure",
   'btn.back': 'Dos',
   'btn.continue': 'Continuer',
   'ob.hours.open': 'Ouvrir',
   'ob.hours.close': 'Fermé',
   'ob.hours.step.title': 'Heures de travail',
   'ob.hours.step.description': 'Informez vos clients de l’ouverture et de la fermeture de votre entreprise.',
   'ob.staffHours.step.title': 'Horaires de travail du personnel',
   'ob.staffHours.step.description':
      'Étape importante, veuillez ajouter les heures de travail pour que le système sache quand les clients peuvent réserver en ligne.',
   'ob.location.step.title': 'Informations de localisation',
   'ob.photo.step.title': 'Photos',
   'ob.photo.step.description': 'Faites savoir à vos clients à quoi ressemble votre entreprise.',
   'input.file.dnd.photos': 'Glissez et déposez des photos',
   'btn.publish': 'Allumer',
   'text.next': 'Suivant',
   'input.phone.number.label': 'Numéro de téléphone',
   'input.state.label': 'État',
   'input.state.placeholder': 'Entrez un état (municipalité)',
   'input.addressDetails.label': "Détails de l'adresse",
   'input.addressDetails.placeholder':
      "Saisissez les détails de l'adresse tels que le code de la porte, les autres entrées, les informations de stationnement, etc.",
   'placeholder.no.photos': 'Aucune photo',
   'btn.options': 'Possibilités',
   'btn.list.profile': 'Allumer',
   'btn.preview': 'Aperçu',
   'btn.next': 'Suivant',
   'market.ob.pleaseWait.title': 'Veuillez patienter ...',
   'market.ob.location.step.title': 'Sélectionnez un emplacement',
   'market.ob.services.step.title': 'Sélectionnez les prestations',
   'market.ob.employee.step.title': 'Sélectionner la date et le personnel',
   'market.ob.timeSlot.step.title': "Sélectionner la date et l'heure",
   'market.ob.personalInfo.step.title': 'Paiement',
   'market.ob.appointmentInfo.step.title': 'Rendez-vous',
   'market.ob.informationAboutYou.title': 'Informations sur vous',
   'market.ob.paymentMethod.title': 'Mode de paiement',
   'market.ob.securePayment.title': 'Paiement sécurisé',
   'market.ob.payWithCard.title': 'Payer par carte',
   'market.ob.payOnSite.title': 'Payer sur place',
   'market.ob.payOnSite.firstDescription': 'Vous devrez payer dans le salon après la procédure.',
   'market.ob.payOnSite.secondDescription':
      "Une petite demande de nous: les entreprises sont souvent de petites entreprises indépendantes, donc si vous ne pouvez pas vous rendre à votre rendez-vous prévu, reprogrammer ou l'annuler.",
   'market.ob.validThru.title': 'Date de validité (mois/année)',
   'market.ob.information.title': 'Information',
   'market.ob.additionalInformation.title': 'Informations complémentaires',
   'market.ob.reschedulingPolicy.title': "Politique d'annulation et de reprogrammation",
   'market.ob.reschedulingPolicy.description':
      'Les changements de rendez-vous peuvent être effectués {0} avant le rendez-vous.',
   'market.ob.fieldsAreRequired.title': '* Les champs sont obligatoires',
   'market.ob.step.title': 'Étape {0} sur 3',
   'market.ob.confirm.step.title': 'Consulter et confirmer',
   'market.ob.noServices.placeholder': "Aucun service sélectionné pour l'instant",
   'market.ob.input.firstName.label': '{0} Prénom',
   'market.ob.input.firstName.placeholder': 'e.g. John',
   'market.ob.input.lastName.label': '{0} Nom de famille',
   'market.ob.input.lastName.placeholder': 'e.g. Biche',
   'market.ob.input.phone.label': '{0} Numéro de téléphone portable',
   'market.ob.input.phone.placeholder': '670 70 822',
   'market.ob.input.email.label': '{0} Adresse électronique',
   'market.ob.input.email.placeholder': 'john.biche@gmail.com',
   'market.ob.input.description.label': 'Notes',
   'market.ob.staff.assignedSpecialist.label': 'avec le personnel',
   'market.ob.staff.noPreference.label': 'Aucune préférence',
   'market.ob.timeSlots.fullyBooked.title': "Désolé, nous n'avons pas d'horaires disponibles pour ce jour.",
   'market.ob.timeSlots.choseAnotherDay.title': 'Veuillez choisir un autre jour.',
   'market.ob.timeSlots.closedSlot.title': "L'heure disponible la plus proche est ",
   'market.ob.timeSlots.selectClosest.title': 'Sélectionnez la date la plus proche',
   'market.ob.timeSlots.note.title': 'Informations importantes',
   'market.ob.service.duration.title': 'min',
   'market.location.workingHours.title': 'Heures de travail',
   'market.location.monday.title': 'Lundi',
   'market.location.tuesday.title': 'Mardi',
   'market.location.wednesday.title': 'Mercredi',
   'market.location.thursday.title': 'Jeudi',
   'market.location.friday.title': 'Vendredi',
   'market.location.saturday.title': 'Samedi',
   'market.location.sunday.title': 'Dimanche',
   'market.location.meetOurTeam.title': 'Rencontrez notre équipe',
   'market.location.title': 'Prenez contact avec nous',
   'market.location.services.title': 'Services',
   'market.location.about.title': 'À propos de',
   'market.location.aboutUs.title': 'A propos de nous',
   'market.confirmation.appointmentCanceled.title': 'Ce rendez-vous a été annulé',
   'market.confirmation.appointmentConfirmed.title': 'Rendez-vous confirmé',
   'market.confirmation.appointmentConfirmed.description': 'Votre rendez-vous est confirmé, voici les détails :',
   'market.confirmation.appointmentDate.title': 'Date du rendez-vous',
   'market.confirmation.scheduledAppointment.title': "L'annulation n'est pas possible en ligne.",
   'market.confirmation.scheduledAppointment.description':
      "Si vous ne pouvez pas vous rendre à votre rendez-vous, veuillez le reporter ou l'annuler.",
   'market.modal.cancelAppointment.title': 'Êtes-vous sûr de vouloir annuler ce rendez-vous ?',
   'market.modal.cancelledAppointment.title': 'Le rendez-vous a été annulé',
   'market.language.changer.title': 'Langue (Language)',
   'market.btn.bookNow': 'Réserver',
   'market.servicesAvailable.title': 'services',
   'market.poweredByPlandok.title': 'Plandok',
   'market.poweredByPlandok.description': 'Vous voulez un site similaire ? Visitez',
   'market.btn.readMore': 'Lire la suite',
   'market.btn.showLess': 'Montrer moins',
   'market.btn.reviews': 'Critiques',
   'market.btn.viewMore': 'Voir plus',
   'market.btn.cancelAppointment': 'Annuler le rendez-vous',
   'market.btn.cancel': 'Oui, annuler',
   'market.wh.closed.title': 'Fermé',
   'market.ob.success.message': 'Fait! La réservation a été créée avec succès',
   'market.ob.cancelAppointment.success.message': 'Rendez-vous annulé avec succès',
   'market.ob.cancelAppointment.error.message': "L'annulation du rendez-vous a échoué",
   'market.ob.cancelAppointment.confirm.title': 'Êtes-vous sûr de vouloir annuler le rendez-vous?',
   'market.location.getDirections.title': 'Obtenir un itinéraire',
   'market.notFound.title': 'Page non trouvée',
   'market.notFound.btn.goBack': 'Retourner',
   'market.ob.quantitativeLimit.title': 'Limite quantitative',
   'market.ob.quantitativeLimit.description':
      'Désolé, le propriétaire a imposé une limite au nombre de services de réservation, ne permettant de sélectionner que {0} servic{0}',
   'market.ob.quantitativeLimit.three': 'trois',
   'market.ob.quantitativeLimit.two': 'deux',
   'market.ob.quantitativeLimit.one': 'un',
   'market.ob.quantitativeLimit.serviceSingular': 'e',
   'market.ob.quantitativeLimit.servicesPlural': 'es',
   'validation.characters.minLength': 'Cette valeur est trop courte. Il doit contenir 3 caractères ou plus.',
   'not.found.btn.label': 'Dos',
   'not.found.subTitle.label': "Désolé, la page que vous avez visitée n'existe pas.",
   'analytics.title': 'Analytique',
   'analytics.dashboard.tab': 'Tableau de bord',
   'analytics.reports.tab': 'Rapports',
   'analytics.premium.title': 'Prime',
   'analytics.totalAppointments.title': 'Total des rendez-vous',
   'analytics.cancellationReasons.title': "Principales raisons d'annulation",
   'analytics.occupancy.title': 'Occupation',
   'analytics.topServices.title': 'Meilleurs services',
   'analytics.clients.title': 'Clientèle',
   'analytics.completed.title': 'Complété',
   'analytics.notCompleted.title': 'Non terminé',
   'analytics.canceled.title': 'Annulé',
   'analytics.noShowAppointments.title': 'No show rendez-vous',
   'analytics.reasonName.title': 'Nom de la raison',
   'analytics.reasonCount.title': 'Compter',
   'analytics.lastPeriod.title': 'dernière période',
   'analytics.workingHours.title': 'Heures de travail',
   'analytics.bookedHours.title': 'Heures réservées',
   'analytics.blockedHours.title': 'Heures bloquées',
   'analytics.unbookedHours.title': 'Heures non réservées',
   'analytics.service.title': 'Service',
   'analytics.thisMonth.title': 'Cette période',
   'analytics.lastMonth.title': 'Dernière période',
   'analytics.totalClients.title': 'Nombre total de clients',
   'analytics.averageVisits.title': 'Visites moyennes par client ',
   'analytics.newClients.title': 'Nouveaux clients',
   'analytics.returningClients.title': 'Clients fidèles',
   'analytics.returnRate.title': 'Taux de retour des clients',
   'analytics.clientsByGender.title': 'Clients par sexe',
   'analytics.clientsBySource.title': 'Clients par source de référence',
   'analytics.clientsByAge.title': 'Clients par âge',
   'analytics.timeRange.custom': 'Coutume',
   'analytics.timeRange.today': "Aujourd'hui",
   'analytics.timeRange.yesterday': 'Hier',
   'analytics.timeRange.last1day': 'Dernier 1 jour',
   'analytics.timeRange.last7days': 'Les 7 derniers jours',
   'analytics.timeRange.last30days': 'Les 30 derniers jours',
   'analytics.timeRange.last90days': '90 derniers jours',
   'analytics.timeRange.last3months': '3 derniers mois',
   'analytics.timeRange.lastMonth': 'Le mois dernier',
   'analytics.timeRange.last6Months': '6 derniers mois',
   'analytics.timeRange.lastYear': "L'année dernière",
   'analytics.timeRange.last2year': '2 ans au dernier',
   'analytics.timeRange.thisWeek': 'Cette semaine',
   'analytics.timeRange.thisMonth': 'Ce mois-ci',
   'analytics.timeRange.thisYear': 'Cette année',
   'analytics.timeRange.tomorrow': 'Demain',
   'analytics.timeRange.next7days': '7 jours suivants',
   'analytics.timeRange.next30days': '30 jours suivants',
   'analytics.menu.dashboard.description': 'Explorez vos données commerciales dans les graphiques et les statistiques',
   'analytics.menu.reports.description': 'Explorez vos données commerciales dans les rapports',
   'analytics.timeRange.allTime': 'Tout le temps',
   'analytics.timeRange.label': 'Plage de temps',
   'analytics.services.lastPeriod.label': 'Dernière période',
   'analytics.totalAppointments.appointment.label': 'Rendez-vous',
   'analytics.totalAppointments.onlineBook.label': 'Réservations en ligne',
   'analytics.gender.male': 'Homme',
   'analytics.gender.female': 'Femelle',
   'analytics.gender.unknown': 'Inconnu',
   'analytics.noData': "Il n'y a pas de données dans la période sélectionnée",
   'analytics.premium.info.title': "Mise à niveau vers la version premium pour voir l'analyse pendant plus de 7 jours.",
   'analytics.premium.upgradeButton.title': 'ACTIVER',
   'analytics.premium.skipButton.title': 'Sauter',
   'reports.appointments.title': 'Rendez-vous',
   'reports.appointments.description':
      'Consultez les revenus projetés des rendez-vous à venir, suivez les taux et les raisons d’annulation.',
   'reports.appointmentsList.title': 'Liste de rendez-vous',
   'reports.appointmentsList.description': 'Affiche tous les rendez-vous avec les informations associées.',
   'reports.appointmentsSummary.title': 'Récapitulatif des rendez-vous',
   'reports.appointmentsSummary.description': 'Affiche les rendez-vous par personnel et par service.',
   'reports.appointmentsCancellations.title': 'Annulations de rendez-vous',
   'reports.appointmentCancellations.title': 'Annulations de rendez-vous',
   'reports.appointmentsCancellations.description':
      'Affiche toutes les raisons et la quantité de rendez-vous annulés pour la période sélectionnée.',
   'reports.summary.title': 'Résumé',
   'reports.clients.title': 'Clientèle',
   'reports.clients.description':
      'Obtenez des informations sur la façon dont les clients interagissent avec votre entreprise et qui sont vos principaux dépensiers.',
   'reports.clientsList.title': 'Clientèle',
   'reports.clientsList.description': 'Liste des clients avec leurs données',
   'reports.exportCsv.title': 'Exporter au format CSV',
   'reports.appointmentsByStaff.title': 'Nominations par le personnel',
   'reports.appointmentsByService.title': 'Rendez-vous par service',
   'reports.clearFilters.button.title': 'Effacer les filtres',
   'reports.filterStaff.placeholder': 'Tout le personnel',
   'reports.filterLocations.placeholder': 'Tous les emplacements',
   'reports.filters.title': 'Filtres',
   'reports.locations.mobile.title': 'Emplacements :',
   'reports.staff.mobile.title': 'Personnel:',
   'reports.filters.apply.title': 'Appliquer',
   'reports.search.placeholder': 'Recherche par référence ou client',
   'reports.subscription.description': 'Plan {0} (personnel {0} uniquement)',
   'reports.notifications.description': '{0} messages ({0})',
   'serviceGroup.addCategory.description':
      'Les catégories sont essentielles, car elles permettent de regrouper des services similaires.',
   'serviceGroup.addCategory.title': 'Ajouter une catégorie',
   'app.install.title': 'Installer Plandok',
   'app.install.description.title':
      "L'installation n'utilise presque aucun espace de stockage et fournit un moyen rapide de revenir à cette application",
   'app.install.button.title': 'Installer',
   'app.skip.button.title': 'Sauter',
   'app.actionUndone.button.title': 'Action annulée',
   'app.appointmentCancelled.button.title': 'Rendez-vous supprimé',
   'app.undo.button.title': 'Annuler',
   'app.showAllTimes.button.title': 'Afficher toutes les heures',
   'app.install.success.title': 'Application installée avec succès !',
   'rp.upgradeToPremiumLink.title': 'Passer à Premium',
   'rp.upgradeToPremium.title': 'pour utiliser cette fonctionnalité',
   'rp.repeatAppointment.title': 'Répéter',
   'rp.input.frequency.title': 'Fréquence',
   'rp.input.endsAfter.title': 'Fin',
   'rp.input.selectDate.title': 'Sélectionnez une date',
   'rp.upgrade.title': 'Améliorez votre forfait',
   'rp.upgrade.description.title':
      "La version premium de Plandok n'est pas activée. Mettez à niveau votre forfait pour accéder pleinement à toutes les fonctionnalités fournies par Plandok.",
   'rp.upgrade.button.title': 'Passer à la version premium',
   'rp.repeating.title': 'Répéter',
   'rp.repeats.title': 'Répétitions',
   'rp.repeatsUntil.title': "jusqu'à",
   'rp.repeatingTimes.title': '({0} fois)',
   'rp.cancel.upcoming.title': 'Supprimer tous les rendez-vous répétés à venir',
   'rp.update.upcoming.title': 'Mise à jour de tous les rendez-vous répétés à venir',
   'rp.edit.appointment.title': 'Mettre à jour le rendez-vous',
   'rp.edit.appointment.description.title':
      'Vous modifiez un rendez-vous répétitif. Sélectionnez les rendez-vous qui doivent être mis à jour :',
   'rp.edit.thisAppointment.title': 'Mettre à jour ce rendez-vous uniquement',
   'rp.edit.allAppointments.title': 'Mettre à jour tous les rendez-vous à venir',
   'rp.edit.saveAppointment.title': 'Guardar',
   'modal.cancelSubscriptionError.title': "Erreur d'annulation de l'abonnement",
   'modal.cancelSubscriptionError.text.1': 'Le plan gratuit est uniquement disponible avec un maximum de 5',
   'modal.cancelSubscriptionError.text.2': 'employés',
   'modal.cancelSubscriptionError.text.3': 'et 1',
   'modal.cancelSubscriptionError.text.4':
      'Pour utiliser la version gratuite, supprimez des emplacements / employés pour répondre aux exigences.',
   'modal.latestPaymentFailed.title': 'Dernier paiement échoué',
   'modal.latestPaymentFailed.text.1':
      'Pour avoir accès à toutes les fonctionnalités, veuillez payer ou utiliser la version gratuite remove',
   'modal.latestPaymentFailed.text.2':
      "pour répondre aux exigences. Le plan gratuit n'est disponible qu'avec un maximum de 5 employés et 1 site.",
   'modal.extraCostsForNewMember.title': 'Coûts supplémentaires pour les nouveaux membres',
   'modal.extraCostsForNewMember.description':
      "L'augmentation du nombre de membres du personnel entraîne des coûts supplémentaires pour votre abonnement mensuel.",
   'modal.premiumUpgradeRequired.title': 'Mise à niveau Premium requise',
   'modal.premiumUpgradeRequired.description':
      'Pour ajouter {0} supplémentaire, votre abonnement doit être mis à niveau vers la formule Premium',
   'modal.premiumUpgradeRequired.location': 'un site',
   'modal.premiumUpgradeRequired.staffMembers': 'des membres du personnel',
   'modal.changeAccount.title': 'Changement de compte Stripe ?',
   'modal.changeAccount.description':
      'Vous ne pourrez pas accepter de paiements pendant que vous changez de compte. Le changement prendra environ une minute.',
   'btn.agree': 'Accepter',
   'btn.decline': 'Refuser',
   'paymentMethod.paymentMethod.title': 'Mode de paiement',
   'paymentMethod.currentPaymentMethod.title': 'Mode de paiement actuel',
   'paymentMethod.expirationDate': "Date d'expiration {0}",
   'paymentMethod.otherPaymentMethods.title': 'Autres modes de paiement',
   'paymentMethod.addAnotherPaymentMethod.btn': 'Ajouter un autre mode de paiement',
   'paymentMethod.addPaymentMethod.title': 'Ajouter un mode de paiement',
   'paymentMethod.noPaymentMethodAdded.title': "Aucun mode de paiement n'a été ajouté",
   'paymentMethod.noPaymentMethodAdded.description':
      'Le mode de paiement sera ajouté lors de la sélection du plan Premium',
   'paymentMethod.goToPlanPage.btn': 'Aller à la page du plan',
   'paymentMethod.add.btn': 'Ajouter',
   'paymentMethod.makeDefault.btn': 'Rendre la carte par défaut',
   'paymentMethod.cardHasBeenAdded.message': 'La carte a été ajoutée',
   'paymentMethod.cardHasBeenAssign.message': 'La carte a été attribuée',
   'page.marketing.overview.messages.description':
      'Envoyez des messages marketing personnalisés à vos clients par SMS ou par e-mail en quelques clics',
   'page.marketing.overview.btn.sendMessage': 'Envoyer le message',
   'page.marketing.overview.campaigns.title': "Campagnes d'envoi automatique",
   'page.marketing.overview.campaigns.description':
      "Créez des campagnes personnalisées en fonction d'événements tels que les anniversaires des clients, etc. \nEnvoyez à travers plusieurs canaux pour diffuser des campagnes par email et par SMS",
   'page.marketing.overview.btn.newCampaign': 'Nouvelle campagne',
   'page.marketing.massMessages.title': 'Créer un nouveau message',
   'page.marketing.massMessages.messageLog': 'Journal des messages',
   'page.marketing.massMessages.sendOn': 'Envoyer sur',
   'page.marketing.massMessages.sendTo': 'Envoyer à',
   'page.marketing.massMessages.channel': 'Canal',
   'page.marketing.campaigns.description':
      'Envoyez des messages marketing automatisés à vos clients par SMS ou par e-mail en quelques clics',
   'page.marketing.campaigns.birthdaySpecial.title': 'Spécial Anniversaire',
   'page.marketing.campaigns.winBackClients.title': 'Récupérer des clients',
   'page.marketing.campaigns.welcomeNewClients.title': 'Bienvenue aux nouveaux clients',
   'page.marketing.campaigns.rewardRegularClients.title': 'Récompenser les clients réguliers',
   'page.marketing.campaigns.birthdaySpecial.description':
      "Surprenez vos clients le jour de leur anniversaire, cette campagne est envoyée automatiquement aux clients dont l'anniversaire est proche",
   'page.marketing.campaigns.winBackClients.description':
      'Cibler les clients désengagés pour les faire revenir avec une offre spéciale. Cette campagne est envoyée aux clients qui ne sont pas revenus après une certaine période de temps',
   'page.marketing.campaigns.welcomeNewClients.description':
      'Transformez les nouveaux venus en habitués en encourageant les clients qui ont réservé pour la première fois à revenir grâce à une réduction spéciale. Cette campagne est envoyée automatiquement aux clients le lendemain de leur toute première vente',
   'page.marketing.campaigns.rewardRegularClients.description':
      "Surprenez vos meilleurs clients avec une offre spéciale, un moyen sûr d'obtenir un engagement encore plus fort de la part de vos meilleurs clients. Cette campagne est envoyée automatiquement aux clients en fonction de leur activité récente en matière de rendez-vous",
   'page.marketing.campaigns.btn.active': 'Actif',
   'page.marketing.campaigns.btn.inactive': 'Inactif',
   'page.marketing.campaigns.btn.startCampaign': 'Lancer la campagne',
   'page.marketing.campaigns.btn.editCampaign': 'Modifier la campagne',
   'page.marketing.campaigns.btn.resetCampaign': 'Réinitialiser la campagne',
   'modal.resetCampaign.description': 'Êtes-vous sûr de vouloir réinitialiser cette campagne ?',
   'modal.resetCampaign.btn.yesReset': 'Oui, réinitialiser',
   'page.marketing.smsCreate.step.1': 'Configuration du SMS. Étape 1 sur 4',
   'page.marketing.smsCreate.step.2': 'Configuration du SMS. Étape 2 sur 4',
   'page.marketing.smsCreate.step.3': 'Configuration des SMS. Étape 3 sur 4',
   'page.marketing.smsCreate.step.4': 'Configuration des SMS. Étape 4 sur 4',
   'page.marketing.emailCreate.step.1': "Configuration de l'e-mail. Étape 1 sur 4",
   'page.marketing.emailCreate.step.2': "Configuration de l'e-mail. Étape 2 sur 4",
   'page.marketing.emailCreate.step.3': "Configuration de l'e-mail. Étape 3 sur 4",
   'page.marketing.emailCreate.step.4': "Configuration de l'e-mail. Étape 4 sur 4",
   'page.marketing.campaignCreate.step.1': 'Configuration de la campagne. Étape 1 sur 3',
   'page.marketing.campaignCreate.step.2': 'Configuration de la campagne. Étape 2 sur 3',
   'page.marketing.campaignCreate.step.3': 'Configuration de la campagne. Étape 3 sur 3',
   'page.marketing.smsCreate.step.1.title': "Message et ID de l'expéditeur",
   'page.marketing.smsCreate.step.1.defaultTitleForSms': "Entrez l'ID de l'expéditeur du SMS",
   'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Entrez votre message',
   'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Entrez le titre du message',
   'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Entrez votre message',
   'page.marketing.emailCreate.step.1.title': "Paramètres de l'e-mail",
   'page.marketing.emailCreate.step.1.messageTitle': 'Titre du message',
   'page.marketing.emailCreate.step.1.replyToEmail': "Réponse à l'e-mail ",
   'page.marketing.emailCreate.step.1.clientReplies': 'Les réponses des clients seront envoyées à cet e-mail',
   'page.marketing.emailCreate.step.1.photo.title': 'Photo',
   'page.marketing.emailCreate.step.1.enablePhoto': 'Activer la photo',
   'page.marketing.emailCreate.step.1.changePhoto': 'Modifier la photo',
   'page.marketing.emailCreate.step.1.button.title': 'Bouton',
   'page.marketing.emailCreate.step.1.enableButton': 'Activer le bouton',
   'page.marketing.emailCreate.step.1.buttonName': 'Nom du bouton',
   'page.marketing.emailCreate.step.1.buttonURL': 'URL du bouton',
   'page.marketing.emailCreate.step.1.socialMedia.title': 'Médias sociaux',
   'page.marketing.emailCreate.step.1.enableSocialMedia': 'Activer les liens vers les médias sociaux',
   'page.marketing.emailCreate.step.1.facebookPage': 'Page Facebook',
   'page.marketing.emailCreate.step.1.instagramPage': 'Page Instagram',
   'page.marketing.emailCreate.step.1.yourPage': 'Votre page',
   'page.marketing.smsCreate.yourMessage': 'Votre message',
   'page.marketing.btn.sendMeTestMessage': 'Envoyez-moi un message de test',
   'page.marketing.smsCreate.step.1.freeTests': "Il reste {0} tests gratuits pour aujourd'hui",
   'page.marketing.smsCreate.messagePreview': 'Aperçu du message',
   'page.marketing.smsCreate.btn.clientSelection': 'Sélection du client',
   'page.marketing.audience.title': 'Audience',
   'page.marketing.smsCreate.step.2.description': 'Choisissez les clients qui recevront votre message',
   'page.marketing.smsCreate.step.2.allClient.title': 'Tous les clients',
   'page.marketing.smsCreate.step.2.allClient.description': 'Envoyer le message à tous vos clients',
   'page.marketing.smsCreate.step.2.clientGroups.title': 'Groupes de clients',
   'page.marketing.smsCreate.step.2.clientGroups.description':
      'Envoyer le message à des groupes de clients sélectionnés',
   'page.marketing.smsCreate.step.2.totalClients': 'Total des clients',
   'page.marketing.smsCreate.step.2.totalPrice': 'Prix total',
   'page.marketing.smsCreate.step.3.description':
      "Choisissez l'heure à laquelle vous souhaitez que votre message soit envoyé",
   'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Sélectionnez une date et une heure personnalisées',
   'page.marketing.smsCreate.step.4.title': 'Paiement du message de masse',
   'page.marketing.smsCreate.step.4.description': 'Saisissez les détails de votre carte de crédit et payez',
   'page.marketing.smsCreate.step.4.paymentAmount': 'Montant du paiement:',
   'btn.scheduleSend': "Programmer l'envoi",
   'btn.previous': 'Précédent',
   'btn.payment': 'Paiement',
   'btn.payAndFinish': 'Payer et terminer',
   'btn.backToDashboard': 'Retour au tableau de bord',
   'btn.saveAndClose': 'Guardar',
   'modal.editClientSelection.title': 'Modifier la sélection des clients',
   'modal.newClient.description': 'Clients ajoutés dans les derniers:',
   'modal.recentClient.title': 'Client récent',
   'modal.recentClient.description': 'Clients ayant effectué une visite en dernier:',
   'modal.clientByAge.title': 'Clients par âge',
   'modal.clientsByGender.title': 'Clients par sexe',
   'modal.paymentConfirmation.title': 'Paiement réussi !',
   'modal.paymentConfirmation.messageSend': 'Votre message va être envoyé',
   'modal.previewMessage.title': 'Message de prévisualisation',
   'modal.previewSms.tab': 'Aperçu du SMS',
   'modal.previewEmail.tab': "Aperçu de l'email",
   'modal.editServiceSelection.title': 'Modifier la sélection des services',
   'modal.editServiceSelection.allServices': 'Tous les services',
   'modal.campaignActivated.title': 'Campagne activée !',
   'modal.campaignActivated.description': 'Vous pouvez interrompre ou modifier cette campagne à tout moment',
   'btn.all': 'Tous {0}',
   'btn.included': 'Inclus {0}',
   'btn.excluded': 'Exclus {0}',
   'checkbox.allClients': 'Tous les clients {0}',
   'scheduleSend.time': 'Heure',
   'scheduleSend.timeSetTo': 'Heure fixée à:',
   'scheduleSend.setTime': "Définir l'heure",
   'campaignSetup.step.1.header.title': 'Paramètres de la campagne',
   'campaignSetup.step.2.header.title': 'Modifier le message',
   'campaignSetup.step.3.header.title': 'Réviser',
   'campaignSetup.channel.description': 'Choisissez les canaux sur lesquels la campagne sera envoyée',
   'campaignSetup.smsSetup.tab': 'Configuration des SMS',
   'campaignSetup.emailSetup.tab': "Configuration de l'email",
   'campaignSetup.almostDone.title': "C'est presque terminé !",
   'campaignSetup.almostDone.description':
      'Votre campagne intelligente est prête à être lancée. Une fois activée, elle enverra automatiquement des messages aux clients sur une base continue. Vous pouvez facilement modifier ou interrompre cette campagne à tout moment',
   'campaignSetup.approximatePrice.header': 'Prix approximatif',
   'campaignSetup.approximatePrice.description': 'Pour les 30 prochains jours',
   'campaignSetup.approximatePrice.approx': 'Environ',
   'campaign.birthdaySpecial.description':
      "Cette campagne envoie automatiquement des messages aux clients à l'approche de leur anniversaire",
   'campaign.birthdaySpecial.sendToClient': 'Envoyer au client:',
   'campaign.birthdaySpecial.selectOption.1': "Le jour de l'anniversaire",
   'campaign.birthdaySpecial.selectOption.2': "Trois jours avant l'anniversaire",
   'campaign.birthdaySpecial.selectOption.3': "Une semaine avant l'anniversaire",
   'campaign.birthdaySpecial.selectOption.4': "Deux semaines avant l'anniversaire",
   'campaign.winBackClients.description':
      'Cette campagne est envoyée aux clients qui ne sont pas revenus après une certaine période',
   'campaign.winBackClients.clientsWhoDidntReturn': 'Clients qui ne sont pas revenus au cours de la dernière période',
   'campaign.winBackClients.appliedTo': 'Appliqué à',
   'campaign.winBackClients.all': ' tous les ',
   'campaign.winBackClients.services': 'services',
   'campaign.winBackClients.editSelection': 'Modifier la sélection',
   'campaign.welcomeNewClients.description':
      'Transformez les nouveaux venus en habitués en encourageant les clients de la première heure à réserver à nouveau grâce à une remise spéciale',
   'campaign.welcomeNewClients.smartCampaignNewClients':
      'Cette campagne intelligente est envoyée automatiquement aux nouveaux clients le lendemain de leur première vente',
   'campaign.rewardRegularClients.description':
      'Cette campagne est envoyée automatiquement aux clients en fonction de leurs récents rendez-vous',
   'campaign.rewardRegularClients.clientsWithAtLeast': 'Clients ayant au moins',
   'campaign.rewardRegularClients.appointmentsInThe': 'rendez-vous dans les',
   'campaign.rewardRegularClients.last': 'derniers',
   'campaign.rewardRegularClients.appointments': 'rendez-vous',
   'campaign.rewardRegularClients.inTheLast': 'dans les derniers',
   'campaign.rewardRegularClients.months': 'mois',
   'subscriptionPaymentFailed.title':
      "Veuillez mettre à jour votre mode de paiement jusqu'à {0}, sinon toutes les fonctionnalités premium seront interrompues, merci.",
   'subscriptionCancelled.title':
      'Nous nous excusons mais votre Premium a été désactivé pour non-paiement. Si vous souhaitez continuer à utiliser les fonctionnalités premium, veuillez activer le plan Premium, merci.',
   'notificationPaymentFailed.title':
      "Votre paiement pour les notifications a échoué. Veuillez finaliser le paiement jusqu'au {0}, sinon les notifications seront désactivées, merci.",
   'notificationCancelled.title':
      'Nous nous excusons mais vos notifications ont été désactivées pour non-paiement. Si vous souhaitez continuer à envoyer des notifications, veuillez effectuer le paiement, merci.',
   'btn.hideOptions': 'Cacher les options',
   'btn.showOptions': 'Afficher les options',
   'notification.googleDisconnected': 'Google Calendar déconnecté',
   'notification.googleConnected': 'Google Calendar connecté',
   'notification.appleDisconnected': 'Apple Calendar déconnecté',
   'notification.appleConnected': 'Apple Calendar connecté',
   'notification.microsoft365Disconnected': 'Calendrier Microsoft365 déconnecté',
   'notification.microsoft365Connected': 'Calendrier Microsoft365 connecté',
   'notification.linkCopied': 'Lien copié',
   'calendarSync.title': "Synchronisation de l'agenda",
   'calendarSync.description':
      'Synchronisez les rendez-vous de Plandok avec votre calendrier préféré pour une organisation sans effort et ne manquez jamais un événement important.',
   'googleCalendar.title': 'Calendrier Google',
   'appleCalendar.title': 'Calendrier Apple',
   'microsoft365Calendar.title': 'Calendrier Microsoft365',
   'linkYourCalendar.title': 'Lier vos calendriers',
   'connect.btn': 'Connecter',
   'disconnect.rtn': 'Déconnecter',
   'connected.label': 'Connecté',
   'modal.workingHours.standartMethod.btn': 'Méthode standard',
   'modal.workingHours.customMethod.btn': 'Méthode personnalisée',
   'modal.workingHours.workingDates.input': 'Dates de travail',
   'modal.workingHours.selectDates.placeholder': 'Sélectionner les dates',
   'noInternetConnection.notification.title': 'Pas de connexion internet',
   'app.update.title': 'Nouvelle version disponible',
   'app.update.description.title':
      'Nous avons ajouté de nouvelles fonctionnalités et corrigé quelques bugs pour que votre expérience avec Plandok soit la plus agréable possible.',
   'app.update.button.title': 'Mise à jour',
   'app.notNow.button.title': 'Pas encore',
   'app.updateAvailable.button.title': 'Nouvelle version disponible',
   'upgradePlan.success.message': "L'abonnement a été mis à jour avec succès",
   'upgradePlan.error.message':
      "Erreur de mise à jour de l'abonnement, veuillez contacter le support si votre carte est débitée et que l'abonnement n'est pas mis à jour.",
   'sidebar.inventory': 'Inventaire',
   'products.tab': 'Produits',
   'suppliers.tab': 'Fournisseurs',
   'stockOrders.tab': 'Commandes de stock',
   'stockTracking.tab': 'Suivi des stocks',
   'input.productsSearch.placeholder': 'Recherche par nom de produit ou par code-barres',
   'product.create.btn.title': 'Ajouter un nouveau produit',
   'table.products.product': 'Produit',
   'table.products.barcode': 'Code barre',
   'table.products.brand': 'Marque',
   'table.products.sale': 'Vente',
   'table.products.saleOpt': 'Vente Opt.',
   'saleOptions.tooltip.title': 'Options de vente',
   'saleOptions.tooltip.description': "Vous pouvez choisir d'activer/désactiver la vente lors du paiement.",
   'seeMore.title': 'voir plus',
   'option.products.downloadCSV': 'Télécharger CSV',
   'option.products.downloadExcel': 'Télécharger Excel',
   'option.products.manageBrands': 'Gérer les marques',
   'option.products.manageCategories': 'Gérer les catégories',
   'option.products.manageStock': 'Gérer le stock',
   'option.products.importProducts': 'Importer des produits',
   'modal.filter.categories.title': 'Catégories',
   'modal.filter.brands.title': 'Marques',
   'modal.filter.stock.title': 'Stock',
   'modal.filter.allCategories.selectItem': 'Toutes les catégories',
   'modal.filter.allBrands.selectItem': 'Toutes les marques',
   'modal.filter.allSuppliers.selectItem': 'Tous les fournisseurs',
   'modal.filter.allProducts.selectItem': 'Tous les produits',
   'input.suppliersSearch.placeholder': 'Recherche par nom de fournisseur',
   'supplier.create.btn.title': 'Ajouter un nouveau fournisseur',
   'table.suppliers.supplierName': 'Nom du fournisseur',
   'table.suppliers.phoneNumber': 'Numéro de téléphone',
   'table.suppliers.physicalAddress': 'Adresse physique',
   'validation.notification.reminderTime': "Impossible d'envoyer des notifications avec la même heure de rappel.",
   'validation.paymentType.switches': 'Au moins un mode de paiement doit être autorisé',
   'plan.premium.option.14':
      '0 % de commission Plandok pour les rendez-vous réservés via les réservations en ligne par carte de crédit.',
   'title.dragAndExtend.appointment.permission': 'Peut faire glisser et prolonger les rendez-vous',
   'button.lastVisit.addAnotherLastvisit': 'AJOUTER UNE AUTRE DERNIÈRE VISITE',
   'notifications.template.badge.validate': 'Valider',
   'notifications.template.badge.refused': 'Refusé',
   'paid.by.card': 'Payé par carte',
   'costs.manual.paid.link':
      'Ou vous pouvez payer vos notifications manuellement avec votre carte, en cliquant sur ce lien',
   'modal.remove.account.title': 'Supprimer le compte Stripe',
   'modal.remove.account.description':
      'Votre compte sera supprimé et vous ne pourrez plus accepter les paiements en ligne. ',
   'ob.remove.account.option': 'Supprimer le compte Stripe',
   'btn.remove.account': 'Retirer',
   'calendar.tooltip.selected': 'Choisi',
   'calendar.tooltip.no.preference': 'Aucune préférence',
   'ob.gtag.manager.title': 'Gestionnaire de balises Google',
   'ob.analytics.gtag.description.1':
      'Le gestionnaire de balises Google vous permet de mesurer votre retour sur investissement publicitaire ainsi que de suivre vos sites et applications Flash, vidéo et de réseaux sociaux.',
   'ob.gtag.manager.description.1':
      'Google Tag Manager est un système de gestion de balises gratuit qui vous permet de gérer et de déployer des balises marketing (extraits de code ou pixels de suivi) sur votre site Web ou votre application mobile sans avoir à modifier directement le code.',
   'ob.gtag.manager.description.2':
      "Essentiellement, GTM rationalise le processus de gestion des balises marketing, facilitant ainsi le suivi des performances du site Web, la mesure des campagnes marketing et l'optimisation de l'expérience utilisateur.",
   'text.gender.male': 'Mâle',
   'text.gender.female': 'Femelle',
   'text.gender.unknown': 'Inconnu',
}

export default labels
