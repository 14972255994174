import Icon from '@ant-design/icons'
import { Button, Form, getIsMobile, Text, useLocation, useModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import * as api from 'api'
import { ModifyMassLayout } from 'components'
import { RoutePath } from 'constants/routes'
import { FORM_ERROR } from 'final-form'
import useQuery from 'hooks/data/useQuery'
import React, { useEffect } from 'react'

import * as SC from '../styles'

let onSaveToken: any

export default function PaymentDetailsForm() {
   const { data } = useQuery({
      fetchData: api.getClientToken,
   })
   const { goBack, navigate }: any = useLocation()
   const isMobile = getIsMobile()
   const [, { showModal }] = useModal()

   const payConfirmation = () => {
      showModal({
         type: 'PAYMENT_CONFIRMATION_MODAL',
         modalProps: { String },
      })
   }

   useEffect(() => {
      if (data) {
         // @ts-ignore
         window.braintree.dropin.create(
            {
               authorization: data,
               selector: '#dropin-container',
            },
            function (err: any, instance: any) {
               onSaveToken = () =>
                  new Promise((resolve, reject) => {
                     instance.requestPaymentMethod(function (err: any, payload: any) {
                        if (err) {
                           return reject(err)
                        }
                        resolve(payload)
                     })
                  })
            },
         )
      }
   }, [data])

   const onSubmit = async (form: any) => {
      if (form.nonce) {
         return Promise.reject({ [FORM_ERROR]: 'Please add card.' })
      }

      try {
         const data = await onSaveToken?.()
         const braintreePaymentMethodNonce = data?.nonce
         const reqData = { ...form, braintreePaymentMethodNonce }
         return api.upgradePlan(reqData)
      } catch (e) {
         return Promise.reject({ [FORM_ERROR]: 'Something went wrong, please try again or contact support.' })
      }
   }

   return (
      <>
         <Form
            onSubmit={onSubmit}
            onSuccess={() => {
               setTimeout(() => {
                  navigate(RoutePath.MARKETING)
               }, 300)
            }}>
            {({ formError, submitting }: any) => (
               <>
                  <SC.CardContainer>
                     <SC.HeaderTitle>
                        <Text weight="semiBold" size="large" mb="none">
                           <IntlLabel label="Payment for mass message" />
                        </Text>
                        <Text size={isMobile ? 'small' : 'medium'} weight="normal" mb="none">
                           <IntlLabel label="Type down your credit card details and pay" />
                        </Text>
                     </SC.HeaderTitle>
                     <div id="dropin-container" style={{ minHeight: '230px' }}></div>
                     <SC.PaymentAmount>
                        <Text size="medium" weight="normal">
                           <IntlLabel label="Payment amount:" />
                        </Text>
                        <Text size="xlarge" weight="semiBold" ml="xsmall">
                           <IntlLabel label="0.74" /> <IntlLabel label="€" />
                        </Text>
                     </SC.PaymentAmount>
                     <SC.PayFinishBtn>
                        <Button
                           label="Pay and finish"
                           minorBtn
                           htmlType="submit"
                           loading={submitting}
                           onClick={payConfirmation}
                           upperCase={false}
                        />
                     </SC.PayFinishBtn>
                  </SC.CardContainer>
                  <Form.Alert text={formError} />
                  <ModifyMassLayout.Footer>
                     <Button block type="primary" ghost upperCase={false} onClick={goBack}>
                        <Icon type="arrow-left" style={{ marginRight: '10px' }} />
                        <IntlLabel label={isMobile ? 'Provious' : 'Schedule send'} />
                     </Button>
                  </ModifyMassLayout.Footer>
               </>
            )}
         </Form>
      </>
   )
}
