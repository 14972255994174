import { AuthBlock, getIsMobile } from '@plandok/core'
import cn from 'classnames'
import { PermissionType } from 'constants/auth'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { Tooltip } from 'react-tooltip'

import * as SC from '../../styles'
import { getResizeLimit } from '../../support/helpers/card'
import {
   calculateDynamicTime,
   convertDistanceToTime,
   getNextColumnDelta,
   getNextColumnDeltaMobile,
   roundCalendarTime,
} from '../../support/helpers/card-drag'
import ResizeHandle from './ResizeHandle'
import TimeCardContent from './TimeCardContent'

interface ITimeCard {
   onMove(timeCard: any, nextStartTime: number, nextColumnIndex: number): any
   onResize(timeCard: any, nextEndTime: number): any
   onCardClick(id: string): any
   onCardClickMobile(timeCard: any): any
   style: any
   timeCard: any
   parentFrameId: string
   tooltipComponent: any
   canEdit: boolean
   timeFormat: string
   getContainerRef: any
   columnIndex: any
   columnWidth: number
   columnsCount: number
   hourHeight: number
   timeCardComponent: any
   displayOnly: boolean
}

interface ITimeCardState {
   isLoading: boolean
   isDragging: boolean
   isResizing: boolean
   leftContainerOffset: number
   columnDelta: number
   isScrolling: boolean
   isScrollListenerAttached: boolean
   dynamicStartTime?: number
   dynamicEndTime?: number
   mobileDragActive?: boolean
   mobileColumnDelta?: number
   isSelectSlotMode?: boolean
}

const TimeCard: React.FC<ITimeCard> = (props: ITimeCard) => {
   const [state, setState] = useState<ITimeCardState>({
      isLoading: false,
      isDragging: false,
      leftContainerOffset: 0,
      columnDelta: 0,
      mobileColumnDelta: undefined,
      dynamicStartTime: undefined,
      dynamicEndTime: undefined,
      isResizing: false,
      mobileDragActive: false,
      isScrolling: false,
      isScrollListenerAttached: false,
   })

   const cardRef = useRef<HTMLDivElement>(null)
   const cardContentRef = useRef<HTMLDivElement>(null)
   const mobileTimer = useRef<any>(null)
   const prevClickTime = useRef<any>(null)
   const scrollTimeout = useRef<any>(null)

   const handleScroll = useCallback(() => {
      clearTimeout(scrollTimeout.current)
      setState(prevState => ({ ...prevState, isScrolling: true }))
      scrollTimeout.current = setTimeout(() => {
         setState(prevState => ({ ...prevState, isScrolling: false }))
      }, 2000)
   }, [])

   const handleMobileDrag = useCallback(
      (event: any) => {
         event.stopPropagation()
         if (prevClickTime.current && new Date().getTime() - prevClickTime.current < 200) {
            prevClickTime.current = null
            return props.onCardClick(props.timeCard)
         }

         prevClickTime.current = new Date().getTime()
         mobileTimer.current = setTimeout(() => {
            if (!state.isScrolling) {
               setState(prevState => ({ ...prevState, mobileDragActive: true }))
            }
         }, 1500)
      },
      [state.isScrolling, props],
   )

   const stopMobileDrag = useCallback(() => {
      if (prevClickTime.current && new Date().getTime() - prevClickTime.current < 300) {
         prevClickTime.current = null
         clearTimeout(mobileTimer.current)
         return props.onCardClickMobile(props.timeCard)
      }
      if (mobileTimer.current) {
         clearTimeout(mobileTimer.current)
      }
   }, [props])

   useEffect(() => {
      const attachScrollListener = () => {
         const scrollContainer = props.getContainerRef()
         if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll)
            setState(prevState => ({ ...prevState, isScrollListenerAttached: true }))
         }
      }

      const removeScrollListener = () => {
         const scrollContainer = props.getContainerRef()
         if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', handleScroll)
            setState(prevState => ({ ...prevState, isScrollListenerAttached: false }))
         }
      }

      attachScrollListener()

      return () => {
         if (mobileTimer.current) {
            clearTimeout(mobileTimer.current)
            mobileTimer.current = null
         }
         removeScrollListener()
         clearTimeout(scrollTimeout.current)
      }
   }, [props, handleScroll])

   useEffect(() => {
      const attachScrollListener = () => {
         const scrollContainer = props.getContainerRef()
         if (scrollContainer && !state.isScrollListenerAttached) {
            scrollContainer.addEventListener('scroll', handleScroll)
            setState(prevState => ({ ...prevState, isScrollListenerAttached: true }))
         }
      }

      attachScrollListener()
   }, [handleScroll, props, state.isScrollListenerAttached])

   const updateDynamicTime = (y: any) => {
      const { timeCard } = props
      const deltaTime = convertDistanceToTime(y, props.hourHeight)
      const { dynamicStartTime, dynamicEndTime } = calculateDynamicTime(timeCard, deltaTime)

      setState(prevState => ({ ...prevState, dynamicStartTime, dynamicEndTime }))
   }

   const removeMobileActiveColumn = () => {
      document
         .querySelectorAll('.calendar-column[data-column-active]')
         ?.forEach((e: any) => e.removeAttribute('data-column-active'))
   }

   const handleDrag = ({ pageX: screenX }: any, { y, x }: any) => {
      if (!props.canEdit) return
      updateDynamicTime(y)

      if (getIsMobile()) {
         const mobileColumnDelta = getNextColumnDeltaMobile({
            x,
            columnWidth: props.columnWidth,
            columnIndex: props.columnIndex,
            columnsCount: props.columnsCount,
         })

         if (mobileColumnDelta !== state.mobileColumnDelta) {
            setState(prevState => ({ ...prevState, mobileColumnDelta }))
            const nextInd = props.columnIndex + mobileColumnDelta
            removeMobileActiveColumn()
            document.getElementById(`calendar-column-${nextInd}`)?.setAttribute?.('data-column-active', 'true')
         }
      } else {
         const columnDelta = getNextColumnDelta({
            screenX,
            columnDelta: state.columnDelta,
            columnWidth: props.columnWidth,
            leftContainerOffset: state.leftContainerOffset,
            columnIndex: props.columnIndex,
            columnsCount: props.columnsCount,
            scrollLeft: props.getContainerRef().scrollLeft,
         })

         if (columnDelta !== state.columnDelta) {
            setState(prevState => ({ ...prevState, columnDelta }))
         }
      }
   }

   const getLeftContainerOffset = () => {
      let leftOffset = props.getContainerRef()!.getBoundingClientRect().left
      if (props.parentFrameId) {
         leftOffset += window.parent.document.getElementById(props.parentFrameId)?.getBoundingClientRect?.()?.left || 0
      }

      return leftOffset
   }

   const startDrag = () => {
      if (props.canEdit) {
         setState(prevState => ({
            ...prevState,
            isDragging: true,
            leftContainerOffset: getLeftContainerOffset(),
         }))
      } else {
         props.onCardClick(props.timeCard)
         return false
      }
   }

   const dragStop = (_: any, { lastX, lastY }: any) => {
      if (lastX === 0 && lastY === 0) {
         setState(prevState => ({ ...prevState, isDragging: false }))
         return props.onCardClick(props.timeCard)
      }

      let colDelta: any = state.columnDelta

      if (getIsMobile()) {
         removeMobileActiveColumn()
         colDelta = state.mobileColumnDelta || 0
      }
      let nextColumnIndex = props.columnIndex + colDelta
      const nextStartTime = state.dynamicStartTime
      props.onMove(props.timeCard, nextStartTime ?? 0, nextColumnIndex).then(() => {
         if (cardRef.current) {
            cardRef.current.style.transform = 'none'
         }

         setState(prevState => ({
            ...prevState,
            isDragging: false,
            dynamicEndTime: undefined,
            dynamicStartTime: undefined,
            mobileDragActive: false,
         }))
      })
   }

   const onResizeStart = (e: any) => {
      e.stopPropagation()
      setState(prevState => ({ ...prevState, isResizing: true, isDragging: false }))
   }

   const onResizeStop = () => {
      setState(prevState => ({ ...prevState, isLoading: true }))

      props.onResize(props.timeCard, state.dynamicEndTime ?? 0).then(() => {
         setState(prevState => ({
            ...prevState,
            isResizing: false,
            isDragging: false,
            dynamicEndTime: undefined,
            isLoading: false,
            mobileDragActive: false,
         }))
      })
   }

   const onResize = (a: any, { y }: any) => {
      if (cardContentRef.current) {
         cardContentRef.current.style.height = `calc(${props.style.height} + ${y}px)`
      }
      const { timeCard } = props
      const deltaTime = convertDistanceToTime(y, props.hourHeight)
      const dynamicEndTime = roundCalendarTime(timeCard.endTime + deltaTime)

      setState(prevState => ({ ...prevState, dynamicEndTime }))
   }

   const getCardTooltipId = () => `_card-popper-id_${props.timeCard.id}`
   const getCardVersion = () => {
      const { timeCard } = props
      return `${timeCard.id}-${timeCard.startTime}-${timeCard.endTime}`
   }

   const {
      timeCard,
      displayOnly,
      tooltipComponent: TooltipComponent,
      timeCardComponent: TimeCardComponent = TimeCardContent,
      timeFormat,
   } = props
   const { isDragging, columnDelta, dynamicStartTime, dynamicEndTime, isResizing, mobileDragActive } = state
   const isMobile = getIsMobile()
   const color = timeCard.isBlocked ? '#a4adba' : timeCard.color

   const cardContent = (
      <TimeCardComponent
         timeCard={timeCard}
         dynamicStartTime={dynamicStartTime}
         dynamicEndTime={dynamicEndTime}
         timeFormat={timeFormat}
      />
   )

   if (displayOnly) {
      return (
         <SC.Card
            style={props.style}
            className="static-time-card"
            isDragging={false}
            key={`${timeCard.startTime}-${timeCard.endTime}`}>
            <SC.CardContent bgColor={color}>{cardContent}</SC.CardContent>
         </SC.Card>
      )
   }

   const tooltipEnabled = !isDragging && !isResizing && !mobileDragActive && !isMobile

   return (
      <>
         {isDragging && (
            <SC.Card
               style={props.style}
               className="static-time-card"
               isDragging={false}
               key={`${timeCard.startTime}-${timeCard.endTime}`}>
               <SC.CardContent bgColor={color}>{cardContent}</SC.CardContent>
            </SC.Card>
         )}
         <Draggable
            bounds={isMobile ? false : 'parent'}
            axis={isMobile ? 'both' : 'y'}
            onStart={startDrag}
            onDrag={handleDrag}
            onStop={dragStop}
            disabled={isResizing || (isMobile && !mobileDragActive)}
            key={getCardVersion()}>
            <SC.Card
               style={props.style}
               isDragging={isDragging}
               isResizing={isResizing}
               columnDelta={columnDelta}
               data-for={getCardTooltipId()}
               data-tooltip-id={getCardTooltipId()}
               data-tip=""
               className={cn({
                  'static-time-card': !isDragging,
                  'mobile-before-drag-card': isMobile && !mobileDragActive,
                  'mobile-on-drag-card': isMobile && mobileDragActive,
               })}
               ref={cardRef}>
               <SC.CardContent ref={cardContentRef} bgColor={color}>
                  {cardContent}
                  {isMobile && !mobileDragActive && (
                     <SC.MobileCardOverlay onTouchStart={handleMobileDrag} onTouchEnd={stopMobileDrag} />
                  )}
               </SC.CardContent>
               <AuthBlock section={PermissionType.CAN_BOOK_APPOINTMENTS && PermissionType.CAN_DRAG_APPOINTMENTS}>
                  <Draggable
                     bounds={getResizeLimit(timeCard, props.hourHeight)}
                     onStart={onResizeStart}
                     onDrag={onResize}
                     onStop={onResizeStop}
                     disabled={isDragging}
                     position={isResizing ? undefined : { x: 0, y: 0 }}
                     axis="y">
                     <ResizeHandle hidden={isMobile && !mobileDragActive} />
                  </Draggable>
               </AuthBlock>
            </SC.Card>
         </Draggable>
         {tooltipEnabled && (
            <Tooltip
               place="right"
               id={getCardTooltipId()}
               delayShow={0}
               style={{ backgroundColor: 'white', zIndex: '100000', opacity: 1 }}
               render={() => (
                  <TooltipComponent booking={timeCard} isBlocked={timeCard.isBlocked} timeFormat={timeFormat} />
               )}
            />
         )}
      </>
   )
}

export default TimeCard
