import { Button, Field, Form, Text } from '@plandok/core'
import { getLabel, ReactIntl } from '@plandok/i18n'
import * as api from 'api'
import { flow, noop } from 'lodash'
import { AnalyticsServices } from 'pages/dashboard/BookingPage/BookingAnalytics'
import React, { useContext } from 'react'

import * as SC from '../styles'
import { AnalyticsModalProps } from '../types/types'

type AnalyticModalProps = {
   altKey: string
   FIELD_PLACEHOLDER: string
   imgSrc: string
   description1: string
   description2: string
   onClose: () => void
   modalProps: AnalyticsModalProps['modalProps']
   fieldName: string
   fieldLabel: string
   fieldTooltip: string
   accountLabel: string
   href: string
}

export default function AnalyticsModal({
   altKey,
   imgSrc,
   description1,
   description2,
   onClose,
   modalProps,
   fieldName,
   fieldLabel,
   FIELD_PLACEHOLDER,
   fieldTooltip,
   accountLabel,
   href,
}: AnalyticModalProps) {
   const { lang } = useContext(ReactIntl)

   const alt = getLabel(altKey, lang)

   return (
      <div className="justify-between">
         <div className="align-center">
            <SC.AnalyticsImg src={imgSrc} alt={alt} />
         </div>

         <SC.BookingAnalyticsInfo>
            <Text label={description1} size="base" />
            <Text label={description2} size="base" />

            <Form
               onSubmit={(form: AnalyticsServices) =>
                  api.updateAnalyticService({ ...modalProps.analyticsServices, ...form })
               }
               onSuccess={flow(onClose, modalProps.onSuccess || noop)}
               initialValues={modalProps.analyticsServices}>
               {({ submitting }: { submitting: boolean }) => (
                  <>
                     <Field.Input
                        name={fieldName}
                        label={fieldLabel}
                        placeholder={FIELD_PLACEHOLDER}
                        tooltip={fieldTooltip}
                     />
                     <Button
                        type="primary"
                        label="btn.save"
                        htmlType="submit"
                        loading={submitting}
                        upperCase={false}
                        semiBold
                     />
                  </>
               )}
            </Form>

            <div className="text-center">
               <Text label={accountLabel} size="base" mb="none" />
               <div className="justify-center">
                  <Text label="ob.analytics.click" size="base" mb="none" />
                  <a href={href} target="_blank" rel="noopener noreferrer">
                     <Text label="ob.analytics.here" size="base" mb="none" weight="medium" />
                  </a>
                  <Text label="ob.analytics.learn" size="base" mb="none" ml="xxsmall" />
               </div>
            </div>
         </SC.BookingAnalyticsInfo>
      </div>
   )
}
