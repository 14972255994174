import { Button, mediaMdDown, Text } from '@plandok/core'
import styled, { css } from 'styled-components'

export const CalendarSyncContainer = styled.div`
   border-radius: 10px;
   background: ${({ theme }) => theme.background && '#f8f9fc'};
   margin-bottom: 28px;
   padding: 11px 24px;

   ${mediaMdDown(css`
      border-radius: 0;
      background: #ffffff;
      border-top: 1px solid #e9ecf3;
      padding: 28px 0;
      margin-bottom: 0;
   `)}
`

export const CalendarSyncTitleContainer = styled.div`
   display: flex;
   margin-bottom: 6px;
   div {
      font-size: 20px;
      line-height: 28px;
      ${mediaMdDown(css`
         font-size: 18px;
         line-height: 21px;
      `)}
   }
`

export const PremiumFeatureTitleContainer = styled.div`
   display: flex;
   align-items: center;
   margin-left: 12px;
   svg {
      display: flex;
   }
   div:nth-child(2) {
      font-size: 18px;

      ${mediaMdDown(css`
         font-size: 16px;
      `)}
   }
`

export const CalendarSyncDescription = styled(Text)`
   color: #61749d;
   max-width: 592px;
   margin-bottom: 6px;
   font-size: 16px;

   ${mediaMdDown(css`
      font-size: 14px;
      line-height: 20px;
   `)}
`

export const ShowOptionsContainer = styled.div`
   display: flex;
   align-items: center;

   div {
      color: #2472e8;
      line-height: 20px;
   }

   svg {
      margin-left: 6px;
      path {
         fill: #2472e8;
      }
   }

   :hover {
      cursor: pointer;
   }
`

export const UpgradeButton = styled(Button)`
   background: #2472e8 !important;
   border: none;
   max-height: 40px;

   ${mediaMdDown(css`
      margin-top: 22px;
      width: 100%;
   `)}
`

export const CalendarItemContainer = styled.div`
   padding: 16px 0;
   border-top: 1px solid #e9ecf3;
   display: flex;
   justify-content: space-between;

   button {
      border: 0.5px solid rgba(173, 182, 204, 0.5);
      padding: 0 24px;
      box-shadow: 0px 4px 12px -3px rgba(38, 60, 122, 0.2);
   }

   ${mediaMdDown(css`
      flex-direction: column;
      button {
         font-size: 14px;
         margin-top: 16px;
      }
   `)}
`

export const ConnectedContainer = styled.div`
   margin-left: 18px;
   display: flex;
   align-items: center;

   div {
      font-size: 14px;
      color: #009329;
   }

   svg {
      stroke: #009329;
      width: 14px;
      height: 10px;
   }

   ${mediaMdDown(css`
      margin-left: 16px;

      div {
         font-size: 12px;
      }
   `)}
`
