import { ModifyMassLayout, NotReleased } from 'components'
import { RoutePath } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import SheduleSendCustomTimeForm from './components/SheduleSendCustomTimeForm'

export const SheduleSendCustomTimeFormWithApi = withFormApi({
   backPath: RoutePath.CLIENT_SELECTION,
   successMessage: 'Massage',
})(SheduleSendCustomTimeForm)

export default function ModifyScheduleSendCustomTimePage() {
   return (
      <NotReleased>
         <ModifyMassLayout title="Schedule send" step="SMS setup. Step 3 of 4">
            <SheduleSendCustomTimeFormWithApi />
         </ModifyMassLayout>
      </NotReleased>
   )
}
