import { Button, CustomIcon, useModal, withModal } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { PermissionType } from 'constants/auth'
import { RoutePath } from 'constants/routes'
import useHasPermission from 'hooks/permission/useHasPermission'
import React from 'react'

import * as SC from '../styles'

function ClientDetailsMenuModal(props: any) {
   const [, { showModal }] = useModal()
   const { navigate } = useLocation()

   const hasPermission = useHasPermission(PermissionType.CAN_SEE_CLIENT_INFO)

   const phone = props.modalProps.phoneNumber

   const editClient = () => {
      props.onClose()
      navigate(`${RoutePath.CLIENT_EDIT}/${props.modalProps.id}`)
   }

   const openDeleteClientModal = () =>
      showModal({ type: 'CONFIRM_DELETE_CLIENT', modalProps: { id: props.modalProps.id, onSuccess: props.onClose } })

   return (
      <SC.ClientDetailsMenuContainer>
         {phone && (
            <SC.MenuItem as="a" href={`tel:${phone}`}>
               <div>
                  <CustomIcon type="callClient" />
               </div>
               <div>Call {phone}</div>
            </SC.MenuItem>
         )}
         <SC.MenuItem onClick={hasPermission ? editClient : undefined}>
            <div>
               <CustomIcon type="editClient" />
            </div>
            <div>Edit</div>
         </SC.MenuItem>
         <SC.MenuItem isRemove onClick={openDeleteClientModal}>
            <div>
               <CustomIcon type="deleteClient" />
            </div>
            <div>Delete</div>
         </SC.MenuItem>
         <SC.ClientDetailsFooter>
            <Button label="btn.close" block supportBtn onClick={props.onClose} />
         </SC.ClientDetailsFooter>
      </SC.ClientDetailsMenuContainer>
   )
}

export default withModal(null, { noClose: true, contentClassName: 'pb-0 client-select-modal' })(ClientDetailsMenuModal)
