// import { Area } from '@ant-design/charts';
import { Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React, { useEffect, useState } from 'react'

import { App_AppointmentsAnalyticsGraphPoint } from '../../../../../../graphql/generatedTypes'
import * as SC from './styles'

type TotalAppointmentsChartProps = {
   chartPoints?: App_AppointmentsAnalyticsGraphPoint[] | null
}

type ChartsData = {
   range: string
   type: string
   value: number
}

export const TotalAppointmentsChart = ({ chartPoints }: TotalAppointmentsChartProps) => {
   const [, setMappedPoints] = useState<ChartsData[]>([])

   // const chartColors = ['#6897a7', '#8bc0d6', '#60d7a7', '#dedede', '#fedca9', '#fab36f', '#d96d6f']

   // const getTranslatedChartTitles = (label: string): string => {
   //    if (label === 'Appointments') return 'analytics.totalAppointments.appointment.label'
   //    if (label === 'Online bookings') return 'analytics.totalAppointments.onlineBook.label'
   //    return ''
   // }

   // const customTooltip: CustomTooltip = useMemo(
   //    () => ({
   //       customContent: (_, data) => {
   //          const findDate = chartPoints?.find(point => point.xValue.slice(5) === data[0]?.data.range)?.xValue
   //          if (findDate) {
   //             return (
   //                <SC.Tooltip>
   //                   <div>
   //                      <IntlDate date={new Date(findDate)} dateFormat="MMM yyyy dd" />
   //                   </div>
   //                   <SC.TooltipDivider />
   //                   {!!data?.length &&
   //                      data?.map((item: any) => (
   //                         <SC.TooltipDataRow key={item.name}>
   //                            <SC.TooltipCircle color={item.color} />
   //                            <SC.TooltipValues>{item.value}</SC.TooltipValues>
   //                            <IntlLabel label={getTranslatedChartTitles(item.name)} />
   //                         </SC.TooltipDataRow>
   //                      ))}
   //                </SC.Tooltip>
   //             )
   //          }
   //       },
   //    }),
   //    [chartPoints],
   // )

   // const areaChartConfig = {
   //    data: mappedPoints,
   //    xField: 'range',
   //    yField: 'value',
   //    yAxis: {
   //       position: 'right' as const,
   //    },
   //    seriesField: 'type',
   //    autoFit: true,
   //    color: chartColors,
   //    legend: { position: 'top' } as any,
   //    tooltip: customTooltip,
   //    meta: { range: { range: [0, 1] } },
   // }

   useEffect(() => {
      const totalChartPoints: ChartsData[] = []

      chartPoints?.map(item => {
         return totalChartPoints.push(
            {
               range: item.xValue.slice(5) || '',
               value: item.yAppointmentsCount || 0,
               type: 'Appointments',
            },
            {
               range: item.xValue.slice(5) || '',
               value: item.yOnlineBookingsCount || 0,
               type: 'Online bookings',
            },
         )
      })

      setMappedPoints(totalChartPoints)
   }, [chartPoints])

   return (
      <SC.TotalAppointmentsChart>
         <Text size="mlarge" weight="bold" colorType="base" mb="xsmall">
            <IntlLabel label="analytics.totalAppointments.title" />
         </Text>

         <SC.AreaChart>{/*<Area {...areaChartConfig} />*/}</SC.AreaChart>
      </SC.TotalAppointmentsChart>
   )
}

export default TotalAppointmentsChart
