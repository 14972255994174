import { mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const Container = styled.div`
   display: flex;
   margin-bottom: 40px;
`

export const SGroupBody = styled.div`
   position: relative;
   min-height: 50px;
`

export const Drag = styled.div`
   display: flex;
   align-items: center;
   width: 40px;
   cursor: grab;
   ${mediaMobileDown(css`
      display: none;
   `)}
`

export const Control = styled.div`
   position: absolute;
   right: 30px;
   font-size: 40px;
`

export const Item = styled.div`
   display: flex;
   flex-direction: column;
   background: white;
   flex-grow: 1;
   min-height: 230px;
   position: relative;
   border-radius: ${props => props.theme.borderRadiusBase};
   padding: 25px 25px 25px 45px;
   box-shadow: 0 3px 50px rgba(53, 38, 122, 0.15);
   ${mediaMobileDown(css`
      border-radius: 10px;
      padding: 25px 25px 25px 30px;
   `)}
`

export const Title = styled.div`
   color: ${props => props.theme.primaryColor};
   font-size: 28px;
   font-weight: 500;
   margin-bottom: 20px;
`

export const Prefix = styled.div<any>`
   position: absolute;
   left: 0;
   top: 0;
   bottom: 0;
   background: ${props => props.color};
   width: ${props => props.theme.borderRadiusBase};
   border-bottom-left-radius: ${props => props.theme.borderRadiusBase};
   border-top-left-radius: ${props => props.theme.borderRadiusBase};
   ${mediaMobileDown(css`
      width: 10px;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
   `)}
`

export const ServiceItem = styled.div`
   background: #f8f9fc;
   display: flex;
   color: #adb6cc;
   border-radius: 6px;
   margin-bottom: 10px;
   cursor: pointer;
   font-size: 16px;

   :hover {
      background: #e9ecf3;
   }

   [data-service-item-drag] {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      cursor: grab;
   }
   [data-service-item-content] {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      user-select: none;
      align-items: center;
      padding-right: 20px;
      div {
         &:first-of-type {
            color: #61749d;
            text-align: left;
            width: 50%;
         }
         &:nth-of-type(2) {
            text-align: left;
            flex: 1 0 0;
            font-weight: 500;
         }
      }

      ${mediaMobileDown(css`
         padding: 0.5rem 1.1rem 0.5rem;
      `)}
   }
`
