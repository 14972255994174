import { Row } from 'antd'
import cn from 'classnames'
import React from 'react'

export default function FieldRow({ children, className, gutter = 10, ...props }: { children: React.ReactNode } & any) {
   return (
      <Row justify="space-between" className={cn('w-100', className)} gutter={gutter} {...props}>
         {children}
      </Row>
   )
}
