import { Button, Text, useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import cn from 'classnames'
import { StateCalendar } from 'pages/dashboard/CalendarPage/constants'
import { CalendarItem, WorkingHours } from 'pages/dashboard/CalendarPage/types'
import React, { memo, useEffect, useMemo, useRef } from 'react'
import { isFirefox } from 'react-device-detect'
import { useResizeDetector } from 'react-resize-detector'
import { ThemeProvider } from 'styled-components'

import { RoutePath } from '../constants/routes'
import CalendarList from './components/CalendarList/CalendarList'
import TimeColumn from './components/TimeColumn'
import * as SC from './styles'
import * as helpers from './support/helpers'
import { ModeType } from './support/types'

export interface ICalendarActions {
   onMove(timeCard: any, nextStartTime: number, nextColumnIndex: number, indexChanged: boolean): any
   onResize(timeCard: any, nextEndTime: number): any
   onCardClick(timeCard: any): any
   onTimeSlotClick(time: number, info: any): any
}

// #2b72bd
export interface ICustomComponents {
   headerComponent: any
   tooltipComponent: any
   timeCardComponent?: any
}

export interface ICalendarProps extends ICalendarActions, ICustomComponents {
   timeInterval: number
   modeType: ModeType
   date: Date
   hasWorkingStaffs: boolean
   isCalendarLoading: boolean
   data: CalendarItem[]
   isSelectSlotMode?: boolean
   staffFromFilterParams?: string
   timeFormat: string
   parentFrameId?: string
   customViewType: string
   customViewTime: WorkingHours
   workingHoursStartTime: number
   workingHoursEndTime: number
   setContainerContext: (context: any) => void
   scrollToCurrentTime: (containerRef: any) => void
}

function EpicCalendar(props: ICalendarProps) {
   const { navigate } = useLocation()
   const containerRef: any = useRef()

   const { width: containerWidth } = useResizeDetector({
      targetRef: containerRef,
   })

   const dataLength = props.data?.length
   const hours: number[] = []

   const appTheme = useMemo(
      () => helpers.getThemeConfig(props, containerWidth ?? window.innerWidth),
      [props, containerWidth],
   )

   const actions = useMemo(
      () => ({
         onMove: props.onMove,
         onResize: props.onResize,
         onCardClick: props.onCardClick,
         onTimeSlotClick: props.onTimeSlotClick,
      }),
      [props.onMove, props.onResize, props.onCardClick, props.onTimeSlotClick],
   )

   const getContainerRef = () => containerRef?.current

   props.data.forEach(data => {
      if (data.workingHours.length) {
         const workingHours = (data.workingHours[0].endTime - data.workingHours[0].startTime) / 60

         hours.push(workingHours)
      }
   })

   const checkWorkingHours = hours.reduce((acc, val) => acc + val, 0)

   useEffect(() => {
      if (containerRef.current) {
         props.scrollToCurrentTime(containerRef.current)
         props.setContainerContext(containerRef)
      }
      return () => {}
   }, [containerRef.current])

   return (
      <ThemeProvider theme={appTheme}>
         <SC.Container>
            {!!props.data.length ? (
               <SC.Content>
                  <SC.CalendarBody
                     ref={containerRef}
                     id="epic-calendar__body"
                     className={cn('epic-calendar__body', {
                        'epic-calendar__select-slot-mode': props.isSelectSlotMode,
                     })}
                     isFirefox={isFirefox}>
                     <TimeColumn
                        date={props.date}
                        dataLength={dataLength}
                        hourHeight={appTheme.hourHeight}
                        modeType={props.modeType}
                        customViewType={props.customViewType}
                        customViewTime={props.customViewTime}
                        workingHoursStartTime={props.workingHoursStartTime}
                        workingHoursEndTime={props.workingHoursEndTime}
                        timeFormat={props.timeFormat}
                     />
                     <CalendarList
                        timeInterval={props.timeInterval}
                        data={props.data}
                        hourHeight={appTheme.hourHeight}
                        columnWidth={appTheme.columnWidth}
                        getContainerRef={getContainerRef}
                        actions={actions}
                        parentFrameId={props.parentFrameId}
                        headerComponent={props.headerComponent}
                        tooltipComponent={props.tooltipComponent}
                        timeCardComponent={props.timeCardComponent}
                        modeType={props.modeType}
                        isSelectSlotMode={props.isSelectSlotMode}
                        customViewType={props.customViewType}
                        customViewTime={props.customViewTime}
                        workingHoursStartTime={props.workingHoursStartTime}
                        workingHoursEndTime={props.workingHoursEndTime}
                        timeFormat={props.timeFormat}
                     />
                  </SC.CalendarBody>
               </SC.Content>
            ) : (
               <>
                  {!!props.data.length && checkWorkingHours <= 0 ? (
                     <SC.Content>
                        <SC.CalendarBody
                           ref={containerRef}
                           id="epic-calendar__body"
                           className={cn('epic-calendar__body', {
                              'epic-calendar__select-slot-mode': props.isSelectSlotMode,
                           })}
                           isFirefox={isFirefox}>
                           <TimeColumn
                              date={props.date}
                              dataLength={dataLength}
                              modeType={props.modeType}
                              hourHeight={appTheme.hourHeight}
                              customViewType={StateCalendar.FULL_CALENDAR}
                              customViewTime={{ startTime: 0, endTime: 1380 }}
                              workingHoursStartTime={props.workingHoursStartTime}
                              workingHoursEndTime={props.workingHoursEndTime}
                              timeFormat={props.timeFormat}
                           />
                           <CalendarList
                              timeInterval={props.timeInterval}
                              data={props.data}
                              hourHeight={appTheme.hourHeight}
                              columnWidth={appTheme.columnWidth}
                              getContainerRef={getContainerRef}
                              actions={actions}
                              parentFrameId={props.parentFrameId}
                              headerComponent={props.headerComponent}
                              tooltipComponent={props.tooltipComponent}
                              timeCardComponent={props.timeCardComponent}
                              modeType={props.modeType}
                              isSelectSlotMode={props.isSelectSlotMode}
                              customViewType={StateCalendar.FULL_CALENDAR}
                              customViewTime={{ startTime: 0, endTime: 1380 }}
                              workingHoursStartTime={props.workingHoursStartTime}
                              workingHoursEndTime={props.workingHoursEndTime}
                              timeFormat={props.timeFormat}
                           />
                        </SC.CalendarBody>
                     </SC.Content>
                  ) : (
                     <SC.EmptyState>
                        <div>
                           <img src="/img/illustrations/empty-staff.svg" alt="placeholder" />
                        </div>
                        {!props.hasWorkingStaffs && !props.isCalendarLoading && (
                           <>
                              <Text size="mlarge" weight="semiBold" isCenter>
                                 <IntlLabel label="staff.emptyState.title" />
                              </Text>
                              <Text size="base" weight="medium" isCenter>
                                 <IntlLabel label="staff.emptyState.description" />
                              </Text>
                              <Button
                                 type="primary"
                                 htmlType="submit"
                                 upperCase={false}
                                 smallRadius
                                 style={{ minWidth: '244px' }}
                                 onClick={() => navigate(`${RoutePath.STAFF}#hours`)}>
                                 <IntlLabel label="staff.viewStaffMembers.button" />
                              </Button>
                           </>
                        )}
                     </SC.EmptyState>
                  )}
               </>
            )}
         </SC.Container>
      </ThemeProvider>
   )
}

EpicCalendar.defaultProps = {
   data: [],
}

export default memo(EpicCalendar)
