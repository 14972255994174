import React from 'react'

import * as SC from '../styles'

export default function TableHeader(props: { columns: any[] }) {
   return (
      <SC.Head className="ant-table-thead">
         <tr>
            <th />
            {(props.columns || []).map(({ key, dataIndex, title }) => (
               <th key={`${key}-${dataIndex}`} className="">
                  <span className="ant-table-header-column">
                     <div>
                        <span className="ant-table-column-title">{title}</span>
                     </div>
                  </span>
               </th>
            ))}
         </tr>
      </SC.Head>
   )
}
