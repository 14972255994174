import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, DatePicker, getIsMobile, startOfWeekIntl } from '@plandok/core'
import cn from 'classnames'
import { addDays, isSameDay, isToday } from 'date-fns'
import { getCalendarRangeStart } from 'helpers/date/calendar'
import React from 'react'

import DateFilterDisplay from './components/DateFilterDisplay'
import { DatePickerContainer } from './components/DatePickerContainer'
import * as SC from './styles'

type DateFilterItemProps = {
   date: Date
   changeDate: (date: Date) => void
   viewType?: any
}

export default function DateFilterItem({ date = new Date(), changeDate, viewType }: DateFilterItemProps) {
   const isWeekMode = viewType === 'week'
   const rangeDate = getCalendarRangeStart(date, isWeekMode)

   const isCurrentDate = isWeekMode ? isSameDay(startOfWeekIntl(new Date()), startOfWeekIntl(date)) : isToday(rangeDate)

   const daysDelta = isWeekMode ? 7 : 1

   return (
      <SC.DateFilterContainer className="date-picker-right-position filter-datepicker-container">
         <Button icon={<LeftOutlined />} ghost onClick={() => changeDate(addDays(date, -daysDelta))} />
         {!getIsMobile() && (
            <Button
               type="primary"
               ghost={!isCurrentDate}
               onClick={() => changeDate(new Date())}
               label="calendar.today"
               minorBtn={isCurrentDate}
            />
         )}
         <DatePicker
            value={rangeDate as any}
            customInput={
               <Button ghost={!getIsMobile()}>
                  <DateFilterDisplay isWeekMode={isWeekMode} date={date} />
               </Button>
            }
            calendarContainer={(props: any) => <DatePickerContainer {...props} date={date} changeDate={changeDate} />}
            withPortal={getIsMobile()}
            onSelect={changeDate}
            className="filter-datepicker"
            calendarClassName={cn({ 'week-mode-react-datepicker': isWeekMode })}
         />
         <Button icon={<RightOutlined />} ghost onClick={() => changeDate(addDays(date, daysDelta))} />
      </SC.DateFilterContainer>
   )
}
