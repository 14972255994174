import './style.less'

import { useAppContext, useModal, useNavigation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { AppLayout, MobileBackButton, NoInternetConnection } from 'components'
import { PermissionType } from 'constants/auth'
import { RoutePath, StaffTabHash } from 'constants/routes'
import React from 'react'

import StaffDatesPage from './StaffDatesPage'
import StaffMembersPage from './StaffMembersPage'
import StaffPermissionsPage from './StaffPermissionsPage'
import StaffTabs from './StaffTabs'
import StaffWorkingPage from './StaffWorkingPage'

export default function StaffPage() {
   const [context] = useAppContext()
   const { navigate, hash } = useNavigation()
   const [, { showModal }] = useModal()

   const goToUpdateStaff = (id: string) => navigate(`${RoutePath.STAFF_UPDATE}/${id}`)

   const goToCreateStaff = () =>
      context?.subscription?.canAddEmployee
         ? navigate(RoutePath.STAFF_CREATE)
         : showModal({
              type: 'EXTRA_COSTS_FOR_NEW_MEMBER',
           })

   // : showModal({ type: 'PREMIUM_UPGRADE_REQUIRED', modalProps: { staffPage: true } });

   const getSidebarTitle = () => {
      switch (true) {
         case hash?.includes?.(StaffTabHash.WORKING_HOURS):
            return 'staff.menu.working'
         case hash?.includes?.(StaffTabHash.USER_PERMISSIONS):
            return 'staff.menu.permissions'
         case hash?.includes?.(StaffTabHash.CLOSED_DATES):
            return 'staff.menu.dates'
         default:
            return 'sidebar.staff'
      }
   }

   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="sidebar.staff"
            mobileHeader={() => (
               <>
                  <MobileBackButton />
                  <div>
                     <IntlLabel label={getSidebarTitle()} />
                  </div>
                  <div />
               </>
            )}
            addButtonPath={hash?.includes?.(StaffTabHash.STAFF_MEMBERS) ? RoutePath.STAFF_CREATE : undefined}>
            <StaffTabs>
               <StaffMembersPage
                  key={PermissionType.EMPLOYEES}
                  goToCreateStaff={goToCreateStaff}
                  goToUpdateStaff={goToUpdateStaff}
               />
               <StaffWorkingPage key={PermissionType.WORKING_HOURS} />
               <StaffDatesPage key={PermissionType.CLOSED_DATES} />
               <StaffPermissionsPage key={PermissionType.PERMISSION_LEVELS} />
            </StaffTabs>
         </AppLayout>
      </NoInternetConnection>
   )
}
