import { mediaLgDown, mediaLgUp, mediaSmDown } from '@plandok/core'
import styled, { css } from 'styled-components'

type ScrollableContentProps = {
   maxHeight?: number
}

export const Container = styled.div`
   margin-bottom: 10px;
   padding: 6px;
   display: grid;
   grid-gap: 20px;
   grid-template-columns: repeat(auto-fit, minmax(15ch, 1fr));
   grid-template-rows: 50px auto;
   grid-template-areas:
      'date-picker date-picker date-picker'
      'total-appointments-1 total-appointments-2 total-appointments-2'
      'cancellation-reasons occupancy top-services'
      'clients clients clients';

   h2 {
      font-size: 1.5rem;
      color: #13316d;
   }

   ${mediaLgDown(css`
      grid-template-areas:
         'date-picker'
         'total-appointments-1'
         'total-appointments-2'
         'cancellation-reasons'
         'occupancy'
         'top-services'
         'clients';
   `)}
`

export const AnalyticsCard = styled.div`
   height: 100%;
   box-shadow: 0px 2px 10px #263c7a33;
   border-radius: 20px;
   margin-bottom: 40px;

   ${mediaSmDown(css`
      padding: 1.5rem 1rem;
   `)}
`

//max-height: ${({ maxHeight }: ScrollableContentProps) => (maxHeight ? `${maxHeight}vh` : '19vh')};
export const ScrollableContent = styled.div<ScrollableContentProps>`
   ${mediaLgUp(css`
      overflow-y: auto;
      overflow-x: hidden;
      white-space: nowrap;

      &::-webkit-scrollbar {
         width: 0.2rem;
         height: 0.2rem;
         margin-top: 1rem;
         margin-bottom: 1rem;
      }
      &::-webkit-scrollbar-track-piece {
         background-color: #e9ecf3;
      }
      &::-webkit-scrollbar-thumb {
         background: #adb6cc;
         border-radius: 10px;
      }
   `)}
`

export const Inliner = styled.div`
   display: flex;
   justify-content: flex-end;
`

export const NumbersColumn = styled.div`
   justify-content: center;
`

export const Divider = styled.div`
   margin: 5px 0 !important;
`
