import { Button, Mutation, useModal, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import * as api from 'api'
import React from 'react'

import * as SC from '../styles'

type ConfirmUnsubscribeModalProps = {
   modalProps: {
      onSuccess: () => void
   }
   onClose: () => void
   hideAllModals: () => void
}

function ConfirmUnsubscribeModal({ modalProps, onClose, hideAllModals }: ConfirmUnsubscribeModalProps) {
   const [, { showModal }] = useModal()

   const unsubscribe = async () => {
      const cancelSubscription = await api.cancelPlan()

      if (cancelSubscription.errors) {
         onClose()
         showModal({ type: 'CANCEL_SUBSCRIPTION_ERROR' })
      } else {
         modalProps?.onSuccess()
         hideAllModals()
      }
   }

   return (
      <SC.UnsubscribeContainer>
         <h2>
            <IntlLabel label="modal.confirmCancel.subscription" />
         </h2>
         <span>
            <IntlLabel label="modal.confirmCancel.subscription.description" />
         </span>
         <SC.ModalFooter>
            <Mutation action={unsubscribe}>
               {({ loading, action }: any) => (
                  <>
                     <Button disabled={loading} label="btn.cancel" onClick={onClose} />
                     <Button
                        loading={loading}
                        label="btn.cancelSubscription.confirm"
                        danger
                        onClick={action}
                        data-confirm-unsubscribe
                     />
                  </>
               )}
            </Mutation>
         </SC.ModalFooter>
      </SC.UnsubscribeContainer>
   )
}

export default withModal(null)(ConfirmUnsubscribeModal)
