import { Button, Form, Text, useLocation, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import * as api from 'api'
import { NotReleased } from 'components'
import { RoutePath } from 'constants/routes'
import { flow, noop } from 'lodash'
import React from 'react'

import * as SC from '../styles'

function PaymentConfirmationModal(props: any) {
   const { navigate } = useLocation()

   const dashboard = () => {
      navigate(RoutePath.MARKETING)
      props.onClose()
   }

   return (
      <NotReleased>
         <Form
            onSubmit={(form: any) => api.closeAppointment(props.modalProps.Number, form)}
            onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}>
            {({ submitting }: any) => (
               <SC.PaymentContainer>
                  <img src="/img/illustrations/checked.svg" alt="placeholder" />
                  <Text label="Payment successful!" size="mlarge" weight="semiBold" mb="none" />
                  <SC.TextContainer>
                     <Text size="small" mb="none">
                        <IntlLabel label="Your message will be send" />
                     </Text>
                     <Text label="2021/01/18 at 9:30" size="small" weight="semiBold" ml="xsmall" mb="none" />
                  </SC.TextContainer>
                  <Button
                     type="primary"
                     label="Back to dashboard"
                     loading={submitting}
                     onClick={dashboard}
                     upperCase={false}
                  />
               </SC.PaymentContainer>
            )}
         </Form>
      </NotReleased>
   )
}

export default withModal(null, { contentClassName: 'payment-modal-content' })(PaymentConfirmationModal)
