import { LabelKey } from '@plandok/i18n'

export const getAvatarSign = (value?: string) => (value || '').substring(0, 2).toUpperCase()

export const getPercentDiff = (oldValue: number, newValue: number): number => {
   const valuesDiff = newValue - oldValue
   const percentDiff = (valuesDiff / oldValue) * 100
   const formatDiff = parseInt(percentDiff.toFixed(2))

   if (Number.isNaN(percentDiff) || !isFinite(percentDiff)) {
      return 0
   }

   return formatDiff
}

export const getColorType = (percent: number): string => {
   switch (true) {
      case percent > 0:
         return 'green'
      case percent < 0:
         return 'red'
      case percent === 0:
         return 'grey'
      default:
         return ''
   }
}

export const getCustomIconByPercent = (percent: number): LabelKey => {
   switch (true) {
      case percent > 0:
         return 'arrowUpPlus'
      case percent < 0:
         return 'arrowDownMinus'
      case percent === 0:
         return 'arrowUpZero'
      default:
         return ''
   }
}

export const capitalizeFirstLetter = (value: string): string => {
   const lowerCaseValue = value.toLowerCase()

   return `${lowerCaseValue[0].toUpperCase()}${lowerCaseValue.slice(1)}`
}

export const getBase64 = (file: any) =>
   new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
   })

export const getPlanPriceByStaffCount = (staffCount: number) => {
   const RANGE_1_THRESHOLD = 2
   const RANGE_2_THRESHOLD = 5
   const RANGE_3_THRESHOLD = 20

   const RANGE_1_PRICE_PER_STAFF = 1.99
   const RANGE_2_PRICE_PER_STAFF = 1.29

   const BASE_PRICE = 25.99
   const MEDIUM_PRICE = 18.99
   const LOWEST_PRICE = 9.99

   switch (true) {
      case staffCount > RANGE_3_THRESHOLD:
         return parseFloat(
            (
               BASE_PRICE +
               RANGE_1_PRICE_PER_STAFF * (RANGE_3_THRESHOLD - RANGE_2_THRESHOLD) +
               RANGE_2_PRICE_PER_STAFF * (staffCount - RANGE_3_THRESHOLD)
            ).toFixed(2),
         )
      case staffCount > 5 && staffCount <= RANGE_3_THRESHOLD:
         return parseFloat((BASE_PRICE + RANGE_1_PRICE_PER_STAFF * (staffCount - RANGE_2_THRESHOLD)).toFixed(2))
      case staffCount === RANGE_1_THRESHOLD:
         return MEDIUM_PRICE
      case staffCount > 1 && staffCount <= RANGE_2_THRESHOLD:
         return BASE_PRICE
      default:
         return LOWEST_PRICE
   }
}

const requestXhr = (method: any, url: string, data: any) =>
   new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest()
      xhr.open(method, url)
      xhr.onload = resolve
      xhr.onerror = reject
      xhr.send(data)
   })

export const uploadFileToS3 = async (upload: any, fileObj: any) => {
   if (upload._alreadyUploaded) {
      return
   }

   const formData = new FormData()
   const s3config = upload?.form?.input

   formData.append('key', s3config.key)
   formData.append('X-Amz-Credential', s3config.xAmzCredential)
   formData.append('X-Amz-Date', s3config.xAmzDate)
   formData.append('X-Amz-Signature', s3config.xAmzSignature)
   formData.append('X-Amz-Algorithm', s3config.xAmzAlgorithm)
   formData.append('Content-Type', 'image/jpeg')
   formData.append('Policy', s3config.policy)
   formData.append('acl', s3config.acl)
   formData.append('file', fileObj)

   return requestXhr('POST', upload?.form?.action, formData)
}
