import { message } from 'antd'
import * as api from 'api'
import { extractOptionsFromList } from 'helpers/app/lookups'
import { action, computed, observable } from 'mobx'

import { staffOptions } from '../components/CalendarFilter/constants'
import * as helpers from './calendar-state.helpers'

export interface ICalendarFilterParams {
   location?: string | null
   staff?: string
   viewType: 'day' | 'week'
   date: Date
   timeOffset: number
}

const initFilter: ICalendarFilterParams = {
   location: null,
   staff: 'all_employees',
   viewType: 'day',
   date: new Date(),
   timeOffset: 15,
}

export class CalendarState {
   @observable filterParams: ICalendarFilterParams = initFilter
   @observable data: any = null
   @observable employees: any[] = []
   @observable locations: any[] = []
   @observable initialized = false
   @observable isLoading = false

   @computed get employeesOptions() {
      return extractOptionsFromList(this.employees, 'EMPLOYEE')
   }

   @computed get locationOptions() {
      return extractOptionsFromList(this.locations, 'LOCATION')
   }

   @computed get filterParamsFormatted() {
      return helpers.getCalendarFilterParams(this.filterParams as any, this.employees)
   }

   @computed get calendarData() {
      return (
         helpers.extractCalendarData(
            this.filterParams.viewType,
            this.filterParams.date,
            this.filterParams.staff,
            this.data,
         ) || []
      )
   }

   @computed get getAlerts() {
      const alerts = this.data?.alerts

      return {
         subscriptionCancelled: alerts?.find((alert: any) => alert.type === 'subscription_cancelled'),
         notificationCancelled: alerts?.find((alert: any) => alert.type === 'notification_cancelled'),
         subscriptionPaymentFailed: alerts?.find((alert: any) => alert.type === 'subscription_payment_failed'),
         notificationPaymentFailed: alerts?.find((alert: any) => alert.type === 'notification_payment_failed'),
      }
   }

   @action
   fetchData = async (isEventSource = false) => {
      if (this.filterParamsFormatted) {
         if (!isEventSource) {
            this.isLoading = true
         }

         try {
            this.data = await api.fetchCalendar(this.filterParamsFormatted)
         } catch (error) {
            console.error(error)
         } finally {
            this.isLoading = false
         }
      } else {
         this.isLoading = false
      }
   }

   @action
   resetInitialization = () => {
      this.initialized = false
   }

   @action
   changeFilter = (field: string) => {
      return async (value: any) => {
         if (field === 'viewType' && value === 'week') {
            const isGroupStaff = staffOptions.some(e => e.code === this.filterParams.staff)
            if (isGroupStaff) {
               this.filterParams.viewType = 'week'
               this.filterParams.staff = this.employees?.[0]?.id
            }
         } else if (field === 'staff' && staffOptions.some(e => e.code === value)) {
            this.filterParams.viewType = 'day'
            this.filterParams.staff = value
         } else if (field === 'location') {
            this.employees = (await api.fetchEmployeesCalendarOptions({ location_id: value }))?.employees || []

            if (
               !this.employees?.some?.(e => e?.id === this.filterParams.staff) &&
               this.filterParams.staff !== 'working_employees'
            ) {
               this.filterParams.staff = 'all_employees'
            }
         }
         ;(this.filterParams as any)[field as any] = value as any

         await this.fetchData()
      }
   }

   @action
   resetFilter = () => {
      this.data = null
      this.filterParams = initFilter
      this.initialized = false
   }

   @action
   initialFetchData = async (context: { timeSlotInterval: number; defaultViewRange: 'day' | 'week' }) => {
      this.isLoading = true

      if (!this.initialized) {
         const locations = (await api.fetchLocationsCalendarOptions())?.locations || []
         this.locations = locations
         this.filterParams.location = locations?.[0]?.id
         this.employees =
            (await api.fetchEmployeesCalendarOptions({ location_id: this.filterParams.location }))?.employees || []
         let data = await api.fetchCalendar(this.filterParamsFormatted)

         if (!data) {
            message.error('Failed to get data for calendar, please contact support.')
            return
         }

         this.filterParams.timeOffset = context.timeSlotInterval
         this.filterParams.viewType = context.defaultViewRange

         if (context.defaultViewRange === 'week') {
            this.filterParams.staff = data?.employees?.[0]?.id
            data = await api.fetchCalendar(this.filterParamsFormatted)
         }
         this.isLoading = false
         this.data = data
         this.initialized = true
      } else {
         this.locations = (await api.fetchLocationsCalendarOptions())?.locations || []
         this.employees =
            (await api.fetchEmployeesCalendarOptions({ location_id: this.filterParams.location }))?.employees || []
         await this.fetchData()
      }
   }
}

export default new CalendarState()
