import {
   Button,
   composeValidators,
   countryOptions,
   currencyOptions,
   customRequired,
   Field,
   Form,
   timeZoneOptions,
   validateEmail,
   validatePassword,
   validateRequired,
} from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { passwordTooltip } from 'constants/form/general'
import React from 'react'

interface RegisterFormProps {
   onSubmit?: any
   onSuccess?: any
   initialValues?: any
   isLoading?: any
}

export default function RegisterForm(props: RegisterFormProps) {
   return (
      <Form
         onSubmit={props.onSubmit}
         excludeList={['agreement']}
         onSuccess={props.onSuccess}
         initialValues={{ ...(props?.initialValues ?? {}) }}
         keepDirtyOnReinitialize>
         {({ submitting, formError }: any) => (
            <>
               <Field.Row>
                  <Field.Input
                     name="employeeFirstName"
                     label="input.firstName.label"
                     placeholder="input.firstName.placeholder"
                     md={12}
                     submitting={submitting}
                     validate={validateRequired}
                  />
                  <Field.Input
                     name="employeeLastName"
                     label="input.lastName.label"
                     placeholder="input.lastName.placeholder"
                     md={12}
                     submitting={submitting}
                  />
               </Field.Row>
               <Field.Input
                  name="employeeEmail"
                  label="input.email.label"
                  placeholder="input.email.placeholder"
                  validate={composeValidators(validateRequired, validateEmail)}
                  submitting={submitting}
               />
               <Field.Input
                  name="employeePassword"
                  label="input.password.label"
                  placeholder="input.password.placeholder"
                  type="password"
                  tooltip={passwordTooltip}
                  validate={composeValidators(validateRequired, validatePassword)}
                  submitting={submitting}
               />
               <Field.Input
                  name="companyName"
                  label="input.company.label"
                  placeholder="input.company.placeholder"
                  validate={validateRequired}
                  submitting={submitting}
               />
               <Field.Select
                  name="companyCountryIsoCode"
                  id="companyCountryIsoCode"
                  label="input.country.label"
                  placeholder="input.country.placeholder"
                  validate={validateRequired}
                  submitting={submitting}
                  options={countryOptions}
                  loading={props?.isLoading}
               />
               <Field.Row>
                  <Field.Select
                     name="companyTimeZone"
                     md={12}
                     label="input.timeZone.label"
                     placeholder="input.timeZone.placeholder"
                     validate={validateRequired}
                     submitting={submitting}
                     options={timeZoneOptions}
                     loading={props?.isLoading}
                  />
                  <Field.Select
                     name="companyCurrencyIsoCode"
                     md={12}
                     label="input.currency.label"
                     placeholder="input.currency.placeholder"
                     validate={validateRequired}
                     submitting={submitting}
                     options={currencyOptions}
                     loading={props?.isLoading}
                  />
               </Field.Row>
               <Field.Checkbox
                  name="agreement"
                  type="checkbox"
                  validate={customRequired('register.validation.confirm')}>
                  <IntlLabel label="register.agreement.agree" />
                  <a href="https://plandok.com/terms" target="_blank" rel="noopener noreferrer">
                     <IntlLabel label="register.agreement.terms" />
                  </a>
                  ,
                  <a href="https://plandok.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                     <IntlLabel label="register.agreement.policy" />
                  </a>
               </Field.Checkbox>
               <Form.Alert text={formError} />
               <Button type="primary" block htmlType="submit" loading={submitting} label="register.btn" />
            </>
         )}
      </Form>
   )
}
