import { useAppContext, useNavigation } from '@plandok/core'
import { Layout } from 'antd'
import * as api from 'api'
import { RoutePath } from 'constants/routes'
import A2HSProvider from 'pages/dashboard/CalendarPage/components/A2HSProvider'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useStore } from 'store/store'

import Sidebar from './components/Sidebar'

function ApplicationLayout(props: any) {
   const [state, { setAppContext }] = useAppContext()

   let path: any = window.location.pathname
   const { params } = useNavigation()

   const setLocationId = useStore(state => state.setLocationId)
   const setLocations = useStore(state => state.setLocations)
   const setCalendarState = useStore(state => state.setCalendarState)

   let hideSidebar = [
      RoutePath.SEARCH,
      RoutePath.MESSAGE_CREATE,
      RoutePath.CAMPAIGN_SETUP,
      RoutePath.START_CAMPAIGN_PAGE,
      RoutePath.EMAIL_MESSAGE_CREATE,

      RoutePath.BIRTHDAY_CAMPAIGN_SETTING,
      RoutePath.WIN_BACK_CLIENTS_SETTING,
      RoutePath.WELCOME_NEW_CLIENTS_SETTING,
      RoutePath.REWARD_REGULAR_CLIENTS_SETTING,

      RoutePath.CLIENT_CREATE,
      RoutePath.SERVICE_CREATE,
      RoutePath.STAFF_CREATE,
      RoutePath.APPOINTMENT_CREATE,
      `${RoutePath.CLIENT_EDIT}/${params.id}`,
      `${RoutePath.SERVICE_EDIT}/${params.id}`,
      `${RoutePath.STAFF_UPDATE}/${params.id}`,
      `${RoutePath.APPOINTMENT_UPDATE}/${params.id}`,
      `${RoutePath.APPOINTMENT_DETAILS}/${params.id}`,
      `${RoutePath.BOOKING_CREATE}/${params.locationId}`,
   ].includes(path)

   useEffect(() => {
      setAppContext({ isLoading: true })

      api.fetchApplicationContext().then(res => {
         setAppContext({ ...res.data, isAuthenticated: true })

         setCalendarState({
            viewType: res.data.defaultViewRange,
            timeOffset: res.data.timeSlotInterval,
         })
      })

      api.fetchLocationsCalendarOptions()
         .then(res => {
            setLocationId(res?.data[0].id)
            setLocations(res?.data)
         })
         .finally(() => setAppContext({ isLoading: false }))

      return () => {}
   }, [state.refreshContext])

   return (
      <Layout id="main-layout" className={props.className}>
         {!hideSidebar && <Sidebar disableShade={props.disableSidebarShade} />}
         <Layout>
            <A2HSProvider>
               <Outlet />
            </A2HSProvider>
         </Layout>
      </Layout>
   )
}

export default ApplicationLayout
