import styled from 'styled-components'

export const NoDataContainer = styled.div`
   display: grid;
   place-content: center;
   height: 10rem;
   color: #13316d;
   white-space: pre-line;
   text-align: center;
`
