import { mediaLgDown } from '@plandok/core'
import styled, { css } from 'styled-components'

import { PriceType } from './constants'

export const PricingItem = styled.div<any>`
   width: 358px;
   background: white;
   display: flex;
   flex-direction: column;
   background: #ffffff 0% 0% no-repeat padding-box;
   border-top: ${p => (p.active ? '3px solid #13316D' : '1px solid #c1c4c8')};
   border-left: ${p => (p.active ? '3px solid #13316D' : '1px solid #c1c4c8')};
   border-bottom: ${p => (p.active ? '3px solid #13316D' : '1px solid #c1c4c8')};
   ${p => (p.active ? 'border-right: 3px solid #13316D !important' : 'border-right: none')};
   padding: 40px 20px 20px 20px;
   text-align: center;

   &:last-of-type {
      border-right: 1px solid #c1c4c8;
   }

   p[data-pricing-main-name] {
      color: #13316d;
      font-size: 28px;
   }

   button {
      width: 100%;
      max-width: 180px;
   }

   [data-pricing-current-plan] b {
      height: 42px;
      font-weight: bold;
      font-size: 18px;
      color: #13316d;
   }

   [data-pricing-current-plan-mobile] {
      display: none;
   }

   p[data-pricing-main-price] {
      p:last-of-type {
         margin-top: -10px;
         color: #13316d;
         font-size: 12px;
         font-weight: 300;
      }
      p:first-of-type,
      p[data-pricing-main-price-free] {
         color: #13316d;
         font-size: 36px;
         font-weight: bold;
         margin-bottom: 0;
         &[data-pricing-main-price-free] {
            margin-bottom: 55px;
         }
      }
   }

   p {
      font-size: 18px;
      color: #0a1c43;
      font-weight: 300;

      &[data-pricing-title-description] {
         margin-top: 40px;
         margin-bottom: 10px;
      }
   }

   div[role='separator'] {
      margin: 5px 0;
   }

   img {
      margin-bottom: 50px;
   }

   ${mediaLgDown(css`
      width: 100%;
      padding: 28px;
      flex-direction: row;
      margin-bottom: 40px;
      border-right: 1px solid #c1c4c8;
      border-radius: 2px;

      img {
         display: none;
      }
      div[data-pricing-info-column] {
         width: 100%;
         &:first-of-type {
            display: flex;
            flex-direction: column;
            align-items: center;
         }
      }

      [data-pricing-current-plan] {
         display: none;
      }

      p[data-pricing-title-description] {
         margin-top: 0;
      }

      [data-pricing-current-plan-mobile] {
         margin-top: 5px;
         display: inline;
      }
   `)}
`

export const PricingList = styled.div`
   display: flex;
   justify-content: center;
   margin: 54px auto 40px;

   ${mediaLgDown(css`
      margin: 32px 6px 22px;
      flex-direction: column;
      align-items: center;
   `)}
`

export const ActivePlanIndicator = styled.div`
   display: flex;
   text-align: center;
   justify-content: center;
   margin-bottom: 8px;
   align-items: center;
   margin-top: ${({ theme }) => (theme.planType === PriceType.FREE ? '65px' : '-28px')};

   div:nth-child(1) {
      border: 1px solid #2472e8;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
         stroke: #2472e8;
      }
   }

   div:nth-child(2) {
      line-height: 21px;
   }

   ${mediaLgDown(css`
      margin-top: ${({ theme }) => (theme.planType === PriceType.FREE ? '0' : '30px')};
   `)}
`
