import { useNavigation } from '@plandok/core'
import * as api from 'api'
import { ModifyLayout, NoInternetConnection } from 'components'
import { RoutePath } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import ServiceForm from './components/ServiceForm'

export const ServiceFormWithApi = withFormApi({
   editAction: api.editService,
   detailsAction: api.fetchServiceDetails,
   createAction: api.createService,
   backPath: RoutePath.SERVICES,
   successMessage: 'Service Created',
})(ServiceForm)

export default function ModifyServicePage() {
   const { params, urlQuery } = useNavigation()
   let query = urlQuery()

   const serviceGroupId = query.serviceGroupId
   const isEdit = params && params.id

   return (
      <NoInternetConnection>
         <ModifyLayout title={isEdit ? 'service.edit.title' : 'service.new.title'}>
            <ServiceFormWithApi initialValues={{ duration: 60, serviceGroupId, isBookableOnline: true }} />
         </ModifyLayout>
      </NoInternetConnection>
   )
}
