import { css } from 'styled-components'
import { getIsMobile } from '../screen'

export const BreakPoint = {
   XS: 0,
   SM: 600,
   MD: 768,
   LG: 992,
   XL: 1200,
}

const mediaBreakpointUp = (size: any) => (styles: any) =>
   css`
      @media screen and (min-width: ${size}px) {
         ${styles};
      }
   ` as any

const mediaBreakpointDown = (size: any) => (styles: any) =>
   css`
      @media screen and (max-width: ${size - 1}px) {
         ${styles};
      }
   ` as any

export const mediaBreakpointBetweenAny = (min: any, max: any) => (styles: any) => css`
   @media screen and (min-width: ${min}px) and (max-width: ${max - 1}px) {
      ${styles};
   }
`

const isMobile = getIsMobile()

export const mediaMobileDown = (styles: any) => mediaBreakpointDown(isMobile ? BreakPoint.XL : BreakPoint.MD)(styles)

export const mediaXsUp = (styles: any) => styles
export const mediaSmUp = (styles: any) => mediaBreakpointUp(BreakPoint.SM)(styles)
export const mediaMdUp = (styles: any) => mediaBreakpointUp(isMobile ? BreakPoint.XL : BreakPoint.MD)(styles)
export const mediaMdUpMobily = (styles: any) => mediaBreakpointUp(isMobile ? 1800 : BreakPoint.MD)(styles)
export const mediaLgUp = (styles: any) => mediaBreakpointUp(BreakPoint.LG)(styles)
export const mediaXlUp = (styles: any) => mediaBreakpointUp(BreakPoint.XL)(styles)

export const mediaXsDown = (styles: any) => mediaBreakpointDown(BreakPoint.XS)(styles)
export const mediaSmDown = (styles: any) => mediaBreakpointDown(BreakPoint.SM)(styles)
export const mediaMdDown = (styles: any) => mediaBreakpointDown(BreakPoint.MD)(styles)
export const mediaLgDown = (styles: any) => mediaBreakpointDown(BreakPoint.LG)(styles)
export const mediaXlDown = (styles: any) => mediaBreakpointDown(BreakPoint.XL)(styles)

export const mediaMdUpOrMobile = (styles: any) => (props: any) => {
   return props.isMobile
      ? css`
           ${styles}
        `
      : mediaBreakpointUp(BreakPoint.MD)(styles)
}
export const mediaMobileDownOrMobile = (styles: any) => (props: any) => {
   return props.isMobile
      ? css`
           ${styles}
        `
      : mediaBreakpointDown(BreakPoint.MD)(styles)
}

export const mediaXsOnly = (styles: any) => mediaBreakpointDown(BreakPoint.SM)(styles)
export const mediaSmOnly = (styles: any) => mediaBreakpointBetweenAny(BreakPoint.SM, BreakPoint.MD)(styles)
export const mediaMdOnly = (styles: any) => mediaBreakpointBetweenAny(BreakPoint.MD, BreakPoint.LG)(styles)
export const mediaLgOnly = (styles: any) => mediaBreakpointBetweenAny(BreakPoint.LG, BreakPoint.XL)(styles)
export const mediaXlOnly = (styles: any) => mediaBreakpointUp(BreakPoint.XL)(styles)

export const mediaBetween = (min: any, max: any) => (styles: any) => mediaBreakpointBetweenAny(min, max)(styles)

export const cutText = css`
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
`
