import { Tooltip } from 'antd'
import MenuContext from 'antd/lib/menu/MenuContext'
import { TooltipProps } from 'antd/lib/tooltip'
// @ts-ignore
import { Item } from 'rc-menu'
import React, { useContext, useRef } from 'react'

/**
 * This component is a copy of the Ant Design component
 * since Ant Design does not provide an API to override tooltip behavior.
 */
const MenuItem: React.FC<any> = props => {
   const menuItemRef = useRef<any>(null)
   const menuContext = useContext(MenuContext)

   const onKeyDown = (e: React.MouseEvent<HTMLElement>) => {
      menuItemRef.current?.onKeyDown(e)
   }

   const { level, children, rootPrefixCls, title, siderCollapsed, ...rest } = props

   const tooltipProps: TooltipProps = {
      title: title || (level === 1 ? children : ''),
      mouseLeaveDelay: 0,
      mouseEnterDelay: 0,
   }

   if (!siderCollapsed && !menuContext?.inlineCollapsed) {
      tooltipProps.title = null
      tooltipProps.visible = false // Reset `visible` for control mode tooltip display.
   }

   return (
      <Tooltip {...tooltipProps} placement="right" overlayClassName={`${rootPrefixCls}-inline-collapsed-tooltip`}>
         <Item {...rest} ref={menuItemRef} onKeyDown={onKeyDown}>
            {children}
         </Item>
      </Tooltip>
   )
}

// MenuItem.isMenuItem = true;

export default MenuItem
