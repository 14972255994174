import { Language } from '@plandok/i18n'
import { LogoutPage, PrivateRoute, PublicRoute } from 'components'
import { RoutePath } from 'constants/routes'
import LoginPage from 'pages/auth/LoginPage'
import RegistrationPage from 'pages/auth/RegistrationPage'
import ResetPasswordPage from 'pages/auth/ResetPasswordPage'
import SetNewPasswordPage from 'pages/auth/SetNewPasswordPage'
import PaymentConfirmationPage from 'pages/plans/PaymentConfirmationPage'
import Page404 from 'pages/support/Page404'
import PlansPage from 'pages/support/PlansPage'
import PricingCalculationPage from 'pages/support/PricingCalculationPage'
import React from 'react'

export const publicRoute = (isAuthenticated: boolean) => {
   return [
      {
         path: RoutePath.NEW_PRICING,
         element: <PublicRoute isAuthenticated={false} appComponent={PricingCalculationPage} />,
      },
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.NEW_PRICING}`,
         element: <PublicRoute isAuthenticated={false} appComponent={PricingCalculationPage} />,
      })),
      {
         path: RoutePath.PLANS,
         element: <PublicRoute isAuthenticated={false} appComponent={PlansPage} />,
      },
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.PLANS}`,
         element: <PublicRoute isAuthenticated={false} appComponent={PlansPage} />,
      })),
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.LOGIN}`,
         element: <PublicRoute isAuthenticated={isAuthenticated} appComponent={LoginPage} />,
      })),
      {
         path: RoutePath.LOGIN,
         element: <PublicRoute isAuthenticated={isAuthenticated} appComponent={LoginPage} />,
      },
      { path: RoutePath.LOGOUT, element: <LogoutPage /> },
      {
         path: '/test_error_reports_page',
         element: (
            <PublicRoute
               isAuthenticated={isAuthenticated}
               appComponent={() => {
                  throw new Error('This page is to test app crash and how error reports work.')
                  return null // eslint-disable-line
               }}
            />
         ),
      },
      {
         path: '/test_error_reports_page_2',
         element: (
            <PrivateRoute
               isAuthenticated={isAuthenticated}
               path="/test_error_reports_page_2"
               appComponent={() => {
                  throw new Error('This page is to test app crash and how error reports work.')
                  return null // eslint-disable-line
               }}
            />
         ),
      },
      {
         path: '*',
         element: <Page404 />,
      },
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.RESET_PASSWORD}`,
         element: <PublicRoute isAuthenticated={isAuthenticated} appComponent={ResetPasswordPage} />,
      })),
      {
         path: RoutePath.RESET_PASSWORD,
         element: <PublicRoute isAuthenticated={isAuthenticated} appComponent={ResetPasswordPage} />,
      },

      {
         path: `${RoutePath.CREATE_PASSWORD}/:email/:token`,
         element: <SetNewPasswordPage />,
      },
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.CREATE_PASSWORD}/:email/:token`,
         element: <SetNewPasswordPage />,
      })),
      {
         path: `${RoutePath.RESET_PASSWORD_CONFIRMATION}/:email/:token`,
         element: <SetNewPasswordPage />,
      },
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.RESET_PASSWORD_CONFIRMATION}/:email/:token`,
         element: <SetNewPasswordPage />,
      })),

      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.REGISTRATION}`,
         element: <PublicRoute isAuthenticated={isAuthenticated} appComponent={RegistrationPage} />,
      })),
      {
         path: RoutePath.REGISTRATION,
         element: <PublicRoute isAuthenticated={isAuthenticated} appComponent={RegistrationPage} />,
      },

      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.PAYMENT_CONFIRMATION}`,
         element: <PaymentConfirmationPage />,
      })),
      {
         path: RoutePath.PAYMENT_CONFIRMATION,
         element: <PaymentConfirmationPage />,
      },
   ]
}
