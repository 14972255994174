import { Button } from '@plandok/core'
import styled, { css } from 'styled-components'

type UndoProps = {
   isMobile: boolean
}

export const BtnShowAllTimes = styled(Button)<UndoProps>`
   z-index: 4;
   position: fixed;
   left: 50%;
   bottom: 0.5rem;
   transform: translate(-50%);
   border: 0.5px solid #adb6cc !important;
   box-shadow: 0px 4px 12px -3px rgba(38, 60, 122, 0.2) !important;
   color: #2472e8 !important;

   ${({ isMobile }) =>
      isMobile &&
      css`
         bottom: 5rem;
      `}
`
