import { Button } from '@plandok/core'
import styled from 'styled-components'

export const Container = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
`

export const CalendarIcon = styled.div`
   position: relative;
   text-align: center;
   height: 42px;
   user-select: none;
   i {
      font-size: 24px;
   }
   [data-today-date] {
      text-align: center;
      position: absolute;
      user-select: none;
      top: -4px;
      font-size: 11px;
      left: 4px;
   }

   &:hover,
   &:focus,
   &:active {
      opacity: 0.8;
   }
`

export const CalendarDate = styled.div`
   i {
      font-size: 9px;
      margin-left: 3px;
   }
`

export const DatePickerButton = styled(Button)`
   color: #14316d !important;
   background-color: white;
   @media screen and (max-width: 340px) {
      background-color: transparent;
   }
`

const SharedArrowIconButton = styled(Button)`
   min-width: 42px;
   @media screen and (max-width: 340px) {
      display: none;
   }
`

export const LeftIconButton = styled(SharedArrowIconButton)`
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
`

export const RightIconButton = styled(SharedArrowIconButton)`
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
`

export const DateFilterContainer = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
   justify-content: center;
   align-items: center;

   .filter-datepicker {
      border-radius: 0;
   }
`
