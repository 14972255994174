import '../../styles/styles.less'

import { Button, useModal, withModal } from '@plandok/core'
import { IntlLabel, IntlTemplate } from '@plandok/i18n'
import { Spin } from 'antd'
import * as api from 'api'
import { App_Client } from 'graphql/generatedTypes'
import intersection from 'lodash/intersection'
import React, { useEffect, useState } from 'react'

import * as SC from '../../styles'
import { ClientsForMerge } from '../../types/types'

const useGetDuplicatedClients = () => {
   const [duplicatedClients, setDuplicatedClients] = useState<App_Client[][]>([])
   const [isLoading, setIsLoading] = useState<boolean>(false)
   const [mode, setMode] = useState<string>('first_name')

   useEffect(() => {
      setIsLoading(true)
      ;(async () => {
         try {
            const { data } = await api.fetchDuplicatedClients('', mode)
            setDuplicatedClients(data)
         } catch (e) {
            console.error('Error when getting duplicate clients')
         } finally {
            setIsLoading(false)
         }
      })()
   }, [mode])

   return { duplicatedClients, setDuplicatedClients, mode, setMode, isLoading }
}

type ModalProps = {
   checkAction: () => void
}

type MergeDuplicateClientsModalProps = {
   modalProps: ModalProps
   onClose: () => void
}

function MergeDuplicateClientsModal({ modalProps, onClose }: MergeDuplicateClientsModalProps) {
   const { duplicatedClients, setDuplicatedClients, mode, setMode, isLoading } = useGetDuplicatedClients()

   const [сlientsForMerge, setClientsForMerge] = useState<ClientsForMerge[]>([])

   const [, { showModal }] = useModal()

   const openConfirmMergeModal = () => {
      showModal({
         type: 'CONFIRM_MERGE_DUPLICATED_CLIENTS',
         modalProps: { сlientsForMerge, setAction: setDuplicatedClients, ...modalProps },
      })
      onClose()
   }

   const clientsСount = duplicatedClients.reduce((acc, clients) => acc + clients.length, 0)
   const clientsCountToShow = [`(${duplicatedClients.length && clientsСount})`]
   const allSelected = duplicatedClients.length === сlientsForMerge.length
   const btnLabel = duplicatedClients.length ? 'clients.merge.btn.title' : 'btn.cancel'
   const partial = Boolean(
      !allSelected &&
         (intersection(duplicatedClients.map(clients => clients[0].id)),
         сlientsForMerge.map(({ root: rootId }) => rootId)).length,
   )

   const toggleSelectAll = () => {
      const allClients: ClientsForMerge[] = []

      duplicatedClients.forEach((clients: App_Client[]) =>
         allClients.push({
            root: clients[0].id,
            rest: clients.slice(1).map(({ id }) => id),
         }),
      )

      setClientsForMerge(allSelected ? [] : allClients)
   }

   const onClick = () =>
      duplicatedClients.length
         ? openConfirmMergeModal()
         : () => {
              setClientsForMerge([])
              onClose()
           }

   return (
      <Spin spinning={isLoading}>
         <SC.ClientMergeButtonsContainer>
            <Button
               className={mode === 'phone_number' ? 'active-button-merge-clients' : 'button-merge-clients'}
               upperCase={false}
               onClick={() => {
                  setClientsForMerge([])
                  setMode('phone_number')
               }}>
               <IntlTemplate
                  label="clients.byNumber.btn.title"
                  params={mode === 'phone_number' ? clientsCountToShow : ['']}
               />
            </Button>
            <Button
               className={mode === 'email' ? 'active-button-merge-clients' : 'button-merge-clients'}
               upperCase={false}
               onClick={() => {
                  setClientsForMerge([])
                  setMode('email')
               }}>
               <IntlTemplate label="clients.byEmail.btn.title" params={mode === 'email' ? clientsCountToShow : ['']} />
            </Button>
            <Button
               className={mode === 'first_name' ? 'active-button-merge-clients' : 'button-merge-clients'}
               upperCase={false}
               onClick={() => {
                  setClientsForMerge([])
                  setMode('first_name')
               }}>
               <IntlTemplate
                  label="clients.byName.btn.title"
                  params={mode === 'first_name' ? clientsCountToShow : ['']}
               />
            </Button>
         </SC.ClientMergeButtonsContainer>

         {duplicatedClients.length ? (
            <SC.StyledCheckboxWrapper>
               <SC.StyledCheckbox checked={allSelected} indeterminate={partial} onChange={toggleSelectAll}>
                  <IntlLabel label="clients.merge.selectAll.title" />
               </SC.StyledCheckbox>
            </SC.StyledCheckboxWrapper>
         ) : null}

         {/*{duplicatedClients.length ? (*/}
         {/*  <SC.DuplicatedClientsCardWrapper>*/}
         {/*    {duplicatedClients.map((сlients, index) => (*/}
         {/*      <div key={index}>*/}
         {/*        <div>*/}
         {/*          <GroupClientsCheckbox*/}
         {/*            сlients={сlients}*/}
         {/*            сlientsForMerge={сlientsForMerge}*/}
         {/*            setClientsForMerge={setClientsForMerge}*/}
         {/*          />*/}
         {/*        </div>*/}
         {/*        <SC.DuplicatedClientsContainer>*/}
         {/*          {сlients.map((client) => (*/}
         {/*            <div className="client-container" key={client.id}>*/}
         {/*              {client.firstName && <Avatar>{client.firstName.substring(0, 1).toUpperCase()}</Avatar>}*/}
         {/*              <div>*/}
         {/*                <div>{`${client.firstName} ${client.lastName || ''}`}</div>*/}
         {/*                <div>{client.email}</div>*/}
         {/*                <div>{client.phoneNumber}</div>*/}
         {/*              </div>*/}
         {/*            </div>*/}
         {/*          ))}*/}
         {/*        </SC.DuplicatedClientsContainer>*/}
         {/*      </div>*/}
         {/*    ))}*/}
         {/*  </SC.DuplicatedClientsCardWrapper>*/}
         {/*) : (*/}
         {/*  <SC.DuplicatedClientsNotFoundWrapper>*/}
         {/*    <div>*/}
         {/*      <CustomIcon type="duplicateContactsNotFound" />*/}
         {/*      <Text*/}
         {/*        size="mlarge"*/}
         {/*        colorType="base"*/}
         {/*        mb="xsmall"*/}
         {/*        weight="medium"*/}
         {/*        label="clients.merge.duplicatesNotFound.title"*/}
         {/*      />*/}
         {/*      <Text*/}
         {/*        size="base"*/}
         {/*        colorType="base"*/}
         {/*        mb="none"*/}
         {/*        weight="normal"*/}
         {/*        label="clients.merge.duplicatesNotFound.description"*/}
         {/*      />*/}
         {/*    </div>*/}
         {/*  </SC.DuplicatedClientsNotFoundWrapper>*/}
         {/*)}*/}

         <SC.ModalFooterDuplicatedClients>
            <Button label={btnLabel} type="primary" upperCase={false} onClick={onClick} />
         </SC.ModalFooterDuplicatedClients>
      </Spin>
   )
}

export default withModal('clients.merge.modal.title', { contentClassName: 'duplicated-clients-modal' })(
   MergeDuplicateClientsModal as any,
)
