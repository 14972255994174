import {
   Button,
   composeValidators,
   CustomIcon,
   Field,
   Form,
   IconButton,
   Text,
   useLocation,
   validateEmail,
   validateOneOfTheRequiredFiles,
   validateRequired,
} from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { message, Spin } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import {
   appointmentBookingsLimitOptions,
   appointmentChangeTimeSettingOptions,
   earliestAppointmentSettingOptions,
   latestAppointmentSettingOptions,
   timeSlotIntervalSettingOptions,
} from 'constants/data'
import { useOnlineBookingSettingsQuery } from 'graphql/queries/onlineBookingSettings.generated'
import { pick } from 'lodash'
import React, { useEffect, useState } from 'react'
import slugify from 'react-slugify'

import { mutate, mutation } from '../../../../graphql'
import * as SC from './styles'
import { BookingSettingsForm } from './types'

type FieldType = 'FIRST_NAME' | 'LAST_NAME' | 'PHONE_NUMBER' | 'EMAIL' | 'NOTES'

const getOptionsByField = (fieldName: FieldType) => [
   {
      value: fieldName,
      label: <Text colorType="base" weight="normal" size="small" mb="none" label="ob.settings.requiredField.title" />,
   },
   {
      value: `${fieldName}-optional`,
      label: <Text colorType="base" weight="normal" size="small" mb="none" label="ob.settings.optionalField.title" />,
   },
   {
      value: `${fieldName}-hidden`,
      label: <Text colorType="base" weight="normal" size="small" mb="none" label="Hidden" />,
   },
]

export default function BookingSettings() {
   const { loading, data } = useOnlineBookingSettingsQuery()
   const { goBack } = useLocation()

   const serviceLimit = data?.onlineBookingSettings?.serviceLimit || 0

   const [isClickedOnHidden, setIsClickedOnHidden] = useState(false)
   const [isDisplayWarning, setIsDisplayWarning] = useState(false)
   const [fieldsInfo, setFieldsInfo] = useState({ firstName: '', lastName: '', email: '', phoneNumber: '', notes: '' })

   const changeFirstName = ({ target: { value } }: RadioChangeEvent) =>
      setFieldsInfo(prevState => ({ ...prevState, firstName: value }))
   const changeLastName = ({ target: { value } }: RadioChangeEvent) =>
      setFieldsInfo(prevState => ({ ...prevState, lastName: value }))
   const changePhoneNumber = ({ target: { value } }: RadioChangeEvent) =>
      setFieldsInfo(prevState => ({ ...prevState, phoneNumber: value }))
   const changeEmail = ({ target: { value } }: RadioChangeEvent) =>
      setFieldsInfo(prevState => ({ ...prevState, email: value }))
   const changeNotes = ({ target: { value } }: RadioChangeEvent) =>
      setFieldsInfo(prevState => ({ ...prevState, notes: value }))

   const onSubmit = (form: BookingSettingsForm) => {
      const onlineRequiredField = Object.values(fieldsInfo).filter((fieldName: string) =>
         ['FIRST_NAME', 'LAST_NAME', 'PHONE_NUMBER', 'EMAIL', 'NOTES'].includes(fieldName),
      )

      const prepareOnlineOptionalField = Object.values(fieldsInfo)
         .filter((fieldName: string) => fieldName.endsWith('-optional'))
         .slice(-9)

      const prepareOnlineHiddenField = Object.values(fieldsInfo)
         .filter((fieldName: string) => fieldName.endsWith('-hidden'))
         .slice(-7)

      const onlineOptionalField = prepareOnlineOptionalField.map((fieldName: string) =>
         fieldName.substring(0, fieldName.length - 9),
      )

      const onlineHiddenField = prepareOnlineHiddenField.map((fieldName: string) =>
         fieldName.substring(0, fieldName.length - 7),
      )

      return mutate(mutation.UPDATE_BOOKING_SETTINGS)({
         ...pick(
            form,
            'earliestAppointment',
            'latestAppointment',
            'timeSlotInterval',
            'allowEmployeeSelection',
            'importantInformation',
            'appointmentChangeTime',
            'notifyBookedEmployee',
            'notifySpecificEmail',
            'notificationSpecificEmail',
            'partnerSlug',
            'serviceLimit',
            'placeholderNotes',
         ),
         onlineRequiredField,
         onlineOptionalField,
         onlineHiddenField,
      })
   }

   const onSuccess = () => message.success(<IntlLabel label="notification.success.update" />)

   useEffect(() => {
      const { onlineRequiredField, onlineOptionalField, onlineHiddenField } = data?.onlineBookingSettings || {}

      const setFields = (fieldName: string, fields: string[]) => fields.find((field: string) => field === fieldName)

      const checkField = (fieldName: string) =>
         setFields(fieldName, onlineRequiredField || []) ||
         (setFields(fieldName, onlineOptionalField || []) && `${fieldName}-optional`) ||
         (setFields(fieldName, onlineHiddenField || []) && `${fieldName}-hidden`) ||
         `${fieldName}`

      setFieldsInfo({
         firstName: checkField('FIRST_NAME'),
         lastName: checkField('LAST_NAME'),
         email: checkField('EMAIL'),
         phoneNumber: checkField('PHONE_NUMBER'),
         notes: checkField('NOTES'),
      })
   }, [data])

   const onClose = () => {
      setIsDisplayWarning(false)
      setIsClickedOnHidden(true)
   }

   return (
      <Spin spinning={loading}>
         <SC.Container>
            <Form
               onSubmit={onSubmit}
               onSuccess={onSuccess}
               initialValues={{ ...data?.onlineBookingSettings, serviceLimit }}
               validate={() =>
                  validateOneOfTheRequiredFiles(
                     Object.values(fieldsInfo),
                     ['FIRST_NAME', 'LAST_NAME', 'PHONE_NUMBER', 'EMAIL'],
                     setIsDisplayWarning,
                     isClickedOnHidden,
                  )
               }>
               {({ submitting, values }: any) => (
                  <>
                     <SC.FormInfoContainer>
                        <Text label="ob.settings.booking.title" weight="semiBold" size="large" mb="small" />
                        <Text label="ob.settings.booking.description" size="small" mb="none" color="#61749D" />
                     </SC.FormInfoContainer>
                     <SC.Card>
                        <Field.Select
                           name="earliestAppointment"
                           label="input.earliestAppointment.label"
                           placeholder="input.earliestAppointment.placeholder"
                           options={earliestAppointmentSettingOptions}
                           translate
                        />
                        <Field.Select
                           name="latestAppointment"
                           label="input.latestAppointment.label"
                           placeholder="input.latestAppointment.placeholder"
                           options={latestAppointmentSettingOptions}
                           translate
                        />
                        <Field.Select
                           name="timeSlotInterval"
                           label="input.timeSlots.label"
                           placeholder="input.timeSlots.placeholder"
                           options={timeSlotIntervalSettingOptions}
                           translate
                        />
                        <Field.Checkbox
                           name="allowEmployeeSelection"
                           textKey="checkbox.allowCustomers"
                           type="checkbox"
                        />
                        <Field.TextArea
                           name="importantInformation"
                           label="input.importantInfo.label"
                           placeholder="input.importantInfo.placeholder"
                        />
                     </SC.Card>

                     <SC.FormInfoContainer>
                        <Text label="ob.settings.cancellation.title" weight="semiBold" size="large" mb="small" />
                        <Text label="ob.settings.cancellation.description" size="small" mb="none" color="#61749D" />
                     </SC.FormInfoContainer>
                     <SC.Card>
                        <Field.Select
                           name="appointmentChangeTime"
                           label="input.changeAppointment.label"
                           placeholder="input.changeAppointment.placeholder"
                           options={appointmentChangeTimeSettingOptions}
                           translate
                        />
                     </SC.Card>

                     <SC.FormInfoContainer>
                        <Text label="ob.settings.required.title" weight="semiBold" size="large" mb="small" />
                        <Text label="ob.settings.required.description" size="small" mb="none" color="#61749D" />
                     </SC.FormInfoContainer>
                     <SC.Card>
                        {isDisplayWarning && (
                           <SC.WarningWrapper>
                              <CustomIcon type="infoСircle" />
                              <div>
                                 <Text
                                    label="ob.settings.requiredField.notification.title"
                                    weight="semiBold"
                                    size="small"
                                 />
                                 <Text
                                    label="ob.settings.requiredField.notification.description"
                                    size="small"
                                    mb="none"
                                 />
                              </div>
                              <IconButton icon="close" onClick={onClose} size="20px" />
                           </SC.WarningWrapper>
                        )}

                        <SC.RadioRow>
                           <SC.RadioTitle
                              colorType="base"
                              weight="semiBold"
                              size="small"
                              mb="none"
                              label="ob.settings.nameField.title"
                           />

                           <SC.Radio.Group
                              disabled={true}
                              onChange={changeFirstName}
                              options={getOptionsByField('FIRST_NAME')}
                              value={fieldsInfo.firstName}
                           />
                        </SC.RadioRow>

                        <SC.RadioRow>
                           <SC.RadioTitle
                              colorType="base"
                              weight="semiBold"
                              size="small"
                              mb="none"
                              label="ob.settings.lastNameField.title"
                           />

                           <SC.Radio.Group
                              onChange={changeLastName}
                              options={getOptionsByField('LAST_NAME')}
                              value={fieldsInfo.lastName}
                           />
                        </SC.RadioRow>

                        <SC.RadioRow>
                           <SC.RadioTitle
                              colorType="base"
                              weight="semiBold"
                              size="small"
                              mb="none"
                              label="ob.settings.phoneField.title"
                           />

                           <SC.Radio.Group
                              onChange={changePhoneNumber}
                              options={getOptionsByField('PHONE_NUMBER')}
                              value={fieldsInfo.phoneNumber}
                           />
                        </SC.RadioRow>

                        <SC.RadioRow>
                           <SC.RadioTitle
                              colorType="base"
                              weight="semiBold"
                              size="small"
                              mb="none"
                              label="ob.settings.emailField.title"
                           />

                           <SC.Radio.Group
                              onChange={changeEmail}
                              options={getOptionsByField('EMAIL')}
                              value={fieldsInfo.email}
                           />
                        </SC.RadioRow>

                        <SC.RadioRow>
                           <SC.RadioTitle
                              colorType="base"
                              weight="semiBold"
                              size="small"
                              mb="none"
                              label="input.notes.label"
                           />

                           <SC.Radio.Group
                              onChange={changeNotes}
                              options={getOptionsByField('NOTES')}
                              value={fieldsInfo.notes}
                           />
                        </SC.RadioRow>

                        <SC.NotesPlaceholderWrapper>
                           <Field.Input
                              name="placeholderNotes"
                              label="ob.settings.notes.label"
                              placeholder="ob.settings.notes.placeholder"
                           />
                        </SC.NotesPlaceholderWrapper>
                     </SC.Card>

                     <SC.FormInfoContainer>
                        <Text label="ob.bookingLimit.title" weight="semiBold" size="large" mb="small" />
                        <Text label="ob.bookingLimit.description" size="small" mb="none" color="#61749D" />
                     </SC.FormInfoContainer>
                     <SC.Card>
                        <Field.Select
                           name="serviceLimit"
                           label="ob.bookingLimit.label"
                           placeholder="ob.bookingLimit.unlimited.title"
                           options={appointmentBookingsLimitOptions}
                           translate
                        />
                     </SC.Card>

                     <SC.FormInfoContainer>
                        <Text label="ob.settings.notifications.title" weight="semiBold" size="large" mb="small" />
                        <Text label="ob.settings.notifications.description" size="small" mb="none" color="#61749D" />
                     </SC.FormInfoContainer>
                     <SC.Card style={{ paddingTop: '10px' }} className="notification-emails">
                        <Field.Checkbox name="notifyBookedEmployee" textKey="checkbox.sendToBooked" type="checkbox" />
                        <Field.Checkbox
                           name="notifySpecificEmail"
                           textKey="checkbox.sendSpecificEmail"
                           type="checkbox"
                        />
                        {values.notifySpecificEmail ? (
                           <Field.Input
                              name="notificationSpecificEmail"
                              label="input.specificEmail.label"
                              placeholder="input.specificEmail.placeholder"
                              validate={composeValidators(validateRequired, validateEmail)}
                           />
                        ) : (
                           <Field.Input
                              name="notificationSpecificEmail"
                              label="input.specificEmail.label"
                              placeholder="input.specificEmail.placeholder"
                              validate={validateEmail}
                           />
                        )}
                     </SC.Card>

                     <SC.FormInfoContainer>
                        <Text label="ob.settings.locationsUrl.title" weight="semiBold" size="large" mb="small" />
                        <Text label="ob.settings.locationsUrl.description" size="small" mb="none" color="#61749D" />
                     </SC.FormInfoContainer>
                     <SC.Card style={{ paddingTop: '10px' }}>
                        <Field.Input
                           name="partnerSlug"
                           label="input.locationsUrl.label"
                           placeholder="input.locationsUrl.placeholder"
                        />
                        <a
                           href={`https://book.plandok.com/partner/${slugify(values.partnerSlug)}`}
                           target="_blank"
                           rel="noreferrer">
                           <IntlLabel label={`https://book.plandok.com/partner/${slugify(values.partnerSlug)}`} />
                        </a>
                     </SC.Card>
                     <SC.ActionButtons>
                        <Button label="btn.cancel" type="primary" ghost upperCase={false} onClick={goBack} />
                        <Button
                           label="btn.save"
                           type="primary"
                           htmlType="submit"
                           upperCase={false}
                           loading={submitting}
                        />
                     </SC.ActionButtons>
                  </>
               )}
            </Form>
         </SC.Container>
      </Spin>
   )
}
