import axios, { Method, AxiosResponse } from 'axios'
import { FORM_ERROR } from 'final-form'
import { ApiRouteType } from '../../types/api-routes'
import { message as antMessage } from 'antd'

type Resp = any

const getMethod = (method: Method) =>
   (
      ({
         get: axios.get,
         delete: axios.delete,
         head: axios.head,
         post: axios.post,
         put: axios.put,
         patch: axios.patch,
      }) as any
   )[method]

export const getIsOk = (res: AxiosResponse<any>) => [200, 202, 201, 204, 203].includes(res.status)

const getErrorObj = (data: any) => {
   const { errors, message } = data || { errors: {} }
   const result: any = {}

   if (!errors) {
      return {
         [FORM_ERROR]: message || data?.error?.message || 'Something went wrong, please try again or contact support.',
      }
   }

   if (Array.isArray(errors)) {
      errors.forEach(({ field, message: errorMessage }: any) => {
         result[field || FORM_ERROR] = errorMessage
      })
   } else {
      // TODO check if we can remove it
      Object.keys(errors).forEach(key => {
         const errorText = Array.isArray(errors[key]) ? errors[key].join(' \n') : errors[key]
         if (!Number.isNaN(parseInt(key, 10))) {
            result[FORM_ERROR] = (result[FORM_ERROR] || '').concat(' ').concat(errorText)
         } else if (errors[key] && errors[key].field) {
            result[errors[key].field] = errors[key].message
         } else {
            result[key] = errorText
         }
      })
   }

   return result
}

export const getPath = (path: ApiRouteType, method: Method, props: any) => {
   if (['delete', 'get'].includes(method)) {
      return `${path}${
         props
            ? `?${Object.entries(props)
                 .map(e => e.join('='))
                 .join('&')}`
            : ''
      }`
   }

   return path
}

export const callAction = <T>(path: ApiRouteType, method: Method, isForm?: boolean): ((props?: T) => Promise<Resp>) => {
   return (props?: T, ops?: any) =>
      getMethod(method)(getPath(path, method, props), props, ops)
         .then((res: AxiosResponse<any>) => {
            if (isForm) {
               if (!getIsOk(res)) {
                  return { errors: getErrorObj(res.data) }
               }
               return res.data
            } else if (!getIsOk(res)) {
               return res?.data
            }

            return res?.data
         })
         .catch(({ response }: any) => {
            if (isForm) {
               return { errors: getErrorObj(response?.data as any) }
            }
            return response?.data as any
         })
}

export const callActionWithId = <T>(
   path: ApiRouteType,
   method: Method,
   isForm?: boolean,
   options?: {
      customUrlFunc?: (id: any, props: any) => string
   },
): ((id: any, props?: T) => Promise<Resp | null>) => {
   return (id: any, props?: T) =>
      getMethod(method)(options?.customUrlFunc?.(id, props) || path.replace(/{.+}/, id), props)
         .then((res: AxiosResponse<any>) => {
            if (isForm) {
               if (!getIsOk(res)) {
                  antMessage.error('Failed to perform action')
                  return { errors: getErrorObj(res.data) }
               }
               return res.data
            } else if (!getIsOk(res)) {
               antMessage.error('Failed to perform action')
               return res?.data
            }

            return res?.data
         })
         .catch(({ response }: any) => {
            if (isForm) {
               return { errors: getErrorObj(response?.data as any) }
            }
            return response?.data as any
         })
}
