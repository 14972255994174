import { Layout } from 'antd'
import { MobileSidebar, NoInternetConnection } from 'components'
import React from 'react'

import MobileMenu from './components/MobileMenu'

export default function MobileMenuPage() {
   return (
      <NoInternetConnection>
         <Layout>
            <MobileSidebar />
            <MobileMenu />
         </Layout>
      </NoInternetConnection>
   )
}
