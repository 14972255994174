import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { message } from 'antd'
import React, { memo, ReactNode, useEffect, useState } from 'react'
import { useStore } from 'store/store'

import UpdateNotification from '../components/UpdateNotification'
import { replaceHistoryState } from '../store/calendar-state.helpers'

interface IBeforeInstallPromptEvent extends Event {
   readonly platforms: string[]
   readonly userChoice: Promise<{
      outcome: 'accepted' | 'dismissed'
      platform: string
   }>
   prompt(): Promise<void>
}

type A2HSProviderProps = {
   children: ReactNode
}

export default memo(function A2HSProvider({ children }: A2HSProviderProps) {
   const state = useStore()
   const [, setDeferredPrompt] = useState<IBeforeInstallPromptEvent | null>(null)
   const [, setIsVisibleUndo] = useState(false)
   const [, setIsVisibleA2HS] = useState(false)
   const [isSuccess] = useState(false)

   const { location } = useLocation()

   // const isOpenedFromApp = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1

   // const onCancel = () => setIsVisibleA2HS(false)

   const onNotNow = () => {
      state.setSystems({ displayUpdateButton: true, displayUpdateAlert: false })
      localStorage.setItem('skipNewUpdate', 'true')
   }

   useEffect(() => {
      window.addEventListener('beforeinstallprompt', (e: any) => {
         e.preventDefault()
         setDeferredPrompt(e)
         setIsVisibleA2HS(true)
      })

      const deleteAppointment = () => {
         setIsVisibleUndo(false)
         replaceHistoryState()
      }

      if (location.state?.id) {
         setIsVisibleUndo(true)
         setTimeout(deleteAppointment, 10000)
      }
   }, [location])

   // const onOk = () => {
   //    deferredPrompt?.prompt()
   //    deferredPrompt?.userChoice.then(choiceResult => {
   //       if (choiceResult.outcome === 'accepted') {
   //          setIsSuccess(true)
   //          onCancel()
   //       }
   //    })
   // }

   const onUpdate = () => {
      if ('caches' in window) {
         caches.keys().then(names => names.forEach(name => caches.delete(name)))

         state.setSystems({ displayUpdateButton: false, displayUpdateAlert: false })
         window.location.reload()
      }
   }

   isSuccess && message.success(<IntlLabel label="app.install.success.title" />)

   return (
      <div>
         {/*{isVisibleA2HS && !isOpenedFromApp && <A2HSNotification onOk={onOk} onCancel={onCancel} />}*/}
         {state.systems.displayUpdateAlert && <UpdateNotification onOk={onUpdate} onCancel={onNotNow} />}
         {/*<BtnUndo isVisibleUndo={isVisibleUndo} setIsVisibleUndo={setIsVisibleUndo} deletedAppointment={location.state} />*/}
         {children}
      </div>
   ) as any
})
