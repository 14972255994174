import { Button, Text, useLocation } from '@plandok/core'
import { PremiumFeatureLabel } from 'components'
import { RoutePath } from 'constants/routes'
import React from 'react'

import * as SC from '../../SetupForm/styles'

interface DesktopTitleBlockProps {
   premium: boolean
   labelSettings: string
}

export default function DesktopTitleBlock({ premium, labelSettings }: DesktopTitleBlockProps) {
   const { navigate } = useLocation()

   const upgrade = () => navigate(RoutePath.BILLING)

   return (
      <SC.SubtitleBlock>
         {!premium && (
            <>
               <PremiumFeatureLabel size="small" weight="normal" lh="base" />
               <Button label="button.reminder.upgrade" type="primary" smallRadius onClick={upgrade} upperCase={false} />
            </>
         )}
         <Text colorType="base" mb="small" size="medium" weight="semiBold" label={labelSettings} lh="mlarge" />
      </SC.SubtitleBlock>
   )
}
