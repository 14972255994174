export const socialData = [
   {
      name: 'facebook',
      label: 'Facebook page',
      icon: 'facebookCustomIcon',
      value: 'www.facebook.com/business',
   },
   {
      name: 'instagram',
      label: 'Instagram page',
      icon: 'instagramCustomIcon',
      value: 'www.instagram.com/yourbusiness',
   },
   {
      name: 'yourPage',
      label: 'Your page',
      icon: 'globeCustomIcon',
      value: 'www.yourbusiness.com',
   },
]

export const defaultMessage = `BODY TEXT Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
Neque vestibulum convallis et fames faucibus dui consequat. 
     
Nam donec vitae quisque lacus nisl, eu odio elit sem. 
Vitae velit lacus eu lectus.`
