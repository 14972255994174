import { PieChartOutlined } from '@ant-design/icons'
import { Card } from '@plandok/core'
import { IntlLabel, IntlMoney } from '@plandok/i18n'
import React from 'react'
import { IClientDetails } from 'types/api'

import * as SC from './style'

export default function ClientTotalBlock({ data }: { data: IClientDetails }) {
   const { statistics } = data

   return (
      <SC.Container as={Card} noPadding>
         <SC.Divider />
         <SC.ContainerTitle>
            <div>
               <div data-total-item-main>
                  <PieChartOutlined />
               </div>
               <div data-total-item-minor>
                  <IntlLabel label="client.statistics" />
               </div>
            </div>
         </SC.ContainerTitle>
         <SC.TotalNumberContainer>
            <div data-total-numbers-row>
               <div>
                  <div data-total-item-main>
                     <IntlMoney value={statistics.bookingsTotalPrice} />
                  </div>
                  <div data-total-item-minor>
                     <IntlLabel label="client.totalSales" />
                  </div>
               </div>
            </div>
            <div data-total-numbers-row>
               <div>
                  <div data-total-item-main>{statistics.totalBookingsCount}</div>
                  <div data-total-item-minor>
                     <IntlLabel label="client.allBooking" />
                  </div>
               </div>
               <div>
                  <div data-total-item-main>{statistics.completedBookingsCount}</div>
                  <div data-total-item-minor>
                     <IntlLabel label="client.completed" />
                  </div>
               </div>
               <div>
                  <div data-total-item-main>{statistics.cancelledBookingsCount}</div>
                  <div data-total-item-minor>
                     <IntlLabel label="client.cancelled" />
                  </div>
               </div>
               <div>
                  <div data-total-item-main>{statistics.missedBookingsCount}</div>
                  <div data-total-item-minor>
                     <IntlLabel label="client.noShow" />
                  </div>
               </div>
            </div>
         </SC.TotalNumberContainer>
         <SC.Divider />
      </SC.Container>
   )
}
