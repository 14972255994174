import React from 'react'

// import * as RSH from 'react-sortable-hoc';
import * as SC from '../style'

function ListServiceContainer(props: any): any {
   return <SC.Item>{props.children}</SC.Item>
}

export default ListServiceContainer
