import axios from 'api/axios'

export const downloadInvoicePDF = async (id: number) => {
   const response = await axios.get(`subscription-billing/invoices/${id}/download`, { responseType: 'arraybuffer' })
   const url = window.URL.createObjectURL(new Blob([response.data]))
   const link = document.createElement('a')
   link.href = url
   link.setAttribute('download', `P${id}.pdf`) //or any other extension
   document.body.appendChild(link)
   link.click()
   document.body?.removeChild?.(link)
}
