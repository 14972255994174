import { CustomIcon, Text, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React from 'react'

function ViewMessageModal(props: { modalProps: { text: string } }) {
   return <Text size="base">{(props.modalProps || {}).text}</Text>
}

export default withModal(
   <span>
      <CustomIcon type="message" /> <IntlLabel label="modal.message.title" />
   </span>,
)(ViewMessageModal)
