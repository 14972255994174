import { mediaMdUp } from '@plandok/core'
import styled, { css } from 'styled-components'

export const Container = styled.div`
   display: flex;
   text-align: center;
   align-items: center;
   color: ${({ theme }) => theme.primaryColor};
   font-weight: 500;
   cursor: pointer;

   span#calendar svg {
      color: #adb6cc;
      font-size: 24px;
      margin-right: 10px;
   }

   span#arrow svg {
      margin-left: auto;
      font-size: 12px;
   }

   ${mediaMdUp(css`
      font-size: 20px;
      margin: 0 auto;
      span#arrow svg {
         margin-left: 10px;
      }
   `)}
`
