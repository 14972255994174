import { FieldRenderProps } from 'react-final-form'
import withFormBlock from './FormBlock/withFormBlock'
import React from 'react'
import { mediaSmDown } from '../../../helpers/styles'
import styled, { css } from 'styled-components'

const Container = styled.div`
   text-align: center;
   margin: 0px 40px 15px 20px;
   color: #13316d;
   font-size: 26px;
   font-weight: 600;
   ${mediaSmDown(css`
      margin: 23px auto 20px auto;
   `)}
`

interface FormInputProps {
   submitting?: boolean
}

const EmailPreviewInput = ({ input, submitting, meta, ...rest }: FormInputProps & FieldRenderProps<any, any>) => {
   return <Container>{input.value}</Container>
}

export default withFormBlock<FormInputProps>(EmailPreviewInput)
