import {
   callAction,
   countryOptions,
   currencyOptions,
   LocalStorage,
   phonePrefixOptions,
   timeZoneOptions,
} from '@plandok/core'
import { LANGUAGE_HEADER } from 'constants/api'

import * as ApiType from './api.d'
import axios from './axios'

export const login = (props: any) => callAction<ApiType.Login>('/login', 'post', true)(props)

export const getLocationInfo = async () => {
   try {
      const res: any = await axios.get(
         `https://ip-api.io/json?api_key=${process.env.REACT_APP_IP_API_KEY || 'feae14ac-26cb-4524-9d3b-87530d5a6fa1'}`,
      )

      const data = res?.data
      return {
         companyCountryIsoCode: countryOptions.find((e: any) => e.code === data?.country_code)?.code,
         companyTimeZone: timeZoneOptions.find((e: any) => e.code === data?.time_zone)?.code,
         companyCurrencyIsoCode: currencyOptions.find((e: any) => e.code === countryToCurrency[data?.country_code])
            ?.code,
         callingCode: phonePrefixOptions.find(
            option => option.value === `+${data?.callingCode}` && option.country === data?.country_name,
         )?.value,
      }
   } catch (e) {
      return {}
   }
}

export const register = callAction<ApiType.Registration>('/register', 'post', true)

export const authSuccess = (props: any) => {
   LocalStorage.setRefreshToken(props.refreshToken)
   LocalStorage.setAuthToken(props.token)
   if (props.context?.languageIsoCode) {
      axios.defaults.headers[LANGUAGE_HEADER] = props.context.languageIsoCode
   }
   axios.defaults.headers.Authorization = `Bearer ${props.token}`
}

export const closeSession = callAction('/close-session', 'post', false)

export const logout = async () => {
   let refreshToken = LocalStorage.getRefreshToken()

   if (refreshToken) {
      await closeSession({ refreshToken: LocalStorage.getRefreshToken() })
   }

   delete axios.defaults.headers.Authorization
   delete axios.defaults.headers[LANGUAGE_HEADER]
}

export const requestPasswordChange = callAction<ApiType.Login>('/request-password-reset', 'post', true)

export const fetchSubscriptionInfo = callAction('/subscription-billing/subscription', 'get', true)
export const fetchApplicationContext = callAction('/context', 'get', true)

export const setNewPassword = callAction('/reset-password', 'post', true)
export const socialAuthLogin = (social: string) => callAction(`/social/${social}/login`, 'post', true)
export const socialAuthInformation = (social: string) => callAction(`/social/${social}/verify`, 'post', true)

const countryToCurrency = {
   AD: 'EUR',
   AE: 'AED',
   AF: 'AFN',
   AG: 'XCD',
   AI: 'XCD',
   AL: 'ALL',
   AM: 'AMD',
   AN: 'ANG',
   AO: 'AOA',
   AQ: 'USD',
   AR: 'ARS',
   AS: 'USD',
   AT: 'EUR',
   AU: 'AUD',
   AW: 'AWG',
   AX: 'EUR',
   AZ: 'AZN',
   BA: 'BAM',
   BB: 'BBD',
   BD: 'BDT',
   BE: 'EUR',
   BF: 'XOF',
   BG: 'BGN',
   BH: 'BHD',
   BI: 'BIF',
   BJ: 'XOF',
   BL: 'EUR',
   BM: 'BMD',
   BN: 'BND',
   BO: 'BOB',
   BQ: 'USD',
   BR: 'BRL',
   BS: 'BSD',
   BT: 'BTN',
   BV: 'NOK',
   BW: 'BWP',
   BY: 'BYN',
   BZ: 'BZD',
   CA: 'CAD',
   CC: 'AUD',
   CD: 'CDF',
   CF: 'XAF',
   CG: 'XAF',
   CH: 'CHF',
   CI: 'XOF',
   CK: 'NZD',
   CL: 'CLP',
   CM: 'XAF',
   CN: 'CNY',
   CO: 'COP',
   CR: 'CRC',
   CU: 'CUP',
   CV: 'CVE',
   CW: 'ANG',
   CX: 'AUD',
   CY: 'EUR',
   CZ: 'CZK',
   DE: 'EUR',
   DJ: 'DJF',
   DK: 'DKK',
   DM: 'XCD',
   DO: 'DOP',
   DZ: 'DZD',
   EC: 'USD',
   EE: 'EUR',
   EG: 'EGP',
   EH: 'MAD',
   ER: 'ERN',
   ES: 'EUR',
   ET: 'ETB',
   FI: 'EUR',
   FJ: 'FJD',
   FK: 'FKP',
   FM: 'USD',
   FO: 'DKK',
   FR: 'EUR',
   GA: 'XAF',
   GB: 'GBP',
   GD: 'XCD',
   GE: 'GEL',
   GF: 'EUR',
   GG: 'GBP',
   GH: 'GHS',
   GI: 'GIP',
   GL: 'DKK',
   GM: 'GMD',
   GN: 'GNF',
   GP: 'EUR',
   GQ: 'XAF',
   GR: 'EUR',
   GS: 'FKP',
   GT: 'GTQ',
   GU: 'USD',
   GW: 'XOF',
   GY: 'GYD',
   HK: 'HKD',
   HM: 'AUD',
   HN: 'HNL',
   HR: 'EUR',
   HT: 'HTG',
   HU: 'HUF',
   ID: 'IDR',
   IE: 'EUR',
   IL: 'ILS',
   IM: 'GBP',
   IN: 'INR',
   IO: 'USD',
   IQ: 'IQD',
   IR: 'IRR',
   IS: 'ISK',
   IT: 'EUR',
   JE: 'GBP',
   JM: 'JMD',
   JO: 'JOD',
   JP: 'JPY',
   KE: 'KES',
   KG: 'KGS',
   KH: 'KHR',
   KI: 'AUD',
   KM: 'KMF',
   KN: 'XCD',
   KP: 'KPW',
   KR: 'KRW',
   KW: 'KWD',
   KY: 'KYD',
   KZ: 'KZT',
   LA: 'LAK',
   LB: 'LBP',
   LC: 'XCD',
   LI: 'CHF',
   LK: 'LKR',
   LR: 'LRD',
   LS: 'LSL',
   LT: 'EUR',
   LU: 'EUR',
   LV: 'EUR',
   LY: 'LYD',
   MA: 'MAD',
   MC: 'EUR',
   MD: 'MDL',
   ME: 'EUR',
   MF: 'EUR',
   MG: 'MGA',
   MH: 'USD',
   MK: 'MKD',
   ML: 'XOF',
   MM: 'MMK',
   MN: 'MNT',
   MO: 'MOP',
   MP: 'USD',
   MQ: 'EUR',
   MR: 'MRU',
   MS: 'XCD',
   MT: 'EUR',
   MU: 'MUR',
   MV: 'MVR',
   MW: 'MWK',
   MX: 'MXN',
   MY: 'MYR',
   MZ: 'MZN',
   NA: 'NAD',
   NC: 'XPF',
   NE: 'XOF',
   NF: 'AUD',
   NG: 'NGN',
   NI: 'NIO',
   NL: 'EUR',
   NO: 'NOK',
   NP: 'NPR',
   NR: 'AUD',
   NU: 'NZD',
   NZ: 'NZD',
   OM: 'OMR',
   PA: 'PAB',
   PE: 'PEN',
   PF: 'XPF',
   PG: 'PGK',
   PH: 'PHP',
   PK: 'PKR',
   PL: 'PLN',
   PM: 'EUR',
   PN: 'NZD',
   PR: 'USD',
   PS: 'ILS',
   PT: 'EUR',
   PW: 'USD',
   PY: 'PYG',
   QA: 'QAR',
   RE: 'EUR',
   RO: 'RON',
   RS: 'RSD',
   RU: 'RUB',
   RW: 'RWF',
   SA: 'SAR',
   SB: 'SBD',
   SC: 'SCR',
   SD: 'SDG',
   SE: 'SEK',
   SG: 'SGD',
   SH: 'SHP',
   SI: 'EUR',
   SJ: 'NOK',
   SK: 'EUR',
   SL: 'SLE',
   SM: 'EUR',
   SN: 'XOF',
   SO: 'SOS',
   SR: 'SRD',
   SS: 'SSP',
   ST: 'STN',
   SV: 'USD',
   SX: 'ANG',
   SY: 'SYP',
   SZ: 'SZL',
   TC: 'USD',
   TD: 'XAF',
   TF: 'EUR',
   TG: 'XOF',
   TH: 'THB',
   TJ: 'TJS',
   TK: 'NZD',
   TL: 'USD',
   TM: 'TMT',
   TN: 'TND',
   TO: 'TOP',
   TR: 'TRY',
   TT: 'TTD',
   TV: 'AUD',
   TW: 'TWD',
   TZ: 'TZS',
   UA: 'UAH',
   UG: 'UGX',
   UM: 'USD',
   US: 'USD',
   UY: 'UYU',
   UZ: 'UZS',
   VA: 'EUR',
   VC: 'XCD',
   VE: 'VES',
   VG: 'USD',
   VI: 'USD',
   VN: 'VND',
   VU: 'VUV',
   WF: 'XPF',
   WS: 'WST',
   YE: 'YER',
   YT: 'EUR',
   ZA: 'ZAR',
   ZM: 'ZMW',
   ZW: 'ZWL',
} as any
