import { Card, useAppContext, useNavigation } from '@plandok/core'
import { useQuery } from '@tanstack/react-query'
import { Col, Row, Skeleton } from 'antd'
import * as api from 'api'
import { QueryType } from 'api/query'
import { AppLayout, NoInternetConnection } from 'components'
import React, { useEffect, useState } from 'react'

import BtnUndo from '../CalendarPage/components/BtnUndo'
import ClientAppointmentsBlock from './components/ClientAppointmentsBlock'
import ClientDetailsMobileHeader from './components/ClientDetailsMobileHeader'
import ClientInfoBlock from './components/ClientInfoBlock'
import ClientTotalBlock from './components/ClientTotalBlock'

export default function ClientDetailsPage() {
   const [context] = useAppContext()
   const [isVisibleUndo, setIsVisibleUndo] = useState(false)
   const timeFormat = context.timeFormat

   const { params, location }: any = useNavigation()

   const { data, isFetching, refetch } = useQuery({
      queryKey: [QueryType.PROFILE_DETAILS, params.id],
      queryFn: () => api.fetchClientProfileDetails(params.id),
   })

   useEffect(() => {
      // const deleteAppointment = () => {
      //    setIsVisibleUndo(false)
      //    replaceHistoryState()
      // }
      // if (location.state?.id) {
      //   setIsVisibleUndo(true);
      //   setTimeout(deleteAppointment, 10000);
      // }
   }, [location])

   const isLoading = isFetching || !timeFormat

   return (
      <NoInternetConnection>
         <>
            <BtnUndo
               isVisibleUndo={isVisibleUndo}
               setIsVisibleUndo={setIsVisibleUndo}
               deletedAppointment={data}
               refetch={refetch}
            />

            <AppLayout
               headerTitle="client.page.title"
               mobileHeader={() => (
                  <ClientDetailsMobileHeader id={params.id} phoneNumber={data?.profile?.phoneNumber} />
               )}>
               <AppLayout.Container>
                  <Row justify="space-between">
                     <Col md={7} span={24}>
                        {isLoading ? (
                           <Card withShadow>
                              <Skeleton avatar loading active />
                           </Card>
                        ) : (
                           <ClientInfoBlock data={data} id={params.id} />
                        )}
                     </Col>
                     <Col md={16} span={24}>
                        {isLoading ? (
                           <Card withShadow className="mb-2">
                              <Skeleton loading active />
                           </Card>
                        ) : (
                           <ClientTotalBlock data={data} />
                        )}
                        {isLoading ? (
                           <Card withShadow>
                              <Skeleton loading active />
                           </Card>
                        ) : (
                           <ClientAppointmentsBlock data={data} pathName={location?.pathname} timeFormat={timeFormat} />
                        )}
                     </Col>
                  </Row>
               </AppLayout.Container>
            </AppLayout>
         </>
      </NoInternetConnection>
   )
}
