import * as baseTheme from './base'
import * as buttonTheme from './button'
import * as inputTheme from './input'
import * as tableTheme from './table'
import * as zIndexTheme from './z-index'
import * as calendarTheme from './calendar'

export default {
   ...baseTheme,
   ...buttonTheme,
   ...inputTheme,
   ...tableTheme,
   ...zIndexTheme,
   ...calendarTheme,
}
