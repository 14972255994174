import { useAppContext } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { message, Spin } from 'antd'
import * as api from 'api'
import { AppLayout, NoInternetConnection } from 'components'
import withApiData from 'hocs/form/withApiData'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import CompanyDetailsForm from './components/CompanyDetailsForm'
import CompanyMobileHeader from './components/CompanyMobileHeader'

const CompanyDetailsFormWithApi = withFormApi({
   createAction: api.updateCompanyDetails,
   successMessage: 'Updated.',
})(CompanyDetailsForm)

function CompanyDetailsPage({ data, isLoading }: any) {
   const [, { updateCompanyDetails }] = useAppContext()

   return (
      <NoInternetConnection>
         <AppLayout headerTitle="setup.page.title" mobileHeader={CompanyMobileHeader}>
            <AppLayout.Container maxWidth="680px">
               {data ? (
                  <CompanyDetailsFormWithApi
                     initialValues={data}
                     onSuccess={(_: any, _a: any, form: any) => {
                        if (form?.timeZone && form?.countryIsoCode) {
                           updateCompanyDetails({ timeZone: form.timeZone, country: form.countryIsoCode })
                           message.success(<IntlLabel label={'notification.success.update'} />)
                        }
                     }}
                  />
               ) : (
                  <Spin spinning={isLoading}>
                     <CompanyDetailsForm />
                  </Spin>
               )}
            </AppLayout.Container>
         </AppLayout>
      </NoInternetConnection>
   )
}

export default withApiData(api.fetchCompanyDetails, { exactData: true })(CompanyDetailsPage)
