import { withModal } from '@plandok/core'
import { GOOGLE_ANALYTICS_URL } from 'constants/external-links'
import React from 'react'

import googleAnalytics from '../images/google-analytics.svg'
import { AnalyticsModalProps } from '../types/types'
import AnalyticsModal from './AnalyticsModal'

function GoogleAnalyticsModal({ modalProps, onClose }: AnalyticsModalProps) {
   const googleAnalyticsModalProps = {
      altKey: 'ob.analytics.google.title',
      FIELD_PLACEHOLDER: 'UA-XXXXX-Y',
      imgSrc: googleAnalytics,
      description1: 'ob.analytics.google.description.1',
      description2: 'ob.analytics.google.description.2',
      onClose,
      modalProps,
      fieldName: 'googleTrackerId',
      fieldLabel: 'ob.analytics.google.trackingId',
      fieldTooltip: 'ob.analytics.google.tooltip',
      accountLabel: 'ob.analytics.google.account',
      href: GOOGLE_ANALYTICS_URL,
   }

   return <AnalyticsModal {...googleAnalyticsModalProps} />
}

export default withModal('ob.analytics.google.title', { contentClassName: 'online-booking-analytics-modal' })(
   GoogleAnalyticsModal,
)
