import { Button, getIsMobile, useModal } from '@plandok/core'
import { Row } from 'antd'
import { toJS } from 'mobx'
import React, { useState } from 'react'

import { AppTable } from '../../../../components'
import { query } from '../../../../graphql'
import * as SC from '../../MessagesPage/styles'
import { closedDatesColumns } from './config'

function getMobileColumn(isMobile: boolean) {
   return ({ value }: any) => {
      return (
         <Row justify="space-between" align="middle" className="w-100">
            <SC.MessageContainer isMobile={isMobile}>
               {closedDatesColumns.map((e, i) => (
                  <div key={i} style={{ minWidth: '100px' }}>
                     {(e as any)?.render?.(value.startDate, { ...value })}
                  </div>
               ))}
            </SC.MessageContainer>
         </Row>
      )
   }
}

export default function StaffDatesPage() {
   const [, { showModal }] = useModal()
   const isMobile = getIsMobile()
   const [refresh, setRefresh] = useState(0)

   const openCreateClosedDate = () =>
      showModal({
         type: 'VIEW_CLOSED_DATE',
         modalProps: {
            onSuccess: () => setRefresh(refresh + 1),
         },
      })

   const viewClosedDate = (id: string, initialValues: any) => {
      const values = toJS(initialValues)?.locations
      showModal({
         type: 'VIEW_CLOSED_DATE',
         modalProps: {
            id,
            skipDetails: true,
            initialValues: { ...initialValues, locationIds: values?.map((e: any) => e.id) },
            onSuccess: () => setRefresh(refresh + 1),
         },
      })
   }

   return (
      <AppTable
         type="closedDates"
         refreshData={refresh}
         viewDetails={viewClosedDate}
         columns={closedDatesColumns}
         mobileRowComponent={getMobileColumn(isMobile)}
         apiConfig={{
            apiType: 'gql',
            gqlQuery: query.CLOSED_DATES,
            mapping: {
               pageKey: 'page',
               dataKey: 'closedDates',
               totalCountKey: 'closedDates.totalCount',
               searchKey: 'query',
               emptyStateKey: 'closedDates.emptyState',
            },
         }}
         showPlaceholder
         placeholder={{
            imgName: 'dates',
            titleLabel: 'state.closedDates.title',
            descriptionLabel: 'state.closedDates.description',
            btnLabel: 'state.closedDates.button',
            onClick: openCreateClosedDate,
         }}>
         <Row justify="end" className="w-100 mt-1 mb-2">
            <Button label="staff.create.title" type="primary" upperCase={false} onClick={openCreateClosedDate} />
         </Row>
      </AppTable>
   )
}
