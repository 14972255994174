import { withModal } from '@plandok/core'
import { FACEBOOK_PIXEL_URL } from 'constants/external-links'
import React from 'react'

import facebookPixel from '../images/facebook-pixel.svg'
import { AnalyticsModalProps } from '../types/types'
import AnalyticsModal from './AnalyticsModal'

function FacebookPixelModal({ modalProps, onClose }: AnalyticsModalProps) {
   const facebookPixelModalProps = {
      altKey: 'ob.analytics.facebook.title',
      FIELD_PLACEHOLDER: '1234567891011121',
      imgSrc: facebookPixel,
      description1: 'ob.analytics.facebook.description.1',
      description2: 'ob.analytics.facebook.description.2',
      onClose,
      modalProps,
      fieldName: 'facebookTrackerId',
      fieldLabel: 'ob.analytics.facebook.pixelId',
      fieldTooltip: 'ob.analytics.facebook.tooltip',
      accountLabel: 'ob.analytics.facebook.account',
      href: FACEBOOK_PIXEL_URL,
   }

   return <AnalyticsModal {...facebookPixelModalProps} />
}

export default withModal('ob.analytics.facebook.title', { contentClassName: 'online-booking-analytics-modal' })(
   FacebookPixelModal,
)
