import styled from 'styled-components'

export const ClientItem = styled.div`
   display: flex;
   align-items: center;
   cursor: pointer;
   color: ${({ theme }) => theme.primaryColor};

   &:hover {
      opacity: 0.8;
   }
   [data-client-item-avatar] {
      margin-right: 15px;
   }

   [data-client-item-info] {
      line-height: 16px;
      div:first-of-type {
         font-size: 18px;
         font-weight: 500;
      }
      div:not(:first-of-type) {
         font-weight: 300;
         font-size: 14px;
      }
   }
`
