import { Col, Divider, Row } from 'antd'
import React from 'react'

import { reportCardSettings } from '../config'
import ReportCard from './ReportCard'
import * as SC from './styles'

export const Reports = () => (
   <Row gutter={20}>
      <Col md={12} span={24}>
         <ReportCard {...reportCardSettings.APPOINTMENT} />
      </Col>
      <SC.Divider as={Divider} />
      <Col md={12} span={24}>
         <ReportCard {...reportCardSettings.CLIENT} />
         <br />
      </Col>
   </Row>
)

export default Reports
