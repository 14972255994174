import { useLocation, useParams } from '@plandok/core'
import * as api from 'api'
import { ModifyLayout, NoInternetConnection } from 'components'
import { RoutePath } from 'constants/routes'
import withFormApi from 'hocs/form/withFormApi'
import React from 'react'

import ClientForm from './components/ClientForm'

const ClientFormWithApi = withFormApi({
   detailsAction: api.fetchClientDetails,
   createAction: api.createClient,
   editAction: api.editClient,
   successMessage: 'Client Created',
})(ClientForm)

export default function ModifyClientPage(props: any) {
   const params: any = useParams()
   const { navigate, goBack }: any = useLocation()

   const onSuccess = (res: any) => {
      return props.onSuccess ? props.onSuccess(res) : navigate(RoutePath.CLIENTS)
   }

   const onClose = props.onClose || goBack

   const id = props.customId || params?.id

   return (
      <NoInternetConnection>
         <ModifyLayout style={props.style} title={id ? 'title.edit.client' : 'client.create.title'} onClose={onClose}>
            <ClientFormWithApi
               customId={id}
               skipDetails={props.skipDetails}
               onBack={onClose}
               initialValues={{ notificationChannel: 'sms_and_email', gender: 'unknown' }}
               onSuccess={onSuccess}
            />
         </ModifyLayout>
      </NoInternetConnection>
   )
}
