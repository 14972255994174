import { getFirstKey } from '@plandok/core'
import React, { useCallback, useEffect, useState } from 'react'

export default function withApiData(fetchData?: any, { dataKey, exactData, skipMountFetch, isSortable }: any = {}) {
   return (WrappedComponent: any) => {
      const WithApiData = (props: any) => {
         const [data, setData] = useState<any>(null)
         const [isLoading, setIsLoading] = useState<boolean>(true)
         const [emptyState, setEmptyState] = useState<any>(undefined)

         const sortData = useCallback((data?: any[]) => {
            return data?.sort?.((a: any, b: any) => a?.position - b?.position) || []
         }, [])

         const fetchDataHandler = useCallback(() => {
            const fetchFn = fetchData || props.fetchData
            if (!fetchFn) {
               return
            }

            return fetchFn(props.filterParams).then((e: any) => {
               let result = exactData ? e : dataKey ? e[dataKey] : getFirstKey(e)

               if (isSortable) {
                  result = sortData(result)
               }

               setData(result)
               setIsLoading(false)
               setEmptyState(e?.meta?.emptyState)
            })
         }, [fetchData, props.fetchData, props.filterParams, dataKey, exactData, isSortable, sortData])

         const changeData = useCallback((newData?: any[]) => {
            setData(newData)
         }, [])

         const getIsPlaceholderVisible = useCallback(() => {
            return !isLoading && !data?.length
         }, [isLoading, data])

         useEffect(() => {
            if (!skipMountFetch) {
               fetchDataHandler()
            }
         }, [fetchDataHandler, skipMountFetch])

         useEffect(() => {
            fetchDataHandler()
         }, [props.filterParams])

         return (
            <WrappedComponent
               {...props}
               data={data}
               isLoading={isLoading}
               emptyState={emptyState}
               fetchData={fetchDataHandler}
               changeData={changeData}
               showPlaceholder={getIsPlaceholderVisible()}
            />
         )
      }

      return WithApiData
   }
}
