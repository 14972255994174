import { useContext } from 'react'
import { format } from 'date-fns/format'
import { LanguageFnsConfig } from '../languages'
import ReactIntl from './ReactIntl'
import React from 'react'

export default function IntlDate({ date, dateFormat }: { date?: Date; dateFormat: any }) {
   const { lang } = useContext(ReactIntl)

   if (!date) {
      return null
   }

   return (
      <span className="capitalize">
         {(format(date, dateFormat, { locale: LanguageFnsConfig[lang || 'en'] } as any) || '').replace(/\./g, '')}
      </span>
   )
}
