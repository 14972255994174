import { useLocation } from '@plandok/core'
import { NoInternetConnection } from 'components'
import React from 'react'

import SetupCancellationForm from './components/SetupCancellationForm'
import SetupConfirmationForm from './components/SetupConfirmationForm'
import SetupLastVisitForm from './components/SetupLastVisitForm'
import SetupPageLayout from './components/SetupPageLayout'
import SetupRemindersForm from './components/SetupRemindersForm'
import SetupRescheduleForm from './components/SetupRescheduleForm'
import SetupThankYouForm from './components/SetupThankYouForm'
import { getSetupPageLabel } from './helpers'

export default function SetupPage() {
   const { location } = useLocation()

   return (
      <NoInternetConnection>
         <SetupPageLayout title={getSetupPageLabel(location.hash)}>
            <SetupPageLayout.Body>
               <SetupRemindersForm />
            </SetupPageLayout.Body>

            <SetupPageLayout.Body>
               <SetupConfirmationForm />
            </SetupPageLayout.Body>

            <SetupPageLayout.Body>
               <SetupRescheduleForm />
            </SetupPageLayout.Body>

            <SetupPageLayout.Body>
               <SetupCancellationForm />
            </SetupPageLayout.Body>

            <SetupPageLayout.Body>
               <SetupThankYouForm />
            </SetupPageLayout.Body>

            <SetupPageLayout.Body>
               <SetupLastVisitForm />
            </SetupPageLayout.Body>
         </SetupPageLayout>
      </NoInternetConnection>
   )
}
