import React from 'react'

import { IntlLabel, LabelKey } from '@plandok/i18n'
import cn from 'classnames'
import flow from 'lodash/flow'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import { ComponentType, ReactNode } from 'react'
import * as SC from './styles'
import Modal from '.'
import { CustomIcon, Text } from '@plandok/core'

export default function withModal(
   label: LabelKey | ReactNode | null,
   {
      editLabel,
      wide,
      fullScreen,
      middle,
      noClose,
      contentClassName,
      icon,
   }: {
      editLabel?: LabelKey | ReactNode | null
      wide?: boolean
      fullScreen?: boolean
      middle?: boolean
      noClose?: boolean
      contentClassName?: string
      icon?: string
   } = {} as any,
) {
   return (WrappedComponent: ComponentType<any>) =>
      ({ visible, ...props }: any) => {
         const { id } = props.modalProps || {}
         const isEdit = !!id
         // eslint-disable-next-line no-nested-ternary
         let labelComponent
         if (isEdit) {
            labelComponent = isString(label) ? (
               <IntlLabel label={editLabel as LabelKey} />
            ) : isFunction(editLabel) ? (
               editLabel(props.modalProps)
            ) : (
               editLabel
            )
         } else if (!label && props.modalProps?.title) {
            labelComponent = <IntlLabel label={props.modalProps.title as LabelKey} />
         } else {
            labelComponent = isString(label) ? (
               <IntlLabel label={label as LabelKey} />
            ) : isFunction(label) ? (
               label(props.modalProps)
            ) : (
               label
            )
         }
         if (icon) {
            labelComponent = (
               <SC.ModalWithIconTitle>
                  <CustomIcon type={icon as any} />
                  <Text size="xlarge" weight="semiBold" colorType="base" mb="none">
                     <IntlLabel label={label as LabelKey} />
                  </Text>
               </SC.ModalWithIconTitle>
            )
         }

         return (
            <Modal
               title={labelComponent}
               visible={visible}
               onClose={flow(props.onClose, props.modalProps?.onExit || function () {})}
               noClose={noClose}
               className={cn({
                  'ant-modal-wide': wide,
                  'ant-modal-middle': middle,
                  'ant-modal-fullscreen': fullScreen,
               })}
               wrapClassName={contentClassName}
            >
               <WrappedComponent onClose={props.hideModal} isEdit={isEdit} id={id} {...props} />
            </Modal>
         )
      }
}
