import { RightOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import { ClientItem } from 'components'
import React from 'react'

export default function MobileClientRow({ value }: any) {
   return (
      <Row justify="space-between" align="middle" className="w-100">
         <ClientItem client={value} />
         <RightOutlined />
      </Row>
   )
}
