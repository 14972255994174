import Icon from '@ant-design/icons'
import { Button, Mutation, withModal } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import * as api from 'api'
import { RoutePath } from 'constants/routes'
import React from 'react'

import * as SC from '../styles'

function ConfirmClientDeleteModal({ modalProps, onClose, hideAllModals }: any) {
   const { navigate } = useLocation()

   const onSuccess = () => {
      navigate(RoutePath.CLIENTS)
      if (hideAllModals) {
         hideAllModals()
      }
   }

   const clientName = `${modalProps?.profile?.firstName || ''} ${modalProps?.profile?.lastName || ''}`

   return (
      <SC.DeleteClientContainer>
         <Icon type="delete" data-delete-icon />
         <div>
            <IntlLabel label="modal.confirmClientDelete.text.0" /> <br /> <b>{clientName}</b>
            <IntlLabel label="modal.confirmClientDelete.text.1" />
         </div>
         <SC.ModalFooter>
            <Mutation action={api.deleteClient} id={modalProps.id} onSuccess={onSuccess}>
               {({ loading, action }: any) => (
                  <>
                     <Button disabled={loading} label="btn.cancel" onClick={onClose} />
                     <Button loading={loading} label="btn.confirmDelete" danger onClick={action} />
                  </>
               )}
            </Mutation>
         </SC.ModalFooter>
      </SC.DeleteClientContainer>
   )
}

export default withModal(null)(ConfirmClientDeleteModal as any)
