import { useContext } from 'react'
import React from 'react'

import ReactDatePicker from 'react-datepicker'
import { ReactIntl, getDateFnsLocale } from '@plandok/i18n'
import DatePickerInput from './components/DatePickerInput'
import DatepickerHeader from './components/DatepickerHeader'
import './styles/index.less'
import './styles/addon.less'

import { format } from 'date-fns'

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy'

export const formatDateField = (value: any) => {
   return value instanceof Date ? format(value, DEFAULT_DATE_FORMAT) : value
}

const DatePicker = ({ onClear, ...props }: any & { value?: Date; onClear?: () => any }) => {
   const { lang } = useContext(ReactIntl)

   const localeConfig = getDateFnsLocale(lang)
   const baseLocaleConfig = {
      ...localeConfig,
      // overriding base date-fns locale to have weekStartsOn - 1 (Monday) instead of Sunday
      options: { weekStartsOn: 1, firstWeekContainsDate: 1 },
   }

   const changeRaw = (e: any) => {
      e.preventDefault()
   }

   return (
      <ReactDatePicker
         shouldCloseOnSelect={true}
         renderCustomHeader={DatepickerHeader}
         customInput={<DatePickerInput {...props} onClear={onClear} inputValue={formatDateField(props.value)} />}
         selected={props.value}
         onSelect={value => props.onChange(value)}
         onChangeRaw={changeRaw}
         onFocus={e => e.target.blur()}
         locale={baseLocaleConfig ? (baseLocaleConfig as any) : lang}
         popperPlacement="top-start"
         {...props}
      />
   )
}

export default DatePicker
