import {
   isTablet,
   isMobile,
   isAndroid,
   isSmartTV,
   isWinPhone,
   isIOS,
   isIPhone13,
   isIPod13,
   isIPad13,
} from 'react-device-detect'

export const getIsMobile = () => {
   return (
      isTablet ||
      isMobile ||
      isAndroid ||
      isIOS ||
      isSmartTV ||
      isWinPhone ||
      isIPhone13 ||
      isIPod13 ||
      isIPad13 ||
      window.innerWidth < 768
   )
}

export const getScreenHeight = () =>
   Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
   )
