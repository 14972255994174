import { Button, Text, withModal } from '@plandok/core'
import React from 'react'

import * as SC from '../styles'

function DeleteStaffHourModal() {
   return (
      <>
         <Text size="base">
            You are deleting a shift that repeats weekly. Deleting upcoming shifts will overwrite Anna's ongoing Tuesday
            schedule.
         </Text>
         <SC.DeleteShiftBtnBlock>
            <Button label="btn.close" block />
            <Button label="btn.deleteUpcomingShift" block minorBtn />
            <Button label="btn.deleteThisShift" block type="primary" />
         </SC.DeleteShiftBtnBlock>
      </>
   )
}

export default withModal('modal.repeatShift.title', { wide: true })(DeleteStaffHourModal)
