import { IntlLabel } from '@plandok/i18n'
import { Menu } from 'antd'
import React from 'react'

export default function CreateServiceMenu({ createService, createCategory, ...props }: any) {
   return (
      <Menu className="app-common-dropdown" {...props}>
         <Menu.Item onClick={() => createService()}>
            <IntlLabel label="service.new.title" />
         </Menu.Item>
         <Menu.Item onClick={createCategory}>
            <IntlLabel label="modal.newCategory.title" />
         </Menu.Item>
      </Menu>
   )
}
