import { PlusOutlined } from '@ant-design/icons'
import { Button, CustomIcon } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Dropdown } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import * as SC from './styles'

const FadeMask = styled.div`
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 109;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.45);
`
//
// const MenuItem = styled(Menu.Item)`
//    display: flex !important;
//    align-items: center;
//    &:first-of-type {
//       border-top-left-radius: 20px;
//       border-top-right-radius: 20px;
//    }
//    &:last-of-type {
//       border-bottom-left-radius: 20px;
//       border-bottom-right-radius: 20px;
//    }
//    svg {
//       margin-right: 10px;
//    }
// `

const MultipleAddButton: React.FC<{ actions: any[]; className?: string }> = ({ actions, className }) => {
   const [visible, setVisible] = useState(false)

   const toggleVisible = (isVisible?: boolean) => {
      setVisible(isVisible !== undefined ? isVisible : !visible)
   }

   const items = actions?.map((action, index) => ({
      key: index.toString(),
      label: (
         <div
            onClick={() => {
               toggleVisible(false)
               action.action()
            }}>
            {action.icon && <CustomIcon type={action.icon} />}
            {action.title && <IntlLabel label={action.title} />}
         </div>
      ),
   }))

   return (
      <>
         {visible && <FadeMask />}
         <Dropdown
            menu={{ items }}
            trigger={['click']}
            onOpenChange={toggleVisible}
            open={visible}
            placement="topRight">
            <SC.ButtonContainer className={className} open={visible}>
               <Button type="primary" icon={<PlusOutlined />} minorBtn />
            </SC.ButtonContainer>
         </Dropdown>
      </>
   )
}

export default MultipleAddButton
