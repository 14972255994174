import { Text } from '@plandok/core'
import * as api from 'api'
import React, { useEffect } from 'react'
import { useStore } from 'store/store'

import * as SC from '../styles'

export default function HeaderUsage() {
   const notificationCharge = useStore(state => state.notificationCharge)
   const setNotificationCharge = useStore(state => state.setNotificationCharge)

   useEffect(() => {
      if (notificationCharge === undefined) {
         ;(async () => {
            let response = await api.fetchNotificationCharge()
            setNotificationCharge(response?.data?.notificationCharge ?? 0)
         })()
      }

      return () => {}
   }, [])

   const DEFAULT_NOTIFICATION_CHARGE = 0

   const formattedCharge = `€${notificationCharge?.toFixed(2) || DEFAULT_NOTIFICATION_CHARGE}`

   return (
      <SC.ContainerUsage>
         <Text size="base" label="header.usage" colorType="base" lh="small" weight="semiBold" />
         <Text size="base" mb="none" colorType="base" lh="small">
            {formattedCharge}
         </Text>
      </SC.ContainerUsage>
   )
}
