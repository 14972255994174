import { Button, IconButton, mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const Container = styled.div`
   margin-top: 50px;

   ${mediaMobileDown(css`
      margin-left: auto;
      margin-right: auto;
   `)};
`

export const BackButton = styled.a`
   background-color: inherit;
   margin-bottom: 0.5rem;
`

export const BackButtonContent = styled.div`
   display: flex;
   align-items: center;
   color: #136ef6;

   div {
      padding-left: 1rem;
   }
`

export const ExportCSVButton = styled(Button)`
   display: flex;
   align-items: center;
   border: 1px solid #ced3e0;
   border-width: 1px !important;

   ${mediaMobileDown(css`
      position: fixed;
      right: 0;
      top: 5px;
      z-index: 1000;
      border: none;
      background: #fff !important;
   `)}

   div:first-child {
      margin-right: 10px;
   }
`

export const PopoverContentRow = styled.div`
   &:hover {
      cursor: pointer;
   }
`

export const ClearFilterButton = styled(Button)`
   color: #136ef6 !important;
   padding: 0.5rem;

   ${mediaMobileDown(css`
      height: auto;
   `)}
`

export const ToolsButton = styled(IconButton)`
   background: #fff;
   border: 1px solid #ced3e0;
   box-sizing: border-box;
   border-radius: 5px;
   height: 42px;
   width: 42px;
`
