import { IntlDate, IntlLabel } from '@plandok/i18n'
import React from 'react'

export const columnsConfig = [
   {
      title: <IntlLabel label="table.cancelReason.name" />,
      dataIndex: 'name',
      key: 'name',
   },
   {
      title: <IntlLabel label="table.cancelReason.addDate" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: ({ value }: any) => (
         <IntlDate date={value.createdAt && new Date(value.createdAt)} dateFormat="dd MMM yyyy" />
      ),
   },
]
