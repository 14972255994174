import { mediaLgDown, mediaSmDown } from '@plandok/core'
import styled, { css } from 'styled-components'

import { AnalyticsCard } from '../../styles'

type LegendDotProps = {
   color: string
}

export const Clients = styled(AnalyticsCard)`
   grid-area: clients;
   background: #fff;
   padding: 30px;

   canvas {
      margin-left: -0.5rem;
   }
`

export const TotalNumbersStatus = styled.div`
   display: flex;

   ${mediaLgDown(css`
      flex-direction: column;
   `)}
`

export const TotalNumbersColumn = styled.div`
   margin-right: 3rem;

   ${mediaLgDown(css`
      margin-right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
   `)}
`

export const CountTitle = styled.div`
   ${mediaLgDown(css`
      font-size: 18px;
      margin-bottom: 0;
   `)}
`

export const ColumnGraph = styled.div`
   height: 18rem;

   div:first-child {
      white-space: pre-line;
   }

   &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      background-color: #adb6cc;
   }
   &::-webkit-scrollbar-track-piece {
      background-color: #fff;
   }

   ${mediaSmDown(css`
      height: auto;
   `)}
`

export const LegendContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
`

export const LegendDot = styled.div<LegendDotProps>`
   height: 14px;
   width: 14px;
   min-width: 14px;
   background-color: ${({ color }) => color};
   border-radius: 50%;
`

export const LegendValue = styled.h4`
   padding-left: 0.8rem;
   margin-bottom: 0;
   color: #13316d;
   font-weight: normal;
`

export const BarChart = styled.div``

export const PieChart = styled.div`
   display: grid;
   grid-template-columns: 12rem 1fr;
   height: 13rem;

   ${mediaSmDown(css`
      height: 11rem;
      width: 15rem;
   `)}
`

export const ChartsContainer = styled.div`
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   max-height: 20rem;

   ${mediaLgDown(css`
      grid-template-columns: 1fr;
      max-height: inherit;
   `)}
`

export const Tooltip = styled.div`
   padding: 0.4rem 0 0.4rem;
   text-align: center;
`

export const Divider = styled.div`
   display: none;
   ${mediaLgDown(css`
      display: flex;
      margin: 0.5rem 0 !important;
   `)}
`
