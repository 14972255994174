import { Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React from 'react'

export const appointmentsCancellationsColumns = [
   {
      title: <IntlLabel label="table.appointmentsCancellations.ref" />,
      key: 'id',
      dataIndex: 'id',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.appointmentsCancellations.client" />,
      key: 'client',
      dataIndex: 'client',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.appointmentsCancellations.service" />,
      key: 'serviceName',
      dataIndex: 'serviceName',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.appointmentsCancellations.scheduledDate" />,
      key: 'scheduledDate',
      dataIndex: 'scheduledDate',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.appointmentsCancellations.cancelledDate" />,
      key: 'cancelledDate',
      dataIndex: 'cancelledDate',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.appointmentsCancellations.cancelledBy" />,
      key: 'cancelledBy',
      dataIndex: 'cancelledBy',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.appointmentsCancellations.reason" />,
      key: 'reasonName',
      dataIndex: 'reasonName',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.appointmentsCancellations.price" />,
      key: 'price',
      dataIndex: 'price',
      render: (value: any) => {
         const price = JSON.parse(JSON.stringify(value))
         return (
            <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
               {price.amount} {price.currency}
            </Text>
         )
      },
   },
]

export const cancellationReasonColumns = [
   {
      title: <IntlLabel label="table.appointmentsCancellations.reason" />,
      key: 'CancellationReasonName',
      dataIndex: 'CancellationReasonName',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
   {
      title: <IntlLabel label="table.appointmentsCancellations.totalCount" />,
      key: 'totalCount',
      dataIndex: 'totalCount',
      render: (value: string) => (
         <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
            {value}
         </Text>
      ),
   },
]
