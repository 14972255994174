import Icon from '@ant-design/icons'
import { Button, Field, Form, getIsMobile, IconButton, Text, useLocation } from '@plandok/core'
import { IntlDate, IntlLabel } from '@plandok/i18n'
import { ModifyMassLayout } from 'components'
import { RoutePath } from 'constants/routes'
import { flow, noop } from 'lodash'
import React, { useState } from 'react'

import * as SC from '../styles'
import CustomTimeDatePicker from './CustomTimeDatePicker'

export default function SheduleSendCustomTmeForm(props: any) {
   const [startDate, setStartDate] = useState(new Date())
   const { navigate, goBack } = useLocation()

   const redirectBack = () => navigate(RoutePath.SCHEDULE_SEND)
   const payment = () => navigate(RoutePath.PAYMENT_DETILES)
   const isMobile = getIsMobile()
   const handleSubmit = (form: any) =>
      props.onSubmit({
         ...form,
         price: form.price || 0,
      })
   const setCustom = (e: any) => {
      setStartDate(e)
   }

   return (
      <>
         <Form
            onSubmit={handleSubmit}
            onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
            initialValues={props.initialValues}>
            {({ formError, submitting }: any) => (
               <>
                  <SC.CardContainer>
                     <SC.RedirectBtn onClick={redirectBack}>
                        <IconButton icon="left" />
                     </SC.RedirectBtn>
                     <SC.HeaderTitle>
                        <Text weight="semiBold" size="mlarge" mb="none">
                           <IntlLabel label="Schedule send" />
                        </Text>
                        <Text size="base" weight="normal">
                           <IntlLabel label="Pick time you want your message to be send" />
                        </Text>
                     </SC.HeaderTitle>
                     <SC.CustomTimeContainer>
                        <CustomTimeDatePicker onChange={setCustom} selected={startDate} />
                        <SC.CustomTimeProps>
                           <Field.CustomInput name="customdate" date={startDate} />
                           <Field.CustomInput name="customtime" hour={true} date={startDate} />
                           <SC.CustomTimeBtn>
                              <Icon type="check" />
                              <Text size="base" weight="normal" mb="none">
                                 <IntlLabel label="Time set to:" />
                              </Text>
                              <Text size="base" weight="semiBold">
                                 <IntlDate date={startDate} dateFormat="MMM dd, yyyy, hh:mm" />
                              </Text>
                              <Button
                                 label="Set time"
                                 upperCase={false}
                                 type="primary"
                                 htmlType="submit"
                                 loading={submitting}
                              />
                           </SC.CustomTimeBtn>
                        </SC.CustomTimeProps>
                     </SC.CustomTimeContainer>
                  </SC.CardContainer>
                  <Form.Alert text={formError} />
                  <ModifyMassLayout.Footer>
                     <Button block type="primary" ghost upperCase={false} onClick={goBack}>
                        <Icon type="arrow-left" style={{ marginRight: '10px' }} />
                        <IntlLabel label={isMobile ? 'Provious' : 'Client selection'} />
                     </Button>
                     <Button
                        block
                        label={isMobile ? 'Next' : 'Payment'}
                        type="primary"
                        upperCase={false}
                        onClick={payment}>
                        <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                     </Button>
                  </ModifyMassLayout.Footer>
               </>
            )}
         </Form>
      </>
   )
}
