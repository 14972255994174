import { PriceType } from './constants'

export const extractPlans = (plans: any[]) => ({
   freePlan: plans.find(({ type }) => type === PriceType.FREE),
   premiumPlan: plans.find(({ type }) => type === PriceType.PREMIUM),
})

export const getCardIconByBrand = (brand: string): any => {
   switch (brand) {
      case 'visa':
         return 'visa'
      case 'mastercard':
         return 'mastercard'
      case 'maestro':
         return 'maestro'
      case 'affirm':
         return 'affirm'
      case 'alipay':
         return 'alipay'
      case 'amazonpay':
         return 'amazonpay'
      case 'amex':
         return 'amex'
      case 'applepay':
         return 'applepay'
      case 'bancontact':
         return 'bancontact'
      case 'bitcoin':
         return 'bitcoin'
      case 'bitcoincash':
         return 'bitcoincash'
      case 'bitpay':
         return 'bitpay'
      case 'citadele':
         return 'citadele'
      case 'diners':
         return 'diners'
      case 'discover':
         return 'discover'
      case 'elo':
         return 'elo'
      case 'etherium':
         return 'etherium'
      case 'forbrugsforeningen':
         return 'forbrugsforeningen'
      case 'giropay':
         return 'giropay'
      case 'googlepay':
         return 'googlepay'
      case 'ideal':
         return 'ideal'
      case 'interac':
         return 'interac'
      case 'klarna':
         return 'klarna'
      case 'lightcoin':
         return 'lightcoin'
      case 'payoneer':
         return 'payoneer'
      case 'paypal':
         return 'paypal'
      case 'paysafe':
         return 'paysafe'
      case 'poli':
         return 'poli'
      case 'qiwi':
         return 'qiwi'
      case 'sepa':
         return 'sepa'
      case 'shoppay':
         return 'shoppay'
      case 'skrill':
         return 'skrill'
      case 'sofort':
         return 'sofort'
      case 'stripe':
         return 'stripe'
      case 'unionpay':
         return 'unionpay'
      case 'venmo':
         return 'venmo'
      case 'verifone':
         return 'verifone'
      case 'webmoney':
         return 'webmoney'
      case 'wechat':
         return 'wechat'
      case 'yandex':
         return 'yandex'
      default:
         return 'defaultCard'
   }
}
