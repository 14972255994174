import { Button, Field, Form, Text, useModal, withModal } from '@plandok/core'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import * as api from 'api'
import { flow, noop } from 'lodash'
import React, { useState } from 'react'

import { PermissionType } from '../../../../constants/auth'
import { mutate, mutation } from '../../../../graphql'
import * as SC from '../styles'

type ModalProps = {
   id: string
   recurrenceId: string
   onSuccess: () => void
}

type CancelAppointmentModalProps = {
   onClose: () => void
   modalProps: ModalProps
}

function CancelAppointmentModal({ onClose, modalProps }: CancelAppointmentModalProps) {
   const [, { showModal, hideModal }]: any = useModal()
   const [isChecked, setIsChecked] = useState(true)

   const recurrenceId = modalProps.recurrenceId ?? ''

   const onChange = (event: CheckboxChangeEvent) => setIsChecked(event.target.checked)

   const onSubmitWithRepeat = (event: { cancellationReasonId: string }) =>
      mutate(mutation.CANCEL_RECURRING_APPOINTMENT)({
         cancellationReasonId: event.cancellationReasonId,
         recurrenceId,
         appointmentId: modalProps.id,
      })

   return (
      <Form
         onSubmit={flow(
            recurrenceId && isChecked ? onSubmitWithRepeat : (form: any) => api.closeAppointment(modalProps.id, form),
         )}
         onSuccess={flow(onClose, modalProps.onSuccess || noop)}>
         {({ submitting }: any) => (
            <>
               <Field.AsyncSelect
                  name="cancellationReasonId"
                  label="input.cancelReason.label"
                  asyncType="cancelReason"
                  selectFirstAsDefault
                  addBtn="btn.new.cancelReason"
                  section={PermissionType.CLIENT_SETTINGS}
                  modalType="CREATE_CANCEL_REASON"
                  showModal={showModal}
                  hideModal={hideModal}
               />
               {recurrenceId && (
                  <SC.StyledCheckbox checked={isChecked} onChange={onChange}>
                     <Text
                        as="span"
                        size="base"
                        colorType="base"
                        weight="normal"
                        mb="none"
                        label="rp.cancel.upcoming.title"
                     />
                  </SC.StyledCheckbox>
               )}
               <SC.StyledActionRow>
                  <Button htmlType="submit" label="btn.cancelAppointment" loading={submitting} minorBtn smallRadius />
               </SC.StyledActionRow>
            </>
         )}
      </Form>
   )
}

export default withModal('btn.cancelAppointment.title', {
   editLabel: 'modal.cancelAppointment.title',
})(CancelAppointmentModal)
