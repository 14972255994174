import { IntlLabel } from '@plandok/i18n'
import { LinkWithLanguage } from 'components'
import { RoutePath } from 'constants/routes'
import React from 'react'

export default function SetNewPasswordFooter() {
   return (
      <div className="text-center">
         <div data-footer-info>
            <IntlLabel label="text.havingTrouble" />
            <LinkWithLanguage to={RoutePath.RESET_PASSWORD} label="Reset your password again" />
         </div>
      </div>
   )
}
