import { IconButton } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { RoutePath } from 'constants/routes'
import React from 'react'

export default function MobileBackButton(props: { path?: RoutePath }) {
   const { goBack, navigate } = useLocation()

   const redirectBack = props.path ? () => navigate(props.path as RoutePath) : goBack

   return (
      <div onClick={redirectBack}>
         <IconButton icon="left" />
      </div>
   )
}
