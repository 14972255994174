import { endOfWeek, startOfWeek } from 'date-fns'

import { formatDateField } from '../../../../helpers/date/field'

export const getFilterParams = (date: Date, location?: string | null) => {
   return {
      from_date: formatDateField(startOfWeek(date, { weekStartsOn: 1 })),
      date_to: formatDateField(endOfWeek(date, { weekStartsOn: 1 })),
      location_id: location,
   }
}
