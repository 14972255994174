import { CustomIcon, Text } from '@plandok/core'
import { getPlanPriceByStaffCount } from 'helpers/common'
import React, { useState } from 'react'

import * as SC from '../Modal/styles'
import { TABLE_COLUMNS, TABLE_DATA } from '../PricingCalculate'

type PricingCalculationProps = {
   staffCount?: number
}

export default function PricingCalculation({ staffCount }: PricingCalculationProps) {
   const defaultCountOfStaff = 1
   const [modalStaffCount, setModalStaffCount] = useState(staffCount || defaultCountOfStaff)

   const decrement = () => {
      if (modalStaffCount === defaultCountOfStaff) {
         return
      }

      setModalStaffCount(modalStaffCount => modalStaffCount - defaultCountOfStaff)
   }

   const increment = () => setModalStaffCount(modalStaffCount => modalStaffCount + defaultCountOfStaff)

   return (
      <div className="text-center">
         <SC.DependingDescription colorType="base" label="premium.membership" />
         <SC.PriceCalculatedDescription colorType="base" label="pricing.calculated" mb="xmedium" />
         <SC.CustomTable columns={TABLE_COLUMNS} dataSource={TABLE_DATA} pagination={false} />
         <SC.PriceCalculator size="mlarge" weight="semiBold" label="pricing.calculator" mb="medium" />
         <SC.ContainerCounter>
            <Text label="pricing.numberStuff" mb="none" size="medium" lh="mlarge" style={{ marginRight: '16px' }} />
            <div onClick={decrement}>
               <CustomIcon type="decreaseCircleIcon" />
            </div>
            <SC.InputCalc type="text" value={modalStaffCount} readOnly />
            <div onClick={increment}>
               <CustomIcon type="increaseCircleIcon" />
            </div>
         </SC.ContainerCounter>
         <SC.WrapperPrice>
            <Text colorType="base" weight="bold" mb="none">
               {getPlanPriceByStaffCount(modalStaffCount)}€
            </Text>
            <Text colorType="base" label="period.perMonth" mb="none" size="small" ml="xxsmall" lh="base" />
         </SC.WrapperPrice>
      </div>
   )
}
