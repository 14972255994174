import { formatTime } from '@plandok/core'
import { addDays, isSameDay, startOfWeek } from 'date-fns'
import { parseDateField } from 'helpers/date/field'
import { getIsInWorkingHourRange } from 'helpers/date/work-hours'
import { IEmployeeScheduleRange } from 'types'

export const extractWorkTableData = (data: IEmployeeScheduleRange, date: Date, timeFormat: string) => {
   const fromWeek = startOfWeek(date, { weekStartsOn: 1 })

   const ranges =
      data.scheduleRanges?.map(e => ({
         id: data.id,
         startDate: parseDateField(e.startDate) || new Date(),
         endDate: parseDateField(e.endDate),
         shifts: e.shifts?.sort((a: any, b: any) => a!.startTime - b!.startTime),
      })) || []

   return [...Array(7)].map((_, i) => {
      const currentDate = addDays(fromWeek, i)
      const elem = ranges.find(e => getIsInWorkingHourRange(e, currentDate))

      return {
         element: elem,
         totalTime: elem?.shifts?.reduce((acc, e: any) => acc + (e.endTime - e.startTime), 0) || 0,
         shifts:
            elem?.shifts
               ?.sort((a: any, b: any) => a!.startTime - b!.startTime)
               ?.map?.(
                  e => `${formatTime(e.startTime as any, timeFormat)} - ${formatTime(e.endTime as any, timeFormat)}`,
               ) ?? [],
      }
   })
}

export const formatShiftInfoToForm = (data: any, locationId: string) => {
   const repeat =
      data.startDate &&
      data.endDate &&
      isSameDay(parseDateField(data.startDate) || new Date(), parseDateField(data.endDate) || new Date())
         ? 'norepeat'
         : 'weekly'

   const endRepeat = repeat === 'weekly' ? (!data.endDate ? 'ongoing' : 'specific') : null

   return {
      locationId,
      endRepeatDate: data.endDate,
      startDate: data.startDate,
      repeat,
      endRepeat,
      shiftStart: data?.shifts?.[0]?.startTime,
      shiftEnd: data?.shifts?.[0]?.endTime,
      shiftStartSecond: data?.shifts?.[1]?.startTime,
      shiftEndSecond: data?.shifts?.[1]?.endTime,
      shiftStartThird: data?.shifts?.[2]?.startTime,
      shiftEndThird: data?.shifts?.[2]?.endTime,
      shiftStartFourth: data?.shifts?.[3]?.startTime,
      shiftEndFourth: data?.shifts?.[3]?.endTime,
   }
}
