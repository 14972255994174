import { CustomIcon, Text } from '@plandok/core'
import React from 'react'

import * as SC from '../styles'

interface ITemplateStatusBadge {
   status: string
}

const getStatusText = (status: string) => {
   switch (status) {
      case 'validate':
         return 'notifications.template.badge.validate'
      case 'pending':
         return 'notification.status.pending'
      case 'refused':
         return 'notifications.template.badge.refused'
      default:
         return 'notification.status.pending'
   }
}

const getStatusIcon = (status: string) => {
   switch (status) {
      case 'validate':
         return 'activeCardCircle'
      case 'pending':
         return 'pendingCardCircle'
      case 'refused':
         return 'inactiveCardCircle'
      default:
         return 'pendingCardCircle'
   }
}

const getStatusColor = (status: string) => {
   switch (status) {
      case 'validate':
         return '#6bce3c'
      case 'pending':
         return '#FFC107'
      case 'refused':
         return '#c2c2c2'
      default:
         return '#FFC107'
   }
}

const TemplateStatusBadge = ({ status }: ITemplateStatusBadge) => {
   return (
      <SC.BadgeContainer theme={{ color: getStatusColor(status) }}>
         <CustomIcon type={getStatusIcon(status)} />
         <Text label={getStatusText(status)} mb="none" weight="medium" size="medium" ml="xxsmall" />
      </SC.BadgeContainer>
   )
}

export default TemplateStatusBadge
