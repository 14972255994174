import { Button, composeValidators, Field, Form, validateEmail, validateRequired } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { message } from 'antd'
import * as api from 'api'
import React from 'react'

export default function ResetPasswordForm() {
   const onSuccess = () => message.success(<IntlLabel label="message.successReset.password" />)

   return (
      <Form onSubmit={api.requestPasswordChange} onSuccess={onSuccess}>
         {({ submitting, formError }: any) => (
            <>
               <Field.Input
                  name="email"
                  label="input.email.label"
                  placeholder="input.email.placeholder"
                  validate={composeValidators(validateRequired, validateEmail)}
                  submitting={submitting}
               />
               <Form.Alert text={formError} />
               <Button type="primary" block htmlType="submit" loading={submitting} label="reset.btn.text" />
            </>
         )}
      </Form>
   )
}
