import { IntlLabel } from '@plandok/i18n'
import React from 'react'

import * as SC from '../styles'
import IconEmptyClientList from './IconEmptyClientList'

export default function EmptyListHolder() {
   return (
      <SC.EmptyContainer isPadding>
         <i>
            <IconEmptyClientList />
         </i>
         <div>
            <IntlLabel label="appointment.empty.list" />
         </div>
      </SC.EmptyContainer>
   )
}
