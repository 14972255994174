import { Text } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlTemplate } from '@plandok/i18n'
import { Menu, Spin } from 'antd'
import { BillingTabHash, RoutePath } from 'constants/routes'
import { useGetEstimatedCosts } from 'hooks/costs'
import upperFirst from 'lodash/upperFirst'
import React from 'react'

import * as SC from '../styles'

type HeaderUserMenuUsageProps = {
   goToPricingPage: () => void
}

export default function HeaderUserMenuUsage({ goToPricingPage }: HeaderUserMenuUsageProps) {
   const { location, navigate }: any = useLocation()

   const { estimatedCostsData, isLoading } = useGetEstimatedCosts()

   const estimatedCostsPath = `${RoutePath.BILLING}#${BillingTabHash.COSTS}`
   const estimatedCostsPage = `${location.pathname}${location.hash}` === estimatedCostsPath
   const subscription = estimatedCostsData?.subscription
   const totalCharge = `${estimatedCostsData?.message?.totalCharge?.toFixed(2) || 0}€`
   const formatPlanType = upperFirst(subscription?.planType || '')
   const formatEndDate = subscription?.endDate?.replaceAll('-', '.') || ''
   const totalCount = estimatedCostsData?.message?.totalCount || 0
   const total = `${estimatedCostsData?.total?.toFixed(2) || 0}€`
   const taxes = `${estimatedCostsData?.taxes || 0}€`
   const staffCount = subscription?.staffCount || ''
   const vat = estimatedCostsData?.vat || 0

   const goToEstimatedCostsPage = () => navigate(estimatedCostsPath)

   return (
      <Spin spinning={isLoading}>
         <Menu className="app-common-dropdown">
            <SC.UsageContainer>
               <SC.SubscriptionTitleContainer>
                  <Text mb="none" size="medium" weight="medium" lh="mlarge" label="payment.tab.title.sub" />
                  {subscription?.planType !== 'free' && (
                     <Text mb="small" size="small" lh="base" colorType="grey">
                        <IntlTemplate label="plan.premium.activeUntilDate" params={[formatEndDate]} />
                     </Text>
                  )}
                  <SC.SubscriptionDescriptionContainer>
                     <div className="d-flex">
                        <SC.PlanType mb="none" size="small" lh="large" onClick={goToPricingPage} color="#2472E8">
                           <IntlTemplate label="header.dropdown.subscription.plan" params={[formatPlanType]} />
                        </SC.PlanType>
                        <Text mb="none" size="small" lh="large" ml="xxsmall">
                           <IntlTemplate label="header.dropdown.subscription.staff" params={[staffCount]} />
                        </Text>
                     </div>

                     <Text mb="none" size="small" lh="base">
                        {estimatedCostsData?.subscription?.total?.toFixed(2) || 0}€
                     </Text>
                  </SC.SubscriptionDescriptionContainer>
               </SC.SubscriptionTitleContainer>

               {estimatedCostsData?.message?.hasUnpaid && (
                  <SC.MessagesContainer>
                     <Text
                        mb="none"
                        size="medium"
                        weight="medium"
                        lh="mlarge"
                        style={{ marginBottom: '6px' }}
                        label="payment.tab.title.notification"
                     />
                     <div className="justify-align">
                        <Text mb="none" size="small" lh="large">
                           <IntlTemplate label="header.dropdown.notifications.description" params={[totalCount]} />
                        </Text>
                        <Text mb="none" size="small" lh="base">
                           {totalCharge}
                        </Text>
                     </div>
                  </SC.MessagesContainer>
               )}

               <SC.TaxesContainer>
                  <Text mb="none" size="base" lh="small">
                     <IntlTemplate label="appointment.vat" params={[vat]} />
                  </Text>
                  <Text mb="none" size="base" lh="small" weight="semiBold">
                     {taxes}
                  </Text>
               </SC.TaxesContainer>

               <SC.TotalContainer>
                  <Text mb="none" label="appointment.total" size="base" lh="small" />
                  <Text mb="none" size="base" lh="small" weight="semiBold">
                     {total}
                  </Text>
               </SC.TotalContainer>

               {!estimatedCostsPage && (
                  <SC.ViewPaymentDetails
                     mb="none"
                     label="header.paymentDetails"
                     size="base"
                     lh="large"
                     onClick={goToEstimatedCostsPage}
                     color="#2472E8"
                  />
               )}
            </SC.UsageContainer>
         </Menu>
      </Spin>
   )
}
