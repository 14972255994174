import { mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

type PlaceholderContainerProps = {
   direction: boolean
}

export const PlaceholderContainer = styled.div<PlaceholderContainerProps>`
   text-align: center;
   width: 100%;
   max-width: 1200px;
   margin-left: ${({ direction }) => (direction ? '10px' : '180px')};
   display: flex;
   flex-direction: ${({ direction }) => (direction ? 'row' : 'row-reverse')};
   justify-content: center;
   align-items: center;
   [data-marketing-placeholder-mobile-title] {
      display: none;
   }
   [data-placeholder-column-img] img {
      height: 448px;
      width: 531px;
      margin-top: 70px;
   }
   [data-placeholder-column-info] button {
      min-width: 180px;
      margin-top: 32px;
   }
   [data-placeholder-column-info] {
      h2 {
         color: #13316d;
         font-size: 56px;
         font-weight: 600;
         text-align: left;
         margin: 0px;
         width: ${({ direction }) => (direction ? '700px' : '500px')};
      }
      div {
         color: #13316d;
         font-size: 18px;
         font-weight: 400;
         text-align: left;
         width: ${({ direction }) => (direction ? '600px' : '526px')};
      }
   }

   ${mediaMobileDown(css<any>`
      flex-direction: column;
      text-align: center;
      padding: 0 40px;
      margin: 20px 0 0 0;
      [data-placeholder-column-img] {
         order: 1;
      }
      [data-placeholder-column-img] img {
         height: 39px;
         width: 44px;
         margin-top: 0px;
      }
      [data-marketing-placeholder-mobile-title] {
         display: block;
         order: 2;
      }
      [data-placeholder-column-info] button {
         display: none;
      }
      [data-placeholder-column-info] {
         order: 3;
         div {
            text-align: center;
            font-size: 14px;
            line-height: 1.5;
            width: ${({ direction }) => (direction ? '280px' : '260px')};
            margin-bottom: 22px;
         }
         h2 {
            display: none;
         }
      }
   `)};
`

export const Container = styled.div`
   ${mediaMobileDown(css`
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
   `)};
`

export const ActionContainer = styled.div`
   display: none;
   ${mediaMobileDown(css`
      display: flex;
      justify-content: center;
      margin-bottom: 90px;
      button {
         min-width: 180px;
      }
   `)};
`
