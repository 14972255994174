import { CustomIcon, Text } from '@plandok/core'
import { IntlTemplate } from '@plandok/i18n'
import PricingItem from 'pages/support/PlansPage/components/PricingItem'
import React from 'react'

import { PriceType } from './constants'
import * as SC from './styles'

const ActivePlanIndicator = (planType: string) => (
   <SC.ActivePlanIndicator theme={{ planType }}>
      <div>
         <CustomIcon type="checkmark" />
      </div>
      <Text size="medium" weight="medium" lh="mlarge" mb="none" label="btn.currentPlan" style={{ color: '#2472E8' }} />
   </SC.ActivePlanIndicator>
)

export const PricingItemGroup = ({ planType, ...props }: any) =>
   planType === PriceType.FREE ? (
      <div>
         {props.active && ActivePlanIndicator(planType)}
         <PricingItem
            planName="plan.foreverFree"
            advantages={[
               'plan.free.option.0',
               'plan.free.option.1',
               'plan.free.option.2',
               'plan.free.option.3',
               'plan.free.option.4',
               'plan.free.option.5',
            ]}
            planType={PriceType.FREE}
            isFree
            {...props}
         />
      </div>
   ) : (
      <div>
         {props.active && (
            <div className="text-center">
               {ActivePlanIndicator(planType)}
               <Text mb="small" size="medium" lh="mlarge">
                  <IntlTemplate label="plan.premium.activeUntilDate" params={[props?.endDate]} />
               </Text>
            </div>
         )}
         <PricingItem
            planName="analytics.premium.title"
            advantages={[
               'plan.premium.option.0',
               'plan.free.option.2',
               'plan.free.option.3',
               'plan.free.option.4',
               'plan.premium.option.1',
               'plan.premium.option.2',
               'plan.premium.option.3',
               'plan.premium.option.4',
               'plan.premium.option.5',
               'plan.premium.option.6',
               'plan.premium.option.7',
               'plan.premium.option.8',
               'plan.premium.option.9',
               'plan.premium.option.10',
               'plan.premium.option.11',
               'plan.premium.option.12',
               'plan.premium.option.13',
               'plan.premium.option.14',
            ]}
            planType={PriceType.PREMIUM}
            {...props}
         />
      </div>
   )
