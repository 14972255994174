import { Card } from '@plandok/core'
import React from 'react'
import { ReactNode } from 'react'

export default function TableContainer(props: { children: ReactNode }) {
   return (
      <Card withShadow noPadding padding="10px">
         <div className="ant-table ant-table-default ant-table-scroll-position-left">
            <div className="ant-table-content">
               <table style={{ tableLayout: 'auto', minWidth: '100%' }} className="ant-table">
                  {props.children}
               </table>
            </div>
         </div>
      </Card>
   )
}
