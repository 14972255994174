import { IntlMoney } from '@plandok/i18n'
import React from 'react'

import { formatDuration } from '../../../../../helpers/date'
import * as SC from './styles'

export default function ServiceOption({ value = {}, title = '' }: any) {
   return (
      <SC.Container color={value.serviceGroup.color} isSpacing={!!title}>
         {!!title && (
            <div className="ant-select-dropdown-menu-item-group-title" title={title}>
               {title}
            </div>
         )}
         <SC.Content>
            <div data-service-option-first-column>
               <div data-service-option-name>{value.name}</div>
               <div data-service-option-time>{formatDuration(value.duration)}</div>
            </div>
            <div data-service-option-money>
               <IntlMoney value={value.price} />
            </div>
         </SC.Content>
      </SC.Container>
   )
}
