import { IntlLabel } from '@plandok/i18n'
import { MenuItem } from 'components'
import React from 'react'
import { useStore } from 'store/store'

import SidebarIcons from './SidebarIcons'
import { ButtonProps } from './SupportButton'

export default function UpdateButton({ collapsed, isFixed, ...props }: ButtonProps) {
   const state = useStore()

   const showUpdateNotification = () => {
      state.setSystems({ displayUpdateButton: false, displayUpdateAlert: true })
      localStorage.setItem('isHiddenNewVersionNotification1', 'false')
   }

   return (
      <MenuItem
         {...props}
         style={{
            position: isFixed ? 'fixed' : 'inherit',
            width: isFixed ? '111px' : '108px',
            bottom: '80px',
         }}
         title={<IntlLabel label="app.update.button.title" />}
         siderCollapsed={collapsed}
         onClick={showUpdateNotification}>
         <div data-sidebar-update-icon>
            <i>{SidebarIcons.update}</i>
            <span>
               <IntlLabel label="app.update.button.title" />
            </span>
         </div>
      </MenuItem>
   )
}
