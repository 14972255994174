import { Button, Link, Text } from '@plandok/core'
import { IntlLabel, LabelKey } from '@plandok/i18n'
import { RoutePath } from 'constants/routes'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

const SPlaceholderItem = styled.div<any>`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: ${({ position = 'absolute' }) => position};
   left: 0;
   right: 0;
   bottom: 0;
   top: 0;
`

const SPlaceholderContainer = styled.div`
   text-align: center;
   max-width: 670px;
   width: 100%;
   p {
      color: #61749d;
      margin-bottom: 30px;
   }
`

const SPlaceholderImage = styled.div`
   text-align: center;
   width: 100%;
   margin-bottom: 20px;
`

export interface PlaceholderProps {
   imgName: string
   titleLabel?: LabelKey
   descriptionLabel?: LabelKey
   btnLabel?: LabelKey
   linkLabel?: any
   path?: RoutePath
   onClick?: any
   position?: string
   containerComponent?: any
   templateOptions?: any
   mobileTitleContent?: ReactNode
   direction?: boolean
}

export default function Placeholder(props: PlaceholderProps) {
   const ContainerComponent = props.containerComponent || SPlaceholderContainer

   return (
      <SPlaceholderItem position={props.position}>
         <ContainerComponent direction={props.direction}>
            {props.mobileTitleContent}
            <SPlaceholderImage data-placeholder-column data-placeholder-column-img>
               <img src={`/img/illustrations/${props.imgName}.svg`} alt="placeholder" />
            </SPlaceholderImage>
            <div data-placeholder-column data-placeholder-column-info>
               <Text size="mlarge" weight="semiBold" isCenter>
                  {props.titleLabel && <IntlLabel label={props.titleLabel} />}
               </Text>
               <Text size="base" weight="medium" isCenter>
                  {props.descriptionLabel && (
                     <IntlLabel label={props.descriptionLabel} isTemplate={props.templateOptions?.description} />
                  )}
                  {props.path && <Link label={props.linkLabel} to={props.path} />}
               </Text>
               {props.onClick && (
                  <div>
                     <Button
                        minorBtn
                        onClick={props.onClick}
                        data-button-create
                        type="primary"
                        htmlType="submit"
                        smallRadius
                        style={{ minWidth: '244px' }}>
                        <IntlLabel label={props.btnLabel} />
                     </Button>
                  </div>
               )}
            </div>
         </ContainerComponent>
      </SPlaceholderItem>
   )
}
