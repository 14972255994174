import { AsyncSelectInput } from '@plandok/core'
import * as api from 'api'
import { AppSetupList, NoInternetConnection } from 'components'
import React, { useCallback } from 'react'
import { useStore } from 'store/store'

import { resourcesColumn } from './config'

export default function ResourcesPage() {
   const locationId = useStore(useCallback(state => state.locationId, []))
   const setLocationId = useStore(useCallback(state => state.setLocationId, []))

   return (
      <NoInternetConnection>
         {locationId && (
            <AppSetupList
               btnLabel="btn.new.resource"
               columns={resourcesColumn}
               title="resources.list.title"
               alwaysAllowDelete
               controlItem={
                  <AsyncSelectInput
                     asyncType="location"
                     className="resource-select-input"
                     input={{ value: locationId, onChange: (selected: any) => setLocationId(selected) }}
                     selectFirstAsDefault
                     hideForOne
                  />
               }
               modalType="CREATE_RESOURCE"
               fetchData={api.fetchResources}
               filterParams={{ 'filter[location_id]': locationId }}
               initialValues={{ locationId: locationId }}
               placeholder={{
                  imgName: 'resources',
                  titleLabel: 'state.resources.title',
                  descriptionLabel: 'state.resources.description',
                  btnLabel: 'state.resources.button',
               }}
            />
         )}
      </NoInternetConnection>
   )
}
