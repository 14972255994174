import { SidebarType } from '../components/app/AppLayout/types'
import { PermissionType } from '../constants/auth'
import { RoutePath } from '../constants/routes'

export const sidebarConfig: SidebarType[] = [
   { iconType: 'calendar', title: 'sidebar.calendar', path: RoutePath.CALENDAR },
   { iconType: 'client', title: 'sidebar.client', path: RoutePath.CLIENTS, permission: PermissionType.CLIENTS },
   {
      iconType: 'staff',
      title: 'sidebar.staff',
      path: RoutePath.STAFF,
      permission: PermissionType.EMPLOYEES,
      mobilePath: RoutePath.STAFF_SELECT,
   },
   { iconType: 'service', title: 'sidebar.service', path: RoutePath.SERVICES, permission: PermissionType.SERVICES },
   {
      iconType: 'notification',
      title: 'sidebar.notification',
      path: RoutePath.CLIENT_MESSAGES,
      mobilePath: RoutePath.MESSAGES_MOBILE,
      permission: PermissionType.MESSAGES,
   },
   { iconType: 'settings', title: 'sidebar.settings', path: RoutePath.SETTINGS },
   {
      iconType: 'booking',
      title: 'ob.title',
      path: RoutePath.BOOKING,
      permission: PermissionType.ONLINE_BOOKINGS,
      mobilePath: RoutePath.BOOKING_MOBILE,
   },
   {
      iconType: 'analytics',
      title: 'sidebar.analytics',
      path: RoutePath.ANALYTICS,
      permission: PermissionType.ANALYTICS,
      mobilePath: RoutePath.ANALYTICS_MOBILE,
      isPremium: false,
   },
   {
      iconType: 'marketing',
      title: 'title.marketing.permission',
      path: RoutePath.MARKETING,
      permission: PermissionType.MARKETING,
      mobilePath: RoutePath.MARKETING_MOBILE,
      notReleased: true,
   },
]
