import { OptionType } from '../../../../../constants/select'
import { countryOptions, phonePrefixOptions } from '../../../../../constants/data'

export interface CombinedValue {
   prefix?: string
   value?: string
}

export const extractValue = (value: any, defaultValue: string) => {
   const val = (value || '').split('-')

   return {
      prefix: val[0] || defaultValue || '+370',
      value: val[1] || '',
   }
}

export const onValueChange =
   (onChange: (a: any) => any, currentVal: any | undefined, options: OptionType[] | undefined, defaultValue: string) =>
   ({ target }: any) => {
      const { prefix } = extractValue(currentVal, defaultValue)
      const { value } = target
      return onChange(`${prefix || '+370'}-${value}`)
   }

export const onPrefixChange =
   (onChange: (a: any) => void, currentVal: CombinedValue | undefined, defaultValue: string) => (nextValue: string) => {
      const { value } = extractValue(currentVal, defaultValue)
      return onChange(`${nextValue || ''}-${value || ''}`)
   }

export const findDefaultPrefix = (country: string) => {
   const countryName = countryOptions.find(e => e?.code === country)?.name
   const phonePrefix = countryName && phonePrefixOptions.find(e => e?.country?.includes(countryName))?.value
   return phonePrefix ?? '+370'
}
