import styled, { css } from 'styled-components'
import { mediaMobileDown } from '../../../../helpers/styles'

type ContainerProps = {
   spaced: boolean
   size: string
}

export const Input = styled.input`
   background: inherit;
   border: none;
   outline: none;
   border-bottom: 1px solid #adb6cc;
   font-size: 30px;
   width: 100%;
   color: ${({ theme }) => theme.primaryColor};
   ::placeholder {
      color: #adb6cc;
   }

   ${mediaMobileDown(css`
      font-size: 20px;
   `)}
`

export const Line = styled.div`
   position: absolute;
   bottom: 0;
   height: 2px;
   max-width: 100%;
   background: ${({ theme }) => theme.primaryColor};
`

export const HiddenText = styled.span`
   visibility: hidden;
   position: absolute;
   font-size: 30px;

   ${mediaMobileDown(css`
      font-size: 20px;
   `)}
`

export const Container = styled.div<ContainerProps>`
   position: relative;
   width: 100%;
   padding-left: ${({ spaced }) => (spaced ? '1rem' : 'inherit')};

   ${Input}, & + ${HiddenText} {
      ${({ size }) =>
         size === 'large' &&
         css`
            font-size: 50px;

            ${mediaMobileDown(css`
               font-size: 30px;
            `)}
         `}
   }
`
