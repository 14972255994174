import { Card, mediaLgUp, mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const CardContainer = styled(Card)`
   max-width: 1230px;
   margin: auto;
   box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
   padding: 35px 35px 20px 35px;
   border-radius: 20px;
   input[type='number']::-webkit-outer-spin-button,
   input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   ${mediaMobileDown(css`
      max-width: 355px;
      padding: 0 15px;
      margin-top: 100px;
   `)}
`

export const Text = styled.div`
   div {
      margin-bottom: 5px;
   }
   ${mediaMobileDown(css`
      padding-top: 30px;
   `)}
`

export const EmailContainer = styled.div`
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: baseline;
   justify-content: space-between;
   margin-bottom: 30px;
   div {
      color: #b3bcd0;
      margin-left: 6px;
      margin-top: -14px;
   }
`

export const ActionContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;
   button {
      min-width: 250px;
      margin-bottom: 10px;
   }
   ${mediaMobileDown(css`
      margin: 20px 0;
   `)}
`

export const MessagePreviewContainer = styled.div`
   border: 1px solid #adb6cc;
   box-sizing: border-box;
   border-radius: 10px;
   width: 100%;
   min-height: 504px;
   padding: 70px 70px 0;
   margin-bottom: 30px;
   display: flex;
   flex-direction: column;
   align-items: center;

   svg:first-child {
      margin-bottom: 40px;
   }
   .ant-form-explain,
   .ant-form-item-label {
      display: none;
   }
   ${mediaMobileDown(css`
      min-height: 380px;
      padding: 50px 10px 30px 10px;
   `)}
`

export const CheckboxList = styled.div`
   margin-bottom: 30px;
   button.ant-switch.ant-switch-checked.info-checkbox {
      background: #13316d;
   }
`

export const Pricing = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   margin-right: 10px;
   margin-bottom: 20px;
   color: #13316d;
   font-size: 16px;
   font-weight: 500;
   cursor: pointer;
   text-decoration: underline;
`

export const enabledSocialList = styled.div`
   margin: 35px 0;
   input {
      color: #13316d;
   }
   svg {
      fill: #b5c3e1;
      display: flex;
   }
`

export const socialMediaIcon = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   cursor: pointer;
   svg {
      width: 30px;
      height: 30px;
      fill: #13316d;
      text-align: center;
      margin: 0 10px;
   }
`

export const ScrollContent = styled.div`
   ${mediaLgUp(css`
      overflow-y: auto;
      max-height: 73vh;

      &::-webkit-scrollbar {
         width: 0.2rem;
         height: 0.2rem;
         margin-top: 1rem;
         margin-bottom: 1rem;
      }
      &::-webkit-scrollbar-track-piece {
         background-color: #e9ecf3;
      }
      &::-webkit-scrollbar-thumb {
         background: #adb6cc;
         border-radius: 10px;
      }
   `)}
`
