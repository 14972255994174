import React, { useMemo, useEffect, useRef, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Input, Select } from 'antd'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import { removeEmojiFromString } from '../../../../helpers/form'
import { phonePrefixOptions } from '../../../../constants/data'
import { OptionType } from '../../../../constants/select'
import withFormBlock from '../FormBlock/withFormBlock'
import { PhonePrefixOptions } from '../../../../types'
import { IoC } from '../../../../di.config'
import * as helpers from './helpers'
import { SearchDiv } from './styles'

interface FormCombinedInputProps extends FieldRenderProps<any, any> {
   submitting?: boolean
   preloadedPrefix?: string
   options?: OptionType[]
}

interface SelectBeforeProps {
   onChange?: (val: string) => void
   value?: string
   defaultValue?: string
   options?: OptionType[]
}

const SelectBefore = ({ options, ...props }: SelectBeforeProps) => {
   const defaultValue = props.defaultValue || '+370'
   const menuRef = useRef<any>(null)
   const selectRef = useRef<any>(null)
   const [isOpen, setIsOpen] = useState(false)
   const [searchVal, setSearchVal] = useState('')

   const contains = (root: Node | null | undefined, n: Node | null = null) => {
      if (!root) {
         return false
      }
      return root.contains(n)
   }

   const handleClickOutside = (event: MouseEvent) => {
      if (menuRef && menuRef.current && !menuRef.current.contains(event.target) && isOpen) {
         setIsOpen(false)
      }

      const selectDom = document.getElementsByClassName('phone-input-prefix')[0]
      const selectedDom = ReactDOM.findDOMNode(selectDom)
      if (selectedDom && contains(selectedDom, event.target as HTMLElement) && !isOpen) {
         setIsOpen(true)
      }
   }

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside, false)
      return () => {
         document.removeEventListener('mousedown', handleClickOutside, false)
      }
   })

   let filteredPhoneNumbers: PhonePrefixOptions[] = []

   if (searchVal.length > 0) {
      filteredPhoneNumbers = phonePrefixOptions.filter(
         item =>
            item.country.toUpperCase().indexOf(searchVal.toUpperCase().trim()) > -1 ||
            item.text.toUpperCase().toString().replace(/\s/g, '').indexOf(searchVal.toUpperCase()) > -1,
      )
   } else {
      filteredPhoneNumbers = phonePrefixOptions
   }

   return (
      <Select
         {...props}
         ref={selectRef}
         style={{ width: 100 }}
         defaultValue={defaultValue}
         value={props.value || defaultValue}
         placeholder={defaultValue}
         className={cn('phone-input-prefix', 'br-small-select')}
         popupClassName="phone-input--dropdown"
         open={isOpen}
         onSelect={() => setIsOpen(false)}
         dropdownRender={menu => (
            <div ref={menuRef}>
               <SearchDiv>
                  <Input.Search
                     className="ant-input-filter"
                     value={searchVal}
                     onChange={(e: any) => {
                        setSearchVal(e.target.value)
                     }}
                     placeholder="Search"
                  />
               </SearchDiv>
               {menu}
            </div>
         )}
      >
         {filteredPhoneNumbers.map((val: PhonePrefixOptions, index: number) => (
            <Select.Option value={val.text} key={index}>
               <span data-display-country>{val.country}</span> {val.text}
            </Select.Option>
         ))}
      </Select>
   )
}

const FormCombinedInput = ({
   input: { onChange, value: fullValue, ...input },
   submitting,
   options,
   preloadedPrefix,
   ...rest
}: FormCombinedInputProps) => {
   const country = IoC.getService().useCountry()
   const prefixDefault = useMemo(() => helpers.findDefaultPrefix(country), [country])
   const { prefix, value } = helpers.extractValue(fullValue, preloadedPrefix || prefixDefault)

   return (
      <Input
         addonBefore={SelectBefore({
            options,
            value: removeEmojiFromString(prefix),
            onChange: helpers.onPrefixChange(onChange, fullValue, prefixDefault),
            defaultValue: preloadedPrefix ? preloadedPrefix : prefixDefault,
         })}
         id={input.name}
         value={value}
         onChange={helpers.onValueChange(onChange, fullValue, options, preloadedPrefix || prefixDefault)}
         {...input}
         {...rest}
         className={cn('disable-number-controls', rest.className)}
         onWheel={event => {
            // @ts-ignore
            event?.target?.blur?.()
         }}
         type="number"
      />
   )
}

export default withFormBlock<FormCombinedInputProps>(FormCombinedInput)
