import { IntlLabel } from '@plandok/i18n'
import React from 'react'

export const columnsConfig = [
   {
      title: <IntlLabel label="table.header.refSourcetitle" />,
      dataIndex: 'name',
      key: 'name',
   },
   {
      title: <IntlLabel label="table.header.refSourceAddDate" />,
      dataIndex: 'date',
      key: 'date',
   },
]
