import { cutText, mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const Header = styled.div`
   display: flex;
   div {
      padding: 20px 0;
      color: ${props => props.theme.primaryColor};
      text-align: center;
      font-weight: 500;
      width: 200px;
      min-width: 200px;
      min-width: 200px;
      &[data-staff-item-name] {
         width: 300px;
         min-width: 300px;

         ${mediaMobileDown(css`
            min-width: 160px;
            width: 160px;
         `)}
      }
   }

   ${mediaMobileDown(css`
      height: 35px;
      div {
         padding: 0 0 10px 0;
      }
   `)}
`

export const UserCell = styled.div`
   display: flex;
   ${cutText as any};
   [data-staff-user-avatar] {
      margin-right: 10px;
   }
   [data-staff-user-info] div {
      ${cutText as any};
      font-size: 14px;
      max-width: 120px;
      line-height: 20px;
      color: ${props => props.theme.primaryColor};
      &:first-of-type {
         font-weight: 600;
         line-height: 20px;
      }
      &:last-of-type {
         font-size: 13px;
      }
   }
`

export const Photo = styled.img`
   width: 40px;
   height: 40px;
   border-radius: 50%;

   :hover {
      cursor: pointer;
   }
`

export const WorkItem = styled.div`
   background: #5fb0ff;
   color: white;
   font-size: 14px;
   border-radius: 8px;
   padding: 3px 15px 3px 15px;
   height: 100%;
   margin: 1px 1px;
   cursor: pointer;
`

export const Item = styled.div`
   display: flex;
   flex-direction: row;
   height: 60px;

   &:not(:last-of-type) {
      border-bottom: 1px solid #e9ecf3;
   }
   [data-staff-item-cell] {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      cursor: pointer;
      &:not(:last-of-type) {
         border-right: 1px solid #adb6cc;
      }
      &:hover {
         background: #f8f9fc;
      }
   }
   ${UserCell} {
      width: 300px;
      min-width: 300px;
      padding: 10px 10px 8px 15px;
      border-right: 1px solid #adb6cc;

      ${mediaMobileDown(css`
         min-width: 160px;
         width: 160px;
      `)}
   }
`

export const Body = styled.div`
   background: white;
   box-shadow: 0 3px 50px rgba(53, 38, 122, 0.15);
   border-radius: ${props => props.theme.borderRadiusBase};
   display: flex;
   flex-direction: column;
   width: 1700px;

   ${mediaMobileDown(css`
      width: 1600px;
   `)}
`

export const Container = styled.div`
   margin-top: 10px;
   display: flex;
   flex-direction: column;
   overflow: auto;
   height: calc(100vh - 260px);
   margin-left: -30px;
   padding: 0 30px;
   ${mediaMobileDown(css`
      height: calc(100vh - 190px);
   `)}
`
