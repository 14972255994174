import { mediaSmDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const DateFilterContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center;
   width: 100%;

   button {
      &:first-of-type {
         min-width: 42px;
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
      }

      &:last-of-type {
         min-width: 42px;
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
      }

      &:not(:first-of-type):not(:last-of-type) {
         border-radius: 0;
      }
   }
   .react-datepicker {
      display: flex;
      padding-bottom: 0;
      padding-top: 0;
   }
`

export const DatePickerContainer = styled.div`
   display: flex;
   position: relative;
   padding: 0.625rem 1.25rem 0.625rem 0;
`

export const DatePickerBtnContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 1.563rem 0 1.563rem 1.25rem;
   border-left: 0.5px solid #f2f3f5;

   button {
      margin: 0.5rem 0;
      width: 100%;
      text-transform: inherit;
      height: 2.125rem;
      background-color: #f2f3f5;
      color: #14316d;
      padding: 0 1.563rem;
   }
   ${mediaSmDown(css`
      display: none;
   `)}
`
