import { CustomIcon } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { List } from 'antd'
import cn from 'classnames'
import { AppLayout, MobileBackButton, NoInternetConnection } from 'components'
import React from 'react'

import { RoutePath } from '../../../../constants/routes'
import { Item } from '../../../../types'
import { extendedBillingTabs } from '../config'

const MobileBillingMenuPage = () => {
   // const { data, isLoading }: any = useQuery({
   //    fetchData: api.fetchSubscriptionInfo,
   // })
   const { navigate } = useLocation()

   return (
      <NoInternetConnection>
         <AppLayout
            mobileHeader={() => (
               <>
                  <MobileBackButton />
                  <div>
                     <IntlLabel label="title.billingPlan" />
                  </div>
                  <div />
               </>
            )}
            headerTitle="title.billingPlan"
            className={cn('billing-page', { 'billing-page__is-mobile': true })}>
            <List
               itemLayout="horizontal"
               className="staff-menu__list"
               dataSource={extendedBillingTabs}
               renderItem={(item: Item) => (
                  <List.Item
                     onClick={() => navigate(item.hasBasePath ? item.path : `${RoutePath.BILLING}#${item.path}`)}>
                     <List.Item.Meta
                        avatar={<CustomIcon type={item.icon} />}
                        title={<IntlLabel label={item.title} />}
                        description={<IntlLabel label={item.description} />}
                     />
                     <div>
                        <CustomIcon type="arrowForward" />
                     </div>
                  </List.Item>
               )}
            />
         </AppLayout>
      </NoInternetConnection>
   )
}

export default MobileBillingMenuPage
