import Icon from '@ant-design/icons'
import { Button, Form, SearchInput, Text, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Checkbox } from 'antd'
import * as api from 'api'
import { flow, noop } from 'lodash'
import React, { useEffect, useState } from 'react'

import * as SC from '../styles'

interface SingleCheckProps {
   onChange: (select: string[]) => void
   selectedOptions: string[]
   item: Service
}

type Service = {
   id: string
   serviceName: string
   time: string
   price: number
}

const serviceItem: Record<string, Service[]> = {
   '10': [
      {
         id: '1',
         serviceName: 'Service1',
         time: '1h',
         price: 120,
      },
      {
         id: '2',
         serviceName: 'Service2',
         time: '30 min',
         price: 50,
      },
      {
         id: '3',
         serviceName: 'Service3',
         time: '30 min',
         price: 60,
      },
   ],
   '11': [
      {
         id: '4',
         serviceName: 'Service1',
         time: '1h',
         price: 50,
      },
      {
         id: '5',
         serviceName: 'Service2',
         time: '30 min',
         price: 60,
      },
   ],
   '12': [
      {
         id: '7',
         serviceName: 'Service1',
         time: '1h',
         price: 50,
      },
      {
         id: '8',
         serviceName: 'Service2',
         time: '30 min',
         price: 60,
      },
      {
         id: '9',
         serviceName: 'Service3',
         time: '30 min',
         price: 300,
      },
   ],
}

interface ServiceProps {
   id: string
   service: string
}

const services: ServiceProps[] = [
   {
      id: '10',
      service: 'Service category name',
   },
   {
      id: '11',
      service: 'Category name',
   },
   {
      id: '12',
      service: 'Category',
   },
]

const SingleCheck = ({ onChange, selectedOptions, item }: SingleCheckProps) => {
   const { id } = item
   const toggleSelect = (isSelected: boolean) =>
      onChange(
         isSelected
            ? (selectedOptions || []).filter(selectedOption => selectedOption !== id)
            : [...(selectedOptions || []), id],
      )
   const isSelected = !!(selectedOptions || []).find(selectedOption => selectedOption === item.id)

   return (
      <SC.ServiceSelectionCheckList>
         <Checkbox checked={isSelected} onChange={() => toggleSelect(isSelected)}>
            <SC.ServiceSelectionItem>
               <SC.ServiceSelectionName>
                  {item.serviceName}
                  <div>{item.time}</div>
               </SC.ServiceSelectionName>
               <div>{item.price}€</div>
            </SC.ServiceSelectionItem>
         </Checkbox>
      </SC.ServiceSelectionCheckList>
   )
}

function EditServiceSelectionModal(props: any) {
   const [value, setValue] = useState('')
   const [selectedOptions, setSelectedOptions] = useState<string[]>([])
   const [selectedGroups, setSelectedGroups] = useState<Record<string, string[]>>({})
   const [buttonTab, setButtonTab] = useState('All')

   const isSelectAll = (selectedOptions || []).length === services.length
   let isSelectedGroupAll: Record<string, boolean> = {}

   const toggleSelectAll = () => {
      onChange(isSelectAll ? [] : services.map(e => e.id))
      let temp: Record<string, string[]> = {}
      for (const key in serviceItem) {
         temp[key] = serviceItem[key].map(i => i.id)
      }
      setSelectedGroups(isSelectAll ? {} : temp)
   }
   const onChange = (options: any[]) => {
      setSelectedOptions(options || [])
   }

   useEffect(() => {
      services.map(service => {
         isSelectedGroupAll[service.id] = (selectedGroups[service.id] || []).length === serviceItem[service.id].length

         console.log('effect', isSelectedGroupAll)

         if (
            (!isSelectedGroupAll[service.id] && selectedOptions.find(e => e === service.id)) ||
            (isSelectedGroupAll[service.id] && !selectedOptions.find(e => e === service.id))
         )
            onChange(
               !isSelectedGroupAll[service.id]
                  ? selectedOptions.filter(e => e !== service.id)
                  : [...selectedOptions, service.id],
            )
         return []
      })
   }, [selectedGroups])

   const toggleSelectGroupAll = (id: string) => {
      onChangeGroup(selectedOptions.find(e => e === id) ? [] : serviceItem[id].map(e => e.id), id)
   }

   const onChangeGroup = (options: any[], id: string) => {
      setSelectedGroups({ ...selectedGroups, [id]: options || [] })
   }

   return (
      <Form
         onSubmit={(form: any) => api.closeAppointment(props.modalProps.Number, form)}
         onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}>
         {({ submitting }: any) => (
            <>
               <SC.ServiceSelectionContainer>
                  <SC.ServiceSelectionHeader>
                     <SC.SearchClient>
                        <SearchInput value={value} setValue={setValue} />
                        <Icon type="search" />
                     </SC.SearchClient>
                     <SC.ClientSelectionBtn>
                        <Button
                           type="primary"
                           upperCase={false}
                           ghost={buttonTab !== 'All'}
                           onClick={() => setButtonTab('All')}>
                           <Text size="base" colorType={buttonTab === 'All' ? 'white' : 'base'} mb="none">
                              <IntlLabel label="All (200)" />
                           </Text>
                        </Button>
                        <Button
                           type="primary"
                           upperCase={false}
                           ghost={buttonTab !== 'Included'}
                           onClick={() => setButtonTab('Included')}>
                           <Text size="base" colorType={buttonTab === 'Included' ? 'white' : 'base'} mb="none">
                              <IntlLabel label="Included (175)" />
                           </Text>
                        </Button>
                        <Button
                           type="primary"
                           upperCase={false}
                           ghost={buttonTab !== 'Exluded'}
                           onClick={() => setButtonTab('Exluded')}>
                           <Text size="base" colorType={buttonTab === 'Exluded' ? 'white' : 'base'} mb="none">
                              <IntlLabel label="Exluded (25)" />
                           </Text>
                        </Button>
                     </SC.ClientSelectionBtn>
                  </SC.ServiceSelectionHeader>
                  {buttonTab === 'All' && (
                     <>
                        <SC.ServiceSelectionAll>
                           <Checkbox
                              checked={isSelectAll}
                              indeterminate={!isSelectAll && (selectedOptions || []).length > 0}
                              onChange={toggleSelectAll}>
                              <IntlLabel label="All services" />
                           </Checkbox>
                        </SC.ServiceSelectionAll>
                        <SC.ServiceSelectionListWrapper>
                           {services.map(service => (
                              <div key={service.id}>
                                 <SC.ServiceSelectionCheckListGroup>
                                    <Checkbox
                                       checked={!!selectedOptions.find(e => e === service.id)}
                                       indeterminate={
                                          !selectedOptions.find(e => e === service.id) &&
                                          (selectedGroups[service.id] || []).length > 0
                                       }
                                       onChange={() => toggleSelectGroupAll(service.id)}>
                                       <IntlLabel label={service.service} />
                                    </Checkbox>
                                 </SC.ServiceSelectionCheckListGroup>
                                 {serviceItem[service.id]?.map(item => (
                                    <div key={item.id}>
                                       <SingleCheck
                                          selectedOptions={selectedGroups[service.id]}
                                          onChange={options => onChangeGroup(options, service.id)}
                                          item={item}
                                       />
                                    </div>
                                 ))}
                              </div>
                           ))}
                        </SC.ServiceSelectionListWrapper>
                     </>
                  )}
               </SC.ServiceSelectionContainer>
               <SC.ClientSelectionFooterBtn>
                  <Button
                     type="primary"
                     label="Save and close"
                     htmlType="submit"
                     loading={submitting}
                     upperCase={false}
                  />
               </SC.ClientSelectionFooterBtn>
            </>
         )}
      </Form>
   )
}

export default withModal('Edit service selection', { contentClassName: 'service-content' })(EditServiceSelectionModal)
