import Icon from '@ant-design/icons'
import { Button, Form, SearchInput, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Avatar, Checkbox } from 'antd'
import * as api from 'api'
import { getAvatarSign } from 'helpers/common'
import { flow, noop } from 'lodash'
import React, { useEffect, useState } from 'react'

import * as SC from '../styles'

interface SingleCheckProps {
   onChange: { (select: string[]): void }
   selectedOptions: string[]
   item: {
      id: string
      firstName: string
      lastName: string
      email: string
   }
}

type Client = {
   id: string
   email: string
   firstName: string
   lastName: string
   phoneNumber: string
}

const SingleCheck = ({ onChange, selectedOptions, item }: SingleCheckProps) => {
   const { id } = item
   const toggleSelect = (isSelected: boolean) =>
      onChange(
         isSelected
            ? (selectedOptions || []).filter(selectedOption => selectedOption !== id)
            : [...selectedOptions, id],
      )
   const isSelected = !!(selectedOptions || []).find(selectedOption => selectedOption === item.id)

   return (
      <SC.ClientSelectionCheckList>
         <Checkbox checked={isSelected} onChange={() => toggleSelect(isSelected)}>
            <SC.ClientSelectionItem>
               <div>
                  <Avatar>{getAvatarSign(item.firstName || item.lastName)}</Avatar>
               </div>
               <SC.ClientSelectionName>
                  {item.firstName} {item.lastName}
                  <div>{item.email}</div>
               </SC.ClientSelectionName>
            </SC.ClientSelectionItem>
         </Checkbox>
      </SC.ClientSelectionCheckList>
   )
}

function EditClientSelectionModal(props: any) {
   const [value, setValue] = useState('')
   const [clients, setClients] = useState<Client[]>([])
   const [selectedOptions, setSelectedOptions] = useState<any[]>([])

   const isSelectAll = (selectedOptions || []).length === clients.length
   const toggleSelectAll = () => onChange(isSelectAll ? [] : clients.map((e: any) => e.id))

   useEffect(() => {
      api.fetchClientOptions(value ? { query: value } : value).then(e => setClients((e || {}).clients || []))
   }, [value])

   const filteredClients: Client[] = clients
   const onChange = (options: any[]) => {
      setSelectedOptions(options || [])
   }
   return (
      <Form
         onSubmit={(form: any) => api.closeAppointment(props.modalProps.Number, form)}
         onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}>
         {({ submitting }: any) => (
            <>
               <SC.ClientSelectionContainer>
                  <SC.SearchClient>
                     <SearchInput value={value} setValue={setValue} />
                     <Icon type="search" />
                  </SC.SearchClient>
                  <SC.ClientSelectionBtn>
                     <Button label="All (200)" type="primary" upperCase={false} />
                     <Button label="Included (175)" type="primary" ghost upperCase={false} />
                     <Button label="Exluded (25)" type="primary" ghost upperCase={false} />
                  </SC.ClientSelectionBtn>
                  <SC.ClientSelectionAll>
                     <Checkbox
                        checked={isSelectAll}
                        indeterminate={!isSelectAll && (selectedOptions || []).length > 0}
                        onChange={toggleSelectAll}>
                        <IntlLabel label="All clients (200)" />
                     </Checkbox>
                  </SC.ClientSelectionAll>
                  {filteredClients &&
                     filteredClients.map(item => (
                        <div key={item.id}>
                           <SingleCheck selectedOptions={selectedOptions} onChange={onChange} item={item} />
                        </div>
                     ))}
               </SC.ClientSelectionContainer>
               <SC.ClientSelectionFooterBtn>
                  <Button type="primary" label="btn.save" htmlType="submit" loading={submitting} upperCase={false} />
               </SC.ClientSelectionFooterBtn>
            </>
         )}
      </Form>
   )
}

export default withModal('Edit client selection', { contentClassName: 'client-content' })(EditClientSelectionModal)
