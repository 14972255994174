import './upload.less'

import { CustomIcon, withField } from '@plandok/core'
import { Upload } from 'antd'
import { NotReleased } from 'components'
import React, { useEffect, useState } from 'react'

interface ChangeUploadProps {
   input?: {
      value: string[]
   }
}

const PreviewChangePhoto = (props: ChangeUploadProps) => {
   const [image, setImage] = useState<any[] | undefined>(undefined)
   const tempImage =
      props.input?.value?.map?.((e: any, i: number) => ({
         ...e,
         uid: e.uid || e.name || i,
         status: e.status || 'done',
      })) || []

   useEffect(() => {
      setImage(tempImage.length > 0 ? tempImage.slice(tempImage.length - 1) : undefined)
   }, [tempImage])

   return (
      <NotReleased>
         <>
            {!props.input?.value ? (
               <CustomIcon type="preVector" />
            ) : (
               <div className="clearfix app-photo-preview">
                  <Upload beforeUpload={() => false} listType="picture-card" fileList={image}></Upload>
               </div>
            )}
         </>
      </NotReleased>
   )
}

export default withField(PreviewChangePhoto)
