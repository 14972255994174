import { DownOutlined } from '@ant-design/icons'
import { useLocation } from '@plandok/core'
import { Language, LanguageNameMapping, ReactIntl } from '@plandok/i18n'
import { Dropdown, Menu } from 'antd'
import React, { useContext } from 'react'
import styled from 'styled-components'

const Container = styled.div`
   position: absolute;
   top: -20px;
   right: 10px;
   cursor: pointer;
   font-size: 16px !important;
   font-weight: bold !important;
`

const MenuDropdown = ({ changeLanguage, ...props }: any) => (
   <Menu {...props}>
      {Object.values(Language).map(lang => (
         <Menu.Item key={lang} onClick={() => changeLanguage(lang)}>
            {LanguageNameMapping[lang]}
         </Menu.Item>
      ))}
   </Menu>
)

export default function RegistrationLangSelect({ page }: any) {
   const { navigate } = useLocation()
   const { lang, changeLang } = useContext(ReactIntl)

   const onLangUpdate = (nextLang: any) => {
      changeLang(nextLang)
      navigate(`/${nextLang}${page}`)
   }

   return (
      <Dropdown trigger={['hover', 'click']} overlay={<MenuDropdown changeLanguage={onLangUpdate} />}>
         <Container>
            {LanguageNameMapping[lang]} <DownOutlined />
         </Container>
      </Dropdown>
   )
}
