import { AuthBlock, getIsMobile } from '@plandok/core'
import { LabelKey } from '@plandok/i18n'
import { Layout } from 'antd'
import cn from 'classnames'
import { PermissionType } from 'constants/auth'
import React, { ComponentType, ReactNode } from 'react'

import AddButton from './components/AddButton'
import AppHeader from './components/AppHeader'
import * as SC from './styles'

interface AppLayoutProps {
   children: ReactNode
   mobileHeader: ComponentType
   headerTitle: LabelKey
   mobileBackground?: string
   headerIconTitle?: string
   customButton?: any
   addButtonPath?: string
   mobileWhiteBg?: boolean
   addBtnClassName?: string
   disableSidebarShade?: boolean
   noPaddingBottom?: boolean
   addButtonModal?: string
   contentClassName?: string
   hideSideBar?: boolean
   addButtonPermission?: PermissionType
   className?: string
   modalProps?: any
}

function AppLayout(props: AppLayoutProps) {
   const showAddBtn = props.addButtonPath || props.addButtonModal
   const isMobile = getIsMobile()

   window.addEventListener(
      'load',
      () => {
         console.log('Loading state ....')
      },
      false,
   )

   return (
      <>
         <AppHeader
            mobileHeader={props.mobileHeader}
            mobileBackground={props.mobileBackground}
            headerTitle={props.headerTitle}
            headerIconTitle={props.headerIconTitle}
         />
         <Layout.Content
            className={cn(
               { 'mobile-layout-content-white': props.mobileWhiteBg },
               {
                  'pb-0': props.noPaddingBottom,
               },
               {
                  'is-mobile': isMobile,
               },
               props.contentClassName,
            )}>
            {props.children}
         </Layout.Content>
         {showAddBtn && (
            <AuthBlock section={props.addButtonPermission}>
               <AddButton
                  className={props.addBtnClassName}
                  path={props.addButtonPath}
                  addButtonModal={props.addButtonModal}
                  modalProps={props.modalProps}
               />
            </AuthBlock>
         )}
         {props.customButton}
      </>
   )
}

AppLayout.Container = SC.Container

AppLayout.defaultProps = {
   disableSidebarShade: true,
}

export default AppLayout
