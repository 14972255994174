import { mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const BtnContainer = styled.div`
   margin-top: 40px;
   margin-bottom: 20px;
   display: flex;
   justify-content: center;
   button {
      width: 100%;
      max-width: 320px;
   }

   ${mediaMobileDown(css`
      padding: 10px;
      margin-top: 20px;
   `)}
`
