import { callAction, callActionWithId } from '@plandok/core'

export const fetchClients = callAction('/clients', 'get')

export const checkDuplicatedClients = callAction('/clients/duplicates/check', 'get')

export const fetchDuplicatedClients = callActionWithId('/clients/duplicates/search', 'get', false, {
   customUrlFunc: (id: string, mode: string) => `/clients/duplicates/search?mode=${mode}`,
})

export const mergeDuplicatedClients = callAction('/clients/duplicates/merge', 'post')

export const fetchClientOptions = callAction('/clients/for-select', 'get')

export const createClient = callAction('/clients', 'post', true)
export const editClient = callActionWithId('/clients/{clientId}', 'put', true)

export const fetchClientDetails = callActionWithId('/clients/{clientId}', 'get')

export const fetchClientProfileDetails = callActionWithId('/clients/{clientId}/profile', 'get', false, {
   customUrlFunc: (clientId: string) => `/clients/${clientId}/profile`,
})

export const fetchClientAppointmentDetails = callActionWithId('/clients/{clientId}/for-appointment', 'get', false, {
   customUrlFunc: (clientId: string) => `/clients/${clientId}/for-appointment`,
})

export const deleteClient = callActionWithId('/clients/{clientId}', 'delete')
