import { Button } from '@plandok/core'
import * as api from 'api'
import { DraggableTable, InfoPlaceholder, PageControlContainer } from 'components'
import withApiData from 'hocs/form/withApiData'
import React from 'react'

import { columnsConfig } from './config'

type StaffMembersPageProps = {
   goToCreateStaff: () => void
   goToUpdateStaff: (id: string) => void
}

const StaffTableWithApi = withApiData(api.fetchEmployees, { isSortable: true })(DraggableTable)

export default function StaffMembersPage({ goToCreateStaff, goToUpdateStaff }: StaffMembersPageProps) {
   return (
      <>
         <PageControlContainer mobileHidden>
            <PageControlContainer.Item />
            <PageControlContainer.Item>
               <InfoPlaceholder
                  imgName="staff"
                  titleLabel="state.staff.title"
                  descriptionLabel="state.staff.description"
               />
            </PageControlContainer.Item>
            <PageControlContainer.Item mobileHidden>
               <Button
                  label="btn.new.staff"
                  type="primary"
                  onClick={goToCreateStaff}
                  bold={false}
                  upperCase={false}
                  data-button-create-staff
                  minorBtn
               />
            </PageControlContainer.Item>
         </PageControlContainer>
         <StaffTableWithApi
            columns={columnsConfig}
            onItemClick={goToUpdateStaff}
            onPositionUpdate={async (id: string, newPosition: number) =>
               await api.changeEmployeePosition('', { id, newPosition })
            }
         />
      </>
   )
}
