import { composeValidators, Field, useAppContext, useModal, validateRequired } from '@plandok/core'
import { Spin } from 'antd'
import * as api from 'api'
import useQuery from 'hooks/data/useQuery'
import { senderTooltip } from 'pages/setup/SetupPage/constants'
import React from 'react'

import { validateSmsSenderId } from '../../SetupForm/helpers'
import * as SC from '../../SetupForm/styles'
import DesktopTitleBlock from '../DesktopTitleBlock'
import LabelWithTagSelector from '../LabelWithTagSelector'
import MobileTitleBlock from '../MobileTitleBlock'

export default function ChannelsBlock({
   notificationType,
   channels,
   values,
   onTagSelectedForEmail,
   customTextAreaForEmail,
   onTagSelectedForSms,
   customTextAreaForSms,
   onTagSelectedForWhatsApp,
   customTextAreaForWhatsApp,
   onSubmit,
}: {
   notificationType: string
   channels: string[]
   values: any
   onTagSelectedForEmail: (template: string, tag: string) => void
   customTextAreaForEmail: null | any
   onTagSelectedForSms: (template: string, tag: string) => void
   customTextAreaForSms: null | any
   onTagSelectedForWhatsApp: (template: string, tag: string) => void
   customTextAreaForWhatsApp: null | any
   onSubmit: () => void
}) {
   const [context] = useAppContext()
   const { data: priceData }: any = useQuery({
      fetchData: api.fetchPricing,
      extractDataFunc: (_: any) => _,
   })

   const [, { showModal }] = useModal()

   const isPremiumPlan = context.subscription?.isPremium

   const sendTestMessageForEmail = () =>
      showModal({
         type: 'SEND_TEST_MESSAGE_FOR_EMAIL',
         modalProps: {
            channel: 'email',
            notificationType,
            onSubmit,
            values,
         },
      })

   const sendTestMessageForNumber = (channel: string) =>
      showModal({
         type: 'SEND_TEST_MESSAGE_FOR_NUMBER',
         modalProps: {
            channel,
            notificationType,
            onSubmit,
            values,
         },
      })

   const onClickPricingInfo = () =>
      showModal({
         type: 'PRICING_MODAL',
         modalProps: { priceData },
      })

   return (
      <>
         {channels?.includes('email') && (
            <>
               <SC.EmailSettingsTitleCommon
                  colorType="base"
                  mb="small"
                  size="medium"
                  weight="semiBold"
                  label="label.emailSettings"
                  lh="mlarge"
               />

               <SC.WrapperFields>
                  <Field.Input
                     name="emailSubject"
                     label="input.emailSubject.label"
                     placeholder="input.emailSubject.label"
                  />

                  <SC.TemplateTextAreaWithTags
                     name="emailTemplate"
                     placeholder="input.emailTemplate.label"
                     customTextArea={customTextAreaForEmail}
                     values={values}
                     labelComponent={
                        <LabelWithTagSelector
                           template="emailTemplate"
                           label="input.emailTemplate.label"
                           onTagSelected={onTagSelectedForEmail}
                           reminder={false}
                        />
                     }
                     onClickSendTestMessage={sendTestMessageForEmail}
                  />
               </SC.WrapperFields>
            </>
         )}

         {channels?.includes('sms') && (
            <>
               <span className="hidden-md">
                  <DesktopTitleBlock premium={isPremiumPlan} labelSettings="label.SMSSettings" />
               </span>

               <span className="visible-md">
                  <MobileTitleBlock premium={isPremiumPlan} labelSettings="label.SMSSettings" />
               </span>

               <SC.WrapperFields>
                  <Spin spinning={!isPremiumPlan}>
                     <Field.Input
                        name="smsSenderName"
                        label="input.smsSender.label"
                        maxLength={11}
                        placeholder="input.smsSender.label"
                        tooltip={senderTooltip}
                        validate={composeValidators(validateRequired, validateSmsSenderId)}
                     />
                  </Spin>

                  <SC.TemplateTextAreaWithTags
                     name="smsTemplate"
                     customTextArea={customTextAreaForSms}
                     values={values}
                     labelComponent={
                        <LabelWithTagSelector
                           template="smsTemplate"
                           label="input.smsTemplate.label"
                           onTagSelected={onTagSelectedForSms}
                           premium={isPremiumPlan}
                           reminder={true}
                        />
                     }
                     placeholder="input.smsTemplate.label"
                     onClickPricingInfo={onClickPricingInfo}
                     onClickSendTestMessage={() => sendTestMessageForNumber('sms')}
                     premium={isPremiumPlan}
                     reminder={true}
                     price={priceData?.meta.default.smsPrice}
                  />
               </SC.WrapperFields>
            </>
         )}

         {channels?.includes('whatsapp') && (
            <>
               <span className="hidden-md">
                  <DesktopTitleBlock premium={isPremiumPlan} labelSettings="label.whatsAppSettings" />
               </span>

               <span className="visible-md">
                  <MobileTitleBlock premium={isPremiumPlan} labelSettings="label.whatsAppSettings" />
               </span>

               <SC.WrapperFields>
                  <Spin spinning={!isPremiumPlan}>
                     <Field.Input
                        name="whatsappSenderName"
                        label="input.messageSender.label"
                        maxLength={11}
                        placeholder="input.whatsAppSender.placeholder"
                        tooltip={senderTooltip}
                        validate={composeValidators(validateRequired, validateSmsSenderId)}
                        disabled
                     />
                  </Spin>

                  <SC.TemplateTextAreaWithTags
                     name="whatsappTemplate"
                     customTextArea={customTextAreaForWhatsApp}
                     values={values}
                     labelComponent={
                        <LabelWithTagSelector
                           template="whatsappTemplate"
                           label="input.messageTemplate.label"
                           onTagSelected={onTagSelectedForWhatsApp}
                           premium={isPremiumPlan}
                           reminder={true}
                           disabled
                        />
                     }
                     placeholder="input.messageTemplate.label"
                     onClickPricingInfo={onClickPricingInfo}
                     onClickSendTestMessage={() => sendTestMessageForNumber('whatsapp')}
                     premium={isPremiumPlan}
                     reminder={true}
                     price={priceData?.meta.default.whatsappPrice}
                     contentEditable={false}
                  />
               </SC.WrapperFields>
            </>
         )}
      </>
   )
}
