import { formatDateField } from 'helpers/date/field'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

export const getIsAddAvailable = (values: any) =>
   (values.bookings || []).length > 1 || ((values.bookings || [])[0] || {}).serviceId

export const formatAppointmentForm = (form: any) => ({
   ...form,
   bookings: (form?.bookings || []).map((e: any) => omit(e, 'price')),
})

export const detailsAppointmentSelect = (
   res: any,
   rescheduleItemDate?: string | Date,
   rescheduleItemTime?: number,
   rescheduleItemStaff?: string,
) => ({
   locationId: res?.locationId,
   date: rescheduleItemDate ? formatDateField(rescheduleItemDate) : res?.date || new Date(),
   clientId: res?.client?.id,
   notes: res?.notes,
   bookings: res?.bookings.map((e: any) => ({
      ...pick(e, 'serviceId', 'duration'),
      employeeId: rescheduleItemStaff || e?.employee?.id,
      resourceId: e?.resource?.id,
      startTime: rescheduleItemTime ?? e?.startTime,
   })),
})
