import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from '../../../../hooks/router'
import { Spin } from 'antd'
import {
   Language,
   LanguageNameMapping,
   LanguageNameEnglishMapping,
   ReactIntl,
   getLabel,
   updateStorageLanguage,
} from '@plandok/i18n'
import TextInput from '../../../../components/form/input/TextInput'
import CustomIcon from '../../../../components/common/CustomIcon'
import Mutation from '../../../async/Mutation'
import * as api from '../../../../api'
import withModal from '../withModal'
import * as SC from '../styles'

function SelectLanguageModal({ modalProps, onClose }: any) {
   const { changeLang, lang } = useContext(ReactIntl)

   const [listLanguage, setListLanguage] = useState([])
   const [searchValue, setSearchValue] = useState('')

   const history = useHistory()

   useEffect(() => {
      const allLanguages: any = []

      Object.values(Language).map(lang =>
         allLanguages.push({
            languageName: LanguageNameMapping[lang],
            languageNameEnglish: LanguageNameEnglishMapping[lang],
            lang,
         }),
      )

      setListLanguage(allLanguages)
   }, [])

   const langFilter = (lang: any) => {
      const filterLanguages = lang.languageName.toLowerCase().indexOf(searchValue.toLowerCase()) === 0

      if (filterLanguages) {
         return filterLanguages
      } else {
         return lang.languageNameEnglish.toLowerCase().indexOf(searchValue.toLowerCase()) === 0
      }
   }

   return (
      <Mutation action={api.updateUserLanguage} onSuccess={onClose} passId>
         {({ loading, action }: any) => {
            const changeLanguageBind = (lang: any) => () => {
               changeLang(lang)
               updateStorageLanguage(lang)
               if (modalProps && modalProps?.slug) {
                  history.push(`/${lang}/${modalProps?.slug}`)
                  onClose()
               } else {
                  return action({ id: lang })
               }
            }

            return (
               <SC.SelectLangList as={Spin} spinning={loading}>
                  <SC.SelectLangLabel>
                     <TextInput
                        placeholder={`${getLabel('modal.language.placeholder', lang)}...`}
                        value={searchValue}
                        onChange={({ target: { value } }) => setSearchValue(value)}
                        style={{ padding: '12px 20px', marginBottom: '13px', maxWidth: '330px' }}
                     />
                  </SC.SelectLangLabel>
                  <SC.WrapperListLanguages>
                     {listLanguage
                        .filter(itemLang => langFilter(itemLang))
                        .map((itemLang: any) => (
                           <SC.SelectLangItem
                              key={itemLang.lang}
                              onClick={changeLanguageBind(itemLang.lang)}
                              theme={{ select: itemLang.lang === lang }}
                           >
                              <span className="align-center">
                                 <CustomIcon type={itemLang.lang} />
                                 <span>{itemLang.languageName}</span>
                                 <span>({itemLang.languageNameEnglish})</span>
                              </span>
                              <CustomIcon type="checkmark" />
                           </SC.SelectLangItem>
                        ))}
                  </SC.WrapperListLanguages>
               </SC.SelectLangList>
            )
         }}
      </Mutation>
   )
}

export default withModal('modal.language.title', { contentClassName: 'select-language-modal' })(SelectLanguageModal)
