import { FieldRenderProps } from 'react-final-form'
import withFormBlock from '../FormBlock/withFormBlock'
import React from 'react'
import CustomIcon from '../../../common/CustomIcon'
import { Input } from 'antd'

interface FormSocialInputProps {
   submitting?: boolean
   iconType: 'globeCustomIcon' | 'facebookIcon' | 'instagramCustomIcon'
}

const FormSocialInput = ({
   input,
   submitting,
   limit,
   meta,
   iconType,
   ...rest
}: FormSocialInputProps & FieldRenderProps<any, any>) => {
   return <Input addonBefore={<CustomIcon type={iconType} />} id={input.name} {...input} {...rest} />
}

export default withFormBlock<FormSocialInputProps>(FormSocialInput)
