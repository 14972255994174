import { getDaysInMonth, setMonth } from 'date-fns'

export const getDayOptions = (month: number) => {
   if (month == null) {
      return []
   }
   return [...Array(getDaysInMonth(setMonth(new Date(), month - 1)))].map((_, i) => ({
      code: i + 1,
      name: (i + 1).toString(),
   }))
}
