import { IconButton, Text, useModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Dropdown, Menu, message } from 'antd'
import * as api from 'api'
import React from 'react'

import * as SC from '../styles'

type PaymentMethodDropdownProps = {
   paymentMethod: string
   fetchData: () => void
}

export default function PaymentMethodDropdown({ paymentMethod, fetchData }: PaymentMethodDropdownProps) {
   const [, { showModal }]: any = useModal()

   const openDeletePaymentMethod = () =>
      showModal({
         type: 'DELETE_PAYMENT_METHOD_MODAL',
         modalProps: {
            paymentMethod,
            fetchData,
         },
      })

   const assignCard = async () => {
      const assignCard = await api.assignPaymentMethods({
         paymentMethod,
      })

      if (assignCard.errors) {
         message.error(<IntlLabel label={Object.values(assignCard.errors)} />)
      } else {
         message.success(<IntlLabel label="paymentMethod.cardHasBeenAssign.message" />)
         fetchData()
      }
   }

   const MethodControlMenu = ({ ...props }: any) => (
      <Menu className="app-common-dropdown" {...props}>
         <Menu.Item onClick={assignCard}>
            <Text label="paymentMethod.makeDefault.btn" mb="none" size="small" lh="base" />
         </Menu.Item>
         <Menu.Item onClick={openDeletePaymentMethod}>
            <Text label="btn.delete" mb="none" size="small" lh="base" colorType="red" />
         </Menu.Item>
      </Menu>
   )

   return (
      <Dropdown trigger={['click']} overlay={() => <MethodControlMenu />} placement="bottomRight">
         <SC.Control>
            <IconButton icon="bigDots" />
         </SC.Control>
      </Dropdown>
   )
}
