import styled from 'styled-components'

type ButtonProps = {
   size?: string
}

export const Button = styled.button<ButtonProps>`
   height: ${({ size }) => size || '28px'};
   width: ${({ size }) => size || '28px'};
   cursor: pointer;
   text-align: center;
   border-radius: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
   outline: none;
   background: transparent;
   border: none;
   position: relative;
   z-index: 1;

   svg {
      position: absolute; /* Fix for https://gitlab.com/plandok/plandok-app/-/issues/449 */
   }

   &:hover,
   &:focus,
   &:active {
      background: ${({ color }) => color || '#e9ecf3'};
   }
`
