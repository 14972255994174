import { LocalStorage } from '@plandok/core'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define the initial state
const initialState = {
   displayName: '',
   currencyIsoCode: 'EUR',
   languageIsoCode: 'en',
   country: 'BY',
   locale: 'en-ZW',
   timeZone: 'UTC',
   isLoading: true,
   isAuthenticated: false,
   refreshContext: 1,
   defaultViewRange: 'week',
   timeSlotInterval: 15,
   calendarViewTime: 'full_calendar',
   timeFormat: '24h',
   customViewTime: {
      startTime: 0,
      endTime: 1435,
   },
   subscription: {
      type: 'free',
      isPremium: false,
      canHandleAction: false,
      canAddAppointment: false,
      canAddEmployee: false,
      canAddLocation: false,
   },
}

export interface InitialStateType {
   displayName: string
   defaultViewRange: 'week' | 'day'
   timeSlotInterval: number
   calendarViewTime: string
   isLoading: boolean
   isAuthenticated: boolean
   refreshContext: number
   notificationCharge: number
   customViewTime: {
      startTime: number
      endTime: number
   }
   timeZone: string
   timeFormat: string
   locale: string
   languageIsoCode: string
   currencyIsoCode: string
   country: string
   subscription: {
      type: string
      isPremium: boolean
      locationCount: number
      employeeCount: number
      canHandleAction: boolean
      canAddAppointment: boolean
      canAddEmployee: boolean
      canAddLocation: boolean
      currentPlanId: string
   }
   access: {
      permissionLevel: string
      sections: string[]
   }
}

// Helper functions for local storage interaction
const setInitialState = (obj: any) => LocalStorage.setAppContext(obj)

// Create the slice
const appContextSlice = createSlice({
   name: 'appContext',
   initialState: () => {
      try {
         const context = localStorage.getItem('app-context')
         return context ? JSON.parse(context) : initialState
      } catch (e) {
         return
      }
   },
   reducers: {
      setAppContext(state, action: PayloadAction<any>) {
         const newState = { ...state, ...action.payload }
         setInitialState(newState)
         return newState
      },
      setTimeZone(state, action: PayloadAction<string>) {
         state.timeZone = action.payload
         setInitialState(state)
      },
      updateCompanyDetails(state, action: PayloadAction<{ country: string; timeZone: string }>) {
         const { country, timeZone } = action.payload
         state.country = country
         state.timeZone = timeZone
         setInitialState(state)
      },
      setDisplayName(state, action: PayloadAction<string>) {
         state.displayName = action.payload
         setInitialState(state)
      },
      resetAppContext() {
         LocalStorage.clearAll()
         return { ...initialState }
      },
      refreshAppContext(state) {
         state.refreshContext = state.refreshContext + 1
      },
      changeLanguage(state, action: PayloadAction<string>) {
         state.languageIsoCode = action.payload
      },
   },
})

// Export actions
export const {
   setAppContext,
   setTimeZone,
   updateCompanyDetails,
   setDisplayName,
   resetAppContext,
   changeLanguage,
   refreshAppContext,
} = appContextSlice.actions

export default appContextSlice
