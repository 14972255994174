import { Modal as AntModal } from 'antd'
import { mediaMobileDown } from '../../../helpers/styles'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import CustomIcon from '../CustomIcon'

interface ModalProps {
   title: ReactNode
   children: ReactNode
   visible: boolean
   className?: string
   noClose?: boolean
   onClose: () => void
   onConfirm: () => void
}

const AppModal = styled<ModalProps & any>(AntModal)`
   padding: 10px;

   ${mediaMobileDown(css`
      .ant-modal-content {
         //padding: 20px;
      }
   `)}
`

export default function Modal(props: ModalProps & any) {
   return (
      <AppModal
         title={props.title}
         open={props.visible}
         footer={null}
         closeIcon={<CustomIcon type="close" />}
         onCancel={props.onClose}
         onOk={props.onConfirm}
         className={props.className}
         closable={!props.noClose}
         {...props}
      >
         {props.children}
      </AppModal>
   )
}
