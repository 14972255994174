import { Button, getIsMobile } from '@plandok/core'
import * as api from 'api'
import cn from 'classnames'
import { AppLayout, AppTable, NoInternetConnection } from 'components'
import React, { useState } from 'react'

import { messageLogColumns, mobileCampaignsCardData } from '../config'
import * as SC from '../styles'
import CampaignsMobileHeader from './CampaignsMobileHeader'
import MobileCampaignCard from './MobileCampaignsCard'

interface MarketingCampaignsProps {
   buttonTab: string
   setButtonTab: (tabName: string) => void
}

export default function MarketingCampaigns({ buttonTab, setButtonTab }: MarketingCampaignsProps) {
   const [switchBtn, setSwitchBtn] = useState(false)
   const isMobile = getIsMobile()

   return (
      <NoInternetConnection>
         <AppLayout
            headerTitle="Auto-sending Campaigns"
            mobileHeader={CampaignsMobileHeader}
            className={cn('campaigns-page', { 'campaigns-page__is-mobile': isMobile })}>
            <SC.Container>
               <SC.CampaignsSwitchBtns>
                  <Button
                     label="Campaigns"
                     upperCase={false}
                     ghost={buttonTab !== 'campaigns'}
                     onClick={() => setButtonTab('campaigns')}
                     type="primary"
                  />
                  <Button
                     label="Message log"
                     upperCase={false}
                     ghost={buttonTab !== 'message'}
                     onClick={() => setButtonTab('message')}
                     type="primary"
                  />
               </SC.CampaignsSwitchBtns>
               {buttonTab === 'campaigns' &&
                  mobileCampaignsCardData?.map((item, index) => (
                     <MobileCampaignCard
                        key={index}
                        indexNumber={index}
                        headerText={item.headerText}
                        icon={item.icon}
                        url={item.url}
                        description={item.description}
                        switchButton={switchBtn}
                        onChange={(checked: boolean) => setSwitchBtn(checked)}
                     />
                  ))}
            </SC.Container>
            {buttonTab === 'message' && (
               <SC.MessageLog>
                  <AppTable
                     type="message"
                     columns={messageLogColumns}
                     mobileClassName="app_clients-list--mobile"
                     apiConfig={{
                        fetchData: api.fetchClients,
                        mapping: {
                           pageKey: 'page[offset]',
                           maxCountKey: 'page[limit]',
                           dataKey: 'massMarketingCampaign',
                           totalCountKey: 'messageLog.totalCount',
                           searchKey: 'query',
                           emptyStateKey: 'messageLog.emptyState',
                        },
                     }}
                  />
               </SC.MessageLog>
            )}
         </AppLayout>
      </NoInternetConnection>
   )
}
