import Icon from '@ant-design/icons'
import React, { useRef, useState } from 'react'
// @ts-ignore:next-line
import SmsCounter from 'sms-counter'
import { Spin } from 'antd'
import DOMPurify from 'dompurify'
import { Text, theme, CustomIcon } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import withFormBlock from '../FormBlock/withFormBlock'
import * as SC from './styles'

interface FormInputProps extends Partial<any> {
   submitting?: boolean
   onClickPricingInfo?: () => void
   onClickSendTestMessage?: () => void
   premium?: boolean
   reminder?: boolean
   values: any
   customTextArea: null | any
   placeholder: string
   price?: number
   contentEditable?: boolean
}

const FormTextAreaWithTags = ({
   input,
   submitting,
   onClickPricingInfo,
   onClickSendTestMessage,
   premium,
   reminder = false,
   values,
   customTextArea,
   placeholder,
   price,
   contentEditable = true,
   ...rest
}: FormInputProps) => {
   const [isVisibleWhatsAppNotificate, setIsVisibleWhatsAppNotificate] = useState(true)
   const [, setRerender] = useState<number>(Date.now())
   const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

   const BtnSendTestMessage = (
      <SC.SMSPricing onClick={onClickSendTestMessage} color={theme.linkColor} theme="send">
         <Icon type="mail" />
         <IntlLabel label="reminder.sendTestMessage" />
      </SC.SMSPricing>
   )

   const onKeyUp = () => {
      values[input.name] = customTextArea.current?.innerText
      if (debounceTimeoutRef.current) {
         clearTimeout(debounceTimeoutRef.current)
      }

      debounceTimeoutRef.current = setTimeout(() => {
         setRerender(Date.now())
      }, 300)
   }

   const smsInfoObj = customTextArea.current?.innerText && SmsCounter.count(customTextArea.current?.innerText)
   const whatsApp = input.name === 'whatsappTemplate'
   const displayCount = input.name === 'smsTemplate' || whatsApp
   const email = input.name === 'emailTemplate'

   return (
      <>
         {whatsApp && isVisibleWhatsAppNotificate && (
            <SC.WhatsAppAdditionalInfo>
               <span>
                  <CustomIcon type="whatsAppCautionNotificate" />
               </span>
               <Text size="small" label="reminder.whatsApp.notificate" mb="none" ml="xxsmall" lh="base" />
               <span onClick={() => setIsVisibleWhatsAppNotificate(false)} className="cursor-pointer">
                  <CustomIcon type="deleteButton" />
               </span>
            </SC.WhatsAppAdditionalInfo>
         )}

         <Spin spinning={!premium && reminder}>
            <SC.CustomTextArea
               id={input.name}
               contentEditable={contentEditable}
               ref={customTextArea}
               suppressContentEditableWarning={true}
               dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(input.value.replace(/\n\r?/g, '<br />')) }}
               theme={{ placeholder, contentEditable }}
               onKeyUp={onKeyUp}
            />
         </Spin>

         <SC.Characters theme={{ displayCount }}>
            {displayCount && (
               <Spin spinning={!premium && reminder}>
                  <SC.Count>
                     <Text label="reminder.charactersCount" size="small" mb="none" />
                     <Text size="small" weight="semiBold" mb="none" ml="xxsmall" colorType="base">
                        {whatsApp
                           ? `${customTextArea.current?.innerText.length || 0}/1024 (1 SMS = ${price}€)`
                           : `${smsInfoObj?.length || 0}/${smsInfoObj?.per_message || 160} (${smsInfoObj?.messages || 1} SMS = ${
                                price! * smsInfoObj?.messages || price
                             }€)`}
                     </Text>
                  </SC.Count>
               </Spin>
            )}
            <span>
               {onClickPricingInfo && (
                  <SC.SMSPricing onClick={onClickPricingInfo}>
                     <Icon type="exclamation-circle" />
                     <IntlLabel label="reminder.pricing" />
                  </SC.SMSPricing>
               )}
               {onClickSendTestMessage && (premium || email) && <span className="hidden-md">{BtnSendTestMessage}</span>}
            </span>
         </SC.Characters>
         {onClickSendTestMessage && (premium || email) && <span className="visible-md">{BtnSendTestMessage}</span>}
      </>
   )
}

export default withFormBlock<FormInputProps>(FormTextAreaWithTags)
