import { Button, mediaMdDown, mediaMobileDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const StyledResendPasswordButton = styled(Button)`
   ${mediaMobileDown(css`
      width: 100%;
      margin-bottom: 1.5rem;
   `)};
`

export const AdditionalPriceContainer = styled.div`
   display: flex;
   justify-content: center;
   div {
      :first-child {
         margin: 24px 5px 0 16px;
      }
      :last-child {
         max-width: max-content;
         margin-top: 24px;
         border-bottom: 1px solid #61749d;
         &:hover {
            cursor: pointer;
         }
      }
   }

   ${mediaMdDown(css`
    flex-direction: column;
    div:last-child {
        margin: 5px 0 0 16px;
      }
    }
  `)}
`

export const StyledPictureAndNameBlock = styled.div`
   display: grid;
   grid-template-columns: 10rem 1fr;
   grid-template-rows: repeat(2, 1fr);
   width: 100%;

   div:first-child {
      z-index: 10;
      grid-column: 1/2;
      grid-row: 1/3;
      &:hover {
         cursor: pointer;
      }
   }

   ${mediaMobileDown(css`
      grid-template-columns: 8rem 1fr;
   `)};
`
