import { IntlMoney } from '@plandok/i18n'
// import * as RSH from 'react-sortable-hoc';
import { formatDuration } from 'helpers/date'
import React from 'react'

import * as SC from '../style'
import SmallDragIcon from './SmallDragIcon'
//
// const DragHandle: any = (
//    <div data-service-item-drag>
//       <SmallDragIcon />
//    </div>
// )

function ServiceItem({ item = {}, editService, groupId }: any) {
   const editItem = () => {
      editService(item.id, groupId)
   }

   return (
      <SC.ServiceItem onClick={editItem}>
         <div data-service-item-drag>
            <SmallDragIcon />
         </div>
         <div data-service-item-content>
            <div>{item.name}</div>
            <div>{formatDuration(item.duration)}</div>
            <div>
               <IntlMoney value={item.price} />
            </div>
         </div>
      </SC.ServiceItem>
   )
}

export default ServiceItem
