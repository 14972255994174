import { CustomIcon, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React from 'react'

import * as SC from '../../../pages/dashboard/AnalyticsPage/AnalyticsReports/styles'

interface BackButtonProps {
   onClick: () => void
}

export const BackButton = ({ onClick }: BackButtonProps) => (
   <SC.BackButton onClick={onClick}>
      <SC.BackButtonContent>
         <CustomIcon type="arrowBack" />
         <Text size="medium" color="link" weight="medium" mb="none">
            <IntlLabel label="btn.back" />
         </Text>
      </SC.BackButtonContent>
   </SC.BackButton>
)

export default BackButton
