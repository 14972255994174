import { useAppContext } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Elements } from '@stripe/react-stripe-js'
import { Spin } from 'antd'
import * as api from 'api'
import { AppLayout, NoInternetConnection } from 'components'
import useStripeElement from 'hooks/use-stripe-element'
import CheckoutForm from 'pages/plans/components/CheckoutForm'
import SubscriptionSummaryCard from 'pages/plans/components/SubscriptionSummaryCard'
import React, { useEffect, useState } from 'react'

export default function UpdateSubscriptionPage() {
   const [context] = useAppContext()
   const [isLoading, setIsLoading] = useState<boolean>(false)
   const [estimatedCostsData, setEstimatedCostsData] = useState<any>({})
   const { stripePromise } = useStripeElement()

   useEffect(() => {
      ;(async () => {
         setIsLoading(true)
         let response = await api.fetchEstimatedCosts({ staffCount: context.subscription.employeeCount })
         setIsLoading(false)

         setEstimatedCostsData(response.data)
      })()
      return () => {}
   }, [])

   const onPaymentSubmit = async (paymentMethod: string, billingInformation: any) => {
      return await api.upgradePlan({ paymentMethod: paymentMethod, billingInformation })
   }

   return (
      <NoInternetConnection>
         <AppLayout
            mobileHeader={() => (
               <>
                  <div />
                  <div>
                     <IntlLabel label="payment.step.title" />
                  </div>
                  <div />
               </>
            )}
            headerTitle="payment.step.title">
            <Spin spinning={isLoading}>
               <SubscriptionSummaryCard estimatedCostsData={estimatedCostsData} />
            </Spin>

            {stripePromise && (
               <Elements stripe={stripePromise}>
                  <CheckoutForm
                     onPaymentSubmit={onPaymentSubmit}
                     total={`${estimatedCostsData?.total?.toFixed(2) || 0}€`}
                  />
               </Elements>
            )}
         </AppLayout>
      </NoInternetConnection>
   )
}
