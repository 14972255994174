import { mediaMdUp, mediaSmDown, mediaSmUp, Text } from '@plandok/core'
import styled, { css } from 'styled-components'

export const Header = styled.header`
   display: flex;
   justify-content: space-between;
   z-index: 1;
   height: 64px;
   box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
   ${mediaMdUp(css`
      background: transparent !important;
   `)}
   ${mediaSmDown(css`
      display: none;
   `)}
`

export const MobileHeader = styled.header<any>`
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   z-index: 9;
   background: ${({ background }: any) => (background ?? 'inherit') + ' !important'};
   [data-mobile-header-content] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      border-bottom: 1px solid #e9ecf3;
      margin: 0 15px;
      font-weight: 500;
      color: ${({ theme }) => theme.primaryColor};
   }
   ${mediaSmUp(css`
      display: ${({ isMobile }: any) => (isMobile ? 'block' : 'none !important')};
   `)}
`

export const Title = styled.div`
   font-size: ${({ theme }) => theme.fontSizeXlg};
   font-weight: bold;
   padding: 10px 0 0 0;
   color: ${({ theme }) => theme.primaryColor};
`

export const TitleWithIcon = styled.div`
   padding-left: 0.5rem;
`

export const UserInfo = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   button:not([data-pricing-link]) {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      &:hover,
      &:active,
      &:focus {
         background: #adb6cc;
         path {
            fill: white;
         }
      }
   }
`

export const ContainerUsage = styled.div`
   border-left: 1px solid #dfe6f1;
   padding: 0 23px 0 17px;
   text-align: center;
   margin-left: 8px;
   line-height: 19px;
   cursor: pointer;
   div:nth-child(1) {
      margin-bottom: 1px;
   }
`

export const UsageContainer = styled.div`
   padding: 3px 24px 25px;
   min-width: 350px;
`

export const SubscriptionTitleContainer = styled.div`
   border-bottom: 1px solid #dfe6f1;
   padding: 16px 0;
   div:first-child {
      margin-bottom: 3px;
   }
`

export const SubscriptionDescriptionContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: nowrap;
`

export const PlanType = styled(Text)`
   :hover {
      cursor: pointer;
   }
`

export const MessagesContainer = styled.div`
   border-bottom: 1px solid #dfe6f1;
   padding: 16px 0;
`

export const TaxesContainer = styled.div`
   display: flex;
   justify-content: space-between;
   max-width: max-content;
   margin: 12px 0 8px auto;
   div:last-child {
      margin-left: 40px;
   }
`

export const TotalContainer = styled.div`
   display: flex;
   justify-content: space-between;
   max-width: max-content;
   margin-left: auto;
   div:last-child {
      margin-left: 40px;
   }
`

export const ViewPaymentDetails = styled(Text)`
   text-align: center;
   margin-top: 24px;
   :hover {
      cursor: pointer;
   }
`
