import { ButtonAddAnother, Placeholder } from 'components'
import React from 'react'

import DragHandle from './components/DragHandle'
import ListServiceContainer from './components/ListServiceContainer'
import ServiceControl from './components/ServiceControl'
import ServiceItem from './components/ServiceItem'
import * as SC from './style'

// const SortableServicesBody = RSH.SortableContainer((props: any) => <SC.SGroupBody>{props.children}</SC.SGroupBody>);

function ServiceGroup({
   onServiceItemDrop,
   editCategory,
   editService,
   fetchServiceGroups,
   createService,
   ...props
}: any) {
   const onCreate = () => createService(props.id)
   return (
      <SC.Container {...props} data-service-group-list={props.name}>
         <DragHandle />
         <ListServiceContainer useDragHandle onSortEnd={onServiceItemDrop}>
            <ServiceControl editCategory={editCategory} id={props.id} fetchServiceGroups={fetchServiceGroups} />
            <SC.Prefix color={props.color} />
            <SC.Title>{props.name}</SC.Title>
            {/*<SortableServicesBody onSortEnd={props.handleServiceSortEnd} useDragHandle>*/}
            {(props.services || []).map((e: any, i: number) => (
               <ServiceItem
                  key={`service-item-${e.id}`}
                  item={e}
                  index={i}
                  groupId={props.id}
                  editService={editService}
               />
            ))}

            {(props.services || []).length === 0 && (
               <Placeholder
                  imgName="serviceGroup"
                  descriptionLabel="state.serviceGroup.description"
                  btnLabel="state.serviceGroup.btn"
                  onClick={onCreate}
               />
            )}
            {/*</SortableServicesBody>*/}
            {(props.services || []).length > 0 && <ButtonAddAnother onClick={onCreate} label="service.new.title" />}
         </ListServiceContainer>
      </SC.Container>
   )
}

export default ServiceGroup
