import { useLocation } from '@plandok/core'

import { NOT_RELEASED_TOKEN } from '../../../constants/auth'

interface PreReleasedProps {
   children: React.ReactNode
}

export default function NotReleased({ children }: PreReleasedProps) {
   const locationData: any = useLocation()

   const isNrt = locationData?.location?.search.includes(NOT_RELEASED_TOKEN)
   const isNrtSession = sessionStorage.getItem('nrt')
   const isDev = process.env.NODE_ENV === 'development'

   if (isDev || isNrt || isNrtSession) {
      if (!isNrtSession) sessionStorage.setItem('nrt', NOT_RELEASED_TOKEN)
      return children
   }

   return null
}
