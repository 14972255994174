export const STATUS_CONFIG = {
   completed: {
      color: '#97D561',
      text: 'appointment.status.completed',
   },
   confirmed: {
      color: '#29C0CC',
      text: 'appointment.status.confirmed',
   },
   no_show: {
      color: '#FC6792',
      text: 'appointment.status.notShow',
   },
   new: {
      color: '#4A9CFC',
      text: 'appointment.status.new',
   },
}

export const NOTES_LENGTH = 76
