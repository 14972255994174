import { Card, mediaSmDown } from '@plandok/core'
import styled, { css } from 'styled-components'

export const PaymentMethodPageContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   min-height: inherit;
`

export const PaymentMethodCard = styled(Card)`
   width: 596px;
   padding: 32px 40px 40px;
   margin: 60px auto;
   box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
   border: none;
   ${mediaSmDown(css`
      width: calc(100vw - 16px);
      padding: 20px 14px 40px;
   `)}
`

export const CurrentPaymentMethodContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 46px 24px 0;
   ${mediaSmDown(css`
      padding: 0 0 28px;
   `)}
`

export const OtherPaymentMethodsContainer = styled.div`
   border-top: 1px solid #dfe6f1;
   padding: 24px 0 8px 0;
   ${mediaSmDown(css`
      padding: 21px 0 16px 0;
   `)}
`

export const OtherPaymentMethodContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 16px;
`

export const Control = styled.div`
   position: inherit;
   margin-left: 18px;
   right: 30px;
   button {
      width: 33px;
      height: 33px;
      circle {
         fill: #13316d;
      }
   }
`

export const AddPaymentMethod = styled.div`
   display: flex;
   max-width: max-content;
   align-items: center;
   div {
      color: #2472e8;
   }
   :hover {
      cursor: pointer;
   }
`

export const NoPaymentMethodContainer = styled.div`
   text-align: center;

   div:first-of-type {
      line-height: 28px;

      ${mediaSmDown(css`
         max-width: 227px;
         margin: 0 auto;
      `)};
   }
   button {
      padding: 0 24px;
   }
`

export const InputsCardBlock = styled.div`
   padding-bottom: 24px;
   line-height: 16px;
   .StripeElement {
      padding: 11px 24px;
      border: 1px solid #adb6cc;
      border-radius: 26px;
      min-width: 241px;
   }
   .StripeElement--focus,
   .StripeElement--hover {
      border-color: grey;
   }

   ${mediaSmDown(css`
      .StripeElement {
         min-width: 157px;
      }
   `)}
`

export const CardNumberTitle = styled.div`
   display: flex;
   justify-content: space-between;
   padding-right: 24px;
   margin-bottom: 8px;
   align-items: flex-end;
`

export const CardElementsWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   margin-top: 11px;
`
