import { Button, CustomIcon, getIsMobile, mediaMdDown, Text, useLocation } from '@plandok/core'
import { IntlTemplate } from '@plandok/i18n'
import { RoutePath } from 'constants/routes'
import React, { memo } from 'react'
import styled, { css } from 'styled-components'

export const Container = styled.div`
   background: #fff8f8;
   border: 1px solid #eb3b30;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   padding: 7px;
   align-items: center;
   margin-bottom: 16px;

   ${mediaMdDown(css`
      padding: 20px 24px 24px;
      max-width: calc(100vw - 20px);
      margin: 16px auto;

      div {
         flex-direction: column;
         align-items: start;
         span {
            margin-bottom: 10px;
            svg {
               width: 24px;
               height: 24px;
            }
         }
      }
   `)};
`

const BtnPayNow = styled(Button)`
   margin-left: 11px;
   height: 36px !important;
   line-height: 16px;
   font-size: 14px;
   display: flex;
   align-items: center;
   padding: 10px 35px;

   ${mediaMdDown(css`
      margin: 0;
   `)};
`

export const BtnDismiss = styled(Button)`
   margin-left: 16px;
   height: 36px !important;
   line-height: 16px;
   font-size: 14px;
   display: flex;
   align-items: center;
   border: 1px solid #adb6cc !important;
   box-shadow: none !important;
   padding: 10px 37px;

   ${mediaMdDown(css`
      margin: 0;
   `)};
`

export const BtnsWrapper = styled.div`
   display: flex;

   ${mediaMdDown(css`
      flex-direction: row-reverse !important;
      min-width: 100%;
      justify-content: space-between;
   `)};
`

type PaymentErrorNotificationType = {
   warningDate?: string
   type?: string
   setIsDisplayPaymentErrorAlert: (isDisplayAlert: boolean) => void
}

export default memo(function PaymentErrorNotification({
   warningDate,
   type,
   setIsDisplayPaymentErrorAlert,
}: PaymentErrorNotificationType) {
   const { navigate } = useLocation()
   const isMobile = getIsMobile()

   const goToPlanCreate = () =>
      navigate(type === 'subscription_payment_failed' ? RoutePath.UPDATE_SUBSCRIPTION : RoutePath.NOTIFICATION_PAYMENT)
   const dismiss = () => {
      sessionStorage.setItem('isPaymentErrorDismiss', 'true')
      setIsDisplayPaymentErrorAlert(false)
   }

   return (
      <Container>
         <div className="align-center">
            <span className="align-center">
               <CustomIcon type="clear" />
               <Text
                  weight="semiBold"
                  size={isMobile ? 'mlarge' : 'base'}
                  mb="none"
                  label="payment.error.title"
                  ml="xsmall"
                  lh={isMobile ? 'xxlarge' : 'small'}
               />
            </span>

            <Text
               size="small"
               mb={isMobile ? 'small' : 'none'}
               ml={isMobile ? 'none' : 'base'}
               lh="large"
               style={{ maxWidth: '596px' }}>
               <IntlTemplate
                  label={
                     type === 'subscription_payment_failed'
                        ? 'subscriptionPaymentFailed.title'
                        : 'notificationPaymentFailed.title'
                  }
                  params={[warningDate]}
               />
            </Text>

            <BtnsWrapper>
               <BtnPayNow label="payment.payNow.title" onClick={goToPlanCreate} upperCase={false} lightBtn semiBold />
               <BtnDismiss label="payment.dismiss.title" onClick={dismiss} upperCase={false} whiteBtn semiBold />
            </BtnsWrapper>
         </div>
      </Container>
   )
})
