import { useNavigation } from '@plandok/core'
import { AuthLayout, NoInternetConnection } from 'components'
import { RoutePath } from 'constants/routes'
import React from 'react'

import SetNewPasswordFooter from './SetNewPasswordFooter'
import SetNewPasswordForm from './SetNewPasswordForm'

export default function SetNewPasswordPage() {
   const { params } = useNavigation()

   const email = params?.email
   const passwordResetToken = params?.token

   return (
      <NoInternetConnection>
         <AuthLayout
            title="title.newPassword"
            mobileDescription="title.newPassword.description"
            page={RoutePath.RESET_PASSWORD}>
            <AuthLayout.Card>
               <SetNewPasswordForm
                  initialValues={{
                     email,
                     passwordResetToken,
                  }}
               />
            </AuthLayout.Card>
            <br />
            <SetNewPasswordFooter />
         </AuthLayout>
      </NoInternetConnection>
   )
}
