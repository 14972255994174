import { Button, Form, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Divider, Radio } from 'antd'
import flow from 'lodash/flow'
import noop from 'lodash/noop'
import React, { useState } from 'react'

import * as SC from '../styles'

function CreateClientByGenderModal(props: any) {
   const { groupName, updateGender } = props.modalProps
   const [clientAgeType, setClientAgeType] = useState(0)
   const clientByGenderData = [
      {
         text: 'Females only',
      },
      {
         text: 'Males only',
      },
   ]

   const genderData = () => {
      updateGender(groupName, clientByGenderData[clientAgeType].text)
      props.onClose()
   }

   return (
      <Form onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}>
         {({ submitting }: any) => {
            return (
               <SC.StyledAgeContainer>
                  {clientByGenderData &&
                     clientByGenderData.map((item, index) => (
                        <SC.Container key={index} background={clientAgeType === index}>
                           <Radio onClick={() => setClientAgeType(index)} checked={clientAgeType === index}>
                              <IntlLabel label={item.text} />
                           </Radio>
                        </SC.Container>
                     ))}
                  <SC.Divider as={Divider} />
                  <Button type="primary" label="btn.save" onClick={genderData} loading={submitting} upperCase={false} />
               </SC.StyledAgeContainer>
            )
         }}
      </Form>
   )
}

export default withModal('Clients by Gender', { contentClassName: 'age-modal-content' })(CreateClientByGenderModal)
