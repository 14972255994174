import { useDraggable } from '@dnd-kit/core'
import { CustomIcon } from '@plandok/core'
import React from 'react'

import * as SC from '../styles'
const DragHandle = (props: { id: string }) => {
   const { attributes, listeners, setNodeRef } = useDraggable({
      id: props.id,
   })

   return (
      <td className="drag-cell" ref={setNodeRef} {...listeners} {...attributes}>
         <CustomIcon type="smallDragHandle" />
      </td>
   )
}

function TableRow({ value = {}, columns, index, onClick }: any) {
   return (
      <SC.Row className={`ant-table-row ant-table-row-level-${index}`} data-row-key={index} onClick={onClick}>
         <DragHandle id={value.id} />
         {columns.map(({ render: RenderComponent, key, style }: any) => (
            <td key={`${key}`} style={style}>
               {RenderComponent ? <RenderComponent value={value} /> : value[key]}
            </td>
         ))}
      </SC.Row>
   )
}

export default TableRow
