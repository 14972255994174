import { useAppContext } from '@plandok/core'
import { useQuery } from '@tanstack/react-query'
import { Spin } from 'antd'
import * as api from 'api'
import React from 'react'

import { PriceType } from './constants'
import { extractPlans } from './helpers'
import { PricingItemGroup } from './PricingItemGroup'
import * as SC from './styles'

type PricingPageProps = {
   fetchCurrentPlan: () => void
}

export default function PricingPage({ fetchCurrentPlan }: PricingPageProps) {
   const [context] = useAppContext()

   const { data, isLoading, refetch } = useQuery({
      queryKey: [],
      queryFn: api.fetchPlans,
   })

   const { freePlan, premiumPlan } = extractPlans(data?.data || [])

   const currentPlanId = data?.meta?.currentPlanId
   const endDate = data?.meta?.endDate?.replaceAll('-', '.') || ''

   return (
      <Spin spinning={isLoading}>
         <SC.PricingList>
            <PricingItemGroup
               active={currentPlanId && freePlan?.id === currentPlanId}
               plan={freePlan}
               planType={PriceType.FREE}
               fetchData={refetch}
               planId={freePlan?.id}
               currentStaffCount={context.subscription.employeeCount}
            />
            <PricingItemGroup
               active={currentPlanId && premiumPlan?.id === currentPlanId}
               plan={premiumPlan}
               planType={PriceType.PREMIUM}
               fetchData={refetch}
               planId={premiumPlan?.id}
               currentStaffCount={context.subscription.employeeCount}
               endDate={endDate}
               fetchCurrentPlan={fetchCurrentPlan}
            />
         </SC.PricingList>
      </Spin>
   )
}
