import { useCallback, useState } from 'react'

import { useStore } from '../../../../store/store'

type UseQueryReportsType = {
   locations: any
   employees: any
   setTimeRange: (e: any) => void
}

export const useFilters = ({ locations, employees, setTimeRange }: UseQueryReportsType) => {
   const [selectedStaffValue, setSelectedStaffValue] = useState('')
   const [selectedLocationValue, setSelectedLocationValue] = useState('')

   const setLocationId = useStore(useCallback(state => state.setLocationId, []))
   const setEmployeeId = useStore(useCallback(state => state.setEmployeeId, []))
   const setStartDate = useStore(useCallback(state => state.setStartDate, []))
   const setEndDate = useStore(useCallback(state => state.setEndDate, []))
   const setIsTimeRangeSelected = useStore(useCallback(state => state.setIsTimeRangeSelected, []))

   const mappedLocations: { code: string; name: string }[] = locations?.edges?.map(({ node }: any) => ({
      code: node.id,
      name: node.name,
   }))
   const mappedEmployees: { code: string; name: string }[] = employees?.edges?.map(({ node }: any) => ({
      code: node.id,
      name: `${node.firstName} ${node.lastName ?? ''}`,
   }))

   const onStaffChange = (selectedId: string) => {
      const selectedStaffName = mappedEmployees.find(employee => employee.code === selectedId)?.name ?? ''
      const selectedStaffId = mappedEmployees.find(employee => employee.code === selectedId)?.code ?? ''
      setSelectedStaffValue(selectedStaffName)
      setEmployeeId(selectedStaffId)
   }
   const onLocationChange = (selectedId: string) => {
      const selectedLocationName = mappedLocations.find(location => location.code === selectedId)?.name ?? ''
      const selectedLocationId = mappedLocations.find(location => location.code === selectedId)?.code ?? ''
      setSelectedLocationValue(selectedLocationName)
      setLocationId(selectedLocationId)
   }

   const clearFilters = () => {
      setEmployeeId('')
      setLocationId('')
      setSelectedLocationValue('')
      setSelectedStaffValue('')
      setStartDate(null)
      setEndDate(null)
      setIsTimeRangeSelected(false)
      setTimeRange('')
   }

   const selectAllStaffValues = () => {
      setEmployeeId('')
      setSelectedStaffValue('')
   }
   const selectAllLocationValues = () => {
      setLocationId('')
      setSelectedLocationValue('')
   }

   return {
      mappedLocations,
      mappedEmployees,
      clearFilters,
      selectAllStaffValues,
      selectAllLocationValues,
      selectedStaffValue,
      selectedLocationValue,
      onStaffChange,
      onLocationChange,
      setSelectedStaffValue,
      setSelectedLocationValue,
   }
}
