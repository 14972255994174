import React, { useState } from 'react'

export default function Mutation({ children, action, id, passId, onSuccess }: any) {
   const [loading, setLoading] = useState(false)

   const actionFunc = (props: any) => {
      setLoading(true)
      return (passId ? action(props?.id, props) : action(id, props)).then((res: any) => {
         setLoading(false)
         if (onSuccess) {
            return onSuccess(res)
         }
         return res
      })
   }

   return <>{children({ loading, action: actionFunc }) || ''}</>
}
