import { IoC } from '../../di.config'
import { findCurrencySign } from './helpers'
import React from 'react'

export const useCurrencyCode = () => {
   const currencyIsoCode = IoC.getService().useCurrencyCode()
   return currencyIsoCode || 'EUR'
}

export const useCurrencySign = () => {
   const currencyCode = useCurrencyCode()
   return findCurrencySign(currencyCode)
}

export const withCurrencySign = (Component: any) => {
   return (props: any) => {
      const sign = useCurrencySign()
      return <Component sign={sign} {...props} />
   }
}

/**
 * IntlMoney - represent number string as money
 * @example  '1.2' -> '1.2 $' with currency USD
 */
export default function IntlMoney({ value, currency }: { value: number | string; currency?: string }) {
   const currencyIsoCode = useCurrencyCode()
   const val = typeof value === 'string' ? parseFloat(value) || 0 : value
   const locale = currencyIsoCode === 'USD' ? 'en-US' : undefined

   if (typeof val?.toLocaleString !== 'function') {
      const currencySign = findCurrencySign(currencyIsoCode)
      return <>{val?.toString()?.concat(' ')?.concat(currencySign)}</>
   }

   const formatValue = val.toLocaleString(locale, {
      currency,
      maximumFractionDigits: 0,
   })

   if (currency) {
      return (
         <>
            {formatValue} {currency}
         </>
      )
   }

   return <>{val.toLocaleString(locale, { style: 'currency', currency: currencyIsoCode })}</>
}
