/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
   'login.title': 'Zaloguj się na swoje konto',
   'label.email': 'Adres e-mail',
   'placeholder.email': 'np. jankowalski@gmail.com',
   'input.password.label': 'Hasło',
   'input.password.placeholder': 'Wprowadź hasło',
   'login.btn': 'Zaloguj się na Plandok',
   'link.sign.in': 'Zaloguj się',
   'label.password': 'Wprowadź hasło',
   'login.forgot.password': 'Nie pamiętasz hasła?',
   'login.no.account': 'Nie masz konta?',
   'login.description': 'W razie problemów z logowaniem, spróbuj zresetować swoje hasło.',
   'register.title': 'Utwórz konto',
   'register.description':
      'Utwórz darmowe konto w Wirtualnym Terminarzu Plandok - programowi do zarządzania rezerwacjami',
   'register.btn': 'Utwórz moje konto',
   'register.agreement.agree': 'Akceptuję',
   'register.agreement.terms': 'Regulamin',
   'register.agreement.policy': 'Politykę prywatności',
   'register.agreement.and': 'i',
   'register.agreement.partner': 'Warunki Handlowe Partnerów',
   'register.have.account': 'Masz już konto?',
   'register.validation.confirm': 'Zanim przejdziesz dalej, przejrzyj i zaakceptuj powyższe ustalenia.',
   'link.sign.up': 'Zarejestruj się',
   'text.alreadyHaveAccount': 'Masz już konto?',
   'input.company.label': 'Firma',
   'input.company.placeholder': 'Firma',
   'input.country.label': 'Kraj',
   'input.country.placeholder': 'Nazwa kraju',
   'input.timeZone.label': 'Strefa czasowa',
   'input.timeZone.placeholder': 'GTM +02.00',
   'input.currency.label': 'Waluta',
   'input.language.label': 'Język (Language)',
   'input.language.placeholder': 'Polski',
   'input.currency.placeholder': 'Złoty - PLN',
   'reset.title': 'Zresetuj hasło',
   'reset.btn.text': 'Wyślij prośbę o zresetowanie hasła',
   'reset.pwd.btn': 'Zresetuj hasło',
   'reset.no.password': 'Nie chcesz resetować swojego hasła?',
   'reset.description': 'Podaj nam swój adres e-mail, a my wyślemy Ci intrukcję zmiany hasła.',
   'sidebar.calendar': 'Kalendarz',
   'sidebar.client': 'Klienci',
   'sidebar.staff': 'Pracownicy',
   'sidebar.service': 'Usługi',
   'sidebar.notification': 'Wiadomości',
   'sidebar.settings': 'Ustawienia',
   'sidebar.menu': 'Menu',
   'sidebar.analytics': 'Analityka',
   'sidebar.language': 'Język (Language)',
   'sidebar.logout': 'Wyloguj się',
   'user.menu.settings': 'Moje ustawienia',
   'user.menu.language': 'Zmień język',
   'user.menu.logout': 'Wyloguj się',
   'input.globalSearch.placeholder': 'Czego szukasz?',
   'input.globalSearch.description':
      'Szukaj według nazwy klienta, numeru telefonu, adresu e-mail lub numeru rezerwacji',
   'title.upcomingAppointments': 'Nadchodzące wizyty',
   'calendar.day': 'Dzień',
   'calendar.week': 'Tydzień',
   'calendar.monday': 'Poniedziałek',
   'calendar.tuesday': 'Wtorek',
   'calendar.wednesday': 'Środa',
   'calendar.thursday': 'Czwartek',
   'calendar.friday': 'Piątek',
   'calendar.saturday': 'Sobota',
   'calendar.sunday': 'Niedziela',
   'calendar.mon': 'Pon',
   'calendar.tue': 'Wto',
   'calendar.wed': 'Śro',
   'calendar.thu': 'Czw',
   'calendar.fri': 'Pią',
   'calendar.sat': 'Sob',
   'calendar.sun': 'Nie',
   'calendar.today': 'Dzisiaj',
   'calendar.january': 'Styczeń',
   'calendar.february': 'Luty',
   'calendar.march': 'Marzec',
   'calendar.april': 'Kwiecień',
   'calendar.may': 'Maj',
   'calendar.june': 'Czerwiec',
   'calendar.july': 'Lipiec',
   'calendar.august': 'Sierpień',
   'calendar.september': 'Wrzesień',
   'calendar.october': 'Październik',
   'calendar.november': 'Listopad',
   'calendar.december': 'Grudzień',
   'calendar.jan': 'Sty',
   'calendar.feb': 'Lut',
   'calendar.mar': 'Mar',
   'calendar.apr': 'Kwi',
   'calendar.may.short': 'Maj',
   'calendar.jun': 'Cze',
   'calendar.jul': 'Lip',
   'calendar.aug': 'Sie',
   'calendar.sept': 'Wrz',
   'calendar.oct': 'Paź',
   'calendar.nov': 'Lis',
   'calendar.dec': 'Gru',
   'calendar.allStaff': 'Wszystkie',
   'calendar.workingStaff': 'Pracujący',
   'calendar.tooltip.time': 'Czas',
   'calendar.tooltip.category': 'Kategoria',
   'calendar.tooltip.service': 'usługa',
   'calendar.tooltip.staff': 'Pracownik',
   'calendar.tooltip.price': 'Cena',
   'calendar.tooltip.repeating': 'Powtórzenie',
   'btn.viewDetails': 'Zobacz szczegóły',
   'calendar.tooltip.emptyCustomer': 'Z wejściem bezpośrednio z ulicy',
   'calendar.tooltip.bookedOnline': 'Rezerwacja internetowa',
   'calendar.print.description':
      'Aby móc korzystać z funkcji drukowania, Twój plan musi zostać zaktualizowany. Aby uaktualnić odwiedź',
   'calendar.print.appointmentsBetween.title': 'Spotkania pomiędzy',
   'calendar.print.appointmentsAnd.title': 'I',
   'calendar.billing.title': 'Planowanie i rozliczenia',
   'calendar.btn.print.title': 'Wydrukować',
   'datepicker.next.week': 'W następnym tygodniu',
   'datepicker.in.two.weeks': 'Za 2 tygodnie',
   'datepicker.in.three.weeks': 'Za 3 tygodnie',
   'datepicker.in.four.weeks': 'Za 4 tygodnie',
   'datepicker.in.five.weeks': 'Za 5 tygodni',
   'datepicker.in.six.weeks': 'Za 6 tygodni',
   'modal.splitAppointment.title': 'Podziel wizytę',
   'modal.splitAppointment.text':
      'Ta wizyta jest częścią grupy, przesunięcie jej na inny dzień spowoduje zamienienie jej na osobne rezerwacje. Jesteś pewny?',
   'blocktime.create.modal.title': 'Zablokuj czas',
   'blocktime.edit.modal.title': 'Edytuj blokadę przedziału czasowego',
   'appointment.create.menu': 'Wizyta',
   'blocktime.create.menu': 'Zablokuj przedział czasowy',
   'appointment.new.title': 'Nowa wizyta',
   'input.startTime.label': 'Czas rozpoczęcia',
   'input.endTime.label': 'Czas zakończenia',
   'input.startDate.label': 'Data de início',
   'input.endDate.label': 'Data de término',
   'input.startDate.description.label': 'Selecione a data de início',
   'input.endDate.description.label': 'Selecione a data de término',
   'input.closedDatedDescription.placeholder': 'por exemplo feriados',
   'input.service.label': 'Usługa',
   'input.services.label': 'usługi',
   'input.service.placeholder': 'Wybierz usługę',
   'input.staff.label': 'Pracownicy',
   'input.staff.placeholder': 'Wybierz pracownika',
   'input.pickDateAndTime.title': 'Wybierz datę i godzinę',
   'input.pickDate.title': 'Wybierz datę',
   'service.add.another': 'Dodaj inną usługę',
   'input.appointmentNotes.label': 'Notatki o wizycie',
   'input.emailSubject.placeholder': 'Notatka o Twojej wizycie',
   'input.appointmentNotes.placeholder': 'Dodaj notatkę o wizycie (widoczną wyłącznie dla pracowników)',
   'input.searchClients.placeholder': 'Znajdź klienta',
   'input.searchService.placeholder': 'Szukaj',
   'appointment.empty.list':
      'Wyszukaj, aby dodać klienta lub pozostaw puste, aby umożliwić wizytę z wejściem bezpośrednio z ulicy.',
   'appointment.clients.empty.list': 'Lista klientów jest pusta.',
   'appointment.clients.addClient': 'Utwórz klienta',
   'client.create.new': 'Utwórz nowego klienta',
   'client.choose': 'Wybierz klienta',
   'client.menu.edit': 'Edytuj dane klienta',
   'client.menu.remove': 'Usuń z wizyty',
   'appointment.btn.save': 'Rezerwuj ',
   'appointment.vat': 'VAT ({0}%)',
   'appointment.total': 'Całość',
   'appointment.view.title': 'Zobacz wizytę',
   'appointment.status.btn': 'Status wizyty',
   'appointment.btn.cancel': 'Usuwać',
   'btn.cancelAppointment': 'Kasować',
   'modal.cancelAppointment.title': 'Kasować',
   'title.clients': 'Klienci',
   'client.create.title': 'Nowy klient',
   'client.options.export.title': 'Eksportuj klientów',
   'client.options.export.description': 'Pobierz plik CSV z danymi klienta',
   'client.options.import.title': 'Importuj klientów',
   'client.export.premium.description': 'Aby móc korzystać z funkcji eksportu, Twój plan musi zostać uaktualniony.',
   'client.export.premium.visit': 'Aby uaktualnić odwiedź ',
   'client.import.instructions.description':
      'Postępuj zgodnie z poniższymi instrukcjami, aby zaimportować klientów na swoje konto Plandok',
   'client.import.instructions.download': 'Pobierz plik importu, korzystając z przycisku poniżej',
   'client.import.instructions.copy': 'Skopiuj i wklej dane klienta do pliku',
   'client.import.instructions.email': 'Wyślij go e-mailem na adres context@plandok.com',
   'client.import.instructions.account': 'a my zaimportujemy na Twoje konto',
   'table.clients.name': 'Nazwa',
   'table.clients.phone': 'Telefon',
   'table.clients.mobile': 'Telefon',
   'table.clients.email': 'Adres e-mail',
   'table.clients.gender': 'Płeć',
   'client.page.title': 'Profil klienta',
   'btn.more.options': 'Więcej opcji',
   'btn.showAll': 'Pokaż wszystko',
   'btn.collapse': 'Zwiń',
   'btn.reschedule': 'Zmień termin',
   'title.edit.client': 'Edytuj profil klienta',
   'menu.edit.client': 'Edytuj profil klienta',
   'menu.delete.client': 'Usuń profil klienta',
   'title.mobilePhone': 'Telefon komórkowy',
   'title.telephoneNumber': 'Numer telefonu',
   'title.email': 'Adres e-mail',
   'title.address': 'Adres',
   'title.dateOfBirth': 'Data urodzenia',
   'title.gender': 'Płeć',
   'client.statistics': 'Statystyki',
   'client.totalSales': 'Całość sprzedaży',
   'client.totalBookings': 'Całkowite rezerwacje',
   'client.outstanding': 'Wyjątkowy',
   'client.allBooking': 'Wszystkie rezerwacje',
   'client.completed': 'Zakończone',
   'client.cancelled': 'Odwołane',
   'client.noShow': 'Klient nie pojawił się',
   'appointment.list.title': 'Wizyty',
   'upcoming.list.title': 'Nadchodzące',
   'past.list.title': 'Poprzednie',
   'modal.confirmClientDelete.text.0': 'Czy na pewno chcesz trwale usunąć profil klienta',
   'modal.confirmClientDelete.text.1': 'z bazy danych?',
   'input.date.label': 'Konkretna data',
   'input.date.placeholder': 'Wybierz datę',
   'input.firstName.label': 'Imię',
   'input.firstName.placeholder': 'np. Jan',
   'input.lastName.label': 'Nazwisko',
   'input.lastName.placeholder': 'np. Kowalski',
   'input.mobile.label': 'Telefon komórkowy',
   'input.mobile.placeholder': 'Wprowadź numer',
   'input.email.label': 'Adres e-mail',
   'input.email.placeholder': 'np. jankowalski@gmail.com',
   'input.title.label': 'Tytuł',
   'input.title.placeholder': 'np. Dentysta',
   'input.notificationType.label': 'Wysyłaj powiedomienia za pomocą',
   'tab.personalInfo': 'Dane osobowe',
   'input.gender.label': 'Płeć',
   'input.referal.label': 'Źródło polecenia',
   'input.referal.placeholder': 'Wybierz źródło',
   'input.birthDay.label': 'Urodziny',
   'input.month.placeholder': 'Miesiąc',
   'input.day.placeholder': 'Dzień',
   'input.year.placeholder': 'Rok',
   'input.clientsNotes.label': 'Notatki o kliencie',
   'input.clientsNotes.placeholder': 'Wprowadź notatki o kliencie',
   'input.bookingDisplay.label': 'Pokaż na wszystkich rezerwacjach',
   'input.serviceDescription.label': 'Opis usługi',
   'input.serviceDescription.placeholder':
      'Dodaj krótki opis, który będzie widoczny dla klientów podczas rezerwacji online',
   'input.isBookableOnline.label': 'Dostępne w przypadku rezerwacji online',
   'service.onlineBookings.title': 'Rezerwacje internetowe',
   'tab.address': 'Adres',
   'input.address.label': 'Adres',
   'input.address.placeholder': 'Wprowadź adres',
   'input.city.label': 'Miasto',
   'input.city.placeholder': 'Wprowadź miasto',
   'input.zip.label': 'Kod pocztowy',
   'input.zip.placeholder': 'Wprowadź kod pocztowy',
   'btn.edit': 'Edytuj',
   'btn.cancel': 'Anuluj',
   'btn.save': 'Zapisz',
   'btn.save.changes': 'Zapisać',
   'tabs.staffWorking.title': 'Godziny pracy',
   'tabs.staffMembers.title': 'Pracownicy',
   'tabs.userPermissions.title': 'Uprawnienia użytkownika',
   'tabs.closedDates.title': 'Zamknięte daty',
   'btn.new.staff': 'Nowy pracownik',
   'table.staff.name': 'Imię',
   'table.staff.mobileNumber': 'Numer telefonu',
   'table.staff.email': 'Adres e-mail',
   'table.staff.userPermission': 'Uprawnienia użytkownika',
   'table.staff.dataRange': 'Zakres dat',
   'table.staff.numberOfDays': 'Liczba dni',
   'table.staff.location': 'Lokalizacja',
   'table.staff.description': 'Opis',
   'table.appointmentsList.appointment': 'Spotkanie',
   'table.appointmentsList.client': 'Klient',
   'table.appointmentsList.service': 'Praca',
   'table.appointmentsList.date': 'Data',
   'table.appointmentsList.time': 'Czas',
   'table.appointmentsList.duration': 'Czas trwania',
   'table.appointmentsList.location': 'Lokalizacja',
   'table.appointmentsList.staff': 'Personel',
   'table.appointmentsList.price': 'Cena',
   'table.appointmentsList.status': 'Status',
   'table.appointmentsCancellations.ref': 'Nr ref',
   'table.appointmentsCancellations.client': 'Klient',
   'table.appointmentsCancellations.service': 'Praca',
   'table.appointmentsCancellations.scheduledDate': 'Zaplanowana data',
   'table.appointmentsCancellations.cancelledDate': 'Anulowana data',
   'table.appointmentsCancellations.cancelledBy': 'Anulowane przez',
   'table.appointmentsCancellations.reason': 'Powód',
   'table.appointmentsCancellations.price': 'Cena',
   'table.appointmentsCancellations.totalCount': 'Całkowita liczba',
   'table.clients.appointments': 'Spotkania',
   'table.clients.noShows': 'Brak pokazów',
   'table.clients.totalSales': 'Całkowita sprzedaż',
   'table.clients.added': 'W dodatku',
   'table.clients.daysAbsent': 'Dni nieobecności',
   'table.clients.lastAppointment': 'Ostatnie spotkanie',
   'table.clients.lastLocation': 'Ostatnia lokalizacja',
   'table.appointmentsByService.serviceName': 'Nazwa usługi',
   'table.appointmentsByService.appointments': 'Spotkania',
   'table.appointmentsByService.totalValue': 'Całkowita wartość',
   'table.appointmentsByStaff.staffMember': 'Członek personelu',
   'table.appointmentsByStaff.appointments': 'Spotkania',
   'table.appointmentsByStaff.totalValue': 'Całkowita wartość',
   'table.pagination.showing.title': 'Seans',
   'table.pagination.results.title': 'wyniki',
   'clients.duplicate.title': 'Znaleziono zduplikowanych klientów.',
   'clients.duplicate.description': 'Istnieją podobni klienci, których można połączyć.',
   'clients.merge.btn.title': 'Łączyć',
   'clients.merge.modal.title': 'Połącz klientów',
   'clients.merge.selectAll.title': 'Wybierz wszystkie duplikaty',
   'clients.merge.duplicatesNotFound.title': 'Nie znaleziono duplikatów',
   'clients.merge.duplicatesNotFound.description': 'Nie ma dostępnych klientów do połączenia',
   'clients.mergeConfirm.modal.title': 'Potwierdź połączenie',
   'clients.mergeConfirm.modal.description':
      'Czy na pewno chcesz scalić {0} duplikatów? Ta czynność jest trwała i nie można jej cofnąć.',
   'clients.mergeConfirm.checkbox.title': 'Rozumiem, że połączenia klientów nie można cofnąć',
   'clients.mergeConfirm.btn.title': 'Potwierdź i połącz',
   'clients.mergeConfirm.cancel.btn.title': 'Anulować',
   'clients.byNumber.btn.title': 'Telefonicznie {0}',
   'clients.byEmail.btn.title': 'Przez e-mail {0}',
   'clients.byName.btn.title': 'Według nazwy {0}',
   'title.newStaff': 'Nowy pracownik',
   'title.editStaff': 'Edytuj pracowników',
   'description.extraCharge':
      'Po dodaniu nowych pracowników do miesięcznej subskrypcji zostanie doliczona dodatkowa opłata.',
   'tabs.details': 'Szczegóły',
   'input.staffPicture.label': 'Zdjęcie',
   'input.cropImage.title': 'Przytnij obraz',
   'input.cropImage.btn.apply.title': 'Stosować',
   'input.stafffirstName.label': 'Imię',
   'input.stafffirstName.placeholder': 'np. Jan',
   'input.stafflastName.label': 'Nazwisko',
   'input.stafflastName.placeholder': 'np. Kowalski',
   'input.stafmobile.label': 'Telefon',
   'input.stafemail.label': 'Adres e-mail',
   'input.stafemail.placeholder': 'np. jankowalski@gmail.com',
   'input.permission.label': 'Uprawnienia użytkownika',
   'input.uploadProfile.label': 'Prześlij zdjęcie profilowe',
   'input.changeProfile.label': 'Zmień zdjęcie profilowe',
   'input.notes.label': 'Notatki',
   'input.staffNotes.placeholder': 'Dodaj notatki prywatne widoczne tylko w ustawieniach pracowników (opcjonalne)',
   'input.blockTime.placeholder': 'np. spotkanie przy obiedzie',
   'tabs.locations': 'Lokalizacje',
   'tabs.integrations': 'Integracje',
   'input.staffLocations.description': 'Ustaw lokalizacje, w których można wykonać rezerwację dla tego pracownika.',
   'input.multiLocation.description': 'Ustaw lokalizacje, w których można wykonać rezerwację dla tego pracownika.',
   'tabs.services': 'Usługi',
   'input.staffServices.description': 'Ustaw usługi świadczone przez tego pracownika.',
   'input.multiServices.description': 'Ustaw usługi świadczone przez tego pracownika.',
   'input.shiftStart.label': 'Początek zmiany',
   'input.shiftEnd.label': 'Koniec zmiany',
   'edit.staff.title.resendPassword': 'Ponownie wyślij hasło',
   'edit.staff.info.resendPassword':
      'Pracownik nie otrzymał wiadomości e-mail z hasłem? Kliknij przycisk poniżej, aby ponownie wysłać wiadomość e-mail zawierającą instrukcje dotyczące konfiguracji hasła.',
   'edit.staff.button.resendPassword': 'Wyślij ponownie e-mail z hasłem',
   'btn.add.shift': 'Dodaj zmianę',
   'break.time': '{0} przerwa',
   'input.repeat.label': 'Powtarzaj',
   'input.repeatWeekly.label': 'Tygodniowo',
   'input.repeatDoNot.label': 'Nie powtarzaj',
   'input.endRepeat.label': 'Koniec powtarzania',
   'input.endRepeatOngoing.label': 'Trwa',
   'input.endRepeatSpecificDate.label': 'Konkretna data',
   'input.endRepeatDate.placeholder': 'Wybierz datę',
   'btn.deleteUpcomingShift': 'Usuń nadchodzące zmiany',
   'btn.deleteThisShift': 'Usuń tylko tę zmianę',
   'btn.updateUpcomingShift': 'Zaktualizuj nadchodzące zmiany',
   'btn.updateThisShift': 'Zaktualizuj tylko tę zmianę',
   'modal.repeatShift.title': 'Powtarzająca się zmiana',
   'form.error.uniqShifts': 'Zmiany powinny być wyjątkowe',
   'btn.add.new': 'Dodaj nowe',
   'btn.addNewService': 'Dodaj nową usługę',
   'btn.newCategory': 'Nowa kategoria',
   'btn.newServiceGroup': 'Nowa kategoria',
   'btn.editCategory': 'Edytuj kategorię',
   'btn.deleteCategory': 'Usuń kategorię',
   'btn.downloadImportFile': 'Pobierz plik importu',
   'serviceGroup.delete.success': 'Kategoria została pomyślnie usunięta!',
   'service.new.title': 'Nowa usługa',
   'service.edit.title': 'Edytuj usługę',
   'input.serviceName.label': 'Nazwa usługi',
   'input.serviceName.placeholder': 'np. masaż',
   'input.serviceCategory.label': 'Kategoria usług',
   'input.serviceCategory.placeholder': 'Wybierz kategorię usług',
   'input.price.label': 'Cena',
   'input.price.placeholder': '0',
   'input.duration.label': 'Czas trwania',
   'input.duration.placeholder': 'Czas trwania',
   'input.staffSelect.description': 'Wybierz pracownika, który świadczy tę usługę',
   'input.newCategoryName.label': 'Nazwa kategorii',
   'input.newCategoryDescription.label': 'Opis kategorii',
   'input.newCategoryDescription.placeholder': 'Opisz tę kategorię, będzie ona widoczna w Rezerwacjach online',
   'modal.newCategory.title': 'Nowa kategoria',
   'modal.editCategory.title': 'Edytuj kategorię',
   'input.chooseCategory.label': 'Wybierz kolor kategorii:',
   'client.messages.title': 'Wiadomości od klienta',
   'tabs.messagesLog': 'Dziennik komunikatów',
   'tabs.messagesLog.description': 'Lista wysłanych wiadomości do Twoich klientów',
   'tabs.messagesSettings': 'Ustawienia',
   'tabs.messagesSettings.description': 'Dostosuj ustawienia wiadomości do potrzeb swojej firmy',
   'table.header.timeSent': 'Wysłane',
   'table.header.client': 'Klient',
   'table.header.appointment': 'Wizyta',
   'table.header.destination': 'Adres',
   'table.header.type': 'Rodzaj',
   'table.header.message': 'Wiadomość',
   'table.header.status': 'Status',
   'table.header.cost': 'Koszt',
   'modal.message.title': 'Zobacz wiadomość',
   'setup.page.title': 'Konfiguracja',
   'title.accountSettings': 'Ustawienia konta',
   'title.accountSetup': 'Konfiguracja konta',
   'title.accountSetup.description': 'Zarządzaj ustawieniami (np. nazwa firmy lub strefa czasowa)',
   'resources.list.title': 'Zasoby',
   'title.resources': 'Zasoby',
   'title.resources.description': 'Ustaw zasoby podlegające rezerwacji, np. gabinety, krzesła lub inne wyposażenie',
   'title.locations': 'Lokalizacje',
   'title.locations.description': 'Zarządzaj lokalizacjami Twojej firmy',
   'calendar.settings.title': 'Ustawienia kalendarza',
   'calendar.btn.selectFromCalendar': 'Wybierz z kalendarza',
   'title.calendarSettings': 'Ustawienia kalendarza',
   'title.calendarSettings.description': 'Dopasuj kolor i układ Twojego kalendarza',
   'title.clientSettings': 'Ustawienia klientów',
   'title.onlineBookingSettings': 'Ustawienia rezerwacji online',
   'title.salesSettings': 'Ustawienia sprzedaży',
   'title.receiptSequencing': 'Sekwencjonowanie paragonów',
   'title.receiptSequencing.description':
      'Konfiguracja szczegółów wyświetlanych na paragonach sprzedaży wystawianych klientom',
   'title.receiptTemplate': 'Szablon paragonu',
   'title.taxes.description':
      'Zarządzanie stawkami podatku, które mają zastosowanie do przedmiotów sprzedawanych przy kasie',
   'title.paymentTypes': 'Rodzaje płatności',
   'title.paymentTypes.description': 'Skonfiguruj ręczne typy płatności do użycia podczas realizacji zakupu',
   'title.discounts.description': 'Konfiguracja rabatów',
   'title.receiptPrefix': 'Paragon №. Prefiks',
   'title.receiptNumber': 'Następny numer paragonu',
   'title.change': 'Zmień',
   'title.saleReceiptTemplate': 'Szablon paragonu sprzedaży',
   'description.saleReceiptTemplate': 'Dostosuj treść wyświetlaną na paragonach sprzedaży wystawianych klientom',
   'setting.automaticallyPrint': 'Automatyczne drukowanie paragonów po zakończeniu sprzedaży',
   'setting.showMobile': 'Wyświetlanie telefonu komórkowego i adresu e-mail klienta na paragonie sprzedaży',
   'setting.showAddress': 'Wyświetlanie adresu klienta na paragonie sprzedaży',
   'title.receiptTitle': 'Tytuł paragonu',
   'title.referrals': 'Źródła poleceń',
   'title.referrals.description': 'Ustaw źródła poleceń aby sprawdzić, skąd klienci dowiedzieli się o Twojej firmie',
   'title.clientNotifications': 'Ustawienia powiadomień dla klientów',
   'title.clientNotifications.description': 'Zarządzaj wiadomościami wysyłanymi do klientów przed umówioną wizytą.',
   'cancelReason.list.title': 'Powody odwołania',
   'title.cancellationReasons': 'Powody odwołania',
   'title.cancellationReasons.description': 'Sprawdzaj dlaczego klienci nie pojawiają się na wizytach',
   'title.onlineBooking.description': 'Zarządzanie dostępnością i ustawieniami rezerwacji online wycieczek',
   'title.pointOfSale': 'Punkt sprzedaży',
   'discount.list.title': 'Zniżki',
   'title.discountTypes': 'Rodzaje zniżek',
   'title.discountTypes.description': 'Ustaw manualne zniżki, których będziesz używać podczas płatności za wizytę',
   'title.premiumFeature': 'Funkcja premium',
   'company.details.title': 'Dane firmy',
   'input.businessName.label': 'Nazwa firmy',
   'input.businessName.placeholder': 'np. Promyk',
   'input.description.label': 'Opis',
   'input.description.placeholder': 'Wprowadź opis',
   'input.businessAddress.label': 'Adres',
   'input.businessAddress.placeholder': 'Wprowadź adres',
   'input.website.label': 'Strona internetowa',
   'input.website.placeholder': 'Wprowadź stronę internetową',
   'input.contactNumber.label': 'Numer kontaktowy',
   'input.contactNumber.placeholder': 'Wprowadź numer',
   'input.businessTimeZone.label': 'Strefa czasowa',
   'input.businessTimeZone.placeholder': 'GMT +02.00',
   'input.businessCountry.label': 'Kraj',
   'input.businessCountry.placeholder': 'Wybierz kraj',
   'input.timeFormat.label': 'Format czasu',
   'input.timeFormat.placeholder': '24-godzinny',
   'table.header.resourceName': 'Zasoby',
   'btn.new.resource': 'Nowy zasób',
   'input.resourceName.label': 'Nazwa zasobu',
   'input.resourceName.placeholder': 'Wprowadź nazwę',
   'input.resourceDescription.label': 'Opis',
   'input.resourceDescription.placeholder': 'Wprowadź opis',
   'input.resourceLocation.placeholder': 'Nazwa lokalizacji',
   'resourcesTooltip.location': 'Gdzie jest ten zasób?',
   'modal.newResource.title': 'Utwórz nowy zasób',
   'modal.editResource.title': 'Edytuj zasób',
   'table.header.locationName': 'Lokalizacja',
   'table.header.locatonAddress': 'Adres',
   'table.header.location.Phone': 'Telefon',
   'modal.newLocation.title': 'Nowa lokalizacja',
   'modal.editLocation.title': 'Edytuj lokalizację',
   'btn.new.location': 'Nowa lokalizacja',
   'btn.addExtraLocation': 'Dodaj dodatkową lokalizację',
   'input.locationName.label': 'Nazwa lokalizacji',
   'input.locationName.placeholder': 'Wprowadź nazwę lokalizacji',
   'input.profile.locationName.placeholder': 'Np. Salon fryzjerski',
   'input.slug.label': 'Slug',
   'input.locationTips.label': 'Wskazówki dotyczące lokalizacji',
   'input.slug.explanation': 'Będzie widoczny w adresie URL Twojej lokalizacji',
   'input.contactEmail.label': 'E-mail kontaktowy',
   'input.locationAddress.label': 'Adres',
   'input.locationAddress.placeholder': 'Wprowadź adres lokalizacji',
   'tooltip.contactEmail': 'Na ten adres wyślemy odpowiedzi klientów na powiadomienia.',
   'input.appointmentColors.label': 'Kolor wizyty',
   'input.colorBy.service': 'wg Grupy Usługowej',
   'input.colorBy.employee': 'wg pracownika',
   'input.colorBy.appointment': 'wg statusu wizyty',
   'input.timeSlot.label': 'Przedział czasowy',
   'input.defaultViewType.label': 'Widok domyślny',
   'input.calendarTimeRange.label': 'Zakres czasu kalendarza',
   'input.calendarTimeRange.allTimes.option': 'Wszystkie godziny (00:00 - 24:00)',
   'input.calendarTimeRange.onlyWorkingHours.option': 'Tylko godziny pracy',
   'input.calendarTimeRange.customRange.option': 'Zakres niestandardowy',
   'input.weekStart.label': 'Początek (dzień tygodnia)',
   'table.header.refSourcetitle': 'Źródło polecenia',
   'table.header.refSourceAddDate': 'Dodano datę',
   'btn.new.referral': 'Nowe źródło polecenia',
   'input.nameRefSource.label': 'Nazwa źródła polecenia',
   'input.nameRefSource.placeholder': 'np. reklama na Facebooku',
   'modal.newRefSource.title': 'Nowe źródło polecenia',
   'modal.editRefSource.title': 'Edytuj źródło polecenia',
   'input.sendBy.label': 'Wysłane przez',
   'input.emailSubject.label': 'Temat wiadomości e-mail',
   'input.emailTemplate.label': 'Szablon wiadomości e-mail',
   'input.emailTemplate.placeholder': 'Wprowadź szablon wiadomości e-mail',
   'input.subject.label': 'Temat wiadomości e-mail',
   'input.subject.placeholder': 'Wprowadź temat wiadomości e-mail',
   'title.messagingSettings.label': 'Ustawienia wiadomości',
   'title.messagingSettings.description':
      'Skonfigurowane tutaj wiadomości będą automatycznie wysyłane do klientów. Wszystkie wygenerowane wiadomości są łatwo dostępne w zakładce wiadomości (w menu głównym). Dostosuj ustawienia dotyczące sposobu i czasu wysyłania wiadomości oraz edytuj szablony, aby spersonalizować tekst. Użyj poniższych tagów, aby umieścić dane wizyty w wiadomościach:\n\n',
   'client.first.name': 'CLIENT_FIRST_NAME',
   'client.last.name': 'CLIENT_LAST_NAME',
   'staff.first.name': 'STAFF_FIRST_NAME',
   'staff.last.name': 'STAFF_LAST_NAME',
   'booking.date.time': 'BOOKING_DATE_TIME',
   'booking.date': 'BOOKING_DATE',
   'booking.time': 'BOOKING_TIME',
   'service.name': 'SERVICE_NAME',
   'business.name': 'BUSINESS_NAME',
   'business.phone': 'BUSINESS_PHONE',
   'business.address': 'BUSINESS_ADDRESS',
   'business.email': 'BUSINESS_EMAIL',
   'location.name': 'LOCATION_NAME',
   'location.phone': 'LOCATION_PHONE',
   'location.address': 'LOCATION_ADDRESS',
   'location.email': 'LOCATION_EMAIL',
   'tabs.reminders.title': 'Przypomnienia o wizycie',
   'input.enableReminder.label': 'Włącz przypomnienia',
   'input.enableReminder.description': 'Automatycznie wysyłaj do klientów, aby przypomnieć im o nadchodzących wizytach',
   'input.reminder.title': 'Przypomnienie {0}',
   'input.message.title': 'Wiadomość {0}',
   'input.reminder.description': 'Kanały i czas wysyłania',
   'input.reminder.channels': 'Wybierz kanały, na których ta wiadomość zostanie wysłana',
   'input.reminderBefore.label': 'Przypomnienia zostaną wysłane przed',
   'input.smsSender.label': 'Identyfikator nadawcy SMS',
   'input.messageSender.label': 'Identyfikator nadawcy wiadomości',
   'input.smsSender.placeholder': 'Wprowadź identyfikator nadawcy SMS',
   'input.smsTemplate.label': 'Szablon SMS',
   'input.messageTemplate.label': 'Szablon wiadomości',
   'input.smsTemplate.placeholder': 'Wprowadź szablon SMS',
   'input.whatsAppSender.placeholder': 'Plandok',
   'reminder.whatsApp.notificate':
      'Szablon wiadomości WhatsApp jest obecnie nieedytowalny ze względu na ograniczenia. Aktywnie pracujemy jednak nad tym, aby był w pełni edytowalny.',
   'reminder.charactersCount': 'Liczba znaków:',
   'reminder.pricing': 'Cennik',
   'modal.pricing.description':
      'Różne kraje mają różne stawki cenowe. Zapewniamy najlepszą możliwą stawkę od naszych partnerów. Może się ona różnić.',
   'modal.pricing.subDescription':
      'Opłaty zostaną zastosowane po wysłaniu wiadomości i dodane do nadchodzącej faktury.',
   'modal.pricing.counterDescription':
      'Standardowa wiadomość tekstowa SMS jest ograniczona do 160 znaków i 70 znaków dla wiadomości ze znakami specjalnymi.',
   'modal.pricing.learnMore': 'Dowiedz się więcej',
   'modal.pricing.priceRates': 'Stawki cenowe',
   'modal.pricing.messagePrice': 'Cena za 1 wiadomość:',
   'modal.sendTestMessageByNumber.title': 'Wyślij wiadomość testową na ten numer:',
   'modal.sendTestMessageByNumber.description': 'Opłaty obowiązują jak za zwykłą wiadomość.',
   'modal.sendTestMessageByEmail.title': 'Wyślij wiadomość testową na ten e-mail:',
   'modal.sendTestMessageByEmail.placeholder': 'Proszę podać adres e-mail',
   'btn.send': 'Wyślij',
   'reminder.sendTestMessage': 'Wyślij testową wiadomość',
   'button.reminder.addTag': 'Dodaj tag',
   'button.reminder.addAnotherReminder': 'DODAJ KOLEJNE PRZYPOMNIENIE',
   'button.message.addAnotherMessage': 'DODAJ KOLEJNĄ WIADOMOŚĆ',
   'button.reminder.upgrade': 'Uaktualnij, aby uzyskać dostęp',
   'label.emailSettings': 'Ustawienia Email',
   'label.SMSSettings': 'Ustawienia SMS',
   'label.whatsAppSettings': 'Ustawienia WhatsApp',
   'tooltip.sender.0': 'Nazwa nadawcy wiadomości SMS',
   'tooltip.sender.1': 'Maksymalnie 11 znaków',
   'tooltip.sender.2': 'Może nie działać w niektórych krajach',
   'tooltip.sender.3': 'Wyłącznie litery alfabetu łacińskiego i cyfry',
   'tabs.confirmations.title': 'Potwierdzenie wizyty',
   'btn.appBookings': 'Rezerwacje w aplikacji',
   'btn.onlineBookings': 'Rezerwacje online',
   'input.enableAppConfirmation.label': 'Włącz potwierdzenie rezerwacji w aplikacji',
   'input.enableOnlineConfirmation.label': 'Włącz potwierdzenia rezerwacji online',
   'input.enableConfirmationApp.description':
      'Automatycznie wysyłane do klientów, gdy zarezerwowano dla nich spotkanie',
   'input.enableConfirmationOnline.description': 'Ustawienia wiadomości',
   'messageSetup.header': 'Kanały',
   'channels.subHeader': 'Przełożenia',
   'tabs.reschedules.title': 'Przełożenie wizyty',
   'input.enableReschedule.label': 'Włącz powiadomienia o przełożeniu wizyty',
   'input.enableReschedule.description':
      'Automatycznie wysyłaj do klientów, kiedy czas rozpoczęcia ich wizyty zostanie zmieniony',
   'tabs.cancellations.title': 'Odwołanie wizyty',
   'input.enableCancellation.label': 'Włącz powiadomienia o odwołaniu wizyty',
   'input.enableCancellations.description': 'Automatycznie wysyłaj do klientów, jeśli ich wizyta zostanie odwołana',
   'tabs.thanks.title': 'Podziękowanie',
   'input.enableThank.label': 'Włącz wiadomości z podziękowaniem',
   'input.enableThankyou.description': 'Wiadomość zostanie wysłana do klientów po zakończeniu ich wizyty',
   'tabs.lastVisit.title': 'Ostatnia wizyta',
   'input.enableLastVisit.label': 'Włącz powiadomienia o ostatniej wizycie',
   'input.enableLastVisit.description':
      'Automatycznie wysyłaj do klientów po upływie określonej liczby dni od ich ostatniej wizyty',
   'table.cancelReason.name': 'Powody odwołania',
   'table.cancelReason.addDate': 'Dodano datę',
   'btn.new.cancelReason': 'Nowy powód odwołania',
   'modal.newCancelReason.title': 'Nowy powód odwołania',
   'modal.editCancelReason.title': 'Edytuj powód odwołania',
   'input.cancelReason.title': 'Nazwa powodu odwołania',
   'input.cancelReason.placeholder': 'np. Klient nie jest dostępny',
   'table.discounts.name': 'Zniżki',
   'table.discounts.value': 'Wartość',
   'table.discounts.addDate': 'Data utworzenia',
   'input.discountName.label': 'Nazwa zniżki',
   'input.discountName.placeholder': 'np. Zniżka dla seniorów',
   'input.discountValue.label': 'Wartość zniżki',
   'btn.new.discount': 'Nowa zniżka',
   'input.discountPercentage.label': '% procent',
   'input.discountSum.label': 'Kwota',
   'modal.newDiscount.title': 'Nowy rodzaj zniżki',
   'modal.editDiscount.title': 'Edytuj zniżkę',
   'modal.deleteAccount.title': 'Usuń konto',
   'modal.deleteAccount.description':
      'Usunięcie konta jest procesem nieodwracalnym i wszystkie Twoje dane zostaną usunięte na zawsze.',
   'modal.deleteAccount.successMessage': 'Twoje konto zostało pomyślnie usunięte.',
   'title.mySettings': 'Moje ustawienia',
   'title.personalDetails': 'Dane osobowe',
   'title.personalDetails.description':
      'Wprowadź swoje imię i dane kontaktowe. Podany tu adres e-mail będzie potrzebny do zalogowania się. ',
   'btn.continueWithGoogle.title': 'Kontynuuj z Google',
   'btn.continueWithFacebook.title': 'Kontynuuj z Facebookiem',
   'notification.googleAccountUnlinked': 'Konto Google niepołączone',
   'notification.googleAccountLinked': 'Konto Google połączone',
   'notification.facebookAccountUnlinked': 'Konto na Facebooku niepołączone',
   'notification.facebookAccountLinked': 'Połączone konto Facebook',
   'btn.linkAccount': 'Połącz konto',
   'btn.unlinkAccount': 'Odłącz konto',
   'btn.deleteYourAccount': 'Usuń swoje konto',
   'input.myName.label': 'Imię',
   'input.myName.placeholder': 'np. Jan',
   'input.mylastName.label': 'Nazwisko',
   'input.mylastName.placeholder': 'np. Kowalski',
   'title.changePassword': 'Zmień hasło',
   'title.changePassword.description':
      'Aby dokonać zmian, wprowadź swoje stare hasło, a następnie wpisz nowe. Jeśli nie pamiętasz swojego obecnego hasła, wyloguj się i zresetuj hasło (link dostępny na stronie logowania).\n',
   'input.currentPassword.label': 'Obecne hasło',
   'label.currentPassword.placeholder': 'Wprowadź obecne hasło',
   'input.newPassword.label': 'Nowe hasło',
   'input.newPassword.placeholder': 'Wprowadź nowe hasło',
   'input.verifyPassword.label': 'Powtórz hasło',
   'input.verifyPassword.placeholder': 'Powtórz nowe hasło',
   'btn.confirmDelete': 'Tak, usuń teraz',
   'btn.confirm': 'Potwierdź',
   'btn.confirmBook': 'Potwierdzenie i rezerwacja',
   'btn.confirmPay': 'Potwierdź i zapłać',
   'btn.delete': 'Usuń',
   'btn.close': 'Zamknij',
   'validation.required': 'Pole wymagane',
   'validation.email.format': 'Niepoprawny format adresu e-mail',
   'validation.url.format': 'Nieprawidłowy format adresu url, musi zaczynać się od https:// lub http://',
   'validation.password.length': 'Hasło musi składać się z przynajmniej 7 znaków',
   'validation.password.letters': 'Hasło powinno zawierać litery i cyfry',
   'validation.startTime': 'Czas rozpoczęcia nie może być późniejszy lub taki sam jak czas zakończenia',
   'validation.endTime': 'Czas zakończenia nie może być wcześniejszy lub taki sam jak czas rozpoczęcia',
   'tooltip.password.title': 'Hasło musi:',
   'tooltip.password.characters': 'Składać się z przynajmniej 7 znaków',
   'tooltip.password.number': 'Zawierać cyfrę',
   'tooltip.password.letter': 'Zawierać literę',
   'tooltip.location': 'Lokalizacja - podpowiedź',
   'footer.support.center': 'Potrzebujesz pomocy? Skontaktuj się z nami',
   'not.found': 'Nie znaleziono',
   'input.cancelReason.label': 'Powód odwołania',
   'input.location.label': 'Lokalizacja',
   'input.location.placeholder': 'Nazwa lokalizacji',
   'input.name.label': 'Nazwa',
   'title.recentlyAddedClients': 'Klienci (niedawno dodani)',
   'title.calendarView': 'Widok kalendarza',
   'text.manageProfile': 'Zarządzaj swoim profilem użytkownika',
   'modal.language.title': 'Wybierz język',
   'modal.language.placeholder': 'Wyszukaj język',
   'dropdown.access.medium': 'Średni ',
   'dropdown.access.high': 'Wysoki',
   'dropdown.access.low': 'Niski',
   'dropdown.access.basic': 'Podstawowy',
   'dropdown.access.no': 'Brak dostępu',
   'dropdown.access.owner': 'Właściciel',
   'button.select.all': 'Wybierz wszystkie',
   'button.selected': 'Wybrano',
   'dropdown.gender.unknown': 'Nieznany',
   'dropdown.gender.male': 'Mężczyzna',
   'dropdown.gender.female': 'Kobieta',
   'dropdown.notificationBy.none': 'Nie wysyłaj',
   'dropdown.notificationBy.sms': 'SMS',
   'dropdown.notificationBy.email': 'E-mail',
   'dropdown.notificationBy.combined': 'SMS i e-mail',
   'notification.success.login': 'Logowanie powiodło się!',
   'notification.success.register': 'Rejestracja powiodła się!',
   'dropdown.time.minutes': 'minuty',
   'dropdown.repeat.no': 'Nie powtarzaj',
   'dropdown.repeat.weekly': 'Co tydzień',
   'dropdown.endRepeat.ongoing': 'W trakcie',
   'dropdown.endRepeat.weekly': 'Określona data',
   'dropdown.time.appointment.immediately': 'Natychmiast',
   'dropdown.time.appointment.upTo1Hour': 'Do 1 godziny',
   'dropdown.time.appointment.upTo2Hours': 'Do 2 godzin',
   'dropdown.time.appointment.upTo3Hours': 'Do 3 godzin',
   'dropdown.time.appointment.upTo4Hours': 'Do 4 godzin',
   'dropdown.time.appointment.upTo5Hours': 'Do 5 godzin',
   'dropdown.time.appointment.upTo6Hours': 'Do 6 godzin',
   'dropdown.time.appointment.upTo7Hours': 'Do 7 godzin',
   'dropdown.time.appointment.upTo8Hours': 'Do 8 godzin',
   'dropdown.time.appointment.upTo9Hours': 'Do 9 godzin',
   'dropdown.time.appointment.upTo10Hours': 'Do 10 godzin',
   'dropdown.time.appointment.upTo11Hours': 'Do 11 godzin',
   'dropdown.time.appointment.upTo12Hours': 'Do 12 godzin',
   'dropdown.time.appointment.upTo13Hours': 'Do 13 godzin',
   'dropdown.time.appointment.upTo14Hours': 'Do 14 godzin',
   'dropdown.time.appointment.upTo15Hours': 'Do 15 godzin',
   'dropdown.time.appointment.upTo16Hours': 'Do 16 godzin',
   'dropdown.time.appointment.upTo17Hours': 'Do 17 godzin',
   'dropdown.time.appointment.upTo18Hours': 'Do 18 godzin',
   'dropdown.time.appointment.upTo19Hours': 'Do 19 godzin',
   'dropdown.time.appointment.upTo20Hours': 'Do 20 godzin',
   'dropdown.time.appointment.upTo21Hours': 'Do 21 godzin',
   'dropdown.time.appointment.upTo22Hours': 'Do 22 godzin',
   'dropdown.time.appointment.upTo23Hours': 'Do 23 godzin',
   'dropdown.time.appointment.upTo24Hours': 'Do 1 dnia',
   'dropdown.time.appointment.upTo48Hours': 'Do 2 dni',
   'dropdown.time.appointment.upTo3days': 'Do 3 dni',
   'dropdown.time.appointment.upTo4days': 'Do 4 dni',
   'dropdown.time.appointment.upTo5days': 'Do 5 dni',
   'dropdown.time.appointment.upTo6days': 'Do 6 dni',
   'dropdown.time.appointment.upTo1week': 'Do 1 tygodnia',
   'dropdown.time.appointment.upTo2weeks': 'Do 2 tygodni',
   'dropdown.time.appointment.upTo15Min': 'Do 15 minut',
   'dropdown.time.appointment.upTo30Min': 'Do 30 minut',
   'dropdown.time.appointment.upTo1Month': 'Do 1 miesiąca',
   'dropdown.time.appointment.upTo2Months': 'Do 2 miesięcy',
   'dropdown.time.appointment.upTo3Months': 'Do 3 miesięcy',
   'dropdown.time.appointment.upTo4Months': 'Do 4 miesięcy',
   'dropdown.time.appointment.upTo5Months': 'Do 5 miesięcy',
   'dropdown.time.appointment.upTo6Months': 'Do 6 miesięcy',
   'dropdown.time.appointment.interval5Min': '5 minut',
   'dropdown.time.appointment.interval10Min': '10 minut',
   'dropdown.time.appointment.interval15Min': '15 minut',
   'dropdown.time.appointment.interval20Min': '20 minut',
   'dropdown.time.appointment.interval30Min': '30 minut',
   'dropdown.time.appointment.interval40Min': '40 minut',
   'dropdown.time.appointment.interval45Min': '45 minut',
   'dropdown.time.appointment.interval50Min': '50 minut',
   'dropdown.time.appointment.interval60Min': '60 minut',
   'dropdown.time.appointment.interval75Min': '75 minut',
   'dropdown.time.appointment.interval90Min': '90 minut',
   'dropdown.time.appointment.interval120Min': '120 minut',
   'dropdown.time.appointment.interval150Min': '150 minut',
   'dropdown.time.appointment.interval180Min': '180 minut',
   'dropdown.time.appointment.interval240Min': '240 minut',
   'dropdown.time.appointment.anyTime': 'W dowolnym momencie',
   'dropdown.time.appointment.disabled': 'Wyłączony',
   'dropdown.time.appointment.doesntRepeat': 'Nie powtarza się',
   'dropdown.time.appointment.daily': 'Codziennie',
   'dropdown.time.appointment.every2Days': 'Co 2 dni',
   'dropdown.time.appointment.every3Days': 'Co 3 dni',
   'dropdown.time.appointment.every4Days': 'Co 4 dni',
   'dropdown.time.appointment.every5Days': 'Co 5 dni',
   'dropdown.time.appointment.every6Days': 'Co 6 dni',
   'dropdown.time.appointment.every7Days': 'Co 7 dni',
   'dropdown.time.appointment.weekly': 'Tygodnik',
   'dropdown.time.appointment.every2weeks': 'Co 2 tygodnie',
   'dropdown.time.appointment.every3weeks': 'Co 3 tygodnie',
   'dropdown.time.appointment.every4weeks': 'Co 4 tygodnie',
   'dropdown.time.appointment.every5weeks': 'Co 5 tygodni',
   'dropdown.time.appointment.every6weeks': 'Co 6 tygodni',
   'dropdown.time.appointment.every7weeks': 'Co 7 tygodni',
   'dropdown.time.appointment.every8weeks': 'Co 8 tygodni',
   'dropdown.time.appointment.every9weeks': 'Co 9 tygodni',
   'dropdown.time.appointment.every10weeks': 'Co 10 tygodni',
   'dropdown.time.appointment.monthly': 'Miesięczny',
   'dropdown.time.appointment.every2months': 'Co 2 miesiące',
   'dropdown.time.appointment.every3months': 'Co 3 miesiące',
   'dropdown.time.appointment.every4months': 'Co 4 miesiące',
   'dropdown.time.appointment.every5months': 'Co 5 miesięcy',
   'dropdown.time.appointment.every6months': 'Co 6 miesięcy',
   'dropdown.time.appointment.every7months': 'Co 7 miesięcy',
   'dropdown.time.appointment.every8months': 'Co 8 miesięcy',
   'dropdown.time.appointment.every9months': 'Co 9 miesięcy',
   'dropdown.time.appointment.every10months': 'Co 10 miesięcy',
   'dropdown.time.appointment.every11months': 'Co 11 miesięcy',
   'dropdown.time.appointment.after2times': 'Po 2 razach',
   'dropdown.time.appointment.after3times': 'Po 3 razach',
   'dropdown.time.appointment.after4times': 'Po 4 razach',
   'dropdown.time.appointment.after5times': 'Po 5 razach',
   'dropdown.time.appointment.after6times': 'Po 6 razach',
   'dropdown.time.appointment.after7times': 'Po 7 razach',
   'dropdown.time.appointment.after8times': 'Po 8 razach',
   'dropdown.time.appointment.after9times': 'Po 9 razach',
   'dropdown.time.appointment.after10times': 'Po 10 razach',
   'dropdown.time.appointment.after11times': 'Po 11 razach',
   'dropdown.time.appointment.after12times': 'Po 12 razach',
   'dropdown.time.appointment.after13times': 'Po 13 razach',
   'dropdown.time.appointment.after14times': 'Po 14 razach',
   'dropdown.time.appointment.after15times': 'Po 15 razach',
   'dropdown.time.appointment.after20times': 'Po 20 razach',
   'dropdown.time.appointment.after25times': 'Po 25 razach',
   'dropdown.time.appointment.after30times': 'Po 30 razach',
   'dropdown.time.appointment.specificDate': 'Konkretna data',
   'modal.createHours.title': 'Utwórz {0} godziny',
   'modal.editHours.title': 'Edytuj {0} godziny',
   'modal.deleteItem.confirm.0': 'Czy na pewno chcesz trwale',
   'modal.deleteItem.confirm.1': 'usunąć',
   'modal.deleteItem.confirm.2': 'z bazy danych?',
   'modal.entity.category': 'Kategoria',
   'modal.entity.referal': 'Źródło polecenia\n',
   'modal.entity.resource': 'Zasób',
   'modal.entity.location': 'Lokalizacja',
   'modal.deleteReminder.title': 'Usuń przypomnienie',
   'modal.deleteMessage.title': 'Usuń wiadomość',
   'modal.deleteReminder.description': 'Czy na pewno chcesz usunąć to przypomnienie ?',
   'modal.deleteMessage.description': 'Czy na pewno chcesz usunąć tę Wiadomość ?\n',
   'modal.deleteReminder.confirm': 'Tak, usuń',
   'modal.deletePaymentMethod.title': 'Usuń metodę płatności',
   'modal.deletePaymentMethod.description': 'Ta metoda płatności zostanie trwale usunięta.',
   'closedDate.create.modal.title': 'Dodaj datę zamknięcia',
   'closedDate.edit.modal.title': 'Edytuj datę zamknięcia',
   'staff.access.owner': 'Właściciel',
   'staff.access.high': 'Wysoki',
   'staff.access.medium': 'Średni',
   'staff.access.low': 'Niski',
   'staff.access.basic': 'Podstawowy',
   'staff.access.no_access': 'Brak dostępu',
   'staff.create.title': 'Dodaj datę zamknięcia',
   'staff.emptyState.title': 'Brak zaplanowanych pracowników.',
   'staff.emptyState.description': 'Aby dodać godziny pracy, zobacz sekcję dotyczącą pracowników.\n',
   'staff.viewStaffMembers.button': 'Zobacz pracowników',
   'staff.picture.changePhoto': 'Zmień zdjęcie',
   'staff.picture.removePhoto': 'Usuń zdjęcie',
   'input.clientSearch.placeholder': 'Wyszukaj wg nazwy, adresu e-mail lub numeru telefonu',
   'input.blockedDate.label': 'Data',
   'appointment.notes.title': 'Notatki o wizycie',
   'appointment.history.title': 'Historia spotkań',
   'appointment.history.by.title': ' przez {0}',
   'appointment.log.created.label': 'Stworzony przez',
   'appointment.log.updated.label': 'Zaktualizowany przez',
   'appointment.log.rescheduled.label': 'Przełożony na',
   'appointment.log.cancelled.label': 'Anulowane przez',
   'appointment.log.changed.label': 'Zmieniono status przez',
   'appointment.notificationLog.rescheduling': 'Powiadomienie o przełożeniu zostało wysłane',
   'appointment.notificationLog.cancellation': 'Powiadomienie o anulowaniu zostało wysłane',
   'appointment.notificationLog.confirmation': 'Powiadomienie z potwierdzeniem zostało wysłane',
   'appointment.notificationLog.reminder': 'Powiadomienie o przypomnieniu zostało wysłane',
   'appointment.notificationLog.thankYou': 'Dziękujemy. Powiadomienie wysłane',
   'appointment.notificationLog.lastVisit': 'Wysłano powiadomienie o ostatniej wizycie',
   'state.cancelReason.title': 'Ustaw powody odwołania',
   'state.cancelReason.description':
      'Śledząc powody odwołania dowiesz się, dlaczego klienci nie pojawiają się na umówionych wizytach.  ',
   'state.cancelReason.button': 'Dodaj powód odwołania',
   'state.resources.title': 'Zarządzaj zasobami',
   'state.resources.description':
      'Zwiększ swoje szanse na sukces, sprawnie zarządzając zasobami. Kontroluj dostępność gabinetów, mebli i niezbędnych narzędzi, aby upewnić się, że umówione wizyty przebiegną bez przeszkód. ',
   'state.resources.button': 'Dodaj zasób',
   'state.referralSource.title': 'Zarządzaj źródłami poleceń',
   'state.referralSource.description':
      'Źródła poleceń mówią Ci, skąd klient dowiedział się o Twojej działalności. Śledź je i wykorzystuj zdobyte informacje, aby zwiększyć widoczność swojej firmy i współczynnik konwersji.',
   'state.referralSource.button': 'Dodaj źródło polecenia',
   'state.locations.title': 'Ustaw swoje lokalizacje',
   'state.locations.description':
      'Tutaj możesz zarządzać wszystkimi oddziałami swojej firmy, ustalać dla nich odpowiednie godziny pracy i tworzyć oddzielne kalendarze.',
   'state.staff.title': 'Zarządzaj swoim zespołem pracowników',
   'state.staff.description':
      'Stwórz profile swoich pracowników, aby dodawać i edytować ich harmonogramy oraz zarządzać ich wizytami i godzinami pracy.  ',
   'state.messageLog.title': 'Śledź wiadomości',
   'state.messageLog.description':
      'Wiadomości przypominają Twoim klientom o umówionych wizytach i przekazują inne niezbędne informacje.\nTutaj możesz śledzić wysyłane wiadomości i sprawdzać ich status.\nAby skonfigurować automatycznie wysyłane wiadomości, przejdź do',
   'state.messageLog.link': 'Ustawienia powiadomień',
   'state.clients.title': 'Zarządzaj klientami',
   'state.clients.description':
      'Prawdziwa troska o klienta zaczyna się od sprawdzenia, czy jego profil zawiera wszystkie informacje niezbędne do sprawnego wykonania usługi i łatwego kontaktu.',
   'state.clients.button': 'dodaj klienta',
   'state.services.title': 'Ustaw grupy i usługi',
   'state.services.description':
      'Grupy są niezbędne, ponieważ pozwalają Ci uporządkować podobne do siebie usługi. Dzięki nim Twój kalendarz będzie przejrzysty i czytelny!',
   'state.services.button': 'Dodaj grupę usług',
   'state.calendar.title': 'Nie przypisano pracowników',
   'state.calendar.description': 'Aby ustawić godziny pracy Twojego zespołu, zobacz',
   'state.calendar.button': 'Sekcja Pracowników',
   'state.closedDates.title': 'Zamknięte daty',
   'state.closedDates.description':
      'Podaj daty, w których Twoja firma jest zamknięta z powodu świąt państwowych, prac konserwacyjnych lub z innego powodu. W tych dniach Klienci nie będą mogli dokonywać rezerwacji on-line.',
   'state.closedDates.button': 'Dodaj datę zamknięcia',
   'title.billingPlan': 'Plan i płatności',
   'btn.upgrade': 'Ulepsz',
   'header.usage': 'UŻYTKOWANIE',
   'header.dropdown.subscription.plan': '{0} Plan',
   'header.dropdown.subscription.staff': '({0} Członkowie personelu)',
   'header.dropdown.notifications.description': '{0} wiadomości',
   'header.paymentDetails': 'Zobacz szczegóły płatności',
   'title.billingPlan.description':
      'Zarządzaj swoimi kontami. Plan i szczegóły płatności, sprawdzaj transakcje i pobieraj faktury.',
   'plan.free.title': 'Starter',
   'plan.individual.title': 'Indiwidualny (1 pracownik)',
   'plan.business.title': 'Biznesowy',
   'btn.upgradeNow': 'Ulepsz',
   'btn.getStarted': 'Rozpocznij',
   'btn.currentPlan': 'Obecny Plan',
   'plan.free.option.0': '1 Lokalizacja',
   'plan.free.option.1': 'Do 5 pracowników',
   'plan.free.option.2': 'Nieograniczone spotkania',
   'plan.free.option.3': 'Nieograniczona liczba klientów',
   'plan.free.option.4': 'Nieograniczone rezerwacje online',
   'plan.free.option.5': 'Powiadomienia e-mailowe',
   'plan.premium.option.0': 'Nieograniczone lokalizacje',
   'plan.premium.option.1': 'Nieograniczone spotkania',
   'plan.premium.option.2': 'Nieograniczona liczba klientów',
   'plan.premium.option.3': 'Nieograniczone rezerwacje online',
   'plan.premium.option.4': 'Powtarzające się spotkania',
   'plan.premium.option.5': 'Powiadomienia e-mailowe',
   'plan.premium.option.6': 'Powiadomienia SMS*',
   'plan.premium.option.7': 'Powiadomienia WhatsApp*',
   'plan.premium.option.8': 'Nieograniczona Analityka',
   'plan.premium.option.9': 'Nieograniczone Raporty',
   'plan.premium.option.10': 'Drukuj harmonogramy',
   'plan.premium.option.11': 'Eksport klienta',
   'plan.premium.option.12': 'Wsparcie priorytetowe',
   'plan.premium.option.13': 'Usunięte markę plandok z e -maili (darmowe e -maile zasilane przez Pandok)',
   'plan.premium.costsInfo': '*Koszty SMS/WhatsApp nie są uwzględnione w planie, obowiązują dodatkowe opłaty.',
   'plan.premium.activeUntilDate': 'Aktywny do {0}',
   'period.perMonth': 'miesięcznie (bez VATu)',
   'plan.premium.description': 'Cena wzrośnie po dodaniu kolejnych pracowników.',
   'plan.premium.viewPricing': 'Zobacz wycenę',
   'plan.premium.numberStuff': 'Twoja obecna liczba pracowników: {0}',
   'premium.membership': 'Cena członkostwa Premium może się różnić w zależności od liczby posiadanych pracowników.',
   'premium.price.perMonth': 'Cena premium za miesiąc',
   'pricing.calculated': 'Cena jest obliczana w następujący sposób:',
   'pricing.calculator': 'Kalkulator cen',
   'pricing.numberStuff': 'Liczba pracowników',
   'pricing.extra.member': '{0} za każdego dodatkowego członka',
   'modal.title.pricing': 'Сennik',
   'plan.foreverFree': 'Na zawsze za darmo',
   'plan.individual.0': 'Plan Starter oraz dodatkowo:',
   'plan.individual.1': 'Spresonalizowane powiadomienia',
   'plan.individual.2': 'Brak logo Plandok',
   'plan.individual.option.3': 'Nielimitowane powiadomienia SMS',
   'plan.individual.4': 'Pierwszeństwo w Centrum Pomocy',
   'plan.business.0': 'Plan Indiwidualny oraz dodatkowo:',
   'plan.business.1': 'Nielimitowana ilość lokalizacji',
   'plan.business.2': 'Nielimitowana ilość pracowników',
   'block.billingInfo.title': 'Dane rozliczeniowe',
   'block.carInfo.title': 'Dane karty płatniczej',
   'input.cardNumber.label': 'Numer karty',
   'input.cardNumber.placeholder': '1234 5678 3456 2456',
   'input.expireDate.label': 'Data ważności',
   'input.expireDate.placeholder': '05/21',
   'input.cvv.label': 'CVV/CVC',
   'input.cvv.placeholder': '123',
   'modal.confirmCancel.subscription': 'Czy na pewno chcesz anulować swoją subskrypcję?',
   'modal.confirmCancel.subscription.description': 'Jeśli zdecydujesz się na anulowanie, wrócisz do wersji darmowej.',
   'btn.cancelSubscription.confirm': 'Tak, anuluj moją subskrypcję',
   'loading.processingSubscribtion': 'Przetwarzamy Twoją płatności. Poczekaj...',
   'state.serviceGroup.description': 'Brak usług w tej grupie',
   'state.serviceGroup.btn': 'Dodaj usługę',
   'appointment.status.new': 'Nowa wizyta',
   'appointment.status.confirmed': 'Potwierdzona',
   'appointment.status.notShow': 'Klient nie pojawił się',
   'appointment.status.completed': 'Ukończona',
   'appointment.status.cancelled': 'Anulowany',
   'billing.tab.plan': 'Twój Plan',
   'billing.tab.costs': 'Szacowany koszt',
   'billing.tab.billing': 'Dane rozliczeniowe',
   'billing.tab.invoice': 'Faktury',
   'plan.free': 'Darmowy',
   'plan.deactivated.title': 'Usługa Premium została wyłączona',
   'plan.active.title': 'Aktywować',
   'payment.step.title': 'Płatności',
   'payment.error.title': 'Aktualizacja płatności',
   'payment.payNow.title': 'Płacić',
   'payment.dismiss.title': 'Odrzucać',
   'payment.tab.title.sub': 'Subskrypcja',
   'payment.tab.subTitle': 'Plan premium',
   'payment.per.members': 'na członka',
   'payment.tab.title.notification': 'Powiadomienia',
   'payment.card.details': 'Szczegóły karty',
   'payment.success.message': 'Płatność została zrealizowana',
   'payment.payWithCard.title': 'Zapłać kartą',
   'payment.cardNumber.title': 'Numer karty',
   'payment.expirationDate.title': 'Data ważności',
   'payment.digits.title': '(3 cyfry)',
   'payment.amount': 'Wielkość płatności:',
   'payment.btn.pay': 'Płacić',
   'invoice.table.date': 'Data',
   'invoice.table.invoice': 'Faktura',
   'invoice.table.download': 'Pobierz',
   'invoice.table.status': 'Status',
   'costs.billingPeriod.title': 'Szacunkowe koszty w tym okresie rozliczeniowym',
   'costs.billingPeriod.description':
      'To są bieżące koszty użytkowania w tym okresie rozliczeniowym. Podział kosztów jest dostępny poniżej.',
   'costs.billingPeriod.additionalInfo':
      'Gdy wydatki na powiadomienia osiągną 50 €, zostaną naliczone automatycznie. Ale nie martw się, jeśli kwota jest mniejsza; zostanie ona wygodnie uwzględniona w miesięcznej subskrypcji.',
   'costs.summary.title': 'Podsumowanie miesiąca do dnia dzisiejszego',
   'costs.summary.description': 'Opłaty te są uwzględniane w saldzie Twojego konta',
   'costs.summary.detailedCharges': 'Szczegółowe opłaty',
   'costs.summary.noData': 'Brak danych',
   'costs.summary.quantity': 'Ilość',
   'costs.summary.subtotal': 'Suma częściowa',
   'costs.summary.taxes': 'Podatki',
   'dropdown.time.hours': 'godziny',
   'message.planUpgrade': 'Twój plan został ulepszony!',
   'costs.subscription': 'Subskrypcja',
   'btn.cancelSubscription': 'Anuluj subskrypcję',
   'btn.no': 'Nie',
   'notification.success.update': 'Aktualizacja powiodła się',
   'validation.password.match': 'Hasła nie pasują do siebie',
   'blocked.time': 'Zablokowany przedział czasowy',
   'message.successReset.password':
      'Gotowe! Wiadomość e-mail z instrukcją zresetowania hasła została wysłana. Sprawdź swoją skrzynkę odbiorczą.',
   'title.newPassword': 'Wprowadź nowe hasło',
   'title.newPassword.description': 'Zabezpiecz swoje konto tworząc nowe hasło logowania',
   'input.newPasswordRepeat.label': 'Potwierdź nowe hasło',
   'btn.change.password': 'Zmień moje hasło',
   'text.havingTrouble': 'Coś idzie nie tak?',
   'link.resetPassword.again': 'Ponownie zresetuj hasło',
   'message.successNew.password': 'Zmiana hasła powiodła się.',
   'title.repeatShifts': 'Powtarzająca się zmiana',
   'text.confirmShift.update':
      'Edytowałeś zmianę, która powtarza się co tydzień. Usunięcie nadchodzących zmian spowoduje zastąpienie {0} obecnego {0} harmonogramu.',
   'text.confirmShift.delete':
      'Edytowałeś zmianę, która powtarza się co tydzień. Usunięcie nadchodzących zmian spowoduje zastąpienie {0} obecnego {0} harmonogramu.',
   'btn.deleteUpcoming.shifts': 'Usuń nadchodzące zmiany',
   'btn.deleteThis.shift': 'Usuń tylko tę zmianę',
   'btn.updateUpcoming.shifts': 'Zaktualizuj nadchodzące zmiany',
   'btn.updateThis.shift': 'Zaktualizuj tylko tę zmianę',
   'text.permisionTab':
      'Zdecyduj, które sekcje będą dostępne dla poszczególnych użytkowników w zależności od ich poziomu uprawnień. Wszyscy zalogowani użytkownicy mają dostęp do kalendarza, konto właściciela natomiast ma dostęp do wszystkich sekcji.',
   'title.permission.locations': 'Lokalizacje',
   'title.permission.booking': 'Rezerwacje i klienci',
   'title.permission.staff': 'Zespół pracowników',
   'title.permission.setup': 'Konfiguracja',
   'title.permission.basic': 'Podstawowy',
   'title.permission.low': 'Niski',
   'title.permission.med': 'Średni',
   'title.permission.high': 'Wysoki',
   'title.permission.owner': 'Właściciel',
   'title.allLocations.permission': 'Dostęp do wszystkich lokalizacji',
   'title.accessCalendar.permission': 'Dostęp do własnego kalendarza',
   'title.accessOtherCalendar.permission': 'Dostęp do kalendarzy innych pracowników',
   'title.canBook.permission': 'Zezwalaj na umawianie wizyt',
   'title.contactInfo.permission': 'Zezwalaj na dostęp do danych kontaktowych klienta',
   'title.clients.permission': 'Klienci',
   'title.messages.permission': 'Wiadomości',
   'title.services.permission': 'Usługi',
   'title.workingHours.permission': 'Godziny pracy',
   'title.staffMembers.permission': 'Pracownicy',
   'title.permissionLevels.permission': 'Poziomy uprawnień',
   'title.accountSetup.permission': 'Ustawienia konta',
   'title.clientSettings.permission': 'Ustawienia klienta',
   'title.onlineBookings.permission': 'Rezerwacje internetowe',
   'title.analytics.permission': 'Analityka',
   'title.marketing.permission': 'Marketing',
   'notification.type.reminder': 'Powiadomienie',
   'notification.type.confirmation': 'Potwierdzenie',
   'notification.type.online_booking_confirmation': 'Potwierdzenie rezerwacji online',
   'notification.type.rescheduling': 'Przełożenie wizyty',
   'notification.type.cancellation': 'Odwołanie',
   'notification.type.thank_you': 'Podziękowanie',
   'notification.type.last_visit': 'Ostatnia wizyta',
   'notification.channel.sms': 'SMS',
   'notification.channel.email': 'Email',
   'notification.channel.whatsapp': 'WhatsApp',
   'notification.status.sent': 'Wysłane',
   'notification.status.pending': 'W oczekiwaniu',
   'notification.status.failed': 'Niepowodzenie',
   'dropdown.time.days': 'dni',
   'title.appointment': 'wizyta',
   'input.reminderBeforeLastVisit.label': 'Powiadomienia zostaną wysłane po upływnie wskazanej liczby dni',
   'txt.with': 'z',
   'short.hours': 'godź.',
   'short.minutes': 'min.',
   'input.registrationNumber.label': 'Numer firmy',
   'input.vatNumber.label': 'Numer Vat',
   'invoice.table.priceTaxIncl': 'Kwota',
   'error.wrongSenderId': 'Identyfikator nadawcy powinien składać się z od 3 do 11 liter lub cyfr.',
   'sidebar.help': 'Pomoc',
   'modal.help.title': 'Jak możemy pomóc?',
   'modal.help.liveChat.title': 'Czat na żywo',
   'modal.help.liveChat.description': 'Masz pytania? Po prostu zapytaj nas od razu',
   'modal.help.suggestFeature.title': 'Zaproponuj funkcję',
   'modal.help.suggestFeature.description':
      'Podziel się sugestiami, zobacz sugestie innych i zagłosuj na funkcje, które chcesz',
   'modal.help.viewUpdates.title': 'Zobacz aktualizacje',
   'modal.help.viewUpdates.description': 'Zobacz nasze najnowsze aktualizacje i ulepszenia',
   'warning.title.paidFeature': 'Funkcja płatna',
   'warning.smsFeatureMissing':
      'Aby uzyskać dostęp do ustawień SMS, musisz ulepszyć swój plan. Aby dokonać zmian, przejdź do',
   'warning.link.billingAndPricing': 'Plan i płatności',
   'warning.newPage.0': 'został ulepszony!',
   'warning.newPage.1': 'Jeśli używałeś poprzedniej wersji Plandok, Twoje hasło nie będzie działać. Prosimy, ',
   'warning.newPage.2': 'zresetuj swoje hasło',
   'warning.newPage.3': 'aby cieszyć się najnowszą wersją. Potrzebujesz więcej informacji? Skontaktuj się się z nami',
   'warning.newPage.4': 'lub',
   'title.blockTime.select': 'Wybierz czas wizyty',
   'calendar.allResources': 'Wszystkie zasoby',
   'appointment.edit.title': 'Edytuj wizytę',
   'appointment.create.title': 'Pomyślnie utworzono spotkanie.',
   'appointment.update.title': 'Pomyślnie zaktualizowano termin.',
   'select.notify.title': '{0} nie działa w wybranych odstępach czasu, ale nadal możesz rezerwować spotkania.',
   'booking.notify.title': 'Wybrany czas jest zarezerwowany, ale możesz umówić się na spotkanie.',
   'textArea.characters.left': 'pozostało znaków',
   'textArea.characters.exceeded': 'limit został przekroczony o ',
   'validation.characters.exceeded': 'Limit znaków został przekroczony ',
   'staff.menu.members': 'Pracownicy',
   'staff.menu.dates': 'dni wolne od pracy',
   'staff.menu.working': 'Godziny pracy pracowników',
   'staff.menu.permissions': 'Uprawnienia użytkownika',
   'staff.menu.members.description': 'Dodawaj i usuwaj członków zespołu i kontroluj ich poziomy dostępu',
   'staff.menu.dates.description': 'Zaplanuj nadchodzące zamknięcia firm lub święta państwowe',
   'staff.menu.working.description': 'Zarządzaj kalendarzem dopasowując harmonogramy pracowników',
   'staff.menu.permissions.description': 'Ustaw dostęp do poufnych funkcji i informacji',
   'message.successUpdateLocation': 'Gotowe! Profil lokalizacji został pomyślnie zaktualizowany.',
   'ob.title': 'Rezerwacja online',
   'ob.overview.tab': 'Przegląd',
   'ob.massMessages.tab': 'Wiadomości masowe',
   'ob.campaigns.tab': 'Kampanie',
   'ob.profile.tab': 'Profil online',
   'ob.links.tab': 'Linki',
   'ob.settings.tab': 'Ustawienia',
   'ob.payments.tab': 'Płatności',
   'ob.status.online': 'Online',
   'ob.status.offline': 'Offline',
   'ob.status.title': 'Status',
   'ob.profile.step': '{0} z 4',
   'ob.profile.step.title': 'Profil i lokalizacja',
   'ob.profile.step.description':
      'Zaprezentuj swoją firmę i usługi, które świadczysz. Sekcja „O mnie” to pierwsze, co zobaczą potencjalnie klienci, upewnij się więc, że podajesz wszystkie niezbędne informacje i numer kontaktowy, pod którym można dowiedzieć się więcej. Wprowadź adres, w którym świadczysz swoje usługi, aby Twoi klienci mogli zostać poinformowani i wyświetlić adres.',
   'ob.overview.placheholder.title': 'Umawianie wizyt nigdy jeszcze nie było takie proste!',
   'ob.overview.placheholder.description':
      'Utwórz swój profil online i pozwól klientom <b> znaleźć Cię </b> za pomocą mediów społecznościowych i na Twojej własnej stronie internetowej.',
   'ob.overview.start.now': 'Rozpocznij teraz',
   'ob.links.direct.title': 'Bezpośredni link',
   'ob.links.facebook.title': 'Link do Facebooka',
   'ob.links.instagram.title': 'Link do Instagrama',
   'ob.links.direct.description':
      'Ułatw sobie życie i skorzystaj z linku pozwalającego klientom na samodzielną rezerwację wizyt.\nMożesz umieścić go na swojej stronie internetowej i gdziekolwiek tylko zechcesz.\nJeśli nie jesteś pewien, jak używać linka, nie martw się - pomożemy!',
   'ob.links.facebook.description':
      'Udostępnij ten link na Facebooku, aby przyciągnąć więcej klientów i pomóc im zarezerwować wizytę właśnie u Ciebie!',
   'ob.links.instagram.description':
      'Ułatw sobie życie i skorzystaj z linku pozwalającego klientom na samodzielną rezerwację wizyt.\nMożesz umieścić go na swojej stronie internetowej i gdziekolwiek tylko zechcesz.\nJeśli nie jesteś pewien, jak używać linka, nie martw się - pomożemy!',
   'ob.analytics.tab.description':
      'Dodaj Google Analytics i Facebook Pixel do swojej strony rezerwacji online, aby analizować ruch i zachowanie użytkowników.',
   'ob.analytics.google.title': 'Google Analytics',
   'ob.analytics.facebook.title': 'Facebook Pixel',
   'ob.analytics.google.description':
      'Dzięki Google Analytics możesz śledzić i analizować ruch w witrynie oraz zachowanie użytkowników, aby uzyskać wgląd w to, jak ulepszyć swoją witrynę.',
   'ob.analytics.active': 'Aktywny',
   'ob.analytics.inactive': 'Nieaktywny',
   'ob.analytics.setUp.btn': 'Skonfigurowany',
   'ob.analytics.google.description.1':
      'Google Analytics to potężne narzędzie, które może pomóc w monitorowaniu ruchu w witrynie i zachowania użytkowników. Dzięki Google Analytics możesz śledzić liczbę odwiedzających Twoją witrynę, skąd pochodzą, jakie strony odwiedzają i jak długo pozostają w Twojej witrynie.',
   'ob.analytics.google.description.2':
      'Dodatkowo Google Analytics pozwala na ustawienie celów i śledzenie konwersacji, co pozwala lepiej zrozumieć, jak dobrze działa Twoja witryna i jak możesz ją ulepszyć.',
   'ob.analytics.google.trackingId': 'Identyfikator śledzenia',
   'ob.analytics.google.tooltip':
      'Aby znaleźć swój identyfikator śledzenia, musisz najpierw zalogować się na swoje konto Google Analytics, wybrać witrynę lub aplikację, którą chcesz śledzić, a następnie przejść do zakładki "Administrator". Następnie należy kliknąć "Kod śledzenia". Twój identyfikator śledzenia zostanie wyświetlony w formacie "UA-XXXX-Y".',
   'ob.analytics.google.account': 'Nie masz jeszcze konta Google Analytics?',
   'ob.analytics.click': 'Kliknij',
   'ob.analytics.here': 'tutaj',
   'ob.analytics.learn': 'aby dowiedzieć się, jak je utworzyć.',
   'ob.analytics.facebook.description.1':
      'Facebook Pixel to narzędzie, które pozwala śledzić zachowanie użytkowników w witrynie, w tym konwersje, odsłony i inne zdarzenia.',
   'ob.analytics.facebook.description.2':
      'Piksel Facebooka może dostarczyć cennych informacji na temat odbiorców, takich jak dane demograficzne, zainteresowania, typ urządzenia, wyświetlenia stron i podjęte działania, co może pomóc w lepszym zrozumieniu i optymalizacji doświadczeń użytkowników w witrynie.',
   'ob.analytics.facebook.pixelId': 'Identyfikator piksela',
   'ob.analytics.facebook.tooltip':
      'Aby znaleźć identyfikator piksela Facebooka, wykonaj następujące kroki: Przejdź do swojego konta Facebook Ads Manager. Kliknij przycisk "Menu" w lewym górnym rogu ekranu. W sekcji "Menedżer zdarzeń" wybierz "Piksele". Tutaj powinieneś zobaczyć listę wszystkich pikseli powiązanych z Twoim kontem. Kliknij nazwę piksela, dla którego potrzebujesz identyfikatora. Identyfikator piksela zostanie wyświetlony w lewym górnym rogu ekranu, obok nazwy piksela.',
   'ob.analytics.facebook.account': 'Nie masz jeszcze strony biznesowej na Facebooku?',
   'ob.analytics.google.account.mobile.1': 'Nie masz jeszcze konta Google Analytics',
   'ob.analytics.google.account.mobile.2': 'konta? Kliknij',
   'ob.analytics.google.account.mobile.3': 'aby dowiedzieć się, jak',
   'ob.analytics.google.account.mobile.4': 'je utworzyć.',
   'ob.analytics.facebook.account.mobile.1': 'Nie masz jeszcze strony biznesowej na Facebooku',
   'ob.analytics.facebook.account.mobile.2': 'strony biznesowej? Kliknij',
   'ob.analytics.facebook.account.mobile.3': 'aby dowiedzieć się, jak',
   'ob.analytics.facebook.account.mobile.4': 'je utworzyć.',
   'ob.analytics.successfullySaved.message': 'Pomyślnie zapisane',
   'ob.settings.booking.title': 'Rezerwacja online - dostępność',
   'ob.settings.booking.description':
      'Ustaw najwcześniejsze i najpóźniejsze godziny, w których można rezerwować wizytę. Zarządzaj rezerwacjami, przekazuj klientom wszystkie niezbędne informacje i przypisuj swoich pracowników do konkretnych zadań.',
   'ob.settings.cancellation.title': 'Odwoływanie i przekładanie wizyt',
   'ob.settings.cancellation.description':
      'Ustal ramy czasowe, w których klienci będą mogli odwoływać i przekładać swoje wizyty.',
   'ob.settings.locationsUrl.title': 'Adres URL lokalizacji',
   'ob.settings.locationsUrl.description':
      'Skonfiguruj i udostępnij pojedynczy adres URL dla wszystkich lokalizacji, w których można dokonać rezerwacji online. Jest to przydatne tylko dla firm mających wiele lokalizacji.',
   'ob.settings.businessName.explanation': 'Twój adres URL: book.plandok.com/nazwa-firmy/Lokalizacja2556',
   'ob.settings.notifications.title': 'Powiadomienia e-mail',
   'ob.settings.notifications.description':
      'Wybierz odbiorców powiadomień e-mail o nowych wizytach lub wprowadzonych zmianach.',
   'ob.settings.required.title': 'Pola wymagane i opcjonalne',
   'ob.settings.required.description':
      'Wybierz, które pola klient będzie zobowiązany podać podczas dokonywania rezerwacji online.',
   'ob.settings.requiredField.title': 'Wymagany',
   'ob.settings.optionalField.title': 'Fakultatywny',
   'ob.settings.requiredField.notification.title': 'Wystąpił problem z ustawieniami',
   'ob.settings.requiredField.notification.description':
      'Przepraszamy, ale jedno pole musi być wymagane, z wyjątkiem "notatek".',
   'ob.settings.nameField.title': 'Imię',
   'ob.settings.lastNameField.title': 'Nazwisko',
   'ob.settings.phoneField.title': 'Numer telefonu',
   'ob.settings.emailField.title': 'E-mail',
   'ob.settings.notes.label': 'Tekst zastępczy dla notatek',
   'ob.settings.notes.placeholder': 'Np. Wprowadź swoje notatki',
   'ob.bookingLimit.title': 'Limit rezerwacji online',
   'ob.bookingLimit.description': 'Określ maksymalną liczbę rezerwacji usług, jaką chcesz ustawić.',
   'ob.bookingLimit.label': 'Limitowanie rezerwacji usług',
   'ob.bookingLimit.unlimited.title': 'Bez ograniczeń',
   'ob.bookingLimit.1service.title': 'maks. 1 Usługa',
   'ob.bookingLimit.2services.title': 'maks. 2 Usługi',
   'ob.bookingLimit.3services.title': 'maks. 3 Usługi',
   'ob.menu.edit.profile': 'Edytuj profil',
   'ob.menu.turnOff.profile': 'Wyłącz profil',
   'ob.profile.status': 'Status',
   'ob.overview.tab.description': 'Rozwijaj swoją firmę, umożliwiając klientom rezerwację spotkań online.',
   'ob.profile.tab.description':
      'Zarządzaj profilem online dla swoich lokalizacji. Dostosuj obrazy, opisy, adresy i wiele innych.',
   'ob.links.tab.description': 'Tutaj znajdziesz link do rezerwacji online, który możesz udostępnić swoim klientom.',
   'ob.settings.tab.description':
      'Dostosuj tutaj ustawienia rezerwacji online, tak aby Twoi klienci mogli dokonywać rezerwacji tylko wtedy, gdy jest to dla Ciebie wygodne.',
   'ob.payments.tab.description':
      'Wybierz metody płatności, z których klienci będą mogli korzystać podczas płacenia za usługi.',
   'ob.payments.page.description':
      'Tutaj możesz wybrać metody płatności, z których klienci będą mogli korzystać podczas płacenia za usługi.',
   'ob.payOnSite.title': 'Zapłać na miejscu',
   'ob.payOnSite.description': 'Twoi klienci będą mogli płacić na miejscu gotówką.',
   'ob.payWithCard.title': 'Zapłać kartą',
   'ob.payWithCard.description': 'Twoi klienci będą mogli płacić kartą kredytową za pośrednictwem aplikacji Stripe.',
   'ob.payWithCard.info.1': 'Korzystasz z darmowego planu Plandok, więc będziesz musiał zapłacić',
   'ob.payWithCard.info.2': 'prowizję Plandok i prowizję Stripe przy użyciu tej metody płatności.',
   'ob.payWithCard.info.3': ' mieć',
   'ob.payWithCard.info.4': 'Opłaty prowizyjne Plandok.',
   'ob.payWithCard.info.5': 'Plan Premium nie zwalnia z opłaty prowizji Stripe. ',
   'ob.payWithCard.info.6': 'Sprawdź ceny Stripe tutaj',
   'ob.stripeConnected.info': '(Stripe connected)',
   'ob.requiresSetup.info': '(Wymaga skonfigurowania konta Stripe)',
   'ob.connectToStripe.btn': 'Połącz się ze Stripe',
   'ob.connectToStripe.successMessage.title': 'Udane połączenie z usługą Stripe',
   'ob.waitingAuthorization.title': 'Oczekiwanie na autoryzację w Stripe',
   'ob.stripeDashboard.title': 'Wszystkie dzienniki płatności i zwroty są dostępne w ',
   'ob.stripeDashboard.url': 'Pulpit nawigacyjny Stripe',
   'ob.yourStripeAccount.title': 'Twoje konto Stripe',
   'ob.visitStripe.option': 'Odwiedź Stripe',
   'ob.changeAccount.option': 'Zmień konto Stripe',
   'input.earliestAppointment.label': 'Najwcześniejsza wizyta',
   'input.earliestAppointment.placeholder': 'Na krótko przed wizytą',
   'input.latestAppointment.label': 'Ostatnia wizyta',
   'input.latestAppointment.placeholder': 'Nie wcześniej niż z 6-miesięcznym wyprzedzeniem',
   'input.timeSlots.label': 'Przedziały czasowe',
   'input.timeSlots.placeholder': '15 minut',
   'checkbox.allowCustomers': 'Pozwól klientom dokonywać rezerwacji usług konkretnego pracownika',
   'input.importantInfo.label': 'Ważne informacje',
   'input.importantInfo.placeholder': 'Informacje, które zobaczą klienci podczas potwierdzania rezerwacji',
   'input.changeAppointment.label': 'Zmiany w rezerwacji mogą zostać wprowadzone',
   'input.changeAppointment.placeholder': 'W dowolnym momencie',
   'checkbox.sendToBooked': 'Wyślij do pracownika, którego usługi zostały zarezerwowane',
   'checkbox.sendSpecificEmail': 'Wyślij na określony adres e-mail',
   'input.specificEmail.label': 'Określony adres e-mail',
   'input.specificEmail.placeholder': 'Wprowadź adres e-mail',
   'input.locationsUrl.label': 'Adres URL lokalizacji',
   'input.locationsUrl.placeholder': 'Np. Johns-Barber-Shop',
   'ob.btn.save': 'Zapisz',
   'ob.btn.cancel': 'Anuluj',
   'ob.btn.showLink': 'Pokaż link',
   'ob.btn.active': 'Aktywny',
   'ob.btn.inactive': 'Nieaktywne',
   'ob.linkModal.title': 'Bezpośredni Link dla rezerwacji',
   'ob.linkModal.description': 'Dzięki temu linkowi klienci będa mogli bezpośrednio rezerwować Twoje usługi!',
   'ob.linkModal.afterDescription':
      'Ten link przekieruje użytkowników bezpośrednio do Twoich usług. Za pomocą jednego kliknięcia klienci będą mogli otworzyć Twój profil i zarezerwować wizytę.\nTen link jest łatwy w użyciu i działa na dowolnym urządzeniu - komputerze, telefonie lub tablecie.',
   'ob.fbLinkModal.title': 'Strona na Facebooku Zarezerwuj teraz',
   'ob.fbLinkModal.description':
      'Skonfiguruj zintegrowany przycisk Zarezerwuj teraz na swojej stronie firmowej na Facebooku, aby bezproblemowo otrzymywać nowe rezerwacje bezpośrednio z Facebooka.\n',
   'ob.fbLinkModal.step1': 'Zaloguj się do strony swojej firmy na Facebooku',
   'ob.fbLinkModal.step2': 'Kliknij przycisk „Dodaj przycisk” znajdujący się obok zdjęcia na okładce',
   'ob.fbLinkModal.step3': 'Wybierz opcję „Zarejestruj się” i wprowadź poniższy link do rezerwacji',
   'ob.linkModalCopySuccess.title': 'Link skopiowany!',
   'ob.btn.copyLink': 'Skopiuj link',
   'title.appointmentSlot.select': 'Wybierz czas wizyty',
   'btn.back': 'Anuluj',
   'btn.continue': 'Kontynuować',
   'ob.hours.open': 'Otwórz',
   'ob.hours.close': 'Zamknąć',
   'ob.hours.step.title': 'Godziny pracy',
   'ob.hours.step.description': 'Poinformuj klientów o otwarciu i zamknięciu Twojej firmy.',
   'ob.staffHours.step.title': 'Godziny pracy personelu',
   'ob.staffHours.step.description':
      'Ważny krok, dodaj godziny pracy, aby system mógł wiedzieć, kiedy klienci mogą zarezerwować online.',
   'ob.location.step.title': 'Informacje o lokalizacji',
   'ob.photo.step.title': 'Zdjęcia',
   'ob.photo.step.description': 'Poinformuj klientów, jak wygląda miejsce Twojej firmy.',
   'input.file.dnd.photos': 'Przeciągnij i upuść zdjęcia',
   'btn.publish': 'Włączyć',
   'text.next': 'Następny',
   'input.phone.number.label': 'Numer telefonu',
   'input.state.label': 'Państwo',
   'input.state.placeholder': 'Podaj stan (gmina)',
   'input.addressDetails.label': 'Dane adresowe',
   'input.addressDetails.placeholder':
      'Wprowadź dane adresowe, takie jak kod do drzwi, inne wejścia, informacje o parkingu itp.',
   'placeholder.no.photos': 'Brak zdjęć',
   'btn.options': 'Opcje',
   'btn.list.profile': 'Włączać',
   'btn.preview': 'Zapowiedź',
   'btn.next': 'Następny',
   'market.ob.pleaseWait.title': 'Proszę czekać ...',
   'market.ob.location.step.title': 'Wybierz lokalizację',
   'market.ob.services.step.title': 'Wybierz usługi',
   'market.ob.employee.step.title': 'Wybierz datę i personel',
   'market.ob.timeSlot.step.title': 'Wybierz datę i godzinę',
   'market.ob.personalInfo.step.title': 'Płatność',
   'market.ob.appointmentInfo.step.title': 'Informacje o spotkaniu',
   'market.ob.informationAboutYou.title': 'Informacje o Tobie',
   'market.ob.paymentMethod.title': 'Sposób płatności',
   'market.ob.securePayment.title': 'Bezpieczna płatność',
   'market.ob.payWithCard.title': 'Płać kartą',
   'market.ob.payOnSite.title': 'Zapłać na miejscu',
   'market.ob.payOnSite.firstDescription': 'Będziesz musiał zapłacić w salonie po zabiegu.',
   'market.ob.payOnSite.secondDescription':
      'Niewielkie prośbę od nas: firmy to często małe niezależne firmy, więc jeśli nie możesz przejść do zaplanowanego wizyty, przeniesienia lub anuluj.',
   'market.ob.validThru.title': 'Ważny termin (miesiąc/rok)',
   'market.ob.information.title': 'Informacje',
   'market.ob.additionalInformation.title': 'Dodatkowe informacje',
   'market.ob.reschedulingPolicy.title': 'Polityka anulowania/przekładania wizyt',
   'market.ob.reschedulingPolicy.description':
      'Zmiany w umówionych spotkaniach mogą być dokonywane na {0} przed umówionym spotkaniem.',
   'market.ob.fieldsAreRequired.title': '* Pola są wymagane',
   'market.ob.step.title': 'Krok {0} z 3',
   'market.ob.confirm.step.title': 'Zobacz i potwierdź',
   'market.ob.noServices.placeholder': 'Nie wybrano jeszcze żadnych usług',
   'market.ob.input.firstName.label': '{0} Imię',
   'market.ob.input.firstName.placeholder': 'e.g. Jan',
   'market.ob.input.lastName.label': '{0} Nazwisko',
   'market.ob.input.lastName.placeholder': 'e.g. Łania',
   'market.ob.input.phone.label': '{0} Numer telefonu komórkowego',
   'market.ob.input.phone.placeholder': '670 70 822',
   'market.ob.input.email.label': '{0} Adres e-mail',
   'market.ob.input.email.placeholder': 'jan.łania@gmail.com',
   'market.ob.input.description.label': 'Uwagi',
   'market.ob.staff.assignedSpecialist.label': 'z personelem',
   'market.ob.staff.noPreference.label': 'Brak preferencji',
   'market.ob.timeSlots.fullyBooked.title': 'Przepraszamy, nie mamy dostępnych godzin na ten dzień.',
   'market.ob.timeSlots.choseAnotherDay.title': 'Proszę wybrać inny dzień.',
   'market.ob.timeSlots.closedSlot.title': 'Najbliższy dostępny termin to ',
   'market.ob.timeSlots.selectClosest.title': 'Wybierz najbliższą datę',
   'market.ob.timeSlots.note.title': 'Ważne informacje',
   'market.ob.service.duration.title': 'min',
   'market.location.workingHours.title': 'Godziny pracy',
   'market.location.monday.title': 'Poniedziałek',
   'market.location.tuesday.title': 'Wtorek',
   'market.location.wednesday.title': 'Środa',
   'market.location.thursday.title': 'Czwartek',
   'market.location.friday.title': 'Piątek',
   'market.location.saturday.title': 'Sobota',
   'market.location.sunday.title': 'Niedziela',
   'market.location.meetOurTeam.title': 'Poznaj nasz zespół',
   'market.location.title': 'Skontaktuj się z nami',
   'market.location.services.title': 'Usługi',
   'market.location.about.title': 'O',
   'market.location.aboutUs.title': 'O nas',
   'market.confirmation.appointmentCanceled.title': 'To spotkanie zostało anulowane',
   'market.confirmation.appointmentConfirmed.title': 'Nominacja potwierdzona',
   'market.confirmation.appointmentConfirmed.description': 'Twoja wizyta została potwierdzona, oto szczegóły:',
   'market.confirmation.appointmentDate.title': 'Data wizyty',
   'market.confirmation.scheduledAppointment.title': 'Odwołanie wizyty nie jest możliwe online.',
   'market.confirmation.scheduledAppointment.description':
      'Jeśli nie możesz przyjść na umówioną wizytę, przełóż ją na inny termin lub odwołaj.',
   'market.modal.cancelAppointment.title': 'Czy na pewno chcesz odwołać tę wizytę ?',
   'market.modal.cancelledAppointment.title': 'Appointment was cancelled',
   'market.language.changer.title': 'Język (Language)',
   'market.btn.bookNow': 'Rezerwuj',
   'market.servicesAvailable.title': 'usługi',
   'market.poweredByPlandok.title': 'Plandok',
   'market.poweredByPlandok.description': 'Chcesz mieć podobną stronę ? Odwiedź',
   'market.btn.readMore': 'Czytaj więcej',
   'market.btn.showLess': 'Pokaż mniej',
   'market.btn.reviews': 'Recenzje',
   'market.btn.viewMore': 'Zobacz więcej',
   'market.btn.cancelAppointment': 'Odwołaj wizytę',
   'market.btn.cancel': 'Tak, anuluj',
   'market.wh.closed.title': 'Zamknięte',
   'market.ob.success.message': 'Zrobione! Rezerwacja została pomyślnie utworzona',
   'market.ob.cancelAppointment.success.message': 'Spotkanie pomyślnie anulowane',
   'market.ob.cancelAppointment.error.message': 'Nie udało się anulować spotkania',
   'market.ob.cancelAppointment.confirm.title': 'Czy na pewno chcesz anulować wizytę?',
   'market.location.getDirections.title': 'Uzyskaj wskazówki',
   'market.notFound.title': 'Nie znaleziono strony',
   'market.notFound.btn.goBack': 'Wróć',
   'market.ob.quantitativeLimit.title': 'Limit ilościowy',
   'market.ob.quantitativeLimit.description':
      'Przepraszamy, właściciel nałożył limit na liczbę usług rezerwacji, umożliwiając wybór tylko {0} usług{0}',
   'market.ob.quantitativeLimit.three': 'trzy',
   'market.ob.quantitativeLimit.two': 'dwie',
   'market.ob.quantitativeLimit.one': 'jedna',
   'market.ob.quantitativeLimit.serviceSingular': 'a',
   'market.ob.quantitativeLimit.servicesPlural': 'i',
   'validation.characters.minLength': 'Ta wartość jest za krótka. Powinien mieć 3 lub więcej znaków.',
   'not.found.btn.label': 'Z powrotem',
   'not.found.subTitle.label': 'Przepraszamy, strona, którą odwiedziłeś, nie istnieje.',
   'analytics.title': 'Analityka',
   'analytics.dashboard.tab': 'Panel',
   'analytics.reports.tab': 'Raporty',
   'analytics.premium.title': 'Premia',
   'analytics.totalAppointments.title': 'Łączna liczba spotkań',
   'analytics.cancellationReasons.title': 'Najważniejsze powody anulowania',
   'analytics.occupancy.title': 'Okupacja',
   'analytics.topServices.title': 'Najlepsze usługi',
   'analytics.clients.title': 'Klienci',
   'analytics.completed.title': 'Zakończony',
   'analytics.notCompleted.title': 'Nieukończone',
   'analytics.canceled.title': 'Odwołany',
   'analytics.noShowAppointments.title': 'Brak umówionych wizyt',
   'analytics.reasonName.title': 'Nazwa powodu',
   'analytics.reasonCount.title': 'Liczyć',
   'analytics.lastPeriod.title': 'ostatni okres',
   'analytics.workingHours.title': 'Godziny pracy',
   'analytics.bookedHours.title': 'Zarezerwowane godziny',
   'analytics.blockedHours.title': 'Zablokowane godziny',
   'analytics.unbookedHours.title': 'Niezarezerwowane godziny',
   'analytics.service.title': 'Praca',
   'analytics.thisMonth.title': 'Ten okres',
   'analytics.lastMonth.title': 'Ostatni okres',
   'analytics.totalClients.title': 'Wszystkich klientów',
   'analytics.averageVisits.title': 'Średnia liczba wizyt na klienta ',
   'analytics.newClients.title': 'Nowi klienci',
   'analytics.returningClients.title': 'Powracający klienci',
   'analytics.returnRate.title': 'Stopa zwrotu klientów',
   'analytics.clientsByGender.title': 'Klienci według płci',
   'analytics.clientsBySource.title': 'Klienci według źródła referencyjnego',
   'analytics.clientsByAge.title': 'Klienci według wieku',
   'analytics.timeRange.custom': 'Zwyczaj',
   'analytics.timeRange.today': 'Dzisiaj',
   'analytics.timeRange.yesterday': 'Wczoraj',
   'analytics.timeRange.last1day': 'Ostatni 1 dzień',
   'analytics.timeRange.last7days': 'Ostatnie 7 dni',
   'analytics.timeRange.last30days': 'Ostatnie 30 dni',
   'analytics.timeRange.last90days': 'Ostatnie 90 dni',
   'analytics.timeRange.last3months': 'Ostatnie 3 miesiące',
   'analytics.timeRange.lastMonth': 'W zeszłym miesiącu',
   'analytics.timeRange.last6Months': 'Ostatnie 6 miesięcy',
   'analytics.timeRange.lastYear': 'Ostatni rok',
   'analytics.timeRange.last2year': 'Ostatnie 2 lata',
   'analytics.timeRange.thisWeek': 'Ten tygodnzien',
   'analytics.timeRange.thisMonth': 'W tym miesiącu',
   'analytics.timeRange.thisYear': 'Ten roku',
   'analytics.timeRange.tomorrow': 'Jutro',
   'analytics.timeRange.next7days': 'Następne 7 dni',
   'analytics.timeRange.next30days': 'Następne 30 dni',
   'analytics.menu.dashboard.description': 'Przeglądaj dane biznesowe w postaci wykresów i statystyk',
   'analytics.menu.reports.description': 'Przeglądaj swoje dane biznesowe w raportach',
   'analytics.timeRange.allTime': 'Cały czas',
   'analytics.timeRange.label': 'Zakres czasu',
   'analytics.services.lastPeriod.label': 'Ostatni okres',
   'analytics.totalAppointments.appointment.label': 'Wizyty',
   'analytics.totalAppointments.onlineBook.label': 'Rezerwacje internetowe',
   'analytics.gender.male': 'Męski',
   'analytics.gender.female': 'Żeński',
   'analytics.gender.unknown': 'Nieznany',
   'analytics.noData': 'W wybranym okresie nie ma danych',
   'analytics.premium.info.title': 'Upgrade do wersji premium, aby wyświetlić dane przez okres dłuższy niż 7 dni.',
   'analytics.premium.upgradeButton.title': 'AKTYWUJ',
   'analytics.premium.skipButton.title': 'Pominąć',
   'reports.appointments.title': 'Spotkania',
   'reports.appointments.description':
      'Przeglądaj prognozowane przychody z nadchodzących spotkań, śledź wskaźniki odwołań i przyczyny.',
   'reports.appointmentsList.title': 'Lista spotkań',
   'reports.appointmentsList.description': 'Wyświetla wszystkie spotkania wraz z powiązanymi informacjami.',
   'reports.appointmentsSummary.title': 'Podsumowanie spotkań',
   'reports.appointmentsSummary.description': 'Wyświetla spotkania według personelu i obsługi.',
   'reports.appointmentsCancellations.title': 'Odwołanie wizyt',
   'reports.appointmentCancellations.title': 'Anulowanie wizyt',
   'reports.appointmentsCancellations.description':
      'Wyświetla wszystkie powody i liczbę odwołanych spotkań w wybranym okresie.',
   'reports.summary.title': 'Streszczenie',
   'reports.clients.title': 'Klienci',
   'reports.clients.description':
      'Uzyskaj wgląd w to, jak klienci wchodzą w interakcję z Twoją firmą i kto wydaje najwięcej pieniędzy',
   'reports.clientsList.title': 'Klienci',
   'reports.clientsList.description': 'Lista Klientów wraz z ich danymi',
   'reports.exportCsv.title': 'Eksportuj plik CSV',
   'reports.appointmentsByStaff.title': 'Nominacje przez personel',
   'reports.appointmentsByService.title': 'Spotkania przez serwis',
   'reports.clearFilters.button.title': 'Wyczyść filtry',
   'reports.filterStaff.placeholder': 'Cały personel',
   'reports.filterLocations.placeholder': 'Wszystkie lokalizacje',
   'reports.filters.title': 'Filtry',
   'reports.locations.mobile.title': 'Lokalizacje:',
   'reports.staff.mobile.title': 'Personel:',
   'reports.filters.apply.title': 'Stosować',
   'reports.search.placeholder': 'Szukaj według referencji lub klienta',
   'reports.subscription.description': '{0} plan ({0} pracowników)',
   'reports.notifications.description': '{0} wiadomości ({0})',
   'serviceGroup.addCategory.description': 'Kategorie są niezbędne, ponieważ pozwalają grupować podobne usługi.',
   'serviceGroup.addCategory.title': 'Dodaj kategorię',
   'app.install.title': 'Zainstaluj Plandoka',
   'app.install.description.title':
      'Instalacja prawie nie zajmuje miejsca i zapewnia szybki sposób powrotu do tej aplikacji',
   'app.install.button.title': 'Zainstalować',
   'app.skip.button.title': 'Pominąć',
   'app.actionUndone.button.title': 'Działanie cofnięte',
   'app.appointmentCancelled.button.title': 'Nominacja usunięta',
   'app.undo.button.title': 'Cofnij',
   'app.showAllTimes.button.title': 'Pokaż wszystkie czasy',
   'app.install.success.title': 'Aplikacja została zainstalowana pomyślnie!',
   'rp.upgradeToPremiumLink.title': 'Uaktualnij do wersji Premium',
   'rp.upgradeToPremium.title': 'aby korzystać z tej funkcji',
   'rp.repeatAppointment.title': 'Powtarzać',
   'rp.input.frequency.title': 'Częstotliwość',
   'rp.input.endsAfter.title': 'Zakończenie',
   'rp.input.selectDate.title': 'Wybierz datę',
   'rp.upgrade.title': 'Uaktualnij swój plan',
   'rp.upgrade.description.title':
      'Wersja premium Plandoka nie jest aktywowana. Uaktualnij swój plan, aby uzyskać pełny dostęp do wszystkich funkcji oferowanych przez Plandok.',
   'rp.upgrade.button.title': 'Przejdź na wersję premium',
   'rp.repeating.title': 'Powtórzenie',
   'rp.repeats.title': 'Powtarza',
   'rp.repeatsUntil.title': 'dopóki',
   'rp.repeatingTimes.title': '({0} razy)',
   'rp.cancel.upcoming.title': 'Usuń wszystkie nadchodzące powtarzające się spotkania',
   'rp.update.upcoming.title': 'Zaktualizuj wszystkie nadchodzące powtórne spotkania',
   'rp.edit.appointment.title': 'Zaktualizuj termin',
   'rp.edit.appointment.description.title':
      'Edytujesz spotkanie cykliczne. Wybierz, które spotkania mają zostać zaktualizowane:',
   'rp.edit.thisAppointment.title': 'Zaktualizuj tylko to spotkanie',
   'rp.edit.allAppointments.title': 'Aktualizuj wszystkie nadchodzące spotkania',
   'rp.edit.saveAppointment.title': 'Ratować',
   'modal.cancelSubscriptionError.title': 'Błąd anulowania subskrypcji',
   'modal.cancelSubscriptionError.text.1': 'Plan darmowy jest dostępny tylko dla maksymalnie 5',
   'modal.cancelSubscriptionError.text.2': 'pracowników',
   'modal.cancelSubscriptionError.text.3': 'i 1',
   'modal.cancelSubscriptionError.text.4':
      'Aby skorzystać z wersji bezpłatnej, usuń lokalizacje / pracowników, aby spełnić wymagania.',
   'modal.latestPaymentFailed.title': 'Ostatnia płatność nie powiodła się',
   'modal.latestPaymentFailed.text.1':
      'Aby uzyskać dostęp do wszystkich funkcji, proszę zapłacić lub skorzystać z darmowej wersji usuń',
   'modal.latestPaymentFailed.text.2':
      ', aby spełnić wymagania. Plan darmowy jest dostępny tylko dla maksymalnie 5 pracowników i 1 lokalizacji.',
   'modal.extraCostsForNewMember.title': 'Dodatkowe koszty dla nowych członków',
   'modal.extraCostsForNewMember.description':
      'Zwiększenie liczby pracowników wiąże się z dodatkowymi kosztami do miesięcznego abonamentu.',
   'modal.premiumUpgradeRequired.title': 'Wymagane uaktualnienie Premium',
   'modal.premiumUpgradeRequired.description': 'Aby dodać {0}, Twój plan musi zostać uaktualniony do wersji Premium',
   'modal.premiumUpgradeRequired.location': 'dodatkową lokalizację',
   'modal.premiumUpgradeRequired.staffMembers': 'dodatkowych pracowników',
   'modal.changeAccount.title': 'Zmieniasz konto Stripe?',
   'modal.changeAccount.description':
      'Podczas zmiany konta nie będzie możliwe przyjmowanie płatności. Zmiana zajmie około minuty.',
   'btn.agree': 'Zgadzam się',
   'btn.decline': 'Odmowa',
   'paymentMethod.paymentMethod.title': 'Metoda płatności',
   'paymentMethod.currentPaymentMethod.title': 'Aktualna metoda płatności',
   'paymentMethod.expirationDate': 'Data ważności {0}',
   'paymentMethod.otherPaymentMethods.title': 'Inne metody płatności',
   'paymentMethod.addAnotherPaymentMethod.btn': 'Dodaj kolejną metodę płatności',
   'paymentMethod.addPaymentMethod.title': 'Dodaj metodę płatności',
   'paymentMethod.noPaymentMethodAdded.title': 'Nie dodano żadnej metody płatności',
   'paymentMethod.noPaymentMethodAdded.description': 'Metoda płatności zostanie dodana przy wyborze planu Premium',
   'paymentMethod.goToPlanPage.btn': 'Przejdź do strony planu',
   'paymentMethod.add.btn': 'Dodaj',
   'paymentMethod.makeDefault.btn': 'Ustawienie domyślne',
   'paymentMethod.cardHasBeenAdded.message': 'Karta została dodana',
   'paymentMethod.cardHasBeenAssign.message': 'Karta została przypisana',
   'page.marketing.overview.messages.description':
      'Wysyłaj niestandardowe wiadomości marketingowe do swoich klientów poprzez SMS lub Email za pomocą kilku prostych kliknięć',
   'page.marketing.overview.btn.sendMessage': 'Wyślij wiadomość',
   'page.marketing.overview.campaigns.title': 'Automatyczne wysyłanie kampanii',
   'page.marketing.overview.campaigns.description':
      'Twórz spersonalizowane kampanie w oparciu o wydarzenia takie jak urodziny klientów i inne. \nWysyłaj przez wiele kanałów, aby dostarczyć kampanie przez e-mail i SMS',
   'page.marketing.overview.btn.newCampaign': 'Nowa kampania',
   'page.marketing.massMessages.title': 'Ustaw nową wiadomość',
   'page.marketing.massMessages.messageLog': 'Rejestr wiadomości',
   'page.marketing.massMessages.sendOn': 'Wyślij dalej',
   'page.marketing.massMessages.sendTo': 'Wyślij do',
   'page.marketing.massMessages.channel': 'Kanał',
   'page.marketing.campaigns.description':
      'Wysyłaj automatyczne wiadomości marketingowe do swoich klientów poprzez SMS lub Email za pomocą kilku prostych kliknięć',
   'page.marketing.campaigns.birthdaySpecial.title': 'Urodziny Specjalne',
   'page.marketing.campaigns.winBackClients.title': 'Odzyskaj klientów',
   'page.marketing.campaigns.welcomeNewClients.title': 'Przywitaj nowych klientów',
   'page.marketing.campaigns.rewardRegularClients.title': 'Nagradzaj stałych klientów',
   'page.marketing.campaigns.birthdaySpecial.description':
      'Zaskocz klientów w ich szczególnym dniu, ta kampania automatycznie wysyła wiadomości do klientów w pobliżu ich urodzin',
   'page.marketing.campaigns.winBackClients.description':
      'Skierowanie do niezaangażowanych klientów, aby powrócili z ofertą specjalną. Ta kampania jest wysyłana do klientów, którzy nie wrócili po określonym czasie',
   'page.marketing.campaigns.welcomeNewClients.description':
      'Zamień nowych w stałych bywalców, zachęcając pierwszorazowych klientów do ponownej rezerwacji za pomocą specjalnej zniżki. Ta kampania jest automatycznie wysyłana do klientów dzień po ich pierwszej sprzedaży',
   'page.marketing.campaigns.rewardRegularClients.description':
      'Zaskocz najlepszych klientów specjalną ofertą, to pewny sposób na jeszcze większe zaangażowanie Twoich najlepszych klientów. Ta kampania jest automatycznie wysyłana do klientów na podstawie ostatniej aktywności w zakresie umawiania spotkań',
   'page.marketing.campaigns.btn.active': 'Aktywny',
   'page.marketing.campaigns.btn.inactive': 'Nieaktywny',
   'page.marketing.campaigns.btn.startCampaign': 'Rozpocznij kampanię',
   'page.marketing.campaigns.btn.editCampaign': 'Edytuj kampanię',
   'page.marketing.campaigns.btn.resetCampaign': 'Wyzeruj kampanię',
   'modal.resetCampaign.description': 'Czy na pewno chcesz zresetować tę kampanię ?',
   'modal.resetCampaign.btn.yesReset': 'Tak, zresetuj',
   'page.marketing.smsCreate.step.1': 'Konfiguracja SMS. Krok 1 z 4',
   'page.marketing.smsCreate.step.2': 'Konfiguracja SMS. Krok 2 z 4',
   'page.marketing.smsCreate.step.3': 'Konfiguracja SMS. Krok 3 z 4',
   'page.marketing.smsCreate.step.4': 'Konfiguracja SMS. Krok 4 z 4',
   'page.marketing.emailCreate.step.1': 'Konfiguracja poczty elektronicznej. Krok 1 z 4',
   'page.marketing.emailCreate.step.2': 'Konfiguracja poczty elektronicznej. Krok 2 z 4',
   'page.marketing.emailCreate.step.3': 'Konfiguracja poczty elektronicznej. Krok 3 z 4',
   'page.marketing.emailCreate.step.4': 'Konfiguracja poczty elektronicznej. Krok 4 z 4',
   'page.marketing.campaignCreate.step.1': 'Konfiguracja kampanii. Krok 1 z 3',
   'page.marketing.campaignCreate.step.2': 'Konfiguracja kampanii. Krok 2 z 3',
   'page.marketing.campaignCreate.step.3': 'Konfiguracja kampanii. Krok 3 z 3',
   'page.marketing.smsCreate.step.1.title': 'Identyfikator wiadomości i nadawcy',
   'page.marketing.smsCreate.step.1.defaultTitleForSms': 'Wprowadź identyfikator nadawcy SMS',
   'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Wpisz swoją wiadomość',
   'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Wpisz tytuł wiadomości',
   'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Wpisz swoją wiadomość',
   'page.marketing.emailCreate.step.1.title': 'Ustawienia poczty elektronicznej',
   'page.marketing.emailCreate.step.1.messageTitle': 'Tytuł wiadomości',
   'page.marketing.emailCreate.step.1.replyToEmail': 'Odpowiedz na email',
   'page.marketing.emailCreate.step.1.clientReplies': 'Odpowiedzi klientów będą wysyłane na ten email',
   'page.marketing.emailCreate.step.1.photo.title': 'Zdjęcie',
   'page.marketing.emailCreate.step.1.enablePhoto': 'Włącz zdjęcie',
   'page.marketing.emailCreate.step.1.changePhoto': 'Zmień zdjęcie',
   'page.marketing.emailCreate.step.1.button.title': 'Przycisk',
   'page.marketing.emailCreate.step.1.enableButton': 'Włącz przycisk',
   'page.marketing.emailCreate.step.1.buttonName': 'Nazwa przycisku',
   'page.marketing.emailCreate.step.1.buttonURL': 'URL przycisku',
   'page.marketing.emailCreate.step.1.socialMedia.title': 'Media społecznościowe',
   'page.marketing.emailCreate.step.1.enableSocialMedia': 'Włącz linki do mediów społecznościowych',
   'page.marketing.emailCreate.step.1.facebookPage': 'Strona na Facebooku',
   'page.marketing.emailCreate.step.1.instagramPage': 'Strona na Instagramie',
   'page.marketing.emailCreate.step.1.yourPage': 'Twoja strona',
   'page.marketing.smsCreate.yourMessage': 'Twoja wiadomość',
   'page.marketing.btn.sendMeTestMessage': 'Wyślij mi wiadomość z testem',
   'page.marketing.smsCreate.step.1.freeTests': 'Na dziś zostały {0} darmowe testy',
   'page.marketing.smsCreate.messagePreview': 'Podgląd wiadomości',
   'page.marketing.smsCreate.btn.clientSelection': 'Wybór klienta',
   'page.marketing.audience.title': 'Publiczność',
   'page.marketing.smsCreate.step.2.description': 'Wybierz, którzy klienci otrzymają Twoją wiadomość',
   'page.marketing.smsCreate.step.2.allClient.title': 'Wszyscy klienci',
   'page.marketing.smsCreate.step.2.allClient.description': 'Wyślij wiadomość do wszystkich klientów',
   'page.marketing.smsCreate.step.2.clientGroups.title': 'Grupy klientów',
   'page.marketing.smsCreate.step.2.clientGroups.description': 'Wyślij wiadomość do wybranych grup klientów',
   'page.marketing.smsCreate.step.2.totalClients': 'Wszyscy klienci',
   'page.marketing.smsCreate.step.2.totalPrice': 'Łączna cena',
   'page.marketing.smsCreate.step.3.description': 'Wybierz czas, w którym chcesz wysłać wiadomość',
   'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Wybierz niestandardową datę i godzinę',
   'page.marketing.smsCreate.step.4.title': 'Płatność za wiadomość masową',
   'page.marketing.smsCreate.step.4.description': 'Wpisz dane swojej karty kredytowej i zapłać',
   'page.marketing.smsCreate.step.4.paymentAmount': 'Kwota płatności:',
   'btn.scheduleSend': 'Zaplanuj wysyłkę',
   'btn.previous': 'Poprzedni',
   'btn.payment': 'Płatność',
   'btn.payAndFinish': 'Zapłać i zakończ',
   'btn.backToDashboard': 'Powrót do pulpitu',
   'btn.saveAndClose': 'Zapisz i zamknij',
   'modal.editClientSelection.title': 'Edycja wyboru klienta',
   'modal.newClient.description': 'Klienci dodani w ostatnim czasie:',
   'modal.recentClient.title': 'Ostatni klient',
   'modal.recentClient.description': 'Klienci, którzy odwiedzili nas w ostatnim czasie:',
   'modal.clientByAge.title': 'Klient wg wieku',
   'modal.clientsByGender.title': 'Klienci według płci',
   'modal.paymentConfirmation.title': 'Płatność została zrealizowana !',
   'modal.paymentConfirmation.messageSend': 'Twoja wiadomość zostanie wysłana',
   'modal.previewMessage.title': 'Podgląd wiadomości',
   'modal.previewSms.tab': 'Podgląd SMS',
   'modal.previewEmail.tab': 'Podgląd Email',
   'modal.editServiceSelection.title': 'Edytuj wybór usługi',
   'modal.editServiceSelection.allServices': 'Wszystkie usługi',
   'modal.campaignActivated.title': 'Kampania aktywowana !',
   'modal.campaignActivated.description': 'W każdej chwili możesz wstrzymać lub edytować tę kampanię',
   'btn.all': 'Wszystkie {0}',
   'btn.included': 'Uwzględnione {0}',
   'btn.excluded': 'Wykluczone {0}',
   'checkbox.allClients': 'Wszyscy klienci {0}',
   'scheduleSend.time': 'czas',
   'scheduleSend.timeSetTo': 'Czas ustawiony na:',
   'scheduleSend.setTime': 'Ustaw czas',
   'campaignSetup.step.1.header.title': 'Ustawienie kampanii',
   'campaignSetup.step.2.header.title': 'Edycja wiadomości',
   'campaignSetup.step.3.header.title': 'Przeglądaj',
   'campaignSetup.channel.description': 'Wybierz kanały, którymi zostanie wysłana kampania',
   'campaignSetup.smsSetup.tab': 'Konfiguracja SMS',
   'campaignSetup.emailSetup.tab': 'Konfiguracja e-mail',
   'campaignSetup.almostDone.title': 'Prawie gotowe !',
   'campaignSetup.almostDone.description':
      'Twoja inteligentna kampania jest gotowa do pracy, po jej włączeniu będzie automatycznie wysyłać wiadomości do klientów na bieżąco. Możesz łatwo modyfikować lub wstrzymać kampanię w dowolnym momencie',
   'campaignSetup.approximatePrice.header': 'Przybliżona cena',
   'campaignSetup.approximatePrice.description': 'Za najbliższe 30 dni',
   'campaignSetup.approximatePrice.approx': 'Ok.',
   'campaign.birthdaySpecial.description':
      'Ta kampania automatycznie wysyła wiadomości do klientów w okolicach ich urodzin',
   'campaign.birthdaySpecial.sendToClient': 'Wyślij do klienta:',
   'campaign.birthdaySpecial.selectOption.1': 'W dniu urodzin',
   'campaign.birthdaySpecial.selectOption.2': 'Trzy dni przed urodzinami',
   'campaign.birthdaySpecial.selectOption.3': 'Tydzień przed urodzinami',
   'campaign.birthdaySpecial.selectOption.4': 'Dwa tygodnie przed urodzinami',
   'campaign.winBackClients.description':
      'Ta kampania jest wysyłana do klientów, którzy nie powrócili po określonym czasie',
   'campaign.winBackClients.clientsWhoDidntReturn': 'Klienci, którzy nie wrócili w ostatnim okresie',
   'campaign.winBackClients.appliedTo': 'Dotyczy',
   'campaign.winBackClients.all': 'wszystkich',
   'campaign.winBackClients.services': 'usług',
   'campaign.winBackClients.editSelection': 'Edytuj wybór',
   'campaign.welcomeNewClients.description':
      'Zamień nowych klientów w stałych bywalców, zachęcając pierwszaków do ponownej rezerwacji specjalnym rabatem',
   'campaign.welcomeNewClients.smartCampaignNewClients':
      'Ta inteligentna kampania automatycznie wysyła do nowych klientów dzień po ich pierwszej sprzedaży',
   'campaign.rewardRegularClients.description':
      'Ta kampania jest automatycznie wysyłana do klientów na podstawie ostatnich wizyt',
   'campaign.rewardRegularClients.clientsWithAtLeast': 'Klienci z co najmniej',
   'campaign.rewardRegularClients.appointmentsInThe': 'umówionych wizyt w ciągu',
   'campaign.rewardRegularClients.last': 'ostatnich',
   'campaign.rewardRegularClients.appointments': 'umówionych wizyt',
   'campaign.rewardRegularClients.inTheLast': 'w ciągu ostatnich',
   'campaign.rewardRegularClients.months': 'miesięcy',
   'subscriptionPaymentFailed.title':
      'Zaktualizuj metodę płatności do {0}, w przeciwnym razie wszystkie funkcje premium zostaną przerwane, dziękuję.',
   'subscriptionCancelled.title':
      'Przepraszamy, ale Twoje konto Premium zostało dezaktywowane z powodu braku płatności. Jeśli chcesz nadal korzystać z funkcji premium, aktywuj Plan Premium, dziękujemy.',
   'notificationPaymentFailed.title':
      'Twoja płatność za powiadomienia nie powiodła się. Proszę o dokonanie płatności do {0}, w przeciwnym razie powiadomienia zostaną wyłączone, dziękujemy.',
   'notificationCancelled.title':
      'Przepraszamy, ale Twoje powiadomienia zostały wyłączone z powodu braku płatności. Jeżeli chcesz kontynuować wysyłanie powiadomień prosimy o dokonanie płatności, dziękujemy.',
   'btn.hideOptions': 'Ukryj opcje',
   'btn.showOptions': 'Pokaż opcje',
   'notification.googleDisconnected': 'Kalendarz Google odłączony',
   'notification.googleConnected': 'Kalendarz Google połączony',
   'notification.appleDisconnected': 'Kalendarz Apple odłączony',
   'notification.appleConnected': 'Kalendarz Apple połączony',
   'notification.microsoft365Disconnected': 'Kalendarz Microsoft 365 odłączony',
   'notification.microsoft365Connected': 'Kalendarz Microsoft365 połączony',
   'notification.linkCopied': 'Skopiowane łącze',
   'calendarSync.title': 'Synchronizacja kalendarza',
   'calendarSync.description':
      'Jednokierunkowa synchronizacja spotkań Plandok z ulubionym kalendarzem zapewnia łatwą organizację i pozwala nigdy nie przegapić ważnego wydarzenia.',
   'googleCalendar.title': 'Kalendarz Google',
   'appleCalendar.title': 'Kalendarz Apple',
   'microsoft365Calendar.title': 'Kalendarz Microsoft 365',
   'linkYourCalendar.title': 'Połącz swoje kalendarze',
   'connect.btn': 'Połącz',
   'disconnect.rtn': 'Rozłącz',
   'connected.label': 'Połączony',
   'modal.workingHours.standartMethod.btn': 'Metoda standardowa',
   'modal.workingHours.customMethod.btn': 'Metoda niestandardowa',
   'modal.workingHours.workingDates.input': 'Daty robocze',
   'modal.workingHours.selectDates.placeholder': 'Wybierz daty',
   'noInternetConnection.notification.title': 'Brak połączenia z Internetem',
   'app.update.title': 'Nowa wersja dostępna',
   'app.update.description.title':
      'Dodaliśmy nowe funkcje i naprawiliśmy kilka błędów, aby korzystanie z Plandok było jak najbardziej płynne.',
   'app.update.button.title': 'Aktualizuj ',
   'app.notNow.button.title': 'Nie teraz',
   'app.updateAvailable.button.title': 'Nowa wersja dostępna',
   'upgradePlan.success.message': 'Subskrypcja została pomyślnie zaktualizowana',
   'upgradePlan.error.message':
      'Błąd aktualizacji subskrypcji, skontaktuj się z pomocą techniczną, jeśli karta została obciążona, a subskrypcja nie została zaktualizowana',
   'sidebar.inventory': 'Inwentaryzacja',
   'products.tab': 'Produkty',
   'suppliers.tab': 'Dostawcy',
   'stockOrders.tab': 'Zamówienia magazynowe',
   'stockTracking.tab': 'Śledzenie zapasów',
   'input.productsSearch.placeholder': 'Wyszukiwanie według nazwy produktu lub kodu kreskowego',
   'product.create.btn.title': 'Dodawanie nowego produktu',
   'table.products.product': 'Produkt',
   'table.products.barcode': 'Kod kreskowy',
   'table.products.brand': 'Marka',
   'table.products.sale': 'Sprzedaż',
   'table.products.saleOpt': 'Opc. sprzedaży',
   'saleOptions.tooltip.title': 'Opcje sprzedaży',
   'saleOptions.tooltip.description': 'Możesz włączyć lub wyłączyć opcję sprzedaży podczas realizacji zakupu.',
   'seeMore.title': 'zobacz więcej',
   'option.products.downloadCSV': 'Pobierz CSV',
   'option.products.downloadExcel': 'Pobierz Excel',
   'option.products.manageBrands': 'Zarządzanie markami',
   'option.products.manageCategories': 'Zarządzaj kategoriami',
   'option.products.manageStock': 'Zarządzanie zapasami',
   'option.products.importProducts': 'Importuj produkty',
   'modal.filter.categories.title': 'Kategorie',
   'modal.filter.brands.title': 'Marki',
   'modal.filter.stock.title': 'Zapasy',
   'modal.filter.allCategories.selectItem': 'Wszystkie kategorie',
   'modal.filter.allBrands.selectItem': 'Wszystkie marki',
   'modal.filter.allSuppliers.selectItem': 'Wszyscy dostawcy',
   'modal.filter.allProducts.selectItem': 'Wszystkie produkty',
   'input.suppliersSearch.placeholder': 'Wyszukiwanie według nazwy dostawcy',
   'supplier.create.btn.title': 'Dodaj nowego dostawcę',
   'table.suppliers.supplierName': 'Nazwa dostawcy',
   'table.suppliers.phoneNumber': 'Numer telefonu',
   'table.suppliers.physicalAddress': 'Adres fizyczny',
   'validation.notification.reminderTime': 'Nie można wysyłać powiadomień o tej samej godzinie przypomnienia.',
   'validation.paymentType.switches': 'Musi być dozwolona co najmniej jedna metoda płatności',
   'plan.premium.option.14':
      '0% prowizji Plandok za wizyty zarezerwowane za pośrednictwem rezerwacji online przy użyciu karty kredytowej.',
   'title.dragAndExtend.appointment.permission': 'Można przeciągać i przedłużać spotkania',
   'button.lastVisit.addAnotherLastvisit': 'DODAJ KOLEJNĄ OSTATNĄ WIZYTĘ',
   'notifications.template.badge.validate': 'Uprawomocnić',
   'notifications.template.badge.refused': 'Odrzucony',
   'paid.by.card': 'Płatne kartą',
   'costs.manual.paid.link': 'Możesz też zapłacić za powiadomienia ręcznie swoją kartą, klikając ten link',
   'modal.remove.account.title': 'Usuń konto Stripe',
   'modal.remove.account.description':
      'Twoje konto zostanie usunięte i nie będziesz mógł akceptować płatności online. ',
   'ob.remove.account.option': 'Usuń konto Stripe',
   'btn.remove.account': 'Usunąć',
   'calendar.tooltip.selected': 'Wybrany',
   'calendar.tooltip.no.preference': 'Brak preferencji',
   'ob.gtag.manager.title': 'Menedżer tagów Google',
   'ob.analytics.gtag.description.1':
      'Menedżer tagów Google pozwala mierzyć ROI z reklam, a także śledzić Flash, wideo i witryny i aplikacje w sieciach społecznościowych.',
   'ob.gtag.manager.description.1':
      'Google Tag Manager to bezpłatny system zarządzania tagami, który umożliwia zarządzanie tagami marketingowymi (fragmentami kodu lub pikselami śledzącymi) i wdrażanie ich w witrynie lub aplikacji mobilnej bez konieczności bezpośredniej modyfikacji kodu.',
   'ob.gtag.manager.description.2':
      'Zasadniczo GTM usprawnia proces zarządzania tagami marketingowymi, ułatwiając śledzenie wydajności witryny, pomiar kampanii marketingowych i optymalizację doświadczeń użytkowników.',
   'text.gender.male': 'Mężczyzna',
   'text.gender.female': 'Kobieta',
   'text.gender.unknown': 'Nieznany',
}

export default labels
