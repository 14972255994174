import { useAppContext } from '@plandok/core'

export const useCurrencyCode = () => {
   const [state] = useAppContext()
   return state?.currencyIsoCode || 'EUR'
}

export const useCountry = () => {
   const [state] = useAppContext()
   return state?.country
}
