import { mediaMobileDown, theme } from '@plandok/core'
import styled, { css } from 'styled-components'

export const Row = styled.tr`
   td {
      background: white;
      padding: 12px;
      border-top: 1px solid #e8e8e8;
      transition:
         all 0.3s,
         border 0s;

      ${mediaMobileDown(css`
         padding: 8px !important;
      `)}
   }
`

export const Head = styled.thead`
   th {
      padding: 15px 0;
      text-align: left !important;
      color: ${theme.primaryColor}
         ${mediaMobileDown(css`
            padding: 8px !important;
         `)};
   }
`
