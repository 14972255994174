import styled from 'styled-components'

import { AnalyticsCard } from '../../styles'

type TooltipCircleProps = {
   color: string
}

export const TotalAppointments = styled(AnalyticsCard)`
   grid-area: total-appointments-1;
   background: #fff;
   padding: 30px;
`

export const TotalAppointmentsChart = styled(AnalyticsCard)`
   grid-area: total-appointments-2;
   background: #fff;
   padding: 30px;
`

export const TotalStatus = styled.div`
   display: grid;
   grid-template-columns: 1fr auto;
`

export const TotalCount = styled.div`
   display: flex;
   align-items: baseline;
   height: 4.2rem;

   div:first-child {
      padding-right: 1rem;
   }
`

export const Inliner = styled.div`
   display: flex;
   justify-content: flex-end;
`

export const NumbersColumn = styled.div`
   justify-content: center;
`

export const Divider = styled.div`
   margin: 5px 0 !important;
`

export const AreaChart = styled.div`
   height: 15rem;
`

export const Tooltip = styled.div`
   padding: 0.4rem 1rem 0.4rem;
   color: #fff;
   background: #13316d;
   overflow-x: hidden;
   margin-left: -1rem;
   margin-right: -1rem;
   border-radius: 10px;
   box-shadow: 0px 2px 10px #263c7a33;
`

export const TooltipDivider = styled.div`
   margin-top: 0.5rem;
   border-bottom: 1px solid #566d9c;
`

export const TooltipDataRow = styled.div`
   padding: 0.4rem 0 0.4rem;
   display: flex;
`

export const TooltipCircle = styled.div<TooltipCircleProps>`
   height: 10px;
   width: 10px;
   background-color: ${({ color }) => color};
   border-radius: 50%;
   margin-right: 0.8rem;
   display: inline-block;
`

export const TooltipValues = styled.div`
   margin-right: 0.8rem;
`
