import { Text } from '@plandok/core'
import { IntlTemplate } from '@plandok/i18n'
import { format } from 'date-fns'
import upperFirst from 'lodash/upperFirst'
import * as SC from 'pages/plans/UpdateSubscriptionPage/styles'
import React from 'react'

export default function SubscriptionSummaryCard({ estimatedCostsData }: any) {
   const formatDate = (date: string) => format(new Date(date), 'yyyy/MM/dd')

   const totalCharge = `${estimatedCostsData?.message?.totalCharge?.toFixed(2) || 0}€`
   const total = `${estimatedCostsData?.total?.toFixed(2) || 0}€`

   return (
      <SC.SummaryCardContainer>
         <Text label="reports.summary.title" weight="semiBold" className="summary" />
         {estimatedCostsData?.subscription && (
            <SC.ServiceItem>
               <div>
                  <Text colorType="base" size="medium" weight="semiBold" lh="mlarge" label="payment.tab.title.sub" />
                  <Text colorType="base" mb="none">
                     <IntlTemplate
                        label="reports.subscription.description"
                        params={[
                           upperFirst(estimatedCostsData?.subscription?.planType),
                           estimatedCostsData?.subscription?.staffCount,
                        ]}
                     />
                  </Text>
               </div>
               <Text colorType="base" mb="none">
                  {estimatedCostsData?.subscription?.total?.toFixed(2) || 0}€
               </Text>
            </SC.ServiceItem>
         )}

         {estimatedCostsData?.message?.hasUnpaid && (
            <SC.ServiceItem>
               <div>
                  <Text
                     colorType="base"
                     size="medium"
                     weight="semiBold"
                     lh="mlarge"
                     label="payment.tab.title.notification"
                  />
                  <Text colorType="base" mb="none">
                     <IntlTemplate
                        label="reports.notifications.description"
                        params={[
                           estimatedCostsData?.message?.totalCount,
                           `${formatDate(estimatedCostsData?.message?.startDate)} - ${formatDate(
                              estimatedCostsData?.message?.endDate,
                           )}`,
                        ]}
                     />
                  </Text>
               </div>
               <Text colorType="base" mb="none">
                  {totalCharge}
               </Text>
            </SC.ServiceItem>
         )}

         <SC.ContainerTotal>
            <div>
               <Text mb="none" size="large" lh="xxlarge">
                  <IntlTemplate label="appointment.vat" params={[estimatedCostsData?.vat || 0]} />
               </Text>
               <Text lh="xxlarge" size="large" colorType="base" mb="none" ml="large">
                  {estimatedCostsData?.taxes || 0}€
               </Text>
            </div>
            <SC.Total>
               <Text size="large" lh="xxlarge" colorType="base" mb="none" label="appointment.total" />
               <Text lh="xxlarge" size="large" weight="semiBold" colorType="base" mb="none" ml="large">
                  {total}
               </Text>
            </SC.Total>
         </SC.ContainerTotal>
      </SC.SummaryCardContainer>
   )
}
