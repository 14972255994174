import { Text } from '@plandok/core'
import { IntlLabel, IntlMoney } from '@plandok/i18n'
import { formatDuration } from 'helpers/date'
import sumBy from 'lodash/sumBy'
import React from 'react'

export default function AppointmentFooterTotal({ value }: any) {
   const totalPrice =
      value?.bookings
         ?.map((e: any) => parseFloat(e.price))
         ?.filter(Boolean)
         ?.reduce((acc: any, a: any) => acc + a, 0)
         ?.toFixed(2) || 0

   const totalDuration = sumBy(value?.bookings || [], 'duration') || 0

   return (
      <Text size="mlarge" weight="medium" colorType="base" mb="none">
         <IntlLabel label="appointment.total" /> <IntlMoney value={totalPrice} /> (
         {formatDuration(totalDuration) || '0'})
      </Text>
   )
}
